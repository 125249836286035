import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import httpRequest from 'plugin/httprequest';
import { months, mpType, product, rekBank } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import { formatDate, formatNumber } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';

class ModalProsesTransaksiMpRetur extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                caption: 'Pembayaran Susulan',
                items: [
                    {
                        dataField: 'id',
                        label: {
                            alignment: 'left',
                            text: 'ID Retur'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'returNo',
                        label: {
                            alignment: 'left',
                            text: 'No Retur'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalPembayaranSusulan',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran Susulan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            width: '100%'
                        }
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Keterangan'
                        },
                    },
                    {
                        dataField: 'returRekbankId',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Bank Pembayar'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'rekName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Data Pembayaran MP',
                items: [
                    {
                        dataField: 'idTransaksi',
                        label: {
                            alignment: 'left',
                            text: 'ID Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalTransaksi',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'periodeBulan',
                        label: {
                            alignment: 'left',
                            text: 'Periode Bulan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: months(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'periodeTahun',
                        label: {
                            alignment: 'left',
                            text: 'Periode Tahun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Manfaat Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'mpName',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nik',
                        label: {
                            alignment: 'left',
                            text: 'NIK'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'fullName',
                        label: {
                            alignment: 'left',
                            text: 'Nama Peserta'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'mpNominal',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Manfaat Pensiun'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'paymentRekbankId',
                        label: {
                            alignment: 'left',
                            text: 'Rekening Pembayaran MP'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'rekName',
                            readOnly: true
                        },
                    }
                ]
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    retrieveData = async (data) => {
        var loadAPI = 'pembayaran-mp-returs'
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + data.id, 'GET')

        var loadAPIDetil = 'pembayaran-mp-detils'
        var getDataDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil + '/' + getData.pembayaranMpDetilId, 'GET')

        var loadAPIPemMP = 'pembayaran-mps'
        var getDataPemMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPemMP + '/' + getDataDetil.pembayaranMpId, 'GET')

        var loadAPIPeserta = 'pesertas'
        var getDataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta + '/' + getDataDetil.pesertaId, 'GET')
        
        this.setState({
            formData: {
                ...getData,
                ...getDataDetil,
                ...getDataPeserta,
                keterangan: `Pembayaran Kembali Retur a/n ${getDataPeserta.fullName} sebesar Rp.${formatNumber(data.mpNominal)}`,
                idTransaksi: getDataDetil.id,
                idRetur: data.id,
                periodeBulan: getDataPemMp.bulan,
                periodeTahun: getDataPemMp.tahun,
                tanggalTransaksi: getDataPemMp.paymentDate,
                productId: getDataPemMp.productId,
                mpTypeId: getDataPemMp.mpTypeId,
                mpNominal: data.mpNominal,
            }
        })
    }

    submitData = async () => {
        var loadAPI = 'pembayaran-mp-returs/pembayaranSusulanDariRetur'
        var formData = this.state.formData
        var data = {
            idRetur: formData.idRetur,
            idTransaksi: formData.idTransaksi,
            keterangan: formData.keterangan,
            namaPeserta: formData.fullName,
            nik: formData.nik,
            noRetur: formData.returNo,
            nominalManfaatPensiun: formData.mpNominal,
            rekeningBankPembayar: formData.returRekbankId,
            rekeningPembayaranMp: formData.paymentRekbankId,
            tanggalPembayaranSusulan: formatDate(formData.tanggalPembayaranSusulan),
            periodeBulan: formData.periodeBulan,
            produk: formData.productId,
            tipeManfaatPensiun: formData.mpTypeId,
            tanggalTransaksi: formData.tanggalTransaksi,
        }
        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
            values: data
        })

        if (response) {
            await alert("Data Sukses Diproses!", "Berhasil")
            this.props.forceRefresh()
            this.hide()
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Retur'}
                width={800}
                height={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    ref={this.formRef}
                                    colCount={1}
                                    id={'formActionProsesRetur'}
                                    formData={this.state.formData}
                                    items={this.dataMaster}
                                    scrollingEnabled={true}
                                    labelLocation='left'
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ModalProsesTransaksiMpRetur