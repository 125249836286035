import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader } from 'devextreme-react';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { imageSource, formatUploadFileData } from 'plugin/helper';
import { dayBase } from 'dataSource/lookup';


class FormEditorBank extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            uploadedData: []
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'master',
                colCount: 4,
                colSpan: 2,
                items:[
                    {
                        dataField: 'bankCode',
                        label: {
                            text: 'Kode Bank'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'bankName',
                        label: {
                            text: 'Nama Bank'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'initialName',
                        label: {
                            text: 'Nama Inisial'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'biCode',
                        label: {
                            text: 'Kode BI'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'swiftCode',
                        label: {
                            text: 'Kode Swift'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'dayBase',
                        label: {
                            text: 'Hari/Tahun Aktua;'
                        },
                        colSpan: 2,
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: dayBase(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            openOnFieldClick: true,
                            searchEnabled: true
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'kinerja',
                caption: 'Informasi Kinerja',
                colCount: 3,
                colSpan: 2,
                items:[
                    {
                        dataField: 'totalAsset',
                        label: {
                            text: 'Total Asset'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'returnOnAsset',
                        label: {
                            text: 'Return on Asset (ROA)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'returnOnEquity',
                        label: {
                            text: 'Return on Equity (ROE)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'netInterestMargin',
                        label: {
                            text: 'Net Interest Margin (NIM)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'loanDepositRatio',
                        label: {
                            text: 'Loan to Deposit Ratio (LDR)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'capitalAdequacyRatio',
                        label: {
                            text: 'Capital Adequancy Ratio (CAR)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'nplGross',
                        label: {
                            text: 'Non Performing Loan (Gross)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'nplNet',
                        label: {
                            text: 'Non Performing Loan (Nett)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                    {
                        dataField: 'costIncomeRatio',
                        label: {
                            text: 'Cost to Income Ratio (CIR)'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00 %",
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'address',
                caption: 'Informasi Alamat',
                colCount: 3,
                colSpan: 2,
                items:[
                    {
                        dataField: 'alamat',
                        label: {
                            text: 'Alamat'
                        },
                        colSpan: 3,
                        editorType : 'dxTextBox',
                        editorOptions: {
                        },
                    },
                    {
                        dataField: 'noTelp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'fax',
                        label: {
                            text: 'Fax'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'contactPerson1',
                caption: 'Informasi Contact Person 1',
                colCount: 2,
                alignItemLabels : true,
                items:[
                    {
                        dataField: 'cp1Nama',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1UnitKerja',
                        label: {
                            text: 'Unit Kerja'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1NoTlp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp1Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'contactPerson2',
                caption: 'Informasi Contact Person 2',
                colCount: 2,
                items:[
                    {
                        dataField: 'cp2Nama',
                        label: {
                            text: 'Nama'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2UnitKerja',
                        label: {
                            text: 'Unit Kerja'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2NoTlp',
                        label: {
                            text: 'No Telp.'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2NoHp',
                        label: {
                            text: 'No HP'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'cp2Email',
                        label: {
                            text: 'Email'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                ]
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show(dataMaster) {
        
        var dataMaster = dataMaster || {
        }
        
        this.setState({
            popupVisible: true,
            dataMaster: dataMaster
        });

    }

    hide(){
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.dataMaster
        if (data.id) {
            try {
                let type = 'success'
                let text = 'Sukses ubah !'
                var updateAPI = `staticdata-wf/banks`
                var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, updateAPI, 'PUT', {
                    values: this.state.dataMaster,
                    key: this.state.dataMaster.id
                }, this.state.dataMaster)

                if (response) {
                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    this.hide()
                } else {
                    notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                console.log(e)
            }
        } else {
            if (data) {
                try {
                    let type = 'success'
                    let text = 'Sukses input !'
                    var insertAPI = `staticdata-wf/banks`
                    var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, insertAPI, 'POST', {
                        values: data,
                    })
                    if (response) {
                        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                        this.hide()
                    } else {
                        notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                    console.log(e)
                }
            }
        }
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];
            
            var fr = new FileReader();

            var formattedFileData;

            var dataMaster = { ...this.state.dataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                dataMaster[`${fieldName}Name`] = files.name;
                dataMaster[fieldName] = formattedFileData.base64data;
                dataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                console.log(dataMaster)

                this.setState({
                    dataMaster: dataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Bank Data`}
                    minWidth={'90vw'}
                    height="90vh"

                    ref={this.PopupRef}

                    toolbarItems = {this.popupToolbarItem}
                >
                    <div className="container-fluid py-3">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={2}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.contactPersonItem}
                                    labelLocation={"left"}
                                />
                            </div> */}
                            <div className="col-md-6 text-center" style={{height:"262px"}}>
                                <FileUploader
                                    accept={'.bmp,.gif,.ico,.jpeg,.jpg,.png,.svg,.tif,.tiff,.webp'}
                                    uploadMode={'useForm'}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText="Taruh Logo Bank disini"
                                    showFileList={false}
                                    selectButtonText="Upload Logo Bank"
                                    name={'logo'}

                                    value = {this.state.uploadedData}

                                    ref={this.fileUploderRef}
                                />
                                <img src={imageSource(this.state.dataMaster.logoContentType,this.state.dataMaster.logo)} style={{maxHeight: 'calc(100% - 75px)',maxWidth: '100%'}}/>
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormEditorBank
