import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import { addURL } from 'redux/actions/url';
import ModalActionKpa from 'components/pages/modal/investasi/pengaturan/kpa/kpa'
import ModalInvoiceKpa from 'components/pages/modal/investasi/pengaturan/kpa/invoice'
import { productDefault } from 'dataSource/lookup';

class KPA extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dataMaster: {}
    }

    this.modalActionKpaRef = React.createRef()
    this.modalInvoiceRef = React.createRef()
    this.dataGridRef = React.createRef()

    this.filterItem = [
      {
        dataField: 'produk',
        label: {
          text: 'Produk',
          alignment: "left",
          location: "left"
        },
        editorType: 'dxSelectBox',
        editorOptions: {
          dataSource: {
            store: {
              type: "array",
              data: [
                { id: "1", value: "Program Pensiun Manfaat Pasti" },
              ],
              key: "id"
            }
          },
          valueExpr: "id", // contains the same values as the "statusId" field provides
          displayExpr: "value", // provides display values
          searchEnabled: true,
          deferRendering: false,
          width: '100%',
          onValueChanged: (data) => {
            this.dataGridRef.current.forceRefresh(true)
          }
        }
      },
    ]

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Ubah',
            hint: 'Ubah',
            cssClass: 'text-success',
            onClick: (e) => {
              this.showModalActionKpa('edit', e.row.data)
            }
          },
          {
            text: 'Invoice',
            hint: 'Invoice',
            cssClass: 'text-info',
            onClick: (e) => {
              this.showModalInvoice(e.row.data)
            }
          },
        ],
      },
      {
        dataField: 'id',
        caption: "No.",
        cellRender: (data) => {
          return data.rowIndex + 1
        },
      },
      {
        dataField: 'noKontrak',
        caption: 'No Kontrak',
      },
      {
        dataField: 'productId',
        caption: 'Produk',
        lookup: {
          dataSource: productDefault(this.props.store),
          valueExpr: 'id',
          displayExpr: 'productCode'
        }
      },
      {
        dataField: 'kontraktorName',
        caption: 'Kontraktor',
      },
      {
        dataField: 'kontraktorAddress',
        caption: 'Alamat'
      },
      {
        dataField: 'kontrakDate',
        caption: 'Tanggal Kontrak',
        dataType: 'date',
        format: 'dd MMM yyyy',
      },
      {
        dataField: 'expiredDate',
        caption: 'Tanggal Akhir',
        dataType: 'date',
        format: 'dd MMM yyyy',
      },
      {
        dataField: 'sharingProfitPercent',
        caption: 'Sharing Profit',
        alignment: 'right',
        dataType: 'number',
        format: '#,##0.00',
        editorOptions: {
          format: "#,##0.00",
          showClearButton: true
        }
      },
      {
        dataField: 'kontrakNominalTahun',
        caption: 'Nominal Target Pertahun',
        alignment: 'right',
        dataType: 'number',
        format: '#,##0.00',
        editorOptions: {
          format: "#,##0.00",
          showClearButton: true
        }
      },
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  forceRefresh = () => {
    this.dataGridRef.current.forceRefresh(true)
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          text: 'KPA',
          onClick: (e) => {
            this.showModalActionKpa('add')
          },
        }
      }
    )
  }

  showModalActionKpa = (type, data = null) => {
    this.modalActionKpaRef.current.show()
    this.modalActionKpaRef.current.retrieveData(type, data)
  }

  showModalInvoice = (data) => {
    this.modalInvoiceRef.current.show()
    this.modalInvoiceRef.current.retrieveData(data)
  }
  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Kerjasama Pengelolaan Aset</h2>

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='kpas'
          insertAPI='kpas'
          updateAPI='kpas'
          deleteAPI='kpas'

          backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Aset Alocation"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Aset Alocation'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        <ModalActionKpa
          ref={this.modalActionKpaRef}
          store={this.props.store}
          forceRefresh={this.forceRefresh}
        />

        <ModalInvoiceKpa
          ref={this.modalInvoiceRef}
          store={this.props.store}
          forceRefresh={this.forceRefresh}
        />

      </div>
    )
  }
}

export default KPA