import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalTunjanganLain from 'components/pages/modal/sdm-umum/pengaturan/tunjangan-lain/form'
import FormModalTunjanganMedis from 'components/pages/modal/sdm-umum/pengaturan/tunjangan-medis/form'
import { dasarNilai } from 'dataSource/lookup'
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { confirm } from 'devextreme/ui/dialog'

class TunjanganMedis extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalTunjanganMedis(e)
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : async (e) => {
                            let data = e.row.data
                            let result = await confirm("Apakah anda yakin ingin menghapus data ini?", "Konfirmasi");

                            if (result) {
                                this.delete(data)
                            }
                        }
                    },
                ],
            },
            {
                dataField:"kodeTunjangan",
                caption:"Kode"
            },
            {
                dataField:"namaTunjangan",
                caption:"Tunjangan Medis"
            },
            {
                dataField:"periodeTunjanganPertahun",
                caption:"Periode Tunjangan (Tahun)"
            },
            
            {
                dataField:"maxPengajuanPertahun",
                caption:"Maximum Pengajuan (Tahun)"
            },
            {
                dataField:"dasarNilaiTunjangan",
                caption:"Dasar NIlai Tunjangan",
                lookup:{
                    dataSource: dasarNilai(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dasarNilai', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"dasarNilaiTunjangan",
                caption:"Dasar NIlai Tunjangan",
                alignment:"center",
                columns:[
                    {
                        dataField:"presentaseGajiPokok",
                        caption:"Presentasi Gaji Pokok",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00 %',
                        editorOptions: {
                            format:'#,##0.00 %',
                            showClearButton: true
                        }
                    },
                    {
                        dataField:"presentaseTakeHomePay",
                        caption:"Presentasi Take Home Pay",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00 %',
                        editorOptions: {
                            format:'#,##0.00 %',
                            showClearButton: true
                        }
                    },
                    {
                        dataField:"nominalTunjangan",
                        caption:"Nominal",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00',
                        editorOptions: {
                            format:'#,##0.00',
                            showClearButton: true
                        }
                    },
                ]
            },
            {
                dataField:"status",
                caption:"Status",
                dataType:'boolean'
            },
        ]

        this.showModalTunjanganMedisRef = React.createRef()
        this.dataGridRef = React.createRef()
        
        this.selectedRowKeys = []
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalTunjanganMedis = (e) => {
        if (e.row) {
            this.showModalTunjanganMedisRef.current.retrieveData(e.row.data)
        }
        this.showModalTunjanganMedisRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'trash',
                    text: 'hapus',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin ingin menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: () => {
                                        this.selectedDelete(e)
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ]
                        })
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalTunjanganMedis(e)
                    },
                }
            }
        )
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    delete = async(data) => {
        let id = data.id
        if(id) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'tunjangan-medis', 'DELETE', id)
                this.forceRefresh()
                notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success', 1000);
            } catch (e) {
                notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error', 1000);
            }
        }
    }

    selectedDelete = async () => {
        var errorMessage = []
        if (this.selectedRowKeys.length === 0) {
            notify({message: "Silahkan pilih yang akan di hapus",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',3000);
            return null
        }
        
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'tunjangan-lains', 'DELETE', this.selectedRowKeys[i])
            } catch (e){
                console.log(e);
                errorMessage.push('Sebagian data tidak bisa dihapus, karena sudah digunakan')
            }

        }
        
        if (errorMessage.length > 0) {
            notify({message: errorMessage.join('\n'),width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
            this.forceRefresh()
            return
        }

        this.forceRefresh()
        notify({message: 'Data Berhasil Dihapus',width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Tunjangan Medis</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='tunjangan-medis'
                    insertAPI='tunjangan-medis'
                    updateAPI='tunjangan-medis'
                    deleteAPI='tunjangan-medis' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Tunjangan Medis"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tunjangan Medis'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalTunjanganMedis 
                    ref={this.showModalTunjanganMedisRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default TunjanganMedis