import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { aperd } from '../parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'

class Aperd extends Component {
    constructor(props){
        super(props)

        this.columns = aperd

        this.modalEditorRef = React.createRef()
        this.modalUploadBrokerRef = React.createRef()
        this.datagridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalEditor = (data) => {
        this.modalEditorRef.current.show(data)
    }

    showModalUpload = () => {
        this.modalUploadBrokerRef.current.show()
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">APERD</h2>

                <DevExpressDataGrid
                    ref= {this.datagridRef}
                    loadAPI='aperds'
                    insertAPI='staticdata-wf/aperds'
                    updateAPI='staticdata-wf/aperds'
                    deleteAPI='staticdata-wf/aperds' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    remoteOperations = {false}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Aperd"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aperd Data'}
                    popupWidth={'70vw'} //masukan dalam ukuran pixel
                    popupHeight={'400px'} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    formItems = {this.columns.find(value => value.type === 'form').items}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default Aperd
