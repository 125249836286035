import React, { Component } from 'react'
import { currency, banks, instruments, orders, instrumentsCategoryReksadana, product } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import DetailPengalihanReksadana from './detail'
import FormPengajuanPengalihanReksadana from 'components/pages/modal/investasi/pengajuan/reksadana/pengalihan/index'

class PengajuanPengalihanReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridOrderReksadanaRef = React.createRef()
        this.formPengajuanPengalihanReksadanaRef = React.createRef()
        this.PesetujuanPengalihanReksadana = React.createRef()

        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        onClick: (e) => {
                            this.showModalInsert()
                        }
                    },
                    {
                        text: 'View',
                        hint: 'View',
                        onClick: (e) => {
                            this.showModalView(e.row.data)
                        }
                    },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        onClick: (e) => {
                            // this.showModalFormOrderUploadReksadana()
                        }
                    },
                ]
            },
            {
                dataField: 'noPengajuan',
                caption: 'No Pengajuan',
            },
            {
                dataField: 'tanggalPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalOrder',
                caption: 'Tanggal Order',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup : {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'kategori',
                caption: 'Kategori',
            },
            {
                dataField: 'jumlahInstrumen',
                caption: 'Jumlah Instrumen',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0',
            },
            {
                dataField: 'nilaiPengalihan',
                caption: 'Nilai Pengalihan',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'persetujuan',
                caption: 'Persetujuan',
            },
            {
                dataField: 'statusId',
                caption:'Status',
            },
        ]

        this.currentData = []
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 
    
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan Pengalihan',
                    onClick: (e) => {
                        this.showModalInsert()
                    },
                }
            }
        )
    }

    showModalInsert = () => {
        this.formPengajuanPengalihanReksadanaRef.current.show()
    }

    showModalView = (data) => {
        var dataMaster = {...data}
        var dataPembelian = dataMaster.detail 
        delete dataMaster.detail
        this.formPengajuanPengalihanReksadanaRef.current.show(dataMaster,dataPembelian)
    }

    showModalPersetujuan = () => {
        this.PesetujuanPengalihanReksadana.current.show()
    }

    showModalPersetujuan = (rowData) => {
        this.formPersetujuanPengalihanReksadanaRef.current.show()
        this.formPersetujuanPengalihanReksadanaRef.current.retrieveData({
            noPengajuan: rowData.noPengajuan,
            pengajuanDate: rowData.tanggalPengajuan,
            instrument: rowData.kategori,
            transactionType: 'Pembelian',
            jumlahKomite: 7,
            diSetujui: 3,
            diTolak: 2,
        },rowData)
    }

    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                noPengajuan : '001',
                tanggalPengajuan: '2020-09-14',
                tanggalOrder: '2020-09-14',
                productId: 1151,
                kategori: 'Reksadana',
                jumlahInstrumen: 3,
                nilaiPengalihan: 300000000,
                persetujuan: '3 dari 7',
                statusId: 'Dalam Proses Persetujuan',
                detail: [
                    {
                        id: 1
                    }
                ]
            }
        ]
        this.currentData = JSON.parse(JSON.stringify(dummyData))
        return dummyData
    }

    customDetailElem = (data) => {
        return(
            <DetailPengalihanReksadana detailData = {data.detail} store={this.props.store} />
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengajuan Pengalihan Reksadana</h2>
                <DevExpressDataGrid
                    ref = {this.DataGridOrderReksadanaRef}
                    loadAPI='order-stoks/list?status=0&status=1'
                    insertAPI='order-stoks'
                    updateAPI='order-stoks'
                    deleteAPI='order-stoks' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                    useArraySource = {true}
                    ArraySourceData = {this.loadDummyData}
                    
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Order Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Order Reksadana'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    allowEnabledMasterDetail={true}
                    autoExpandmasterDetail={false}
                    customDetailElem = {this.customDetailElem}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormPengajuanPengalihanReksadana store={this.props.store} ref={this.formPengajuanPengalihanReksadanaRef} />
            </div>
        )
    }
}

export default PengajuanPengalihanReksadana