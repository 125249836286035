import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { currency, banks, instrumentSubCategoryDeposito, instruments, instrumentProperti, rekeningBank } from 'dataSource/lookup';

class DetailPelepasanProperti extends Component {
    constructor(props){
        super(props)
        this.dataSource  = new CustomStore({
            key: 'id',
            load: async(loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `orders/get-by-orderRequestId/${requestId}`
                var responseOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                let result = []
                for(var dataOrder of responseOrder){
                    var responseProperti = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-propertis/findByOrderId/${dataOrder.id}`, 'GET')
                    var allData = {
                        ...dataOrder,
                        ...responseProperti
                    }
                    result.push(allData)
                }
                console.log(result)
                return result
            },
            insert: (values) => {
            },
            update: (key,values) => {
               
            },
            remove: (key) => {
               
            }
        })

        this.columns = [
            {
                dataField: 'instrumentId',
                caption: 'Nama Instrument',
                lookup: {
                    dataSource: instrumentProperti(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.instrumentCode + ' - ' + e.instrumentName
                    }
                },
            },
            {
                dataField: 'priceType',
                caption: 'Tipe Harga',
            },
            {
                dataField: 'priceMin',
                caption: 'Harga Minimum',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'priceMax',
                caption: 'Harga Maksimum',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'qtyOrder',
                caption: 'Jumlah Unit',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0',
            },
            {
                dataField: 'comission',
                caption: 'Fee',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'nominalComission',
                caption: 'Nominal Fee',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'totalPelepasan',
                caption: 'Total Pelepasan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                calculateCellValue: (e) => {
                    let result = (e.priceMax * e.qtyOrder) + e.nominalComission
                    return result
                }
            },
            {
                dataField: 'rekBankId',
                caption: 'Rekening Bayar',
                lookup: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    }
                }
            },
        ]
    }

    render() {
        return (
            <DataGrid
            id={"successDataGrid"}
            showBorders={false}
            dataSource={this.dataSource}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            // height={100}
            showScrollbar={"always"}
            // columns={this.columns}
            >
            {
                this.columns.map((column) => {
                    return <Column 
                        dataField={column.dataField} 
                        caption={column.caption} 
                        lookup={column.lookup} 
                        cellRender={column.cellRender} 
                        alignment={column.alignment || 'left'} 
                        cssClass={column.cssClass}
                        format = {column.format}
                        dataType = {column.dataType}
                        width = {column.width}
                        type = {column.type}
                        buttons = {column.buttons}
                        editorType = {column.editorType}
                        editorOptions = {column.editorOptions}
                        visible = {column.visible}
                        sortOrder = {column.sortOrder}
                        allowEditing = {column.allowEditing}
                        calculateCellValue = {column.calculateCellValue}
                    />
                })
            }
            {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPelepasanProperti