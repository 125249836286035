import React, { Component } from 'react'
import { ScrollView,Popup,Form } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import notify from "devextreme/ui/notify";

class FormModalGenerateAccruedInterest extends Component {
    constructor(props){
        super(props)
        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            DataMaster: {
                tanggalTransaksi:new Date(this.systemDate ? this.systemDate.dateValue : null),
            },
            newData:false,
        }

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: this.prosesData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField:'lastTransaction',
                label:{
                    text:'Generate Accrued Terakhir'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                    readOnly: true
                }
            },
            {
                dataField:'tanggalTransaksi',
                label:{
                    text:'Generate Accrued saat ini'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick: true,
                    useMaskBehavior: true,
                    readOnly: true
                }
            },
            {
                itemType: 'group',
                caption:'Status Generated Accrued Interest',
                items: [
                    {
                        dataField: 'deposito',
                        label: {
                            text: 'Deposito'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'sbn',
                        label: {
                            text: 'SBN'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'obligasiKor',
                        label: {
                            text: 'Obligasi Korporasi'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'eba',
                        label: {
                            text: 'EBA'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'mtn',
                        label: {
                            text: 'MTN'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                ]
            },
        ]

        this.resultGenerate = {}

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
    }

    generateAccrued = async () => {
        var loadAPI = `transaction/get-status-generated-each-category-by-transDate/${formatDate(this.state.DataMaster.tanggalTransaksi)}`

        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadAPI,
            "GET"
        );
        
        this.resultGenerate = response
        var DataMaster = {...this.state.DataMaster}
        if (this.resultGenerate.depositoTotal == 0) {
            DataMaster.deposito = "Tidak Ada"
        }else if (this.resultGenerate.depositoGenerated == this.resultGenerate.depositoTotal) {
            DataMaster.deposito = "Sudah di Generate"
        }else{
            DataMaster.deposito = "Belum di Generate"
        }
        
        if (this.resultGenerate.ebaTotal == 0) {
            DataMaster.eba = "Tidak Ada"
        }else if (this.resultGenerate.ebaGenerated == this.resultGenerate.ebaTotal) {
            DataMaster.eba = "Sudah di Generate"
        }else{
            DataMaster.eba = "Belum di Generate"
        }

        if (this.resultGenerate.obligasiKorpTotal == 0) {
            DataMaster.obligasiKor = "Tidak Ada"
        }else if (this.resultGenerate.obligasiKorpGenerated == this.resultGenerate.obligasiKorpTotal) {
            DataMaster.obligasiKor = "Sudah di Generate"
        }else{
            DataMaster.obligasiKor = "Belum di Generate"
        }

        if (this.resultGenerate.sbnTotal == 0) {
            DataMaster.sbn = "Tidak Ada"
        }else if (this.resultGenerate.sbnGenerated == this.resultGenerate.sbnTotal) {
            DataMaster.sbn = "Sudah di Generate"
        }else{
            DataMaster.sbn = "Belum di Generate"
        }

        if (this.resultGenerate.mtnTotal == 0) {
            DataMaster.mtn = "Tidak Ada"
        }else if (this.resultGenerate.mtnGenerated == this.resultGenerate.mtnTotal) {
            DataMaster.mtn = "Sudah di Generate"
        }else{
            DataMaster.mtn = "Belum di Generate"
        }
        this.setState({
            DataMaster:DataMaster
        })

        return response
    }

    prosesData = async() => {
        try {
            if(this.state.DataMaster.deposito == "Belum di Generate" || this.state.DataMaster.eba == "Belum di Generate" || this.state.DataMaster.obligasiKor == "Belum di Generate" 
                || this.state.DataMaster.sbn == "Belum di Generate" || this.state.DataMaster.mtn == "Belum di Generate"){

                var loadAPI = `transaction/generate-transaksi-accrued-interest`

                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,loadAPI,"POST",{
                    values: {}
                });
                if (response) {
                    notify({ message: 'Proses Accrued Sukses!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);  

                    this.hide()
                    this.props.forceRefresh()
                }
            }else{
                notify({ message: 'Beberapa Sudah di Generate / Tidak Tersedia!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
            }
        } catch (e) {
            console.log(e.response)
            notify({ message: `Terjadi Kesalahan System ( ${e} )`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    newData = (newData) => {
        this.setState({
          newData : newData
        })
    }

    retrieveData = (data) => {
        
        this.setState({
            DataMaster: data,
        })
    }

    show = async() => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters/get-by-paramCode/0 SYSDATE') 
        this.setState({
            DataMaster: {
                tanggalTransaksi: response.dateValue
            },
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {
                tanggalTransaksi:new Date(this.systemDate ? this.systemDate.dateValue : null),
                deposito:null,
                eba: null,
                mtn:  null,
                sbn: null,
                obligasiKor: null
            }
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'45vw'}
                    height="40vh"
                    title={`Generate Accrued Interest`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={1}
                            id={'dataMaster'}
                            formData={this.state.DataMaster}
                            items={this.dataMaster}
                            labelLocation={"left"}  
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalGenerateAccruedInterest