import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import {sysGroup} from 'dataSource/lookup'
import ModalUser from './modalUser';
import { confirm, alert } from 'devextreme/ui/dialog';

class User extends Component {
    constructor(props){
        super(props)

        this.modalUserRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Reset Password',
                        hint : 'Reset Password',
                        onClick : (e) => {
                            var dataUser = this.props.store.getState().sessionRole.find(value => value === 'ADMIN')

                            if(dataUser){
                                this.modalUserRef.current.show();
                                this.modalUserRef.current.retrieveData(e.row.data);
                            }else{
                                alert('Halaman ini hanya bisa diakses oleh ADMIN', 'Error');
                            }
                            
                        }
                    }
            ],
            },
            {
                dataField: 'login',
                caption: 'Username',
            },
            {
                dataField: 'firstName',
                caption: 'First Name',
            },
            {
                dataField: 'lastName',
                caption: 'Last Name',
            },
            {
                dataField: 'email',
                caption: 'Email',
                validationRules: [
                    {
                        type: 'required',
                        message: 'Email tidak boleh kosong'
                    },
                    {
                        type: 'custom',
                        validationCallback : (e) => {
                            console.log(e);
                            var regexPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                            if(regexPattern.test(e.value)){
                                return true
                            }
                            return false
                        },
                        message: 'Format email salah'
                    }
                ]
            },
            {
                dataField: 'sysGroups',
                caption: 'Role',
                cellRender: (cellData) => {
                    return cellData.data.sysGroups.join(', ')
                },
                editorType: 'dxTagBox',
                editorOptions: {
                    dataSource: sysGroup(this.props.store),
                    valueExpr: 'groupCode',
                    displayExpr: 'groupName',
                }
            },
            {
                dataField: 'workGroup',
                caption: 'Workflow Group',
                editorType: 'dxTagBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            groupName: 'Komite'
                        },
                        {
                            id: 2,
                            groupName: 'Direktur'
                        },
                        {
                            id: 3,
                            groupName: 'Maker'
                        },
                        {
                            id: 4,
                            groupName: 'Checker'
                        },
                        {
                            id: 5,
                            groupName: 'Approval'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'groupName',
                }
            },
            {
                dataField: 'activated',
                caption: 'Status',
                dataType: 'boolean'
            },
        ]

        this.columnDefaultValue = [
            {
                dataField: 'activated',
                value: false,
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onRowInserting = (e) => {
        var data = e.data
        data.password = 'Dapen123$'

        e.cancel = false
    }

    refreshDataGrid = () => {
        this.dataGridRef.current.forceRefresh()
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pengguna</h2>

                <DevExpressDataGrid
                    loadAPI='users?size=9999'
                    insertAPI='register'
                    updateAPI='users'
                    deleteAPI='users' 

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"User"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Pengguna'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onRowInserting = {this.onRowInserting}
                    columnDefaultValue = {this.columnDefaultValue}

                    // remoteOperations = {true}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                    ref={this.dataGridRef}
                />

                <ModalUser ref={this.modalUserRef} store={this.props.store} refreshDataGrid={this.refreshDataGrid}/>
            </div>
        )
    }
}

export default User
