import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { coa, currency, dc, departments, journalType, product, statusJournal } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class ModalEditJurnalReccuring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            DataMaster: {}
        };

        this.columns = [
            {
                itemType: 'group',
                name: 'masterGroup',
                colCount: 3,
                items: [
                    {
                        dataField: 'journalCode',
                        label: {
                            text: 'Kode Jurnal'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        type: 'empty',
                        name: 'empty1'
                    },
                    {
                        type: 'empty',
                        name: 'empty2'
                    },
                    {
                        dataField: 'createdDate',
                        label: {
                            text: 'Tanggal Input'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                        },
                        visible: false
                    },
                    {
                        dataField: 'postedCount',
                        label: {
                            text: 'Jumlah Posting'
                        },
                        editorType: 'dxNumberBox',
                        visible: false
                    },
                    {
                        dataField: 'journalDesc',
                        colSpan: 3,
                        label: {
                            text: 'Deskripsi'
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {
                            width: '100%'
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please Fill Journal Description"
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Pengaturan Pengulangan',
                name: 'recurrenceSetting',
                colCount: 3,
                cssClass: 'border border-bottom-0 pt-3 px-3 mt-2',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'dateStart',
                                label: {
                                    text: 'Tanggal Mulai'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Mohon pilih tanggal mulai"
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        dataField: 'endModel',
                        label: {
                            text: 'Parameter Berhenti'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:
                        {
                            dataSource: [
                                {
                                    id: 'N',
                                    value: 'Tidak berhenti'
                                },
                                {
                                    id: 'A',
                                    value: 'Tanggal Berhenti'
                                },
                                {
                                    id: 'B',
                                    value: 'Jumlah Berhenti'
                                }
                            ],
                            valueExpr: 'id',
                            displayExpr: "value",
                            onValueChanged: (e) => {
                                if (this.state.popupVisible) {
                                    var data = e.value
                                    this.formRef.current.instance.beginUpdate()
                                    if (data == 'A') {
                                        this.formRef.current.instance.itemOption('recurrenceSetting.dateEnd', 'visible', true)
                                        this.formRef.current.instance.itemOption('recurrenceSetting.endOccurences', 'visible', false)
                                    }
                                    else if (data == 'B') {
                                        this.formRef.current.instance.itemOption('recurrenceSetting.dateEnd', 'visible', false)
                                        this.formRef.current.instance.itemOption('recurrenceSetting.endOccurences', 'visible', true)
                                    } else {
                                        this.formRef.current.instance.itemOption('recurrenceSetting.dateEnd', 'visible', false)
                                        this.formRef.current.instance.itemOption('recurrenceSetting.endOccurences', 'visible', false)
                                    }
                                    this.formRef.current.instance.endUpdate()

                                    return data
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Mohon pilih salah satu Parameter Berhenti"
                            }
                        ]
                    },
                    {
                        dataField: 'dateEnd',
                        label: {
                            text: 'Tanggal Berhenti',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            min: new Date(),
                            openOnFieldClick: true
                        },
                        visible: false,
                        validationRules: [
                            {
                                type: "required",
                                message: "Mohon isi tanggal berhenti"
                            }
                        ]
                    },
                    {
                        dataField: 'endOccurences',
                        label: {
                            text: 'Jumlah Perulangan',
                        },
                        editorType: 'dxNumberBox',
                        visible: false,
                        validationRules: [
                            {
                                type: "required",
                                message: "Mohon isi jumlah perulangan"
                            },
                            {
                                type: "range",
                                message: "Jumlah Perulangan harus lebih dari 0",
                                min: 1
                            }
                        ]
                    },
                ],
            },
            {
                itemType: 'group',
                name: 'recurrencePattern',
                caption: 'Pola Pengulangan',
                colCount: 3,
                cssClass: 'border border-top-0 pb-3 px-3 mb-2',
                colSpan: 3,
                items: [
                    {
                        dataField: 'recurModel',
                        label: {
                            text: 'Periode Pengulangan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:
                        {
                            dataSource: [
                                {
                                    id: 'D',
                                    value: 'Harian'
                                },
                                {
                                    id: 'M',
                                    value: 'Bulanan'
                                },
                                {
                                    id: 'Y',
                                    value: 'Tahunan'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: "value",
                            onValueChanged: (e) => {
                                if (this.state.popupVisible) {
                                    var data = e.value
                                    this.formRef.current.instance.beginUpdate()
                                    if (data == 'D') {
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurSpecificDays', 'visible', false)
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurMonthInterval', 'visible', false)
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurnSpecificDate', 'visible', false)
                                    }
                                    else if (data == 'M') {
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurSpecificDays', 'visible', true)
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurMonthInterval', 'visible', true)
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurnSpecificDate', 'visible', false)
                                    } else {
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurSpecificDays', 'visible', false)
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurMonthInterval', 'visible', false)
                                        this.formRef.current.instance.itemOption('recurrencePattern.recurnSpecificDate', 'visible', true)
                                    }
                                    this.formRef.current.instance.endUpdate()

                                    return data
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please Choose Recurrence Model"
                            }
                        ]
                    },
                    {
                        dataField: 'recurnSpecificDate',
                        label: {
                            text: 'Diulang setiap tanggal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM',
                            useMaskBehavior: true,
                            min: new Date(),
                            openOnFieldClick: true
                        },
                        visible: false,
                        validationRules: [
                            {
                                type: "required",
                                message: "Mohon isi tanggal pengulangan"
                            }
                        ]
                    },
                    {
                        dataField: 'recurMonthInterval',
                        label: {
                            text: 'Bulan Pengulangan'
                        },
                        editorType: 'dxNumberBox',
                        visible: false,
                        validationRules: [
                            {
                                type: "required",
                                message: "Mohon isi Bulan Pengulangan"
                            },
                            {
                                type: "range",
                                message: "Bulan Pengulangan harus lebih dari 0",
                                min: 1
                            }
                        ]
                    },
                    {
                        dataField: 'recurSpecificDays',
                        label: {
                            text: 'Tanggal Spesifik'
                        },
                        editorType: 'dxNumberBox',
                        visible: false,
                        validationRules: [
                            {
                                type: "required",
                                message: "Mohon isi Tanggal Spesifik"
                            },
                            {
                                type: "range",
                                message: "Tanggal Spesifik harus bernilai 1 sampai 31",
                                min: 1,
                                max: 31
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 3,
                items: [
                    {
                        dataField: 'debet',
                        label: {
                            text: 'Total Debet'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'credit',
                        label: {
                            text: 'Total Kredit'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        }
                    },
                    {
                        editorType: 'dxButton',
                        editorOptions: {
                            text: 'Calculate Forex Balancing',
                            type: 'success'
                        }
                    },
                    {
                        dataField: 'status',
                        label: {
                            text: 'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'A',
                                    value: 'Aktif'
                                },
                                {
                                    id: 'N',
                                    value: 'Tidak Aktif'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: "value",
                        },
                        visible: false
                    },
                ]
            }
        ]

        this.exchangeColumn = [
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                },
                allowEditing: false
            },
            {
                dataField: 'dealExrateModel',
                caption: 'Model Konversi',
                allowEditing: false
            },
            {
                dataField: 'exrate',
                caption: 'Nilai Tukar',
                allowEditing: false,
                format: '#,##0.00',
            },
            {
                dataField: 'dealExrate',
                caption: 'Nilai Tukar Disepakati',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
            {
                dataField: 'debet',
                caption: 'Debet',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                allowEditing: false
            },
            {
                dataField: 'credit',
                caption: 'Kredit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                allowEditing: false
            },
        ]
        this.journalColumn = [
            {
                dataField: 'coaId',
                caption: 'COA',
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    }, // provides display values
                },
                editorOptions: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    },
                    searchEnabled: true,
                    wrapItemText: true
                },
                width: 350
                // setCellValue : async (newData, value, currentRowData) => {
                //     newData.coaId = value
                //     var dataCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coas/${value}`,'GET')
                //     newData.coaName = dataCoa.coaName
                // }
            },
            // {
            //     dataField: 'coaName',
            //     caption: 'Nama COA',
            //     allowEditing: false
            // },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                },
            },
            {
                dataField: 'produkId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField: 'departmentId',
                caption: 'Departmen',
                lookup: {
                    dataSource: departments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'departmentName',
                },
            },
            {
                dataField: 'dc',
                caption: 'D/C',
                lookup: {
                    dataSource: dc(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                setCellValue: (newData, value, currentRowData) => {
                    newData.dc = value
                    var amount = currentRowData.amount || 0
                    var amountBasedCurrency = currentRowData.amountBasedCurrency || 0
                    if (value === 'D') {
                        newData.debet = amount
                        newData.credit = 0
                        newData.baseDebet = amountBasedCurrency
                        newData.baseCredit = 0
                    } else {
                        newData.debet = 0
                        newData.credit = amount
                        newData.baseDebet = 0
                        newData.baseCredit = amountBasedCurrency
                    }
                }
            },
            {
                dataField: 'projectId',
                caption: 'Proyek',
            },
            {
                dataField: 'amount',
                caption: 'Jumlah',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                setCellValue: async (newData, value, currentRowData) => {
                    if (currentRowData.dc) {
                        var journalExrateData = this.journalExrateRef.current.getDataSource().find(value => value.currencyId === currentRowData.currencyId)

                        var rate = 1
                        if (journalExrateData) {
                            rate = journalExrateData.dealExrate
                        }

                        newData.amountBasedCurrency = value * rate

                        newData.amount = value
                        if (currentRowData.dc === 'D') {
                            newData.debet = value
                            newData.credit = 0
                            newData.baseDebet = value * rate
                            newData.baseCredit = 0
                        } else {
                            newData.debet = 0
                            newData.credit = value
                            newData.baseDebet = 0
                            newData.baseCredit = value * rate
                        }
                    } else {
                        newData.amount = null
                        let type = 'error'
                        let text = 'Please select debit/credit!'

                        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    }


                }
            },
            {
                dataField: 'debet',
                caption: 'Debet',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'credit',
                caption: 'Kredit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'baseDebet',
                caption: 'Debet (IDR)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'baseCredit',
                caption: 'Kredit (IDR)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
        ]
        this.sumExchangeRate = [
            {
                showInColumn: 'exrate',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'debet',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'debet',
                displayFormat: '{0}'
            },
            {
                column: 'credit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'credit',
                displayFormat: '{0}'
            }
        ]
        this.sumJurnalInfo = [
            {
                showInColumn: 'projectId',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'debet',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'debet',
                displayFormat: '{0}'
            },
            {
                column: 'credit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'credit',
                displayFormat: '{0}'
            },
            {
                column: 'baseDebet',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'debet',
                displayFormat: '{0}'
            },
            {
                column: 'baseCredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'credit',
                displayFormat: '{0}'
            }
        ]

        this.journalDetailData = []
        this.journalExrateData = []

        this.popupRef = React.createRef()
        this.formRef = React.createRef()
        this.journalDetailRef = React.createRef()
        this.journalExrateRef = React.createRef()
    }

    retrieveData = async (data) => {
        this.formRef.current.instance.beginUpdate()

        this.formRef.current.instance.itemOption('masterGroup.empty1', 'visible', false)
        this.formRef.current.instance.itemOption('masterGroup.empty2', 'visible', false)
        this.formRef.current.instance.itemOption('masterGroup.createdDate', 'visible', true)
        this.formRef.current.instance.itemOption('masterGroup.postedCount', 'visible', true)

        this.formRef.current.instance.endUpdate()
        this.formRef.current.instance.updateData(data)

        this.journalExrateRef.current.forceRefresh(true)
        this.journalDetailRef.current.forceRefresh(true)

    }

    loadDataDetailAndExrate = async (journalRecurringId) => {
        var journalDetailResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-recurring-detil/getAllByJournalRecurringId/${journalRecurringId}`)

        var coaIdList = journalDetailResponse.map(value => value.coaId)

        coaIdList = [...new Set(coaIdList)]

        var coaList = []

        for (var coaId of coaIdList) {
            var dataCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coas/${coaId}`)
            coaList.push(dataCoa)
        }

        this.journalDetailData = journalDetailResponse.map(response => {
            var selectedCoa = coaList.find(value => value.id === response.coaId)

            response.coaName = selectedCoa.coaName

            if (response.dc === 'D') {
                response.debet = response.amount
                response.credit = 0
                response.baseDebet = response.amountBasedCurrency
                response.baseCredit = 0
            } else {
                response.debet = 0
                response.credit = response.amount
                response.baseDebet = 0
                response.baseCredit = response.amountBasedCurrency
            }

            return response
        })

        var journalExrateResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-recurring-exrates/getAllByJournalRecurringId/${journalRecurringId}`)

        this.journalExrateData = journalExrateResponse.map(value => {
            delete value.journalHeader

            return value
        })
    }

    loadDataDetail = async () => {
        return this.journalDetailData
    }

    loadDataExrate = async () => {
        return this.journalExrateData
    }

    show = async () => {
        if (this.props.type === 'add') {
            var date = new Date(formatDate(new Date())).toISOString()
            var journalCode = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-recurring/generateCode/${date}`, 'GET')
            // var journalCode = {
            //     Code : 'RE-001-20200831-01'
            // }

            this.setState({
                popupVisible: true,
                DataMaster: {
                    journalCode: journalCode,
                    valueDate: formatDate(new Date())
                }
            });

            var toolbarItem = [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan",
                        onClick: () => { this.submit() }
                    },
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Batal",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                }
            ];

            this.popupRef.current.instance.option('toolbarItems', toolbarItem)
            this.formRef.current.instance.option('readOnly', false)
        } else {
            this.setState({
                popupVisible: true,
            });

            var toolbarItem = [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Batal",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                }
            ];

            this.popupRef.current.instance.option('toolbarItems', toolbarItem)
            this.formRef.current.instance.option('readOnly', true)
        }
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });

        this.journalDetailData = []
        this.journalExrateData = []
    };

    onRowUpdatedJournalExrate = (e) => {
        this.setRateJournalDetail(e.data.currencyId)
    }


    onRowInsertedJournalDetail = async (e) => {
        this.calculateTotalDebetCredit()
        await this.setExrateData()
        this.setRateJournalDetail(e.data.currencyId)
    }

    onRowUpdatedJournalDetail = async (e) => {
        this.calculateTotalDebetCredit()
        await this.setExrateData()
        var dataJournalDetails = this.journalDetailRef.current.getDataSource().find(value => value.id === e.key)
        this.setRateJournalDetail(dataJournalDetails.currencyId)
    }

    onRowRemovedJournalDetail = (e) => {
        this.calculateTotalDebetCredit()
        this.setExrateData()
    }

    forexBalancing = async () => {
        var baseCurrencyId = this.props.store.getState().getParam.find(value => value.paramCode === '9 BASECCY')

        if (baseCurrencyId) {
            baseCurrencyId = baseCurrencyId.intValue

            var exrateDatas = this.journalExrateRef.current.getDataSource().filter(value => value.currencyId !== baseCurrencyId)

            var journalBalancingData = []
            for (var exrateData of exrateDatas) {
                var forexBalancingConfigs = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'forex-balancing-configs')
                if (exrateData.debet - exrateData.credit !== 0) {
                    for (var forexBalancingConfig of forexBalancingConfigs) {
                        var dataCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coas/${forexBalancingConfig.coaId}`, 'GET')

                        var dc, amount, amountBasedCurrency, debet, credit, baseDebet, baseCredit

                        if (forexBalancingConfig.type === 1) {
                            dc = exrateData.debet - exrateData.credit < 0 ? 'D' : 'C'
                            amount = Math.abs(exrateData.debet - exrateData.credit)
                            amountBasedCurrency = Math.abs(exrateData.debet - exrateData.credit) * exrateData.dealExrate
                            debet = dc === 'D' ? amount : 0
                            credit = dc === 'C' ? amount : 0
                            baseDebet = dc === 'D' ? amountBasedCurrency : 0
                            baseCredit = dc === 'C' ? amountBasedCurrency : 0
                        } else {
                            dc = exrateData.debet - exrateData.credit > 0 ? 'D' : 'C'
                            amount = Math.abs(exrateData.debet - exrateData.credit) * exrateData.dealExrate
                            amountBasedCurrency = amount
                            debet = dc === 'D' ? amount : 0
                            credit = dc === 'C' ? amount : 0
                            baseDebet = dc === 'D' ? amountBasedCurrency : 0
                            baseCredit = dc === 'C' ? amountBasedCurrency : 0
                        }

                        journalBalancingData.push({
                            type: "insert",
                            data: {
                                coaId: forexBalancingConfig.coaId,
                                coaName: dataCoa.coaName,
                                currencyId: forexBalancingConfig.type === 1 ? forexBalancingConfig.currencyId : baseCurrencyId,
                                departmentId: null,
                                dc: dc,
                                projectId: null,
                                amount: amount,
                                amountBasedCurrency: amountBasedCurrency,
                                debet: debet,
                                credit: credit,
                                baseDebet: baseDebet,
                                baseCredit: baseCredit,
                            }
                        })
                    }
                }
            }

            this.journalDetailRef.current.push(journalBalancingData)
            this.calculateTotalDebetCredit()
            this.setExrateData()
        } else {
            let type = 'error'
            let text = 'Please Set Base Currency at Global Param !'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        }
    }

    calculateTotalDebetCredit = () => {
        var journalDetailData = this.journalDetailRef.current.getDataSource()

        var DataMaster = {}
        DataMaster.debet = 0
        DataMaster.credit = 0

        if (journalDetailData.length > 0) {
            DataMaster.debet = journalDetailData.map(value => value.debet).reduce((a, b) => a + b).toFixed(2)
            DataMaster.credit = journalDetailData.map(value => value.credit).reduce((a, b) => a + b).toFixed(2)
        }
        this.formRef.current.instance.updateData(DataMaster)
    }

    setExrateData = async () => {
        var journalDetailData = this.journalDetailRef.current.getDataSource()
        var journalExrateData = this.journalExrateRef.current.getDataSource()
        var data = []

        if (journalDetailData.length > 0) {
            var existingExrateCurrencyIds = journalExrateData.map(value => value.currencyId)
            var existingDetailCurrencyIds = journalDetailData.map(value => value.currencyId)

            var newCurrencyIds = journalDetailData.map(value => {
                if (!existingExrateCurrencyIds.includes(value.currencyId)) {
                    return value.currencyId
                } else {
                    return false
                }
            }).filter(value => value)



            var deletedCurrencyIds = existingExrateCurrencyIds.filter(value => !existingDetailCurrencyIds.includes(value))

            newCurrencyIds = [...new Set(newCurrencyIds)]

            for (var currencyId of newCurrencyIds) {
                var dataCurrency = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${currencyId}`, 'GET')
                var totalDebit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.debet : 0 }).reduce((a, b) => a + b)
                var totalCredit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.credit : 0 }).reduce((a, b) => a + b)

                data.push({
                    type: "insert",
                    data: {
                        currencyId: currencyId,
                        dealExrateModel: null,
                        exrate: dataCurrency.rate || 1,
                        dealExrate: dataCurrency.rate || 1,
                        debet: totalDebit,
                        credit: totalCredit,
                    }
                })

                var detailData = []
                var selectedDetailData = journalDetailData.filter(value => value.currencyId === currencyId)

                for (var selectedDetail of selectedDetailData) {
                    detailData.push({
                        type: "update",
                        data: {
                            baseDebet: selectedDetail.debet * (dataCurrency.dealExrate || 1),
                            baseCredit: selectedDetail.credit * (dataCurrency.dealExrate || 1),
                        },
                        key: selectedDetail.id
                    })
                }

                this.journalDetailRef.current.push(detailData)
            }

            for (var currencyId of existingExrateCurrencyIds) {
                var totalDebit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.debet : 0 }).reduce((a, b) => a + b)
                var totalCredit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.credit : 0 }).reduce((a, b) => a + b)

                if (totalDebit !== 0 || totalCredit !== 0) {
                    var selectedJournalExrate = journalExrateData.find(value => value.currencyId === currencyId)
                    data.push({
                        type: "update",
                        data: {
                            debet: totalDebit,
                            credit: totalCredit,
                        },
                        key: selectedJournalExrate.id
                    })
                }
            }

            for (var currencyId of deletedCurrencyIds) {
                var selectedJournalExrate = journalExrateData.find(value => value.currencyId === currencyId)

                data.push({
                    type: "remove",
                    key: selectedJournalExrate.id
                })
            }
        } else {
            for (var journalExrate of journalExrateData) {
                data.push({
                    type: "remove",
                    key: journalExrate.id
                })
            }
        }

        this.journalExrateRef.current.push(data)
    }

    setRateJournalDetail = (currencyId) => {
        var journalDetailData = this.journalDetailRef.current.getDataSource()
        var journalExrateData = this.journalExrateRef.current.getDataSource()

        var filteredCurrencyExrate = journalExrateData.find(value => value.currencyId === currencyId)

        if (filteredCurrencyExrate) {
            var filteredCurrencyDetail = journalDetailData.filter(value => value.currencyId === filteredCurrencyExrate.currencyId)

            var data = []
            for (var filteredDetail of filteredCurrencyDetail) {
                data.push({
                    type: "update",
                    data: {
                        amountBasedCurrency: filteredDetail.amount * filteredCurrencyExrate.dealExrate,
                        baseDebet: filteredDetail.debet * filteredCurrencyExrate.dealExrate,
                        baseCredit: filteredDetail.credit * filteredCurrencyExrate.dealExrate,
                    },
                    key: filteredDetail.id
                })
            }

            this.journalDetailRef.current.push(data)
        }
    }

    submit = async () => {
        var formValidation = this.formRef.current.instance.validate()

        if (!formValidation.isValid) {
            let type = 'error'
            let text = formValidation.brokenRules.map(value => value.message).join("\n")

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window }, closeOnOutsideClick: true }, type);

            return null
        }
        var journalHeaderData = this.state.DataMaster
        var journalDetailData = this.journalDetailRef.current.getDataSource()
        var journalExrateData = this.journalExrateRef.current.getDataSource()

        if (this.state.DataMaster.journalType) {
            let type = 'error'
            let text = 'Please select journal type !'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

            return null
        }

        if (journalDetailData.length === 0) {
            let type = 'error'
            let text = 'Journal Not Found, please insert journal !'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

            return null
        }

        if (this.state.DataMaster.debet !== this.state.DataMaster.credit) {
            let type = 'error'
            let text = 'Total Debet and Credit not balance!'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

            return null
        }

        journalHeaderData.status = 'A'

        if (journalHeaderData.id) {
            var journalHeaderResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-recurrings', 'PUT', {
                values: journalHeaderData
            }, journalHeaderData)

            if (journalHeaderResponse) {
                for (var journalDetail of journalDetailData) {
                    var journalDetailResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-recurring-detils', 'PUT', {
                        values: journalDetail
                    }, journalDetail)
                }

                for (var journalExrate of journalExrateData) {
                    var journalExrateResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-recurring-exrates', 'PUT', {
                        values: journalExrate
                    }, journalExrate)
                }
            }
        } else {
            var journalHeaderResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-recurrings', 'POST', {
                values: journalHeaderData
            })

            if (journalHeaderResponse) {
                for (var journalDetail of journalDetailData) {
                    var dataPost = {
                        amount: journalDetail.amount,
                        amountBasedCurrency: journalDetail.amountBasedCurrency,
                        coaId: journalDetail.coaId,
                        currencyId: journalDetail.currencyId,
                        dc: journalDetail.dc,
                        departmentId: journalDetail.departmentId,
                        journalDesc: journalHeaderResponse.journalDesc,
                        journalRecurringId: journalHeaderResponse.id,
                        produkId: journalDetail.produkId,

                    }

                    var journalDetailResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-recurring-detils', 'POST', {
                        values: dataPost
                    })
                }

                for (var journalExrate of journalExrateData) {
                    var dataPost = {
                        credit: journalExrate.credit,
                        debet: journalExrate.debet,
                        currencyId: journalExrate.currencyId,
                        dealExrate: journalExrate.dealExrate,
                        exrate: journalExrate.exrate,
                        journalRecurringId: journalHeaderResponse.id,

                    }

                    var journalExrateResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-recurring-exrates', 'POST', {
                        values: dataPost
                    })
                }
            }
        }

        this.props.forceRefresh()

        this.hide()
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    ref={this.popupRef}
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Form Jurnal Berulang"}
                    width={"90vw"}
                    height={"auto"}
                //   toolbarItems={this.getToolbarItem()}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={"formMaster"}
                                    formData={this.state.DataMaster}
                                    items={this.columns}
                                    ref={this.formRef}
                                    labelLocation={'left'}
                                />
                            </div>
                            <div className="col-md-6">
                                <h6>Currency Exchange Rate</h6>
                                <DevExpressDataGrid
                                    ref={this.journalExrateRef}
                                    menuRightClick={false}

                                    loadAPI='exchange-rates'
                                    insertAPI='exchange-rates'
                                    updateAPI='exchange-rates'
                                    deleteAPI='exchange-rates'

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataExrate}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={true}

                                    editingMode="cell"

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={true}
                                    selection={"none"}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={284}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    // FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.exchangeColumn} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}

                                    // summaryTotalItem={this.sumExchangeRate}

                                    onRowUpdated={this.onRowUpdatedJournalExrate}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h6 className="mt-3 mb-0">Informasi Detail Jurnal</h6>
                                <DevExpressDataGrid
                                    ref={this.journalDetailRef}
                                    menuRightClick={false}

                                    loadAPI='journal-details'
                                    insertAPI='journal-details'
                                    updateAPI='journal-details'
                                    deleteAPI='journal-details'

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataDetail}

                                    allowAdding={this.props.type === 'add' ? true : false}
                                    allowDeleting={this.props.type === 'add' ? true : false}
                                    allowUpdating={this.props.type === 'add' ? true : false}

                                    editingMode="row"

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={true}
                                    selection={"none"}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={'calc(100vh - 660px)'}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    // FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.journalColumn} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}

                                    summaryTotalItem={this.sumJurnalInfo}

                                    onRowInserted={this.onRowInsertedJournalDetail}
                                    onRowUpdated={this.onRowUpdatedJournalDetail}
                                    onRowRemoved={this.onRowRemovedJournalDetail}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>

        );
    }
}

export default ModalEditJurnalReccuring