import React, { Component } from 'react'
import Popup from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { months } from 'dataSource/lookup'

class ViewPenerimaBonus extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{}
        }

        this.DataMaster = [
            {
                itemType:"group",
                colCount:1,
                items:[
                    {
                        dataField:"nik",
                        label:{
                            text:"NIK",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"nama",
                        label:{
                            text:"Nama",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"jabatan",
                        label:{
                            text:"Jabatan",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"unit",
                        label:{
                            text:"Unit",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"cabang",
                        label:{
                            text:"Cabang",
                            alignment:"left"
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"noRek",
                        label:{
                            text:"No. Rekening",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"namaRek",
                        label:{
                            text:"Nama Rekening",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"bank",
                        label:{
                            text:"Bank",
                            alignment:"left"
                        }
                    },
                ]
            },
            {
                itemType:"group",
                colCount:1,
                caption:"Bonus",
                items:[
                    {
                        dataField:"tipeBonus",
                        label:{
                            text:"Tipe Bonus",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"tahunAnggaran",
                        label:{
                            text:"Tahun Anggaran",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"periodeBulan",
                        label:{
                            text:"Periode Bulan",
                            alignment:"left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: months(),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "value", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:"persentaseBonus",
                        label:{
                            text:"Persentase Bonus",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"nominalBonus",
                        label:{
                            text:"Nominal Bonus",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"pajak",
                        label:{
                            text:"Pajak",
                            alignment:"left"
                        }
                    },
                    {
                        dataField:"bonusBersih",
                        label:{
                            text:"Nominal Bonus Bersih",
                            alignment:"left"
                        }
                    },
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];
    }

    submitData = () => {

    }

    show = () => {
        this.setState({
            popupVisible:true
        })
    }

    hide = () => {
        this.setState({
            popupVisible:false
        })
    }

    render() {
        return (
           <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Bonus'} 
                width={550}
                heigth={650}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <Form
                    colCount={1}
                    id={'formFilter'}
                    formData={this.state.DataMaster}
                    items={this.DataMaster}
                    labelLocation={"left"}
                />
            </Popup>
        )
    }
}

export default ViewPenerimaBonus