import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { provinces, cities, currency, banks, productDefault, managerInvestasis, needSettleDepositoPelepasan, rekeningBank, kpds } from 'dataSource/lookup';
import { formatUploadFileData } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';


class ModalActionPencairanSettlementTransaksiDeposito extends Component {
    constructor(props) {
        super(props)
        this.taxDep = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-DEP') : null
        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null,
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPencairanRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        if (this.validate()) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'KONFIRMASI',
                        items: [
                            {
                                dataField: 'referensiNo',
                                label: {
                                    text: 'No Konfirmasi Bank'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'bankId',
                                label: {
                                    text: 'Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'productId',
                                label: {
                                    text: 'Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'productCode',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'pengelola',
                                label: {
                                    text: 'Pengelolaan'
                                },
                                editorType: 'dxRadioGroup',
                                editorOptions: {
                                    items: ['Internal', 'KPD'],
                                    layout: 'horizontal',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'kpdId',
                                label: {
                                    text: 'Tipe'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kpds(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'noKontrak',
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BILYET',
                        items: [
                            {
                                dataField: 'bilyetNo',
                                label: {
                                    text: 'No. Bilyet'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: 'bilyetNo',
                                    displayExpr: 'bilyetNo',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    onSelectionChanged: async (e) => {
                                        var data = e.selectedItem
                                        if (data) {
                                            var dataEfek = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/${data.efekBalanceAvgId}`, 'GET')
                                            this.formRef.current.instance.getEditor('orderCode').option('value', data.orderCode)
                                            this.formRef.current.instance.getEditor('nominalAccruedBunga').option('value', data.bungaPelepasan)
                                            this.formRef.current.instance.getEditor('tanggalPencairan').option('value', data.withdrawDate)
                                            this.formRef.current.instance.getEditor('nominalPencairan').option('value', data.amountOrder)
                                            this.formRef.current.instance.getEditor('receiveRekbankId').option('value', data.rekBankId)
                                            this.formRef.current.instance.getEditor('currencyId').option('value', data.currencyId)
                                            this.formRef.current.instance.getEditor('ratePencairan').option('value', data.rate)
                                            this.formRef.current.instance.getEditor('tenor').option('value', data.tenor)
                                            this.formRef.current.instance.getEditor('tenorTypeId').option('value', data.tenorTypeId)
                                            this.formRef.current.instance.getEditor('nominalDeposito').option('value', dataEfek.faceValue)
                                            this.formRef.current.instance.getEditor('nominalBunga').option('value', data.bungaPelepasan)
                                            this.setState({
                                                efekBalanceAvgId: data.efekBalanceAvgId,
                                                instrumentName: data.instrumentName
                                            })
                                        }
                                    }
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please choose bilyet Number'
                                    }
                                ]
                            },
                            {
                                dataField: 'orderCode',
                                label: {
                                    text: 'No Order Pencairan'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'nominalAccruedBunga',
                                label: {
                                    text: 'Nominal Accrued Bunga'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BANK',
                        items: [
                            {
                                dataField: 'receiveRekbankId',
                                label: {
                                    text: 'Rekening Pencairan/Bunga'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    },
                                    searchEnabled: true,
                                    deferRendering: false
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'CATATAN',
                        items: [
                            {
                                dataField: 'catatan',
                                label: {
                                    visible: false
                                },
                                editorType: 'dxTextArea',
                                editorOptions: {
                                    height: '80'
                                }
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'PENCAIRAN',
                        items: [
                            {
                                dataField: 'tanggalPencairan',
                                label: {
                                    text: 'Tanggal Pencairan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'managerInvestasiId',
                                label: {
                                    text: 'Manager Investasi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: managerInvestasis(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'nominalDeposito',
                                label: {
                                    text: 'Nominal Deposito'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'ratePencairan',
                                label: {
                                    text: 'Rate Pencairan (%)'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var tenor = this.formRef.current.instance.getEditor('tenor').option('value')
                                        var nominalPencairan = this.formRef.current.instance.getEditor('nominalPencairan').option('value')
                                        var tenorTypeId = this.formRef.current.instance.getEditor('tenorTypeId').option('value')
                                        var rate = e.value
                                        this.hitungBunga(tenor, tenorTypeId, rate, nominalPencairan)
                                    }
                                }
                            },
                            {
                                itemType: 'group',
                                colCount: 2,
                                items: [
                                    {
                                        dataField: 'tenor',
                                        label: {
                                            text: 'Tenor'
                                        },
                                        editorType: 'dxNumberBox',
                                        editorOptions: {
                                            onValueChanged: (e) => {
                                                var tenor = e.value
                                                var nominalPencairan = this.formRef.current.instance.getEditor('nominalPencairan').option('value')
                                                var tenorTypeId = this.formRef.current.instance.getEditor('tenorTypeId').option('value')
                                                var rate = this.formRef.current.instance.getEditor('ratePencairan').option('value')
                                                this.hitungBunga(tenor, tenorTypeId, rate, nominalPencairan)
                                            }
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: "Please fill tenor"
                                            }
                                        ]
                                    },
                                    {
                                        dataField: 'tenorTypeId',
                                        label: {
                                            visible: false
                                        },
                                        editorType: 'dxRadioGroup',
                                        editorOptions: {
                                            layout: 'horizontal',
                                            items: ['HARI', 'BULAN'],
                                            value: 'tenorTypeId',
                                            onValueChanged: (e) => {
                                                var tenor = this.formRef.current.instance.getEditor('tenor').option('value')
                                                var nominalPencairan = this.formRef.current.instance.getEditor('nominalPencairan').option('value')
                                                var tenorTypeId = e.value
                                                var rate = this.formRef.current.instance.getEditor('ratePencairan').option('value')
                                                this.hitungBunga(tenor, tenorTypeId, rate, nominalPencairan)
                                            }
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: "Please choose tenor type"
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                dataField: 'nominalPencairan',
                                label: {
                                    text: 'Nominal Pencairan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var nominalPencairan = e.value
                                        var nomBunga = this.formRef.current.instance.getEditor('nominalBunga').option('value')
                                        var feeBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        var tenor = this.formRef.current.instance.getEditor('tenor').option('value')
                                        var tenorTypeId = this.formRef.current.instance.getEditor('tenorTypeId').option('value')
                                        var rate = this.formRef.current.instance.getEditor('ratePencairan').option('value')
                                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                        this.formRef.current.instance.getEditor('nominal').option('value', nominalPencairan + nomBunga - feeBank - ((pph/100) * nomBunga))

                                        this.hitungBunga(tenor, tenorTypeId, rate, nominalPencairan)
                                    }
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please fill Nominal'
                                    }
                                ]
                            },
                            {
                                dataField: 'nominalBunga',
                                label: {
                                    text: 'Nominal Bunga'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var nomPencairan = this.formRef.current.instance.getEditor('nominalPencairan').option('value')
                                        var feeBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        // var tax = this.taxDep.intValue
                                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                        this.formRef.current.instance.getEditor('nominal').option('value', nomPencairan + e.value - feeBank - ((pph/100) * e.value))
                                        // this.formRef.current.instance.getEditor('pph').option('value', (tax / 100) * e.value)
                                    }
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please fill Interest Nominal'
                                    }
                                ]
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    text: 'Biaya Bank/RTGS'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var nomPencairan = this.formRef.current.instance.getEditor('nominalPencairan').option('value')
                                        var nomBunga = this.formRef.current.instance.getEditor('nominalBunga').option('value')
                                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                        this.formRef.current.instance.getEditor('nominal').option('value', nomPencairan + nomBunga - e.value - ((pph/100) * nomBunga))
                                    }
                                },
                                validationRules: [
                                    {
                                        type: 'required',
                                        message: 'Please fill Fee Bank'
                                    }
                                ]
                            },
                            {
                                dataField: 'pph',
                                label: {
                                    text: 'PPH'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    onValueChanged: (e) => {
                                        var nomPencairan = this.formRef.current.instance.getEditor('nominalPencairan').option('value')
                                        var nomBunga = this.formRef.current.instance.getEditor('nominalBunga').option('value')
                                        var feeBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                        this.formRef.current.instance.getEditor('nominal').option('value', nomPencairan + nomBunga - feeBank - ((e.value/100) * nomBunga))
                                    }
                                }
                            },
                            {
                                dataField: 'nominal',
                                label: {
                                    text: 'Nominal Pencairan Bersih'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'currencyId',
                                label: {
                                    text: 'Mata Uang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyCode',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true,
                                    onSelectionChanged: (e) => {
                                        var data = e.selectedItem
                                        if (data) {
                                            this.formRef.current.instance.getEditor('kurs').option('value', data.rate)
                                        }
                                    }
                                }
                            },
                            {
                                dataField: 'kurs',
                                label: {
                                    text: 'Kurs'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true,
                                }
                            }
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'DOKUMEN',
                        items: [
                            {
                                dataField: "fileKonfirmasi",
                                label: {
                                    text: "File Konfirmasi",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    }
                ]
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                bankId: data.bankId,
                kpdId: data.kpdId,
                referensiNo: data.referensiNo,
                orderCode: data.orderCode,
                tenor: data.tenor,
                tenorTypeId: data.tenorTypeId,
                ratePencairan: data.rateOrder,
                pengelola: data.pengelola,
                biayaBank: data.biayaBank,
                bilyetNo: data.bilyetNo,
                efekBalanceAvgId: data.efekBalanceAvgId,
                instrumentName: data.instrumentName,
                konfirmasi: data.konfirmasi,
                konfirmasiContentType: data.konfirmasiContentType,
                konfirmasiName: data.konfirmasiName,
                kurs: data.kurs,
                nominalBunga: data.nominalBunga,
                nominal: data.nominal,
                nominalPencairan: data.nominalPencairan,
                pengelola: data.pengelola,
                productId: data.productId,
                receiveRekbankId: data.receiveRekbankId,
                catatan: data.catatan,
                tanggalPencairan: data.tanggalPencairan,
                pph: data.pph,
            }
        });
        var selectBilyetNo = this.formRef.current.instance.getEditor('bilyetNo')
        selectBilyetNo.option('dataSource', needSettleDepositoPelepasan(this.props.store, data.bankId, data.kpdId, data.productId))
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }
    hitungBunga = (tenor, tenorTypeId, rate, nominalPencairan) => {
        var diffTime = []
        var diffDays = []
        var result = 0
        if (tenorTypeId == 'BULAN') {
            tenor = tenor * 30
        }
        var date1 = new Date('12/31/2021')
        var date2 = new Date('01/01/2021')
        diffTime = Math.abs(date1 - date2)
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        var dayBases = diffDays + 1
        result = nominalPencairan * (rate / 100) * (tenor / dayBases)
        this.formRef.current.instance.getEditor('nominalBunga').option('value', result)
    }
    submitData = () => {
        var data = this.state.dataMaster
        this.props.submitDetailPelepasanData({
            id: data.id,
            bankId: data.bankId,
            kpdId: data.kpdId,
            biayaBank: data.biayaBank,
            referensiNo: data.referensiNo,
            orderCode: data.orderCode,
            tenor: data.tenor,
            tenorTypeId: data.tenorTypeId,
            ratePencairan: data.ratePencairan,
            pengelola: data.pengelola,
            bilyetNo: data.bilyetNo,
            efekBalanceAvgId: this.state.efekBalanceAvgId,
            instrumentName: this.state.instrumentName,
            konfirmasi: this.state.konfirmasi,
            konfirmasiContentType: this.state.konfirmasiContentType,
            konfirmasiName: this.state.konfirmasiName,
            kurs: data.kurs,
            nominalBunga: data.nominalBunga,
            nominal: data.nominal,
            nominalPencairan: data.nominalPencairan,
            pengelola: data.pengelola,
            productId: data.productId,
            receiveRekbankId: data.receiveRekbankId,
            tanggalPencairan: data.tanggalPencairan,
            catatan: data.catatan,
            pph: data.pph,
        })

        this.hide()
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    retrieveData = async (data) => {
        this.setState({
            dataMaster: data,
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`konfirmasi`]: formattedFileData.base64data,
                    [`konfirmasiContentType`]: formattedFileData.mime,
                    [`konfirmasiName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pencairan Deposito'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                ref={this.formRef}
                                formData={this.state.dataMaster}
                                items={this.items}
                                scrollingEnabled={true}
                                height={'calc(70vh - 60px)'}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalActionPencairanSettlementTransaksiDeposito