import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import { instrumentCategories, instruments, instrumentTransactionTypes, kpds, productDefault } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ModalPortofolioJurnalDetail from 'components/pages/modal/investasi/portfolio/transaksi/index';

class LaporanTransaksiPerInstrument extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            dataMaster: {},
            filterData: {
                productId: 1,
                categoryId: 0,
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                inclAccrued: false
            },
        }
        this.modalRef = React.createRef()
        this.dgRef = React.createRef()
        this.filterItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                            alignment: "left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            width: '100%',
                        }
                    },
                    {
                        dataField: 'categoryId',
                        label: {
                            text: 'Kategori Instrument',
                            alignment: "left",
                        },
                        editorType: 'dxTagBox',
                        editorOptions: {
                            dataSource: instrumentCategories(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'categoryName',
                            searchEnabled: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'startDate',
                                label: {
                                    text: 'Periode Investasi'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    openOnFieldClick: true,
                                    useMaskBehavior: true,
                                }
                            },
                            {
                                dataField: 'endDate',
                                label: {
                                    visible: false
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    openOnFieldClick: true,
                                    useMaskBehavior: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'inclAccrued',
                                label: {
                                    text: 'Include Accrued'
                                },
                                editorType: 'dxCheckBox',
                            },
                            {
                                itemType: "button",
                                buttonOptions: {
                                    text: "Filter",
                                    // type:"default",
                                    elementAttr: { class: "bg-dapen-default" },
                                    onClick: () => {
                                        this.setState({
                                            filterData: this.state.filterData
                                        })
                                        this.dgRef.current.forceRefresh(true)
                                    },
                                },
                                horizontalAlignment: "left"
                            },
                        ]
                    }
                ]
            },

        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'View Jurnal Detail',
                        hint: 'View Jurnal Detail',
                        onClick: (e) => {
                            this.showModalJurnal(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'categoryName',
                caption: 'Kategori',
            },
            {
                dataField: 'instrumentCode',
                caption: 'Instrument',
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Penerimaan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'transactionName',
                caption: 'Tipe Transaksi',
            },
            {
                dataField: 'noKontrak',
                caption: 'Pengelola',
            },
            {
                dataField: 'interest',
                caption: 'Interest',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'accruedInterest',
                caption: 'Accrued Interest',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'quantity',
                caption: 'Kuantitas',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'price',
                caption: 'Harga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'amountTrs',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'accruedInterest'
            },
            {
                name: 'quantity',
                column: 'quantity',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'price',
                column: 'price',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'amountTrs',
                column: 'amountTrs',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            }
        ]
    }
    showModalJurnal = async (data) => {
        var transactionId = data.id
        var dataTransaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal-dtls/report-transaksi-portofolio-jurnal-detail?transactionId=${transactionId}`, 'GET')
        var result = []
        for (var transaksi of dataTransaction) {
            var getData = {
                description: transaksi.description,
                currencyId: transaksi.currencyId,
                akunName: transaksi.akunName,
                db: transaksi.db,
                cr: transaksi.cr,
                transactionJurnalId: transaksi.transactionJurnalId,
            }
            result.push(getData)
        }
        this.modalRef.current.show(result)
    }
    loadData = async () => {
        const getAllTypeTrans = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            'instrument-categories',
            "GET"
        );

        const getId = getAllTypeTrans.map((elem) => elem.id).join(',');

        const categoryId = this.state.filterData.categoryId === 0 ? getId : this.state.filterData.categoryId;

        const loadAPI = `transactions/report-transaksi-portofolio?categoryIdList=${categoryId}&endDate=${formatDate(this.state.filterData.endDate)}&productId=${this.state.filterData.productId}&size=99999&startDate=${formatDate(this.state.filterData.startDate)}`;

        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadAPI,
            'GET'
        )
        return this.state.filterData.inclAccrued ?
            response :
            response.filter(val => val.transactionName !== 'Accrued Interest Deposito')
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <h3 className="main-title">Transaksi Portfolio Produk Dana Pensiun</h3>
                    <div className='row'>
                        <div className="col-md-6">
                            <Form
                                colCount={1}
                                id={'formFilter'}
                                formData={this.state.filterData}
                                items={this.filterItem}
                                labelLocation="left"
                            />
                        </div>
                        <div className="mt-2 col-md-12">
                            <DevExpressDataGrid
                                ref={this.dgRef}
                                loadAPI={`transactions/report-transaksi-portofolio?categoryIdList=${this.state.filterData.categoryId}&endDate=${formatDate(this.state.filterData.endDate)}&productId=${this.state.filterData.productId}&size=99999&startDate=${formatDate(this.state.filterData.startDate)}`}
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Transaksi Portfolio Produk Dana Pensiun"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Kas Bank'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                summaryTotalItem={this.summary}
                                // onToolbarPreparing = {this.onToolbarPreparing}
                                height={'calc(100vh - 350px)'}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ModalPortofolioJurnalDetail
                                ref={this.modalRef}
                                store={this.props.store}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default LaporanTransaksiPerInstrument