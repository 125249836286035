import React, {Component} from 'react'
import { Popup,ScrollView, Form, FileUploader } from 'devextreme-react'
import { banks, custodies, kpds, managerInvestasis, pejabat, product, productDefault, rekCustody } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDate, formatUploadFileData } from 'plugin/helper'
import notify from "devextreme/ui/notify";

class ViewPengajuanPemindahanRek extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
            popupVisible: false,
        }

        this.DataMaster = [
            {
                itemType: 'group',
                name:"pengajuan",
                caption:'Pengajuan',
                items:[
                    {
                        dataField:'noPengajuan',
                        label:{
                            text:'No. Pengajuan'
                        },
                        editorOptions:{
                            // readOnly:true
                        }
                    },
                    {
                        dataField:'noSuratAwal',
                        label:{
                            text:'No. Surat Awal'
                        },
                    },
                    {
                        dataField:'noSuratTujuan',
                        label:{
                            text:'No. Surat Tujuan'
                        },
                    },
                    {
                        dataField:'productId',
                        label:{
                            text:'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            // readOnly:true,
                            displayExpr: function (item) {
                                return item && item.productCode + " - " + item.productName;
                            },
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData({awalNoCbest:e.selectedItem.cbestNo,tujuanNoCbest:e.selectedItem.cbestNo})
                                }
                            },
                            onValueChanged:async (e) => {
                                if (e.value) {
                                    var dataKpd = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `kpds`,
                                        "GET"
                                    );
                                    var dataRekCustody = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `rek-custodies`,
                                        "GET"
                                    );

                                    var kpdList = dataKpd.filter(value => value.productId == e.value)
                                    var rekCustodyList = dataRekCustody.filter(value => value.productId == e.value)

                                    var optionsKpdAwal = this.formMasterRef.current.instance.itemOption('pengelolaAwal.awalKpdId')
                                    optionsKpdAwal.editorOptions.dataSource = kpdList
                                    this.formMasterRef.current.instance.itemOption('pengelolaAwal.awalKpdId',optionsKpdAwal)
                                    
                                    var optionsKpdTujuan = this.formMasterRef.current.instance.itemOption('pengelolaTujuan.tujuanKpdId')
                                    optionsKpdTujuan.editorOptions.dataSource = kpdList
                                    this.formMasterRef.current.instance.itemOption('pengelolaTujuan.tujuanKpdId',optionsKpdTujuan)
                                    
                                    var optionsRekCusAwal = this.formMasterRef.current.instance.itemOption('pengelolaAwal.awalCustodyId')
                                    optionsRekCusAwal.editorOptions.dataSource = rekCustodyList
                                    this.formMasterRef.current.instance.itemOption('pengelolaAwal.awalCustodyId',optionsRekCusAwal)

                                    var optionsRekCusTujuan = this.formMasterRef.current.instance.itemOption('pengelolaTujuan.tujuanCustodyId')
                                    optionsRekCusTujuan.editorOptions.dataSource = rekCustodyList
                                    this.formMasterRef.current.instance.itemOption('pengelolaTujuan.tujuanCustodyId',optionsRekCusTujuan)
                                }
                            }
                        }
                    },
                    {
                        dataField:"nominalTransfer",
                        label:{
                            text:'Nominal Transfer'
                        },
                        visible:false,
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format:'#,##0.00',
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:"tanggal",
                caption:'Tanggal',
                items:[
                    {
                        dataField:'tglPengajuan',
                        label:{
                            text:'Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField:'tglPemindahan',
                        label:{
                            text:'Pemindahan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField:'tglTransfer',
                        label:{
                            text:'Transfer'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:"pengelolaAwal",
                caption:'Pengelola Awal',
                items:[
                    {
                        dataField:'awalPengelola',
                        label:{
                            text:'Pengelola'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: [
                                {
                                    id: 'INTERNAL',
                                    value: 'Internal'
                                },
                                {
                                    id: 'KPD',
                                    value: 'KPD'
                                }
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField:'awalKpdId',
                        label:{
                            text:'KPD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: kpds(this.props.store),
                            dataSource: [],
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'noKontrak',
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData({awalMiId:e.selectedItem.managerInvestasiId})
                                }
                            }
                        }
                    },
                    {
                        dataField:'awalCustodyId',
                        label:{
                            text:'Rekening Kustodi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            // dataSource: custodies(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: "rekName",
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData({awalNoRekCustody:e.selectedItem.rekNo})
                                }
                            }
                        }
                    },
                    {
                        dataField:'awalNoRekCustody',
                        label:{
                            text:'No Rek Kustodi'
                        },
                        editorOptions: {
                            readOnly:true,
                        }
                    },
                    {
                        dataField: 'awalMiId',
                        label: {
                            text:'Manager Investasi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly:true,
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.miCode + " - " + item.miName;
                            },
                        }
                    },
                    {
                        dataField:'awalNoCbest',
                        label:{
                            text:'Cbest No'
                        },
                        editorOptions:{
                            readOnly:true,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:"pengelolaTujuan",
                caption:'Pengelola Tujuan',
                items:[
                    {
                        dataField:'tujuanPengelola',
                        label:{
                            text:'Pengelola'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: [
                                {
                                    id: 'INTERNAL',
                                    value: 'Internal'
                                },
                                {
                                    id: 'KPD',
                                    value: 'KPD'
                                }
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField:'tujuanKpdId',
                        label:{
                            text:'KPD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            // dataSource: kpds(this.props.store),
                            dataSource: [],
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'noKontrak',
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData({tujuanMiId:e.selectedItem.managerInvestasiId})
                                }
                            }
                        }
                    },
                    
                    {
                        dataField:'tujuanCustodyId',
                        label:{
                            text:'Rekening Kustodi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            // dataSource: custodies(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: "rekName",
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData({tujuanNoRekCustody:e.selectedItem.rekNo})
                                }
                            }
                        }
                    },
                    {
                        dataField:'tujuanNoRekCustody',
                        label:{
                            text:'No Rek Kustodi'
                        },
                        editorOptions: {
                            readOnly:true,
                        }
                    },
                    {
                        dataField: 'tujuanMiId',
                        label: {
                            text:'Manager Investasi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly:true,
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.miCode + " - " + item.miName;
                            },
                        }
                    },
                    {
                        dataField:'tujuanNoCbest',
                        label:{
                            text:'Cbest No'
                        },
                        editorOptions:{
                            readOnly:true,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:"penandatangan",
                caption:'Penandatangan',
                items:[
                    {
                        dataField:'pejabat1',
                        label:{
                            text:'Pejabat 1'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'value',
                        }
                    },
                    {
                        dataField:'pejabat2',
                        label:{
                            text:'Pejabat 2'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'value',
                        }
                    },
                ]
            }
        ]

        this.popupToolbarItem1 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submit.bind(this)
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Ubah',
                    onClick: this.update.bind(this)
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.pemindahanId = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
    }

    submit = async () => {
        try {
            var DataMaster = {...this.state.DataMaster}
            var loadApi = `pemindahan-rekenings/pemindahan-rekening-create-update` 

            
            DataMaster.tglPemindahan = formatDate(DataMaster.tglPemindahan)
            DataMaster.tglPengajuan = formatDate(DataMaster.tglPengajuan)
            DataMaster.tglTransfer = formatDate(DataMaster.tglTransfer)
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    update = async () => {
        try {
            var DataMaster = {...this.state.DataMaster}
            var loadApi = `pemindahan-rekenings/pemindahan-rekening-create-update` 

            DataMaster.id = this.pemindahanId
            DataMaster.tglPemindahan = formatDate(DataMaster.tglPemindahan)
            DataMaster.tglPengajuan = formatDate(DataMaster.tglPengajuan)
            DataMaster.tglTransfer = formatDate(DataMaster.tglTransfer)
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster,
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    retrieveData = async (data) => {
        var DataMaster = {...this.state.DataMaster}
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `pemindahan-rekenings/${data.id}`,
        );

        this.pemindahanId = data.id

        DataMaster.noPengajuan = response.noPengajuan
        DataMaster.noSuratAwal = response.noSuratSource
        DataMaster.noSuratTujuan = response.noSuratDestination
        DataMaster.productId = response.productId
        DataMaster.tglPengajuan = response.tanggalPengajuan
        DataMaster.tglPemindahan = response.tanggalPemindahan
        DataMaster.tglTransfer = response.tanggalTransfer
        DataMaster.awalPengelola = response.sourcePengelola
        DataMaster.awalKpdId = response.sourceKpdId
        DataMaster.awalCustodyId = response.sourceRekCustodyId
        DataMaster.awalMiId = response.sourceMiId
        DataMaster.awalNoCbest = response.sourceCbestNo
        DataMaster.tujuanPengelola = response.destinationPengelola
        DataMaster.tujuanKpdId = response.destinationKpdId
        DataMaster.tujuanCustodyId = response.destinationRekCustodyId
        DataMaster.tujuanMiId = response.destinationMiId
        DataMaster.tujuanNoCbest = response.destinationCbestNo
        DataMaster.pejabat1 = response.pejabat1
        DataMaster.pejabat2 = response.pejabat2

        this.setState({
            DataMaster:DataMaster
        })

    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster:{}
        })
    }

    render(){
        return(
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'30vw'}
                    height="98vh"
                    title={`Pengajuan Perpindahan Rekening`}
                    ref={this.PopupRef}
                    toolbarItems={this.props.action == "edit" ? this.popupToolbarItem2 : this.popupToolbarItem1}
                >
                    <ScrollView width='100%' height='100%'>
                        <div className="container-fluid">
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.DataMaster}
                                items={this.DataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                            />
                        </div>
                    </ScrollView>
                </Popup>
        )
    }
}

export default ViewPengajuanPemindahanRek