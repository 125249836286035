import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { gender, banks } from 'dataSource/lookup'

class DataGridKasBank extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {  
                displayFormat: 'Total',
                showInColumn : 'operasional'
            },
            {
                name : 'saldoNominal',
                column: 'saldoNominal',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            },
            {
                name : 'komposisi',
                column: 'komposisi',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat : '{0}'
            }
        ]

        this.columns = [
            {
                dataField: 'id',
                caption: 'ID',
                alignment: "right",
            },
            {
                dataField: 'noRek',
                caption: 'Nomor Rekening',
            },
            {
                dataField: 'jenisRekening',
                caption: 'Jenis Rekening',
                lookup: {
                    dataSource: [],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'banks',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "bankName" // provides display values
                }
            },
            {
                dataField: 'operasional',
                caption: 'Operasional',
            },
            {
                dataField: 'saldoNominal',
                caption: 'Saldo Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rate',
                caption: 'Rate',
                alignment: 'right',
                dataType: 'decimal',
            },
            {
                dataField: 'komposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'decimal',
            },
        ]
    }
    
    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                noRek : '31740110203948',
                jenisRekening: 1,
                bank: 1101,
                operasional: 'Investasi',
                saldoNominal: '1040000000',
                rate: '7,80',
                komposisi: '27,37',
            }
        ]
        return dummyData
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    loadAPI='kas-banks'
                    insertAPI='kas-banks'
                    updateAPI='kas-banks'
                    deleteAPI='kas-banks' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useArraySource = {true}
                    ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Portofolio Kas Bank"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Kas Bank'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    height={'calc(100vh - 336px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridKasBank
