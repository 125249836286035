import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import ModalPortfolioRebalance from 'components/pages/modal/investasi/portfolio/rebalance/index';
import ModalCorporateActionSchedule from 'components/pages/modal/investasi/corporate-action/schedule/index';
import Form from 'devextreme-react/form';
import ModalCorporateActionCashDeviden from 'components/pages/modal/investasi/corporate-action/cash-deviden/index';
import ModalFileCorporateActionCashDeviden from 'components/pages/modal/investasi/corporate-action/cash-deviden/file';
import { addURL } from 'redux/actions/url';
import { productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { imageSource } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class CashDeviden extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {},
            filterItem: {
                productId: 1,
            }
        }

        this.modalAlokasiRef = React.createRef()
        this.modalCorporateActionCashDevidenRef = React.createRef()
        this.modalEditCorpactCashDevidenRef = React.createRef()
        this.modalFileCorporateActionCashDevidenRef = React.createRef()
        this.modalKirimRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.filterItem = [
            {
                dataField: "productId",
                label: {
                    text: "Produk",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: (e) => {
                        return e && e.productCode + ' - ' + e.productName
                    }, // provides display values
                },
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah / Detail',
                        onClick: (e) => {
                            this.modalEditCorpactCashDeviden(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.sendDeviden(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                    {
                        text: 'File',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.fileInvestasi && data.fileInvestasiContentType) {
                                // this.showModalFileCorporateActionCashDeviden('edit', e.row.data)
                                this.previewFile(e.row.data)
                            } 
                            else {
                                notify({ message: 'Tidak ada file yang di upload', alignment: 'center', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'warning', 1500);
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            if (e.row.data) {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin ingin membatalkan data ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.cancelTransaction(e.row.data);
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'Nomor Konfirmasi',
            },
            {
                dataField: 'productName',
                caption: 'Produk',
            },
            {
                dataField: 'kpd',
                caption: 'Pengelola',
            },
            {
                dataField: 'jenisCorpact',
                caption: 'Jenis Corpact',
            },
            {
                dataField: 'saham',
                caption: 'Saham',
            },
            {
                dataField: 'tglEntry',
                caption: 'Tanggal Entry',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tglRecording',
                caption: 'Tanggal Recording',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'distributionDate',
                caption: 'Distribution Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'qtyRecording',
                caption: 'Quantity Recording',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'deviden',
                caption: 'Deviden / Lembar',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.000000000'
            },
            // {
            //     dataField: 'bankFee',
            //     caption: 'Biaya Bank',
            //     alignment: 'right',
            //     dataType: 'number',
            //     format: '#,##0.00',
            //     visible: false            
            // },
            {
                dataField: 'nominal',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'pajak',
                caption: 'Pajak',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'netNominal',
                caption: 'Net Nominal',
                alignment: 'right',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'KIRIM',
                            value: 'Kirim'
                        },
                        {
                            id: 'OPEN',
                            value: 'Open'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    defaultValue: 'OPEN'  // Set the default value to 'OPEN'
                },
            },

        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Tambah Deviden',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalCorporateActionCashDeviden()
                    },
                }
            },
        )
    }
    showModalCorporateActionCashDeviden = () => {
        this.modalCorporateActionCashDevidenRef.current.show(
            {
                corpactTypeId: 1
            }
        )
    }

    previewFile = async (data) => {
        var src = imageSource(data.fileInvestasiContentType, data.fileInvestasi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    cancelTransaction = async (data) => {
        try {
            let result = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `corpact-transactions/${data.id}`)
            let editedData = { ...result }
            editedData.status = 'EXPIRED'

            let postEditedData = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `corpact-transactions`, "PUT", {
                values: editedData,
                key: editedData.id
            }, editedData)

            if (postEditedData) {
                notify({ message: 'Berhasil di batalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
            }
        } catch (error) {
            notify({ message: "Terjadi Kesalahan" + error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    modalEditCorpactCashDeviden = async (data) => {
        var detail = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `corpact-transactions/${data.id}`,
            'GET'
        )
        var bankTrs = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `bank-transactions?reffId.in=${data.id}`,
            'GET'
        )
        var getData = {
            id: data.id,
            noKonfirmasi: detail.noKonfirmasi,
            corpactTypeId: detail.corpactTypeId,
            instrumentId: detail.instrumentId,
            recordingDate: detail.recordingDate,
            cumDate: detail.cumDate,
            exDate: detail.exDate,
            paymentDate: detail.distributionDate,
            deviden: detail.devidenAmountPerStock,
            bankFee: detail.bankFee,
            productId: detail.productId,
            kpdId: detail.kpdId,
            qtyOnHand: detail.qtyOnHand,
            qtyRecording: detail.proceedQuantity,
            nominalDeviden: detail.proceedAmount,
            pajak: detail.proceedTax,
            nominalBersih: detail.proceedNetAmount,
            nominalBersih: detail.proceedNetAmount,
            rekBankTerima: bankTrs[0].receiveRekBankId,
            status: data.status,
            fileBase64: imageSource(data.fileBase64),
            fileBase64ContentType: imageSource(data.fileBase64ContentType)
        }
        if (getData.status == 'KIRIM') {
            this.modalKirimRef.current.show(getData)
        } else {
            this.modalEditCorpactCashDevidenRef.current.show(getData)
        }
    }

    loadData = async() => {
        let data = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `corpact-transactions/transaksi-cash-deviden-list/${this.state.filterItem.productId}`,
            'GET'
        );
        data.sort((a, b) => {
            if (a.status === 'OPEN' && b.status !== 'OPEN') {
                return -1;
            } else if (a.status !== 'OPEN' && b.status === 'OPEN') {
                return 1;
            } else {
                return 0;
            }
        });
        return data;
    }

    sendDeviden = async (data) => {
        var id = data.id
        if (id) {
            try {
                var response = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `corpact-transactions/transaksi-cash-deviden-konfirmasi/${id}`,
                    'PUT', {
                    key: id,
                    values: {}
                },
                )
                if (response) {
                    this.forceRefresh()
                    notify({ message: 'Sukses Kirim Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    showModalFileCorporateActionCashDeviden = (data = null) => {
        this.modalFileCorporateActionCashDevidenRef.current.show()
        this.modalFileCorporateActionCashDevidenRef.current.retrieveData(data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Cash Deviden</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    // loadAPI={`corpact-transactions/transaksi-cash-deviden-list/${this.state.filterItem.productId}`}
                    loadAPI="-"
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Cash Deviden"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Cash Deviden'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalCorporateActionCashDeviden
                    ref={this.modalCorporateActionCashDevidenRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'add'}
                />
                <ModalCorporateActionCashDeviden
                    ref={this.modalEditCorpactCashDevidenRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'edit'}
                />
                <ModalCorporateActionCashDeviden
                    ref={this.modalKirimRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    action={'kirim'}
                />
                <ModalFileCorporateActionCashDeviden
                    ref={this.modalFileCorporateActionCashDevidenRef}
                    store={this.props.store}
                />
                <ModalPreviewPdf 
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />

            </div>
        )
    }
}

export default CashDeviden