import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import uuidv4 from 'uuid/v4'
import { entity, entityType, currency } from 'dataSource/lookup'
import ModalCashSettlement from 'components/pages/modal/akutansi/cash-settlement/outstanding';
import httpRequest from 'plugin/httprequest';
import { formatDefaultDate, getSystemDate, imageSource } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/akutansi/cash-settlement/modalPreviewFile';

class CashSettlement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {}
    }

    this.dataGridRef = React.createRef()
    this.modalCashSettlementRef = React.createRef()
    this.modalPreviewPdfRef = React.createRef()

    this.systemDate = props.store.getState().getParam ? (props.store.getState().getParam || []).find(value => value.paramCode === '0 SYSDATE') : null

    this.filterItem = [
      {
        dataField: "dueDate",
        label: {
          text: "Tanggal Jatuh Tempo",
          alignment: "left",
          location: "left"
        },
        editorType: "dxDateBox",
        editorOptions: {
          useMaskBehavior: true,
          width: '100%',
          displayFormat: 'dd MMM yyyy',
          value: new Date((this.systemDate || {}).dateValue)
        }
      },
      {
        itemType: "button",
        buttonOptions: {
          text: "Filter",
          // type:"default",
          elementAttr: { class: "bg-dapen-default" },
          onClick: () => {
            this.filterSubmit()
          },
        },
        horizontalAlignment: "left"
      },
    ]

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          'edit',
          {
            text: 'Buat Voucher',
            hint: 'Buat Voucher',
            cssClass: 'text-success',
            onClick: (e) => {
              this.modalCashSettlementRef.current.show()
              this.modalCashSettlementRef.current.retrieveData(e.row.data)
            }
          },
          // {
          //   text: 'File',
          //   cssClass: 'text-success',
          //   onClick: async(e) => {
          //       let data = e.row.data

          //       let loadArapAPI = `araps/byEntitiIdAndOustandingNotZero/${data.entitiId}`
          //       let responseArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadArapAPI)        
          //       console.log(responseArap);
          //       if (data.fileInvestasi && data.fileInvestasiContentType) {
          //           // this.showModalFileCorporateActionCashDeviden('edit', e.row.data)
          //           this.previewFile(e.row.data)
          //       } 
          //       else {
          //           notify({ message: 'Tidak ada file yang di upload', alignment: 'center', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'warning', 1500);
          //       }
          //   }
          // },
        ],
      },
      {
        dataField: 'entitiId',
        caption: 'Entitas',
        lookup: {
          dataSource: entity(this.props.store),
          valueExpr: 'id',
          displayExpr: 'entityName'
        },
        fixed: true
      },
      {
        dataField: 'entityType',
        caption: 'Tipe Entitas',
        lookup: {
          dataSource: entityType(this.props.store),
          valueExpr: 'id',
          displayExpr: 'value'
        },
        fixed: true
      },
      {
        dataField: 'currencyId',
        caption: 'Mata Uang',
        lookup: {
          dataSource: currency(this.props.store),
          valueExpr: 'id',
          displayExpr: 'currencyCode'
        },
        fixed: true
      },
      {
        dataField: 'over',
        caption: this.headerTitle(-1),
        columns: [
          {
            dataField: 'arover',
            caption: 'Piutang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'apover',
            caption: 'Utang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'netover',
            caption: 'Nett',
            format: '#,##0.00; (#,##0.00)',
            alignment: 'right'
          },
        ]
      },
      {
        dataField: 'arapt0',
        caption: this.headerTitle(0),
        columns: [
          {
            dataField: 'art0',
            caption: 'Piutang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'apt0',
            caption: 'Utang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'nett0',
            caption: 'Nett',
            format: '#,##0.00; (#,##0.00)',
            alignment: 'right'
          },
        ]
      },
      {
        dataField: 'arapt1',
        caption: this.headerTitle(1),
        columns: [
          {
            dataField: 'art1',
            caption: 'Piutang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'apt1',
            caption: 'Utang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'nett1',
            caption: 'Nett',
            format: '#,##0.00; (#,##0.00)',
            alignment: 'right'
          },
        ]
      },
      {
        dataField: 'arapt2',
        caption: this.headerTitle(2),
        columns: [
          {
            dataField: 'art2',
            caption: 'Piutang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'apt2',
            caption: 'Utang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'nett2',
            caption: 'Nett',
            format: '#,##0.00; (#,##0.00)',
            alignment: 'right'
          },
        ]
      },
      {
        dataField: 'arapot2',
        caption: this.headerTitle(3),
        columns: [
          {
            dataField: 'arot2',
            caption: 'Piutang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'apot2',
            caption: 'Utang',
            format: '#,##0.00; #,##0.00',
            alignment: 'right'
          },
          {
            dataField: 'netot2',
            caption: 'Nett',
            format: '#,##0.00; (#,##0.00)',
            alignment: 'right'
          },
        ]
      },
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  headerTitle = (type) => {
    var systemDate = new Date(getSystemDate(this.props.store))
    if (isNaN(systemDate)) {
      systemDate = new Date()
    }
    systemDate.setDate(systemDate.getDate() + (type !== 3 ? type : 2))

    var text
    switch (type) {
      case -1:
        text = 'Sudah Lewat Jatuh Tempo'
        break;
      case 0:
        text = formatDefaultDate(systemDate)
        break;
      case 1:
        text = formatDefaultDate(systemDate)
        break;
      case 2:
        text = formatDefaultDate(systemDate)
        break;
      case 3:
        text = `> ${formatDefaultDate(systemDate)}`
        break;

      default:
        break;
    }

    return text
  }

  previewFile = async (data) => {
    var src = imageSource(data.fileInvestasiContentType, data.fileInvestasi)
    this.modalPreviewPdfRef.current.show()
    this.modalPreviewPdfRef.current.retrieveData(src)
}

  loadData = async () => {
    const systemDate = this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE')
    const T0 = new Date(systemDate.dateValue)
    const T1 = new Date(systemDate.dateValue)
    T1.setDate(T0.getDate() + 1)
    const T2 = new Date(systemDate.dateValue)
    T2.setDate(T0.getDate() + 2)

    var data = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'araps/getAllByOutstandingAmountNotZero')

    var entitiList = data.map(value => value.entitiId)
    entitiList = [...new Set(entitiList)];

    var dataARAP = []
    for (var entitiId of entitiList) {
      const dataArapEntiti = data.filter(value => value.entitiId === entitiId)

      var currencyList = dataArapEntiti.map(value => value.currencyId)
      currencyList = [...new Set(currencyList)];

      let dataEntity = {}

      if (entitiId) {
        dataEntity = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/${entitiId}`)
      }

      for (var currencyId of currencyList) {
        var dataArapEntitiCurrency = dataArapEntiti.filter(value => value.currencyId === currencyId)

        var dataAROver = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() < T0.getTime() && value.amount > 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataAPOver = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() < T0.getTime() && value.amount < 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataART0 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() === T0.getTime() && value.amount > 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataAPT0 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() === T0.getTime() && value.amount < 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataART1 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() === T1.getTime() && value.amount > 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataAPT1 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() === T1.getTime() && value.amount < 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataART2 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() === T2.getTime() && value.amount > 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataAPT2 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() === T2.getTime() && value.amount < 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataAROT2 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() > T2.getTime() && value.amount > 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)
        var dataAPOT2 = dataArapEntitiCurrency.filter(value => new Date(value.dueDate).getTime() > T2.getTime() && value.amount < 0).map(value => value.outstandingAmount).reduce((a, b) => a + b, 0)

        dataARAP.push({
          id: uuidv4(),
          entitiId: dataEntity.id,
          entityType: dataEntity.type,
          currencyId: currencyId,
          arover: dataAROver,
          apover: dataAPOver,
          netover: dataAROver + dataAPOver,
          art0: dataART0,
          apt0: dataAPT0,
          nett0: dataART0 + dataAPT0,
          art1: dataART1,
          apt1: dataAPT1,
          nett1: dataART1 + dataAPT1,
          art2: dataART2,
          apt2: dataAPT2,
          nett2: dataART2 + dataAPT2,
          arot2: dataAROT2,
          apot2: dataAPOT2,
          netot2: dataAROT2 + dataAPOT2,
        })
      }
    }

    return dataARAP
  }

  forceRefresh = () => {
    this.dataGridRef.current.forceRefresh(true)
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Outstanding Cash Settlement</h2>

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='araps'
          insertAPI='araps'
          updateAPI='araps'
          deleteAPI='araps'

          backendserver={process.env.REACT_APP_BACKEND_ACC}

          useArraySource={true}
          ArraySourceData={this.loadData}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Outstanding Cash Settlement"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={false}
          showPageSizeSelector = {false}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Outstanding Cash Settlement'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          height={'calc(100vh - 245px)'}

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          summaryTotalItem={this.sumDebit}
          //   onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        <ModalCashSettlement store={this.props.store} ref={this.modalCashSettlementRef} forceRefresh={this.forceRefresh} />
        <ModalPreviewPdf ref={this.modalPreviewPdfRef} store={this.props.store}/>
      </div>
    )
  }
}

export default CashSettlement