import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ShowModalPengkinianPesertaRegistrasiPasif from 'components/pages/modal/kepesertaan/pengkinianPeserta/pasif/showModalRegistrasiPasif';
import { addURL } from 'redux/actions/url';
import { pengkinianType, participants, statusPeserta } from 'dataSource/lookup';
import ModalInsertPengkinianPasif from 'components/pages/modal/kepesertaan/pengkinianPeserta/pasif/modalInsert';
import httpRequest from 'plugin/httprequest';
import { compareObj, formatDate } from 'plugin/helper';
import { confirmAlert } from 'react-confirm-alert';
import { alert } from 'devextreme/ui/dialog'
import { showLoading } from 'redux/actions/loading';

class RegistrasiPengkinianPasif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // selectedTabIndex: 0,
            filterData: {},
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.popupPesertaRegistrasiPasif = React.createRef()
        this.modalInsertRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPengkinianPesertaRegistrasiPasif(e.row.data.pesertaId, e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Menghapus Pengkinian Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.deleteData(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Mengirim Pengkinian Ini?",
                                buttons: [
                                    {
                                        label: 'Iya',
                                        onClick: () => {
                                            this.kirim(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                        onClick: () => {

                                        }
                                    },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'createdDate',
                caption: 'Tanggal Pengkinian',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'pengkinianTypeId',
                caption: 'Tipe Pengkinian',
                lookup: {
                    dataSource: pengkinianType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName',
                    value: 'pengkinianTypeId'
                }
            },
            {
                dataField: 'nik',
                name: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                name: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pesertaStatusId',
                caption: 'Peserta Status',
                lookup: {
                    dataSource: statusPeserta(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName',
                }
            },
            {
                dataField: 'online',
                caption: 'Media'
            },
            {
                dataField: 'updatedNote',
                caption: 'Keterangan'
            }
        ]
        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        // this.setState({
                        //     getUrl: 'view-pengkinian-peserta-bo-aktifs?statusCode.in=4&updatedStatus.in=false'
                        // })
                        this.dataGridRef.current.forceRefresh()
                    }
                },
                visible: false
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengkinianPesertaRegistrasiPasif = (pesertaId, dataPengkinian) => {
        this.popupPesertaRegistrasiPasif.current.show()
        this.popupPesertaRegistrasiPasif.current.retrieveData(pesertaId, dataPengkinian)
    }

    showModalInsert = async() => {
        let periodeTahun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'pembayaran-mp/periodeTahun', 'GET')
        this.modalInsertRef.current.show(periodeTahun)
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Pengkinian',
                    onClick: (e) => {
                        this.showModalInsert()
                    }
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    deleteData = async (data) => {
        try {
            var deleteAPI = 'pengkinian-peserta-bo-aktifs'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, deleteAPI, 'DELETE', data.id)

            this.forceRefresh()
            var text = `Data berhasil dihapus!`
            var type = 'Success'

            await alert(text, type)
        } catch (e) {
            console.log(e)
            await alert(e, 'Failed')
        }
    }

    kirim = async (data) => {
        var dataPengkinian = data
        var updateParam = JSON.parse(dataPengkinian.updateParam)
        dataPengkinian.updatedDate = formatDate(new Date())
        dataPengkinian.pengkinianDate = formatDate(new Date())
        dataPengkinian.updatedStatus = true
        if (dataPengkinian) {
            try {
                this.props.store.dispatch(showLoading(true))
                var updateAPI = 'pengkinian-peserta-bo-aktifs'
                var responsePengkinian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPengkinian.id,
                    values: dataPengkinian
                },
                    dataPengkinian)

                if (responsePengkinian) {
                    if (updateParam) {
                        var dataPersonal = updateParam.dataPersonal
                        var dataKepegawaian = updateParam.dataKepegawaian
                        var dataKeluarga = updateParam.dataKeluarga
                        var dataAkun = updateParam.dataAkun

                        var responsePersonal = await this.submitDataPersonal(dataAkun, dataPersonal)
                        if (responsePersonal) {
                            var pesertaId = dataPersonal.id
                            await this.submitDataKepegawaian(pesertaId, dataKepegawaian)
                            await this.submitDataKeluarga(pesertaId, dataKeluarga)
                            await this.submitDataAkun(dataAkun, dataPersonal)
                        }
                    }
                }

                var text = `Data berhasil dikirim!`
                var type = 'Success'

                this.props.store.dispatch(showLoading(false))
                var resultAlert = await alert(text, type)
                if (resultAlert) {
                    this.forceRefresh()
                }
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }

    submitDataPersonal = async (dataAkun, data) => {
        var dataPersonal = data
        var ifNullMkUp = "0 Tahun 0 Bulan"
        if (dataPersonal) {
            let loadPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pesertas/${dataPersonal.id}`, 'GET');
            let dataPesertaKepProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${dataPersonal.id}`, 'GET');

            let updateData = dataPesertaKepProduct;
            updateData[0].mpRekeningNo = dataPersonal.mpRekeningNo;
            updateData[0].mpRekeningName = dataPersonal.mpRekeningName;
            updateData[0].mpRekeningBankId = dataPersonal.mpRekeningBankId;
            updateData[0].mpRekeningCabang = dataPersonal.mpRekeningCabang;
            updateData[0].paymentRekbankId = dataPersonal.paymentRekbankId;

            // dataPersonal.masaKerjaBulan = dataAkun[0].masaKerjaBulan
            // dataPersonal.masaKerjaTahun = dataAkun[0].masaKerjaTahun
            // dataPersonal.usiaPensiunBulan = dataAkun[0].usiaPensiunBulan
            // dataPersonal.usiaPensiunTahun = dataAkun[0].usiaPensiunTahun
            // dataPersonal.pensiunDate = dataAkun[0].pensiunDate
            // dataPersonal.pensiunSkNo = dataAkun[0].skPensiunNo
            // dataPersonal.pemberhentianSkNo = dataAkun[0].skPemberhentianNo
            try {
                var updateAPI = 'pesertas'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    key: dataPersonal.id,
                    values: dataPersonal
                },
                    loadPeserta) 

                if (response) {
                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'kepersertaan-products', 'PUT', {
                        key: dataPersonal.id,
                        values: updateData[0]
                    }, dataPesertaKepProduct[0])
                }

                return response
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }

    // submitDataPersonal = async (data) => {
    //     try {
    //         var updateAPI = 'pesertas'
    //         var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
    //             key: data.id,
    //             values: data
    //         },
    //             data)
    //         // return response
    //     } catch (e) {
    //         console.log(e)
    //         await alert(e, 'Failed')
    //     }
    // }

    submitDataKepegawaian = async (pesertaId, data) => {
        var dataKepegawaian = data
        if (data) {
            dataKepegawaian.pesertaId = pesertaId
            try {
                if (!dataKepegawaian.id) {
                    var insertAPI = 'peserta-kepegawaians'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                        values: dataKepegawaian
                    }
                    )
                } else {
                    var loadAPIKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `peserta-kepegawaians/${dataKepegawaian.id}`, 'GET');
                    var updateAPI = 'peserta-kepegawaians'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                        key: dataKepegawaian.id,
                        values: dataKepegawaian
                    },
                        loadAPIKepegawaian)

                }
                return response
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed')
            }
        }
    }

    // submitDataKeluarga = async (pesertaId, data) => {
    //     var dataKeluarga = data.dataKeluarga
    //     var deletedDataKeluarga = data.deletedDataKeluarga
    //     var currentData = data.currentDataKeluarga
    //     var API = 'peserta-families'
    //     if (data.length !== 0) {
    //         for (var value of dataKeluarga) {
    //             if (value.filePenghasilanData) {
    //                 value.filePenghasilan = value.filePenghasilanData
    //             }
    //             if (value.fileStatusMenikahData) {
    //                 value.fileStatusMenikah = value.fileStatusMenikahData
    //             }
    //             if (value.fileStatusSekolahData) {
    //                 value.fileStatusSekolah = value.fileStatusSekolahData
    //             }
    //             value.pesertaId = pesertaId
    //             try {
    //                 if (value.id.length !== 36) {
    //                     var selectedData = currentData.find(cur => cur.id === value.id)
    //                     if (compareObj(value, selectedData) !== true) {
    //                         var updateAPI = 'peserta-families'
    //                         var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
    //                             values: value,
    //                             key: value.id
    //                         },
    //                             value)
    //                     }
    //                 } else {
    //                     delete value.id
    //                     var insertAPI = 'peserta-families'
    //                     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
    //                         values: value
    //                     }
    //                     )
    //                 }

    //                 if (deletedDataKeluarga) {
    //                     let keluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`, 'GET');
                
    //                     for (var values of deletedDataKeluarga) {
    //                         for (let valueJ of keluarga){
    //                             if (valueJ.id === values) {
    //                                 await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'DELETE', values)
    //                                 break
    //                             } 
    //                         } 
    //                     }
    //                 }
    //             } catch (e) {
    //                 console.log(e)
    //                 await alert(e, 'Failed')
    //             }
    //         }
    //     }
    // }

    submitDataKeluarga = async (pesertaId, data) => {
        var dataKeluarga = data.dataKeluarga
        var deletedDataKeluarga = data.deletedDataKeluarga
        var currentData = data.currentDataKeluarga
        var API = 'peserta-families'
        if (data.length !== 0) {
            for (var value of dataKeluarga) {
                value.filePenghasilan = value.filePenghasilanData
                value.fileStatusMenikah = value.fileStatusMenikahData
                value.fileStatusSekolah = value.fileStatusSekolahData
                value.pesertaId = pesertaId
                try {
                    if (typeof value.id === "string") {
                        delete value.id
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'POST', {
                            values: value
                        })
                    } else {
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'PUT', {
                            values: value,
                            key: value.id
                        }, value)
                    }

                    // if (currentData.length !== 0 || value.id.length !== 36) {
                    //     var selectedData = currentData.find(cur => cur.id === value.id)

                    //     if (!selectedData) {
                    //         delete value.id
                    //         var insertAPI = 'peserta-families'
                    //         var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                    //             values: value
                    //         }
                    //         )
                    //     } else {
                    //         if (compareObj(value, selectedData)) {
                    //             var updateAPI = 'peserta-families'
                    //             var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                    //                 values: value,
                    //                 key: value.id
                    //             },
                    //                 value)
                    //         }
                    //         // else{
                    //         //     delete value.id
                    //         //     var insertAPI = 'peserta-families'                    
                    //         //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, insertAPI, 'POST', {
                    //         //             values:value
                    //         //         }
                    //         //     )
                    //         // }
                    //     }
                    // } else {
                    //     delete value.id
                    //     var insertAPI = 'peserta-families'
                    //     var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, insertAPI, 'POST', {
                    //         values: value
                    //     }
                    //     )
                    // }

                    // if (deletedDataKeluarga) {
                    //     var loadAPI = 'peserta-families'
                    //     for (var values of deletedDataKeluarga) {
                    //         await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'DELETE', values)
                    //     }
                    // }
                } catch (e) {
                    console.log(e)
                    await alert(e, 'Failed Add or Edit Keluarga')
                }
            }
            try {
                // if (deletedDataKeluarga) {
                //     let resultMapDelKel = deletedDataKeluarga.map(value => value);
                //     let keluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`, 'GET');
                //     let resultKelId = keluarga.map(value => value.id);

                //     // console.log(resultMapDelKel);
                //     // console.log(resultKelId);

                //     const MergeDataKel = resultMapDelKel.concat(resultKelId);

                //     for (let i = 0; i < resultMapDelKel.length; i++) {
                //         for (let j = 0; j < resultKelId.length; i++) {
                //             if (resultMapDelKel[i] === resultKelId[i]) {
                //                 for (let value of resultMapDelKel) {
                //                     await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'DELETE', value);
                //                 }
                //                 break;
                //             }
                //         }
                //     }

                // }
                if (deletedDataKeluarga) {
                    let keluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${pesertaId}`, 'GET');
            
                    for (var values of deletedDataKeluarga) {
                        for (let valueJ of keluarga){
                            if (valueJ.id === values) {
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, API, 'DELETE', values)
                                break
                            } 
                        } 
                    }
                }
            } catch (e) {
                console.log(e)
                await alert(e, 'Failed Delete Keluarga')
            }
        }
    }

    submitDataAkun = async (data, dataPersonal) => {
        for (var value of data) {
            var dataAkun = value
            dataAkun.nik = dataPersonal.nik
            var updateAPI = 'kepersertaan-products'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                key: dataAkun.id,
                values: dataAkun
            },
                dataAkun)
        }
    }

    loadData = async () => {
        try {
            let loadAPI = 'view-pengkinian-peserta-bo-aktifs?statusCode.in=4&updatedStatus.in=false&size=9999'
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            let data = []

            for(let value of response) {
                let updateParamValue = JSON.parse(value.updateParam)
                if(updateParamValue !== null) {
                    let dataValue = {
                        ...value,
                        fullName: updateParamValue.dataPersonal.fullName
                    }
    
                    data.push(dataValue)
                } else {
                    data.push(value)
                }
            }

            return data;
            
        } catch (e) {
            console.log(e)
        }
    } 

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Registrasi</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={' mt-2 text-center'} style={{ height: '100%' }}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI={`view-pengkinian-peserta-bo-aktifs?statusCode.in=4&updatedStatus.in=false`}
                        insertAPI='pengkinian-peserta-bo-aktifs'
                        updateAPI='pengkinian-peserta-bo-aktifs'
                        deleteAPI='pengkinian-peserta-bo-aktifs'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        // remoteOperations={true}

                        useArraySource={true}
                        ArraySourceData={this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengkinian Peserta Pasif"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengkinian Peserta Pasif'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        height={'calc(100vh - 300px)'}

                        onToolbarPreparing={this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ModalInsertPengkinianPasif
                        store={this.props.store}
                        ref={this.modalInsertRef}
                        forceRefresh={this.forceRefresh}
                    />
                </div>
                <ShowModalPengkinianPesertaRegistrasiPasif
                    ref={this.popupPesertaRegistrasiPasif}
                    actionType={'edit'}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default RegistrasiPengkinianPasif
