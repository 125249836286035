import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { confirm, alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import ViewDetailJournal from './detailJournal';
import ViewFileAccounting from './file';
import ModalPreviewPdf from '../../kepesertaan/compPeserta/previewPdf';
import { imageSource } from 'plugin/helper';
import { formatDate } from '../../../../../plugin/helper';
import { showLoading } from 'redux/actions/loading';

class ViewDetailTransaction extends Component {
    constructor(props) {
        super(props);

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
        };

        this.transactionColumn = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Detail Jurnal',
                        hint: 'Lihat Detail Jurnal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = {
                                transactionId: e.row.data.transactionId,
                                transactionNo: e.row.data.transactionNo,
                                transactionDate: e.row.data.transactionDate,
                                description: e.row.data.description
                            }

                            this.modalViewDetailJournalRef.current.setDataJurnal(this.transType, data)
                            this.modalViewDetailJournalRef.current.show()
                        }
                    },
                    {
                        text: 'Tolak Jurnal',
                        hint: 'Tolak Jurnal',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            let result = await confirm("Apakah anda yakin untuk membatalkan jurnal ini?", "Konfirmasi");
                            if (result) {
                                switch (this.transType) {
                                    case 'KPS':
                                        try {
                                            var data = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pph-21-s/cancel-jurnal-kps/${e.row.data.transactionNo}`)
                                            notify({ message: 'Data Jurnal Berhasil di Batalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                                        } catch (error) {
                                            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                        }
                                        break;
                                    case 'HRD':
                                        try {
                                            var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-tramsactions/cancel-jurnal-sdm/${e.row.data.transactionNo}`)
                                            notify({ message: 'Data Jurnal Berhasil di Batalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                                        } catch (error) {
                                            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                        }
                                        break;
                                    case 'INV':
                                        try {
                                            var data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/cancel-jurnal/${e.row.data.transactionNo}`)
                                            notify({ message: 'Data Jurnal Berhasil di Batalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                                        } catch (error) {
                                            notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                        }
                                        break;
                                    default:
                                        break;
                                }

                                this.setDataTransaksi(this.transType)
                            }
                        }
                    },
                    {
                        text: 'Lihat File',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            switch (this.transType) {
                                case 'KPS':
                                    // this.showFileKps(e.row.data);
                                    break;

                                case 'HRD':
                                    // this.showFileSdm(e.row.data);
                                    break;

                                case 'INV':
                                    this.showFileInv(e.row.data);
                                    break;

                                default:
                                    break;
                            }
                            this.setDataTransaksi(this.transType)
                        }
                    },
                ]
            },
            {
                dataField: 'transactionNo',
                caption: 'No Transaksi',
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
                allowEditing: false
            },
            {
                dataField: 'debit',
                caption: 'Total Debit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'credit',
                caption: 'Total Credit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
        ]

        this.selectedRowsData = []
        this.dataTransaksi = []
        this.valueDate = new Date(this.sysDate ? this.sysDate.dateValue : null)
        this.transType = null

        this.formRef = React.createRef()
        this.transactionDetailRef = React.createRef()
        this.modalViewDetailJournalRef = React.createRef()
        this.modalFileRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
    }

    getToolbarItem = () => {
        return [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this),
                    visible: false
                },
                toolbar: "bottom"
            }
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
            popupVisible: false,
        });
    };

    setDataTransaksi = async (transType) => {
        var data
        this.transType = transType
        switch (transType) {
            case 'KPS':
                data = await this.loadDataTransaksiKepesertaan()
                break;
            case 'HRD':
                data = await this.loadDataTransaksiHRD()
                break;
            case 'INV':
                data = await this.loadDataTransaksiInvestasi()
                break;

            default:
                break;
        }

        this.dataTransaksi = data
        this.transactionDetailRef.current.forceRefresh(true)
    }

    getValueDate = (date) => {
        this.valueDate = formatDate(date.valueDate)
    }

    loadDataTransaksiKepesertaan = async () => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `incoming-transaction/groupByNoTransaksi/${this.valueDate}`)

        return data
    }
    loadDataTransaksiHRD = async () => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `incoming-transaction/groupByNoTransaksi/${this.valueDate}`)

        return data
    }

    loadDataTransaksiInvestasi = async () => {
        var data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal/get-by-valueDate/${this.valueDate}`)
        data = data.map(value => {
            return {
                transactionId: value.jurnalId,
                transactionNo: value.jurnalNo,
                transactionDate: value.transactionDate,
                description: value.description,
                debit: value.totalDb || 0,
                credit: value.totalCr || 0
            }
        })
        return data
    }
    showFileInv = async (data) => {
        var transactionJurnal = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnals/${data.transactionId}`, 'GET')
        var transaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transactions/${transactionJurnal.transactionId}`, 'GET')

        if (transaction.fileSettle == null) {
            if (transaction.bankTransactionId == null) {
                var transactionConfirmation = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/${transaction.confirmationId}`, 'GET')
                if (transactionConfirmation.fileInstruksi === null) {
                    notify({ message: 'Tidak ada berkas transaksi yang ditemukan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                } else {
                    this.previewFile(transactionConfirmation.fileInstruksi, transactionConfirmation.fileInstruksiContentType)
                }

            } else {
                var bankTransaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `bank-transactions/${transaction.bankTransactionId}`, 'GET')
                if (bankTransaction.fileInvoiceId && bankTransaction.fileInstruksiId == null) {
                    notify({ message: 'Tidak ada berkas transaksi yang ditemukan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                } else {
                    var fileInvoice = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `file-investasis/${bankTransaction.fileInvoiceId}`, 'GET')
                    var fileInstruksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `file-investasis/${bankTransaction.fileInstruksiId}`, 'GET')
                    this.modalFileRef.current.show(fileInvoice, fileInstruksi)
                }
            }
        } else {
            this.previewFile(transaction.fileSettle, transaction.fileSettleContentType)
        }
    }

    showFileKps = async (data) => {
        var transactionJurnal = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnals/${data.transactionId}`, 'GET')
        var transaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transactions/${transactionJurnal.transactionId}`, 'GET')

    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    text: 'Import Jurnal Terpilih',
                    onClick: async (e) => {
                        var errorKps = true
                        var errorSdm = true
                        var errorInv = true

                        if (this.selectedRowsData.length === 0) {
                            notify({ message: 'Mohon pilih salah satu transaksi yang akan di import!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        let result = await confirm("Apakah anda yakin untuk melakukan proses import jurnal?", "Konfirmasi");
                        if (result) {
                            this.props.store.dispatch(showLoading(true))

                            switch (this.transType) {
                                case 'KPS':
                                    let kps = await this.importJurnalSelectedKps()
                                    errorKps = kps
                                    // notify({ message: 'Fitur sedang tahap development!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);

                                    break;
                                case 'HRD':
                                    let sdm = await this.importJurnalSelectedHrd()
                                    errorSdm = sdm
                                    // notify({ message: 'Fitur sedang tahap development!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);

                                    break;
                                case 'INV':
                                    let inv = await this.importJurnalSelectedInv()
                                    errorInv = inv
                                    break;
                                default:
                                    break;
                            }
                            this.setDataTransaksi(this.transType)

                            if (!errorKps) {
                                this.props.store.dispatch(showLoading(false))
                                notify({ message: 'Terdapat data error pada import jurnal Kepesertaan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                return false
                            }

                            if (!errorSdm) {
                                this.props.store.dispatch(showLoading(false))
                                notify({ message: 'Terdapat data error pada import jurnal Hrd dan Umum', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                return false
                            }

                            if (!errorInv) {
                                this.props.store.dispatch(showLoading(false))
                                notify({ message: 'Terdapat data error pada import jurnal Investasi', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                                return false
                            }
                            if (errorKps && errorSdm && errorInv) {
                                this.props.store.dispatch(showLoading(false))
                                notify({ message: 'Import Jurnal Transaksi Berhasil', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                            }


                            this.props.store.dispatch(showLoading(false))
                        }
                    },
                }
            }
        )
    }

    importJurnalSelectedKps = async () => {
        try {
            for (let data of this.selectedRowsData) {
                let posting = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-transaction-detils/importJournalSelected/${data.transactionId}`, 'POST', {
                    values: {}
                })
            }
            return true
        } catch (e) {
            return false
        }
    }

    importJurnalSelectedHrd = async () => {
        try {
            for (let data of this.selectedRowsData) {
                let posting = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umums/importJournalSelected/${data.transactionId}`, 'POST', {
                    values: {}
                })
            }
            return true
        } catch (e) {

            return false
        }
    }

    importJurnalSelectedInv = async () => {
        try {
            for (let data of this.selectedRowsData) {
                let posting = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal-dtls/postingSelectedJurnal/${data.transactionNo}/${data.transactionDate}`, 'PUT', {
                    values: {},
                }, {})
            }
            return true
        } catch (e) {
            return false
        }
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    previewFile = async (fileBase64, fileBase64ContentType) => {
        var src = imageSource(fileBase64ContentType, fileBase64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    loadDataTransaksi = () => {
        return this.dataTransaksi
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Detail Transaksi"}
                    width={"90vw"}
                    height={"90vh"}
                    toolbarItems={this.getToolbarItem()}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <DevExpressDataGrid
                                    ref={this.transactionDetailRef}

                                    keyField="transactionId"

                                    loadAPI='journal-details'
                                    insertAPI='journal-details'
                                    updateAPI='journal-details'
                                    deleteAPI='journal-details'

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataTransaksi}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    // editingMode = "row"
                                    // sortingMode="none"

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={false}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={'calc(100vh - 187px)'}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter
                                    onToolbarPreparing={this.onToolbarPreparing}
                                    ColumnConfiguration={this.transactionColumn} // taruh konfigurasi kolom disini
                                    onSelectionChanged={this.onSelectionChanged}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
                <ViewDetailJournal store={this.props.store} ref={this.modalViewDetailJournalRef} />
                <ViewFileAccounting store={this.props.store} ref={this.modalFileRef} />
                <ModalPreviewPdf store={this.props.store} ref={this.modalPreviewPdfRef} />
            </React.Fragment>
        );
    }
}

export default ViewDetailTransaction