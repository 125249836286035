import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { emitens, instrumentCategories, productDefault } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';

class ModalEditAlokasiPerPihak extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.newData = true
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.updateData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                caption: "PIHAK",
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        dataField: 'emitenId',
                        label: {
                            text: 'Pihak'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: emitens(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'emitenName'
                        }
                    },
                ]
            },
            // {
            //     itemType: 'group',
            //     colCount: 2,
            //     items: [

            //     ]
            // },
            {
                itemType: 'group',
                caption: 'ALOKASI',
                items: [
                    {
                        dataField: 'persenMaxOjk',
                        label: {
                            text: 'Alokasi OJK (%)',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            width: '80px',
                        },
                    },
                    {
                        dataField: 'persenMaxInv',
                        label: {
                            text: 'Alokasi Investasi (%)',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            width: '80px',
                        },
                    },
                    {
                        dataField: 'notifikasi',
                        label: {
                            text: 'Notifikasi',
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'catatan',
                caption: 'CATATAN',
                alignItemLabels: 'left',
                items: [
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Catatan',
                            visible: false
                        },
                        editorType: 'dxTextArea'
                    },
                ]
            },
        ]

    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.newData = true
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }

    retrieveData = (data) => {
        console.log(data);
        this.newData = false
        this.setState({
            dataMaster: data,
        })
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    submitData = async () => {
        let data = this.state.dataMaster
        data.kebijakanSpesifikasiId = 1
        try {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value-pihaks`, 'POST', {
                values: data
            })

            if (response) {
                notify({ message: "Data Sukses di simpan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                this.props.forceRefresh()
                this.hide()
            }
        } catch (e) {
            console.log(e);
            notify({ message: "Terjadi kesalahan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }

    updateData = async () => {
        let data = this.state.dataMaster
        data.kebijakanSpesifikasiId = 1
        try {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value-pihaks`, 'PUT', {
                values: data,
                key: data.id
            }, data)

            if (response) {
                notify({ message: "Data Sukses di simpan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                this.props.forceRefresh()
                this.hide()
            }
        } catch (e) {
            console.log(e);
            notify({ message: "Terjadi kesalahan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'25vw'}
                    height="60vh"
                    title={`Form Alokasi Per Pihak`}
                    ref={this.PopupRef}
                    toolbarItems={this.newData ? this.popupToolbarItem : this.popupToolbarItem2}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <Form
                                        ref={this.formRef}
                                        colCount={1}
                                        id={'dataMaster'}
                                        formData={this.state.dataMaster}
                                        items={this.dataMaster}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalEditAlokasiPerPihak