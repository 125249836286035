import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { fixedAssetGroups, unit } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalUmumPengajuan from 'components/pages/modal/sdm-umum/umum/pengadaan/pengajuan/index';
import FormModalTagihanPembelian from 'components/pages/modal/sdm-umum/umum/pembelian/tagihan/index';

class UmumPembelianTagihan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {},
            DataPengajuan: {},
            DataPemeriksaan: {},
            DataPersetujuan: {}
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data, 'edit')
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.kirim(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.kirim(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField: 'noPengajuan',
                caption: 'Nomor Pengajuan'
            },
            {
                dataField: 'noPemesanan',
                caption: 'Nomor Pemesanan'
            },
            {
                dataField: 'tanggalPemesanan',
                caption: 'Tanggal Pemesanan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'supplier',
                caption: 'Supplier'
            },
            {
                dataField: 'statusPemesanan',
                caption: 'Status Pemesanan'
            },
            {
                dataField: 'Pemesanan',
                caption: 'Pemesanan',
                alignment:'center',
                columns: [
                    {
                        dataField: 'kategori',
                        caption: 'Category',
                        lookup: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Aset'
                                },
                                {
                                    id: 2,
                                    value: 'Barang'
                                },
                                {
                                    id: 3,
                                    value: 'Jasa'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                        }
                    },
                    {
                        dataField: 'groupAssetId',
                        caption: 'Group Asset',
                        lookup: {
                            dataSource: fixedAssetGroups(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'assetGroupCode',
                        }
                    },
                    {
                        dataField: 'jenis',
                        caption: 'Jenis'
                    },
                    {
                        dataField: 'jumlah',
                        caption: 'Jumlah',
                        type:'number',
                        format: '#,##0'
                    },
                    {
                        dataField: 'hargaPerUnit',
                        caption: 'Harga/Unit',
                        type:'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'penerimaan',
                caption: 'Penerimaan',
                alignment:'center',
                columns: [
                    {
                        dataField: 'jumlahDiterima',
                        caption: 'Jumlah Diterima',
                        type:'number',
                        format: '#,##0'
                    },
                    {
                        dataField: 'jumlahSisa',
                        caption: 'Jumlah Sisa',
                        type:'number',
                        format: '#,##0'
                    },
                ]
            },
            {
                dataField: 'tagihan',
                caption: 'Tagihan',
                alignment:'center',
                columns: [
                    {
                        dataField: 'jumlahTagihan',
                        caption: 'Jumlah',
                        type:'number',
                        format: '#,##0'
                    },
                    {
                        dataField: 'totalTagihan',
                        caption: 'Total Tagihan',
                        type:'number',
                        format: '#,##0'
                    },
                ]
            },
        ]
    }

    renderButton = (button) => {
        return <div style={{ padding: 20 }}><p>{button.text}</p></div>;
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (data, type) => {
        this.modalRef.current.show()
        this.modalRef.current.retrieveData(data, type)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    kirim = async (data) => {
        var text = `Data berhasil dikirim!`
        var type = 'success'

        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    insertData = async (data) => {
        var dataPengkinian = data
        dataPengkinian.pengkinianTypeId = 2351
        dataPengkinian.pesertaStatusId = 3052
        dataPengkinian.statusCode = 2
        dataPengkinian.online = false
        dataPengkinian.updatedStatus = false

        try {
            var loadAPI = 'pengkinian-onlines'

            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                values: dataPengkinian
            })
            var text = `Data berhasil ditambah!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        this.forceRefresh()
    }

    loadDummyData = () => {
        var data = [
            {
                id: 1,
                tanggalPengajuan: '2017-03-25',
                unitId: 1001,
                kategori: 1,
                jenis: 'Jenis 1',
                tipe: 'Tipe 1',
                merk: 'Merk 1',
                fixedGroupAssetId: 4451,
                jumlah: 100,
            },
            {
                id: 2,
                tanggalPengajuan: '2017-03-25',
                unitId: 1001,
                kategori: 2,
                jenis: 'Jenis 2',
                tipe: 'Tipe 2',
                merk: 'Merk 2',
                fixedGroupAssetId: 4451,
                jumlah: 200,
            },
            {
                id: 3,
                tanggalPengajuan: '2017-03-25',
                unitId: 1001,
                kategori: 3,
                jenis: 'Jenis 3',
                tipe: 'Tipe 3',
                merk: 'Merk 3',
                fixedGroupAssetId: 4451,
                jumlah: 300,
            },
        ]

        return data
    }
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Tagihan Pembelian</h3>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='daftar-penagjuan-pengadaan'
                        insertAPI='daftar-penagjuan-pengadaan'
                        updateAPI='daftar-penagjuan-pengadaan'
                        deleteAPI='daftar-penagjuan-pengadaan'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource={true}
                        ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Tagihan Pembelian"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Tagihan Pembelian'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <FormModalTagihanPembelian
                    ref={this.modalRef}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default UmumPembelianTagihan
