import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import ModalEditFixedAsset from 'components/pages/modal/akutansi/fixedAsset/edit';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class Supplier extends Component {
  constructor(props) {
    super(props)

    this.DataGridRef = React.createRef()

    this.columns = [
      {
        type: 'buttons',
        width: 100,
        buttons: [
          'edit',
          {
            text: 'Edit',
            hint: 'Edit',
            cssClass: 'text-success',
            onClick: (e) => {
              // this.showModalEdit(e.row.data)
            }
          },
        ]
      },
      {
        dataField: 'id',
        caption: "No.",
        cellRender: (data) => {
          return data.rowIndex + 1
        },
      },
      {
        dataField: 'tanggalTransaksi',
        caption: 'Tanggal Transaksi',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right'
      },
      {
        dataField: 'nomorTransaksi',
        caption: 'Nomor Transaksi',
        alignment: 'right',
      },
      {
        dataField: 'tanggalJatuhTempo',
        caption: 'Tanggal Jatuh Tempo',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right'
      },
      {
        dataField: 'nilaiHutang',
        caption: 'Nilai Hutang',
        alignment: 'right',
        dataType: 'number',
        format: '#,##0.00'
      },
      {
        dataField: 'sisaHutang',
        caption: 'Sisa Hutang',
        alignment: 'right',
        dataType: 'number',
        format: '#,##0.00'
      },
    ]
  }

  loadDummyData = () => {
    var dummyData = [
      {
        id: 1,
        tanggalTransaksi: '18 Sept 2020',
        nomorTransaksi: '021',
        tanggalJatuhTempo: '20 Sept 2020',
        nilaiHutang: 20000,
        sisaHutang: 40000,
      }
    ]
    this.currentData = JSON.parse(JSON.stringify(dummyData))
    return dummyData
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: (e) => {
            // this.showModalInsert()
          },
        }
      }
    )
  }
  forceRefresh = () => {
    this.DataGridRef.current.forceRefresh()
  }


  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title font-weight-bold">Supplier</h2>

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='supplier'
          insertAPI='supplier'
          updateAPI='supplier'
          deleteAPI='supplier'

          backendserver={process.env.REACT_APP_BACKEND_ACC}

          useArraySource={true}
          ArraySourceData={this.loadDummyData}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Supplier"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Supplier'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          SummaryConfiguration={this.summary}

          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />

      </div>
    )
  }
}

export default Supplier