import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { download, formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import { FileUploader, Box } from 'devextreme-react';
import { Item } from 'devextreme-react/accordion';
import { ScrollView } from 'devextreme-react';
import ModalPreviewImage from '../../compPeserta/previewImage';
import ModalPreviewPdf from '../../compPeserta/previewPdf';
import { alert } from 'devextreme/ui/dialog';

class PembayaranMpFileSkModal extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData: {}
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()

        this.formItem = [
            {
                dataField: 'skNo',
                label: {
                    alignment: 'left',
                    text: 'No SK'
                },
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async() => {
                        await this.submitData()
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
            dataMaster: {}
        });
    }

    submitData = async() => {
        var data = this.state.formData
        this.props.submitData(data)

        let title = "SUCCESS";
        let text = "Data Berhasil Di Unggah!";
        
        await alert(text, title)
        this.hide()
    }
    
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formatNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formatNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    formData: {
                        ...prevState.formData,
                        [`${fieldName}Name`]: files.name,
                        [`${fieldName}`]: formattedFileData.base64data,
                        [`${fieldName}ContentType`]: formattedFileData.mime
                    }
                }))


            }
            
            fr.readAsDataURL(files)
        }
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`]),this.state.formData[`${fileName}Name`],this.state.formData[`${fileName}Extension`])
    }

    previewFile = async(fileName) => {
        var message = 'Preview data hanya bisa dimunculkan jika ekstensi berbentuk pdf dan gambar!'
        var result = await alert(message, 'Informasi!')
        if(result){
            var src = imageSource(this.state.formData[`${fileName}ContentType`],this.state.formData[`${fileName}`])
            this.modalPreviewPdfRef.current.show()
            this.modalPreviewPdfRef.current.retrieveData(src)
        }
    }

    retrieveData = (data) => {
        this.setState({
            formData: data
        })
    }
    
    
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Surat Keputusan MP Berkala'} 
                minWidth={500}
                minHeight={500}
                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formData'}
                                formData={this.state.formData}
                                items={this.formItem}
                                labelLocation = "left"
                            />
                        </div>
                        <div className="col-md-6">
                            <Box 
                                direction={'row'}
                                width={'100%'}
                            >
                                <Item ratio={1}>
                                    <FileUploader 
                                        accept={'image/png, image/jpeg'} 
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)} 
                                        labelText="Upload File Here"
                                        showFileList={false}
                                        name={'skFile'}
                                    />
                                </Item>
                            </Box>
                        </div>
                        <div className="col-md-6 text-right mt-5">
                            <button onClick={() => this.previewFile('skFile')} className={`btn btn-light btn-sm border rounded ${this.state.formData.skFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                            <button onClick={() => this.downloadFile('skFile')} className={`btn btn-light btn-sm border rounded ${this.state.formData.skFile ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Download
                            </button>
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="text-center mt-5">
                                <img className={`${this.state.formData.skFile || this.state.formData.skFileContentType ? '' : 'd-none'}`} src={this.state.formData.skFile ? imageSource(this.state.formData.skFileContentType, this.state.formData.skFile) : ''} alt="File is not image extension" width="30%"/>
                            </div>
                        </div>
                    </div>
                </div>
                </ScrollView>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </Popup>
        )
    }
}

export default PembayaranMpFileSkModal