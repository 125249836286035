import React, { Component } from "react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { findLastDayOfMonth, formatDate, formatDefaultFullDate } from 'plugin/helper'
import { alert } from 'devextreme/ui/dialog'
import { reportFile } from 'plugin/reportRequest'
import { showLoading } from 'redux/actions/loading';
import httpRequest from "plugin/httprequest";
import showloading from "redux/reducers/loading";


class LaporanHasilInvestasiAkunting extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                productId:1,
                startDate: new Date(new Date().getFullYear(), 0, 1),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.dataGridRef = React.createRef()
        this.dataResult = []

        this.columns = [
            // {
            //     dataField: 'id',
            //     caption: "No.",
            //     cellRender: (data) => {
            //         return data.rowIndex + 1
            //     },
            //     fixed: true
            // },
            {
                // dataField: 'categoryName',
                // caption: 'Jenis Investasi',
                // calculateCellValue: (e) => {
                //     return e.categoryName + ' - ' + e.subName
                // }
                // cellRender : (e) => {
                //     var paddingSize = 20 * e.data.level
                //     return <span style={{paddingLeft: `${paddingSize}px`}}>{e.text}</span>
                // }
                dataField: 'description',
                caption: 'Jenis Investasi',
                fixed: true
            },
            {
                dataField: 'hasilInvestasi',
                caption: 'Hasil Investasi Yang Terealisasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bunga',
                        caption: 'Bunga/Bagi Hasil',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'deviden',
                        caption: 'Deviden',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'sewa',
                        caption: 'Sewa',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'laba_rugi',
                        caption: 'Laba/Rugi Pelepasan',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'lainnya',
                        caption: 'Lainnya',
                        alignment: 'right',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    // {
                    //     dataField: 'unrealizeGainloss',
                    //     caption: 'Hasil yang belum Teralisasi',
                    //     alignment: 'right',
                    //     dataType: 'number',
                    //     format: '#,##0.00',
                    // },
                    // {
                    //     dataField: 'bebanInvestasi',
                    //     caption: 'Beban Investasi',
                    //     alignment: 'right',
                    //     dataType: 'number',
                    //     format: '#,##0.00',
                    // },
                    // {
                    //     dataField: 'netRealizeGainloss',
                    //     caption: 'Hasil Investasi Bersih Terealisasi',
                    //     alignment: 'right',
                    //     dataType: 'number',
                    //     format: '#,##0.00',
                    // },
                    // {
                    //     dataField: 'avgInvestasi',
                    //     caption: 'Rata-rata Investasi',
                    //     alignment: 'right',
                    //     dataType: 'number',
                    //     format: '#,##0.00',
                    // },
                    // {
                    //     dataField: 'roi',
                    //     caption: 'ROI',
                    //     alignment: 'right',
                    //     dataType: 'number',
                    //     format: '#,##0.00',
                    // },
                ]
            },
            {
                dataField: 'delta_spi',
                caption: 'Hasil Inv. Belum Terealisasi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'beban_investasi',
                caption: 'Beban Investasi',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'hasil_bersih',
                caption: 'Hasil Bersih',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            }
        ]

        this.filterItem = [
            {
              dataField: "endDate",
              label: {
                text: "Periode",
                alignment: "left",
                location: "left"
              },
              editorType: "dxDateBox",
              editorOptions: {
                useMaskBehavior: true,
                width: '100%',
                displayFormat: 'MMM yyyy',
                calendarOptions: {
                    maxZoomLevel: 'year'
                },
                openOnFieldClick: true
              }
            },
            {
              dataField: "productId",
              label: {
                text: "Produk",
                alignment: "left",
                location: "left"
              },
              editorType: "dxSelectBox",
              editorOptions: {
                dataSource: product(this.props.store),
                displayExpr: function(item){
                  return item && item.productCode + " - " + item.productName;
                },
                valueExpr: 'id',
                searchEnabled: true,
                deferRendering: false,
              }
            },
            {
              itemType: "button",
              buttonOptions: {
                text: "Filter",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: async() => {
                //   this.treeListRef.current.forceRefresh(true)
                // await alert('Under Development', 'Information!');
                    this.filterData()

                    let selectedDate = new Date(this.state.filterData.endDate)
                    
                    this.setState({
                        tanggal: selectedDate
                    })
                },
              },
              horizontalAlignment: "left"
            },
        ]

        this.sumHasilInvestasi = [
            {
                showInColumn: 'categoryName',
                displayFormat: 'Total :'
            },
            {
                column: 'bunga',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumBunga',
                displayFormat: '{0}'
            },
            {
                column: 'deviden',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumDeviden',
                displayFormat: '{0}'
            },
            {
                column: 'sewa',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumSewa',
                displayFormat: '{0}'
            },
            {
                column: 'laba_rugi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumLaba',
                displayFormat: '{0}'
            },
            {
                column: 'lainnya',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumLainnya',
                displayFormat: '{0}'
            },
            {
                column: 'delta_spi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumDeltaSpi',
                displayFormat: '{0}'
            },
            {
                column: 'beban_investasi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumBebanInvestasi',
                displayFormat: '{0}'
            },
            {
                column: 'hasil_bersih',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumHasilBersih',
                displayFormat: '{0}'
            }
        ]
    }

    getMonthYear = (date) => {
        if (date) {
          let period = formatDefaultFullDate(date).split(' ');
          period = `${period[1]} ${period[2]}`;
          return period;
        }
        return ''; // Handle the case when date is not available
      }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    // text: 'Cetak Data',
                    onClick: (e) => {
                        this.cetakData(this.state.filterData.startDate, this.state.filterData.endDate)
                    },
                }
            }
        )
    }

    // cetakData =  async(startDate, endDate) => {
    //     var response = this.dataGridRef.current.getDataSource()

    //     var transaksiDetail = []
    //     if(response.data.length > 0){
    //         var arrBunga = []
    //         var arrDeviden = []
    //         var arrSewa = []
    //         var arrLaba = []
    //         var arrLainnya = []
    //         var arrunrealizeGainloss = []
    //         var arrBebanInvest = []
    //         var arrnetRealizeGainloss = []
    //         var arrInvestRata = []
    //         var arrRoi = []

    //         for(var value of response.data){
    //             arrBunga.push(value.bunga)
    //             arrDeviden.push(value.deviden)
    //             arrSewa.push(value.sewa)
    //             arrLaba.push(value.realizeGainloss)
    //             arrLainnya.push(value.lainnya)
    //             arrunrealizeGainloss.push(value.unrealizeGainloss)
    //             arrBebanInvest.push(value.bebanInvestasi)
    //             arrnetRealizeGainloss.push(value.netRealizeGainloss)
    //             arrInvestRata.push(value.avgInvestasi)
    //             arrRoi.push(value.roi)

    //             var dataResult = {
    //                 no: 1,
    //                 categoryName: `${value.categoryName} - ${value.subName}`,
    //                 bunga: value.bunga,
    //                 deviden: value.deviden,
    //                 sewa: value.sewa,
    //                 realizeGainloss: value.realizeGainloss,
    //                 lainnya: value.lainnya,
    //                 unrealizeGainloss: value.unrealizeGainloss,
    //                 bebanInvestasi: value.bebanInvestasi,
    //                 netRealizeGainloss: value.netRealizeGainloss,
    //                 avgInvestasi: value.avgInvestasi,
    //                 roi: value.roi
    //             }
    //             transaksiDetail.push(dataResult)

    //             var sumBunga = arrBunga.reduce((a, b) => a + b, 0)
    //             var sumDeviden = arrDeviden.reduce((a, b) => a + b, 0)
    //             var sumSewa = arrSewa.reduce((a, b) => a + b, 0)
    //             var sumLaba = arrLaba.reduce((a, b) => a + b, 0)
    //             var sumLainnya = arrLainnya.reduce((a, b) => a + b, 0)
    //             var sumunrealizeGainloss = arrunrealizeGainloss.reduce((a, b) => a + b, 0)
    //             var sumbebanInvestasi = arrBebanInvest.reduce((a, b) => a + b, 0)
    //             var sumnetRealizeGainloss = arrnetRealizeGainloss.reduce((a, b) => a + b, 0)
    //             var sumavgInvestasi = arrInvestRata.reduce((a, b) => a + b, 0)
    //             var sumroi = arrRoi.reduce((a, b) => a + b, 0)
    //         }

    //         reportFile({
    //             template: { 'shortid': 'rhNhepq' },
    //             data: {
    //                 "startDate": formatDefaultFullDate(startDate),
    //                 "endDate": formatDefaultFullDate(endDate),
    //                 "dailyBalanceDetail": transaksiDetail,
    //                 // "pengelola": kpdData.noKontrak,
    //                 "sumBunga": sumBunga,
    //                 "sumDeviden": sumDeviden,
    //                 "sumSewa": sumSewa,
    //                 "sumLaba": sumLaba,
    //                 "sumLainnya": sumLainnya,
    //                 "sumunrealizeGainloss": sumunrealizeGainloss,
    //                 "sumbebanInvestasi": sumbebanInvestasi,
    //                 "sumnetRealizeGainloss": sumnetRealizeGainloss,
    //                 "sumavgInvestasi": sumavgInvestasi,
    //                 "sumroi": sumroi
    //             },
    //             options: {
    //                 reportName: "Hasil Invetasi"
    //             }
    //         }, true, `Hasil Invetasi.pdf`)
    //     }
    // }

     cetakData =  async(startDate, endDate) => {
        let response = this.dataGridRef.current.getDataSource()
        let periodeYear = new Date(this.state.filterData.endDate).getFullYear();
        let periodeMonth = new Date(this.state.filterData.endDate).getMonth();
        let date = findLastDayOfMonth(periodeYear, periodeMonth);

        let firstDateOfYear = new Date(periodeYear, 0, 1);
        
        let transaksiDetail = []
        if (response.length > 0) {
            let arrBunga = [];
            let arrDeviden = [];
            let arrSewa = [];
            let arrLaba = [];
            let arrLainnya = [];
            let arrDeltaSpi = [];
            let arrBebanInvestasi = [];
            let arrHasilBersih = [];

            for (let value of response) {
                arrBunga.push(value.bunga);
                arrDeviden.push(value.deviden);
                arrSewa.push(value.sewa);
                arrLaba.push(value.laba_rugi);
                arrLainnya.push(value.lainnya);
                arrDeltaSpi.push(value.delta_spi);
                arrBebanInvestasi.push(value.beban_investasi);
                arrHasilBersih.push(value.hasil_bersih);
                let dataValue = {
                    "no": 1,
                    "beban_investasi": value.beban_investasi,
                    "bunga": value.bunga,
                    "delta_spi": value.delta_spi,
                    "description": value.description,
                    "deviden": value.deviden,
                    "hasil_bersih": value.hasil_bersih,
                    "laba_rugi": value.laba_rugi,
                    "lainnya": value.lainnya,
                    "seq_no": value.seq_no,
                    "sewa": value.sewa
                }
                transaksiDetail.push(dataValue);

                var sumBunga = arrBunga.reduce((a, b) => a + b, 0);
                var sumDeviden = arrDeviden.reduce((a, b) => a + b, 0);
                var sumSewa = arrSewa.reduce((a, b) => a + b, 0);
                var sumLaba = arrLaba.reduce((a, b) => a + b, 0);
                var sumLainnya = arrLainnya.reduce((a, b) => a + b, 0);
                var sumDeltaSpi = arrDeltaSpi.reduce((a, b) => a + b, 0);
                var sumBebanInvestasi = arrBebanInvestasi.reduce((a, b) => a + b, 0);
                var sumHasilBersih = arrHasilBersih.reduce((a, b) => a + b, 0);
            }

            let periodDate = formatDefaultFullDate(this.state.filterData.endDate).split(' ')
            periodDate = `${periodDate[1]} ${periodDate[2]}`

            reportFile({
                template: { 'shortid': 'ayiJD8i' },
                data: {
                    "startDate": formatDefaultFullDate(firstDateOfYear),
                    "endDate": formatDefaultFullDate(date),
                    "productId": this.state.filterData.productId === 1 ? 'PROGRAM PENSIUN MANFAAT PASTI' : this.state.filterData.productId === 2 ? 'PROGRAM PENSIUN IURAN PASTI' : null,
                    "dailyBalanceDetail": transaksiDetail,
                    "sumBunga": sumBunga,
                    "sumDeviden": sumDeviden,
                    "sumSewa": sumSewa,
                    "sumLaba": sumLaba,
                    "sumLainnya": sumLainnya,
                    "sumDeltaSpi": sumDeltaSpi,
                    "sumBebanInvestasi": sumBebanInvestasi,
                    "sumHasilBersih": sumHasilBersih
                },
                options: {
                    reportName: `Hasil Investasi ${periodDate}`
                }
            }, true, `Hasil Investasi ${periodDate}.pdf`);
        }
    }

    filterData = async () => {
        try {
            this.props.store.dispatch(showLoading(true))
            let periodeYear = new Date(this.state.filterData.endDate).getFullYear();
            let periodeMonth = new Date(this.state.filterData.endDate).getMonth();
            let date = findLastDayOfMonth(periodeYear, periodeMonth);
            let productId = this.state.filterData.productId;

            let data = [];
            let loadAPI = `accounting-report/getHasilInvestasi/${formatDate(date)}/${productId}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET');

            let counter = 1;

            for (let value of response) {
                let dataValue = {
                    id: counter++,
                    "beban_investasi": value.beban_investasi,
                    "bunga": value.bunga,
                    "delta_spi": value.delta_spi,
                    "description": value.description,
                    "deviden": value.deviden,
                    "hasil_bersih": value.hasil_bersih,
                    "laba_rugi": value.laba_rugi,
                    "lainnya": value.lainnya,
                    "seq_no": value.seq_no,
                    "sewa": value.sewa
                }   
                data.push(dataValue);
            }
            
            this.dataResult = data;
            this.dataGridRef.current.forceRefresh(true)
            this.props.store.dispatch(showLoading(false));
        } catch (error) {
            console.log(error);
        }
    }

    generateBgColor = (e) => {
        // const y = e.key
        // const x = parseInt(e.column.dataField)
        // return this.dictionary.find(value => value.coordinate.find(coor => coor[0] === y && coor[1] === x))
    }

    onCellPrepared = (e) => {
        if (e.rowType == 'header') {
            const dictFound = this.generateBgColor(e)
            if (dictFound) {
                e.cellElement.style.backgroundColor = dictFound.color
            }
        }
    }

    loadData = async () => {
        return this.dataResult
        // try {
        //     let data = [];
        //     let loadAPI = `accounting-report/getHasilInvestasi/${formatDate(this.state.filterData.startDate)}/${this.state.filterData.productId}`
        //     let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET');

        //     for (let value of response) {
        //         let dataValue = {
        //             id: value,
        //             "beban_investasi": value.beban_investasi,
        //             "bunga": value.bunga,
        //             "delta_spi": value.delta_spi,
        //             "description": value.description,
        //             "deviden": value.deviden,
        //             "hasil_bersih": value.hasil_bersih,
        //             "laba_rugi": value.laba_rugi,
        //             "lainnya": value.lainnya,
        //             "seq_no": value.seq_no,
        //             "sewa": value.sewa
        //         }   
        //         data.push(dataValue);
        //     }

        //     return data;
        // } catch (error) {
        //     console.log(error);
        // }
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Laporan Hasil Investasi</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                    // labelLocation={'left'}
                />
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    // loadAPI={`get-investasi-hasil-report?endDate=${formatDate(this.state.filterData.endDate)}&productId=${this.state.filterData.productId}&startDate=${formatDate(this.state.filterData.startDate)}`}
                    loadAPI={`accounting-report/getHasilInvestasi/${formatDate(this.state.filterData.startDate)}/${this.state.filterData.productId}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_ACC}
                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={`Hasil Investasi ${this.getMonthYear(this.state.tanggal)}`}
                    allowExportSelectedData={true}
                    selection={"none"}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            `bunga`,
                            `deviden`,
                            `sewa`,
                            `laba_rugi`,
                            `lainnya`,
                            `delta_spi`,
                            `beban_investasi`,
                            `hasil_bersih`
                        ]
                    }

                    showBorders={true}

                    paging={false}
                    showPageSizeSelector = {false}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 250px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumHasilInvestasi}

                    onToolbarPreparing={this.onToolbarPreparing}
                    onCellPrepared={this.onCellPrepared}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanHasilInvestasiAkunting