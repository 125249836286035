import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, kpds, productDefault, managerInvestasis, instrumentReksadana, fundManager, emitens, custodies, stock } from 'dataSource/lookup';
import { formatUploadFileData, imageSource } from 'plugin/helper';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';


class ModalSettlementPenyelesaianAllotmentIpo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        if (this.validate()) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]


        this.items = [
            {
                itemType: 'group',
                caption: 'Informasi Penyertaan',
                name: 'informasiPenyertaan',
                items: [
                    {
                        dataField: 'id',
                        label: {
                            alignment: 'left',
                            text: 'ID Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'settleNo',
                        label: {
                            alignment: 'left',
                            text: 'No. Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pengelolaan',
                        label: {
                            alignment: 'left',
                            text: 'Pengelolaan'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: 'horizontal',
                            items: [
                                'Internal',
                                'KPD',
                            ],
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            alignment: 'left',
                            text: 'KPD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            onValueChanged: (e) => {
                                if (e.value == 1) {
                                    this.formRef.current.instance.updateData('pengelolaan', 'Internal')
                                } else {
                                    this.formRef.current.instance.updateData('pengelolaan', 'KPD')
                                }
                            },
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk Dapen'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.productCode + ' - ' + e.productName
                            },
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'emitenId',
                        label: {
                            alignment: 'left',
                            text: 'Emiten'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: emitens(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'emitenName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'amountNet',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'transactionDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'paymentDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'settleDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Settle'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'custodyId',
                        label: {
                            alignment: 'left',
                            text: 'Kustodi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'custodyName',
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Allotment',
                name: 'allotment',
                items: [
                    {
                        dataField: 'stockCode',
                        label: {
                            alignment: 'left',
                            text: 'Kode Saham'
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Kode Saham tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'stockName',
                        label: {
                            alignment: 'left',
                            text: 'Nama Saham'
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Nama Saham tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'jumlahLembarPenjatahan',
                        label: {
                            alignment: 'left',
                            text: 'Jumlah Lembar Penjatahan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Jumlah Lembar tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'nominalPenjatahan',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Penjatahan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            onValueChanged: (e) => {
                                var nomPenyertaan = this.formRef.current.instance.getEditor('amountNet').option('value')
                                var nomBiayaPenyertaan = this.formRef.current.instance.getEditor('nominalBiayaPenyertaan').option('value')
                                var nomPenjatahan = e.value

                                this.formRef.current.instance.updateData({
                                    nominalRefund: nomPenyertaan - nomPenjatahan,
                                    nominalRefundBersih: nomPenyertaan - nomPenjatahan - nomBiayaPenyertaan
                                })
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Nominal tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'nominalBiayaPenyertaan',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Biaya Penyertaan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            onValueChanged: (e) => {
                                var nomPenyertaan = this.formRef.current.instance.getEditor('amountNet').option('value')
                                var nomBiayaPenyertaan = e.value
                                var nomPenjatahan = this.formRef.current.instance.getEditor('nominalPenjatahan').option('value')

                                this.formRef.current.instance.updateData({
                                    nominalRefundBersih: nomPenyertaan - nomPenjatahan - nomBiayaPenyertaan
                                })
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Nominal Biaya Penyertaan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'nominalRefund',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Refund'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nominalRefundBersih',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Refund Bersih'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        },
                    },
                    {
                        dataField: "file",
                        label: {
                            text: "File Settle",
                            alignment: 'left'
                        },
                        editorType: "dxFileUploader",
                        editorOptions: {
                            selectButtonText: "Select File",
                            labelText: "Upload File",
                            uploadMode: "useForm",
                            accept: 'image/png, image/jpeg, application/pdf',
                            onValueChanged: this.onUploadStarted.bind(this),
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'File Settle tidak boleh kosong'
                            }
                        ]
                    },
                ]
            },

        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show = async (data) => {
        var dataOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-ipos/${data.orderDetailId}`, 'GET')
        data.emitenId = dataOrder.emitenId
        this.setState({
            popupVisible: true,
            dataMaster: data,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    previewFile = async () => {
        var src = imageSource(this.state.dataMaster.fileSettleContentType, this.state.dataMaster.fileSettle)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState => ({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Settle`]: formattedFileData.base64data,
                        [`${fieldName}SettleContentType`]: formattedFileData.mime,
                        [`fileName`]: files.name,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }
    submitData = async () => {
        var data = this.state.dataMaster
        var dataSubmit = {
            "bankId": data.bankId,
            "broketId": data.brokerId,
            "custodyId": data.custodyId,
            "emitenId": data.emitenId,
            "fileSettle": data.fileSettle,
            "fileSettleContentType": data.fileSettleContentType,
            "jumlahPenjatahan": data.jumlahLembarPenjatahan,
            "kpdId": data.kpdId,
            "noTransaction": data.settleNo,
            "nominalBiayaPenyertaan": data.nominalBiayaPenyertaan,
            "nominalPenjatahan": data.nominalPenjatahan,
            "nominalPenyertaan": data.amountNet,
            "nominalRefund": data.nominalRefund,
            "nominalRefundBersih": data.nominalRefundBersih,
            "paymentDate": data.transactionDate,
            "pengeloaan": data.pengelolaan,
            // "price": ,
            "productId": data.productId,
            "settleDate": data.settleDate,
            "stockCode": data.stockCode,
            "stockName": data.stockName,
            "tanggalTransaksi": data.transactionDate,
            "transactionDate": data.transactionDate,
            "transactionId": data.id
        }
        if (dataSubmit) {
            try {
                var insertAPI = 'order-ipos/ipo-allotment-save'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: dataSubmit
                })

                notify({ message: 'Allotment berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
        this.hide()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Allotment IPO'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    ref={this.formRef}
                                    items={this.items}
                                    labelLocation={"left"}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.dataMaster.fileSettle ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                                <ModalPreviewPdf
                                    ref={this.modalPreviewPdfRef}
                                    store={this.props.store}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalSettlementPenyelesaianAllotmentIpo