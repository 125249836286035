import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import { asetKategoriSdm } from 'dataSource/lookup';
import notify from 'devextreme/ui/notify'

class FormModalSubKategoriAset extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            newData: true
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.formItems = [
            {
                dataField:"kodeAsset",
                label: {
                    text: "Kode Sub Kategori" 
                },
            },
            {
                dataField:"categorySubName",
                label: {
                    text: "Nama Sub Kategori"
                },
            },
            {
                dataField:"assetCategoryId",
                label: {
                    text: "Kategori Aset",
                },
                editorType: 'dxSelectBox',
                editorOptions : {
                    dataSource: asetKategoriSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.categoryCode + ' - ' + item.categoryName
                    }
                },
            },
            {
                dataField:"akunId",
                label: {
                    text: "Kategori Aset",
                },
                editorType: 'dxSelectBox',
                editorOptions : {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.akunCode + ' - ' + item.akunName
                    }
                },
            },
            {
                dataField:"akumDepresiasiAkunId",
                label: {
                    text: "Kategori Aset",
                },
                editorType: 'dxSelectBox',
                editorOptions : {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.akunCode + ' - ' + item.akunName
                    }
                },
            },
            {
                dataField:"depresiasiAkunId",
                label: {
                    text: "Kategori Aset",
                },
                editorType: 'dxSelectBox',
                editorOptions : {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.akunCode + ' - ' + item.akunName
                    }
                },
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.updateData();
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    retrieveData = async (data, response) => {
        if(response) {
            this.formRef.current.instance.getEditor('akunId').option('dataSource', response);
            this.formRef.current.instance.getEditor('akumDepresiasiAkunId').option('dataSource', response);
            this.formRef.current.instance.getEditor('depresiasiAkunId').option('dataSource', response);
        }
        this.setState({
            dataMaster: data
        })
    }

    updateData = async () => {
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-category-subs', 'PUT', {
                key: data.id,
                values: data
            }, data);

            if (response) {
                this.hide();
                notify({ message: 'Data Berhasil Diubah', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Sub Kategori Aset'}
                width={"30vw"}
                height={"auto"}
                toolbarItems={this.PopupToolbarItem}
                >
                <Form
                    ref={this.formRef}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.formItems}
                    labelLocation='left'
                />
            </Popup>
        )
    }
}

export default FormModalSubKategoriAset