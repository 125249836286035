import React, { Component } from 'react'
import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Legend,
    Margin,
    Tooltip,
    Grid
} from 'devextreme-react/chart';
import { akunInvestasi, getKinerjaInv, product, productDefault } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import httpRequest from 'plugin/httprequest'

class KinerjaInvestasi extends Component {
    constructor(props) {
        super(props)

        this.modalKajianRef = React.createRef()
        this.modalInstrumentRef = React.createRef()
        this.modalKomiteRef = React.createRef()
        this.dgRef = React.createRef()

        // this.state = {
        //     filterData: {
        //         productId: 1,
        //     },
        // }

        this.series = [
            { valueField: "komposisi", name: "Komposisi" },
            { valueField: "roi", name: "ROI" },
        ]
        // this.dataSource = []

        this.filterItem = [
            {
                itemType: "button",
                buttonOptions: {
                    icon: 'refresh',
                    text: "Refresh",
                    type: "default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.setState({
                            filterData: {
                                productId: 1,
                            }
                        })
                    },
                },
                horizontalAlignment: "left"
            },
        ]
    }

    // handleChange = async (e) => {
    //     var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-roi-per-instruments?productId.in=${this.state.filterData.productId} `, 'GET')
    //     var data = response
    //     this.dataSource = data
    // }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Chart id="chart"
                            dataSource={getKinerjaInv(this.props.store)}
                            series={this.series}
                        // onOptionChanged={this.handleChange}
                        >
                            <CommonSeriesSettings
                                argumentField="categoryName"
                                type="bar"
                                hoverMode="allArgumentPoints"
                            >
                            </CommonSeriesSettings>
                            <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                            <Tooltip enabled={true} format={'fixedPoint'} zIndex={5} />
                        </Chart>
                    </div>
                    {/* <div className="col-md-12" style={{ paddingBottom: '10px' }}>
                        <Form
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div> */}
                </div>
            </div>
        )
    }
}

export default KinerjaInvestasi
