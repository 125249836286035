import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, pensiunType } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import ShowModalActionPesertaArsip from 'components/pages/modal/kepesertaan/pesertaDapen/arsip/edit';

class PesertaArsip extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            dataMaster: {},
            pemberiKerja: {},
            getUrl: 'view-pesertas?statusPeserta.in=12'
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalActionRef = React.createRef()

        this.PengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'View',
                        hint: 'View',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'Id',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                name:'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'arsipDate',
                caption: 'Tanggal Pengarsipan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            // {
            //     dataField: 'pensiunType',
            //     caption: 'Jenis Pensiun',
            //     lookup: {
            //         dataSource: pensiunType(this.props.store),
            //         valueExpr: "id", // contains the same values as the "statusId" field provides
            //         displayExpr: "statusName" // provides display values
            //     }
            // },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'tanggalBerhenti',
                caption: 'Tanggal Berhenti',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            // {
            //     dataField: 'pensiunDate',
            //     caption: 'Tanggal Pensiun',
            //     dataType: 'date',
            //     format: 'dd MMM yyyy',
            // },
            {
                dataField: 'arsipSkNo',
                caption: 'No SK Pengarsipan',
            }
        ]


        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        var loadAPI
                        if(data.value){
                            loadAPI = `view-pesertas?participantId.in=${data.value}&statusPeserta.in=12`
                        }else{
                            loadAPI = 'view-pesertas?statusPeserta.in=12'
                            // loadAPI = 'simple-pesertas/getNewSimpleAll?statusPeserta.in=1'
                        }
                        this.setState({
                            getUrl: loadAPI
                        })
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (data = {}) => {
        this.modalActionRef.current.show()
        this.modalActionRef.current.retrieveData(data.id)
    }

    retrieveData = async() => {
        var result = [
            {
                id:1
            }
        ]
        return result
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Pengajuan',
                    icon: "plus",
                    onClick: (e) => {
                        this.showModalPengajuanDplk('add')
                    },
                }
            }
        );
    }

    kirim = async () => {
        var text = `Data berhasil dikirim!`
        var type = 'success'
        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Peserta Arsip</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div className={' mt-2 text-center'} style={{ height: '100%' }}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI={this.state.getUrl}
                        insertAPI='pesertas'
                        updateAPI='pesertas'
                        deleteAPI='pesertas'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        remoteOperations={true}
                        
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Peserta Arsip"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Peserta Arsip'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.PengajuanColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        height={'calc(100vh - 300px)'}

                        onToolbarPreparing={this.onToolbarPreparing}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ShowModalActionPesertaArsip
                        ref={this.modalActionRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'view'}
                    />
                </div>
            </div>
        )
    }
}

export default PesertaArsip