import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import { Form } from "devextreme-react";

class HistoryApprovalPengajuan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: this.props.store.getState().sessionUser,
            endDate: this.getNowDate(),
            startDate: this.getStartDate(),
            formFilter: {
                startDate: null,
                endDate: null
            }
        }

        this.dataGridRef = React.createRef();
        this.filterDatas = [];

        this.columns = [
            {
                dataField: 'noPengajuan',
                caption: 'Nomor Pengajuan',
            },
            {
                dataField: 'operation',
                caption: 'Operation'
            },
            {
                dataField: 'taskName',
                caption: 'Task Name'
            },
            {
                dataField: 'notes',
                caption: 'Note'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Approved'
                        },
                        {
                            id: 2,
                            value: 'Rejected'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.filterItem = [
            {
                dataField: 'startDate',
                label : {
                    text: 'Start Date',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    // placeholder: 'dd/mm/yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'endDate',
                label : {
                    text: 'End Date',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    // placeholder: 'dd/mm/yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    // width: '100%',
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    type:"default",
                    onClick:() => {
                        this.filterData()
                    },
                },
                horizontalAlignment:"left"
            },
            // {
            //     editorType: 'dxButton',
            //     editorOptions: {
            //         text: 'Filter',
            //         onClick:() => {
            //             this.dataGridRef.current.forceRefresh(true)
            //         },
            //     }
            // }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    componentDidMount = () => {
        this.defaultData();
    }
    filterData = async () => {
        const Data = this.state.formFilter;
        const Username = this.state.username
        
        let startDateState = new Date(Data.startDate);
        
        let startDateDay = ("0" + (startDateState.getDate())).slice(-2);
        let startDateMonth = ("0" + (startDateState.getMonth() + 1)).slice(-2);
        let startDateYear = new Date(Data.startDate.toDateString()).getFullYear();
        
        let endDateState = new Date(Data.endDate);

        let endDateDay = ("0" + (endDateState.getDate())).slice(-2);
        let endDateMonth = ("0" + (endDateState.getMonth() + 1)).slice(-2);
        let endDateYear = new Date(Data.endDate.toDateString()).getFullYear();

        let startDate = [startDateYear, startDateMonth, startDateDay].join('');
        let endDate = [endDateYear, endDateMonth, endDateDay].join('');

        let loadAPI = `pengajuanorder-wf/historic/tasks-action-by/${Username}/${startDate}/${endDate}`
        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
        
        if (response) {
            this.filterDatas = response;
        }

        this.forceRefresh();
    }
    getNowDate = () => {
        var todayTime = new Date()
        var month = ("0" + (todayTime.getMonth() + 1)).slice(-2);
        // month = '0' + month;
        // var day = todayTime.getDate() + 1
        var day = ("0" + todayTime.getDate()).slice(-2);
        // day = '0' + day;
        var year = todayTime.getFullYear()
        // var nowDate = [year, month, day].join('-')
        var nowDate = [year, month, day].join('')
        return nowDate
    }
    getStartDate = () => {
        var todayTime = new Date()
        // var month = todayTime.getMonth() + 1
        var month = ("0" + (todayTime.getMonth() - 5)).slice(-2);
        // month = '0' + month;
        var day = ("0" + todayTime.getDate()).slice(-2);
        // day = '0' + day;
        // var year = todayTime.getFullYear() - 1
        var year = todayTime.getFullYear();
        // var startDate = [year, month, day].join('-')
        var startDate = [year, month, day].join('')
        return startDate
    }
    forceRefresh = () => {
        if (this.dataGridRef.current) {
            this.dataGridRef.current.forceRefresh(true);
        }
    }
    defaultData = async () => {
        var username = this.state.username
        var startDate = this.getStartDate() 
        var endDate = this.getNowDate()
        // var loadAPI = `pengajuanorder-wf/historic/tasks-action-by?endDate=${endDate}&startDate=${startDate}&userId=${username}`
        var loadAPI = `pengajuanorder-wf/historic/tasks-action-by/${username}/${startDate}/${endDate}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        this.filterDatas = response
        if (response) {
            this.forceRefresh()
        }
    }
    dataSource = async () => {
        return this.filterDatas
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Riwayat Persetujuan Pengajuan Investasi</h2> 
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                    // ref={this.formFilterRef}
                />
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    keyField="taskId"
                    // loadAPI={`pengajuanorder-wf/historic/tasks-action-by?endDate=${this.state.endDate}&startDate=${this.state.startDate}&userId=${this.state.username}`}
                    loadAPI={`pengajuanorder-wf/historic/tasks-action-by/${this.state.username}/${this.state.startDate}/${this.state.endDate}`}
                    insertAPI='kecamatans'
                    updateAPI='kecamatans'
                    deleteAPI='kecamatans'

                    backendserver={process.env.REACT_APP_BACKEND}

                    useArraySource={true}
                    ArraySourceData={this.dataSource}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"History"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'History Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default HistoryApprovalPengajuan