import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, fundManager, emitens, currency, reksadana, instrumentSubCategoryReksadana, aperds, instrumentCategorySubs } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import { alert } from 'devextreme/ui/dialog';


class ModalInstrumentReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.productId = 0
        this.instSubCategoryId = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                colSpan: 2,
                items: [
                    {
                        dataField: 'noPengajuan',
                        label: {
                            text: 'No Pengajuan'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'miId',
                        label: {
                            text: 'Manager Investasi',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: managerInvestasis(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'miName',
                            onValueChanged: (e) => {
                                var selectInstrumentId = this.formRef.current.instance.getEditor('instrumentId')
                                selectInstrumentId.option('dataSource', reksadana(this.props.store, e.value))
                            },
                            searchEnabled: true
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Manager Investasi'
                            }
                        ]
                    },
                    {
                        dataField: 'instrumentId',
                        label: {
                            text: 'Instrument'
                        },
                        editorType: "dxSelectBox",
                        editorOptions: {
                            dataSource: reksadana(this.props.store),
                            valueExpr: 'id',
                            displayExpr: function (item) {
                                return item && item.instrumentCode + " - " + item.instrumentName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                console.log(e)
                                var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${e.value}`)
                                this.instSubCategoryId = instrument.categorySubId
                                var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`emitens/${instrument.emitenId}`)
                                var intrumenSub = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-category-subs/${instrument.categorySubId}`)
                                this.formRef.current.instance.updateData('emitenId', emiten.emitenName)
                                this.formRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)
                                this.formRef.current.instance.getEditor('infoCurrencyId').option('value', instrument.currencyId)
                                this.formRef.current.instance.getEditor('instSubCategoryId').option('value', instrument.categorySubId)
                                this.formRef.current.instance.getEditor('kategoriReksadana').option('value', intrumenSub.subName)
                                // this.formRef.current.instance.getEditor('rate').option('value', instrument.rating1Id)
                                this.formRef.current.instance.getEditor('isinCode').option('value', instrument.isinCode)
                                this.formRef.current.instance.getEditor('nabTerakhir').option('value', instrument.price)
                                this.formRef.current.instance.getEditor('tanggalHargaTerakhir').option('value', instrument.priceDate)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Instrument'
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'aperdId',
                        label: {
                            text: 'APERD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: aperds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'aperdName'
                        },
                    },
                    {
                        dataField: 'instSubCategoryId',
                        label: {
                            text: 'Sub Kategori',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentSubCategoryReksadana(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName'
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Sub Category'
                            }
                        ]
                    },
                    {
                        dataField: 'tanggalPembelian',
                        label: {
                            text: 'Tanggal Pembelian'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please fill Date'
                            }
                        ]
                    },
                    {
                        dataField: 'amountOrder',
                        label: {
                            text: 'Nominal Penempatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00"
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please fill Amount Order'
                            }
                        ]
                    },
                    {
                        dataField: 'feePercent',
                        label: {
                            text: 'Fee (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00"
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please fill Fee Percent'
                            }
                        ]
                    },
                    {
                        dataField: 'fee',
                        label: {
                            text: 'Fee Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00"
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please fill Fee Nominal'
                            }
                        ]
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: "dxSelectBox",
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true,
                            onValueChanged: async (e) => {
                                var currData = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${e.value}`, 'GET')
                                this.formRef.current.instance.updateData('kurs', currData.rate)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Please choose Currency'
                            }
                        ]
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        name: 'information',
                        caption: 'Informasi Reksadana',
                        colCount: 1,
                        items: [
                            {
                                dataField: 'emitenId',
                                label: {
                                    text: 'Penerbit Reksadana'
                                },
                                editorOptions: {
                                    dataSource: emitens(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'emitenName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                            {
                                dataField: 'kategoriReksadana',
                                label: {
                                    text: 'Kategori Reksadana'
                                },
                                editorOptions: {
                                    dataSource: instrumentCategorySubs(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'subName',
                                    readOnly: 'true'
                                },
                            },
                            {
                                dataField: 'infoCurrencyId',
                                label: {
                                    text: 'Mata Uang'
                                },
                                editorType: "dxSelectBox",
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyCode',
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                },
                            },
                            // {
                            //     dataField: 'rate',
                            //     label: {
                            //         text: 'Rating'
                            //     },
                            //     editorType: 'dxNumberBox',
                            //     editorOptions: {
                            //         format: '#,##0.00',
                            //         readOnly: true
                            //     }
                            // },
                            {
                                dataField: 'isinCode',
                                label: {
                                    text: 'ISIN CODE'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'nabTerakhir',
                                label: {
                                    text: 'NAB Terakhir'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'tanggalHargaTerakhir',
                                label: {
                                    text: 'Tanggal Harga Terakhir'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'd MMM yyyy',
                                    readOnly: true
                                }
                            },
                            // {
                            //     dataField: 'aum',
                            //     label: {
                            //         text: 'AUM'
                            //     },
                            //     editorType: 'dxNumberBox',
                            //     editorOptions: {
                            //         format: "#,##0.00",
                            //         readOnly: true
                            //     },
                            // },
                            // {
                            //     dataField: 'unitBeredar',
                            //     label: {
                            //         text: 'Unit Beredar'
                            //     },
                            //     editorType: 'dxNumberBox',
                            //     editorOptions: {
                            //         format: "#,##0.0000",
                            //         readOnly: true
                            //     },
                            // },
                            // {
                            //     dataField: 'kinerja1bulan',
                            //     label: {
                            //         text: 'Kinerja 1 Bulan'
                            //     },
                            //     editorType: 'dxNumberBox',
                            //     editorOptions: {
                            //         format: "#,##0.00 %",
                            //         readOnly: true
                            //     },
                            // },
                            // {
                            //     dataField: 'kinerja1tahun',
                            //     label: {
                            //         text: 'Kinerja 1 Tahun'
                            //     },
                            //     editorType: 'dxNumberBox',
                            //     editorOptions: {
                            //         format: "#,##0.00 %",
                            //         readOnly: true
                            //     },
                            // },
                            // {
                            //     dataField: 'kinerja2tahun',
                            //     label: {
                            //         text: 'Kinerja 2 Tahun'
                            //     },
                            //     editorType: 'dxNumberBox',
                            //     editorOptions: {
                            //         format: "#,##0.00 %",
                            //         readOnly: true
                            //     },
                            // },
                        ],
                    },
                    // {
                    //     itemType: 'group',
                    //     name: 'lastPrice',
                    //     caption: 'Informasi Harga Terakhir',
                    //     colCount: 1,
                    //     cssClass: 'mt-2',
                    //     items: [
                    //         {
                    //             dataField: 'nabPeluncuran',
                    //             label: {
                    //                 text: 'NAB Peluncuran'
                    //             },
                    //             editorType: 'dxNumberBox',
                    //             editorOptions: {
                    //                 format: "#,##0.0000",
                    //                 readOnly: true
                    //             },
                    //         },
                    //         {
                    //             dataField: 'nabTertinggi',
                    //             label: {
                    //                 text: 'NAB Tertinggi'
                    //             },
                    //             editorType: 'dxNumberBox',
                    //             editorOptions: {
                    //                 format: "#,##0.0000",
                    //                 readOnly: true
                    //             },
                    //         },
                    //         {
                    //             dataField: 'nabTerakhir',
                    //             label: {
                    //                 text: 'NAB Terakhir'
                    //             },
                    //             editorType: 'dxNumberBox',
                    //             editorOptions: {
                    //                 format: "#,##0.0000",
                    //                 readOnly: true
                    //             },
                    //         },
                    //     ]
                    // },
                ]
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = (data) => {
        if(data){
            this.productId = data.productId
            this.setState({
                data: {
                    id: data.id,
                    noPengajuan: data.noPengajuan,
                    instrumentId: data.instrumentId,
                    aperdId: data.aperdId,
                    miId: data.miId,
                    instSubCategoryId: data.instSubCategoryId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nabTerakhir: data.nabTerakhir,
                    amountOrder: data.amountOrder,
                    feePercent: data.feePercent,
                    fee: data.fee,
                    totalPenempatan: data.totalPenempatan,
                    tanggalPembelian: data.tanggalPembelian,
                },
                dataDetail: {

                },
            });
        }   
    }

    hide() {
        this.productId = 0
        this.instSubCategoryId = 0
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.data
        let validateBatasan = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-validasi-pengajuan-inv/${this.productId}/${this.instSubCategoryId}/${data.amountOrder}`, 'GET')
        if (validateBatasan.length > 0) {
            if (validateBatasan[0].potensiselisih >= 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")

                this.props.submitDetailPenempatanData({
                    id: data.id,
                    instrumentId: data.instrumentId,
                    aperdId: data.aperdId,
                    emitenId: data.emitenId,
                    currencyId: data.currencyId,
                    kurs: data.kurs,
                    nabTerakhir: data.nabTerakhir,
                    amountOrder: data.amountOrder,
                    feePercent: data.feePercent,
                    fee: data.fee,
                    totalPenempatan: data.amountOrder + data.fee,
                    tanggalPembelian: data.tanggalPembelian,
                    miId: data.miId,
                    instSubCategoryId: data.instSubCategoryId,
                })

                this.hide()
            } else if (validateBatasan[0].potensiselisih < 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")
            } else {
                await alert("Terdapat Kesalahan Data", "Informasi")
            }
        } else {
            await alert("Batasan Resiko Belum di Setting", "Informasi")
        }
    }

    loadDetailPenempatan = () => {
        return []
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Penempatan Investasi Reksadana`}
                width={'90vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12" style={{ height: "calc(80vh - 100px)", overflowY: "auto" }}>
                            <Form
                                colCount={2}
                                id={'form'}
                                ref={this.formRef}
                                formData={this.state.data}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={this.props.type == 'view' ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalInstrumentReksadana
