import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import Form from 'devextreme-react/form';
import { Button, Popup, ScrollView, FileUploader } from 'devextreme-react';
import ViewPenggajianKaryawan from 'components/pages/modal/sdm-umum/payroll/gaji/slipGaji';
import { banks, months, programs, statusPembayaranRekapGaji, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import { download, formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';

import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import ProgressBar from 'devextreme-react/progress-bar'
import { showLoading } from 'redux/actions/loading';
import { alert } from 'devextreme/ui/dialog';

class FormModalPengajuanGajiKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            // selectedTabIndex: 0,
            DataRekap: {},
            DataMaster: {},
            DataDocument: {},
            popupVisible: false,
            uploadedData: [],
            valueData: 0,
        }

        this.formMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            alignment: 'left',
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                        },
                    },
                    {
                        dataField: 'pengajuanNo',
                        label: {
                            alignment: 'left',
                            text: 'No Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'pengajuanDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            // {
                            //     dataField:"per",
                            //     label:{
                            //         text:"Periode",
                            //         alignment:"left",
                            //     },
                            //     editorOptions:{
                            //         enabled:false
                            //     }
                            // },
                            {
                                dataField: "bulan",
                                label: {
                                    text: "Periode Bulan",
                                    alignment: "left",
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values
                                }
                            },
                            {
                                dataField: "tahun",
                                label: {
                                    text: "Tahun",
                                    alignment: "left",
                                },
                                editorType: 'dxNumberBox',
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [

                    {
                        dataField: 'tanggalDari',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Awal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'tanggalSampai',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Akhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'pembayaranDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    }
                ]
            }
        ]

        this.cicilanPinjaman = [
            {
                dataField: "pinjaman",
                caption: "Pinjaman",
            },
            {
                dataField: "karyawan",
                caption: "Karyawan",
            },
            {
                dataField: "nominalCicilan",
                caption: "Nominal Cicilan",
            },
        ]

        this.iuranPemberiKerja = [
            {
                dataField: "produkDapen",
                caption: "Produk Dapen",
            },
            {
                dataField: "peserta",
                caption: "Peserta",
            },
            {
                dataField: "iuranPeserta",
                caption: "Iuran Peserta",
            },
            {
                dataField: "iuranPemberiKerja",
                caption: "Iuran Pemberi Kerja",
            },
        ]

        this.formRekap = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            alignment: 'left',
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'program', // provides display values
                        },
                    },
                    {
                        dataField: 'noPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'No Pengajuan'
                        },
                    },
                    {
                        dataField: 'tglPengajuan',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: "bulan",
                        label: {
                            text: "Bulan",
                            alignment: "left",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: months(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        }
                    },
                    {
                        dataField: "tahun",
                        label: {
                            text: "Tahun",
                            alignment: "left",
                        }
                    },
                    {
                        dataField: 'tglAwal',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Awal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'tglAkhir',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Akhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                ]
            }
        ]

        this.rekapColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Slip Gaji',
                        hint: 'Slip Gaji',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPenggajianKaryawan()
                        }
                    },
                    {
                        text: 'Tunda',
                        hint: 'Tunda',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            // this.showModalPenggajianKaryawan()
                        }
                    },
                ],
            },
            {
                dataField: "nik",
                caption: "NIK",
            },
            {
                dataField: "fullName",
                caption: "Nama",
            },
            {
                dataField: "gajiPokok",
                caption: "Gaji Pokok",
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: "totalTunjangan",
                caption: "Total Tunjangan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "totalPotongan",
                caption: "Total Potongan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "gajiBruto",
                caption: "Gaji Bruto",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pphSebulanNett",
                caption: "PPH 21",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "nominalTf",
                caption: "Nominal Transfer",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "gajiRekNo",
                caption: "No Rekening",
                dataType: 'number',
            },
            {
                dataField: "gajiRekName",
                caption: "Nama Rekening",
            },
            {
                dataField: "gajiRekBankId",
                caption: "Bank",
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
        ]

        this.rekapTransaksiColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Jurnal',
                        hint: 'Lihat Jurnal',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            // this.showModalPenggajianKaryawan()
                        }
                    },
                ],
            },
            {
                dataField: "transactionTypeId",
                caption: "Transaksi",
                lookup: {
                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionTypeName'
                }
            },
            {
                dataField: "transactionTypeSubId",
                caption: "Sub Transaksi",
                lookup: {
                    dataSource: tipeSubTransaksiSdmUmum(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'tipeSubName'
                }
            },
            {
                dataField: "transactionNominal",
                caption: "Nominal",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "pokok",
                caption: "Pokok Pinjaman",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "bunga",
                caption: "Bunga Pinjaman",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: "statusId",
                caption: "Status",
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Rekap Gaji'
            },
            {
                id: 1,
                text: 'Rekap Transaksi'
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.prevTabIndex = 0
        this.popupPenggajianKaryawanRef = React.createRef()
        this.DataGridRekapGajiRef = React.createRef()
        this.DataGridRekapTrsRef = React.createRef()
        this.fileUploderRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.progressBarRef = React.createRef()
        this.dataRekapGaji = []
        this.dataRekapTrs = []
        this.tanggalPengajuan = ''
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    showModalPenggajianKaryawan = () => {
        this.popupPenggajianKaryawanRef.current.show()
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var fr = new FileReader();

            var formattedFileData;

            var DataDocument = { ...this.state.DataDocument };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                DataDocument[`${fieldName}Name`] = files.name;
                DataDocument[`${fieldName}File`] = formattedFileData.base64data;
                DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;

                this.setState({
                    DataDocument: DataDocument
                });
            };

            fr.readAsDataURL(files);
        }
    }

    previewFile = async (state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    downloadFile = (fileName) => {
        download(imageSource(this.state.DataDocument[`${fileName}FileContentType`], this.state.DataDocument[`${fileName}File`]), this.state.DataDocument[`${fileName}Name`])
    }

    show = async () => {
        this.setState({
            popupVisible: true
        })
    }

    generateNoPengajuan = async (isNew) => {
        if (isNew) {
            var pengajuanNo = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `generate-no-automatic/KaryawanCuti/pengajuanNo/PENGGAJIAN`, 'POST', {
                values: {}
            })

            var DataMaster = { ...this.state.DataMaster }
            DataMaster.pengajuanNo = pengajuanNo
            this.setState({
                DataMaster: DataMaster
            })
        } else {
            return null
        }
    }

    hide = () => {
        this.DataGridRekapGajiRef.current.emptyDeletedIndex()
        this.DataGridRekapTrsRef.current.emptyDeletedIndex()
        this.setState({
            DataRekap: {},
            DataMaster: {},
            DataDocument: {},
            popupVisible: false,
            uploadedData: [],
            valueData: 0,
            popupVisible: false
        })
        // document.getElementById('progress-bar').classList.add('d-none')
        this.props.forceRefresh()
        this.DataGridRekapGajiRef.current.forceRefresh()
        this.DataGridRekapTrsRef.current.forceRefresh()
    }

    // generateGaji = async () => {
    //     this.props.store.dispatch(showLoading(true))
    //     // document.getElementById('progress-bar').classList.remove('d-none')
    //     try {
    //         var loadApi = `pembayaran-umums/set-generate-gaji/{ProgramId}/{TanggalPengajuan}/{PeriodeTahun}/{PeriodeBulan}/{TanggalAwal}/{TanggalAkhir}/{TanggalPembayaran}/{User}`
    //         var Data = { ...this.state.DataMaster, ...this.state.DataDocument }
    //         Data.pembayaranDate = formatDate(Data.pembayaranDate)
    //         Data.pengajuanDate = formatDate(Data.pengajuanDate)
    //         Data.tanggalDari = formatDate(Data.tanggalDari)
    //         Data.tanggalSampai = formatDate(Data.tanggalSampai)
    //         var checkData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payrolls/getByBulanAndTahun/${Data.bulan}/${Data.tahun}`, 'GET')
    //         if (checkData.length) {
    //             this.props.store.dispatch(showLoading(false))
    //             await alert('Data sudah di generate', 'Informasi')

    //             return
    //         } else {
    //             var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
    //                 values: Data,
    //             })
    //             if (!response) {
    //                 this.tanggalPengajuan = ''
    //                 notify({ message: "Terjadi Kesalahan Generate", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    //             } else {

    //                 var dataRekapTrs = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payroll-by-transactions`, 'GET')
    //                 this.dataRekapTrs = dataRekapTrs
    //                 this.dataRekapGaji = response

    //                 notify({ message: `Generate Data Sukses!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
    //                 this.DataGridRekapGajiRef.current.forceRefresh(true)
    //                 this.DataGridRekapTrsRef.current.forceRefresh(true)

    //                 // var maxValue = dataRekapTrs.length
    //                 // this.setState({
    //                 //     valueData: maxValue
    //                 // })
    //                 // var currentValue = 1
    //                 // this.progressBarRef.current.instance.option('value', 1)
    //                 // this.progressBarRef.current.instance.option('max', maxValue)

    //                 // if (maxValue > 0) {
    //                 //     for (let i = 1; i < maxValue; i++) {
    //                 //         this.progressBarRef.current.instance.option('value', currentValue++)
    //                 //     }
    //                 // }    
    //             }
    //         }
    //     } catch (e) {
    //         this.tanggalPengajuan = ''
    //         console.log(e);
    //         notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    //         this.props.store.dispatch(showLoading(false))
    //     }
    //     this.props.store.dispatch(showLoading(false))
    // }

    // generateGaji = async () => {
    //     this.props.store.dispatch(showLoading(true))
    //     // document.getElementById('progress-bar').classList.remove('d-none')
    //     try {
    //         var Data = { ...this.state.DataMaster, ...this.state.DataDocument }

    //         var checkData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payrolls/getByBulanAndTahun/${Data.bulan}/${Data.tahun}`, 'GET')

    //         console.log(checkData);
    //         if (checkData.length && Data.statusId == 2) {
    //             this.props.store.dispatch(showLoading(false))
    //             await alert('Data sudah di generate', 'Informasi')

    //             return
    //         } else {
    //             var loadApi = `pembayaran-umums/set-generate-gaji/${Data.programId}/${formatDate(Data.pengajuanDate)}/${Data.tahun}/${Data.bulan}/${formatDate(Data.tanggalDari)}/${formatDate(Data.tanggalSampai)}/${formatDate(Data.pembayaranDate)}/${this.props.store.getState().sessionUser || null}`
    //             var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
    //                 values: {},
    //             })
    //             if (!response) {
    //                 this.tanggalPengajuan = ''
    //                 notify({ message: "Terjadi Kesalahan Generate", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

    //             } else {
    //                 var dataRekapTrs = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payroll-by-transactions`, 'GET')
    //                 this.dataRekapTrs = dataRekapTrs
    //                 this.dataRekapGaji = response

    //                 notify({ message: `Generate Data Sukses!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
    //                 this.DataGridRekapGajiRef.current.forceRefresh(true)
    //                 this.DataGridRekapTrsRef.current.forceRefresh(true)

    //                 // var maxValue = dataRekapTrs.length
    //                 // this.setState({
    //                 //     valueData: maxValue
    //                 // })
    //                 // var currentValue = 1
    //                 // this.progressBarRef.current.instance.option('value', 1)
    //                 // this.progressBarRef.current.instance.option('max', maxValue)

    //                 // if (maxValue > 0) {
    //                 //     for (let i = 1; i < maxValue; i++) {
    //                 //         this.progressBarRef.current.instance.option('value', currentValue++)
    //                 //     }
    //                 // }
    //             }

    //         }
    //     } catch (e) {
    //         this.tanggalPengajuan = ''
    //         console.log(e);
    //         notify({ message: "Terjadi Kesalahan Generate", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
    //         this.props.store.dispatch(showLoading(false))
    //     }
    //     this.props.store.dispatch(showLoading(false))
    // }

    generateGaji = async () => {
        this.props.store.dispatch(showLoading(true))
        // document.getElementById('progress-bar').classList.remove('d-none')
        try {
            var Data = { ...this.state.DataMaster, ...this.state.DataDocument }

            var getAllKaryawanPayrolls =  await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payrolls`, 'GET');

            for (let i = 0; i < getAllKaryawanPayrolls.length; i++){
                // console.log(getAllKaryawanPayrolls[i].statusId);
                var checkData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payrolls/getByBulanAndTahunAndStatusId/${Data.bulan}/${Data.tahun}/${getAllKaryawanPayrolls[i].statusId}`, 'GET')
                if (checkData.length && getAllKaryawanPayrolls[i].statusId === 2) {
                    this.props.store.dispatch(showLoading(false))
                    await alert('Data sudah di generate', 'Informasi')

                    return
                }

                if (checkData.length && getAllKaryawanPayrolls[i].statusId === 1) {
                    this.props.store.dispatch(showLoading(false));
                    await alert('Data sedang dalam status pengajuan', 'Informasi');

                    return;
                }
            }
            var loadApi = `pembayaran-umums/set-generate-gaji/${Data.programId}/${formatDate(Data.pengajuanDate)}/${Data.tahun}/${Data.bulan}/${formatDate(Data.tanggalDari)}/${formatDate(Data.tanggalSampai)}/${formatDate(Data.pembayaranDate)}/${this.props.store.getState().sessionUser || null}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: {},
            })
            if (!response) {
                this.tanggalPengajuan = ''
                notify({ message: "Terjadi Kesalahan Generate", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            } else {
                var dataRekapTrs = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payroll-by-transactions`, 'GET')
                this.dataRekapTrs = dataRekapTrs
                this.dataRekapGaji = response

                notify({ message: `Generate Data Sukses!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
                this.DataGridRekapGajiRef.current.forceRefresh(true)
                this.DataGridRekapTrsRef.current.forceRefresh(true)
            }
                //  } else {
                // var loadApi = `pembayaran-umums/set-generate-gaji/${Data.programId}/${formatDate(Data.pengajuanDate)}/${Data.tahun}/${Data.bulan}/${formatDate(Data.tanggalDari)}/${formatDate(Data.tanggalSampai)}/${formatDate(Data.pembayaranDate)}/${this.props.store.getState().sessionUser || null}`
                // var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                //     values: {},
                // })
                // if (!response) {
                //     this.tanggalPengajuan = ''
                //     notify({ message: "Terjadi Kesalahan Generate", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

                // } else {
                //     var dataRekapTrs = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-payroll-by-transactions`, 'GET')
                //     this.dataRekapTrs = dataRekapTrs
                //     this.dataRekapGaji = response

                //     notify({ message: `Generate Data Sukses!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
                //     this.DataGridRekapGajiRef.current.forceRefresh(true)
                //     this.DataGridRekapTrsRef.current.forceRefresh(true)

                // var maxValue = dataRekapTrs.length
                // this.setState({
                //     valueData: maxValue
                // })
                // var currentValue = 1
                // this.progressBarRef.current.instance.option('value', 1)
                // this.progressBarRef.current.instance.option('max', maxValue)

                // if (maxValue > 0) {
                //     for (let i = 1; i < maxValue; i++) {
                //         this.progressBarRef.current.instance.option('value', currentValue++)
                //     }
                // }
            // }
        } catch (e) {
            this.tanggalPengajuan = ''
            console.log(e);
            notify({ message: "Terjadi Kesalahan Generate", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            this.props.store.dispatch(showLoading(false))
        }
        this.props.store.dispatch(showLoading(false))
    }

    progressOnValueChanged = (e) => {
        if (e.value + 1 == this.state.valueData) {
            notify({ message: `Generate Data Sukses!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600)
            this.tanggalPengajuan = this.state.DataMaster.pengajuanDate
            this.DataGridRekapGajiRef.current.forceRefresh(true)
            this.DataGridRekapTrsRef.current.forceRefresh(true)
        }
    }

    loadDataRekapGaji = () => {
        return this.dataRekapGaji
    }

    loadDataRekapTrs = () => {
        return this.dataRekapTrs
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Penggajian'}
                width={'70vw'}
                heigth={'60vh'}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                        <div className="my-3">
                            <Form
                                colCount={2}
                                id={'formMaster'}
                                formData={this.state.DataMaster}
                                items={this.formMaster}
                                labelLocation={"left"}
                            />
                            <FileUploader
                                disabled={this.props.action == 'view' ? true : false}
                                accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText="Upload File"
                                showFileList={false}
                                name={"dokumen"}
                                value={this.state.uploadedData}
                                ref={this.fileUploderRef}
                            />
                            <div className="row">
                                <label className="col-2">File Name:</label>
                                <label className="col-8">{this.state.DataDocument.dokumenName}</label>
                            </div>
                            <div className="col-md-6 text-right">
                                <button onClick={() => this.previewFile(this.state.DataDocument, 'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                                <button onClick={() => this.downloadFile('dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Download
                                </button>
                            </div>
                            <ModalPreviewPdf
                                ref={this.modalPreviewPdfRef}
                                store={this.props.store}
                            />
                            <ModalPreviewImage
                                ref={this.modalPreviewImageRef}
                                store={this.props.store}
                            />
                        </div>
                        <Button
                            type={'default'}
                            elementAttr={{ class: "bg-dapen" }}
                            text={'Generate Gaji'}
                            onClick={(e) => { this.generateGaji(e) }}
                        />
                        {/* <div className='d-none' style={{ paddingTop: 20 + 'px' }} id="progress-bar">
                            <ProgressBar
                                ref={this.progressBarRef}
                                min={0}
                                onValueChanged={this.progressOnValueChanged}
                            />
                        </div> */}
                        <DevextremeTab
                            dataSource={this.TabDataSource}
                            onOptionChanged={this.onTabSelectionChanged}
                        />
                        <div id="tabindex-0" className={' mt-2 text-center'} style={{ height: "100%" }}>
                            {/* <div className="container-fluid"> */}
                            {/* <Form
                                colCount={2}
                                id={'formMaster'}
                                formData={this.state.DataRekap}
                                items={this.formRekap}
                                labelLocation={"left"}
                            /> */}
                            <DevExpressDataGrid
                                ref={this.DataGridRekapGajiRef}
                                loadAPI='daftar-penerima-thr'
                                insertAPI='daftar-penerima-thr'
                                updateAPI='daftar-penerima-thr'
                                deleteAPI='daftar-penerima-thr'

                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                useArraySource={true}
                                ArraySourceData={this.loadDataRekapGaji}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Daftar Penggajian Karyawan"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                keyField={'nik'}
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Daftar Penggajian Karyawan'}
                                popupWidth={450} //masukan dalam ukuran pixel
                                popupHeight={330} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.rekapColumns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}

                                height={"calc(70vh - 195px)"}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            {/* </div> */}
                        </div>
                        <div id="tabindex-1" className={'d-none mt-2 text-center'} style={{ height: '100%' }}>
                            <DevExpressDataGrid
                                ref={this.DataGridRekapTrsRef}
                                loadAPI='daftar-penerima-thr'
                                insertAPI='daftar-penerima-thr'
                                updateAPI='daftar-penerima-thr'
                                deleteAPI='daftar-penerima-thr'

                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                useArraySource={true}
                                ArraySourceData={this.loadDataRekapTrs}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Daftar Penggajian Karyawan"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Daftar Penggajian Karyawan'}
                                popupWidth={450} //masukan dalam ukuran pixel
                                popupHeight={330} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.rekapTransaksiColumns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}

                                height={"calc(70vh - 195px)"}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />

                        </div>
                    </div>
                    <ViewPenggajianKaryawan
                        ref={this.popupPenggajianKaryawanRef}
                        store={this.props.store}
                    />
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalPengajuanGajiKaryawan