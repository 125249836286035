import React, { Component } from 'react'
import { Form , Popup } from 'devextreme-react'
import { karyawan } from 'dataSource/lookup'

class FormModalPenyesuaianPotongan extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {}
        }

        this.formRef = React.createRef()

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'ids',
                        label: {
                            text: 'ids',
                            visible: false,
                        },
                        editorOptions:{
                            visible: false,
                        }
                    },
                    {
                        dataField: 'nik',
                        label: {
                            text: 'NIK',
                        },
                        // editorType: 'dxSelectBox',
                        editorOptions:{
                            readOnly: true
                        }
                    },
                    {
                        dataField: "karyawanId",
                        label: {
                            text: "Karyawan",
                            // alignment: "left",
                            // location: "left"
                        },
                        editorType: "dxSelectBox",
                        editorOptions: {
                            dataSource: karyawan(this.props.store),
                            displayExpr: "fullName",
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'nominalGajiPokok',
                        label: {
                            text: 'Gaji Pokok'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                    },
                ],
            },
            
            {
                itemType: 'group',
                caption: 'POTONGAN',
                items: [
                    {
                        dataField: 'groupName',
                        label: {
                            text: 'Group'
                        },
                        editorOptions:{
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'potonganName',
                        label: {
                            text: 'Potongan'
                        },
                        editorOptions:{
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'dasarNilai',
                        label: {
                            text: 'Dasar Nilai'
                        },
                        editorOptions:{
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'persen',
                        label: {
                            text: 'Persen'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nilaiPotongan',
                        label: {
                            text: 'Nominal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                    },
                ]
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: () => {
                    this.props.getDataSourcePot(this.state.DataMaster)
                    this.hide()
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            DataMaster: {},
        })
    }

    retrieveData = async(data, dataMaster) => {
        var DataMaster = {...this.state.DataMaster}
        DataMaster.ids = data.ids
        DataMaster.nik = dataMaster.nik
        DataMaster.karyawanId = dataMaster.karyawanId
        DataMaster.nominalGajiPokok = dataMaster.nominalGajiPokok
        DataMaster.groupName = data.groupName
        DataMaster.potonganName = data.potonganName
        DataMaster.dasarNilai = data.dasarNilai
        DataMaster.persen = data.persen
        DataMaster.nilaiPotongan = data.nilaiPotongan

        this.formRef.current.instance.updateData(DataMaster)

        // this.setState({
        //     DataMaster: DataMaster,
        // })
    }

    submitData = () => {
    
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={"Form Tambah Potongan"}
                width={500}
                height={450}
                toolbarItems={this.PopupToolbarItem}
                >
                <div className="container-fluid">
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formDataGaji'}
                        formData={this.state.DataMaster}
                        items={this.form}
                        labelLocation = 'left'
                    />
                </div>
            </Popup>
        )
    }
}

export default FormModalPenyesuaianPotongan