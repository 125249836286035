import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import TransaksiMpSekaligusSkBayar from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/sekaligus/viewSkBayar';
import { banks, hubunganKeluarga, mpType, pensiunType, pesertaFamiliesAll, pesertas, product, tipePembayaranMp } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import TransaksiPembayaranMpSekaligusModal from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/sekaligus/detailTransaksi';
import { formatDate, getSystemDate } from 'plugin/helper';

class TransaksiPembayaranMpSekaligus extends Component {
    constructor(props){
        super(props)

        this.state = {
            filter: {
                // getURL: 'view-pembayaran-mp-sekaliguses/1900-10-01/2070-10-01/1/1'
                getURL: 'view-pembayaran-mp-sekaliguses?size=99999&statusId.in=1&statusId.in=2&statusId.in=3&statusId.in=4'
            },
            formFilter: {
                productId: 0,
                tanggalPembayaranStart: null,
                tanggalPembayaranEnd: null,
                mpPaymentTypeId: 0,
                // getURL: 'view-pembayaran-mp-sekaliguses/1900-10-01/2070-10-01/1/1'
                getURL: 'view-pembayaran-mp-sekaliguses?size=99999&statusId.in=1&statusId.in=2&statusId.in=3&statusId.in=4'
            },
        }

        this.formFilterRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.popupUploadSkBayar = React.createRef()
        this.transaksiPembayaranMpDetailModalRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
                // colSpan: 1
            },
            // {
            //     dataField: 'mpPaymentTypeId',
            //     label : {
            //         text: 'Mp Payment Type',
            //         alignment: "left",
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         dataSource: tipePembayaranMp(this.props.store),
            //         valueExpr: "id", // contains the same values as the "statusId" field provides
            //         displayExpr: "typeName", // provides display values
            //         searchEnabled: true,
            //         deferRendering: false,
            //         width: '100%',
            //     },
            //     colSpan: 1
            // },
            {
                dataField: 'tanggalPembayaranStart',
                label : {
                    text: 'Tanggal Pembayaran',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    // placeholder: 'dd/mm/yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'tanggalPembayaranEnd',
                label : {
                    text: 'S/D',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    // placeholder: 'dd/mm/yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    // width: '100%',
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    type:"default",
                    onClick:() => {
                        this.filterData()
                    },
                },
                horizontalAlignment:"left"
            },
            // {
            //     editorType: 'dxButton',
            //     editorOptions: {
            //         text: 'Filter',
            //         onClick:() => {
            //             this.dataGridRef.current.forceRefresh(true)
            //         },
            //     }
            // }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data.pembayaranMpId)
                        }
                    },
                    {
                        text: 'Unggah SK',
                        hint : 'Unggah SK',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            this.showModalUploadSk()
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'ID',
                cellRender: (e) =>{
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                name: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                name: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName", // provides display values
                },
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                type: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'pensiunDate',
                name: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                type: 'date',
                format: 'dd MMM yyyy'
            },
            {
                name: 'penerimaHp',
                caption: 'Penerima MP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'penerimaName',
                        caption: 'Nama',
                    },
                    {
                        dataField: 'relationCode',
                        caption: 'Hubungan',
                        lookup: {
                            dataSource: hubunganKeluarga(),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "value" // provides display values
                        }
                    },
                ]
            },
            {
                name: 'bankTransferMp',
                caption: 'Bank Tranfer MP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'mpRekeningBankId',
                        name: 'bankId',
                        caption: 'Bank',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "bankName" // provides display values
                        }
                    },
                    {
                        dataField: 'mpRekeningNo',
                        name: 'noRekening',
                        caption: 'No Rekening'
                    },
                    {
                        dataField: 'mpRekeningName',
                        name: 'namaRekening',
                        caption: 'Nama Rekening'
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'AR/AP Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Pengajuan'
                        },
                        {
                            id: 2,
                            value: 'Pemeriksaan'
                        },
                        {
                            id: 3,
                            value: 'Persetujuan'
                        },
                        {
                            id: 4,
                            value: 'Selesai'
                        }
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                },
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    // loadDummyData = () => {
    //     var dummyData = [
    //         {
    //             id: 1,
    //             nik : 9138131,
    //             nama : 'Januari',
    //             tglLahir : '2020-07-16',
    //             tglPensiun : '2020-07-16',
    //             noSkPensiun : 3000000,
    //             namaPenerima:"Rynn",
    //             hubunganPenerima: "anak",
    //             namaBank:"BCA",
    //             noRekening: 1349149,
    //             namaRekening:"Rynnnyanto",
    //             arApStatus : 'aktif',
    //         }
    //     ]
    //     this.currentData = JSON.parse(JSON.stringify(dummyData))
    //     return dummyData
    // }

    filterData = () => {
        var data = this.state.formFilter
        // this.setState({
        //     filter: {
        //         getURL : `view-pembayaran-mp-sekaliguses/${formatDate(data.tanggalPembayaranStart)}/${formatDate(data.tanggalPembayaranEnd)}/${data.productId}/${data.mpPaymentTypeId}`
        //     },
        // })

        this.setState(prevState =>({
            formFilter: {
                ...prevState.formFilter,
                getURL : `view-pembayaran-mp-sekaliguses/${formatDate(data.tanggalPembayaranStart)}/${formatDate(data.tanggalPembayaranEnd)}/${data.productId}/${1}`
            }
        }))

        this.dataGridRef.current.forceRefresh(true)
    }

    showModalUploadSk = () => {
        this.popupUploadSkBayar.current.show()
    }
    
    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift({
    //         location: 'after',
    //         widget: 'dxButton',
    //         options: {
    //             text: 'Kirim',
    //             elementAttr:{class:"bg-dapen"},
    //         }
    //     });
    // }

    showModalDetail = async(pembayaranMpId = 0) => {
        var loadAPI = `pembayaran-mps/${pembayaranMpId}`
        var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        this.transaksiPembayaranMpDetailModalRef.current.show()
        this.transaksiPembayaranMpDetailModalRef.current.retrieveData(getPembayaranMp)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Sekaligus</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                    ref={this.formFilterRef}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    // loadAPI='view-pembayaran-mp-sekaliguses?mpPaymentTypeId.in=1&mpPaymentTypeId.in=2&pesertaBulanan.in=false&pesertaSekaligus100.in=true&statusId.in=4'
                    loadAPI={this.state.formFilter.getURL}
                    insertAPI='pembayaran-mp-Sekaligus'
                    updateAPI='pembayaran-mp-Sekaligus'
                    deleteAPI='pembayaran-mp-Sekaligus' 
                    remoteOperations = {false}
                    
                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    keyField = {'pembayaranMpId'}
                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Sekaligus"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Sekaligus'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form
                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <TransaksiPembayaranMpSekaligusModal
                    ref={this.transaksiPembayaranMpDetailModalRef}
                    store={this.props.store}
                />
                <TransaksiMpSekaligusSkBayar 
                    ref={this.popupUploadSkBayar}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default TransaksiPembayaranMpSekaligus
