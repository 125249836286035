import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { banks, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';

class PPUPerstujuanPengurusActionDetailModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        
        this.dataMaster = [
            {
                itemType: 'group',
                caption : 'Transaksi Biaya',
                items: [
                    {
                        dataField:'transactionTypeId',
                        label:{
                            alignment:'left',
                            text:'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'transactionTypeName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        dataField:'transactionTypeSubId',
                        label:{
                            alignment:'left',
                            text:'Sub Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions:{
                            dataSource: tipeSubTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'tipeSubName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        },
                    },
                    {
                        name: 'pengajuanNominal',
                        label : {
                            alignment: 'left',
                            text: 'Nominal Pengajuan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                    },
                    {
                        name: 'ppn',
                        label : {
                            alignment: 'left',
                            text: 'PPN'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                    },
                    {
                        name: 'pph',
                        label : {
                            alignment: 'left',
                            text: 'PPH'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                    },
                    {
                        name: 'biayaLain',
                        label : {
                            alignment: 'left',
                            text: 'Biaya Lain'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                    },
                    {
                        name: 'potongan',
                        label : {
                            alignment: 'left',
                            text: 'Potongan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                    },
                    {
                        name: 'pengajuanNominalNett',
                        label : {
                            alignment: 'left',
                            text: 'Nominal Bersih'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                    },
                    {
                        name: 'dpAmount',
                        label : {
                            alignment: 'left',
                            text: 'Uang Muka'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        },
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Catatan Pengajuan'
                        },
                        editorType: 'dxTextArea',
                        editorOptions:{
                            width: '100%'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                items : [
                    {
                        itemType : 'group',
                        caption : 'Metode Pembayaran',
                        items: [
                            {
                                dataField: 'transfer',
                                label: {
                                    alignment: 'left',
                                    text: 'Transfer'
                                },
                                editorType: 'dxCheckBox',
                            },
                            {
                                dataField: 'cash',
                                label: {
                                    alignment: 'left',
                                    text: 'Cash'
                                },
                                editorType: 'dxCheckBox',
                            },
                            {
                                dataField: 'cashOut',
                                label: {
                                    alignment: 'left',
                                    text: 'Cash Out'
                                },
                                editorType: "dxNumberBox",
                                editorOptions: {
                                    format: "#,##0.00"
                                },
                            },
                        ]
                    },
                    {
                        itemType : 'group',
                        caption : 'Rekening Tujuan',
                        items: [
                            {
                                dataField: 'tujuanRekNo',
                                label: {
                                    alignment: 'left',
                                    text: 'Nomor Rekening'
                                }
                            },
                            {
                                dataField: 'tujuanRekName',
                                label: {
                                    alignment: 'left',
                                    text: 'Nama Rekening'
                                }
                            },
                            {
                                dataField:'tujuanRekBankId',
                                label:{
                                    alignment:'left',
                                    text:'Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions:{
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'initialName', // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField: 'tujuanRekCabang',
                                label: {
                                    alignment: 'left',
                                    text: 'Cabang'
                                }
                            },
                        ]
                    }
                ]
            }
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    retrieveData = async(data) => {
        var loadAPI = `pembayaran-umum-detils/${data.id}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPI, 'GET')
        this.formDetailRef.current.instance.updateData(getData)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Persetujuan Pengurus'}
                width={950}
                height={600}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid" style={{ height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formDetailRef}
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation = 'left'
                                readOnly= {true}
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default PPUPerstujuanPengurusActionDetailModal