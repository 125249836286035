import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import ModalPortfolioRebalance from 'components/pages/modal/investasi/portfolio/rebalance/index';
import { addURL } from 'redux/actions/url';
import { productDefault } from 'dataSource/lookup';
import Form from 'devextreme-react/form'

class PortfolioRebalance extends Component {
    constructor(props) {
        super(props)

        this.state = {
            filterData: {
                productId: 1
            }
        }

        this.modalAlokasiRef = React.createRef()
        this.modalKategoriAsetAlokasiRef = React.createRef()
        this.modalPortfolioRebalanceRef = React.createRef()

        this.filterItem = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Load Data",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalPortfolioRebalanceRef('detail', e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'DeviasiKategori',
                caption: 'Deviasi Kategori',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'categoryName',
                        caption: 'Kategori'
                    },
                    {
                        dataField: 'persenMax',
                        caption: 'Max Penempatan (%)',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'unrealizedPersen',
                        caption: 'Realisasi Penempaan (%)',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'deviasiPersen',
                        caption: 'Deviasi Nominal (%)',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                dataField: 'rekomendasi',
                caption: 'Rekomendasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'rekomenBeli',
                        caption: 'Beli',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                    {
                        dataField: 'rekomenJual',
                        caption: 'Jual',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPortfolioRebalanceRef = (type, data = null) => {
        this.modalPortfolioRebalanceRef.current.show()
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Rebalance Portfolio</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI={`view-rebalances?productId.in=${this.state.filterData.productId}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Rebalance Portfolio"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={false}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Rebalance Portfolio'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    // onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalPortfolioRebalance
                    ref={this.modalPortfolioRebalanceRef}
                    store={this.props.store}
                />

            </div>
        )
    }
}

export default PortfolioRebalance