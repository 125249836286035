import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import { participants } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class ModalDetailPengkinianData extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster : {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        
        this.dataGridDetailRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: () => {
                        this.hide()
                    } 
                },
                toolbar: 'bottom'
            }
        ]
        this.items = [
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'field',
                caption: 'Field',
            },
            {
                dataField: 'nilaiLama',
                caption: 'Nilai Lama',
            },
            {
                dataField: 'nilaiBaru',
                caption: 'Nilai Baru',
            }
        ]

        this.dummyData = []
    }
    get Popup(){
        return this.popupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Detail Perubahan'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row" style={{height: 'calc(90vh - 325px)'}}>
                        <div className="col-md-12">
                            <DevExpressDataGrid
                                ref={this.dataGridDetailRef}
                                loadAPI={`pesertas`}
                                insertAPI='pesertas'
                                updateAPI='pesertas'
                                deleteAPI='pesertas' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                useArraySource = {true}
                                ArraySourceData = {this.dummyData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Data Detail Perubahan"}

                                allowExportSelectedData={false}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Data Detail Perubahan'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={300} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.items} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}
                                onToolbarPreparing = {this.onToolbarPreparingPesertaAktifBaru}

                                height = {'calc(100vh - 300px)'}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalDetailPengkinianData
