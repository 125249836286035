import React, { Component } from 'react'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { confirmAlert } from 'react-confirm-alert'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { coa, currency, dc, departments, journalTypeByManual, journalType, product, statusJournal, entity } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class ViewJournalVoucher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            DataMaster: {}
        };

        this.columns = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'journalTypeId',
                        label: {
                            text: 'Tipe Jurnal',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: this.props.type == 'add' ? journalTypeByManual(this.props.store, true) : journalType(this.props.store),
                            // dataSource: journalType(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.journalTypeCode + ' - ' + item.journalTypeDesc
                            }, // provides display values
                            onValueChanged: async (e) => {
                                if (this.props.type === 'add' || (this.props.type === 'cashbook' && this.cashBookSubmit === false)) {
                                    var date = formatDate(this.state.DataMaster.valueDate)
                                    var journalCode = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/generateJournalCode/${e.value}/${date}`, 'GET')
                                    this.formRef.current.instance.updateData('journalCode', journalCode.Code)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'journalCode',
                        label: {
                            text: 'Kode Jurnal'
                        },
                        editorOptions: {
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'reffCode',
                        label: {
                            text: 'Kode Referensi',
                        },
                    },
                    {
                        dataField: 'valueDate',
                        label: {
                            text: 'Nilai Tanggal',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd-MMM-yyyy',
                            onValueChanged: async (e) => {
                                if (this.props.type === 'add' || (this.props.type === 'cashbook' && this.cashBookSubmit === false)) {
                                    var journalTypeId = this.state.DataMaster.journalTypeId
                                    var journalCode = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/generateJournalCode/${journalTypeId}/${formatDate(e.value)}`, 'GET')
                                    this.formRef.current.instance.updateData('journalCode', journalCode.Code)
                                }
                                if (!this.disableEvent) {
                                    if (this.props.type === 'editStatusOpen') {
                                        this.clickGenerateKode = true
                                        // var journalTypeId = this.state.DataMaster.journalTypeId
                                        // var journalCode = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/generateJournalCode/${journalTypeId}/${formatDate(e.value)}`, 'GET')
                                        // this.formRef.current.instance.updateData('journalCode', journalCode.Code)
                                    }
                                }
                            }
                        }
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        name: 'generateKode',
                        itemType: 'button',
                        horizontalAlignment: 'right',
                        verticalAlignment: 'center',
                        buttonOptions: {
                            text: 'Generate Kode',
                            type: 'success',
                            onClick: () => {
                                this.generateKode()
                                this.clickGenerateKode = false
                            }
                        },
                        visible: false
                    }
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'journalDesc',
                        colSpan: 2,
                        label: {
                            text: 'Deskripsi'
                        },
                        editorType: 'dxTextArea',
                        editorOptions: {
                            width: '100%'
                        }
                    },
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'debet',
                                label: {
                                    text: 'Total Debet',
                                },
                                editorType: 'dxNumberBox',
                                alignment: 'right',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'credit',
                                label: {
                                    text: 'Total Kredit'
                                },
                                editorType: 'dxNumberBox',
                                alignment: 'right',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'balance',
                                label: {
                                    text: 'Balance'
                                },
                                editorType: 'dxNumberBox',
                                alignment: 'right',
                                editorOptions: {
                                    format: '#,##0.00',
                                    readOnly: true
                                }
                            }
                        ]
                    },
                    {
                        dataField: 'status',
                        label: {
                            text: 'Status',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: statusJournal(),
                            valueExpr: 'id',
                            displayExpr: 'value', // provides display values
                            readOnly: true
                        },
                        visible: false
                    },
                    {
                        name: 'forexBalancing',
                        itemType: 'button',
                        horizontalAlignment: 'left',
                        verticalAlignment: 'center',
                        buttonOptions: {
                            text: 'Calculate Forex Balancing',
                            type: 'success',
                            onClick: () => {
                                this.forexBalancing()
                            }
                        },
                        visible: true
                    }
                ]
            }
        ]

        this.exchangeColumn = [
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                },
                allowEditing: false
            },
            {
                dataField: 'dealExrateModel',
                caption: 'Model Konversi',
                allowEditing: false
            },
            {
                dataField: 'exrate',
                caption: 'Nilai Tukar',
                allowEditing: false,
                format: '#,##0.00',
            },
            {
                dataField: 'dealExrate',
                caption: 'Nilai Tukar Disepakati',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
            {
                dataField: 'debet',
                caption: 'Debet',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                allowEditing: false
            },
            {
                dataField: 'credit',
                caption: 'Kredit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                allowEditing: false
            },
        ]
        this.journalColumn = [
            {
                dataField: 'coaId',
                caption: 'COA',
                lookup: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    }, // provides display values
                },
                width: 300,
                editorOptions: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    },
                    searchEnabled: true,
                    wrapItemText: true
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
                // setCellValue : async (newData, value, currentRowData) => {
                //     console.log(currentRowData)
                //     newData.coaId = value
                //     var dataCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`coas/${value}`,'GET')
                //     newData.coaName = dataCoa.coaName
                // }
            },
            // {
            //     dataField: 'coaName',
            //     caption: 'Nama COA',
            //     allowEditing: false
            // },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField: 'produkId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField: 'departmentId',
                caption: 'Departmen',
                lookup: {
                    dataSource: departments(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'departmentName',
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField: 'entitiId',
                caption: 'Entitas',
                lookup: {
                    dataSource: entity(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                }
                // validationRules: [
                //     {
                //         type: 'required'
                //     }
                // ]
            },
            {
                dataField: 'dc',
                caption: 'D/C',
                lookup: {
                    dataSource: dc(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                setCellValue: (newData, value, currentRowData) => {
                    newData.dc = value
                    var amount = currentRowData.amount || 0
                    var amountBasedCurrency = currentRowData.amountBasedCurrency || 0
                    if (value === 'D') {
                        newData.debet = amount
                        newData.credit = 0
                        newData.baseDebet = amountBasedCurrency
                        newData.baseCredit = 0
                    } else {
                        newData.debet = 0
                        newData.credit = amount
                        newData.baseDebet = 0
                        newData.baseCredit = amountBasedCurrency
                    }
                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            // {
            //     dataField: 'projectId',
            //     caption: 'Proyek',
            // },
            {
                dataField: 'amount',
                caption: 'Jumlah',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                setCellValue: async (newData, value, currentRowData) => {
                    if (currentRowData.dc) {
                        var journalExrateData = this.journalExrateRef.current.getDataSource().find(value => value.currencyId === currentRowData.currencyId)

                        var rate = 1
                        if (journalExrateData) {
                            rate = journalExrateData.dealExrate
                        }

                        newData.amountBasedCurrency = value * rate

                        newData.amount = value
                        if (currentRowData.dc === 'D') {
                            newData.debet = value
                            newData.credit = 0
                            newData.baseDebet = value * rate
                            newData.baseCredit = 0
                        } else {
                            newData.debet = 0
                            newData.credit = value
                            newData.baseDebet = 0
                            newData.baseCredit = value * rate
                        }

                    } else {
                        newData.amount = null
                        let type = 'error'
                        let text = 'Please select debit/credit!'

                        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                    }


                },
                validationRules: [
                    {
                        type: 'required'
                    }
                ]
            },
            {
                dataField: 'debet',
                caption: 'Debet',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'credit',
                caption: 'Kredit',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'baseDebet',
                caption: 'Debet (IDR)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'baseCredit',
                caption: 'Kredit (IDR)',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'journalDesc',
                caption: 'Deskripsi',
            },
        ]
        this.sumExchangeRate = [
            {
                showInColumn: 'exrate',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'debet',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'debet',
                displayFormat: '{0}'
            },
            {
                column: 'credit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'credit',
                displayFormat: '{0}'
            }
        ]
        this.sumJurnalInfo = [
            {
                showInColumn: 'projectId',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'debet',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'debet',
                displayFormat: '{0}'
            },
            {
                column: 'credit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'credit',
                displayFormat: '{0}'
            },
            {
                column: 'baseDebet',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'debet',
                displayFormat: '{0}'
            },
            {
                column: 'baseCredit',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'credit',
                displayFormat: '{0}'
            }
        ]

        this.journalDetailData = []
        this.journalExrateData = []
        this.status = '';
        this.isSubmit = false
        this.cashBookSubmit = false
        this.clickGenerateKode = false
        this.disableEvent = false

        this.formRef = React.createRef()
        this.journalDetailRef = React.createRef()
        this.journalExrateRef = React.createRef()
    }

    getToolbarItem = () => {
        if (this.props.type === 'view') {
            return [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Tutup",
                        onClick: this.hide.bind(this)
                        // onClick: () => {
                        //     confirmAlert({
                        //         title: 'Konfirmasi?',
                        //         message: 'Apakah anda yakin ingin mengirim instruksi ini?',
                        //         buttons: [
                        //             {
                        //                 label: 'Ya',
                        //                 onClick: () => {
                        //                     this.hide()
                        //                 }
                        //             },
                        //             {
                        //                 label: 'Tidak'
                        //             }
                        //         ]
                        //     })
                        // }
                    },
                    toolbar: "bottom"
                }
            ];
        } else if (this.props.type === 'add' || 'edit') {
            return [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan Draft",
                        onClick: () => { this.submit('draft') }
                    },
                    visible: this.status === 'O' ? false : true,
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan",
                        onClick: async () => { 
                            let result = await confirm("Apakah anda yakin untuk menyimpan data jurnal ini?", "Konfirmasi");
                            if (result) {
                                this.submit('submit') 
                            }
                        }
                    },
                    visible: this.status === 'D' ? false : true,
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Tutup",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom",
                    visible: false
                }
            ];
        } else if (this.props.type === 'cashbook') {
            return [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Simpan",
                        onClick: () => {
                            this.isSubmit = true
                            this.cashBookSubmit = true
                            this.props.changeButtonCaption(this.state.DataMaster.journalCode)
                            this.hide()
                        }
                    },
                    toolbar: "bottom"
                },
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Tutup",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                }
            ];
        } else {
            return [
                {
                    widget: "dxButton",
                    location: "after",
                    options: {
                        text: "Tutup",
                        onClick: this.hide.bind(this)
                    },
                    toolbar: "bottom"
                }
            ];
        }
    }

    retrieveData = async (data) => {
        this.status = data.status;
        this.disableEvent = true
        this.setState({
            DataMaster: data
        })

        this.formRef.current.instance.beginUpdate()

        if (this.props.type === 'editStatusOpen') {
            this.formRef.current.instance.itemOption('generateKode', 'visible', true)
        }
        this.formRef.current.instance.itemOption('status', 'visible', true)
        this.formRef.current.instance.itemOption('forexBalancing', 'visible', false)
        this.formRef.current.instance.getEditor('journalTypeId').option('readOnly', true)
        this.formRef.current.instance.getEditor('journalCode').option('readOnly', true)
        this.formRef.current.instance.getEditor('reffCode').option('readOnly', true)
        // this.formRef.current.instance.getEditor('journalDesc').option('readOnly', true)
        this.formRef.current.instance.getEditor('debet').option('readOnly', true)
        this.formRef.current.instance.getEditor('credit').option('readOnly', true)
        this.formRef.current.instance.updateData({
            balance: data.debet - data.credit
        })
        // this.formRef.current.instance.getEditor('valueDate').option('readOnly', true)
        this.formRef.current.instance.endUpdate()

        this.disableEvent = false

    }

    generateKode = async () => {
        var DataMaster = this.state.DataMaster
        var journalCode = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-headers/generateJournalCode/${DataMaster.journalTypeId}/${formatDate(DataMaster.valueDate)}`, 'GET')
        this.formRef.current.instance.updateData('journalCode', journalCode.Code)
    }

    loadDataDetailAndExrate = async (journalHeaderId) => {
        var journalDetailResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-detils/getAllByJournalHeaderId/${journalHeaderId}`)
        this.journalDetailData = journalDetailResponse.map(response => {
            if (response.coa) {
                response.coaId = response.coa.id
                response.coaName = response.coa.coaName
            } else {
                response.coaId = null
                response.coaName = null
            }
            delete response.coa

            if (response.currency) {
                response.currencyId = response.currency.id
            } else {
                response.currencyId = null
            }
            delete response.currency

            if (response.department) {
                response.departmentId = response.department.id
                delete response.department
            }

            delete response.journalHeader

            if (response.dc === 'D') {
                response.debet = response.amount
                response.credit = 0
                response.baseDebet = response.amountBasedCurrency
                response.baseCredit = 0
            } else {
                response.debet = 0
                response.credit = response.amount
                response.baseDebet = 0
                response.baseCredit = response.amountBasedCurrency
            }

            return response
        })

        var journalExrateResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `journal-exrates/getAllByJournalHeaderId/${journalHeaderId}`)

        this.journalExrateData = journalExrateResponse.map(value => {
            value.currencyId = value.currency.id
            delete value.journalHeader

            return value
        })

        this.journalExrateRef.current.forceRefresh(true)
        this.journalDetailRef.current.forceRefresh(true)
    }

    loadDataDetail = async () => {
        return this.journalDetailData
    }

    loadDataExrate = async () => {
        return this.journalExrateData
    }

    show = () => {
        this.isSubmit = false
        if (this.props.type === 'view') {
            this.formRef.current.instance.getEditor('valueDate').option('readOnly', true)
            this.formRef.current.instance.getEditor('journalDesc').option('readOnly', true)
        }
        if (this.props.type === 'edit') {
            this.formRef.current.instance.getEditor('valueDate').option('readOnly', true)
            this.formRef.current.instance.getEditor('journalDesc').option('readOnly', true)
        }
        if (this.props.type === 'editStatusOpen') {
            this.formRef.current.instance.getEditor('valueDate').option('readOnly', false)
            this.formRef.current.instance.getEditor('journalDesc').option('readOnly', false)
        }
        if (this.props.type === 'add' || (this.props.type === 'cashbook' && this.cashBookSubmit === false)) {
            const systemDate = this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE')
            this.setState({
                popupVisible: true,
                DataMaster: {
                    valueDate: formatDate(systemDate.dateValue)
                }
            });

            this.journalDetailData = []
            this.journalExrateData = []

            this.journalExrateRef.current.forceRefresh(true)
            this.journalDetailRef.current.forceRefresh(true)
        } else {
            this.setState({
                popupVisible: true,
            });
        }
    };

    hide = async (e = {}) => {
        var result
        if (this.props.type === 'add' && this.state.popupVisible === true && !this.isSubmit) {
            if (typeof e.cancel === 'boolean') {
                e.cancel = true
            }
            var result = await confirm('Apakah anda yakin untuk membatalkan input jurnal ini?', 'Konfirmasi')
        } else {
            result = true
        }
        if (result) {

            if (typeof e.cancel === 'boolean') {
                e.cancel = false
            }
            this.setState({
                popupVisible: false,
            });


            this.journalDetailData = []
            this.journalExrateData = []
            // this.props.forceRefresh()
        }
    };

    onRowUpdatedJournalExrate = (e) => {
        var dataJournalExrates = this.journalExrateRef.current.getDataSource().find(value => value.id === e.key)
        this.setRateJournalDetail(dataJournalExrates.currencyId)
    }

    onRowInsertedJournalDetail = async (e) => {
        this.calculateTotalDebetCredit()
        await this.setExrateData()
        this.setRateJournalDetail(e.data.currencyId)
    }

    onRowUpdatedJournalDetail = async (e) => {
        this.calculateTotalDebetCredit()
        await this.setExrateData()
        var dataJournalDetails = this.journalDetailRef.current.getDataSource().find(value => value.id === e.key)
        this.setRateJournalDetail(dataJournalDetails.currencyId)
    }

    onRowRemovedJournalDetail = (e) => {
        this.calculateTotalDebetCredit()
        this.setExrateData()
    }

    forexBalancing = async () => {
        var baseCurrencyId = this.props.store.getState().getParam.find(value => value.paramCode === '9 BASECCY')

        if (baseCurrencyId) {
            baseCurrencyId = baseCurrencyId.intValue

            var exrateDatas = this.journalExrateRef.current.getDataSource().filter(value => value.currencyId !== baseCurrencyId)

            var journalBalancingData = []
            for (var exrateData of exrateDatas) {
                var forexBalancingConfigs = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'forex-balancing-configs')
                if (exrateData.debet - exrateData.credit !== 0) {
                    for (var forexBalancingConfig of forexBalancingConfigs) {
                        var dataCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coas/${forexBalancingConfig.coaId}`, 'GET')

                        var dc, amount, amountBasedCurrency, debet, credit, baseDebet, baseCredit

                        if (forexBalancingConfig.type === 1) {
                            dc = exrateData.debet - exrateData.credit < 0 ? 'D' : 'C'
                            amount = Math.abs(exrateData.debet - exrateData.credit)
                            amountBasedCurrency = Math.abs(exrateData.debet - exrateData.credit) * exrateData.dealExrate
                            debet = dc === 'D' ? amount : 0
                            credit = dc === 'C' ? amount : 0
                            baseDebet = dc === 'D' ? amountBasedCurrency : 0
                            baseCredit = dc === 'C' ? amountBasedCurrency : 0
                        } else {
                            dc = exrateData.debet - exrateData.credit > 0 ? 'D' : 'C'
                            amount = Math.abs(exrateData.debet - exrateData.credit) * exrateData.dealExrate
                            amountBasedCurrency = amount
                            debet = dc === 'D' ? amount : 0
                            credit = dc === 'C' ? amount : 0
                            baseDebet = dc === 'D' ? amountBasedCurrency : 0
                            baseCredit = dc === 'C' ? amountBasedCurrency : 0
                        }

                        journalBalancingData.push({
                            type: "insert",
                            data: {
                                coaId: forexBalancingConfig.coaId,
                                coaName: dataCoa.coaName,
                                currencyId: forexBalancingConfig.type === 1 ? forexBalancingConfig.currencyId : baseCurrencyId,
                                departmentId: null,
                                dc: dc,
                                projectId: null,
                                amount: amount,
                                amountBasedCurrency: amountBasedCurrency,
                                debet: debet,
                                credit: credit,
                                baseDebet: baseDebet,
                                baseCredit: baseCredit,
                            }
                        })
                    }
                }
            }

            this.journalDetailRef.current.push(journalBalancingData)
            this.calculateTotalDebetCredit()
            this.setExrateData()
        } else {
            let type = 'error'
            let text = 'Please Set Base Currency at Global Param !'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        }
    }

    calculateTotalDebetCredit = () => {
        var journalDetailData = this.journalDetailRef.current.getDataSource()

        var DataMaster = {}
        DataMaster.debet = 0
        DataMaster.credit = 0

        if (journalDetailData.length > 0) {
            DataMaster.debet = journalDetailData.map(value => value.debet).reduce((a, b) => a + b, 0)
            // DataMaster.debet = journalDetailData.map(value => value.debet).reduce((a, b) => a + b, 0).toFixed(2)
            DataMaster.credit = journalDetailData.map(value => value.credit).reduce((a, b) => a + b, 0)
            // DataMaster.credit = journalDetailData.map(value => value.credit).reduce((a, b) => a + b, 0).toFixed(2)
        }
        this.formRef.current.instance.updateData(DataMaster)
        this.formRef.current.instance.updateData({
            balance: DataMaster.debet - DataMaster.credit
        })
    }

    setExrateData = async () => {
        var journalDetailData = this.journalDetailRef.current.getDataSource()
        var journalExrateData = this.journalExrateRef.current.getDataSource()
        var data = []

        if (journalDetailData.length > 0) {
            var existingExrateCurrencyIds = journalExrateData.map(value => value.currencyId)
            var existingDetailCurrencyIds = journalDetailData.map(value => value.currencyId)

            var newCurrencyIds = journalDetailData.map(value => {
                if (!existingExrateCurrencyIds.includes(value.currencyId)) {
                    return value.currencyId
                } else {
                    return false
                }
            }).filter(value => value)



            var deletedCurrencyIds = existingExrateCurrencyIds.filter(value => !existingDetailCurrencyIds.includes(value))

            newCurrencyIds = [...new Set(newCurrencyIds)]

            for (var currencyId of newCurrencyIds) {
                var dataCurrency = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${currencyId}`, 'GET')
                var totalDebit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.debet : 0 }).reduce((a, b) => a + b)
                var totalCredit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.credit : 0 }).reduce((a, b) => a + b)

                data.push({
                    type: "insert",
                    data: {
                        currencyId: currencyId,
                        dealExrateModel: null,
                        exrate: dataCurrency.rate || 1,
                        dealExrate: dataCurrency.rate || 1,
                        debet: totalDebit,
                        credit: totalCredit,
                    }
                })

                var detailData = []
                var selectedDetailData = journalDetailData.filter(value => value.currencyId === currencyId)

                for (var selectedDetail of selectedDetailData) {
                    detailData.push({
                        type: "update",
                        data: {
                            baseDebet: selectedDetail.debet * (dataCurrency.dealExrate || 1),
                            baseCredit: selectedDetail.credit * (dataCurrency.dealExrate || 1),
                        },
                        key: selectedDetail.id
                    })
                }

                this.journalDetailRef.current.push(detailData)
            }

            for (var currencyId of existingExrateCurrencyIds) {
                var totalDebit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.debet : 0 }).reduce((a, b) => a + b)
                var totalCredit = journalDetailData.map(value => { return value.currencyId === currencyId ? value.credit : 0 }).reduce((a, b) => a + b)

                if (totalDebit !== 0 || totalCredit !== 0) {
                    var selectedJournalExrate = journalExrateData.find(value => value.currencyId === currencyId)
                    data.push({
                        type: "update",
                        data: {
                            debet: totalDebit,
                            credit: totalCredit,
                        },
                        key: selectedJournalExrate.id
                    })
                }
            }

            for (var currencyId of deletedCurrencyIds) {
                var selectedJournalExrate = journalExrateData.find(value => value.currencyId === currencyId)

                data.push({
                    type: "remove",
                    key: selectedJournalExrate.id
                })
            }
        } else {
            for (var journalExrate of journalExrateData) {
                data.push({
                    type: "remove",
                    key: journalExrate.id
                })
            }
        }

        this.journalExrateRef.current.push(data)
    }

    setRateJournalDetail = (currencyId) => {
        var journalDetailData = this.journalDetailRef.current.getDataSource()
        var journalExrateData = this.journalExrateRef.current.getDataSource()

        var filteredCurrencyExrate = journalExrateData.find(value => value.currencyId === currencyId)

        if (filteredCurrencyExrate) {
            var filteredCurrencyDetail = journalDetailData.filter(value => value.currencyId === filteredCurrencyExrate.currencyId)

            var data = []
            for (var filteredDetail of filteredCurrencyDetail) {
                data.push({
                    type: "update",
                    data: {
                        amountBasedCurrency: filteredDetail.amount * filteredCurrencyExrate.dealExrate,
                        baseDebet: filteredDetail.debet * filteredCurrencyExrate.dealExrate,
                        baseCredit: filteredDetail.credit * filteredCurrencyExrate.dealExrate,
                    },
                    key: filteredDetail.id
                })
            }

            this.journalDetailRef.current.push(data)
        }

    }

    submit = async (type) => {
        var errorDetail, errorDetailDesc, errorHeader, errorHeaderDesc, errorExrate, errorExrateDesc
        var journalHeaderData = this.state.DataMaster
        var journalDetailData = this.journalDetailRef.current.getDataSource()
        var journalExrateData = this.journalExrateRef.current.getDataSource()

        if (this.clickGenerateKode) {
            let type = 'error'
            let text = 'Please click generate code if you change value date !'

            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

            return null
        }

        if (type === 'submit') {
            if (this.state.DataMaster.journalType) {
                let type = 'error'
                let text = 'Please select journal type !'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                return null
            }

            if (journalDetailData.length === 0) {
                let type = 'error'
                let text = 'Journal Not Found, please insert journal !'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                return null
            }
            if (this.state.DataMaster.debet !== this.state.DataMaster.credit) {
                let type = 'error'
                let text = 'Total Debet and Credit not balance!'

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);

                return null
            }
            journalHeaderData.status = 'O'
        } else {
            journalHeaderData.status = 'D'
        }

        journalHeaderData.automatic = false

        var journalHeaderResponse

        if (journalHeaderData.id) {
            try {
                journalHeaderResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-headers', 'PUT', {
                    values: journalHeaderData
                }, journalHeaderData)

                if (journalHeaderResponse) {
                    errorHeader = false
                }
            } catch (e) {
                errorHeader = true
                errorHeaderDesc = 'Gagal Mengubah Jurnal Header'
            }

        } else {
            try {
                journalHeaderResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-headers', 'POST', {
                    values: journalHeaderData
                })

                if (journalHeaderResponse) {
                    errorHeader = false
                }
            } catch (e) {
                errorHeader = true
                errorHeaderDesc = 'Gagal Mengirim Jurnal Header'
            }

        }

        if (journalHeaderResponse) {
            for (var journalDetail of journalDetailData) {
                var dataPost = {
                    amount: journalDetail.amount,
                    amountBasedCurrency: journalDetail.amountBasedCurrency,
                    coaId: journalDetail.coaId,
                    currencyId: journalDetail.currencyId,
                    dc: journalDetail.dc,
                    entitiId: journalDetail.entitiId,
                    departmentId: journalDetail.departmentId,
                    // journalDesc: journalHeaderResponse.journalDesc,
                    journalHeaderId: journalHeaderResponse.id,
                    produkId: journalDetail.produkId,
                    journalDesc: journalDetail.journalDesc,
                }

                if (typeof journalDetail.id === 'string') {
                    try {
                        var journalDetailResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-detils', 'POST', {
                            values: dataPost
                        })

                        if (journalDetailResponse) {
                            errorDetail = false
                        }
                    } catch (e) {
                        console.log(e);
                        errorDetail = true
                        errorDetailDesc = 'Gagal Mengirim Jurnal Detail dengan COA Id' + dataPost.coaId
                    }

                } else {
                    try {
                        dataPost.id = journalDetail.id
                        var journalDetailResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-detils', 'PUT', {
                            values: dataPost
                        }, dataPost)

                        if (journalDetailResponse) {
                            errorDetail = false
                        }
                    } catch (e) {
                        console.log(e);
                        errorDetail = true
                        errorDetailDesc = 'Gagal Mengubah Jurnal Detail dengan COA Id' + dataPost.coaId
                    }

                }
            }

            for (var journalExrate of journalExrateData) {
                var dataPost = {
                    credit: journalExrate.credit,
                    debet: journalExrate.debet,
                    currencyId: journalExrate.currencyId,
                    dealExrate: journalExrate.dealExrate,
                    exrate: journalExrate.exrate,
                    journalHeaderId: journalHeaderResponse.id,

                }

                if (typeof journalExrate.id === 'string') {
                    try {
                        var journalExrateResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-exrates', 'POST', {
                            values: dataPost
                        })
                        if (journalExrateResponse) {
                            errorExrate = false
                        }
                    } catch (e) {
                        console.log(e);
                        errorExrate = true
                        errorExrateDesc = 'Gagal Mengubah Jurnal Exrate'
                    }

                } else {
                    try {
                        dataPost.id = journalExrate.id
                        var journalExrateResponse = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'journal-exrates', 'PUT', {
                            values: dataPost
                        }, dataPost)

                        if (journalExrateResponse) {
                            errorExrate = false
                        }
                    } catch (e) {
                        console.log(e);
                        errorExrate = true
                        errorExrateDesc = 'Gagal Mengubah Jurnal Exrate'
                    }

                }
            }
        }

        if (errorHeader) {
            notify({ message: errorHeaderDesc, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            return null
        } else if (errorDetail) {
            notify({ message: errorDetailDesc, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            return null
        } else if (errorExrate) {
            notify({ message: errorDetailDesc, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);

            return null
        }

        if (this.props.type === 'add' || 'edit') {
            this.props.forceRefresh()

            this.isSubmit = true
            this.hide()
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    showTitle={true}
                    title={"Form Jurnal Voucher"}
                    width={"90vw"}
                    height={"90vh"}
                    toolbarItems={this.getToolbarItem()}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={"formMaster"}
                                    formData={this.state.DataMaster}
                                    items={this.columns}
                                    ref={this.formRef}
                                    labelLocation={'left'}
                                />
                            </div>
                            <div className="col-md-6">
                                <h6>Mata Uang</h6>
                                <DevExpressDataGrid
                                    ref={this.journalExrateRef}
                                    menuRightClick={false}

                                    loadAPI='exchange-rates'
                                    insertAPI='exchange-rates'
                                    updateAPI='exchange-rates'
                                    deleteAPI='exchange-rates'

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataExrate}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={true}

                                    editingMode="cell"

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={true}
                                    selection={"none"}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={140}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    // FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.exchangeColumn} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}

                                    // summaryTotalItem={this.sumExchangeRate}

                                    onRowUpdated={this.onRowUpdatedJournalExrate}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h6 className="mt-3 mb-0">Informasi Detail Jurnal</h6>
                                <DevExpressDataGrid
                                    ref={this.journalDetailRef}
                                    menuRightClick={false}

                                    loadAPI='journal-details'
                                    insertAPI='journal-details'
                                    updateAPI='journal-details'
                                    deleteAPI='journal-details'

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadDataDetail}

                                    allowAdding={this.props.type === 'view' ? false : this.props.type === 'editStatusOpen' ? false : true}
                                    allowDeleting={this.props.type === 'view' ? false : this.props.type === 'editStatusOpen' ? false : true}
                                    allowUpdating={this.props.type === 'view' ? false : this.props.type === 'editStatusOpen' ? true : true}

                                    editingMode="cell"
                                    startEditAction="click"
                                    // sortingMode = "none"

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={true}
                                    selection={"none"}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={'calc(90vh - 385px)'}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    // FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.journalColumn} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}

                                    summaryTotalItem={this.sumJurnalInfo}

                                    onRowInserted={this.onRowInsertedJournalDetail}
                                    onRowUpdated={this.onRowUpdatedJournalDetail}
                                    onRowRemoved={this.onRowRemovedJournalDetail}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>

        );
    }
}

export default ViewJournalVoucher