import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, product, banks } from 'dataSource/lookup';
import { formatUploadFileData, formatUploadFileNameAndExtention } from 'plugin/helper';
import { FileUploader } from 'devextreme-react';
import ScrollView from 'devextreme-react/scroll-view';

class ModalDetailPiutangIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            getUrl : 'view-iuran-detil-summaries?iuranId.in=0'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        
        this.prevTabIndex = 0
        this.PopupRef = React.createRef()
        this.dataGridDetailRef = React.createRef()

        this.summary = {
            totalItems: [
                {
                    column: 'receiveOutstanding',
                    summaryType: 'sum',
                    valueFormat: '#,##0.00'
                },
                {
                    column: 'nominalIuran',
                    summaryType: 'sum',
                    valueFormat: '#,##0.00'
                },
            ]
        }

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: () => {
                        this.prosesData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formMaster = [
            {
                dataField: 'participantId',
                label: {
                    text: 'Pemberi Kerja',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    placeholder: ' ',
                    dataSource: participants(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "participantName", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:'productId',
                label:{
                    text: 'Produk',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    placeholder: ' ',
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField : 'tahun',
                label : {
                    text: 'Periode',
                },
                editorOptions : {
                    render : (data) => {
                        console.log(data)
                    }
                }
            },
        
        ]
        
        this.columnsDetail = [
            {
                dataField: 'id',
                caption: 'No Transaksi'
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Karyawan'
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',  
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'phdp',
                caption: 'Upah Pokok Pensiun',
                format: '#,##0.00',
                alignment: 'right'
            },
            // {
            //     dataField: 'rapelUpahPokokPensiun',
            //     caption: 'Rapel Upah Pokok Pensiun',
            //     format: '#,##0.00'
            // },
            {
                dataField: 'phdp',
                caption: 'Dasar Perhitungan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'dapenPerusahaan',
                caption: 'Iuran Perusahaan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'dapenKaryawan',
                caption: 'Iuran Karyawan',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'totalDapen',
                caption: 'Total Iuran',
                format: '#,##0.00',
                alignment: 'right'
            },
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    prosesData = () => {
        console.log('proses')
        this.hide()
    }
    show = () => {
        this.setState({
            popupVisible: true,
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        });
    }

    retrieveData = (data) => {
        this.setState({
            dataMaster: data,
            getUrl : `view-iuran-detil-summaries?iuranId.in=${data.id}`
        });
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}File`]: formattedFileData.base64data,
                        [`${fieldName}FileName`]: formattedFileNameAndExtention.name,
                        [`${fieldName}FileExtention`]: formattedFileNameAndExtention.extention,
                        [`${fieldName}FileContentType`]: formattedFileData.mime,
                    },
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Penerimaan Iuran'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formPemberiKerja'}
                        formData={this.state.dataMaster}
                        items={this.formMaster}
                        labelLocation = {'left'}
                    />
                    <DevExpressDataGrid
                        ref = {this.dataGridDetailRef}
                        loadAPI={this.state.getUrl}
                        insertAPI='iuran-by-types'
                        updateAPI='iuran-by-types'
                        deleteAPI='iuran-by-types' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        remoteOperations = {true}
                        // useArraySource = {true}
                        // ArraySourceData = {[]}
                        
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Data Detail Piutang Iuran"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Detail Piutang Iuran'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columnsDetail} // taruh konfigurasi kolom disini
                        SummaryConfiguration={this.summary}

                        height = {'calc(100vh - 350px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalDetailPiutangIuran