import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { tax, gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks, branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, product, managerInvestasis, instrumentSubCategoryDeposito, currency, instruments, broker, orders, kpds, productDefault, fundManager, rekeningBank, instrumentCategorySubs, instrumentsCategoryObli, instrumentObligasi, ratings } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormSubInstrumentPenempatanObligasi from './formInstrument';
import { inputToTaskInvestasi } from 'plugin/helper';


class FormPengajuanPenempatanObligasiKorporasi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            kajian: "",
            kajianContentType: 'text/html',
            dataPembelian: {},
        }

        this.prevTabIndex = 0
        this.dataPembelian = []
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.dataGridDetailPenempatanRef = React.createRef()
        this.modalOblligasiRef = React.createRef()
        this.kajianPengajuanRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('draft')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' ? false : true

            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Kirim',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData('kirim')
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.actionType == 'view' || this.props.actionType == 'add' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id: 1,
                text: 'Instrumen Pengajuan'
            },
            {
                id: 2,
                text: 'Kajian Pengajuan'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'noPengajuan',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                type: 'empty',
                colSpan: 2
            },
            {
                dataField: 'tanggalPengajuan',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Silahkan Isi Tanggal Pengajuan"
                    }
                ]
            },
            {
                dataField: 'tanggalPembelian',
                label: {
                    text: 'Tanggal Order'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: "d MMM yyyy",
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    width: '100%',
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Silahkan Isi Tanggal penempatan"
                    }
                ]
            },
            {
                dataField: 'fundManagerId',
                label: {
                    text: 'Fund Manager'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: fundManager(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'fundManagerName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Silahkan pilih fund manager"
                    }
                ]
            },
            {
                dataField: 'productId',
                label: {
                    text: 'Produk'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'productCode',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly: this.props.actionType == 'view' ? true : false
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Silahkan Pilih Produk"
                    }
                ]
            },
            {
                dataField: 'categoryName',
                label: {
                    text: 'Instrument'
                },
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'jenisTransaksi',
                label: {
                    text: 'Jenis Transaksi'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
        ]
        this.toolbarItem = [
            'background', 'bold', 'color', 'italic', 'link', 'image', 'strike', 'subscript', 'superscript', 'underline', 'blockquote', 'header'
            , 'increaseIndent', 'decreaseIndent', 'orderedList', 'bulletList', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeBlock'
            , 'variable', 'separator', 'undo', 'redo', 'clear', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft',
            'insertColumnRight', 'deleteColumn'
        ]
    }
    getDataGridColumnConfiguration = (actionType) => {
        var columnConfiguration = [
            {
                dataField: 'instrumentId',
                caption: 'Nama Instrument',
                lookup: {
                    dataSource: instrumentObligasi(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                },
            },
            {
                dataField: 'instSubCategoryId',
                caption: 'Instrument Sub Kategori',
                lookup: {
                    dataSource: instrumentsCategoryObli(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                },
            },
            {
                dataField: 'ratingId',
                caption: 'Rating',
                lookup: this.props.actionType !== 'add' ? undefined : {
                    dataSource: ratings(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.ratingName + ' (' + e.ratingCode + ')'
                    }
                }
            },
            {
                dataField: 'price',
                caption: 'Harga (%)',
                dataType: 'number',
                format: '#,##0.0000',
                alignment: 'right'
            },
            {
                dataField: 'faceValue',
                caption: 'Nilai Investasi',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'rate',
                caption: 'Rate (%)',
                alignment: 'right'
            },
            {
                dataField: 'maturityDate',
                caption: 'Maturity Date',
                dataType: 'date',
                format: 'd MMM yyyy'
            },
            {
                dataField: 'accruedInterest',
                caption: 'Bunga Berjalan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                calculateCellValue: (e) => {
                    if (e.accruedInterest == null) {
                        return 0
                    } else {
                        return e.accruedInterest
                    }
                }
            },
            {
                dataField: 'nilaiPenempatan',
                caption: 'Nilai Penempatan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
                calculateCellValue: (e) => {
                    var fv = e.faceValue
                    var bb = e.accruedInterest == null ? 0 : e.accruedInterest
                    return fv + bb
                }
            },
            // {
            //     dataField: 'pasar',
            //     caption: 'Pasar',
            // },
            {
                dataField: 'htm',
                caption: 'HTM',
                lookup: {
                    dataSource: [
                        {
                            value: true,
                            text: 'HTM'
                        },
                        {
                            value: false,
                            text: 'AFS'
                        },
                    ],
                    valueExpr: 'value',
                    displayExpr: 'text'
                }
            },
            {
                dataField: 'rekBankId',
                caption: 'Rekening Transfer',
                lookup: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    }
                }
            },
        ]

        if (actionType === 'view') {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            onClick: (e) => {
                                var data = e.row.data
                                data.noPengajuan = this.state.dataMaster.noPengajuan
                                data.productId = this.state.dataMaster.productId
                                this.modalOblligasiRef.current.show()
                                this.modalOblligasiRef.current.retrieveData(data)
                            }
                        },
                    ]
                },
            )
            return columnConfiguration
        } else {
            columnConfiguration.unshift(
                {
                    type: 'buttons',
                    buttons: [
                        'edit',
                        {
                            text: this.props.actionType == 'view' ? 'Buka' : 'Ubah',
                            onClick: (e) => {
                                var data = e.row.data
                                data.noPengajuan = this.state.dataMaster.noPengajuan
                                data.productId = this.state.dataMaster.productId
                                this.modalOblligasiRef.current.show()
                                this.modalOblligasiRef.current.retrieveData(data)
                            }
                        },
                        {
                            text: 'Hapus',
                            hint: 'Hapus',
                            onClick: (e) => {
                                this.deleteDetailPenempatanData(e.row.key)
                            }
                        },
                    ]
                },
            )

            return columnConfiguration
        }
        return columnConfiguration
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
        });

    }

    retrieveData = (dataMaster, dataPembelian, kajian) => {
        if(this.props.actionType === 'add') {
            dataMaster = {
                noPengajuan: '<AUTO>',
                tanggalPengajuan: new Date(this.sysDate.dateValue),
                categoryName: 'Obligasi Korporasi',
                jenisTransaksi: 'Penempatan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPembelian: dataPembelian,
                kajian: kajian,
            })
        } else {
            if (dataMaster) {
                this.dataPembelian = dataPembelian || []
                dataMaster.jenisTransaksi = 'Penempatan'
            }

            dataMaster = dataMaster || {
                noPengajuan: '<AUTO>',
                tanggalPengajuan: new Date(this.sysDate.dateValue),
                categoryName: 'Obligasi Korporasi',
                jenisTransaksi: 'Penempatan'
            }

            this.setState({
                dataMaster: dataMaster,
                dataPembelian: dataPembelian,
                kajian: kajian,
            });
            this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
            this.dataGridDetailPenempatanRef.current.forceRefresh(true)
        }
    }

    hide() {
        this.setState({
            popupVisible: false,
        })
    }

    // retrieveData = (data) => {
    //     this.setState({
    //         data: data
    //     })
    //         ;
    // }
    submitData = async (type) => {
        var dataDataGrid = this.dataGridDetailPenempatanRef.current.getDataSource()
        var dataMaster = this.state.dataMaster
        var kajian = btoa(this.state.kajian)
        var kajianType = this.state.kajianContentType
        var draft = type == 'draft' ? true : false

        var customSubmitData = {
            ...dataMaster,
            instrumentObligasiList: dataDataGrid,
            draft: draft,
            kajian: kajian,
            kajianContentType: kajianType,
            requestOrderId: dataMaster.actionType == 'edit' ? dataMaster.requestId : null
        }

        if (dataDataGrid.length == 0) {
            notify({ message: 'Harap isi data instrument pengajuan terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        } else {
            if (customSubmitData) {
                try {
                    var insertAPI = 'order-sbns/pengajuan-penempatan-corporate'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                        values: customSubmitData
                    })

                    if (type == 'kirim') {
                        try {
                            await inputToTaskInvestasi(this.props.store, customSubmitData.requestOrderId, customSubmitData.orderTypeId)
                        } catch (e) {
                            console.log(e);
                            notify({ message: "Terjadi kesalahan saat input data ke task", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }

                    }
                    var message = dataMaster.actionType == 'edit' ? 'Data Pengajuan berhasil diubah!' : 'Pengajuan berhasil!'
                    notify({ message: message, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.hide()
                    this.props.forceRefresh()
                    return response
                } catch (e) {
                    console.log(e)
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
            }
        }
    }

    loadDetailPenempatan = () => {
        return this.dataPembelian
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindexPenempatanObligasi-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexPenempatanObligasi-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) => {
        this.setState({
            kajian: e.value
        })
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Instrument Penempatan',
                    onClick: (e) => {
                        this.showModalInstrumentPenempatan()
                    },
                },
                visible: this.props.actionType == 'view' ? false : true
            }
        )
    }

    showModalInstrumentPenempatan = () => {
        if (this.state.dataMaster.tanggalPembelian == null || this.state.dataMaster.fundManagerId == null || this.state.dataMaster.productId == null) {
            notify({ message: 'Harap lengkapi data usulan penempatan terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            return
        }
        this.modalOblligasiRef.current.show()
        this.modalOblligasiRef.current.retrieveData({
            productId: this.state.dataMaster.productId,
            noPengajuan: this.state.dataMaster.noPengajuan,
            tanggalPembelian: this.state.dataMaster.tanggalPembelian
        })

    }

    submitDetailPenempatanData = (data) => {
        if (data.id) {
            this.dataGridDetailPenempatanRef.current.push([{
                type: "update",
                data: {
                    noPengajuan: data.noPengajuan,
                    tanggalPembelian: data.tanggalPembelian,
                    instrumentId: data.instrumentId,
                    price: data.price,
                    faceValue: data.faceValue,
                    currencyId: data.currencyId,
                    currencyRate: data.currencyRate,
                    htm: data.htm,
                    institusiOrderId: data.institusiOrderId,
                    rekBankId: data.rekBankId,
                    brokerId: data.brokerId,
                    bankId: data.bankId,
                    maturityDate: data.maturityDate,
                    ratingId: data.ratingId,
                    rate: data.rate,
                    instSubCategoryId: data.instSubCategoryId,
                    accruedInterest: data.accruedInterest,
                    kpdId: 1
                },
                key: data.id
            }])
        } else {
            this.dataGridDetailPenempatanRef.current.push([{
                type: "insert",
                data: {
                    noPengajuan: data.noPengajuan,
                    tanggalPembelian: data.tanggalPembelian,
                    instrumentId: data.instrumentId,
                    price: data.price,
                    faceValue: data.faceValue,
                    currencyId: data.currencyId,
                    currencyRate: data.currencyRate,
                    htm: data.htm,
                    institusiOrderId: data.institusiOrderId,
                    rekBankId: data.rekBankId,
                    brokerId: data.brokerId,
                    bankId: data.bankId,
                    maturityDate: data.maturityDate,
                    ratingId: data.ratingId,
                    rate: data.rate,
                    instSubCategoryId: data.instSubCategoryId,
                    accruedInterest: data.accruedInterest,
                    kpdId: 1
                }
            }])
        }
    }

    deleteDetailPenempatanData = (key) => {
        this.dataGridDetailPenempatanRef.current.push([{
            type: "remove",
            key: key
        }])
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Usulan Penempatan Investasi Obligasi'}
                    minWidth={'90vh'}
                    minHeight={'90vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={3}
                                    id={'formMaster'}
                                    ref={this.formMasterRef}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    scrollingEnabled={false}
                                    labelLocation={"left"}
                                />
                            </div>
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                            <div id="tabindexPenempatanObligasi-0" className={' col-md-12'}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <DevExpressDataGrid
                                            ref={this.dataGridDetailPenempatanRef}
                                            loadAPI='kpds'
                                            insertAPI='kpds'
                                            updateAPI='kpds'
                                            deleteAPI='kpds'

                                            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                            useArraySource={true}
                                            ArraySourceData={this.loadDetailPenempatan}

                                            allowAdding={false}
                                            allowDeleting={false}
                                            allowUpdating={false}

                                            exportExcel={false}
                                            exportFileName={"Instrument"}
                                            allowExportSelectedData={true}
                                            selection={"multiple"}

                                            showBorders={true}

                                            editingMode={'cell'}

                                            paging={true}
                                            defaultPageSize={10}
                                            height={'calc(100vh - 380px)'}

                                            //bagian konfigurasi popup saat insert dan edit record
                                            popupTitle={'Instrument'}
                                            popupWidth={700} //masukan dalam ukuran pixel
                                            popupHeight={500} //masukkan dalam ukuran pixel

                                            popupFormLabelLocation='left' //accepted value = top, left, right
                                            popupFormMinColWidth={300} // minimum lebar kolom
                                            popupFormColCount={3} //jumlah kolom pada form

                                            //akhir bagian konfigurasi popup

                                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                            FilterRow={true} // set false untuk mematikan fitur filter

                                            ColumnConfiguration={this.getDataGridColumnConfiguration(this.props.actionType)} // taruh konfigurasi kolom disini
                                            // SummaryConfiguration={this.summary}

                                            onToolbarPreparing={this.onToolbarPreparing}

                                            //contoh konfigurasi kolom
                                            //this.columns = [{
                                            //    dataField: 'kolom1',
                                            //    caption: 'Ini Kolom 1'
                                            //}, {
                                            //    dataField: 'kolom2',
                                            //    caption: 'Ini Kolom 2'
                                            //}]
                                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                            store={this.props.store} // jangan di edit edit
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="tabindexPenempatanObligasi-1" className={'d-none col-md-12'}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <HtmlEditor
                                            ref={this.kajianPengajuanRef}
                                            height="calc(100vh - 380px)"
                                            value={this.state.kajian}
                                            valueType={this.state.kajianContentType}
                                            name={'kajian'}
                                            readOnly={this.props.actionType == 'view' ? true : false}
                                            onValueChanged={this.onValueChanged}
                                        >
                                            <MediaResizing enabled={true} />
                                            <Toolbar>
                                                {
                                                    this.toolbarItem.map((toolbarItem) => {
                                                        return (
                                                            <Item
                                                                formatName={toolbarItem}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Toolbar>
                                        </HtmlEditor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
                <FormSubInstrumentPenempatanObligasi
                    ref={this.modalOblligasiRef}
                    store={this.props.store}
                    submitDetailPenempatanData={this.submitDetailPenempatanData}
                    type={this.props.actionType}
                />
            </React.Fragment>
        )
    }
}

export default FormPengajuanPenempatanObligasiKorporasi