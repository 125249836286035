import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { pesertaById, pesertas, product } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest';
import { Form, Button } from 'devextreme-react'
import reportRequest, { reportFile } from 'plugin/reportRequest'
import { formatDefaultMonthYear, formatDefaultDate, PrintElem, generateExcelFile, formatNumber, formatNumberAum, formatDefaultFullDate } from 'plugin/helper'
import * as XLSX from 'xlsx';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { showLoading } from 'redux/actions/loading';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';

const bulan = [
    {
        id : 1,
        namaBulan: 'Jan'
    },
    {
        id : 2,
        namaBulan: 'Feb'
    },
    {
        id : 3,
        namaBulan: 'Mar'
    },
    {
        id : 4,
        namaBulan: 'Apr'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Jun'
    },
    {
        id : 7,
        namaBulan: 'Jul'
    },
    {
        id : 8,
        namaBulan: 'Agu'
    },
    {
        id : 9,
        namaBulan: 'Sep'
    },
    {
        id : 10,
        namaBulan: 'Okt'
    },
    {
        id : 11,
        namaBulan: 'Nov'
    },
    {
        id : 12,
        namaBulan: 'Des'
    }
]
class ReportDetailIuranPeserta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            formFilter: {},
            // pesertaData: []
        }

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.valuasiDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TGL-VR') : null

        this.dropDownBoxRef = React.createRef()

        this.pesertaId = 0

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

        const { pesertaData } = this.state;

        this.formItem = [
            // {
            //     dataField: 'periode',
            //     label: {
            //         text: 'Periode'
            //     },
            //     editorType: 'dxDateBox',
            //     editorOptions: {
            //         useMaskBehavior: true,
            //         displayFormat: 'MMM yyyy',
            //         placeholder : 'Bulan/Tahun',
            //         calendarOptions: {
            //             maxZoomLevel: 'year'
            //         },
            //         openOnFieldClick: true
            //     }
            // },
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'greaterPeriode',
                                label: {
                                    text: 'Periode (Bulan / Tahun)'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    displayFormat: 'MMM yyyy',
                                    calendarOptions: {
                                        maxZoomLevel: 'year'
                                    },
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'lessPeriode',
                                label: {
                                    text: 'S/D'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    displayFormat: 'MMM yyyy',
                                    calendarOptions: {
                                        maxZoomLevel: 'year'
                                    },
                                    openOnFieldClick: true
                                }
                            }
                        ]
                    },
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode'
                        }
                    },
                    {
                        itemType: 'empty'
                    }
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    openData = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `iurans/detil-peserta?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')
        
        if(this.pesertaId){
            var loadAPIPeserta = `pesertas/${this.pesertaId}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
            if(getPeserta){
                loadAPI = loadAPI+`nik=${getPeserta.nik}&`
            }
        }
        if(filter.participantId){
            loadAPI = loadAPI+`participantId=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // PERIODE
        // if(filter.periode){
        //     var bulanPeriode = new Date(filter.periode).getMonth() + 1;
        //     var tahunPeriode = new Date(filter.periode).getFullYear()
        //     loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        // }

        if(filter.greaterPeriode && filter.lessPeriode){
            var bulanPeriodeAwal = new Date(filter.greaterPeriode).getMonth() + 1;
            var tahunPeriodeAwal = new Date(filter.greaterPeriode).getFullYear()
            var bulanPeriodeAkhir = new Date(filter.lessPeriode).getMonth() + 1;
            var tahunPeriodeAkhir = new Date(filter.lessPeriode).getFullYear()
            loadAPI = loadAPI+`startMonth=${bulanPeriodeAwal}&endMonth=${bulanPeriodeAkhir}&startYear=${tahunPeriodeAwal}&endYear=${tahunPeriodeAkhir}&`
        }

        var getIuranDetailPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var summaryIuranPeserta = []
        var summaryIuranNormalPk = []
        var summaryIuranManfaatPk = []
        var summaryIuranTotal = []
        for(var value of getIuranDetailPeserta){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'
            
            var participantDetailName = getParticipant.find(val => val.id == value.participantId)
            value.participantId = participantDetailName ? participantDetailName.participantName : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'

            let periode = value.pensiunDate ? formatDefaultFullDate(value.pensiunDate).split(' ') : '-'
            let tanggalPensiun = periode[1] == value.bulan && periode[2] == value.tahun ? formatDefaultDate(value.pensiunDate) : '-'

            // summaryIuranPeserta = summaryIuranPeserta + value.totalIuranPeserta ? value.totalIuranPeserta : 0
            // summaryIuranPk = summaryIuranPk + value.totalIuranPk ? value.totalIuranPk : 0
            // summaryIuranTotal = summaryIuranTotal + value.totalIuran ? value.totalIuran : 0

            value.totalIuranPeserta = value.totalIuranPeserta ? value.totalIuranPeserta : 0
            value.iuranNormalPk = value.iuranNormalPk ? value.iuranNormalPk : 0
            value.iuranManfaatLainPk = value.iuranManfaatLainPk ? value.iuranManfaatLainPk : 0
            value.totalIuran = value.totalIuran ? value.totalIuran : 0
            value.pensiunDate = tanggalPensiun
            value.transactionDate = value.transactionDate ? formatDefaultDate(value.transactionDate) : '-'
            value.receiveDate = value.receiveDate ? formatDefaultDate(value.receiveDate) : '-'

            summaryIuranPeserta.push(value.totalIuranPeserta)
            summaryIuranNormalPk.push(value.iuranNormalPk)
            summaryIuranManfaatPk.push(value.iuranManfaatLainPk)
            summaryIuranTotal.push(value.totalIuran)
        }

        if(summaryIuranPeserta.length !== 0 && summaryIuranNormalPk.length !== 0 && summaryIuranManfaatPk.length !== 0 && summaryIuranTotal.length !== 0) {
            var totalIuranPeserta = summaryIuranPeserta.reduce((a, b) => a + b, 0);
            var totalIuranNormalPk = summaryIuranNormalPk.reduce((a, b) => a + b, 0);
            var totalIuranManfaatLainPk = summaryIuranManfaatPk.reduce((a, b) => a + b, 0);
            var totalIuranTotal = summaryIuranTotal.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/iuran/detail-iuran-peserta',
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                // nik: getIuranDetailPeserta[0].nik+' - '+getIuranDetailPeserta[0].fullName,
                nik: getPeserta.nik + ' - ' + getPeserta.fullName,
                dataIuran : getIuranDetailPeserta,
                summaryIuranPeserta: (totalIuranPeserta),
                summaryIuranNormalPk: (totalIuranNormalPk),
                summaryIuranManfaatPk: (totalIuranManfaatLainPk),
                summaryIuranTotal: (totalIuranTotal),
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            }
        }
        var responseFile = await reportFile(data)
        store.dispatch(showLoading(false))
        this.setState({
            src : responseFile
        })
    }

    print = async() => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `iurans/detil-peserta?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')
        
        if(this.pesertaId){
            var loadAPIPeserta = `pesertas/${this.pesertaId}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
            if(getPeserta){
                loadAPI = loadAPI+`nik=${getPeserta.nik}&`
            }
        }
        if(filter.participantId){
            loadAPI = loadAPI+`participantId=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // PERIODE
        // if(filter.periode){
        //     var bulanPeriode = new Date(filter.periode).getMonth() + 1;
        //     var tahunPeriode = new Date(filter.periode).getFullYear()
        //     loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        // }

        if(filter.greaterPeriode && filter.lessPeriode){
            var bulanPeriodeAwal = new Date(filter.greaterPeriode).getMonth() + 1;
            var tahunPeriodeAwal = new Date(filter.greaterPeriode).getFullYear()
            var bulanPeriodeAkhir = new Date(filter.lessPeriode).getMonth() + 1;
            var tahunPeriodeAkhir = new Date(filter.lessPeriode).getFullYear()
            loadAPI = loadAPI+`startMonth=${bulanPeriodeAwal}&endMonth=${bulanPeriodeAkhir}&startYear=${tahunPeriodeAwal}&endYear=${tahunPeriodeAkhir}&`
        }

        var getIuranDetailPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var summaryIuranPeserta = []
        var summaryIuranNormalPk = []
        var summaryIuranManfaatPk = []
        var summaryIuranTotal = []
        for(var value of getIuranDetailPeserta){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'
            
            var participantDetailName = getParticipant.find(val => val.id == value.participantId)
            value.participantId = participantDetailName ? participantDetailName.participantName : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'
            let periode = value.pensiunDate ? formatDefaultFullDate(value.pensiunDate).split(' ') : '-'
            let tanggalPensiun = periode[1] == value.bulan && periode[2] == value.tahun ? formatDefaultDate(value.pensiunDate) : '-'

            value.totalIuranPeserta = value.totalIuranPeserta ? value.totalIuranPeserta : 0
            value.iuranNormalPk = value.iuranNormalPk ? value.iuranNormalPk : 0
            value.iuranManfaatLainPk = value.iuranManfaatLainPk ? value.iuranManfaatLainPk : 0
            value.totalIuran = value.totalIuran ? value.totalIuran : 0
            value.pensiunDate = tanggalPensiun
            value.transactionDate = value.transactionDate ? formatDefaultDate(value.transactionDate) : '-'
            value.receiveDate = value.receiveDate ? formatDefaultDate(value.receiveDate) : '-'

            summaryIuranPeserta.push(value.totalIuranPeserta)
            summaryIuranNormalPk.push(value.iuranNormalPk)
            summaryIuranManfaatPk.push(value.iuranManfaatLainPk)
            summaryIuranTotal.push(value.totalIuran)
        }
        

        getIuranDetailPeserta = getIuranDetailPeserta.map(value => {
            // value.pensiunDate = value.pensiunDate ? formatDefaultDate(value.pensiunDate) : '-'
            // value.transactionDate = value.transactionDate ? formatDefaultDate(value.transactionDate) : '-'
            // value.dueDate = value.dueDate ? formatDefaultDate(value.dueDate) : '-'
            // value.totalIuranPeserta = value.totalIuranPeserta ? formatNumber(value.totalIuranPeserta) : 0
            // value.totalIuranPk = value.totalIuranPk ? formatNumber(value.totalIuranPk) : 0
            // value.totalIuran = value.totalIuran ? formatNumber(value.totalIuran) : 0

            return value
        })

        if(summaryIuranPeserta.length !== 0 && summaryIuranNormalPk.length !== 0 && summaryIuranManfaatPk.length !== 0 && summaryIuranTotal.length !== 0) {
            var totalIuranPeserta = summaryIuranPeserta.reduce((a, b) => a + b, 0);
            var totalIuranNormalPk = summaryIuranNormalPk.reduce((a, b) => a + b, 0);
            var totalIuranManfaatLainPk = summaryIuranManfaatPk.reduce((a, b) => a + b, 0);
            var totalIuranTotal = summaryIuranTotal.reduce((a, b) => a + b, 0);
        }

        var data = {
            template:{
                name: '/dapen/kepesertaan/report/iuran/detail-iuran-peserta',
                // recipe: 'html'
                recipe: 'chrome-pdf'
            },
            data: {
                tanggalLaporan: formatDefaultDate(new Date()),
                product: productName,
                participant: participantName,
                periode : filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
                // nik: getIuranDetailPeserta[0].nik+' - '+getIuranDetailPeserta[0].fullName,
                nik: getPeserta.nik + ' - ' + getPeserta.fullName,
                dataIuran : getIuranDetailPeserta,
                summaryIuranPeserta: (totalIuranPeserta),
                summaryIuranNormalPk: (totalIuranNormalPk),
                summaryIuranManfaatPk: (totalIuranManfaatLainPk),
                summaryIuranTotal: (totalIuranTotal),
                tanggalSystem: this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null,
                tanggalValuasi: this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null,
            },
            options: {
                reportName: 'DETAIL IURAN PESERTA'
            }
        }
        // var response = await reportRequest(this.props.store,data)
        // store.dispatch(showLoading(false))
        // PrintElem(response.data)

        var responseFile = await reportFile(data, true, 'DETAIL IURAN PESERTA')
        store.dispatch(showLoading(false))
        
        return responseFile
    }

    exportExcel = async() => {
        var data = []
        let store = this.props.store
        store.dispatch(showLoading(true))
        var filter = this.state.formFilter
        var loadAPI = `iurans/detil-peserta?`
        var getProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `products`, 'GET')

        var getParticipant = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants`, 'GET')
        
        if(this.pesertaId){
            var loadAPIPeserta = `pesertas/${this.pesertaId}`
            var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
            if(getPeserta){
                loadAPI = loadAPI+`nik=${getPeserta.nik}&`
            }
        }
        if(filter.participantId){
            loadAPI = loadAPI+`participantId=${filter.participantId}&`
            try {
                var participantName = (await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `participants/${filter.participantId}`, 'GET')).participantName
            } catch (error) {
                var participantName = 'Semua'
            }
        }else{
            var participantName = 'Semua'
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId=${filter.productId}&`
            var productName = getProduct.find(val => val.id == filter.productId) ? getProduct.find(val => val.id == filter.productId).productCode : 'Semua'
        }else{
            var productName = 'Semua'
        }

        // PERIODE
        // if(filter.periode){
        //     var bulanPeriode = new Date(filter.periode).getMonth() + 1;
        //     var tahunPeriode = new Date(filter.periode).getFullYear()
        //     loadAPI = loadAPI+`bulan.in=${bulanPeriode}&tahun.in=${tahunPeriode}&`
        // }

        if(filter.greaterPeriode && filter.lessPeriode){
            var bulanPeriodeAwal = new Date(filter.greaterPeriode).getMonth() + 1;
            var tahunPeriodeAwal = new Date(filter.greaterPeriode).getFullYear()
            var bulanPeriodeAkhir = new Date(filter.lessPeriode).getMonth() + 1;
            var tahunPeriodeAkhir = new Date(filter.lessPeriode).getFullYear()
            loadAPI = loadAPI+`startMonth=${bulanPeriodeAwal}&endMonth=${bulanPeriodeAkhir}&startYear=${tahunPeriodeAwal}&endYear=${tahunPeriodeAkhir}&`
        }

        var getIuranDetailPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI.slice(0,-1), 'GET')

        var summaryIuranPeserta = []
        var summaryIuranNormalPk = []
        var summaryIuranManfaatPk = []
        var summaryIuranTotal = []

        var index = 1
        for(var value of getIuranDetailPeserta){
            var month = bulan.find(val => val.id == value.bulan)
            value.bulan = month ? month.namaBulan : '-'
            
            var participantDetailName = getParticipant.find(val => val.id == value.participantId)
            value.participantId = participantDetailName ? participantDetailName.participantName : '-'

            var productDetailName = getProduct.find(val => val.id == value.productId)
            value.productId = productDetailName ? productDetailName.productCode : '-'
            
            let periode = value.pensiunDate ? formatDefaultFullDate(value.pensiunDate).split(' ') : '-'
            let tanggalPensiun = periode[1] == value.bulan && periode[2] == value.tahun ? formatDefaultDate(value.pensiunDate) : '-'

            summaryIuranPeserta.push(value.totalIuranPeserta)
            summaryIuranNormalPk.push(value.iuranNormalPk)
            summaryIuranManfaatPk.push(value.iuranManfaatLainPk)
            summaryIuranTotal.push(value.totalIuran)
            
            var dataValue = {
                id: index++,
                // productId: value.productId,
                periode: `${value.bulan} - ${value.tahun}`,
                // tahun: value.tahun,
                pensiunDate: tanggalPensiun,
                transactionDate: value.transactionDate ? formatDefaultDate(value.transactionDate) : '-',
                receiveDate: value.receiveDate ? formatDefaultDate(value.receiveDate) : '-',
                totalIuranPeserta: value.totalIuranPeserta ? value.totalIuranPeserta : 0,
                iuranNormalPk: value.iuranNormalPk ? value.iuranNormalPk : 0,
                iuranManfaatLainPk: value.iuranManfaatLainPk ? value.iuranManfaatLainPk : 0,
                totalIuran: value.totalIuran ? value.totalIuran : 0
            }

            const objectValue = Object.values(dataValue)

            data.push(objectValue)
        }

        if(summaryIuranPeserta.length !== 0 && summaryIuranNormalPk.length !== 0 && summaryIuranManfaatPk.length !== 0 && summaryIuranTotal.length !== 0) {
            var totalIuranPeserta = summaryIuranPeserta.reduce((a, b) => a + b, 0);
            var totalIuranNormalPk = summaryIuranNormalPk.reduce((a, b) => a + b, 0);
            var totalIuranManfaatLainPk = summaryIuranManfaatPk.reduce((a, b) => a + b, 0);
            var totalIuranTotal = summaryIuranTotal.reduce((a, b) => a + b, 0);
        }

        try{
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DETAIL IURAN PESERTA');
            worksheet.addTable({
              name: 'MyTable',
              ref: 'A2',
              headerRow: true,
              totalsRow: false,
              style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'No'},
                // {name: 'Produk'},
                {name: 'Periode (Bulan - Tahun)'},
                // {name: 'Tahun'},
                {name: 'Tanggal Pensiun'},
                {name: 'Tanggal Transaksi'},
                {name: 'Tanggal Penerimaan'},
                {name: 'Iuran Peserta\r\n(Rp)'},
                {name: 'Iuran Nominal\r\n(Rp)'},
                {name: 'Iuran Manfaat Lain\r\n(Rp)'},
                {name: 'Total Iuran\r\n(Rp)'}
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for(let i = 0; i < lastTableRowNum; i++) {
                const row = worksheet.getRow(i);
                row.eachCell({ includeEmpty: true }, (cell => {
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
                }))
            }

            worksheet.headerFooter.differentFirst = true;
            worksheet.headerFooter = {
                firstHeader: `&C&BDETAIL IURAN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                oddHeader: `&C&BDETAIL IURAN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`,
                evenHeader: `&C&BDETAIL IURAN PESERTA\nDANA PENSIUN PEGADAIAN\n(Valuasi per ${this.valuasiDate ? formatDefaultDate(this.valuasiDate.dateValue) : null}) &R&BTgl : ${this.systemDate ? formatDefaultDate(this.systemDate.dateValue) : null}\nHal &P dari &N`
            }

            worksheet.pageSetup = {
                fitToPage: true,
                margins: {
                    bottom: 0.75,
                    footer: 0.3,
                    header: 0.3,
                    left: 0.7,
                    right: 0.7,
                    top: 0.75
                }
            }

            // worksheet.getCell('A1').value = 'DANA PENSIUN PEGADAIAN'

            // worksheet.getCell('A3').value = 'Kode'
            // worksheet.getCell('B3').value = ': KPS-2-02'

            // worksheet.getCell('A4').value = 'Laporan'
            // worksheet.getCell('B4').value = ': DETAIL IURAN PESERTA'

            // worksheet.getCell('A5').value = 'Tanggal Laporan'
            // worksheet.getCell('B5').value = `: ${formatDefaultDate(new Date())}`

            // worksheet.getCell('A7').value = 'NIK'
            // worksheet.getCell('B7').value = `: ${value.nik+' - '+value.fullName}`
            
            // worksheet.getCell('A8').value = 'Periode (Bulan / Tahun)'
            // // worksheet.getCell('B8').value = `: ${formatDefaultDate(filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua')}`
            // worksheet.getCell('B8').value = `: ${filter.greaterPeriode && filter.lessPeriode ? (filter.greaterPeriode ? formatDefaultMonthYear(filter.greaterPeriode) : "~") + ' S/D ' + (filter.lessPeriode ? formatDefaultMonthYear(filter.lessPeriode) : "~") : 'Semua'}`

            // worksheet.getCell('A9').value = 'Produk'
            // worksheet.getCell('B9').value = `: ${productName}`

            worksheet.mergeCells(`A${lastTableRowNum + 1}:E${lastTableRowNum + 1}`);
            worksheet.getCell(`A${lastTableRowNum + 1}`).value = 'Total (Rp)';
            worksheet.getCell(`A${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`E${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' },};
            worksheet.getCell(`A${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' },};

            worksheet.getCell(`F${lastTableRowNum + 1}`).value = (totalIuranPeserta)
            worksheet.getCell(`F${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`F${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`G${lastTableRowNum + 1}`).value = (totalIuranNormalPk)
            worksheet.getCell(`G${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`G${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`H${lastTableRowNum + 1}`).value = (totalIuranManfaatLainPk)
            worksheet.getCell(`H${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`H${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.getCell(`I${lastTableRowNum + 1}`).value = (totalIuranTotal)
            worksheet.getCell(`I${lastTableRowNum + 1}`).alignment = {vertical: 'middle', horizontal: 'right'};
            worksheet.getCell(`I${lastTableRowNum + 1}`).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.mergeCells('A1:A2)');
            worksheet.getCell('A1').value = 'No';
            worksheet.getCell('A1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('A1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('A2').alignment = {vertical: 'middle', horizontal: 'center'};

            // worksheet.mergeCells('B1:B2');
            // worksheet.getCell('B1').value = 'Produk';
            // worksheet.getCell('B1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            // worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('B2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('B1:B2');
            worksheet.getCell('B1').value = 'Periode (Bulan - Tahun)';
            worksheet.getCell('B1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('B1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('B2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            
            worksheet.mergeCells('C1:C2');
            worksheet.getCell('C1').value = 'Tanggal Pensiun';
            worksheet.getCell('C1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('C1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('C2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('D1:D2');
            worksheet.getCell('D1').value = 'Tanggal Transaksi';
            worksheet.getCell('D1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('D1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('D2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('E1:E2');
            worksheet.getCell('E1').value = 'Tanggal Penerimaan';
            worksheet.getCell('E1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('E1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('E2').alignment = {vertical: 'middle', horizontal: 'center'};

            worksheet.mergeCells('F1:F2');
            worksheet.getCell('F1').value = 'Iuran Peserta\r\n(Rp)';
            worksheet.getCell('F1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('F1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('F2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            worksheet.mergeCells('G1:H1');
            worksheet.getCell('G1').value = 'Iuran PK';
            worksheet.getCell('G1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('G1').alignment = {vertical: 'middle', horizontal: 'center'};
            worksheet.getCell('G2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('G2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('H2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('H2').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

            worksheet.mergeCells('I1:I2');
            worksheet.getCell('I1').value = 'Total Iuran\r\n(Rp)';
            worksheet.getCell('I1').border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            worksheet.getCell('I1').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
            worksheet.getCell('I2').alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};

            // worksheet.getCell('A12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('B12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('C12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('D12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('E12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('F12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('G12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('H12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('I12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('J12').alignment = {vertical: 'middle', horizontal: 'center'};
            // worksheet.getCell('K12').alignment = {vertical: 'middle', horizontal: 'center'};

            for(var i = 3; i <= data.length + 2; i++){
                worksheet.getCell('A'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('A'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('B'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('B'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('C'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('C'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('D'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('D'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'center'};
                worksheet.getCell('E'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('H'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('H'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('I'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                // worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                // worksheet.getCell('J'+i).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            }

            var WidthColums = 20;
            
            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = 30;
            Data6.numFmt = '#,##0.00';

            const Data7 = worksheet.getColumn(7);
            Data7.width = 30;
            Data7.numFmt = '#,##0.00';

            const Data8 = worksheet.getColumn(8);
            Data8.width = 30;
            Data8.numFmt = '#,##0.00';

            const Data9 = worksheet.getColumn(9);
            Data9.width = 30;
            Data9.numFmt = '#,##0.00';

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;
            Data10.numFmt = '#,##0.00';

            // const Data11 = worksheet.getColumn(11);
            // Data11.width = 25;
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DETAIL IURAN PESERTA.xlsx`
                );
            });
        }catch(e){
            console.log(e)
        }
        store.dispatch(showLoading(false))

        // var headerNameMapping = [
        //     {
        //         from:"id",
        //         to:"No"
        //     },
        //     {
        //         from:"productId", 
        //         to:"Produk"
        //     },
        //     {
        //         from:"bulan", 
        //         to:"Bulan"
        //     },
        //     {
        //         from:"tahun", 
        //         to:"Tahun"
        //     },
        //     {
        //         from:"pensiunDate", 
        //         to:"Tanggal Pensiun"
        //     },
        //     {
        //         from:"transactionDate", 
        //         to:"Tanggal Transaksi"
        //     },
        //     {
        //         from:"dueDate", 
        //         to:"Tanggal Pembayaran"
        //     },
        //     {
        //         from:"totalIuranPeserta", 
        //         to:"Iuran Peserta"
        //     },
        //     {
        //         from:"totalIuranPk", 
        //         to:"Iuran Pemberi Kerja"
        //     },
        //     {
        //         from:"totalIuran", 
        //         to:"Total Iuran"
        //     }
        // ]

        // var header = [
        //     {
        //         A: 'DANA PENSIUN PEGADAIAN'
        //     },
        // ]
        
        // const ws = XLSX.utils.json_to_sheet(header, {skipHeader: true});

        // var title = [
        //     {
        //         A: 'Kode',
        //         B: null,
        //         C: ':',
        //         D: 'KPS-2-02'
        //     },
        //     {
        //         A: 'Laporan',
        //         B: null,
        //         C: ':',
        //         D: 'DETAIL IURAN PESERTA'
        //     },
        //     {
        //         A: 'Tanggal Laporan',
        //         B: null,
        //         C: ':',
        //         D: formatDefaultDate(new Date())
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,title,{skipHeader: true, origin: 'A3'})

        // var subTitle = [
        //     {
        //         A: 'Pemberi Kerja',
        //         B: null,
        //         C: ':',
        //         D: participantName
        //     },
        //     {
        //         A: 'Periode',
        //         B: null,
        //         C: ':',
        //         D: filter.periode ? formatDefaultMonthYear(filter.periode) : 'Semua',
        //     },
        //     {
        //         A: 'Produk',
        //         B: null,
        //         C: ':',
        //         D: productName
        //     },
        // ]

        // XLSX.utils.sheet_add_json(ws,subTitle,{skipHeader: true, origin: 'A7'})
        // var instructions = []
    
        // for(var InstructionData of getIuranDetailPeserta){
        //     var row = {}
        //     for(var column in InstructionData){
        //         var headerMapping = headerNameMapping.find(value => value.from == column)
        //         if(headerMapping){
        //             row[headerMapping.to] = InstructionData[column]
        //         }
        //     }
        //     instructions.push(row)
        // }

        // XLSX.utils.sheet_add_json(ws,instructions,{header: headerNameMapping.map(value => value.to),origin: 'A12'})

        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // const excelBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
        // store.dispatch(showLoading(false))
        // generateExcelFile(excelBlob,'LAPORAN_IURAN_RESUME_PK')
    }

    onSelectionChanged = (e) => {
        this.pesertaId = e.selectedRowKeys[0]
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
    }

    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                // loadAPI= {'lookup-pesertas/getByLookupByStatusPeserta/2?size=9999'}
                loadAPI='pesertas/all-simple'
                insertAPI='pengkinian-peserta-bo-aktifs'
                updateAPI='pengkinian-peserta-bo-aktifs'
                deleteAPI='pengkinian-peserta-bo-aktifs'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {false}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Detail Iuran Peserta</h2>
                <div className="row" style={{flexDirection: 'column'}}>
                    <div className="col-md-6">
                        <div style={{marginBottom: "5px"}}>
                            <DropDownBox
                                ref={this.dropDownBoxRef}
                                valueExpr="id"
                                deferRendering={false}
                                placeholder = {'Pilih Peserta'}
                                displayExpr={(item) => {
                                    return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                }}
                                showClearButton={true}
                                dataSource={pesertaById(this.props.store)}
                                onValueChanged={this.onValueChanged}
                                contentRender={this.renderDatagrid}
                            />
                        </div>
                        <Form
                            colCount={1}
                            id={'formDataMaster'}
                            ref={this.formRef}
                            formData={this.state.formFilter}
                            items={this.formItem}
                            labelLocation={"left"}
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Buka"}
                                    stylingMode={"contained"}
                                    onClick={this.openData.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Cetak"}
                                    stylingMode={"contained"}
                                    onClick={this.print.bind(this)}
                                />
                            </div>
                            <div className="col-md-4">
                                <Button
                                    width={250}
                                    text={"Export Excel"}
                                    stylingMode={"contained"}
                                    onClick={this.exportExcel.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <iframe src={this.state.src} className={this.state.src ? '' : 'd-none'} width="100%" style={{height: "calc(100vh - 415px)"}} title={'ea'}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportDetailIuranPeserta