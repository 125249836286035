import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { Box, FileUploader } from 'devextreme-react';
import { Item } from 'devextreme-react/accordion';
import { banks, cities, citiesFilter, gender, kecamatans, kecamatansFilter, kelurahanFilter, kelurahans, mpPenerimaType, pesertaById, provinces } from 'dataSource/lookup';
import { formatUploadFileData, formatUploadFileNameAndExtention, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import ScrollView from 'devextreme-react/scroll-view';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';

class ModalProsesDaftarUlang extends Component {
  constructor(props) {
    super(props)

    this.state = {
      popupVisible: false,
      dataMaster: {
        lamiranSuratKetSekolah: "",
        lamiranSuratKetSekolahContentType: "",
        lamiranSuratKetSekolahName: "",
        lamiranSuratKetSekolahExtention: "",
        lampiranAktaNikah: "",
        lampiranAktaNikahConlamiranSuratKetSekolahContentType: "",
        lampiranAktaNikahName: "",
        lampiranAktaNikahExtention: "",
        lampiranKk: "",
        lampiranKkConlamiranSuratKetSekolahContentType: "",
        lampiranKkName: "",
        lampiranKkExtention: "",
        lampiranSuratKetBelumnikah: "",
        lampiranSuratKetBelumnikahConlamiranSuratKetSekolahContentType: "",
        lampiranSuratKetBelumnikahName: "",
        lampiranSuratKetBelumnikahExtention: "",
        lampiranSuratKetBerpenghasilan: "",
        lampiranSuratKetBerpenghasilanConlamiranSuratKetSekolahContentType: "",
        lampiranSuratKetBerpenghasilanName: "",
        lampiranSuratKetBerpenghasilanExtention: "",
        lampiranSuratKetJandaduda: "",
        lampiranSuratKetJandadudaConlamiranSuratKetSekolahContentType: "",
        lampiranSuratKetJandadudaName: "",
        lampiranSuratKetJandadudaExtention: "",
        lampiranSuratKetMeninggal: "",
        lampiranSuratKetMeninggalConlamiranSuratKetSekolahContentType: "",
        lampiranSuratKetMeninggalName: "",
        lampiranSuratKetMeninggalExtention: "",
      },
    }

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
    this.lookupDataKeluarga = []
    this.pesertaId = 0

    this.PopupRef = React.createRef()
    this.formDetailRef = React.createRef()
    this.dropDownBoxRef = React.createRef()

    this.PopupToolbarItem = [
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Simpan',
          onClick: async () => await this.submitData(),
        },
        toolbar: 'bottom',
      },
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          'text': 'Tutup',
          onClick: this.hide.bind(this)
        },
        toolbar: 'bottom'
      }
    ]

    this.columns = [
        {
            dataField: 'nik',
            caption: 'NIK',
        },
        {
            dataField: 'fullName',
            caption: 'Nama Lengkap',
        },
    ]

    this.dataMaster = [
        {
            dataField: 'tanggalDiterima',
            label: {
                text: 'Tanggal Diterima'
            },
            editorType: 'dxDateBox',
            editorOptions:{
                placeholder: 'dd MMM yyyy',
                displayFormat: 'dd MMM yyyy',
                useMaskBehavior: true,
                width: '100%'
            },
        },
        {
            dataField : 'perubahan',
            label : {
                text: 'Terdapat Perubahan Data'
            },
            editorType: 'dxCheckBox'
        }
    ]
    this.dataDetail = [
        {
            itemType: 'group',
            caption: 'Jika Ada Perubahan',
        },
        {
            itemType: 'group',
            caption: 'Detail Daftar Ulang',
        },
        {
            dataField: 'pesertaMeninggal',
            label: {
                text: 'Peserta Meninggal'
            },
            editorType: 'dxCheckBox'
        },
        {
            dataField: 'pasanganMenikahlagi',
            label: {
                // text: 'Status Janda/Duda',
                visible: false
            },
            editorType: 'dxRadioGroup',
            editorOptions: {
                layout: 'horizontal',
                items: [
                    {
                        value: 1,
                        text: 'Menikah Lagi'
                    },
                    {
                        value: 2,
                        text: 'Tidak Menikah Lagi'
                    },
                ],
                valueExpr: 'value'
            },
        },
        {
            dataField: 'pasanganMeninggal',
            label: {
                text: 'Pasangan Peserta Meninggal'
            },
            editorType: 'dxCheckBox'
        },
        {
            dataField: 'pesertaMenikahlagi',
            label: {
                // text: 'Status Janda/Duda',
                visible: false
            },
            editorType: 'dxRadioGroup',
            editorOptions: {
                layout: 'horizontal',
                items: [
                    {
                        value: 1,
                        text: 'Menikah Lagi'
                    },
                    {
                        value: 2,
                        text: 'Tidak Menikah Lagi'
                    },
                ],
                valueExpr: 'value'
            },
        },
        {
            dataField: 'informasiPribadiPensiunan',
            label: {
                text: 'Informasi Pribadi Pensiunan'
            },
            editorType: 'dxCheckBox'
        },
        {
            itemType: 'group',
            caption: 'Informasi Pribadi Pensiun',
            items: [
                {
                    itemType: 'group',
                    caption: 'Kontak',
                    items: [
                        {
                            dataField: 'email',
                            label:{
                                alignment: 'left',
                                text:'Email',
                            },
                        },
                        {
                            dataField: 'mobileNo',
                            label:{
                                alignment: 'left',
                                text:'No Telp',
                            },
                        },
                        {
                            dataField: 'noHp',
                            label:{
                                alignment: 'left',
                                text:'No Handphone',
                            },
                        },
                    ]
                },
                {
                    itemType: 'group',
                    caption: 'Alamat Domisili',
                    items: [
                        {
                            dataField: 'domisiliAddress',
                            label:{visible: true},
                            editorType: 'dxTextArea',
                            editorOptions: {
                                height: '200px'
                            }
                        },
                        {
                            dataField: 'domisiliProvinsiId',
                            label: {
                                alignment: 'left',
                                text: 'Provinsi'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: provinces(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'provinceName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: async(data) => {
                                    var selectCity = this.formDetailRef.current.instance.getEditor('domisiliCityId')
                                    // selectCity.option('value', '')
                                    selectCity.option('dataSource',citiesFilter(this.props.store,data.value,true))
                                }
                            },
                        },
                        {
                            dataField: 'domisiliCityId',
                            label: {
                                alignment: 'left',
                                text: 'Kota'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: cities(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'cityName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectKecamatan = this.formDetailRef.current.instance.getEditor('domisiliKecamatanId')
                                    // selectKecamatan.option('value', '')
                                    if(data.value){
                                        selectKecamatan.option('dataSource',kecamatansFilter(this.props.store,data.value,true))
                                    }
                                }
                            },
                        },
                        {
                            dataField: 'domisiliKecamatanId',
                            label: {
                                alignment: 'left',
                                text: 'Kecamatan'
                            },
                            editorType: 'dxSelectBox',
                            editorOptions: {
                                dataSource: kecamatans(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'kecamatanName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onValueChanged: (data) => {
                                    var selectKelurahan = this.formDetailRef.current.instance.getEditor('domisiliKelurahanId')
                                    if(data.value){
                                        selectKelurahan.option('dataSource',kelurahanFilter(this.props.store,data.value,true))
                                    }
                                },
                            },
                        },
                        {
                            dataField: 'domisiliKelurahanId',
                            label: {
                                alignment: 'left',
                                text: 'Kelurahan'
                            },
                            editorType : 'dxSelectBox',
                            editorOptions: {
                                dataSource: kelurahans(this.props.store),
                                valueExpr: 'id', // contains the same values as the "statusId" field provides
                                displayExpr: 'kelurahanName', // provides display values'
                                searchEnabled: true,
                                deferRendering: false,
                                onSelectionChanged: (data) => {
                                    if(data.selectedItem) {
                                        var inputKodePos = this.formDetailRef.current.instance.getEditor('domisiliKodePos')
                                        inputKodePos.option('value', data.selectedItem.kodePos)
                                    }
                                }
                            }
                        },
                        {
                            dataField: 'domisiliKodePos',
                            label: {
                                alignment: 'left',
                                text: 'Kode Pos'
                            },
                            editorOptions: {
                                readOnly: true
                            }
                        }
                    ]
                }
            ]
        },
        {
            dataField: 'penerimaBankMp',
            label: {
                text: 'Rekening Penerima Bank MP'
            },
            editorType: 'dxCheckBox'
        },
        {
            itemType: 'group',
            caption: 'Rekening Bank MP',
            items: [
                {
                    dataField: 'rekeningmpNo',
                    label: {
                        text: 'No Rek MP'
                    }
                },
                {
                    dataField: 'rekeningmpNama',
                    label: {
                        text: 'Nama Rek MP'
                    }
                },
                {
                    dataField: 'rekeningmpBankId',
                    label: {
                        text: 'Rekening Bank Pembayaran'
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        dataSource: banks(this.props.store),
                        valueExpr: 'id',
                        displayExpr: 'initialName',
                        searchEnabled: true,
                        deferRendering: false,
                    },
                },
                {
                    dataField: 'rekeningmpCabang',
                    label: {
                        text: 'Cabang Bank Pembayaran'
                    }
                },
            ]
        },
        {
            dataField: 'penerimamp',
            label: {
                text: 'Penerima Manfaat Pensiun'
            },
            editorType: 'dxCheckBox'
        },
        {
            itemType: 'group',
            // caption: 'Penerima Manfaat Pensiun',
            items: [
                {
                    dataField: 'penerimampTypeId',
                    label: {
                        text: 'Tipe Penerima'
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        dataSource: mpPenerimaType(this.props.store),
                        valueExpr: 'id',
                        displayExpr: 'penerimaName',
                        searchEnabled: true,
                        deferRendering: false,
                        onValueChanged : (e) => {
                            var data = e.value
                            var namaPenerima = this.formDetailRef.current.instance.itemOption('namaPenerima')
                            if(data){
                                if(data == 1){
                                    namaPenerima.editorType = 'dxTextBox'
                                    namaPenerima.editorOptions.value = this.state.dataMaster.penerimampNama
                                    namaPenerima.editorOptions.readOnly = true
                                }else{
                                    namaPenerima.editorType = 'dxSelectBox'
                                    namaPenerima.editorOptions.readOnly = false
                                    namaPenerima.editorOptions.dataSource = this.lookupDataKeluarga
                                }
                                this.formDetailRef.current.instance.itemOption('namaPenerima',namaPenerima)
                            }
                        }
                    },
                },
                {
                    dataField : 'penerimampNama',
                    name: 'namaPenerima',
                    label: {
                        text: 'Nama Penerima'
                    },
                    editorType: 'dxSelectBox',
                    editorOptions: {
                        dataSource: [],
                        valueExpr: 'fullName',
                        displayExpr: 'fullName',
                        searchEnabled: true,
                        deferRendering: false,
                    },
                }
            ]
        },
    ]
  }

  get Popup() {
    return this.PopupRef.current.instance
  }

  show() {
    this.setState({
      popupVisible: true,
      selectedTabIndex: 0
    });
  }

  hide() {
    this.dropDownBoxRef.current.instance.option('value','')
    this.setState({
      popupVisible: false,
      dataMaster: {},
    });
  }

  submitData = async () => {
    var dataSubmit = { ...this.state.dataMaster }
    await this.props.submitData(dataSubmit)
    this.hide()
  }

  retrieveData = async(data, type) => {
    if(type == 'online'){
        var loadAPI = `daftar-ulang-onlines/${data.id}`
        data = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')

        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `pesertas/${data.pesertaId}`, 'GET')

        data.pasanganMenikahlagi = data.statusPerkawinan
        data.tanggalDiterima = data.tglTerima
        data.noTelp = data.mobileNo
        data.noHp = data.noTelp
        data.noHp = data.noTelp
        data.domisiliAddress = data.alamatPribadiPensiunan
        data.domisiliProvinsiId = data.provinceId
        data.domisiliCityId = data.cityId
        data.rekeningmpNo = data.nomorRekeningMp
        data.rekeningmpNama = data.namaRekening
        data.rekeningmpBankId = data.bankIdMp
        data.rekeningmpCabang = data.cabang
        data.penerimampTypeId = data.penerimaMp
        data.penerimampNama = getPeserta.fullName
        data.lampiranKk = data.fotoKk
        data.lampiranKkContentType = data.fotoKkContentType
        data.lampiranSuratKetJandaduda = data.filePenerimaJadud
        data.lampiranSuratKetJandadudaContentType = data.filePenerimaJadudContentType
        data.lampiranAktaNikah = data.lampiranPerkawinan
        data.lampiranAktaNikahContentType = data.lampiranPerkawinanContentType
    }

    this.pesertaId = data.pesertaId
    this.dropDownBoxRef.current.instance.option('value',data.pesertaId)
    this.setState({
      dataMaster: data
    })
  }

  onUploadStarted = (e, jenisData) => {
    var fieldName = e.component._options._optionManager._options.name
    for (var i = 0; i < e.value.length; i++) {
      var files = e.value[i]

      var fr = new FileReader()

      var formattedFileData
      var formattedFileNameAndExtention

      fr.onload = (efr) => {
        formattedFileData = formatUploadFileData(efr.target.result)
        formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
        this.setState(prevState => ({
          [jenisData]: {
            ...prevState[jenisData],
            [`${fieldName}Data`]: formattedFileData.base64data,
            [`${fieldName}DataContentType`]: formattedFileData.mime,
            [`${fieldName}Name`]: formattedFileNameAndExtention.name,
            [`${fieldName}Extention`]: formattedFileNameAndExtention.extention,
          }
        }))
      }

      fr.readAsDataURL(files)
    }
  }

  onSelectionChanged = async(e) => {
    this.pesertaId = e.selectedRowKeys[0]

    var loadAPIPeserta = `pesertas/${this.pesertaId}`
    var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')
    
    var loadAPIAkun = `kepersertaan-products/getByPesertaId/${this.pesertaId}`
    var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')

    var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`
    var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKeluarga, 'GET')

    var penerimampNama = ""
    if(getAkun.length !== 0){
        var findPenerimaMp = getKeluarga.find(value => value.id == getAkun[0].mpPenerimaPesertaFamilyTypeId)
        if(findPenerimaMp){
            var penerimaName = findPenerimaMp.fullName
        }else{
            var penerimaName = getPeserta.fullName
        }
    }
    var data = {
        domisiliAddress: getPeserta.domicileAddress1,
        domisiliCityId: getPeserta.domicileCityId,
        domisiliKecamatanId: getPeserta.domicileKecamatanId,
        domisiliKelurahanId: getPeserta.domicileKelurahanId,
        domisiliKodePos: getPeserta.domicilePostalCode,
        domisiliProvinsiId: getPeserta.domicileProvinceId,
        email: getPeserta.email,
        mobileNo: getPeserta.mobileNo,
        noHp: getPeserta.phoneNo,
        penerimampFamilyId: getAkun.length !== 0 ? getAkun[0].mpPenerimaPesertaFamilyTypeId : 0,
        penerimampNama: penerimaName,
        penerimampTypeId: getAkun.length !== 0 ? getAkun[0].mpPenerimaTypeId : 0,
        pesertaId: this.pesertaId,
        rekeningmpBankId: getPeserta.rekeningBankId,
        rekeningmpCabang: getPeserta.branchId,
        rekeningmpNama: getPeserta.rekeningNama,
        rekeningmpNo: getPeserta.rekeningNo,
    }

    this.formDetailRef.current.instance.updateData(data)
    this.lookupDataKeluarga = getKeluarga
    this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
  }

  renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI= {'view-pesertas?statusPeserta.in=4&statusPeserta.in=6'}
                insertAPI='pesertas'
                updateAPI='pesertas'
                deleteAPI='pesertas'

                backendserver={process.env.REACT_APP_BACKEND_CORE}
                
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

  render() {
    return (
      <Popup
        className={'popup'}
        visible={this.state.popupVisible}
        onHiding={this.hide}
        dragEnabled={true}
        resizeEnabled={true}
        showTitle={true}
        title={'FORM DAFTAR ULANG KONVENSIONAL'}
        minWidth={500}
        minHeight={500}

        ref={this.PopupRef}

        toolbarItems={this.PopupToolbarItem}
      >
        <ScrollView height={'100%'} width={'100%'}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">Peserta</label>
                <div className="col-sm-10">
                    <DropDownBox
                        ref={this.dropDownBoxRef}
                        valueExpr="id"
                        deferRendering={false}
                        displayExpr={(item) => {
                            return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                        }}
                        showClearButton={true}
                        dataSource={pesertaById(this.props.store)}
                        onValueChanged={this.onValueChanged}
                        contentRender={this.renderDatagrid}
                    />
                </div>
              </div>
              <Form
                ref={this.formDetailRef}
                colCount={2}
                id={'formDataMaster'}
                formData={this.state.dataMaster}
                items={this.dataMaster}
                labelLocation="left"
                scrollingEnabled={true}
              />
            </div>
            <div className="col-md-12 mt-3">
                <hr/>
            </div>
            <div className="col-md-12">
              <Form
                ref={this.formDetailRef}
                colCount={2}
                id={'formDataMaster'}
                formData={this.state.dataMaster}
                items={this.dataDetail}
                labelLocation="left"
                scrollingEnabled={true}
              />
            </div>
            <div className="col-md-12 mt-3">
                <font className="font-weight-bold" style={{fontSize: '13px'}}>Data Dokumen</font>
                <hr/>
            </div>
            <div className="col-md-6">
                <Box 
                    direction={'row'}
                    width={'100%'}
                >
                    <Item ratio={1}>
                        <FileUploader 
                            accept={'image/png, image/jpeg, image/png'} 
                            uploadMode={'useForm'}
                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                            labelText={this.state.dataMaster.lampiranSuratKetMeninggalName || 'Surat Keterangan Peserta Meninggal'}
                            showFileList={false}
                            name={'lampiranSuratKetMeninggal'}
                        />
                        <img src={imageSource(this.state.dataMaster.lampiranSuratKetMeninggalContentType,this.state.dataMaster.lampiranSuratKetMeninggal)} width="20%"/>
                    </Item>
                </Box>
                <Box 
                    direction={'row'}
                    width={'100%'}
                >
                    <Item ratio={1}>
                        <FileUploader 
                            accept={'image/png, image/jpeg, image/png'} 
                            uploadMode={'useForm'}
                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                            labelText={this.state.dataMaster.lampiranKkName || 'Lampiran Foto Kartu Keluarga'}
                            showFileList={false}
                            name={'lampiranKk'}
                        />
                        <img src={imageSource(this.state.dataMaster.lampiranKkContentType,this.state.dataMaster.lampiranKk)} width="20%" />
                    </Item>
                </Box>
            </div>
            <div className="col-md-6">
                <Box 
                    direction={'row'}
                    width={'100%'}
                >
                    <Item ratio={1}>
                        <FileUploader 
                            accept={'image/png, image/jpeg, image/png'} 
                            uploadMode={'useForm'}
                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                            labelText={this.state.dataMaster.lampiranAktaNikahName || 'Foto Copy Akta Nikah'}
                            showFileList={false}
                            name={'lampiranAktaNikah'}
                        />
                        <img src={imageSource(this.state.dataMaster.lampiranAktaNikahContentType,this.state.dataMaster.lampiranAktaNikah)} width="20%" />
                    </Item>
                </Box>
                <Box 
                    direction={'row'}
                    width={'100%'}
                >
                    <Item ratio={1}>
                        <FileUploader 
                            accept={'image/png, image/jpeg, image/png'} 
                            uploadMode={'useForm'}
                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                            labelText={this.state.dataMaster.lampiranSuratKetJandadudaName || 'Lampiran Foto Surat Keterangan Kejandaan/Kedudaan'}
                            showFileList={false}
                            name={'lampiranSuratKetJandaduda'}
                        />
                        <img src={imageSource(this.state.dataMaster.lampiranSuratKetJandadudaContentType,this.state.dataMaster.lampiranSuratKetJandaduda)} width="20%"/>
                    </Item>
                </Box>
            </div>
            <div className="col-md-6">
                <Box 
                    direction={'row'}
                    width={'100%'}
                >
                    <Item ratio={1}>
                        <FileUploader 
                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                            uploadMode={'useForm'}
                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                            labelText={this.state.dataMaster.lamiranSuratKetSekolahName || 'Lampiran Keterangan masih Sekolah/Kuliah'}
                            showFileList={false}
                            name={'lamiranSuratKetSekolah'}
                        />
                        <img src={imageSource(this.state.dataMaster.lamiranSuratKetSekolahContentType,this.state.dataMaster.lamiranSuratKetSekolah)} width="20%"/>
                    </Item>
                </Box>
                <Box 
                    direction={'row'}
                    width={'100%'}
                >
                    <Item ratio={1}>
                        <FileUploader 
                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                            uploadMode={'useForm'}
                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                            labelText={this.state.dataMaster.lampiranSuratKetBerpenghasilanName || 'Surat Keterangan Berpenghasilan Dari Kelurahan'}
                            showFileList={false}
                            name={'lampiranSuratKetBerpenghasilan'}
                        />
                        <img src={imageSource(this.state.dataMaster.lampiranSuratKetBerpenghasilanContentType,this.state.dataMaster.lampiranSuratKetBerpenghasilan)} width="20%"/>
                    </Item>
                </Box>
            </div>
            <div className="col-md-6">
                <Box 
                    direction={'row'}
                    width={'100%'}
                >
                    <Item ratio={1}>
                        <FileUploader 
                            // accept={'image/png, image/jpeg, image/png, image/xlsx'} 
                            uploadMode={'useForm'}
                            onValueChanged={(e) => this.onUploadStarted(e,'dataMaster')} 
                            labelText={this.state.dataMaster.lampiranSuratKetBelumnikahName || 'Surat Keterangan Belum Menikah'}
                            showFileList={false}
                            name={'lampiranSuratKetBelumnikah'}
                        />
                        <img src={imageSource(this.state.dataMaster.lampiranSuratKetBelumnikahContentType,this.state.dataMaster.lampiranSuratKetBelumnikah)} width="20%"/>
                    </Item>
                </Box>
            </div>
          </div>
        </div>
        </ScrollView>
      </Popup>
    );
  }
}


export default ModalProsesDaftarUlang