import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalPembayaranTunjanganMed from 'components/pages/modal/sdm-umum/payroll/tunjangan-medical/form';
import { months, statusPembayaranRekapGaji } from 'dataSource/lookup';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import {Form} from 'devextreme-react';
import httpRequest from 'plugin/httprequest';

class DaftarPembayaranTunjanganMedical extends Component {
    constructor(props){
        super(props)
 
        this.state = {
            DataMaster:{}
        }

        this.showModalPembayaranTunjanganMedRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
        this.filterData = []

        this.formMaster = [
            {
                dataField:"bulan",
                label:{
                    text:"Bulan",
                    alignment:"left",
                    location:"left",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled:true
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                    location:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        onClick : (e) => {
                            this.showModalPembayaranTunjanganMed(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'nikKaryawan',
                caption: 'NIK',
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'nikPanjang'
                // }
            },
            {
                dataField: 'namaKaryawan',
                caption: 'Karyawan',
                // lookup: {
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'fullName'
                // }
            },
            {
                dataField: 'transactionTypeName',
                caption: 'Tipe Tunjangan',
                // lookup: {
                //     dataSource: tunjanganHrd(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: 'tunjanganName'
                // }
            },
            {
                dataField: 'tanggalPengajuan',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalPembayaran',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'pengajuanTerakhir',
                caption: 'Pengajuan Terakhir',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'nominalDisetujui',
                caption: 'Nominal disetujui',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalPengajuan',
                caption: 'Nominal Pengajuan',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentDidMount = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-karyawan-medical-tunais?statusId.in=2`,
            'GET'
        )
        this.filterData = datas
        this.forceRefresh()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalPembayaranTunjanganMed = (data) => {
        if (data) {
            this.showModalPembayaranTunjanganMedRef.current.retrieveData(data)
        }
        this.showModalPembayaranTunjanganMedRef.current.show(data)
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-karyawan-medical-tunais?statusId.in=2`,
            'GET'
        )

        var listData = []
        for(var data of datas){
            data.tanggalPengajuan = new Date(data.tanggalPengajuan)
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan && value.tanggalPengajuan.getFullYear() == state.tahun)
        }else if (state.bulan) {
            this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.tanggalPengajuan.getFullYear() == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan && value.tanggalPengajuan.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    
    }

    loadData = () => {
        return this.filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Tunjangan Medical</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='regist-pengkinian-pengajuan-aktifs'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPembayaranTunjanganMed 
                    ref={this.showModalPembayaranTunjanganMedRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    from={'daftar'}
                />
            </div>
        )
    }
}

export default DaftarPembayaranTunjanganMedical