import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import KlaimDetailBaru from 'components/pages/modal/kepesertaan/pembayaranMp/klaimMp/klaimDetail';
import KlaimProsesBaru from 'components/pages/modal/kepesertaan/pembayaranMp/klaimMp/klaimProses';
import { klaimType, media } from 'dataSource/lookup';

class KlaimMpRiwayat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {}
    }

    this.dataGridRef = React.createRef()
    this.klaimDetailBaruRef = React.createRef()
    this.klaimProsesBaruRef = React.createRef()

    this.columns = [
      {
        type: 'buttons',
        buttons: [
          {
            text: 'Detail',
            hint: 'Detail',
            visible: true,
            onClick: (e) => {
              this.showModalProses(e.row.data)
            },
          },
        ],
      },
      {
        dataField: 'id',
        caption: "No.",
        cellRender: (data) => {
          return data.rowIndex + 1
        },
      },
      {
        dataField: 'klaimDate',
        caption: 'Tanggal',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right'
      },
      {
        dataField: 'mediaId',
        caption: 'Media',
        lookup : {
          dataSource : media(this.props.store),
          valueExpr : 'id',
          displayExpr : 'mediaName'
        }
      },
      {
        dataField: 'fullName',
        caption: 'Peserta',
      },
      {
        dataField: 'klaimTypeId',
        caption: 'Jenis Klaim',
        lookup : {
          dataSource: klaimType(this.props.store),
          displayExpr: 'klaimTypeName',
          valueExpr: 'id',
        }
      },
      {
        dataField: 'keterangan',
        caption: 'Keterangan',
      },
      {
        dataField: 'verifikasiBy',
        caption: 'Verifikasi Oleh',
      },
      {
        dataField: 'verifikasiDate',
        caption: 'Tanggal Verifikasi',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right'
      },
      {
        dataField: 'verifikasiNote',
        caption: 'Catatan Verifikasi',
      },
      {
        dataField: 'updateBoBy',
        caption: 'Update BO Oleh',
      },
      {
        dataField: 'updateBoDate',
        caption: 'Tanggal Update BO',
        dataType: 'date',
        format: 'dd MMM yyyy',
        alignment: 'right'
      },
      {
        dataField: 'updateBoNote',
        caption: 'Catatan Update BO',
      },
      {
        dataField: 'statusId',
        caption: 'Status',
        lookup : {
            dataSource : [
                {
                    id: 1,
                    value : 'Klaim Baru'
                },
                {
                    id: 2,
                    value : 'Klaim Proses'
                },
                {
                  id: 4,
                  value : 'Klaim Ditolak'
                },
                {
                    id: 5,
                    value : 'Klaim Selesai'
                }
            ],
            valueExpr: 'id',
            displayExpr: 'value'
        }
      },
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          text: 'Klaim Manual',
          onClick: (e) => {
            // this.showModalGeneratePembayaran()
          },
        }
      }
    )
  }

  loadDummyData = () => {
    var dummyData = [
      {
        id: 1,
        tanggal: '17 Jun 2020',
        media: 'Offline',
        peserta: 'Pramadian Syahputra',
        jenisKlaim: null,
      }
    ]
    this.currentData = JSON.parse(JSON.stringify(dummyData))
    return dummyData
  }

  showModalDetail = (data) => {
    this.klaimDetailBaruRef.current.show()
    this.klaimDetailBaruRef.current.retrieveData(data)
  }
  showModalProses = (data) => {
    this.klaimProsesBaruRef.current.show()
    this.klaimDetailBaruRef.current.retrieveData(data)
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Riwayat Klaim MP</h2>
        <Form
          colCount={3}
          id={'formFilter'}
          formData={this.state.formFilter}
        // items={this.filterItem}
        />

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='view-klaim-mps?statusId.greaterThanOrEqual=3'
          insertAPI='peserta-klaims'
          updateAPI='peserta-klaims'
          deleteAPI='peserta-klaims'

          backendserver={process.env.REACT_APP_BACKEND_CORE}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Riwayat Klaim MP"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Riwayat Klaim MP'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={3} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
          // SummaryConfiguration={this.summary}

          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />

        <KlaimDetailBaru
          ref={this.klaimDetailBaruRef}
          store={this.props.store}
        />

        <KlaimProsesBaru
          ref={this.klaimProsesBaruRef}
          store={this.props.store}
          module = {'riwayat'}
        />

      </div>
    )
  }
}

export default KlaimMpRiwayat