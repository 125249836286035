import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { currency, banks, productDefault, fundManager, instrumentSubCategoryById, rekeningBank, kpds, depositoNeddConfirmByBank, depositoWasSent, instrumentSubCategoryDeposito, instrumentObligasi, instrumentTransactionTypes, needSettleSbnPenempatan, instruments } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import ScrollView from 'devextreme-react/scroll-view';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';


class ModalActionSettlementSbnBond extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataBank: {},
            actionType: null,
            rate: 0,
            couponLastDate: 0,
            couponNextDate: 0,
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.pphBb = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-B-Bond') : null
        this.feeCg = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-CG-Bond-DN') : null

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.formBankRef = React.createRef()
        this.getHoliday = []
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                itemType: 'group',
                caption: 'Transaksi',
                items: [
                    {
                        dataField: 'pasar',
                        label: {
                            alignment: 'left',
                            text: 'Pasar'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Primer'
                                },
                                {
                                    id: 2,
                                    value: 'Sekunder'
                                }
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                    },
                    {
                        dataField: 'orderCode',
                        label: {
                            alignment: 'left',
                            text: 'No Order'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'orderCode',
                            displayExpr: 'orderCode',
                            onSelectionChanged: async (e) => {
                                var data = e.selectedItem

                                if (data) {
                                    var dataOrderSbn = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-sbn-bond-sukuks/${data.orderObligasiId}`, 'GET')
                                    var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataOrderSbn.instrumentId}`, 'GET')
                                    var tanggalPenyelesaian = this.formRef.current.instance.getEditor('tanggalPenyelesaian').option('value')
                                    this.formRef.current.instance.getEditor('instrumentName').option('value', data.instrumentName)
                                    this.formRef.current.instance.getEditor('orderTypeId').option('value', dataOrderSbn.orderTypeId)
                                    this.formRef.current.instance.getEditor('faceValue').option('value', dataOrderSbn.faceValue)
                                    this.formRef.current.instance.getEditor('hargaPembelian').option('value', dataOrderSbn.price)
                                    this.formRef.current.instance.getEditor('currencyId').option('value', dataInstrument.currencyId)
                                    this.formBankRef.current.instance.getEditor('rekBankId').option('value', dataOrderSbn.rekBankId)
                                    this.formRef.current.instance.getEditor('bungaBerjalan').option('value', dataOrderSbn.accruedInterest)
                                    this.setState({
                                        instrumentId: dataOrderSbn.instrumentId,
                                        orderSbnId: data.orderObligasiId,
                                        couponLastDate: dataInstrument.couponLastDate,
                                        couponNextDate: dataInstrument.couponNextDate,
                                        rate: dataInstrument.rate,
                                    })
                                    this.hitungAccruedInterest(tanggalPenyelesaian, this.state.rate, this.state.couponLastDate, dataOrderSbn.faceValue, this.state.couponNextDate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'instrumentName',
                        label: {
                            alignment: 'left',
                            text: 'SBN/BOND/Sukuk'
                        },
                        // editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true
                            // dataSource: instrumentObligasi(this.props.store),
                            // valueExpr: 'id',
                            // displayExpr: 'instrumentName',
                        }
                    },
                    {
                        dataField: 'orderTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentTransactionTypes(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'transactionName',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'tanggalTransaksi',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'd MMM yyyy',
                            readOnly: true,
                            onValueChanged: async (e) => {
                                var today = new Date(e.value);
                                var tomorrow = new Date(today);
                                tomorrow.setDate(today.getDate() + 1);
                                tomorrow.toLocaleDateString();
                                if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                                    tomorrow.setDate(tomorrow.getDate() + 1);
                                    tomorrow.toLocaleDateString();
                                    if (tomorrow.getDay() == 0 || tomorrow.getDay() == 6) {
                                        tomorrow.setDate(tomorrow.getDate() + 1);
                                        tomorrow.toLocaleDateString();
                                    }
                                }
                                // var holidays = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'holidays ', 'GET')
                                // if (this.getHoliday.find(val => new Date(val.holidayDate) == tomorrow)) {
                                //     tomorrow.setDate(tomorrow.getDate() + 1);
                                //     tomorrow.toLocaleDateString();
                                // }
                                this.formRef.current.instance.updateData('tanggalPembayaran', formatDate(tomorrow))
                                this.formRef.current.instance.updateData('tanggalPenyelesaian', formatDate(tomorrow))
                            }
                        }
                    },
                    {
                        dataField: 'tanggalPembayaran',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'tanggalPenyelesaian',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Distribusi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            onValueChanged: (e) => {
                                var tanggalPenyelesaian = e.value
                                var fv = this.formRef.current.instance.getEditor('faceValue').option('value')
                                this.hitungAccruedInterest(tanggalPenyelesaian, this.state.rate, this.state.couponLastDate, fv, this.state.couponNextDate)
                            }
                        }
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            readOnly: true,
                            onSelectionChanged: (e) => {
                                if (e.selectedItem) {
                                    this.formRef.current.instance.updateData('kurs', e.selectedItem.rate)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            alignment: 'left',
                            text: 'Rate'
                        },
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            alignment: 'left',
                            text: 'Face Value'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true,
                            onValueChanged: (e) => {
                                var tanggalPenyelesaian = this.formRef.current.instance.getEditor('tanggalPenyelesaian').option('value')
                                var fv = e.value
                                this.hitungAccruedInterest(tanggalPenyelesaian, this.state.rate, this.state.couponLastDate, fv, this.state.couponNextDate)
                            }
                        }
                    },
                    {
                        dataField: 'hargaPembelian',
                        label: {
                            alignment: 'left',
                            text: 'Harga Pembelian (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                            step: 0,
                            readOnly: true,
                            onValueChanged: (e) => {
                                var fv = this.formRef.current.instance.getEditor('faceValue').option('value')
                                this.formRef.current.instance.updateData('proceed', fv * (e.value / 100))
                            }
                        }
                    },
                    {
                        dataField: 'proceed',
                        label: {
                            alignment: 'left',
                            text: 'Proceed'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true,
                            onValueChanged: (e) => {
                                var proceed = e.value
                                var bb = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var cg = this.formRef.current.instance.getEditor('capitalGain').option('value')

                                this.formRef.current.instance.updateData('netPayment', proceed + bb - cg)
                                
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        }
                    },
                    {
                        dataField: 'bungaBerjalan',
                        label: {
                            alignment: 'left',
                            text: 'Bunga Berjalan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bb = e.value
                                var cg = this.formRef.current.instance.getEditor('capitalGain').option('value')

                                this.formRef.current.instance.updateData('netPayment', proceed + bb - cg)
                                this.formRef.current.instance.updateData('pphBungaBerjalan', e.value * (this.pphBb.floatValue / 100))

                                var bungaBerjalan = e.value
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Bunga berjalan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pphBungaBerjalan',
                        label: {
                            alignment: 'left',
                            text: 'PPH Bunga Berjalan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = e.value
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'PPH Bunga berjalan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'capitalGain',
                        label: {
                            alignment: 'left',
                            text: 'Capital Gain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bb = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var cg = e.value

                                this.formRef.current.instance.updateData('netPayment', proceed + bb - cg)
                                this.formRef.current.instance.updateData('pajakCapitalGain', e.value * (this.feeCg.floatValue / 100))
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Capital Gain tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'pajakCapitalGain',
                        label: {
                            alignment: 'left',
                            text: 'Pajak Capital Gain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = e.value
                                var biayaLainnya = this.formRef.current.instance.getEditor('biayaLainnya').option('value')
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'PPH Capital Gain tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'komisi',
                        label: {
                            alignment: 'left',
                            text: 'Komisi'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Komisi tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'biayaLainnya',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Lainnya'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var proceed = this.formRef.current.instance.getEditor('proceed').option('value')
                                var bungaBerjalan = this.formRef.current.instance.getEditor('bungaBerjalan').option('value')
                                var komisi = this.formRef.current.instance.getEditor('komisi').option('value')
                                var pphBungaBerjalan = this.formRef.current.instance.getEditor('pphBungaBerjalan').option('value')
                                var pajakCapitalGain = this.formRef.current.instance.getEditor('pajakCapitalGain').option('value')
                                var biayaLainnya = e.value
                                this.formRef.current.instance.updateData('netProceed', proceed + bungaBerjalan + komisi + biayaLainnya - pphBungaBerjalan - pajakCapitalGain)
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Biaya lainnya tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'netProceed',
                        label: {
                            alignment: 'left',
                            text: 'Net Proceed'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'netPayment',
                        label: {
                            alignment: 'left',
                            text: 'Net Payment'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            readOnly: true
                        },
                    },
                ]
            },
        ]
        this.formBank = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'Bank',
                        items: [
                            {
                                dataField: 'rekBankId',
                                label: {
                                    alignment: 'left',
                                    text: 'Rekening Bayar (Transfer)'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    }
                                }
                            },
                            {
                                dataField: 'biayaBank',
                                label: {
                                    alignment: 'left',
                                    text: 'Biaya Bank'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00',
                                    step: 0,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Catatan',
                        items: [
                            {
                                dataField: "catatan",
                                label: {
                                    visible: false
                                },
                                editorType: "dxTextArea",
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'Dokumen',
                        items: [
                            {
                                dataField: "file",
                                label: {
                                    text: "File Konfirmasi",
                                    alignment: 'left'
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    },
                ]
            }
        ]
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    show = async (data) => {

        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                biayaLainnya: data.biayaLainnya,
                bungaBerjalan: data.bungaBerjalan,
                capitalGain: data.capitalGain,
                catatan: data.catatan,
                faceValue: data.faceValue,
                hargaPembelian: data.hargaPembelian,
                hargaPenjualan: data.hargaPenjualan,
                hargaPerolehan: data.hargaPerolehan,
                komisi: data.komisi,
                kurs: data.kurs,
                proceed: data.proceed,
                netProceed: data.netProceed,
                orderObligasiId: data.orderSbnId,
                pajakCapitalGain: data.pajakCapitalGain,
                pasar: data.pasar,
                pphBungaBerjalan: data.pphBungaBerjalan,
                tanggalPembayaran: data.tanggalPembayaran,
                tanggalPenyelesaian: data.tanggalPenyelesaian,
                tanggalTransaksi: data.tanggalTransaksi,
                orderCode: data.orderCode,
                orderTypeId: data.orderTypeId,
                institusiId: data.institusiId,
                institusiBroker: data.institusiBroker,
                productId: data.productId,
                instrumentName: data.instrumentName,
                currencyId: data.currencyId,
                categoryId: data.categoryId,
                netPayment: data.netPayment,
            },
            dataBank: {
                biayaBank: data.biayaBank,
                rekBankId: data.rekBankId,
            },
            fileKonfirmasi: data.fileKonfirmasi,
            fileKonfirmasiContentType: data.fileKonfirmasiContentType,
            instrumentId: data.instrumentId,
            orderSbnId: data.orderSbnId,
            rate: data.rate,
            couponLastDate: data.couponLastDate,
            couponNextDate: data.couponNextDate,
        })

        var selectOrderId = this.formRef.current.instance.getEditor('orderCode')
        selectOrderId.option('dataSource', needSettleSbnPenempatan(this.props.store, data.productId, data.institusiId, data.institusiBroker, data.categoryId))
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Konfirmasi`]: formattedFileData.base64data,
                    [`${fieldName}KonfirmasiContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }
    previewFile = async () => {
        var src = imageSource(this.state.fileKonfirmasiContentType, this.state.fileKonfirmasi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    hitungAccruedInterest = (tanggalPenyelesaian, rate, couponLastDate, faceValue, couponNextDate) => {
        var unit = faceValue / 1000000
        var settleDate = new Date(tanggalPenyelesaian)
        var lastDate = new Date(couponLastDate)
        var nextDate = new Date(couponNextDate)
        var diffAcrued = Math.abs(settleDate - lastDate)
        var accruedDays = Math.ceil(diffAcrued / (1000 * 60 * 60 * 24))
        var diffPeriod = Math.abs(nextDate - lastDate)
        var periodDays = Math.ceil(diffPeriod / (1000 * 60 * 60 * 24))

        var accruedPerUnit = (accruedDays / periodDays) * 1000000 * ((rate / 100) / 2)

        var result = accruedPerUnit * unit
        this.formRef.current.instance.getEditor('bungaBerjalan').option('value', (Math.ceil(result / 1000) * 1000))
    }
    submitData = () => {
        var data = this.state.dataMaster
        var dataBank = this.state.dataBank
        this.props.submitDetailPenempatanData({
            id: data.id,
            biayaBank: dataBank.biayaBank,
            biayaLainnya: data.biayaLainnya,
            bungaBerjalan: data.bungaBerjalan,
            capitalGain: data.capitalGain,
            catatan: data.catatan,
            faceValue: data.faceValue,
            fileKonfirmasi: this.state.fileKonfirmasi,
            fileKonfirmasiContentType: this.state.fileKonfirmasiContentType,
            hargaPembelian: data.hargaPembelian,
            hargaPenjualan: data.hargaPenjualan,
            hargaPerolehan: data.hargaPerolehan,
            instrumentId: this.state.instrumentId,
            komisi: data.komisi,
            kurs: data.kurs,
            proceed: data.proceed,
            netProceed: data.netProceed,
            orderSbnId: this.state.orderSbnId,
            rate: this.state.rate,
            couponLastDate: this.state.couponLastDate,
            couponNextDate: this.state.couponNextDate,
            pajakCapitalGain: data.pajakCapitalGain,
            pasar: data.pasar,
            pphBungaBerjalan: data.pphBungaBerjalan,
            tanggalPembayaran: data.tanggalPembayaran,
            tanggalPenyelesaian: data.tanggalPenyelesaian,
            tanggalTransaksi: data.tanggalTransaksi,
            orderCode: data.orderCode,
            orderTypeId: data.orderTypeId,
            institusiId: data.institusiId,
            institusiBroker: data.institusiBroker,
            productId: data.productId,
            instrumentName: data.instrumentName,
            currencyId: data.currencyId,
            rekBankId: dataBank.rekBankId,
            categoryId: data.categoryId,
            netPayment: data.netPayment,
        })

        this.hide()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Penempatan Obligasi'}
                minWidth={'60vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    ref={this.formRef}
                                    formData={this.state.dataMaster}
                                    items={this.items}
                                    labelLocation={"left"}
                                />
                            </div>
                            <div className="col-md-6">
                                <Form
                                    colCount={1}
                                    id={'formDataBank'}
                                    ref={this.formBankRef}
                                    formData={this.state.dataBank}
                                    items={this.formBank}
                                    labelLocation={"left"}
                                />
                                <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileKonfirmasi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Buka File
                                </button>
                            </div>
                            <ModalPreviewPdf
                                ref={this.modalPreviewPdfRef}
                                store={this.props.store}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalActionSettlementSbnBond