import React, { Component } from 'react'
import { Form, Button, DataGrid, FileUploader, CheckBox } from 'devextreme-react';
import Popup from 'devextreme-react/popup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify'

class ModalDetailTransaksiReksadana extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            formData: {},
            popupVisible: false,
        }

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'settleDate'
            },
            {
                name: 'quantity',
                column: 'quantity',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'amountNet',
                column: 'amountNet',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
        ]
        this.columns = [
            {
                dataField: 'miName',
                caption: 'Manajer Investasi',
                visible: this.props.type === 'ETF' ? false : true
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Reksadana',
            },
            {
                dataField: 'paymentDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Settle',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'quantity',
                caption: 'Unit',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'price',
                caption: 'NAB',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'amountNet',
                caption: 'Nilai Transaksi Bersih',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]
        this.formItem = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'No. Konfirmasi'
                },
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                },
            },
            {
                dataField: 'transactionName',
                label: {
                    alignment: 'left',
                    text: 'Tipe Transaksi'
                },
            },
        ]
        this.PopupRef = React.createRef()
        this.datagridRef = React.createRef()
        this.formRef = React.createRef()
        this.data = []
    }
    show = async (data) => {
        var response = []
        if(this.props.type === 'ETF'){
            response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/detail-konfirmasi-etf/${data.konfirmasiId}`, 'GET')
        }else{
            response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/detail-konfirmasi-reksadana/${data.konfirmasiId}`, 'GET')
        }
        if (response.length > 0) {
            this.setState({
                popupVisible: true,
                selectedTabIndex: 0,
                formData: {
                    noKonfirmasi: response[0].referensiNo,
                    transactionDate: response[0].transactionDate,
                    transactionName: response[0].transactionName,
                }
            });
            this.data = response
            this.forceRefresh()
        }else{
            notify({ message: 'Data Detail Transaksi tidak ditemukan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }
    hide = () => {
        this.setState({
            popupVisible: false,
        });
        this.data = []
    };
    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    };

    loadData = () => {
        return this.data
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={this.props.type === 'ETF' ? 'Data Detail Transaksi ETF' : 'Data Detail Transaksi Reksadana'}
                    width={'60vw'}
                    height={'60vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'form'}
                                        formData={this.state.formData}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation={'left'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className='col-md-12' style={{ paddingTop: '20px' }}>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}
                                        loadAPI=''
                                        insertAPI=''
                                        updateAPI=''
                                        deleteAPI=''

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.loadData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={false}
                                        exportFileName={this.props.type === 'ETF' ? "Data Detail Transaksi ETF" : 'Data Detail Transaksi Reksadana'}

                                        allowExportSelectedData={false}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={this.props.type === 'ETF' ? 'Data Detail Transaksi ETF' : 'Data Detail Transaksi Reksadana'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                        summaryTotalItem={this.summary}
                                        // SummaryConfiguration={this.summary}
                                        // onToolbarPreparing = {this.onToolbarPreparing}

                                        height={'calc(60vh - 246px)'}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalDetailTransaksiReksadana