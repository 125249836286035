import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { programSdm,statusPembayaranKasUmumSdm } from 'dataSource/lookup';
import ModalUmumPembayaranPengajuan from 'components/pages/modal/sdm-umum/umum/kas-umum/pengajuan-pembayaran/index';
import { alert, confirm } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';

class UmumPengajuanPembayaran extends Component {
    constructor(props) {
        super(props)

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.modalRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalEdit(e.row.data)
                        }
                    },
                    {
                        text : 'Kirim',
                        hint : 'Kirim',
                        cssClass: 'text-primary',
                        onClick : async(e) => {
                            var konfirm = await confirm('Apakah anda ingin mengirim pengajuan ini?' , 'Konfirmasi')
                            if(konfirm){
                                this.kirim(e.row.data)
                            }
                        }
                    },
                    {
                        text : 'Batal',
                        hint : 'Batal',
                        cssClass: 'text-primary',
                        onClick : async(e) => {
                            var konfirm = await confirm('Apakah anda ingin membatalkan pengajuan ini?' , 'Konfirmasi')
                            if(konfirm){
                                this.batal(e.row.data)
                            }
                        }
                    },
                ],
            },
            {
                dataField:"id",
                caption:"No.",
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'paymentNo',
                caption : 'No Pembayaran'
            },
            {
                dataField:"programId",
                caption:"Program",
                lookup:{
                    dataSource: programSdm(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            // {
            //     dataField:"paymentDate",
            //     caption:"Tanggal Pembayaran",
            //     dataType: 'date',
            //     format: 'dd MMM yyyy'
            // },
            {
                dataField:"transactioDate",
                caption:"Tanggal Transaksi",
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:"paymentNominalNett",
                caption:"Total Nominal",
                dataType: 'number',
                alignment : 'right',
                format: '#,##0.00'
            },
            {
                dataField:"statusId",
                caption:"Status",
                alignment : 'center',
                lookup:{
                    dataSource: statusPembayaranKasUmumSdm(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
        ]
    }
    
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    
    showModal = (data) => {
        this.modalRef.current.getUnitUserLogin()
        this.modalRef.current.show()
        this.modalRef.current.getKasUmumByUserLogin()
    }

    showModalEdit = (data) => {
        this.modalEditRef.current.retrieveData(data)
        this.modalEditRef.current.show()
        this.modalEditRef.current.getKasUmumByUserLogin()
        // this.modalEditRef.current.getUnitUserLogin()
    }

    batal = async(data) => {
        try {
            var loadAPIMaster = 'kas-umum-payments/' + data.id 
            var getMaster = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPIMaster, 'GET')
            
            var updateMaster = getMaster
            updateMaster.statusId = 3
            var updateAPIMaster = 'kas-umum-payments' 
            var updateMaster = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, updateAPIMaster, 'PUT', {
                key : getMaster.id,
                values : updateMaster
            }, getMaster)
    
            var loadAPIDetil = 'kas-umum-payment-details/getByKasUmumPaymentId/' + data.id 
            var getDetil = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPIDetil, 'POST')
            for(var detil of getDetil){
                var loadAPIKbt = 'kas-bank-transactions'
                var getKbt = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPIKbt+'/'+detil.kasBankTransactionId, 'GET')
                var paramKbt = getKbt
                paramKbt.statusId = 13
    
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPIKbt, 'PUT', {
                    key : paramKbt.id,
                    values : paramKbt
                }, getKbt)
            }
        } catch (e) {
            console.log(e)
            // var title = 'Gagal!'
            // var text = e
        }
        var title = 'Berhasil!'
        var text = 'Data Berhasil Dibatalkan!'

        await alert(text, title)
        this.forceRefresh()
    }

    kirim = async(data) => {
        // var loadAPI = 'kas-umum-payments/' + data.id 
        // var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadAPI, 'GET')
        try {
        //     var insertAPI = 'kas-umum-payments-kirim' 
        //     await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, insertAPI, 'POST', {
        //             values:getData
        //     })
            
            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umum-payments-kirim/${data.id}/${data.lastModifiedBy}`, 'GET');
            
            if (response.statusCode === 200) {
                // var title = `Berhasil! - ${response.transactionNo}`;
                var title = `Berhasil!`;
                var text = response.statusDescription;
            } else if (response.statusCode === 500) {
                // var title = `Gagal - ${response.transactionNo}`;
                var title = `Gagal`;
                var text = response.statusDescription;
            }
        } catch(e) {
            console.log(e)
            var title = 'Gagal!'
            var text = e
        }
        await alert(text, title)
        this.forceRefresh()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal()
                    },
                }
            }
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pengajuan Pembayaran Kas Umum</h3>
                <div id="tabindex-0" className={'mt-2 text-center'}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI='kas-umum-payments/getByMultipleStatusId/1?size=999'
                        insertAPI='kas-umum-payments'
                        updateAPI='kas-umum-payments'
                        deleteAPI='kas-umum-payments'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        // useArraySource={true}
                        // ArraySourceData={this.loadDummyData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Pengajuan Pembayaran Kas Umum"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Pengajuan Pembayaran Kas Umum'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        onToolbarPreparing={this.onToolbarPreparing}
                        // SummaryConfiguration={this.summary}

                        height={'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ModalUmumPembayaranPengajuan
                        ref={this.modalRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        action={'add'}
                    />
                    <ModalUmumPembayaranPengajuan
                        ref={this.modalEditRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        action={'edit'}
                    />
                    {/* <ModalUmumEditPembayaranPengajuan
                        ref={this.modalEditRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    /> */}
                </div>
            </div>
        )
    }
}

export default UmumPengajuanPembayaran