import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import { formatDate } from 'plugin/helper'
import { product } from 'dataSource/lookup'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import FormModalGenerateAmortisasi from 'components/pages/modal/investasi/settlement/generate-transaksi/transaksi-amortisasi/index'
import ModalGenerateJadwalAmortisasi from 'components/pages/modal/investasi/settlement/generate-transaksi/transaksi-amortisasi/generate'
import { confirmAlert } from 'react-confirm-alert'
import FormModalDetailAmortisasi from 'components/pages/modal/investasi/settlement/generate-transaksi/transaksi-amortisasi/detail'
class TransaksiAmortisasi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            formFilterDate: {
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
            filterJadwal: {
                statusJadwal: 'TERJADWAL'
            },
        }

        this.prevTabIndex = 0
        this.showModalRef = React.createRef()
        this.modalGenerateAmortisasiRef = React.createRef()
        this.modalJadwalRef = React.createRef()
        this.modalGenerateJadwalRef = React.createRef()
        this.dataGridBiayaRef = React.createRef()
        this.dataGridRiwayatRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'startDate',
                label: {
                    text: "Tanggal Awal"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true
                },
            },
            {
                dataField: 'endDate',
                label: {
                    text: "s/d"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true
                },
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
        this.filterItemJadwal = [
            {
                dataField: 'statusJadwal',
                label: {
                    text: "Status Jadwal"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'TERJADWAL',
                            value: 'Sudah Terjadwal',
                        },
                        {
                            id: 'BELUM',
                            value: 'Belum Terjadwal',
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterJadwalSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
        this.columns = [
            {
                dataField: 'tahun',
                caption: 'Tahun',
            },
            {
                dataField: 'bulan',
                caption: 'Bulan',
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelola'
            },
            {
                dataField: 'obligasi',
                caption: 'Obligasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'kodeInstrument',
                        caption: 'Kode',
                    },
                    {
                        dataField: 'namaInstrument',
                        caption: 'Nama Obligasi',
                    },
                    {
                        dataField: 'maturityDate',
                        caption: 'Maturity',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'rate',
                        caption: 'Rate',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'perolehan',
                caption: 'Perolehan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tglPerolehan',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'faceValue',
                        caption: 'Face Value',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'hargaPerolehan',
                        caption: 'Harga',
                        dataType: 'number',
                        format: '#,##0.0000',
                        alignment: 'right',
                    },
                    {
                        dataField: 'nominal',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'yield',
                        caption: 'Yield',
                    },
                ]
            },
            {
                dataField: 'amortisasi',
                caption: 'Amortisasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'penerimaan',
                        caption: 'Penerimaan',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'pendapatan',
                        caption: 'Pendapatan',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'amortisasi',
                        caption: 'Amortisasi',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'nilaiBuku',
                        caption: 'Nilai Buku',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                ]
            },
        ]
        this.columnJadwal = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Generate',
                        onClick: (e) => {
                            this.modalGenerateJadwal(e.row.data)
                        }
                    },
                    {
                        text: 'Lihat',
                        onClick: (e) => {
                            this.modalJadwalAmortisasi(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'produk',
                caption: 'Produk',
            },
            {
                dataField: 'pengelola',
                caption: 'Pengelola'
            },
            {
                dataField: 'obligasi',
                caption: 'Obligasi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'kodeInstrument',
                        caption: 'Kode',
                    },
                    {
                        dataField: 'namaInstrument',
                        caption: 'Nama Obligasi',
                    },
                    {
                        dataField: 'maturityDate',
                        caption: 'Maturity',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'rate',
                        caption: 'Rate',
                        dataType: 'number',
                        format: '#,##0.0000'
                    },
                ]
            },
            {
                dataField: 'perolehan',
                caption: 'Perolehan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tglPerolehan',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'faceValue',
                        caption: 'Face Value',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'hargaPerolehan',
                        caption: 'Harga',
                        dataType: 'number',
                        format: '#,##0.0000',
                        alignment: 'right',
                    },
                    {
                        dataField: 'nominal',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'yield',
                        caption: 'Yield',
                    },
                ]
            },
            {
                dataField: 'kupon',
                caption: 'Kupon',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'kuponLastDate',
                        caption: 'Last Date',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'kuponNextDate',
                        caption: 'Next Date',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'amortisasi',
                        caption: 'Amortisasi',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'nilaiBuku',
                        caption: 'Nilai Buku',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                ]
            },
            {
                dataField: 'statusJadwal',
                caption: 'Status Jadwal'
            },
        ]
        this.TabDataSource = [
            {
                id: 0,
                text: 'Daftar Transaksi Amortisasi'
            },
            {
                id: 1,
                text: 'Jadwal Amortisasi'
            },
        ]
        this.month = []
        this.month[1] = 'Januari'
        this.month[2] = 'Februari'
        this.month[3] = 'Maret'
        this.month[4] = 'April'
        this.month[5] = 'Mei'
        this.month[6] = 'Juni'
        this.month[7] = 'Juli'
        this.month[8] = 'Agustus'
        this.month[9] = 'September'
        this.month[10] = 'Oktober'
        this.month[11] = 'November'
        this.month[12] = 'Desember'
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    modalGenerateAmortisasi = (e) => {
        this.modalGenerateAmortisasiRef.current.show()
    }
    modalGenerateJadwal = async (data) => {
        if (data.statusJadwal == 'TERJADWAL') {
            confirmAlert({
                message: 'Data ini sudah terjadwal',
                title: 'Notifikasi',
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
        } else {
            var balanceBuyDate = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `efek-balance-buy-dates/${data.id}`,
                'GET'
            )
            var instrument = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `instruments/${balanceBuyDate.instrumentId}`,
                'GET'
            )
            var getData = {
                balanceBuyDateId: data.id,
                couponPeriod: instrument.couponPeriod,
                dayBase: instrument.dayBase,
                faceValue: balanceBuyDate.faceValue,
                hargaPembelian: balanceBuyDate.buyPrice,
                instrumentId: balanceBuyDate.instrumentId,
                interestKupon: instrument.rate,
                kpdId: balanceBuyDate.kpdId,
                lastCouponDate: instrument.couponLastDate,
                nextCouponDate: instrument.couponNextDate,
                produkId: balanceBuyDate.productId,
                tglMaturity: instrument.maturityDate,
                tglPembelian: balanceBuyDate.buyDate,
                namaInstrument: data.namaInstrument,
                yield: 0
            }
            this.modalGenerateJadwalRef.current.show(getData)
        }
    }
    modalJadwalAmortisasi = async(data) => {
        var result = []
        var balanceBuyDate = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `efek-balance-buy-dates/${data.id}`,
            'GET'
        )
        var instrument = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `instruments/${balanceBuyDate.instrumentId}`,
            'GET'
        )
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `efek-amortisasi-jadwals/get-by-efekBalanceBuyDateId/${data.id}`,
            'GET'
        )
        var formData = {
            productId: response[0].productId,
            kpdId: response[0].kpdId,
            instruCode: instrument.instrumentCode,
            instruName: instrument.instrumentName,
            buyPrice: response[0].buyPrice,
            buyDate: response[0].buyDate,
            rate: response[0].interest,
            buyValue: response[0].buyValue,
            maturityDate: instrument.maturityDate,
            faceValue: response[0].faceValue,
            couponPeriod: instrument.couponPeriod.replace('_', ' '),
            dayBase: instrument.dayBase,
        }
        for(var dataAmort of response){
            var getData = {
                id: dataAmort.id,
                periode: this.month[dataAmort.bulan] + ' ' + dataAmort.tahun,
                tanggal: dataAmort.tglAmortisasi,
                penerimaan: dataAmort.nominalPenerimaan,
                pendapatan: dataAmort.nominalPendapatan,
                amortisasi: dataAmort.nominalAmortisasi,
                bookValue: dataAmort.nilaiBuku,
            }
            result.push(getData)
        }
        this.modalJadwalRef.current.show(formData, result)
    }
    forceRefresh = () => {
        this.dataGridBiayaRef.current.forceRefresh()
        this.dataGridRiwayatRef.current.forceRefresh()
    }

    filterSubmit = () => {
        this.setState({
            formFilterDate: this.state.formFilterDate
        })

        this.dataGridBiayaRef.current.forceRefresh(true)
    }
    filterJadwalSubmit = () => {
        this.setState({
            filterJadwal: this.state.filterJadwal
        })

        this.dataGridRiwayatRef.current.forceRefresh(true)
    }
    loadDataJadwal = async() => {
        let data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-amortisasi-jadwals/jadwal-amortisasi`, 'GET')
        data = data.filter(value => value.statusJadwal === this.state.filterJadwal.statusJadwal)
        return data
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Generate Amortisasi',
                    onClick: (e) => {
                        this.modalGenerateAmortisasi(e)
                    },
                }
            }
        );
    }
    render() {
        return (
            <div className="container-fluid">
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="main-title">Daftar Transaksi Amortisasi</h2>
                            <Form
                                colCount={4}
                                id={'dataMaster'}
                                formData={this.state.formFilterDate}
                                items={this.filterItem}
                                labelLocation={"left"}
                            />
                            <DevExpressDataGrid
                                ref={this.dataGridBiayaRef}
                                loadAPI={`efek-amortisasi-jadwals/transaksi-amortisasi/${formatDate(this.state.formFilterDate.startDate)}/${formatDate(this.state.formFilterDate.endDate)}`}
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Daftar Transaksi Amortisasi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Tagihan Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}
                                onToolbarPreparing={this.onToolbarPreparing}

                                // remoteOperations = {true}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <FormModalGenerateAmortisasi
                                ref={this.modalGenerateAmortisasiRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                            />
                        </div>
                    </div>
                </div>
                <div id="tabindex-1" className="d-none">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="main-title">Jadwal Amortisasi</h2>
                            <Form
                                colCount={4}
                                id={'dataMaster'}
                                formData={this.state.filterJadwal}
                                items={this.filterItemJadwal}
                                labelLocation={"left"}
                            />
                            <DevExpressDataGrid
                                ref={this.dataGridRiwayatRef}
                                loadAPI={`efek-amortisasi-jadwals/jadwal-amortisasi`}
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                useArraySource={true}
                                ArraySourceData={this.loadDataJadwal}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Jadwal Amortisasi"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Riwayat Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnJadwal} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                // remoteOperations = {true}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ModalGenerateJadwalAmortisasi
                                ref={this.modalGenerateJadwalRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                            />
                            <FormModalDetailAmortisasi 
                                ref={this.modalJadwalRef}
                                store={this.props.store}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TransaksiAmortisasi