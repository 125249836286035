import React, { Component } from 'react'
import Form from 'devextreme-react/form'
import { formatDate} from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class FormDataResume extends Component {
    constructor(props) {
        
        super(props)

        this.state = {
            pesertaId: 0,
            formData: {}
        }

        this.fileId = this.props.data.id

        this.formRef = React.createRef()

        this.items = [
            {
                dataField: 'prosesInsertDbDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Proses'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%'
                }
            },
            {
                dataField: 'jmlRecord',
                label: {
                    alignment: 'left',
                    text: 'Jumlah Record'
                },
            },
            {
                dataField: 'jmlRegistAktif',
                label: {
                    alignment: 'left',
                    text: 'Jumlah Registrasi Aktif'
                },
            },
            {
                dataField: 'jmlPengkinianData',
                label: {
                    alignment: 'left',
                    text: 'Jumlah Pengkinian Data'
                },
            },
            {
                dataField: 'jmlRegistPensiun',
                label: {
                    alignment: 'left',
                    text: 'Jumlah Registrasi Pensiun'
                },
            },
            {
                dataField: 'pesertaPpmp',
                label: {
                    alignment: 'left',
                    text: 'Peserta PPMP'
                },
            },
            {
                dataField: 'pesertaPpip',
                label: {
                    alignment: 'left',
                    text: 'Peserta PPIP'
                },
            },
        ]
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formDataResume'}
                            formData={this.props.data}
                            items={this.items}
                            // scrollingEnabled={true}
                            labelLocation='left'
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default FormDataResume
