import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { product } from 'dataSource/lookup';
import TransaksiMpGenerateSPTModal from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/spt/generate';
import TransaksiMpGenerateSPTDetail from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/spt/detail';

const Bulan = [
    {
        id : 1,
        namaBulan: 'Januari'
    },
    {
        id : 2,
        namaBulan: 'Februari'
    },
    {
        id : 3,
        namaBulan: 'Maret'
    },
    {
        id : 4,
        namaBulan: 'April'
    },
    {
        id : 5,
        namaBulan: 'Mei'
    },
    {
        id : 6,
        namaBulan: 'Juni'
    },
    {
        id : 7,
        namaBulan: 'Juli'
    },
    {
        id : 8,
        namaBulan: 'Agustus'
    },
    {
        id : 9,
        namaBulan: 'September'
    },
    {
        id : 10,
        namaBulan: 'Oktober'
    },
    {
        id : 11,
        namaBulan: 'November'
    },
    {
        id : 12,
        namaBulan: 'Desember'
    },
]
class TransaksiMpGenerateSPT extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {}
        }

        this.dataGridRef = React.createRef()
        this.generateDetailSptRef = React.createRef()
        this.generateSptRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '20%',
                    onValueChanged: (data) => {
                        this.forceRefresh()
                    }
                },
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    // {
                    //     text: 'Kirim',
                    //     hint : 'Kirim',
                    //     cssClass: 'text-info',
                    //     onClick : (e) => {
                    //         console.log(e)
                    //     }
                    // },
                    {
                        text: 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'NO',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'tahun',
                caption: 'Tahun SPT',
            },
            {
                dataField: 'periodeDari',
                caption: 'Periode Dari',
                cellRender : (e) => {
                    var data = e.row.data
                    var bulan = Bulan.find(val => val.id == data.bulanDari)

                    if(bulan){
                        return bulan.namaBulan + ' ' + data.tahunDari
                    }else{
                        return '-'
                    }
                }
            },
            {
                dataField: 'periode Sampai',
                caption: 'Periode Sampai',
                cellRender : (e) => {
                    var data = e.row.data
                    var bulan = Bulan.find(val => val.id == data.bulanSampai)

                    if(bulan){
                        return bulan.namaBulan + ' ' + data.tahunSampai
                    }else{
                        return '-'
                    }
                }
            },
            {
                dataField: 'tanggalPotongan',
                caption: 'Tanggal Potongan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'jumlahPeserta',
                caption: 'Jumlah Peserta',
                alignment: 'right',
                format: "#,##0",
            },
            {
                dataField: 'jumlahMp',
                caption: 'Jumlah MP',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'jumlahPph21',
                caption: 'PPH 21 Terhutang',
                alignment: 'right',
                format: "#,##0.00",
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                produk : 'produk test',
                bulan : 'Januari',
                tahun : 2020,
                totalPensiunan : 3000000,
                totalPembayaran : 3000000,
                noSkPembayaran : 2501,
                tanggalPembayaran : '2020-07-16',
                bankBayar : 'BANK BCA',
                totalBiayaBank : 2500000,
                totalFeeBank : 3000000,
                retur : 500000,
                arApStatus : 'aktif',
            }
        ]
        this.currentData = JSON.parse(JSON.stringify(dummyData))
        return dummyData
    }

    showModalGeneratePembayaran = () => {
        this.generateSptRef.current.show()
    }

    showModalDetail = (data) => {
        this.generateDetailSptRef.current.show()
        this.generateDetailSptRef.current.retrieveData(data)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Generate Pembayaran',
                    onClick: (e) => {
                        this.showModalGeneratePembayaran()
                    },
                }
            }
        )
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Generate SPT</h2>
                <Form
                    colCount={1}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                    className = {'mb-3'}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='pph-21-s?size=9999'
                    insertAPI='pph-21-s'
                    updateAPI='pph-21-s'
                    deleteAPI='pph-21-s' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {false}
                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Generate SPT"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Generate SPT'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <TransaksiMpGenerateSPTDetail 
                    ref={this.generateDetailSptRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
                <TransaksiMpGenerateSPTModal 
                    ref={this.generateSptRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                    product = {this.state.formFilter}
                />
            </div>
        )
    }
}

export default TransaksiMpGenerateSPT