import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { reksadana, emitens, currency, instrumentReksadana, managerInvestasis, aperds, instrumentTransactionTypes } from 'dataSource/lookup';

class DetailPenempatanReksadana extends Component {
    constructor(props){
        super(props)
        this.dataSource  = new CustomStore({
            load: async(loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `order-requests-rd-dtls/${requestId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                return response.orderRdInfos
            },
            key: 'orderDTO.orderRequestId',
            insert: (values) => {
            },
            update: (key,values) => {
               
            },
            remove: (key) => {
               
            }
        })

        this.columns = [
            {
                dataField: 'orderDTO.instrumentId',
                caption: 'Instrumen Code',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentCode'
                }
            },
            {
                dataField: 'orderDTO.instrumentId',
                caption: 'Instrumen Name',
                lookup: {
                    dataSource: instrumentReksadana(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'instrumentName'
                }
            },
            {
                dataField: 'orderRdDTO.miId',
                caption: 'Manager Investasi Code',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miCode'
                }
            },
            {
                dataField: 'orderRdDTO.miId',
                caption: 'Manager Investasi Name',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'orderRdDTO.aperdId',
                caption: 'Aperd Code',
                lookup: {
                    dataSource: aperds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'aperdCode'
                }
            },
            {
                dataField: 'orderRdDTO.aperdId',
                caption: 'Aperd Name',
                lookup: {
                    dataSource: aperds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'aperdName'
                }
            },
            {
                dataField: 'orderRdDTO.amountOrder',
                caption:'Nilai Investasi',
                format: '#,##0',
                alignment: 'right',
                editorType : 'dxNumberBox',
                editorOptions: {
                    format: "#,##0"
                },
            },
            {
                dataField: 'orderRdDTO.orderTypeId',
                caption: 'Transaction Name',
                lookup: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName'
                }
            },
        ]
    }

    render() {
        return (
            <DataGrid
            id={"successDataGrid"}
            showBorders={false}
            dataSource={this.dataSource}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            columnMinWidth={50}
            // height={100}
            showScrollbar={"always"}
            // columns={this.columns}
            >
            {
                this.columns.map((column) => {
                    return <Column 
                        dataField={column.dataField} 
                        caption={column.caption} 
                        lookup={column.lookup} 
                        cellRender={column.cellRender} 
                        alignment={column.alignment || 'left'} 
                        cssClass={column.cssClass}
                        format = {column.format}
                        dataType = {column.dataType}
                        width = {column.width}
                        type = {column.type}
                        buttons = {column.buttons}
                        editorType = {column.editorType}
                        editorOptions = {column.editorOptions}
                        visible = {column.visible}
                        sortOrder = {column.sortOrder}
                        allowEditing = {column.allowEditing}
                        calculateCellValue = {column.calculateCellValue}
                    />
                })
            }
            {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPenempatanReksadana