import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { formatUploadFileData } from 'plugin/helper'
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";

class ModalPertimbanganTransaksiPenyertaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: console.log('simpan')
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
            },
            {
                dataField: 'kode',
                label: {
                    alignment: 'left',
                    text: 'Kode'
                },
            },
            {
                dataField: 'tanggal',
                label: {
                    alignment: 'left',
                    text: 'Tanggal'
                },
            },
            {
                dataField: 'intsrumen',
                label: {
                    alignment: 'left',
                    text: 'Instrumen'
                },
            },
            {
                dataField: 'transaksi',
                label: {
                    alignment: 'left',
                    text: 'Transaksi'
                },
            },
            {
                dataField: 'efek',
                label: {
                    alignment: 'left',
                    text: 'Efek'
                },
            },
        ]
        this.toolbarItem = [
            'background' , 'bold' , 'color' , 'italic' , 'link' , 'image' , 'strike' , 'subscript' , 'superscript' , 'underline' , 'blockquote' , 'header' 
            , 'increaseIndent' , 'decreaseIndent' , 'orderedList' , 'bulletList' , 'alignLeft' , 'alignCenter' , 'alignRight' , 'alignJustify' , 'codeBlock' 
            , 'variable' , 'separator' , 'undo' , 'redo' , 'clear'
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data = null) => {    
        this.setState({
            dataMaster: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Alur Dokumen Investasi'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <HtmlEditor 
                                height="300px"
                                value={this.state.content}
                                name={'content'}
                                onValueChanged={this.onValueChanged}
                            >
                                <Toolbar>
                                    {    
                                        this.toolbarItem.map((toolbarItem) => {
                                            return(
                                                <Item
                                                    formatName = {toolbarItem}
                                                />
                                            )
                                        })
                                    }
                                </Toolbar>
                            </HtmlEditor>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalPertimbanganTransaksiPenyertaan