import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import notify from "devextreme/ui/notify";
import httpRequest from "plugin/httprequest";
import { currency, settleWith, entityType, arapReffType, arapType, banks, entity, coa } from "dataSource/lookup";
import DevExpressDataGrid from "components/inheritComponent/devexpressdatagrid";
import DataSource from "devextreme/data/data_source";
import { showLoading } from "redux/actions/loading";
import { formatDate, imageSource } from 'plugin/helper'
import { alert } from 'devextreme/ui/dialog';
import ModalRejectOutstanding from "./rejectOutstanding";
import ModalPreviewPdf from 'components/pages/modal/akutansi/cash-settlement/modalPreviewFile';

class ModalCashSettlement extends Component {
    constructor(props) {
        super(props);
        this.labaRugiAkunId = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'LB-AKUNID') : null
        this.pembulatanAkunId = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'P-AKUNID') : null
        this.getUserLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null
        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            popupVisible: false,
            formData: {
                pphNominal: 0,
                coaPphAkunId: 0
            },
            amount: 0
        };

        this.dataArap = []
        this.companyBank = []
        this.counterBank = []
        this.selectedRowDatas = []
        this.dataOutstandingHr = {}
        this.dataOutstandingIn = {}
        this.dataOutstandingKp = {}
        this.dataEntitiId = 0

        this.clientBankDataSource = new DataSource({
            store: {
                data: [],
                type: 'array',
                key: 'id'
            }
        });

        this.formItem = [
            {
                itemType: 'group',
                name: 'entityInformation',
                caption: 'Informasi Entitas',
                colCount: 2,
                colSpan: 2,
                items: [
                    {
                        dataField: 'entityName',
                        label: {
                            alignment: 'left',
                            text: 'Nama'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'type',
                        label: {
                            alignment: 'left',
                            text: 'Tipe Entitas'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: entityType(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'cashSettlement',
                caption: 'Cash Settlement',
                colSpan: 2,
                colCount: 3,
                items: [
                    {
                        dataField: 'voucherNo',
                        label: {
                            alignment: 'left',
                            text: 'Kode Voucher'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'entryDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Entri'
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'valueDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Jurnal'
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy',
                            disabledDates: (args) => {
                                const systemDate = new Date(this.systemDate.dateValue);
                                const day = args.date.getDay();
                                const date = args.date.getDate();
                                
                                // Disable dates before two working days from systemDate
                                const twoWorkingDaysBefore = this.getTwoWorkingDaysBefore(systemDate);
                                if (args.date < twoWorkingDaysBefore) {
                                    return true;
                                }
                                return false;
                            },
                            // min: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                        },
                        isRequired: true
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            alignment: 'left',
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode',
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        isRequired: true
                    },
                    {
                        dataField: 'amount',
                        label: {
                            alignment: 'left',
                            text: 'Total'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00;(#,##0.00);',
                            readOnly: true
                        },
                        validationRules: [
                            {
                                type: 'custom',
                                validationCallback: (e) => {
                                    return e.value !== 0
                                },
                                message: 'Nominal yang dibayarkan tidak boleh 0!'
                            }
                        ]
                    },
                    {
                        dataField: 'settleWith',
                        label: {
                            alignment: 'left',
                            text: 'Penyelesain Dengan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: settleWith(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (e) => {
                                this.formRef.current.instance.beginUpdate()
                                if (e.value === 'B') {
                                    this.formRef.current.instance.itemOption('cashSettlement.companyBankId', 'visible', true)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankId', 'visible', true)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankNo', 'visible', true)
                                    this.formRef.current.instance.itemOption('cashSettlement.transferFee', 'visible', true)
                                } else {
                                    this.formRef.current.instance.itemOption('cashSettlement.companyBankId', 'visible', false)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankId', 'visible', false)
                                    this.formRef.current.instance.itemOption('cashSettlement.clientBankNo', 'visible', false)
                                    this.formRef.current.instance.itemOption('cashSettlement.transferFee', 'visible', false)
                                }


                                this.formRef.current.instance.endUpdate()
                                if (e.value === 'B') {
                                    var companyBankEditor = this.formRef.current.instance.getEditor('companyBankId')
                                    companyBankEditor.option('dataSource', this.companyBank)

                                    let dataDatagrid = this.dataGridRef.current.getDataSource()

                                    dataDatagrid = dataDatagrid.find(value => value.amountPaid > 0)

                                    if (dataDatagrid) {
                                        this.formRef.current.instance.updateData({
                                            companyBankId: dataDatagrid.companyBankId
                                        })
                                    }
                                }
                            }
                        },
                        isRequired: true
                    },
                    {
                        dataField: 'companyBankId',
                        label: {
                            alignment: 'left',
                            text: 'Bank Perusahaan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        visible: false,
                        isRequired: true
                    },
                    {
                        dataField: 'clientBankId',
                        label: {
                            alignment: 'left',
                            text: 'Kode Bank Lawan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.initialName + ' - ' + item.bankCode
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        },
                        visible: false,
                    },
                    {
                        dataField: 'clientBankNo',
                        label: {
                            alignment: 'left',
                            text: 'No.Rek Bank Lawan'
                        },
                        editorType: 'dxTextBox',
                        validationRules: [
                            {
                                type: 'numeric',
                                message: 'Nomor rekening tidak valid'
                            }
                        ],
                        visible: false,
                    },
                    {
                        dataField: 'description',
                        label: {
                            alignment: 'left',
                            text: 'Deskripsi'
                        },
                        colSpan: 2,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Deskripsi is Required'
                            },
                            {
                                type: 'stringLength',
                                max: 200,
                                message: 'Description length cannot more than 200 character'
                            }
                        ],
                    },
                    {
                        dataField: 'transferFee',
                        label: {
                            alignment: 'left',
                            text: 'Biaya Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var sp = this.formRef.current.instance.getEditor('pembulatanNominal').option('value')
                                // var amount = this.formRef.current.instance.getEditor('amount').option('value')
                                var amount = this.state.amount
                                var pn = this.formRef.current.instance.getEditor('pphNominal').option('value')
                                var tf = e.value

                                this.formRef.current.instance.getEditor('amount').option('value', amount + sp + pn - tf)

                            }
                        },
                        visible: false,
                        isRequired: true,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Biaya Transfer is Required'
                            },
                            {
                                type: "range",
                                message: "Biaya Transfer Tidak Boleh Minus!",
                                min: 0,
                            }
                        ],
                    },
                    {
                        dataField: 'coaPphAkunId',
                        label: {
                            alignment: 'left',
                            text: 'COA Pemotong'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: coa(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.coaCode + ' - ' + item.coaName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: (e) => {
                                // if (e.value) {
                                //     this.formRef.current.instance.itemOption('cashSettlement.pphNominal', 'isRequired', true)
                                // } else {
                                //     this.formRef.current.instance.itemOption('cashSettlement.pphNominal', 'isRequired', false)
                                // }
                            }
                        }
                    },
                    {
                        dataField: 'pphNominal',
                        label: {
                            alignment: 'left',
                            text: 'COA Nilai'
                        },
                        editorType: 'dxNumberBox',
                        isRequired: true,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'COA Nilai is Required'
                            },
                        ],
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var sp = this.formRef.current.instance.getEditor('pembulatanNominal').option('value')
                                // var amount = this.formRef.current.instance.getEditor('amount').option('value')
                                var amount = this.state.amount
                                var pn = e.value

                                if (this.formRef.current.instance.getEditor('transferFee')) {
                                    var tf = this.formRef.current.instance.getEditor('transferFee').option('value')

                                    this.formRef.current.instance.getEditor('amount').option('value', amount + sp + pn - tf)
                                } else {

                                    this.formRef.current.instance.getEditor('amount').option('value', amount + sp + pn)
                                }

                            }
                        },
                    },
                    {
                        dataField: 'pembulatanNominal',
                        label: {
                            alignment: 'left',
                            text: 'Selisih Pembulatan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            onValueChanged: (e) => {
                                var sp = e.value
                                // var amount = this.formRef.current.instance.getEditor('amount').option('value')
                                var amount = this.state.amount
                                var pn = this.formRef.current.instance.getEditor('pphNominal').option('value')

                                if (this.formRef.current.instance.getEditor('transferFee')) {
                                    var tf = this.formRef.current.instance.getEditor('transferFee').option('value')

                                    this.formRef.current.instance.getEditor('amount').option('value', amount + sp + pn - tf)
                                } else {

                                    this.formRef.current.instance.getEditor('amount').option('value', amount + sp + pn)
                                }

                            }
                        },
                    },

                ]
            },
        ];

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text: 'Detail Transaksi',
                    //     hint: 'Detail Transaksi',
                    //     icon: "search",
                    //     onClick: (e) => {
                    //         // this.showModalDetail(e.row.data)
                    //     }
                    // },
                    {
                        text: 'File',
                        hint: 'File',
                        icon: "doc",
                        onClick: async(e) => {
                            let data = e.row.data;
                            let kpReffno = btoa(data.reffNo)

                            if (data.reffType === 'IN' || data.reffType === 'INV') {
                                let loadAPI = `view-get-file-investasis?reffNo.equals=${data.reffNo}`;
                                let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');

                                if (response[0].fileInvestasi !== null && response[0].fileInvestasiContentType !== null) {
                                    this.previewFile(response[0].fileInvestasiContentType, response[0].fileInvestasi);
                                } else {
                                    notify({ message: 'Tidak ada file yang di upload', alignment: 'center', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'warning', 1500);
                                } 
                            } else if (data.reffType === 'KP') {
                                // let loadAPI = `transaction-confirmations/fetch-file-kps/${data.reffNo}`;
                                let loadAPI = `transaction-confirmations/fetch-file-kps?reffNo=${kpReffno}`
                                let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');
                                if (response.fileKps !== null && response.fileKpsContentType) {
                                    this.previewFile(response.fileKpsContentType, response.fileKps);
                                } else {
                                    notify({ message: 'Tidak ada file yang di upload', alignment: 'center', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'warning', 1500);
                                }
                            } else if (data.reffType === 'HRD' || data.reffType === 'HR') {
                                let loadAPI = `pembayaran-umum-realisasis/fetch-file-sdm/${data.reffNo}`;
                                let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET');

                                if (response.realisasiFile !== null && response.puContentType !== null) {
                                    this.previewFile(response.puContentType, response.realisasiFile);
                                } else {
                                    notify({ message: 'Tidak ada file yang di upload', alignment: 'center', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'warning', 1500);
                                }
                            } else {
                                notify({ message: 'Tidak ada file yang di upload', alignment: 'center', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'warning', 1500);
                            }
                        }
                    },
                    {
                        text: 'Isi Nilai Maksimum',
                        hint: 'Isi Nilai Maksimum',
                        icon: "check",
                        onClick: (e) => {
                            this.dataGridRef.current.push([{
                                type: "update",
                                key: e.row.key,
                                data: {
                                    amountPaid: e.row.data.outstandingAmount,
                                }
                            }])

                            this.formRef.current.instance.updateData({
                                companyBankId: e.row.data.companyBankId,
                                description: e.row.data.description
                            })
                            // this.calculateTotal()
                        }
                    },
                    {
                        text: 'Uncheck',
                        hint: 'Uncheck',
                        icon: "close",
                        onClick: (e) => {
                            this.dataGridRef.current.push([{
                                type: "update",
                                key: e.row.key,
                                data: {
                                    amountPaid: 0,
                                }
                            }])

                            this.formRef.current.instance.updateData({
                                companyBankId: null,
                                description: '',
                                pembulatanNominal: 0,
                                pphNominal: 0,
                                transferFee: 0,
                                coaPphAkunId: null
                            })
                            // this.calculateTotal()
                        }
                    },
                    {
                        text: 'Reject',
                        hint: 'Reject',
                        icon: "trash",
                        onClick: async (e) => {
                            if (e.row) {
                                switch (e.row.data.reffType) {
                                    case 'HR':
                                        try {
                                            this.dataOutstandingHr = e.row.data
                                            this.showRejectNotesRef.current.show()
                                        } catch (e) {
                                            await alert(`Data Tidak Tersedia`, 'Notification');
                                        }
                                        break;

                                    case 'IN':
                                        try {
                                            // await alert(`Tolak Outstanding Transaksi Investasi masih tahap Development!`, 'Notification');
                                            this.dataOutstandingIn = e.row.data
                                            this.showRejectNotesRef.current.show()
                                        } catch (e) {

                                        }
                                        break;

                                    case 'KP':
                                        try {
                                            // await alert(`Tolak Outstanding Transaksi Kepesertaan masih tahap Development!`, 'Notification');
                                            this.dataOutstandingKp = e.row.data
                                            this.showRejectNotesRef.current.show()
                                        } catch (e) {

                                        }
                                        break;

                                    default:
                                        await alert(`Data Tidak Tersedia`, 'Notification');
                                        break;
                                }
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'reffNo',
                caption: 'No Transaksi',
                allowEditing: false
            },
            {
                dataField: 'reffType',
                caption: 'Tipe Transaksi',
                lookup: {
                    dataSource: arapReffType(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                allowEditing: false
            },
            {
                dataField: 'tradeDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy',
                allowEditing: false
            },
            {
                dataField: 'dueDate',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy',
                allowEditing: false
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode',
                },
                allowEditing: false
            },
            {
                dataField: 'type',
                caption: 'Tipe',
                lookup: {
                    dataSource: arapType(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                },
                allowEditing: false
            },
            {
                dataField: 'description',
                caption: 'Description',
                allowEditing: false
            },
            {
                dataField: 'amount',
                caption: 'Nominal Transaksi',
                format: '#,##0.00',
                alignment: "right",
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'outstandingAmount',
                caption: 'Sisa Nominal Transaksi',
                format: '#,##0.00',
                alignment: "right",
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                allowEditing: false
            },
            {
                dataField: 'amountPaid',
                caption: 'Nominal Bayar / Terima',
                format: '#,##0.00',
                alignment: "right",
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
                validationRules: [
                    {
                        type: 'custom',
                        message: 'Nilai Bayar / Terima Melebihi Sisa Nominal Transaksi',
                        validationCallback: (e) => {
                            return e.data.outstandingAmount >= e.value
                        }
                    }
                ]
            },
        ]


        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async () => {
                        this.submitData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Batal",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.showRejectNotesRef = React.createRef()

        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
    }

    previewFile = async (contentType, file) => {
        var src = imageSource(contentType, file)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    getTwoWorkingDaysBefore = (date) => {
        let workingDays = 0;
        let currentDate = new Date(date);
        while (workingDays <= 2) {
            currentDate.setDate(currentDate.getDate() - 1);
            const day = currentDate.getDay();
            // Skip weekends
            if (day !== 0 && day !== 6) {
                workingDays++;
            }
        }
        return currentDate;
    }

    retrieveData = async (data) => {
        if (data.entitiId) {
            this.dataEntitiId = data.entitiId
            var loadAPI = `entitis/${data.entitiId}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI)
            this.formRef.current.instance.updateData(response)

            var loadArapAPI = `araps/byEntitiIdAndOustandingNotZero/${data.entitiId}`
            var responseArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadArapAPI)

            var reffType = responseArap.map(value => value.reffType)
            reffType = [...new Set(reffType)]

            var companyBank = [], companyBankId
            let dataBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks')
            for (const reff of reffType) {
                let dataRekBank
                switch (reff) {
                    case 'IN': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-banks')
                        dataRekBank = dataRekBank.map(value => {
                            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
                            return {
                                id: `${reff}-${value.id}`,
                                value: `${bank ? bank.initialName : null} - ${value.rekNo} - ${value.rekName}`
                            }
                        })
                        break;
                    case 'HR': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'rek-banks')
                        dataRekBank = dataRekBank.map(value => {
                            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)
                            return {
                                id: `${reff}-${value.id}`,
                                value: `${bank ? bank.initialName : null} - ${value.rekNo} - ${value.rekName}`
                            }
                        })
                        break;
                    case 'KP': dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'rekbanks')
                        dataRekBank = dataRekBank.map(value => {
                            const bank = dataBank.find((valueBank) => valueBank.id === value.bankId)

                            return {
                                id: `${reff}-${value.id}`,
                                value: `${bank ? bank.initialName : null} - ${value.rekNo} - ${value.rekName}`
                            }
                        })
                        break;
                    default: break;
                }

                companyBank = [...companyBank, ...dataRekBank]
            }

            this.companyBank = companyBank
            this.dataArap = responseArap

            // responseArap = responseArap.map(value => {
            //     let dataCompanyBank = {}
            //     if (value.reffType === 'HR') {
            //         dataCompanyBank = this.companyBank.find(valueRekBank => valueRekBank.value.includes(value.defaultBank))
            //     }

            //     if (value.reffType === 'IN') {
            //         dataCompanyBank = this.companyBank.find(valueRekBank => {
            //             if ((value.defaultBank || '').includes(' - ')) {
            //                 const defaultBank = value.defaultBank.split(' - ')
            //                 return valueRekBank.value.includes(defaultBank[0])
            //             } else {
            //                 return false
            //             }
            //         })
            //     }

            //     return {
            //         type: "insert",
            //         data: {
            //             id: value.id,
            //             reffNo: value.reffNo,
            //             reffType: value.reffType,
            //             tradeDate: value.tradeDate,
            //             dueDate: value.dueDate,
            //             currencyId: value.currencyId,
            //             type: value.amount > 0 ? 'R' : 'P',
            //             amount: Math.abs(value.amount),
            //             outstandingAmount: Math.abs(value.outstandingAmount),
            //             amountPaid: 0,
            //             description: value.description,
            //             companyBankId: (dataCompanyBank || {}).id
            //         }
            //     }
            // })
            // this.dataGridRef.current.push(responseArap)
        }
    }

    loadData = async () => {
        var loadArapAPI = `araps/byEntitiIdAndOustandingNotZero/${this.dataEntitiId}`
        var responseArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadArapAPI)

        var reffType = responseArap.map(value => value.reffType)
        reffType = [...new Set(reffType)]

        responseArap = responseArap.map(value => {
            let dataCompanyBank = {}
            if (value.reffType === 'HR') {
                dataCompanyBank = this.companyBank.find(valueRekBank => valueRekBank.value.includes(value.defaultBank))
            }

            if (value.reffType === 'IN') {
                dataCompanyBank = this.companyBank.find(valueRekBank => {
                    if ((value.defaultBank || '').includes(' - ')) {
                        const defaultBank = value.defaultBank.split(' - ')
                        return valueRekBank.value.includes(defaultBank[0])
                    } else {
                        return false
                    }
                })
            }

            return {
                id: value.id,
                reffNo: value.reffNo,
                reffType: value.reffType,
                tradeDate: value.tradeDate,
                dueDate: value.dueDate,
                currencyId: value.currencyId,
                type: value.amount > 0 ? 'R' : 'P',
                amount: Math.abs(value.amount),
                outstandingAmount: Math.abs(value.outstandingAmount),
                amountPaid: 0,
                description: value.description,
                companyBankId: (dataCompanyBank || {}).id
            }
        })
        return responseArap
    }

    submitData = async () => {
        const validationRes = this.formRef.current.instance.validate()

        if (validationRes.isValid) {
            if (this.state.formData.coaPphAkunId == null && (this.state.formData.pphNominal > 0 || this.state.formData.pphNominal < 0)) {
                notify({ message: "COA Pemotong harus di isi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            } else if (this.state.formData.coaPphAkunId && (this.state.formData.pphNominal == 0 || this.state.formData.pphNominal == null)) {
                notify({ message: "COA Nilai harus di isi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            } else {
                try {
                    this.props.store.dispatch(showLoading(true))

                    var dataMaster = this.state.formData
                    var dataArap = this.dataGridRef.current.getDataSource()

                    // console.log(dataArap);
                    // return null;


                    dataArap = dataArap.filter(value => value.amountPaid > 0)
                    var companyBank = dataMaster.companyBankId ? dataMaster.companyBankId.split('-') : []
                    var responseInsertVoucher = {}
                    if (dataMaster.amount < 0) {
                        responseInsertVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'cash-voucers', 'POST', {
                            values: {
                                "amount": dataMaster.amount,
                                "currencyId": dataMaster.currencyId,
                                "description": dataMaster.description,
                                "entitiId": dataMaster.id,
                                "entryDate": dataMaster.entryDate,
                                "settleWith": dataMaster.settleWith,
                                "status": "RV",
                                "type": dataMaster.amount > 0 ? 'R' : 'P',
                                "voucherNo": dataMaster.voucherNo,
                                "companyBankId": companyBank[1],
                                "companyBankReff": companyBank[0],
                                "clientBankId": dataMaster.clientBankId,
                                "clientBankNo": dataMaster.clientBankNo,
                                "transferFee": dataMaster.transferFee,
                                "coaPphAkunId": dataMaster.coaPphAkunId || 0,
                                "pphNominal": dataMaster.pphNominal || 0,
                                "pembulatanNominal": dataMaster.pembulatanNominal || 0,
                                "akunPembulatanId": this.pembulatanAkunId.intValue || 0,
                                "akunLabarugiId": this.labaRugiAkunId.intValue || 0,
                                "valueDate": formatDate(dataMaster.valueDate),
                            }
                        })
                    } else {
                        responseInsertVoucher = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'cash-voucers', 'POST', {
                            values: {
                                "amount": dataMaster.amount,
                                "currencyId": dataMaster.currencyId,
                                "description": dataMaster.description,
                                "entitiId": dataMaster.id,
                                "entryDate": dataMaster.entryDate,
                                "settleWith": dataMaster.settleWith,
                                "status": "O",
                                "type": dataMaster.amount > 0 ? 'R' : 'P',
                                "voucherNo": dataMaster.voucherNo,
                                "companyBankId": companyBank[1],
                                "companyBankReff": companyBank[0],
                                "clientBankId": dataMaster.clientBankId,
                                "clientBankNo": dataMaster.clientBankNo,
                                "transferFee": dataMaster.transferFee,
                                "coaPphAkunId": dataMaster.coaPphAkunId || 0,
                                "pphNominal": dataMaster.pphNominal || 0,
                                "pembulatanNominal": dataMaster.pembulatanNominal || 0,
                                "akunPembulatanId": this.pembulatanAkunId.intValue || 0,
                                "akunLabarugiId": this.labaRugiAkunId.intValue || 0,
                                "valueDate": formatDate(dataMaster.valueDate),
                            }
                        })
                    }
                    for (const arap of dataArap) {
                        const responseInsertVoucherDetail = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'cash-voucer-detils', 'POST', {
                            values: {
                                "amountPaid": arap.amountPaid * (arap.type === 'P' ? -1 : 1),
                                "arapId": arap.id,
                                "cashVoucherId": responseInsertVoucher.id,
                            }
                        })

                        const dataArap = this.dataArap.find(value => value.id === arap.id)

                        const updateArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'araps', 'PUT', {
                            values: {
                                outstandingAmount: (arap.outstandingAmount * (arap.type === 'P' ? -1 : 1)) + (arap.amountPaid * (arap.type === 'R' ? -1 : 1))
                            }
                        }, dataArap)
                    }

                    let dataFindType = dataArap.find(value => value.type === 'P');

                    if (dataFindType) {
                        await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps/taskVerifikasiCashVoucer/${responseInsertVoucher.id}`, 'POST', {
                            values: {}
                        })
                    }

                    // for (let data of dataArap) {
                    //     if (data.type === 'P') {
                    //         await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps/taskVerifikasiCashVoucer/${responseInsertVoucher.id}`, 'POST', {
                    //             values: {}
                    //         })
                    //     }
                    // }

                    let type = 'success'
                    let text = 'Data Berhasil Disimpan'

                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 1000);
                    this.props.forceRefresh()
                    this.hide()
                    this.props.store.dispatch(showLoading(false))
                } catch (e) {
                    console.log(e)

                    let type = 'error'
                    let text = 'Proses Menyimpan Data Gagal'

                    notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 1000);
                    this.props.store.dispatch(showLoading(false))
                }
            }
        }
    }

    show = async () => {
        const systemDate = this.props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE')
        var voucherNo = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-voucers/generateCode/${formatDate(systemDate.dateValue)}`)
        this.setState({
            popupVisible: true,
            formData: {
                ...this.state.formData,
                voucherNo: voucherNo,
                entryDate: new Date(systemDate.dateValue)
            }
        });
    };

    hide = () => {
        this.dataEntitiId = 0
        this.dataOutstandingHr = {}
        this.dataOutstandingIn = {}
        this.setState({
            formData: {
                pphNominal: 0,
                coaPphAkunId: null
            },
            amount: 0,
            popupVisible: false,
        });
        this.formRef.current.instance.updateData({
            "amount": null,
            "currencyId": null,
            "description": null,
            "entitiId": null,
            "entryDate": null,
            "settleWith": null,
            "status": null,
            "type": null,
            "voucherNo": null,
            "companyBankId": null,
            "companyBankReff": null,
            "clientBankId": null,
            "clientBankNo": null,
            "transferFee": null,
            "coaPphAkunId": null,
            "pphNominal": null,
            "pembulatanNominal": null,
        })
        this.dataGridRef.current.forceRefresh(true)
        this.props.forceRefresh();

    };

    calculateTotal = (e) => {
        var data = this.dataGridRef.current.getDataSource()

        var total = 0
        var message = []

        let companyBankId, currencyId

        for (const d of data) {
            if (d.amountPaid !== 0) {
                // message.push(d.description)
                currencyId = d.currencyId
            }
            if (d.type === 'R') {
                total = total + d.amountPaid
            } else {
                total = total - d.amountPaid
            }

            companyBankId = d.companyBankId
        }

        this.formRef.current.instance.updateData({
            amount: total,
            // description: message.join(','),
            currencyId: currencyId,
            companyBankId: companyBankId
        })

        this.setState({
            amount: total
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         text: 'Check Selected',
            //         icon: 'check',
            //         onClick: (e) => {
            //             for (const data of this.selectedRowDatas) {
            //                 this.dataGridRef.current.push([{
            //                     type: "update",
            //                     key: data.id,
            //                     data: {
            //                         amountPaid: data.outstandingAmount,
            //                     }
            //                 }])
            //             }

            //             if (this.selectedRowDatas.length > 0) {
            //                 this.formRef.current.instance.updateData({
            //                     companyBankId: this.selectedRowDatas[0].companyBankId,
            //                     description: this.selectedRowDatas[this.selectedRowDatas.length - 1].description
            //                 })
            //             }
            //         },
            //     }
            // },
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         text: 'Refresh',
            //         icon: 'check',
            //         onClick: (e) => {
            //             this.forceRefresh()
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Check All',
                    icon: 'check',
                    onClick: (e) => {
                        var dataListARAP = this.dataGridRef.current.getDataSource();

                        for (const data of dataListARAP) {
                            this.dataGridRef.current.push([{
                                type: "update",
                                key: data.id,
                                data: {
                                    amountPaid: data.outstandingAmount,
                                }
                            }])
                        }

                        if (dataListARAP.length > 0) {
                            this.formRef.current.instance.updateData({
                                companyBankId: dataListARAP[0].companyBankId,
                                description: dataListARAP[dataListARAP.length - 1].description
                            })
                        }
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Un-Check',
                    icon: 'close',
                    onClick: (e) => {
                        var dataListARAP = this.dataGridRef.current.getDataSource();

                        for (const data of dataListARAP) {
                            this.dataGridRef.current.push([{
                                type: "update",
                                key: data.id,
                                data: {
                                    amountPaid: 0,
                                }
                            }])
                        }

                        this.formRef.current.instance.updateData({
                            companyBankId: null,
                            description: '',
                            pembulatanNominal: 0,
                            pphNominal: 0,
                            transferFee: 0,
                            coaPphAkunId: null
                        })
                    },
                }
            },
        )
    }

    rejectOutstandingNotes = async (reasonMessage) => {
        if (this.dataOutstandingHr.reffType === 'HR') {
            let purID = this.dataOutstandingHr.reffNo.slice(4)
            let getArap = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps/${this.dataOutstandingHr.id}`, 'GET')
            await this.sendNoted(purID, getArap, reasonMessage)
        } else if (this.dataOutstandingIn.reffType === 'IN') {
            let purID = this.dataOutstandingIn.reffNo;
            let getArap = this.dataOutstandingIn;
            await this.sendNoted(purID, getArap, reasonMessage)
        } else if (this.dataOutstandingKp.reffType === 'KP') {
            let purId = this.dataOutstandingKp.reffNo;
            let getArap = this.dataOutstandingKp;
            await this.sendNoted(purId, getArap, reasonMessage)
        }
        // let getPemRealisasi = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum-realisasis/${purID}`, 'GET')
        // let getPembayaranUmum = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umums/${getPemRealisasi.pembayaranUmumId}`, 'GET')

    }

    sendNoted = async (purID, dataArap, reasonMessage) => {
        try {
            if (dataArap.reffType === 'HR') {
                let loadAPI = `pembayaran-umums/set-reject-outstanding-sdm/${purID}/${reasonMessage.rejectedNote}`
                let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
                    values: {}
                })
    
                if (response.status !== 'Error') {
                    await this.changeAmount(dataArap)
                    await alert(`Sukses Reject Data`, response.status);
                    this.forceRefresh()
                } else {
                    await alert(response.description_status, response.status);
                    this.forceRefresh()
                }
            } else if (dataArap.reffType === 'IN') {
                let dataValue = {
                    reffNo: dataArap.reffNo,
                    rejectedNotes: reasonMessage.rejectedNote,
                    user:  this.getUserLoged
                }
                let loadAPI = `journal-headers/validasi-reject-outstanding`;
                let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'POST', {
                    values: dataValue
                })
                if (response) {
                    let dataValue = {
                        reffNo: response[0].reffNo,
                        rejectedNotes: response[0].rejectNotes,
                        user: response[0].user
                    }
                    let loadAPI = `transaction/reject-outstanding-inv`;
                    let responseTransaksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'POST', {
                        values: dataValue
                    })

                    if (responseTransaksi[0].status !== 'Gagal') {
                        await this.changeAmount(dataArap)
                        await alert(responseTransaksi[0].descriptionStatus, responseTransaksi[0].status);
                        this.forceRefresh()
                    } else {
                        await alert(responseTransaksi[0].descriptionStatus, responseTransaksi[0].status);
                        this.forceRefresh()
                    }
                }
            } else if (dataArap.reffType === 'KP') {
                let dataValue = {
                    reffNo: dataArap.reffNo,
                    rejectedNotes: reasonMessage.rejectedNote,
                    user:  this.getUserLoged
                }

                let loadAPI = `transaction-confirmations/reject-outstanding-kps`;
                let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                    values: dataValue
                })
                
                if (response.status !== 'Gagal') {
                    await this.changeAmount(dataArap)
                    await alert(response.statusDescription, response.status);
                    this.forceRefresh()
                } else {
                    await alert(response.statusDescription, response.status);
                    this.forceRefresh()
                }
            }
        } catch (e) {
            console.log(e);
            await alert(`Data Gagal di Reject`, 'Gagal');
        }
    }

    changeAmount = async (dataAraps) => {
        try {
            dataAraps.outstandingAmount = 0
            await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `araps`, 'PUT', {
                values: dataAraps,
                key: dataAraps.id
            }, dataAraps)

        } catch (e) {
            console.log(e);
            await alert(`Gagal Mengubah data arap`, 'Gagal');
        }
    }

    // onSelectionChanged = (e) => {
    //     this.selectedRowDatas = e.selectedRowsData
    // }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={"Form Create Cash Voucher"}
                    minWidth={"70vw"}
                    minHeight={"90vh"}
                    toolbarItems={this.PopupToolbarItem}
                >
                    <Form
                        colCount={2}
                        id={"formMaster"}
                        formData={this.state.formData}
                        items={this.formItem}
                        ref={this.formRef}
                        labelLocation="left"
                    />
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        menuRightClick={false}
                        loadAPI='arap'
                        insertAPI='arap'
                        updateAPI='arap'
                        deleteAPI='arap'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource={true}
                        ArraySourceData={this.loadData}

                        editingMode='row'

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}

                        exportExcel={false}
                        exportFileName={"Outstanding Cash Settlement"}
                        allowExportSelectedData={false}
                        selection={"none"}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}
                        grouping={false}

                        height="calc(100% - 250px)"

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Outstanding Cash Settlement'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}
                        summaryTotalItem={this.sumDebit}
                        // onSelectionChanged={this.onSelectionChanged}

                        onRowUpdated={this.calculateTotal}
                        usePushEvent={true}
                        //   onToolbarPreparing={this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </Popup>
                <ModalRejectOutstanding
                    ref={this.showRejectNotesRef}
                    store={this.props.store}
                    rejectOutstandingNotes={this.rejectOutstandingNotes}
                    forceRefresh={this.forceRefresh}
                />
                <ModalPreviewPdf ref={this.modalPreviewPdfRef} store={this.props.store}/>
            </React.Fragment>

        );
    }
}

export default ModalCashSettlement;