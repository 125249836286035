import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import ModalFileSettlementTransaksiDeposito from 'components/pages/modal/investasi/settlement/konfirmasi/deposito/file';
import { addURL } from 'redux/actions/url';
import ModalSettlementTransaksiDepositoPenempatn from 'components/pages/modal/investasi/settlement/konfirmasi/deposito/penempatan/index';
import ModalSettlementTransaksiDepositoPencairan from 'components/pages/modal/investasi/settlement/konfirmasi/deposito/pencairan/index';
import ModalSettlementTransaksiDepositoPerpanjangan from 'components/pages/modal/investasi/settlement/konfirmasi/deposito/perpanjangan/index';
import { banks } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';
import ViewJurnalDetails from 'components/pages/modal/investasi/viewJurnalTrans';
import ModalUploadKpdDeposito from 'components/pages/modal/investasi/settlement/konfirmasi/deposito/uploadKpd';
import ModalDetailTransaksiDeposito from 'components/pages/modal/investasi/settlement/konfirmasi/deposito/detailTransaksi';
import httpRequest from 'plugin/httprequest';

class SettlementTransaksiDeposito extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            filterItem: {
                startDate:new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        }

        this.dataGridRef = React.createRef()
        this.modalAlokasiRef = React.createRef()
        this.modalSettlementTransaksiDepositoRef = React.createRef()
        this.modalPenempatan = React.createRef()
        this.modalPencairan = React.createRef()
        this.modalPerpanjangan = React.createRef()
        this.showModalViewJurnalRef = React.createRef()
        this.modalFileSettlementTransaksiDepositoRef = React.createRef()
        this.modalUploadKpdRef = React.createRef()
        this.modalDetailTransaksiRef = React.createRef()

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            startDate: formatDate(e.value)
                        })
                    }
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            endDate: formatDate(e.value)
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text : 'Lihat Jurnal',
                    //     hint : 'Lihat Jurnal',
                    //     cssClass: 'text-success',
                    //     onClick : (e) => {
                    //         this.showModalViewJurnal(e)
                    //     }
                    // },
                    {
                        text: 'View',
                        hint: 'View',
                        cssClass: 'text-success',
                        onClick: async(e) => {
                            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/detail-konfirmasi-deposito/${e.row.data.konfirmasiId}`, 'GET')
                            
                            if (response.length !== 0) {
                                this.modalDetailTransaksiRef.current.show(e.row.data)
                            } else {
                                notify({ message: 'Data untuk nomor konfirmasi ini kosong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        }
                    },
                    // {
                    //     text: 'File',
                    //     hint: 'File',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.showModalFile('edit', e.row.data)
                    //     }
                    // }
                ],
            },
            {
                dataField: 'konfirmasiId',
                caption: 'ID',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'Nomor Konfirmasi',
            },
            {
                dataField: 'bankName',
                caption: 'Bank',
            },
            {
                dataField: 'trsDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tipeTransaksi',
                caption: 'Tipe Transaksi',
            },
            {
                dataField: 'totalTransaksi',
                caption: 'Transaksi',
                alignment: 'right'
            },

        ]
        this.dropDownbutton = [
            {
                id: 1,
                text: 'Penempatan',
                onClick: () => {
                    this.showModalPenempatan()
                }
            },
            {
                id: 2,
                text: 'Pencairan',
                onClick: () => {
                    this.showModalPencairan()
                }
            },
            {
                id: 3,
                text: 'Perpanjangan',
                onClick: () => {
                    this.showModalPerpanjangan()
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        // var d = new Date().getTime()
        // var start = d - (30 * 24 * 3600 * 1000)
        // var startDate = new Date(start)
        // this.setState({
        //     startDate: formatDate(startDate)
        // })
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxDropDownButton',
                options: {
                    text: 'Konfirmasi',
                    icon: 'plus',
                    items: this.dropDownbutton,
                    keyExpr: 'id',
                    displayExpr: 'text',
                    splitButton: true,
                    onItemClick: 'onClick'
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Upload KPD',
                    onClick: (e) => {
                        this.showModalUploadKPD()
                    },
                }
            },
        )
    }
    showModalUploadKPD = () => {
        this.modalUploadKpdRef.current.show();
        this.modalUploadKpdRef.current.getKpd();
    }
    showModalViewJurnal = (e) => {
        if (e.row) {
            this.showModalViewJurnalRef.current.retrieveData(e.row.data)
            this.showModalViewJurnalRef.current.newData(true)
        }else{
            this.showModalViewJurnalRef.current.newData(false)
        }

        this.showModalViewJurnalRef.current.show()
    }
    showModalPenempatan = (data = null) => {
        this.modalPenempatan.current.show()
        this.modalPenempatan.current.retrieveData(data)
    }
    showModalPencairan = (data = null) => {
        this.modalPencairan.current.show()
        this.modalPencairan.current.retrieveData(data)
    }
    showModalPerpanjangan = (data = null) => {
        this.modalPerpanjangan.current.show()
        this.modalPerpanjangan.current.retrieveData(data)
    }
    showModalFile = (data = null) => {
        this.modalFileSettlementTransaksiDepositoRef.current.show()
        this.modalFileSettlementTransaksiDepositoRef.current.retrieveData(data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfirmasi Deposito</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    keyField={'konfirmasiId'}
                    ref={this.dataGridRef}
                    loadAPI={`transaction-confirmations/deposito/list?endDate=${formatDate(this.state.filterItem.endDate)}&startDate=${formatDate(this.state.filterItem.startDate)}&status=2&size=99999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Transaksi Deposito"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Transaksi Deposito'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 251px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ViewJurnalDetails 
                    ref={this.showModalViewJurnalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementTransaksiDepositoPenempatn
                    ref={this.modalPenempatan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementTransaksiDepositoPencairan
                    ref={this.modalPencairan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalSettlementTransaksiDepositoPerpanjangan
                    ref={this.modalPerpanjangan}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalFileSettlementTransaksiDeposito
                    ref={this.modalFileSettlementTransaksiDepositoRef}
                    store={this.props.store}
                />
                <ModalUploadKpdDeposito 
                    ref={this.modalUploadKpdRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalDetailTransaksiDeposito 
                    ref={this.modalDetailTransaksiRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default SettlementTransaksiDeposito