import React, { Component } from 'react';
import { Popup,ScrollView,Form,FileUploader } from 'devextreme-react'
import { jabatan, programs, sdmUnit } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest' 
import notify from "devextreme/ui/notify";

import { confirmAlert } from 'react-confirm-alert'

class FormModalProbisiRekruitmen extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
        }

        this.formMasterRef = React.createRef()
        
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.DataMaster = [
            {
                dataField:"kodeRekruitmen",
                label:{
                    text:"Kode Rekruitmen"
                }
            },
            {
                dataField:"namaLengkap",
                label:{
                    text:"Nama Lengkap"
                }
            },
            {
                dataField:"noKtp",
                label:{
                    text:"No Ktp"
                }
            },
            {
                dataField:"tempatLahir",
                label:{
                    text:"Tempat Lahir"
                }
            },
            {
                dataField:"tglLahir",
                label:{
                    text:"Tanggal Lahir"
                }
            },
            {
                dataField:"alamat",
                label:{
                    text:"Alamat"
                }
            },
            {
                dataField:"provinsi",
                label:{
                    text:"Provinsi"
                }
            },
            {
                dataField:"kota",
                label:{
                    text:"Kota"
                }
            },
            {
                itemType:'group',
                caption:'Probisi',
                items:[
                    {
                        dataField:"skor",
                        label:{
                            text:"Skor"
                        }
                    },
                    {
                        dataField:"nik",
                        label:{
                            text:"NIK"
                        }
                    },
                    {
                        dataField:"tglProbisiAwal",
                        label:{
                            text:"Tanggal Probisi Awal"
                        }
                    },
                    {
                        dataField:"tglProbisiAkhir",
                        label:{
                            text:"Tanggal Probisi Akhir"
                        }
                    },
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                //   onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
    }

    show() { 
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster:{}
        })
    }

    retrieveData = async(data) => {
        this.setState({
            DataMaster: data,
        })
    }

    render() {
        return (
        <React.Fragment>
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Probisi Kandidat"}
                width={"60vw"}
                height={"70vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                    <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation = 'left'
                        />
                    </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalProbisiRekruitmen
