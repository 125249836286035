import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { months, statusIuran, participants, product } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ModalKonfirmasiPiutangIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/piutangIuran/konfirmasi';
import ModalDetailRiwayatPenerimaanIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/riwayatPenerimaanIuran/detail';
import ModalRekapRiwayatPenerimaanIuran from 'components/pages/modal/kepesertaan/penerimaanIuran/riwayatPenerimaanIuran/rekap';

class RiwayatPenerimaanIuran extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            getUrl: 'iurans/list-riwayat-penerima-iuran?endMonth=4&endYear=2024&participantId=808251&productId=1&startMonth=1&startYear=2000'
        }

        this.dataGridRef = React.createRef()
        this.formFilterRef = React.createRef()
        this.modalKonfirmasiRef = React.createRef()
        this.modalDetailRef = React.createRef()

        this.iuranType = []

        this.filterItem = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'participantId',
                        label: {
                            text: 'Pemberi Kerja',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: participants(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "participantName", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'productId',
                        label:{
                            text: 'Produk',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            placeholder: ' ',
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode", // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'Periode',
                colCount: 3,
                items: [
                    {
                        dataField: 'greaterPeriode',
                        label: {
                            text: 'Periode'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'MMM yyyy',
                            placeholder : 'Bulan/Tahun',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'lessPeriode',
                        label: {
                            text: 'Sampai'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'MMM yyyy',
                            placeholder : 'Bulan/Tahun',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            openOnFieldClick: true
                        }
                    },
                    {
                        dataField: 'filter',
                        label:{
                            visible:false,
                        },
                        editorType: 'dxButton',
                        editorOptions: {
                            horizontalAlignment: 'right',
                            text: 'Filter',
                            onClick: (e) => {
                                this.filterData()
                            }
                        }
                    }
                ]  
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text : 'Rekap',
                        hint : 'Rekap',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalRekap(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender : (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'participantId',
                caption: 'Pemberi Kerja',
                lookup: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName'
                }
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Periode Tahun-Bulan',
                cellRender : (e) => {
                    var data = e.row.data
                    var bulan
                    switch(data.bulan) {
                        case 1:
                            bulan = 'Januari'
                        break
                        case 2:
                            bulan = 'Februari'
                        break
                        case 3:
                            bulan = 'Maret'
                        break
                        case 4:
                            bulan = 'April'
                        break
                        case 5:
                            bulan = 'Mei'
                        break
                        case 6:
                            bulan = 'Juni'
                        break
                        case 7:
                            bulan = 'Juli'
                        break
                        case 8:
                            bulan = 'Agustus'
                        break
                        case 9:
                            bulan = 'September'
                        break
                        case 10:
                            bulan = 'Oktober'
                        break
                        case 11:
                            bulan = 'November'
                        break
                        case 12:
                            bulan = 'Desember'
                        break
                    }
                    return bulan + ' - ' + data.tahun
                }
            },
            {
                dataField: 'transactionDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'dueDate',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'jumlahAkun',
                caption: 'Jumlah Akun',
                format: '#,##0',
                alignment: 'right'
            },
            {
                dataField: 'nominalIuran',
                caption: 'Jumlah Nominal Iuran',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'receiveAmount',
                caption: 'Jumlah Diterima',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'receiveOutstanding',
                caption: 'Outstanding',
                format: '#,##0.00',
                alignment: 'right'
            }
        ]
    }

    filterData = () => {
        var filter = this.state.formFilter
        var loadAPI = 'iurans/list-riwayat-penerima-iuran?'

        if(filter.participantId){
            loadAPI = loadAPI+`participantId=${filter.participantId}&`
        }

        if(filter.productId){
            loadAPI = loadAPI+`productId=${filter.productId}&`
        }

        // PERIODE
        if(filter.greaterPeriode && filter.lessPeriode){
            var greaterBulan = new Date(filter.greaterPeriode).getMonth() + 1;
            var greaterTahun = new Date(filter.greaterPeriode).getFullYear()

            var lessBulan = new Date(filter.lessPeriode).getMonth() + 1;
            var lessTahun = new Date(filter.lessPeriode).getFullYear()

            loadAPI = loadAPI+`startMonth=${greaterBulan}&startYear=${greaterTahun}&endMonth=${lessBulan}&endYear=${lessTahun}&`
        }
        // if(filter.lessPeriode){
        //     var lessBulan = new Date(filter.lessPeriode).getMonth() + 1;
        //     var lessTahun = new Date(filter.lessPeriode).getFullYear()
        //     loadAPI = loadAPI+`bulan.lessThanOrEqual=${lessBulan}&tahun.lessThanOrEqual=${lessTahun}&`
        // }

        this.setState({
            getUrl : loadAPI.slice(0,-1)
        })
    }
    componentWillMount = async() =>{
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        await this.retrieveIuranType()
    } 


    retrieveIuranType = async() => {
        var loadAPIuranTypes = `iuran-types`
        var dataIuranTypes = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIuranTypes, 'GET')

        this.iuranType = dataIuranTypes
    }

    showModalDetail = (data) => {
        this.modalDetailRef.current.show()
        this.modalDetailRef.current.retrieveData(data)
    }
    showModalRekap = (data) => {
        this.modalKonfirmasiRef.current.show()
        this.modalKonfirmasiRef.current.retrieveData(data)
    }
    
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Riwayat Penerimaan Iuran</h2>
                <Form
                    ref={this.formFilterRef}
                    colCount={1}
                    id={'formDataMaster'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation = 'left'
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='iurans'
                    updateAPI='iurans'
                    deleteAPI='iurans' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    // remoteOperations = {false}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Riwayat Penerimaan Iuran"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Riwayat Penerimaan Iuran'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalRekapRiwayatPenerimaanIuran 
                    ref={this.modalKonfirmasiRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
                <ModalDetailRiwayatPenerimaanIuran 
                    ref={this.modalDetailRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default RiwayatPenerimaanIuran