import React, { Component } from 'react'
import Form from "devextreme-react/form";
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react';
import loadColumnConfig from 'components/pages/parts/staticDataColumnConfig';
import ApprovalNotes from './approvalNotes';
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify';

class PreviewApproval extends Component {
    constructor(props){
        super(props)

        this.state = {
            formData : {},
            headerData : {},
            popupVisible: false,
            fields: []
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.fieldsHeader = [
            {
                dataField:"procInstanceId",
                label: {
                    text: "Process ID",
                    location:"left"
                },
                colSpan: 3,
            },
            {
                dataField:"taskName",
                label: {
                    text: "Task Name",
                    location:"left"
                },
                colSpan: 3,
            },
            {
                dataField:"type",
                label: {
                    text: "Module",
                    location:"left"
                },
            },
            {
                dataField:"operation",
                label: {
                    text: "Operation",
                    location:"left"
                },
            },
            {
                dataField:"creatorId",
                label: {
                    text: "Created By",
                    location:"left"
                },
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Setuju',
                    type:'success',
                    onClick: this.approve.bind(this),
                    visible: this.props.approveType === 'checker' || this.props.approveType === 'approval' ? true : false
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Tolak',
                    type:'danger',
                    onClick: this.reject.bind(this),
                    visible: this.props.approveType === 'checker' || this.props.approveType === 'approval' ? true : false
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]


        this.approvalNotes = null
        this.approveOrReject = null

        this.formRef = React.createRef()
        this.formHeaderRef = React.createRef()
        this.approvalNotesRef = React.createRef()
    }

    setFields = (module,store) => {
        var column = loadColumnConfig(module,store)

        if (column === null || column.length < 1) {
            return null;
        } else {
            var formField = (column.find(value => value.type === 'form') || {}).items

            var fieldsItem
            if(formField){
                fieldsItem = formField
            }else{
                fieldsItem = column.map(value => {
                    if(value.caption !== 'Action'){
                        var mappedField = {
                            dataField: value.dataField,
                            label: {
                                text: value.caption,
                                alignment: "left",
                                location:"left"
                            },
                        }
            
                        if(value.editorType){
                            mappedField.editorType = value.editorType
                        }
            
                        if(value.editorType){
                            mappedField.editorOptions = value.editorOptions
                        }
            
                        return mappedField
                    }else{
                        return null
                    }
                })
                
                fieldsItem = fieldsItem.filter(value => value)
            }
        }

        this.setState({
            fields: fieldsItem
        })
    }

    setData = (header,data,taskId) => {
        var headerData = header
        headerData.taskId = taskId
        this.setState({
            headerData: headerData,
            formData: data
        })
    }

    show() {
        this.setState({
            popupVisible: true
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            formData : {},
            headerData : {},
            fields: []
        });
    }

    approvalProcess = async (notes) => {
        let type = 'success'
        let listType = this.props.type === 'persetujuan' ? 'Approve' : 'Pemeriksaan';
        let text = `Sukses ${listType} Data`
        var parameters = {
            "id": this.state.headerData.taskId,
            "notes": notes,
            "status": this.approveOrReject === 'A' ? true : false
        }

        await httpRequest(this.state.headerData.backendserver,this.props.store,'staticdata-wf/review','POST',{
            values: parameters
        })

        this.approveOrReject = null

        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
        this.hide()
        this.props.refreshDataGrid()
    }

    approve = () => {
        this.approveOrReject = 'A'
        this.showApprovalNotes()
    }

    reject = () => {
        this.approveOrReject = 'R'
        this.showApprovalNotes()
    }

    showApprovalNotes = () => {
        this.approvalNotesRef.current.setApproveOrReject(this.approveOrReject)
        this.approvalNotesRef.current.show()
    }

    render() {
        return (
            <div>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    showTitle={true}
                    title={'Detail Data'} 
                    width={'70vw'}
                    height={'70vh'}

                    ref={this.PopupRef}

                    toolbarItems = {this.popupToolbarItem}
                >
                    <Form
                        colCount={3}
                        id={'formHeader'}
                        formData={this.state.headerData}
                        items={this.fieldsHeader}
                        scrollingEnabled={false}
                        ref={this.formHeaderRef}
                        labelLocation={'left'}
                        readOnly={true}
                    />
                    <hr />
                    {/* <div style={{height: '300px', overflowY: 'scroll'}}> */}
                        <Form
                            colCount={1}
                            id={'formData'}
                            formData={this.state.formData}
                            items={this.state.fields}
                            scrollingEnabled={true}
                            ref={this.formRef}
                            labelLocation={'left'}
                            readOnly={true}
                            height={'calc(70vh - 250px'}
                        />
                    {/* </div> */}
                    {/* <div className={'row fixed-bottom mr-2 mb-4'}>
                        <div className={'col-12'}>
                            <div className='float-right'>
                                <Button 
                                    type={'success'}
                                    text={'Approve'}
                                    className="mr-2"
                                    onClick={(e) => {this.approve()}}
                                />
                                <Button 
                                    type={'danger'}
                                    text={'Reject'}
                                    className="mr-2"
                                    onClick={(e) => {this.reject()}}
                                />
                                <Button 
                                    text={'Cancel'}
                                    onClick={this.hide}
                                />
                            </div>
                        </div>
                    </div> */}
                </Popup>
                <ApprovalNotes approvalProcess = {this.approvalProcess} ref={this.approvalNotesRef}/>
            </div>
        )
    }
}

export default PreviewApproval
