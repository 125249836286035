import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import { showLoading } from 'redux/actions/loading';

class FormModalClientPrticipant extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            newData: true
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.formItems = [
            {
                dataField: 'participantCode',
                caption: 'Kode',
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                dataField: 'participantName',
                caption: 'Nama Pemberi Kerja',
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                dataField: 'alamat',
                caption: 'Alamat',
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                dataField: 'kepesertaan',
                caption: 'KEPESERTAAN',
                columns: [
                    {
                        dataField: 'aktif',
                        caption: 'Aktif',
                    },
                    {
                        dataField: 'pasif',
                        caption: 'Pasif',
                    },
                    {
                        dataField: 'arsip',
                        caption: 'Arsip',
                    },
                ],
                validationRules: [
                    {
                        type: "required",
                    }
                ],
            },
            {
                type: 'form',
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'participantCode',
                                label: {
                                    text: 'Kode'
                                }
                            },
                            {
                                dataField: 'participantName',
                                label: {
                                    text: 'Nama Pemberi Kerja'
                                }
                            },
                            {
                                dataField: 'alamat',
                                label: {
                                    text: 'Alamat'
                                }
                            },
                            {
                                dataField: 'email',
                                label: {
                                    text: 'Email'
                                },
                                validationRules: [
                                    {
                                        type: 'custom',
                                        validationCallback : (e) => {
                                            var regexPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                                            if(regexPattern.test(e.value)){
                                                return true
                                            }
                                            return false
                                        },
                                        message: 'Format email salah'
                                    }
                                ]
                            },
                            {
                                dataField: 'telp',
                                label: {
                                    text: 'No. Telp',
                                },
                                editorType:"dxTextBox",
                                editorOptions:{
                                    maxLength:12
                                },
                                validationRules: [
                                    {
                                        type: 'pattern',
                                        pattern: /^\(?([0-9]{0,12})\)?$/,
                                        message: 'No Telepon tidak valid karena mengandung huruf'
                                    },
                                ]
                            },
                            {
                                dataField: 'fax',
                                label: {
                                    text: 'No. Fax'
                                },
                                editorType: 'dxTextBox',
                                validationRules: [
                                    {
                                        type: 'pattern',
                                        pattern: /^\(?([0-9]{0,12})\)?[-.\s]?\(?\d{1,4}?\)?$/,
                                        message: 'No Fax tidak valid karena mengandung huruf'
                                    },
                                ]
                            },
                            {
                                dataField: 'payIuranDate',
                                label: {
                                    text: 'Tanggal Pembayaran Iuran',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                    displayFormat: 'dd MMM yyyy'
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                    }
                                ],
                            },
                            {
                                dataField: 'tglPembayaranIuran',
                                label: {
                                    text: 'Tanggal Iuran',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                            {
                                dataField: 'tglPengkinianPeserta',
                                label: {
                                    text: 'Tanggal Pengkinian Peserta',
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        items: [
                            {
                                itemType: 'group',
                                caption: 'Kontak Person Kepesertaan',
                                items: [
                                    {
                                        dataField: 'namaKepesertaan',
                                        label: {
                                            text: 'Nama'
                                        }
                                    },
                                    {
                                        dataField: 'emailKepesertaan',
                                        label: {
                                            text: 'Email'
                                        },
                                        validationRules: [
                                            {
                                                type: 'custom',
                                                validationCallback : (e) => {
    
                                                    var regexPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                                                    if(regexPattern.test(e.value)){
                                                        return true
                                                    }
                                                    return false
                                                },
                                                message: 'Format email salah'
                                            }
                                        ]
                                    },
                                    {
                                        dataField: 'telpKepesertaan',
                                        label: {
                                            text: 'No. Telp'
                                        },
                                        editorType:"dxTextBox",
                                        editorOptions:{
                                            maxLength:12
                                        },
                                        validationRules: [
                                            {
                                                type: 'pattern',
                                                pattern: /^\(?([0-9]{0,12})\)?$/,
                                                message: 'No Telepon tidak valid karena mengandung huruf'
                                            },
                                        ]
                                    },
                                    {
                                        dataField: 'noHpKepesertaan',
                                        label: {
                                            text: 'No. HP'
                                        },
                                        editorType:"dxTextBox",
                                        editorOptions:{
                                            maxLength:12
                                        },
                                        validationRules: [
                                            {
                                                type: 'pattern',
                                                pattern: /^\(?([0-9]{0,12})\)?$/,
                                                message: 'No Handphone tidak valid karena mengandung huruf'
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                caption: 'Kontak Person Keuangan',
                                items: [
                                    {
                                        dataField: 'namaKeuangan',
                                        label: {
                                            text: 'Nama'
                                        }
                                    },
                                    {
                                        dataField: 'emailKeuangan',
                                        label: {
                                            text: 'Email'
                                        },
                                        validationRules: [
                                            {
                                                type: 'custom',
                                                validationCallback : (e) => {
    
                                                    var regexPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
                                                    if(regexPattern.test(e.value)){
                                                        return true
                                                    }
                                                    return false
                                                },
                                                message: 'Format email salah'
                                            }
                                        ]
                                    },
                                    {
                                        dataField: 'telpKeuangan',
                                        label: {
                                            text: 'No. Telp'
                                        },
                                        editorType:"dxTextBox",
                                        editorOptions:{
                                            maxLength:12
                                        },
                                        validationRules: [
                                            {
                                                type: 'pattern',
                                                pattern: /^\(?([0-9]{0,12})\)?$/,
                                                message: 'No Telepon tidak valid karena mengandung huruf'
                                            },
                                        ]
                                    },
                                    {
                                        dataField: 'noHpKeuangan',
                                        label: {
                                            text: 'No. HP'
                                        },
                                        editorType:"dxTextBox",
                                        editorOptions:{
                                            maxLength:12
                                        },
                                        validationRules: [
                                            {
                                                type: 'pattern',
                                                pattern: /^\(?([0-9]{0,12})\)?$/,
                                                message: 'No Handphone tidak valid karena mengandung huruf'
                                            },
                                        ]
                                    },
                                ]
                            },
                        ]
                    }
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.submitData();
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        this.updateData()
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    retrieveData = async (data) => {
        this.setState({
            dataMaster: data
        })
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'staticdata-wf/participants', 'POST', {
                values: data
            });

            if (response) {
                notify({ message: `Berhasil menambah data!`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.props.store.dispatch(showLoading(false))
                this.hide();
                this.props.forceRefresh();
            }
        } catch (error) {
            console.log(error);
        }
    }

    updateData = async () => {
        this.props.store.dispatch(showLoading(true))
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'staticdata-wf/participants', 'PUT', {
                key: data.id,
                values: data
            }, data);

            if (response) {
                notify({ message: `Data berhasil diubah`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.props.store.dispatch(showLoading(false))
                this.hide();
                this.props.forceRefresh();
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={this.props.actionType === 'add' ? 'Form Client Pemberi Kerja Data' : 'Form Edit Client Pemberi Kerja Data'}
                width={"70vw"}
                height={"70vh"}
                toolbarItems={this.props.actionType === 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                <Form
                    ref={this.formRef}
                    colCount={2}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.formItems.find(value => value.type === 'form').items}
                    labelLocation='left'
                />
            </Popup>
        )
    }
}

export default FormModalClientPrticipant