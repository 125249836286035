import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import {jabatanGolongan} from 'dataSource/lookup'
import notify from 'devextreme/ui/notify'
import { confirm } from 'devextreme/ui/dialog'

class FormModalJabatan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()

        this.formItems = [
            {
                dataField: 'jabatanCode',
                caption: 'Kode Jabatan',
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan',
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
            {
                dataField: 'jabatanGolonganId',
                caption: 'Golongan Jabatan',
                lookup: {
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaJagol',
                    searchEnabled: true,
                },
                validationRules : [
                    {
                        type: "required",
                    }
                ],
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaJagol',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'instruksiTtd',
                caption: 'Instruksi TTD',
                dataType: 'boolean',
                editorType: "dxCheckBox" 
            },
            {
                dataField: 'instruksiTtdUrutan',
                caption: 'Urutan Instruksi TTD',
                dataType: 'number',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: '1'
                        },
                        {
                            id: 2,
                            value: '2'
                        },
                        {
                            id: 3,
                            value: '3'
                        },
                        {
                            id: 4,
                            value: '4'
                        },
                        {
                            id: 5,
                            value: '5'
                        },
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                } 
            },
            {
                dataField: 'authorize',
                caption: 'Authorize',
                dataType: 'boolean',
                editorType: "dxCheckBox" 
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        let result = await confirm('Apakah kamu yakin ingin menambah data ini?', 'Konfirmasi')

                        if(result) {
                            this.submitData();
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: async (e) => {
                        let result = await confirm('Apakah kamu yakin ingin mengubah data ini?', 'Konfirmasi')

                        if(result) {
                            this.updateData()
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ];
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = async(e) => {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
        this.props.forceRefresh(true)
    }

    retrieveData = async (data) => {
        this.setState({
            dataMaster: data
        })
    }

    submitData = async () => {
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'jabatans', 'POST', {
                values: data
            });

            if (response) {
                this.hide();
                await notify({ message: 'Berhasil tambah data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            await notify({ message: `Terjadi kesalahan input ${error}`, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            console.log(error);
        }
    }

    updateData = async () => {
        try {
            let data = this.state.dataMaster;

            let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'jabatans', 'PUT', {
                key: data.id,
                values: data
            }, data);

            if (response) {
                this.hide();
                await notify({ message: 'Berhasil ubah data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.props.forceRefresh();
            }
        } catch (error) {
            await notify({ message: `Terjadi kesalahan input ${error}`, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            console.log(error);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={this.props.actionType === 'add' ? 'Form Jabatan' : 'Form Edit Jabatan'}
                width={"30vw"}
                height={"auto"}
                toolbarItems={this.props.actionType === 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                <Form
                    ref={this.formRef}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.formItems}
                    labelLocation='left'
                />
            </Popup>
        )
    }
}

export default FormModalJabatan