import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { instrumentCategories, productDefault } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalEditAlokasiKategori from './edit-alokasi/index';
import { confirm } from 'devextreme/ui/dialog';
class ModalAlokasiKategori extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.modalRef = React.createRef()
        this.datagridRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: "productId",
                label: {
                    text: "Produk",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.productCode + ' - ' + e.productName
                    }
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        // this.setState({
                        //     startDate: this.state.filterItem.priceDate,
                        //     endDate: this.state.filterItem.priceDate,
                        // })
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.column = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModalEditAlokasiKategori(e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async (e) => {
                            if (e.row) {
                                let result = await confirm("Apakah anda yakin untuk menghapus data ini?", "Konfirmasi");
                                if (result) {
                                    this.delete(e.row.data)
                                }
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'category',
                caption: 'Kategori',
                // calculateCellValue: (rowData) => {
                //     if (rowData.categorySubId !== null) {
                //         return rowData.categorySubId
                //     } else {
                //         return rowData.categoryId
                //     }
                // },
                // lookup: {
                //     dataSource: instrumentCategories(this.props.store),
                //     valueExpr: "id",
                //     displayExpr: "categoryName",
                // }
            },
            {
                dataField: 'persenMax',
                caption: 'Alokasi (%)',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'notifikasi',
                caption: 'Notifikasi',
            },
            {
                dataField: 'keterangan',
                caption: 'Catatan',
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    showModalAddAlokasiKategori = () => {
        this.modalRef.current.show()
    }

    showModalEditAlokasiKategori = (data = null) => {
        if (data) {
            this.modalRef.current.retrieveData(data)
        }
        this.modalRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Alokasi',
                    onClick: (e) => {
                        this.showModalAddAlokasiKategori()
                    },
                }
            }
        )
    }

    delete = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'kebijakan-spesifikasi-values', 'DELETE', data.id);

            this.forceRefresh();
            notify({ message: "Sukses Hapus Data", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 1000);
        } catch (error) {
            console.log(error);
            notify({ message: "Terjadi Kesalahan", width: "AUTO", shading: true, position: { at: "center", my: "center", of: window } }, 'error', 1000);
        }
    }

    loadData = async () => {
        var dataResult = []
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-values?kebijakanSpesifikasi.equals=1&productId.equals=1`, 'GET')
        var kategori = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-categories`, 'GET')
        var kategoriSub = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-category-subs`, 'GET')

        for (let data of response) {

            if (data.categorySubId !== null) {
                let filterKategoriSub = kategoriSub.find(value => value.id === data.categorySubId)
                data.category = filterKategoriSub.subName
            } else {
                let filterKategori = kategori.find(value => value.id === data.categoryId)
                data.category = filterKategori.categoryName
            }
            dataResult.push(data)
        }
        return dataResult
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'80vw'}
                    height="80vh"
                    title={`Alokasi Kategori`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <Form
                                ref={this.formRef}
                                colCount={4}
                                id={'dataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation={"left"}
                            />
                            <div className="row mb-2">
                                <div className="col-md-12">
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}
                                        loadAPI='kebijakan-spesifikasi-values'
                                        insertAPI='kebijakan-spesifikasi-values'
                                        updateAPI='kebijakan-spesifikasi-values'
                                        deleteAPI='kebijakan-spesifikasi-values'

                                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                        useArraySource={true}
                                        ArraySourceData={this.loadData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}
                                        exportExcel={true}
                                        exportFileName={"Alokasi Kategori"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={''}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={350} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.column} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        onToolbarPreparing={this.onToolbarPreparing}
                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                    <ModalEditAlokasiKategori
                                        ref={this.modalRef}
                                        store={this.props.store}
                                        forceRefresh={this.forceRefresh}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalAlokasiKategori