import React, {Component} from 'react'
import { Popup,ScrollView, Form, FileUploader } from 'devextreme-react'
import { banks, currency, custodies,instrumentObligasi,instruments,kpds,product,rekBank,rekCustody,getInstrumentById, productDefault } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper'
import notify from "devextreme/ui/notify";
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage'
class ViewKuponKonfirmasi extends Component {
    constructor(props){
        super(props)
        this.taxBond = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-B-Bond') : null
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        
        this.state = {
            popupVisible: false,
            DataMaster: {
                tglKonfirmasi:new Date(this.sysDate ? this.sysDate.dateValue : null),
                kurs:1,
                currencyId:1001,
            },
            DataDocument: {},
            DataInstrument:{},
            uploadedData:[]
        }

        this.disableEvent = false
        this.notAdd = false

        this.dataRekCust = []
        this.dataRekBank = []
        this.dataInstrumentId = 0
        this.instrumentId = []
        this.listDataInstrument = []
        this.dayBase = ''
        this.idKupon = 0


        this.DataMaster = [
            {
                itemType: 'group',
                name:"konfirmasi",
                caption:'Konfirmasi',
                items: [
                    {
                        dataField:'noKonfirmasi',
                        label:{
                            text:'No. Konfirmasi'
                        },
                        editorOptions: {
                            readOnly:true
                        },
                    },
                    {
                        dataField:'tglKonfirmasi',
                        label:{
                            text:'Tanggal Konfirmasi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal Konfirmasi tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField:'custodyId',
                        label:{
                            text:'Bank Kustodi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: custodies(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.custodyCode + " - " + item.custodyName;
                            },
                            onValueChanged: async(e) => {
                                if (e.value) {
                                    var rekCustodyList = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,`rek-custodies/custodyId/${e.value}`,"GET");
                                    
                                    this.formMasterRef.current.instance.updateData({rekCustodyId:rekCustodyList.id})
                                }
                            }
                        }
                    },
                    {
                        dataField:'rekCustodyId',
                        label:{
                            text:'No Rek Kustodi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekCustody(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true,
                            displayExpr: function (item) {
                                return item && item.rekNo + " - " + item.rekName;
                            },
                            onValueChanged:(e) => {
                                if (e.value) {
                                    var DataMaster = {}
                                    var productId
                                    var dataRekCust = e.component._options._optionManager._options.items
                                    for(var data of dataRekCust){
                                        productId = data.productId
                                    }
                                    DataMaster.produkId = productId
                                    this.formMasterRef.current.instance.updateData(DataMaster)
                                }
                            }
                        }
                    },
                    {
                        dataField:'produkId',
                        label:{
                            text:'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly:true,
                            displayExpr: function (item) {
                                return item && item.productCode + " - " + item.productName;
                            },
                            onValueChanged:async (e) => {
                                if (e.value) {
                                    var rekBankList = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `rek-banks-by-productId?productId=${e.value}`,
                                        "GET"
                                    );
                                    var options = this.formMasterRef.current.instance.itemOption('konfirmasi.rekPenerimaannId')
                                    options.editorOptions.dataSource = rekBankList
                                    this.formMasterRef.current.instance.itemOption('konfirmasi.rekPenerimaannId',options)
                                }
                            }
                        }
                    },
                    {
                        dataField:'kpdId',
                        label:{
                            text:'KPD'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            onValueChanged: this.retrieveDataInstrument,
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'noKontrak',
                        }
                    },
                    {
                        dataField:'instrumentId',
                        label:{
                            text:'Instrument'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'instrumentId',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.instrumentCode + " - " + item.instrumentName;
                            },
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    this.disableEvent = true
                                    var DataInstrument = {}
                                    this.dataInstrumentId = e.value
                                    var response = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `instruments/${e.value}`,
                                        "GET"
                                    );
                                    this.dayBase = response.dayBase

                                    var jumlahDataTransaksi = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `bank-transactions?instrumentId.in=${e.value}&&size=999&transactionTypeId.in=294`
                                    )
                                    this.formMasterRef.current.instance.updateData({kuponKe: jumlahDataTransaksi.length + 1})
                                    
                                    var getInfoAccrued = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `efek-balance-avgs/by-instrumentId/${e.value}`,
                                        "GET"
                                    );

                                    var faceValue = 0, accruedInterest = 0
                                    if (getInfoAccrued) {
                                        faceValue = getInfoAccrued.length > 0 ? getInfoAccrued[0].faceValue : 0
                                        accruedInterest = getInfoAccrued.length > 0 ? getInfoAccrued[0].acruedInterest : 0 
                                    }
                                    
                                    if (response) {
                                        response.rate = response.rate
                                        DataInstrument.instrumentCode = response.instrumentCode
                                        DataInstrument.instrumentName = response.instrumentName
                                        DataInstrument.rate = response.rate
                                        DataInstrument.instrumentCode = response.instrumentCode
                                        DataInstrument.maturityDate = response.maturityDate
                                        DataInstrument.couponPeriod = response.couponPeriod
                                        DataInstrument.couponLastDate = response.couponLastDate
                                        DataInstrument.couponNextDate = response.couponNextDate
                                        DataInstrument.faceValue = faceValue
                                        DataInstrument.accruedInterest = accruedInterest

                                        if (!this.notAdd) {
                                            var kuponLastDate = new Date(response.couponLastDate)
                                            var kuponNextDate = new Date(response.couponNextDate)
                                            var diffYear = kuponNextDate.getFullYear() - kuponLastDate.getFullYear()
                                            var diffMount = kuponNextDate.getMonth() - kuponLastDate.getMonth()
                                            var diffDate = kuponNextDate.getDate() - kuponLastDate.getDate()
                                            var daysCount
                                            var interestDiTerima
                                            if (this.dayBase == 'BASE_30_360') {
                                                daysCount = 360 * (diffYear) + 30 * (diffMount) + (diffDate)
                                                interestDiTerima = daysCount * response.rate / 100 * faceValue / 360
                                            }

                                            if (this.dayBase == 'BASE_30_365') {
                                                daysCount = 365 * (diffYear) + 30 * (diffMount) + (diffDate)
                                                interestDiTerima = daysCount * response.rate / 100 * faceValue / 365
                                            }

                                            if (this.dayBase == 'BASE_ACT_360') {
                                                kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
                                                var timeDiff = (new Date(kuponNextDate).getTime() - kuponLastDate) / 1000
                                                daysCount = Math.floor(timeDiff/(86400))
                                                interestDiTerima = daysCount * response.rate / 100 * faceValue / 360
                                            }

                                            if (this.dayBase == 'BASE_ACT_ACT' || this.dayBase == 'BASE_ACT_365') {
                                                kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
                                                var timeDiff = (new Date(kuponNextDate).getTime() - kuponLastDate) / 1000
                                                daysCount = Math.floor(timeDiff/(86400))
                                                interestDiTerima = daysCount * response.rate / 100 * faceValue / 365
                                            }
                                            var selisihKupon = (Math.round(interestDiTerima * 100000000) / 100000000) - accruedInterest
                                            
                                            DataInstrument.jumlahhari = daysCount
                                            DataInstrument.holdingPeriode = daysCount
                                            DataInstrument.selisihKuponInterest = selisihKupon
                                            this.formMasterRef.current.instance.updateData({tglPenerimaan:response.couponNextDate,interestDiterima:interestDiTerima})
                                        }
                                        this.formInstRef.current.instance.updateData(DataInstrument)
                                        this.disableEvent = false
                                    }
                                }
                            }
                        }
                    },
                    {
                        dataField:'tglPenerimaan',
                        label:{
                            text:'Tanggal Perhitungan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            onValueChanged:(e) => {
                                if (e.value && !this.disableEvent) {
                                    var DataInstrument = {...this.state.DataInstrument}
                                    var kuponLastDate = new Date(DataInstrument.couponLastDate)
                                    var kuponNextDate = new Date(e.value)
                                    var diffYear = kuponNextDate.getFullYear() - kuponLastDate.getFullYear()
                                    var diffMount = kuponNextDate.getMonth() - kuponLastDate.getMonth()
                                    var diffDate = kuponNextDate.getDate() - kuponLastDate.getDate()
                                    var daysCount
                                    var interestDiTerima
                                    if (this.dayBase == 'BASE_30_360') {
                                        daysCount = 360 * (diffYear) + 30 * (diffMount) + (diffDate)
                                        interestDiTerima = daysCount * DataInstrument.rate / 100 * DataInstrument.faceValue / 360
                                    }

                                    if (this.dayBase == 'BASE_30_365') {
                                        daysCount = 365 * (diffYear) + 30 * (diffMount) + (diffDate)
                                        interestDiTerima = daysCount * DataInstrument.rate / 100 * DataInstrument.faceValue / 365
                                    }

                                    if (this.dayBase == 'BASE_ACT_360') {
                                        kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
                                        var timeDiff = (new Date(e.value).getTime() - kuponLastDate) / 1000
                                        daysCount = Math.floor(timeDiff/(86400))
                                        interestDiTerima = daysCount * DataInstrument.rate / 100 * DataInstrument.faceValue / 360
                                    }

                                    if (this.dayBase == 'BASE_ACT_ACT' || this.dayBase == 'BASE_ACT_365') {
                                        kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
                                        var timeDiff = (new Date(e.value).getTime() - kuponLastDate) / 1000
                                        daysCount = Math.floor(timeDiff/(86400))
                                        interestDiTerima = daysCount * DataInstrument.rate / 100 * DataInstrument.faceValue / 365
                                    }
                                    var selisihKupon = (Math.round(interestDiTerima * 100000000) / 100000000) - DataInstrument.accruedInterest
                                    this.formInstRef.current.instance.updateData({
                                                jumlahhari:daysCount,
                                                holdingPeriode:daysCount,
                                                selisihKuponInterest:selisihKupon
                                            }
                                        )
                                    this.formMasterRef.current.instance.updateData({interestDiterima:interestDiTerima})
                                }
                            },
                        }
                    },
                    {
                        dataField:'interestDiterima',
                        label:{
                            text:'Interest Diterima'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format:'#,##0.00',
                            onValueChanged:(e) => {
                                if (e.value) {
                                    var resultInterest = e.value / (1 - this.taxBond.floatValue / 100)
                                    var pph42Final = (e.value / (1 - this.taxBond.floatValue / 100)) * this.taxBond.floatValue / 100
                                    this.formMasterRef.current.instance.updateData({interest:resultInterest,pph42Final:pph42Final})
                                }
                            }
                        }
                    },
                    {
                        dataField:'interest',
                        label:{
                            text:'Interest'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:'pph42Final',
                        label:{
                            text:'PPH 4.2 - Final'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:'kuponKe',
                        label:{
                            text:'Kupon Ke'
                        },
                        editorType: 'dxNumberBox',
                        validationRules: [
                            {
                                type: "range",
                                min: 1,
                                message: "Nomor Kupon harus diisi"
                            },
                        ]
                    },
                    {
                        dataField:'pokokDibayarkan',
                        label:{
                            text:'Pokok Dibayarkan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:'rekPenerimaannId',
                        label:{
                            text:'Rekening Penerimaan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.rekNo + " - " + item.rekName;
                            },
                            onValueChanged: async(e) => {
                                if (e.value) {
                                    var response = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `rek-banks/${e.value}`,
                                        "GET"
                                    );

                                    this.formMasterRef.current.instance.updateData({bankId:response.bankId})
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Rekening Penerimaan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField:'bankId',
                        label:{
                            text:'Bank'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly:true,
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.bankCode + " - " + item.bankName;
                            },
                        }
                    },
                    {
                        dataField:'currencyId',
                        label:{
                            text:'Currency'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly:true,
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.currencyCode + " - " + item.currencyName;
                            },
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    var DataMaster = {}
                                    var response = await httpRequest(
                                        process.env.REACT_APP_BACKEND_ACC,
                                        this.props.store,
                                        `currencies/${e.value}`,
                                        "GET"
                                    );
                                    if (response) {
                                        DataMaster.kurs = response.rate
                                    }
                                    this.formMasterRef.current.instance.updateData(DataMaster)
                                }
                            }
                        }
                    },
                    {
                        dataField:'kurs',
                        label:{
                            text:'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                ]
            },
        ]

        this.DataInstrument = [
            {
                itemType: 'group',
                name:'group',
                items: [
                    {
                        itemType: 'group',
                        caption:'Informasi Instrument',
                        name:'infoInst',
                        items:[
                            {
                                dataField:'instrumentCode',
                                label:{
                                    text:'Kode'
                                }
                            },
                            {
                                dataField:'instrumentName',
                                label:{
                                    text:'Nama Instrument'
                                }
                            },
                            {
                                dataField:'rate',
                                label:{
                                    text:'Interest (%)'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format:'#,##0.000',
                                }
                            },
                            {
                                dataField:'maturityDate',
                                label:{
                                    text:'Maturity Date'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField:'couponPeriod',
                                label:{
                                    text:'Kupon Periode'
                                }
                            },
                            {
                                dataField:'holdingPeriode',
                                label:{
                                    text:'Holding Periode (Days)'
                                }
                            },
                            {
                                dataField:'couponLastDate',
                                label:{
                                    text:'Last Kupon Date'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField:'couponNextDate',
                                label:{
                                    text:'Next Kupon Date'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                },
                                
                            },
                        ]
                        
                    },
                    {
                        itemType: 'group',
                        caption:'Informasi Accrued Interest',
                        name:'infoAccrued',
                        items:[
                            {
                                dataField:'faceValue',
                                label:{
                                    text:'Face Value'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format:'#,##0.00',
                                    onValueChanged:(e) => {
                                        if (e.value && !this.disableEvent) {
                                            var DataInstrument = {...this.state.DataInstrument}
                                            var interestDiTerima
                                            if (this.dayBase == 'BASE_30_360' || this.dayBase == 'BASE_ACT_360') {
                                                interestDiTerima = DataInstrument.holdingPeriode * DataInstrument.rate / 100 * e.value / 360
                                            }

                                            if (this.dayBase == 'BASE_30_365') {
                                                interestDiTerima = DataInstrument.holdingPeriode * DataInstrument.rate / 100 * e.value / 365
                                            }

                                            if (this.dayBase == 'BASE_ACT_ACT' || this.dayBase == 'BASE_ACT_365') {
                                                interestDiTerima = DataInstrument.holdingPeriode * DataInstrument.rate / 100 * e.value / 365 
                                            }
                                            this.formMasterRef.current.instance.updateData({interestDiterima:interestDiTerima})
                                        }
                                    }
                                }
                            },
                            {
                                dataField:'jumlahhari',
                                label:{
                                    text:'Jumlah hari'
                                },
                            },
                            {
                                dataField:'accruedInterest',
                                label:{
                                    text:'Accrued Interest'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format:'#,##0.00',
                                }
                            },
                            {
                                dataField:'selisihKuponInterest',
                                label:{
                                    text:'Selisih Kupon Interest'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format:'#,##0.00',
                                }
                            },
                        ]
                        
                    },
                ] 
            }
        ]

        this.popupToolbarItem1 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submit()
                        }
                    } 
                },
                visible:this.props.action == 'view' ? false : true,
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Ubah',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.update()
                        }
                    } 
                },
                visible:this.props.action == 'view' ? false : true,
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formInstRef = React.createRef()
        this.fileUploderRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    retrieveData = async(data) => {
        this.disableEvent = true
        this.notAdd = true

        this.idKupon = data.id
        var DataInstrument = {...this.state.DataInstrument}
        var dataBankTrans = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `bank-transactions/${data.id}`,
            "GET"
        );
        let string = dataBankTrans.description.split(' ')
        let res = string[4]
        var dataInstrument = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `instruments/${dataBankTrans.instrumentId}`,
            "GET"
        );
        var dataAccrued = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `efek-balance-avgs/by-instrumentId/${dataBankTrans.instrumentId}`,
            "GET"
        );

        await this.retrieveDataInstrument({value:dataBankTrans.kpdId})

        var diffYear = new Date(dataBankTrans.receiveDate).getFullYear() - new Date(dataInstrument.couponLastDate).getFullYear()
        var diffMount = new Date(dataBankTrans.receiveDate).getMonth() - new Date(dataInstrument.couponLastDate).getMonth()
        var diffDate = new Date(dataBankTrans.receiveDate).getDate() - new Date(dataInstrument.couponLastDate).getDate()
        var daysCount
        var kuponLastDate
        if (dataInstrument.dayBase == 'BASE_30_360') {
            daysCount = 360 * (diffYear) + 30 * (diffMount) + (diffDate)
        }

        if (dataInstrument.dayBase == 'BASE_30_365') {
            daysCount = 365 * (diffYear) + 30 * (diffMount) + (diffDate)
        }

        if (dataInstrument.dayBase == 'BASE_ACT_360') {
            kuponLastDate = new Date(dataInstrument.couponLastDate).getTime()
            var timeDiff = (new Date(dataBankTrans.receiveDate).getTime() - kuponLastDate) / 1000
            daysCount = Math.floor(timeDiff/(86400))
        }

        if (dataInstrument.dayBase == 'BASE_ACT_ACT' || dataInstrument.dayBase == 'BASE_ACT_365') {
            kuponLastDate = new Date(dataInstrument.couponLastDate).getTime()
            var timeDiff = (new Date(dataBankTrans.receiveDate).getTime() - kuponLastDate) / 1000
            daysCount = Math.floor(timeDiff/(86400))
        }

        var dataRetrieve = {...dataBankTrans}
        dataRetrieve.produkId = dataRetrieve.productId
        dataRetrieve.tglPenerimaan = dataRetrieve.receiveDate
        dataRetrieve.interest = dataRetrieve.nominalBersih
        dataRetrieve.pph42Final = dataRetrieve.pph
        dataRetrieve.rekPenerimaannId = dataRetrieve.receiveRekBankId
        dataRetrieve.kurs = dataRetrieve.currencyRate
        dataRetrieve.noKonfirmasi = dataRetrieve.konfirmasiNo 
        dataRetrieve.tglKonfirmasi = dataRetrieve.konfirmasiDate
        dataRetrieve.interestDiterima = dataRetrieve.nominalTransaksi
        dataRetrieve.pokokDibayarkan = dataRetrieve.pokokDibayarkan
        dataRetrieve.kuponKe = res
        

        DataInstrument.selisihKuponInterest = dataBankTrans.interestSelisih
        DataInstrument.jumlahhari = daysCount
        DataInstrument.holdingPeriode = daysCount
        DataInstrument.faceValue =  dataAccrued[0].faceValue
        DataInstrument.accruedInterest =  dataAccrued[0].acruedInterest
        this.setState({
            DataMaster: dataRetrieve,
            DataInstrument:DataInstrument
        })

        await this.retrieveFile(dataBankTrans.fileInstruksiId)

        this.disableEvent = false
        this.notAdd = false
    }

    generateNoKonf = async (isNew) => {
        if (isNew) {
            var DataMaster = {...this.state.DataMaster}
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `bank-transactions/generate-no-konfirmasi/PENERIMAAN_KUPON_SBN`,
                "POST",{
                    values:{}
                }
            );
            DataMaster.noKonfirmasi = response
            this.setState({
                DataMaster:DataMaster
            })
        }
    }

    retrieveFile = async (fileId) => {
        try {
            var loadAPI = `file-investasis/${fileId}`;
            var response = await httpRequest(
              process.env.REACT_APP_BACKEND_INVESTASI,
              this.props.store,
              loadAPI,
            );

            var DataDocument = { ...this.state.DataDocument };
            if (response) {
                DataDocument[`id`] = response.id;
                DataDocument[`fileName`] = response.fileName;
                DataDocument[`fileBase64`] = response.fileBase64;
                DataDocument[`fileBase64ContentType`] = response.fileBase64ContentType;
                this.setState({
                    DataDocument: DataDocument
                });
            }
            
        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    retrieveDataInstrument = async (e) => {
        if (e.value && this.state.DataMaster.produkId) {
            var category = [4,5,6,7]
            var DataMaster = this.state.DataMaster
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `efek-balance-avgs/produkId/kpdId/categoryId/${DataMaster.produkId}/${e.value}/${category.join()}`,
                "GET"
            );
            
            var getDataInstrument
            if (response.length !== 0) {
                response.map((val) => {
                    this.instrumentId.push(val.instrumentId)
                })

                getDataInstrument = await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    `instruments/obligasi-multiId/${this.instrumentId.join()}`,
                    "GET"
                );
            }else{
                this.instrumentId = []
                getDataInstrument = []
            }

            this.listDataInstrument = getDataInstrument

            var options = this.formMasterRef.current.instance.getEditor('instrumentId')
            options.option('dataSource', getDataInstrument)
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {
                tglKonfirmasi:new Date(this.sysDate ? this.sysDate.dateValue : null),
                kurs:1,
                currencyId:1001,
            },
            DataDocument: {},
            DataInstrument:{},
            uploadedData:[]
        })
        this.dataRekCust = []
        this.dataRekBank = []
        this.dataInstrumentId = 0
        this.instrumentId = []
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}Base64`] = formattedFileData.base64data;
            DataDocument[`${fieldName}Base64ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}Base64ContentType`],state[`${fileName}Base64`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    update = async () => {
        try {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "PUT",{
                    values: this.state.DataDocument,
                    key:this.state.DataDocument.id
                },this.state.DataDocument
            );

            if (response) {
                await this.updateDataKupon(response.id)
            }else{
                notify({ message: 'Terjadi Kesalahan System , Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    updateDataKupon = async (fileKonfirmasiId) => {
        try {
            var DataMaster = this.state.DataMaster
            var DataInstrument = this.state.DataInstrument
            var loadApi = `bank-transactions/konfirmasi-penerimaan-kupon-interest-create-update`
            var data = {
                id:this.idKupon,
                accruedInterest: DataInstrument.accruedInterest,
                bankId: DataMaster.bankId,
                currencyId: DataMaster.currencyId,
                custodyId: DataMaster.custodyId,
                faceValue: DataInstrument.faceValue,
                fileKonfirmasiId: fileKonfirmasiId,
                instrumentId: this.dataInstrumentId,
                interest: DataMaster.interest,
                interestDiterima: DataMaster.interestDiterima,
                jmlHari: DataInstrument.jumlahhari,
                kpdId: DataMaster.kpdId,
                kuponKe: DataMaster.kuponKe,
                kurs: DataMaster.kurs,
                noKonfirmasi: DataMaster.noKonfirmasi,
                pph42Final: DataMaster.pph42Final,
                produkId: DataMaster.produkId,
                rekCustodyId: DataMaster.rekCustodyId,
                rekPenerimaannId: DataMaster.rekPenerimaannId,
                selisihKuponInterest: DataInstrument.selisihKuponInterest,
                tglKonfirmasi: formatDate(DataMaster.tglKonfirmasi),
                tglPenerimaan: formatDate(DataMaster.tglPenerimaan),
                pokokDibayarkan: DataMaster.pokokDibayarkan
              }
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: data,
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System(${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    submit = async () => {
        try {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "POST",{
                    values: this.state.DataDocument
                }
            );

            if (response) {
                await this.submitDataKupon(response.id)
            }else{
                notify({ message: 'Terjadi Kesalahan System , Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    submitDataKupon = async(fileKonfirmasiId) => {
        try {
            var DataMaster = this.state.DataMaster
            var DataInstrument = this.state.DataInstrument
            var loadApi = `bank-transactions/konfirmasi-penerimaan-kupon-interest-create-update`
            var data = {
                accruedInterest: DataInstrument.accruedInterest,
                bankId: DataMaster.bankId,
                currencyId: DataMaster.currencyId,
                custodyId: DataMaster.custodyId,
                faceValue: DataInstrument.faceValue,
                fileKonfirmasiId: fileKonfirmasiId,
                instrumentId: this.dataInstrumentId,
                interest: DataMaster.interest,
                interestDiterima: DataMaster.interestDiterima,
                jmlHari: DataInstrument.jumlahhari,
                kpdId: DataMaster.kpdId,
                kuponKe: DataMaster.kuponKe,
                kurs: DataMaster.kurs,
                noKonfirmasi: DataMaster.noKonfirmasi,
                pph42Final: DataMaster.pph42Final,
                produkId: DataMaster.produkId,
                rekCustodyId: DataMaster.rekCustodyId,
                rekPenerimaannId: DataMaster.rekPenerimaannId,
                selisihKuponInterest: DataInstrument.selisihKuponInterest,
                tglKonfirmasi: formatDate(DataMaster.tglKonfirmasi),
                tglPenerimaan: formatDate(DataMaster.tglPenerimaan),
                pokokDibayarkan: DataMaster.pokokDibayarkan
              }
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: data
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    render(){
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'75vw'}
                    height="77vh"
                    title={`Form Konfirmasi Penerimaan Kupon Interest`}
                    ref={this.PopupRef}
                    toolbarItems={this.props.action == 'edit' ? this.popupToolbarItem2 : this.popupToolbarItem1}
                >
                    <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                    <div className="row">
                        <div className={'col-md-12'}>
                            <div className="row">
                                <div className="col-md-4">
                                <Form
                                    ref={this.formMasterRef}
                                    colCount={1}
                                    id={'dataMaster'}
                                    formData={this.state.DataMaster}
                                    items={this.DataMaster}
                                    labelLocation={"left"}  
                                    readOnly={this.props.action == 'view' ? true : false}
                                />
                                <FileUploader
                                    disabled={this.props.action == 'view' ? true : false}
                                    accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                    uploadMode={"useForm"}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText="Upload File Here"
                                    showFileList={false}
                                    name={"file"}
                                    value={this.state.uploadedData}
                                    ref={this.fileUploderRef}
                                />
                                    <div className="row">
                                      <label className="col-4">File Name:</label>
                                      <label className="col-8">{ this.state.DataDocument.fileName}</label>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button onClick={() => this.previewFile(this.state.DataDocument,'file')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.fileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                            Preview
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <Form
                                        ref={this.formInstRef}
                                        colCount={1}
                                        id={'formMaster1'}
                                        formData={this.state.DataInstrument}
                                        items={this.DataInstrument}
                                        labelLocation={"left"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        
                    </ScrollView>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default ViewKuponKonfirmasi