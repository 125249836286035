import React, { Component } from 'react';
import { Popup,ScrollView,Form,FileUploader } from 'devextreme-react'
import { educationBackground, jabatan, programs, sdmUnit } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest' 
import notify from "devextreme/ui/notify";

import { confirmAlert } from 'react-confirm-alert'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalKandidatRekruitmen from './formKandidat';


class ModalKandidatRekruitmen extends Component {
    constructor(props){
        super(props)

        this.state={
            popupVisible:false,
            DataMaster:{}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.showModalAddKandidatReff = React.createRef()
        this.showModalEditKandidatReff = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        onClick : (e) => {
                            this.showModalEditKandidat(e.row.data)
                        }
                    },
                    
                    {
                        text : 'Batal',
                        hint : 'Batal',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin membatalkan data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.batal(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ],
            },
            // {
            //     dataField: 'programId',
            //     caption: 'Program',
            //     lookup:{
            //         dataSource: programs(this.props.store),
            //         valueExpr: 'id',
            //         displayExpr: 'program',
            //         searchEnabled: true,
            //     }
            // },
            {
                dataField: 'kodeRekurtmen',
                caption: 'Kode Rekruitmen',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'tempatLahir',
                caption: 'Tempat Lahir',
            },
            {
                dataField: 'email',
                caption: 'Email',
            },
            {
                dataField: 'noHp',
                caption: 'No Hp',
            },
            {
                dataField: 'tanggalLahir',
                caption: 'Tanggal Lahir',
                dataType:'date',
                format:"dd MMM yyyy"
            },
            {
                dataField: 'alamat',
                caption: 'Alamat',
            },
            {
                caption: 'Pendidikan',
                alignment:'center',
                columns:[
                    {
                        dataField:'edLembaga',
                        caption:'Lembaga'
                    },
                    {
                        dataField:'edLevelId',
                        caption:'Level',
                        lookup:{
                            dataSource: educationBackground(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'educationName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'edJurusan',
                        caption:'Jurusan'
                    },
                    {
                        dataField:'edTahunLulus',
                        caption:'Tahun Lulus',
                        dataType:'number',
                    },
                    {
                        dataField:'edIpk',
                        caption:'IPK',
                        dataType:'number',
                    },
                ]
            },
            {
                dataField: 'skor',
                caption: 'Skor',
            },
        ]
    }

    show() { 
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster:{}
        })
    }

    retrieveData = async(data) => {
        this.setState({
            DataMaster: data,
        })
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'rekurtmen-kandidats?size=99999', 'GET')
        response = response.filter(value => value.rekurtmenId == this.state.DataMaster.id)
        return response
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'plus',
                  text:'Tambah Kandidat',
                  onClick: (e) => {
                    this.showModalAddKandidat(e)
                  },
              }
          }
        )
    }

    showModalAddKandidat = () => {
        this.showModalAddKandidatReff.current.show()
        this.showModalAddKandidatReff.current.getRekruitmenId(this.state.DataMaster.id)
    }

    showModalEditKandidat = (data) => {
        this.showModalEditKandidatReff.current.show()
        this.showModalEditKandidatReff.current.retrieveData(data)
        this.showModalEditKandidatReff.current.getRekruitmenId(this.state.DataMaster.id)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    batal = async (data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'rekurtmen-kandidats', 'DELETE', data.id)
            this.forceRefresh()
            notify({message: "Sukses !!",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }

    render() {
        return (
            <React.Fragment>
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Daftar Kandidat"}
                width={"90vw"}
                height={"70vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                <ScrollView height="100%" width="100%">
                    <DevExpressDataGrid
                        ref = {this.dataGridRef}
                        loadAPI='rekurtmen?size=9999'
                        insertAPI='pengajuan-rapel'
                        updateAPI='pengajuan-rapel'
                        deleteAPI='pengajuan-rapel' 

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useArraySource = {true}
                        ArraySourceData= {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Rapel"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Rapel'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    </ScrollView>
                </Popup>
                <FormModalKandidatRekruitmen 
                    from={'add'}
                    ref={this.showModalAddKandidatReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalKandidatRekruitmen
                    from={'edit'}
                    ref={this.showModalEditKandidatReff}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </React.Fragment>
        )
    }
}

export default ModalKandidatRekruitmen