import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { mpType, pesertaById, product } from 'dataSource/lookup';
import ScrollView from 'devextreme-react/scroll-view';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import httpRequest from 'plugin/httprequest';
import { alert, confirm } from 'devextreme/ui/dialog'
import { formatDate, formatDefaultDate, formatDefaultMonthYear } from 'plugin/helper';

class ModalActionPotongan extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
        }        

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.kepersertaanProductId = 0
        this.pesertaId = 0

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                visible: this.props.actionType === 'lihat' ? false : true,
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        let formValidate = this.validate();
                        if (formValidate) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                caption: 'Peserta Pensiun',
                items: [
                    {
                        dataField: 'potonganNo',
                        label: {
                            alignment: 'left',
                            text: 'No Potongan'
                        },
                        editorOptions: {
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        }
                    },
                    {
                        dataField: 'productId',
                        label: {
                            alignment: 'left',
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: product(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'productCode', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        },
                    },
                    {
                        dataField: 'potonganDate',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Potongan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal potongan tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'transactionPeriode',
                        // dataField: 'potonganPeriodeBulanSampai',
                        label : {
                            text: 'Periode Pembayaran',
                            alignment: 'left', 
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'MMM yyyy',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            openOnFieldClick: true,
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Periode pembayaran tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'keterangan',
                        label: {
                            alignment: 'left',
                            text: 'Keterangan'
                        },
                        editorOptions: {
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Potongan',
                items: [
                    {
                        dataField: 'mpTypeId',
                        label: {
                            alignment: 'left',
                            text: 'Jenis Manfaat Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: mpType(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'mpName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        },
                    },
                    {
                        dataField: 'potonganPeriodeBulanDari',
                        label: {
                            alignment: 'left',
                            text: 'Periode Potongan Dari'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'MMM yyyy',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Periode potongan dari tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'potonganPeriodeBulanSampai',
                        label: {
                            alignment: 'left',
                            text: 'Periode Potongan Sampai'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'MMM yyyy',
                            calendarOptions: {
                                maxZoomLevel: 'year'
                            },
                            useMaskBehavior: true,
                            width: '100%',
                            readOnly: this.props.actionType === 'lihat' ? true : false
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Periode potongan sampai tidak boleh kosong'
                            }
                        ]
                    },
                    {
                        dataField: 'nominalMpSebelum',
                        label: {
                            alignment: 'left',
                            text: 'Total MP Sebelum Potongan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'nominalPotonganTotal',
                        label: {
                            alignment: 'left',
                            text: 'Total Nominal Potongan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        }
                    },
                    {
                        dataField: 'pajakSebelum',
                        label: {
                            alignment: 'left',
                            text: 'Pajak Sebelumnya'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'penguranganPajak',
                        label: {
                            alignment: 'left',
                            text: 'Pengurangan Pajak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        }
                    },
                    {
                        dataField: 'nominalSetelahPajak',
                        label: {
                            alignment: 'left',
                            text: 'Nominal Setelah Pajak'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: this.props.actionType === 'lihat' ? true : false,
                        }
                    }
                ]
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    show() {
        console.log(this.props.actionType);
        this.setState({
            popupVisible: true,         
        });
    }
    
    hide() {
        this.dropDownBoxRef.current.instance.option('value',"")
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    retrieveData = async(data) => {
        var loadAPI = `kepersertaan-products/${data.kepersertaanProductId}`
        var getKepersertaanProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        let loadAPIPotongan = `pembayaran-potongans/${data.pembayaranPotonganId}`
        let getPotongan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPotongan, 'GET');

        let transactionBulan = data.transactionPeriodeBulan;
        let trannsactionTahun = data.transactionPeriodeTahun;

        let periode = [transactionBulan, 1, trannsactionTahun].join('-');
        
        data.transactionPeriode = formatDefaultMonthYear(periode);
        
        this.kepersertaanProductId = getKepersertaanProduct.id
        this.pesertaId = getKepersertaanProduct.pesertaId
        this.dropDownBoxRef.current.instance.option('value',getKepersertaanProduct.pesertaId)
        this.setState({
            formData: data
        })       
    }

    submitData = async() => {
        var dataSubmit = {...this.state.formData}
        dataSubmit.kepersertaanProductId = this.kepersertaanProductId

        let actionType = this.props.actionType;
        let periodebulan = formatDate(dataSubmit.transactionPeriode);
        let splitPreiode = periodebulan.split('-');
        let transactionPeriodeBulan = parseInt(splitPreiode[1]);
        let transactionPeriodeTahun = parseInt(splitPreiode[0]);

        if (actionType !== 'add') {
            console.log('test1');
            await this.props.updateData(dataSubmit);
            this.hide();
        } else {
            console.log('test2');
            let loadAPI = `pengalihan-mps/getValidasiPotRapel/2/${transactionPeriodeBulan}/${transactionPeriodeTahun}/${dataSubmit.kepersertaanProductId}`;
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET');
    
            if (response[0].statusDescription !== null) {
                let result = await confirm(response[0].statusDescription, 'Konfirmasi');
                if (result) {
                    await this.props.submitData(dataSubmit)
                    this.hide()             
                }
            } else {
                await this.props.submitData(dataSubmit);
                this.hide();
            }
        }
    }

    onSelectionChanged = (e) => {
        this.kepersertaanProductId = e.selectedRowKeys[0]
        this.pesertaId = e.selectedRowsData[0].pesertaId
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowsData[0].pesertaId)
    }

    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI= {'view-lookup-kepersertaan-products?akunStatusId.in=2&akunStatusId.in=5&akunStatusId.in=7'}
                insertAPI='kepersertaan-product'
                updateAPI='kepersertaan-product'
                deleteAPI='kepersertaan-product'

                backendserver={process.env.REACT_APP_BACKEND_CORE}
                
                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Lookup Peserta Pensiun"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Lookup Peserta Pensiun'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Potongan Manfaat Pensiun'} 
                minWidth={500}
                minHeight={300}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid" style={{height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Peserta Pensiun</label>
                                <div className="col-sm-10">
                                    <DropDownBox
                                        ref={this.dropDownBoxRef}
                                        valueExpr="id"
                                        deferRendering={false}
                                        displayExpr={(item) => {
                                            return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                        }}
                                        showClearButton={true}
                                        dataSource={pesertaById(this.props.store)}
                                        onValueChanged={this.onValueChanged}
                                        contentRender={this.renderDatagrid}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataActionPotongan'}
                                formData={this.state.formData}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation = 'left'
                                readOnly = {this.props.actionType == 'view' ? true : false}
                            />
                        </div>
                    </div>

                </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ModalActionPotongan