import React, { Component } from 'react'
import httpRequest from 'plugin/httprequest'
import { Popup, Form } from 'devextreme-react'
import notify from 'devextreme/ui/notify';
import { confirm } from "devextreme/ui/dialog";

class UpdateNilaiWajar extends Component {
    constructor(props) {
        super(props)

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            dataRetrieve: {}
        }

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.form = [
            {
                dataField: "appraisalKjppName",
                label: {
                    text: "Nama KJPP",
                }
            },
            {
                dataField:'appraisalYear',
                label:{
                    text:'Tahun Apprisal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    step: 0
                }
            },
            {
                dataField: "nilaiWajar",
                label: {
                    text: "Nilai Wajar",
                },
                editorType: "dxNumberBox",
                editorOptions: {
                    format: "#,##0.00"
                }
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async (e) => {
                        var result = await confirm('Apakah anda yakin menyimpan data ini?', 'Konfirmasi')
                        if (result) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
    }

    retrieveData = async (data, type, getInstrumentId) => {
        if (type !== 'avg') {
            let getDetailProperty = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/${data.id}`)
    
            let getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.insturmentId}`, 'GET')
    
            this.setState({
                dataRetrieve: getDetailProperty,
                dataMaster: {
                    ...getInstrument,
                    appraisalKjppName: getInstrument.appraisalKjppName,
                    appraisalYear: getInstrument.appraisalYear,
                    nilaiWajar: getDetailProperty.price
                }
            })
        } else {
            let getDetailProperty = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates?instrumentId.equals=${getInstrumentId}`)
            let getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${getInstrumentId}`, 'GET')

            this.setState({
                dataRetrieve: getDetailProperty[0],
                dataMaster: {
                    ...getInstrument,
                    appraisalKjppName: getInstrument.appraisalKjppName,
                    appraisalYear: getInstrument.appraisalYear,
                    nilaiWajar: getDetailProperty[0].price
                }
            })
        }
    }


    submitData = async () => {
        try {
            let dataRetrieve = this.state.dataRetrieve;
            let dataMaster = this.state.dataMaster;

            dataRetrieve.price = dataMaster.nilaiWajar

            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates`, 'PUT', {
                key: dataRetrieve.id,
                values: dataRetrieve,
            }, dataRetrieve)

            if (response) {
                let dataValue = {
                    ...dataMaster,
                    appraisalValue: dataMaster.nilaiWajar,
                    appraisalYear: dataMaster.appraisalYear,
                    appraisalKjppName: dataMaster.appraisalKjppName
                }
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments`, 'PUT', {
                    key: dataRetrieve.instrumentId,
                    values: dataValue
                }, dataValue)

                notify({ message: 'Sukses Update Nilai', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.hide()
                this.props.forceRefresh()
            }

        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            dataRetrieve: {}
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Update Nilai Wajar Properti'}
                width={'50vh'}
                height={'40vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <Form
                    colCount={1}
                    id={"formAnggaran"}
                    formData={this.state.dataMaster}
                    items={this.form}
                    ref={this.formRef}
                    labelLocation="left"
                // readOnly = {true}
                />
            </Popup>
        )
    }
}

export default UpdateNilaiWajar