import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { Form } from "devextreme-react";
import { months, product } from 'dataSource/lookup'
import ModalDaftarUlang from 'components/pages/modal/kepesertaan/pengkinianPeserta/pasif/daftar-ulang/modalDaftarUlang'
import ShowModalPengkinianPesertaProsesDaftarUlang from 'components/pages/modal/kepesertaan/pengkinianPeserta/pasif/daftar-ulang/prosesDaftarUlang'
import ModalCloseDaftarUlang from 'components/pages/modal/kepesertaan/pengkinianPeserta/pasif/daftar-ulang/close';

class DaftarUlangPengkinianPasif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            productId: '',
        }
        this.ModalProsesDaftarUlangRef = React.createRef()
        this.ModalCloseDaftarUlangRef = React.createRef()
        this.ModalDaftarUlangRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    location: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
            }
        ]
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    // {
                    //     text: 'Ubah',
                    //     hint: 'Ubah',
                    //     onClick: () => {
                    //         console.log('ubah')
                    //     }
                    // },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        onClick: (e) => {
                            this.showModalProsesDaftarUlang(e.row.data)
                        }
                    },
                    {
                        text: 'Tutup',
                        hint: 'Tutup',
                        onClick: (e) => {
                            this.showModalClose(e.row.data)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: () => {
                            console.log('ubah')
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'ID',
                cellRender : (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productName'
                }
            },
            {
                dataField: 'periodeBulan',
                caption: 'Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'periodeTahun',
                caption: 'Tahun',
            },
            {
                dataField: 'pengkinianTanggal',
                caption: 'Tanggal Awal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalAkhir',
                caption: 'Tanggal Akhir Terima Data',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'jumlahPasif',
                caption: 'Jumlah Peserta Pasif',
            },
            {
                dataField: 'jumlahDataMasuk',
                caption: 'Jumlah Data Masuk',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'dataMasukKonvensional',
                        caption: 'Konvensional'
                    },
                    {
                        dataField: 'dataMasukOnline',
                        caption: 'Online'
                    },
                ]
            },
            {
                dataField: 'jumlahDataProses',
                caption: 'Jumlah Data Proses',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'dataProsesKonvensional',
                        caption: 'Konvensional'
                    },
                    {
                        dataField: 'dataProsesOnline',
                        caption: 'Online'
                    },
                ]
            },
            {
                dataField: 'belumDaftarUlang',
                caption: 'Jumlah Peserta Belum Di Proses'
            }
        ]
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Daftar Ulang',
                    onClick: (e) => {
                        this.showModalDaftarUlang(e)
                    }
                }
            }
        )
    }

    showModalDaftarUlang = () => {
        this.ModalDaftarUlangRef.current.show()
    }
    
    showModalProsesDaftarUlang = (data) => {
        this.ModalProsesDaftarUlangRef.current.show()
        this.ModalProsesDaftarUlangRef.current.retrieveData(data)
    }

    showModalClose = (data) => {
        this.ModalCloseDaftarUlangRef.current.show()
        this.ModalCloseDaftarUlangRef.current.retrieveData(data)
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Ulang</h2>
                <Form
                    colCount={3}
                    id={'formProductId'}
                    formData={this.state.productId}
                    items={this.filterItem}
                />
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-daftar-ulang-peserta-pasifs'
                    insertAPI='products'
                    updateAPI='products'
                    deleteAPI='products'

                    remoteOperations = {true}
                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Daftar Ulang"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Ulang Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                 <ShowModalPengkinianPesertaProsesDaftarUlang
                    ref={this.ModalProsesDaftarUlangRef}
                    forceRefresh = {this.forceRefresh}
                    store={this.props.store}
                 />
                 <ModalDaftarUlang
                    ref={this.ModalDaftarUlangRef}
                    forceRefresh = {this.forceRefresh}
                    store={this.props.store}
                 />
                 <ModalCloseDaftarUlang
                    ref={this.ModalCloseDaftarUlangRef}
                    forceRefresh = {this.forceRefresh}
                    store={this.props.store}
                 />
            </div>
        )
    }
}

export default DaftarUlangPengkinianPasif