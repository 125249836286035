import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import { anggaranItem, sdmUnit } from 'dataSource/lookup';
import DetailRealisasiBudgetPerPeriode from './detailRealisasiPerPeriode';
import { alert } from 'devextreme/ui/dialog';

class DetailRealisasiBudget extends Component {
    constructor(props) {
        super(props);
        this.state = {
          popupVisible: false,
          formData: {},
        };
    
        this.form = [
            {
                itemType: 'group',
                name: 'basicFilter',
                items: [
                    {
                        dataField: 'tahun',
                        label:{
                            alignment:"left",
                            text:"Tahun Anggaran"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'status',
                        label:{
                            alignment:"left",
                            text:"Status"
                        },
                        editorType:"dxSelectBox",
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 'N',
                                    value: 'Non Aktif'
                                },
                                {
                                    id: 'A',
                                    value: 'Aktif'
                                }
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            readOnly: true
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: 'tanggal',
                                label: {
                                    text: 'Tanggal Realisasi'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                },
                            },
                            {
                                dataField: 'filter',
                                label: {
                                    visible: false,
                                },
                                editorType: 'dxButton',
                                editorOptions: {
                                    text: 'Filter',
                                    onClick: async(e) => {
                                        let filter = this.state.formData
                                        let tanggal = filter.tanggal ? filter.tanggal.split('-') : null
                                        if(tanggal[0] == filter.tahun) {
                                            this.filterData()
                                        } else {
                                            await alert('Tanggal melebihi batas tahun anggaran!', 'Informasi')
                                        }
                                    }
                                }
                            }
                        ]
                    },
                ]
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail Periode',
                        hint : 'Detail Periode',
                        onClick : (e) => {
                            this.detailRealisasiPerPeriodeRef.current.show()
                            this.detailRealisasiPerPeriodeRef.current.retrieveData(e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'anggaranItemId',
                caption: 'Anggaran',
                lookup : {
                    dataSource: anggaranItem(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (item) => {
                        return item && item.anggaranCode + ' - ' + item.anggaranName
                    },
                }
            },
            {
                dataField: 'unitId',
                caption: 'Unit',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'unitName',
                }
            },
            {   
                dataField: 'nominalRealisasi',
                caption: 'Anggaran',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'nominalEfektif',
                caption: 'Realisasi',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField:'selisih',
                caption: 'Sisa Anggaran',
                alignment: 'left',
                columns: [
                    {
                        dataField: 'nominalSelisih',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                    {
                        dataField: 'persenSelisih',
                        caption: '(%)',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right',
                    },
                ]
            },
        ]
    
        this.PopupToolbarItem = [
        //   {
        //     widget: "dxButton",
        //     location: "after",
        //     options: {
        //       text: "Ok",
        //       onClick: async () => {
        //         this.submitData()
        //       }
        //     },
        //     toolbar: "bottom"
        //   },
          {
            widget: "dxButton",
            location: "after",
            options: {
              text: "Tutup",
              onClick: this.hide.bind(this)
            },
            toolbar: "bottom"
          }
        ];
    
        this.formRef = React.createRef()
        this.datagridRef = React.createRef()
        this.detailRealisasiPerPeriodeRef = React.createRef()
        this.filterDataAnggaran = []
    }

    retrieveData = (data = null) => {
        this.formRef.current.instance.updateData({
            'tanggal': data.tahun+'-12-01',
            'status': data.status,
            'tahun': data.tahun
        })
        this.formRef.current.instance.getEditor('tanggal').option({
            min: data.tahun+'-01-01',
            max: data.tahun+'-12-31'
        })

        this.forceRefresh()
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
          popupVisible: false,
          formData: {},
        });
        this.filterDataAnggaran = []    
    };

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    filterData = async () => {
        var result = [];
        let dataState = this.state.formData;
        let loadAPI = `anggaran/getRealisasiAnggaran/${dataState.tahun}/1/${(dataState.tanggal)}`;

        let response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, loadAPI, 'GET');

        for (let value of response) {
            let dataValue = {
                ...value,
                anggaranItemId: value.anggaranItemId,
                unitId: value.unit_id,
                nominalRealisasi: value.nominalEfektif,
                nominalEfektif: value.nominal_realisasi,
                nominalSelisih: value.sisa_anggaran,
                persenSelisih: value.nominalEfektif === 0 ? 0 : (value.nominalEfektif - value.nominal_realisasi < 0 ? 0 : (value.nominalEfektif - value.nominal_realisasi) / value.nominalEfektif * 100)
            }
            result.push(dataValue);
        }
        
        this.filterDataAnggaran = result
        this.forceRefresh()
    }

    loadData = () => {
        return this.filterDataAnggaran
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                resizeEnabled = {true}
                showTitle={true}
                title="Detail Realisasi Anggaran"
                width={"90vw"}
                height={"auto"}
                toolbarItems={this.PopupToolbarItem}
                > 
                    <div className="mb-3">
                        <Form
                            colCount={1}
                            id={"formCOA"}
                            formData={this.state.formData}
                            items={this.form}
                            ref={this.formRef}
                            labelLocation="left"
                        />
                        
                        <DevExpressDataGrid
                            ref= {this.datagridRef}
                            loadAPI='anggarans'
                            insertAPI='anggarans'
                            updateAPI='anggarans'
                            deleteAPI='anggarans' 

                            backendserver={process.env.REACT_APP_BACKEND_ACC}

                            useArraySource = {true}
                            ArraySourceData = {this.loadData}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            exportExcel={true}
                            exportFileName={`Detail Realisasi Anggaran Tahun ${this.state.formData.tahun}`}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            exportWithFormatNum={true}
                            exceptFieldExportFormtNum={
                                [
                                    'nominalEfektif',
                                    'nominalRealisasi',
                                    'nominalSelisih',
                                    'persenSelisih'
                                ]
                            }

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Detail Anggaran Data'}
                            popupWidth={'70vw'} //masukan dalam ukuran pixel
                            popupHeight={'400px'} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </Popup>
                <DetailRealisasiBudgetPerPeriode ref={this.detailRealisasiPerPeriodeRef} store={this.props.store} />
            </React.Fragment>
        )
    }
}

export default DetailRealisasiBudget