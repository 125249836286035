import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import uuidv4  from 'uuid/v4'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { dasarNilai, tipeTransaksiSdmUmum, tunjanganKategori, tunjanganGroup, akunHR } from 'dataSource/lookup';
import FormModalTunjangan from 'components/pages/modal/sdm-umum/pengaturan/tunjangan/form';
import { confirm } from 'devextreme/ui/dialog'
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';

class Tunjangan extends Component {
    constructor(props){
        super(props)

        this.state = {
            // selectedTabIndex: 0,
        }

        this.tunjanganColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalTunjangan(e)
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : async (e) => {
                            let data = e.row.data
                            let result = await confirm("Apakah anda yakin ingin menghapus data ini?", "Konfirmasi");

                            if (result) {
                                this.delete(data)
                            }
                        }
                    },
                ],
            },
            {
                dataField:"tunjanganCode",
                caption:"Kode"
            },
            {
                dataField:"tunjanganName",
                caption:"Tunjangan"
            },
            {
                dataField:"tunjanganCategoryId",
                caption:"Kategori",
                lookup:{
                    dataSource: tunjanganKategori(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'categoryName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"tunjanganGroupId",
                caption:"Group",
                lookup:{
                    dataSource: tunjanganGroup(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'groupName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"akunId",
                caption:"Akun",
                lookup:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField:"dasarNilaiId",
                caption:"Dasar Nilai Tunjangan",
                alignment:'center',
                columns:[
                    {
                        dataField:"dasarNilaiId",
                        caption:"Dasar Nilai",
                        lookup:{
                            dataSource: dasarNilai(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'dasarNilai', // provides display values
                            deferRendering: false,
                        },
                    },
                    {
                        dataField:"percentValue",
                        caption:"Persentase",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00 %'
                    },
                    {
                        dataField:"nominal",
                        caption:"Nominal",
                        alignment:'right',
                        dataType:'number',
                        format:'#,##0.00'
                    },
                ]
            },
            {
                dataField:"status",
                caption:"Status",
                dataType:'boolean'
            }
        ]

        this.DataGridTunjanganRef = React.createRef()
        this.showModalTunjanganRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    delete = async (data) => {
        let id = data.id
        if(id) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'tunjangan-types', 'DELETE', id)
                this.forceRefresh()
                notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success', 1000);
            } catch (e) {
                notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error', 1000);
            }
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalTunjangan(e)
                    },
                }
            }
        )
    }

    showModalTunjangan = (e) => {
        if(e.row){
            this.showModalTunjanganRef.current.retrieveData(e.row.data)
        }
        this.showModalTunjanganRef.current.show()
    }

    forceRefresh = () => {
        this.DataGridTunjanganRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Tunjangan</h3>
                <div id="tabindex-0" className={' mt-2 text-center' }  style={{height: '100%'}}>
                    <DevExpressDataGrid
                        ref = {this.DataGridTunjanganRef}
                        loadAPI='tunjangan-types?size=9999'
                        insertAPI='tunjangan-types'
                        updateAPI='tunjangan-types'
                        deleteAPI='tunjangan-types' 

                        backendserver={process.env.REACT_APP_BACKEND_HRD}


                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Tunjangan"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Tunjangan'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.tunjanganColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(100vh - 300px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <FormModalTunjangan 
                        ref={this.showModalTunjanganRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    />
                </div>
            </div>
        )
    }
}

export default Tunjangan