import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { currency, banks, instrumentSubCategoryDeposito } from 'dataSource/lookup';
import { summaryDate, formatDefaultDate } from 'plugin/helper';

class DetailPenempatanDeposito extends Component {
    constructor(props) {
        super(props)
        this.dataSource = new CustomStore({
            load: async (loadOptions) => {
                var requestId = this.props.detailData
                var loadAPI = `order-requests-deposito/${requestId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
                return response.orderDepositoInfos
            },
            key: 'orderDTO.orderRequestId',
            insert: (values) => {
            },
            update: (key, values) => {

            },
            remove: (key) => {

            }
        })

        this.dataGridDetalRef = React.createRef()
        this.columns = [
            {
                dataField: 'orderDTO.categorySubId',
                caption: 'Deposito',
                lookup: {
                    dataSource: instrumentSubCategoryDeposito(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName'
                }
            },
            {
                dataField: 'orderDepositoDTO.bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'orderDepositoDTO.currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName'
                }
            },
            {
                dataField: 'orderDepositoDTO.kurs',
                caption: 'Kurs',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'orderDepositoDTO.amountOrder',
                caption: 'Nilai Penempatan',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'orderDepositoDTO.rateOrder',
                caption: 'Rate (%)',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },
            {
                dataField: 'orderDepositoDTO.placementDate',
                caption: 'Tanggal Penempatan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'orderDepositoDTO.tenor',
                caption: 'Tenor',
                cellRender: (e) => {
                    var data = e.row.data.orderDepositoDTO
                    return data.tenor + ' ' + data.tenorTypeId
                }
            },
            {
                dataField: 'orderDepositoDTO.maturityDate',
                caption: 'Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]
    }

    getDataSource = () => {
        this.dataGridDetalRef.current.getDataSource()
    }
    render() {
        return (
            <DataGrid
                id={"successDataGrid"}
                ref={this.dataGridDetalRef}
                showBorders={false}
                dataSource={this.dataSource}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                // height={100}
                showScrollbar={"always"}
            // columns={this.columns}
            >
                {
                    this.columns.map((column) => {
                        return <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            lookup={column.lookup}
                            cellRender={column.cellRender}
                            alignment={column.alignment || 'left'}
                            cssClass={column.cssClass}
                            format={column.format}
                            dataType={column.dataType}
                            width={column.width}
                            type={column.type}
                            buttons={column.buttons}
                            editorType={column.editorType}
                            editorOptions={column.editorOptions}
                            visible={column.visible}
                            sortOrder={column.sortOrder}
                            allowEditing={column.allowEditing}
                            calculateCellValue={column.calculateCellValue}
                        />
                    })
                }
                {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailPenempatanDeposito