import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalPengajuanPenghapusanAset from 'components/pages/modal/sdm-umum/umum/penghapusan/pengajuanPenghapusan';
import { addURL } from 'redux/actions/url';
import notify from "devextreme/ui/notify";
import {confirm} from "devextreme/ui/dialog";
import httpRequest from 'plugin/httprequest';
import { entity } from 'dataSource/lookup';
import { getSystemDate, monthDiff } from 'plugin/helper';

class PengajuanPenghapusanAset extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalPengajuanPenghapusanAset(e, false)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            const result = await confirm("Apakah anda yakin ingin membatalkan pengajuan data ini?","Konfirmasi")
                            if(result){
                                this.batal(e.row.data)
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: async (e) => {
                            const result = await confirm("Apakah anda yakin ingin mengirim pengajuan data ini?","Konfirmasi")
                            if(result){
                                this.kirim(e.row.data)
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField:'pengajuanNo',
                caption:'No Pengajuan'
            },
            {
                dataField:'assetKode',
                caption:'Kode Aset'
            },
            {
                dataField:'assetName',
                caption:'Nama Aset'
            },
            {
                dataField:'requestBy',
                caption:'Diajukan Oleh'
            },
            {
                dataField:'requestDate',
                caption:'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'keterangan',
                caption:'Keterangan Persetujuan'
            },
            {
                dataField:'disposalTypeId',
                caption:'Tipe Penghapusan',
                lookup:{
                    dataSource: [
                        {
                            id: 1,
                            value: 'Penjualan',
                        },
                        {
                            id: 2,
                            value: 'Rusak Permanen',
                        },
                        {
                            id: 3,
                            value: 'Hilang',
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            },
            {
                dataField: 'aset',
                caption: 'Aset',
                alignment:'center',
                columns:[
                    {
                        dataField:'categoryName',
                        caption:'Kategori'
                    },
                    {
                        dataField:'categorySubName',
                        caption:'Sub Kategori'
                    },
                    {
                        dataField:'merk',
                        caption:'Merk'
                    },
                    {
                        dataField:'spesifikasi',
                        caption:'Spesifikasi Aset'
                    },
                    {
                        dataField:'tanggalPerolehan',
                        caption:'Tanggal Perolehan'
                    },
                    {
                        dataField:'nilaiPerolehan',
                        caption:'Nominal Pembelian'
                    },
                ]
            },
            {
                dataField:'pembeli',
                caption:'Pembeli',
                lookup:{
                    dataSource: entity(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'nilaiEkonomi',
                caption: 'Nilai Ekonomi',
                alignment:'center',
                columns:[
                    {
                        dataField:'umurAset',
                        caption:'Umur Aset'
                    },
                    {
                        dataField:'nilaiBuku',
                        caption:'Nilai Buku'
                    },
                ]
            },
            {
                dataField:'keterangan',
                caption:'Keterangan'
            },
        ]

        this.showModalPengajuanPenghapusanAsetRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengajuanPenghapusanAset = (e, type) => {
        if (e.row) {
            this.showModalPengajuanPenghapusanAsetRef.current.retrieveData(e.row.data, type)
        }
        this.showModalPengajuanPenghapusanAsetRef.current.show()
        // this.showModalPengajuanPenghapusanAsetRef.current.getEntityAcc()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPengajuanPenghapusanAset(e)
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    
    kirim = async (data) => {
        try {
            var getAssetDisposal = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-disposals/${data.id}`, 'GET')
        
            getAssetDisposal.statusId = 2
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-disposals', 'PUT', {
                values: getAssetDisposal,
                key:getAssetDisposal.id
            },getAssetDisposal)

            if (response) {
                this.forceRefresh()
                notify({ message: "Pengajuan Penghapusan Aset berhasil dikirim!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    batal = async (data) => {
        try {
            var getAssetDisposal = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-disposals/${data.id}`, 'GET')
        
            getAssetDisposal.statusId = -1
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-disposals', 'PUT', {
                values: getAssetDisposal,
                key:getAssetDisposal.id
            },getAssetDisposal)

            if (response) {
                this.forceRefresh()
                notify({ message: "Pengajuan Penghapusan Aset berhasil dibatalkan !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-penghapusan-aset-pengajuans', 'GET')
        response = response.filter(value => value.statusId == 1)

        var data = []

        for(let value of response) {
            data.push({
                ...value,
                umurAset: monthDiff(new Date(getSystemDate(this.props.store)), new Date(value.tanggalPerolehan)) + ' Bulan',
            })
        }
        
        return data
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Penghapusan Aset</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='daftar-penagjuan-pengadaan'
                    insertAPI='daftar-penagjuan-pengadaan'
                    updateAPI='daftar-penagjuan-pengadaan'
                    deleteAPI='daftar-penagjuan-pengadaan'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Penghapusan Aset"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Penghapusan Aset'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPengajuanPenghapusanAset 
                    ref={this.showModalPengajuanPenghapusanAsetRef}
                    store={this.props.store}
                    type={'pengajuan'}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PengajuanPenghapusanAset