import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormSuratKeputusanKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/surat-keputusan/form';
import { SKTypeHRD, karyawan } from 'dataSource/lookup';

class SuratKeputusanKaryawan extends Component {
    constructor(props){
        super(props)
        this.showModalSkKaryawanRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalSkKaryawan(e,'ubah')
                        }
                    },
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalSkKaryawan(e,'lihat')
                        }
                    }
                ]
            },
            {
                dataField: 'skNo',
                caption: 'No. SK',
            },
            {
                dataField: 'karyawanId',
                caption: 'Karyawan',
                cellRender: (e) => {
                    var data = e.data
                    if(data.karyawanId){
                        var result = `NIK : ${data.nik} - ${data.fullName}`
                    }else{
                        var result = ""
                    }

                    return result
                }
            },
            {
                dataField: 'skTypeId',
                caption: 'Jenis SK',
                lookup:{
                    dataSource: SKTypeHRD(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'typeName' 
                }
            },
            {
                dataField: 'skDate',
                caption: 'Tanggal SK',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'efektifDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    showModalSkKaryawan = (e,type) => {
        if (e.row) {
            if(type === 'ubah'){
                this.showModalSkKaryawanRef.current.retrieveData(e.row.data, type)
            }else if(type === 'lihat'){
                this.showModalSkKaryawanRef.current.retrieveData(e.row.data, type)
            }
        }
        this.showModalSkKaryawanRef.current.show()
        
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalSkKaryawan(e)
                    },
                }
            }
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Surat Keputusan Karyawan</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-karyawan-sks'
                    insertAPI='karyawan-sks'
                    updateAPI='karyawan-sks'
                    deleteAPI='karyawan-sks' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    keyField = {'karyawanSkId'}
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar SK Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormSuratKeputusanKaryawan
                    ref={this.showModalSkKaryawanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default SuratKeputusanKaryawan