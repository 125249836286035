import React, { Component } from 'react'
import { Popup,ScrollView, Form } from 'devextreme-react'
import { lemburBiaya, tipeLembur } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

export default class FormTambahTanggalLembur extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster: {},
            newData:true
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.disableEvent = false
        this.golJabatanId = 0
        this.gapok = 0
        this.karyawanId = 0

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: () => {
                    this.props.getDataSource(this.state.DataMaster)
                    this.hide()
                  }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];
        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.formMaster = [
            {
                dataField: 'tglLembur',
                label: {
                    text: 'Tanggal Lembur'
                },
                editorType: 'dxDateBox',
                editorOptions:{
                    displayFormat: 'dd MMM yyyy',
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    // onValueChanged:(e) => {
                    //     if (e.value) {
                    //         let date = new Date(e.value)
                    //         if (date.getDay() === 6 || date.getDay() === 0) {
                    //             this.formRef.current.instance.getEditor('lemburType').option('value', 1)
                    //         }else{
                    //             this.formRef.current.instance.getEditor('lemburType').option('value', 0)
                    //         }
                    //     }
                    // }
                }
            },
            {
                dataField: 'lemburType',
                label: {
                    text: 'Tipe Lembur'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: tipeLembur(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'lemburName', // provides display values
                    // readOnly:true
                    // onValueChanged: async(e) => {
                    //     if (e.value) {
                    //         var getLemburBiaya = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `lembur-biayas`, 'GET')
                    //         var filterLemburBiaya = getLemburBiaya.filter(value => value.jabatanGolonganId == this.golJabatanId && value.lemburTypeId == e.value)
                    //         this.formRef.current.instance.getEditor('dasarPerhitungan').option('dataSource', filterLemburBiaya)
                    //     }
                    // }
                },
            },
            {
                dataField:"jamDari",
                label: {
                    text: 'Jam Awal'
                },
                editorType:"dxDateBox",
                editorOptions:{
                    type: "time",
                    onValueChanged:(e) => {
                        if (e.value) {
                            this.differentTime(e.value,this.date2)
                            this.date1 = e.value
                        }
                    },
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat: 'HH:mm',
                    pickerType:"rollers"
                }
            },
            {
                dataField:"jamSampai",
                label: {
                    text: 'Jam Akhir'
                },
                editorType:"dxDateBox",
                editorOptions:{
                    type: "time",
                    onValueChanged:(e) => {
                        if (e.value) {
                            this.differentTime(this.date1,e.value)
                            this.date2 = e.value
                        }
                    },
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat: 'HH:mm',
                    pickerType:"rollers"
                } 
            },
            {
                dataField: 'jamJumlah',
                label: {
                    text: 'Durasi Lembur(Jam)'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    readOnly:true,
                    onValueChanged: async(e) => {
                        if (e.value) {
                            let getlemburType = this.formRef.current.instance.getEditor('lemburType').option('value')
                            let weekdays = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-lembur/perhitunganLemburWeekdays/${this.karyawanId}/${e.value}`, 'GET')
                            let weekends = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-lembur/perhitunganLemburWeekends/${this.karyawanId}/${e.value}`, 'GET')
                            if (getlemburType == 0) {
                                this.formRef.current.instance.getEditor('uangLembur').option('value', weekdays)  
                            }else{
                                this.formRef.current.instance.getEditor('uangLembur').option('value', weekends)  
                            }
                            var calcTarifLembur = (1 / 175) * this.gapok 
                            this.formRef.current.instance.getEditor('tarifLembur').option('value', calcTarifLembur)               
                        }
                    }
                }
            },
            // {
            //     dataField: 'dasarPerhitungan',
            //     label: {
            //         text: 'Dasar Perhitungan'
            //     },
            //     editorType: 'dxSelectBox',
            //     editorOptions: {
            //         // dataSource: lemburBiaya(this.props.store),
            //         dataSource: [],
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'dasarPerhitungan', // provides display values
            //         onSelectionChanged:(e) => {
            //             if (e.selectedItem) {
            //                 var DataMaster = {...this.state.DataMaster}
            //                 if (e.selectedItem.dasarPerhitungan == 'hari') {
            //                     DataMaster.tarifLembur = e.selectedItem.uangLembur
            //                     DataMaster.uangLembur = e.selectedItem.uangLembur
            //                 }else{
            //                     DataMaster.tarifLembur = e.selectedItem.uangLembur
            //                     DataMaster.uangLembur = e.selectedItem.uangLembur * DataMaster.jamJumlah
            //                 }

            //                 this.formRef.current.instance.updateData(DataMaster)
            //             }else{
            //                 this.formRef.current.instance.updateData({tarifLembur:0,uangLembur:0})
            //             }
            //         }
            //     },
            // },
            
            {
                dataField: 'tarifLembur',
                label: {
                    text: 'Tarif Lembur'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    format:"#,##0.00",
                    readOnly:true
                }
            },
            {
                dataField: 'uangLembur',
                label: {
                    text: 'Uang Lembur'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    format:"#,##0.00",
                    readOnly:true
                }
            },
            {
                dataField: 'aktivitas',
                label: {
                    text: 'Aktifitas'
                },
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Katerangan'
                },
                editorType:'dxTextArea',
                editorOptions:{
                    height:100
                }
            },
        ]
    }

    newData = (data) => {
        this.setState({
            newData:data
        })
    }

    getJabatanGolId = (data) => {
        this.golJabatanId = data
    }

    getDataGapok = (data) => {
        this.gapok = data
    }

    getDataKaryawanId = (data) => {
        this.karyawanId = data
    }

    differentTime = (date1, date2) => {
        if(date1 && date2){
            var diffDate1 = date1
            var diffDate2 = date2
            if(diffDate1.getDate() < diffDate2.getDate()){
                diffDate2.setDate(diffDate2.getDate() - 1);
            }
            if(diffDate1.getHours() >= diffDate2.getHours()){
                diffDate2.setDate(diffDate2.getDate() + 1);
            }
            diffDate1.setSeconds(0)
            diffDate2.setSeconds(0)
            var DataMaster = {...this.state.DataMaster}
            var total = diffDate2.getTime() - diffDate1.getTime()
            var diff = total / (1000 * 3600);
            DataMaster.jamJumlah = Math.round(diff)
            this.setState({
                DataMaster:DataMaster
            })
        }
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.golJabatanId = 0 
        this.gapok = 0 
        this.karyawanId = 0 
        this.setState({
            popupVisible: false,
            DataMaster: {},
            newData:true
        })
    }
 
    retrieveData = async(data) => {
        data.dasarPerhitungan = parseInt(data.dasarPerhitungan)
        data.jamDari = new Date(data.jamDari)
        data.jamSampai = new Date(data.jamSampai)
        this.setState({
            DataMaster : data
        })
    }

    render() {
        return (
            <div>
                <Popup
                    className={"popup"}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    showTitle={true}
                    title={"Tanggal Lembur"}
                    width={"35vw"}
                    height={"65vh"}
                    toolbarItems={this.readOnly ? [] : this.state.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <ScrollView height="100%" width="100%">
                        <div className='container-fluid'>
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formPengajuanLembur'}
                                formData={this.state.DataMaster}
                                items={this.formMaster}
                                labelLocation = 'left'
                                readOnly={!this.state.newData}
                            />
                        </div>
                    </ScrollView>
                </Popup>
            </div>
        )
    }
}
