import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { tipeDokumen, karyawan } from 'dataSource/lookup';
import FormGajiKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/gaji/form';
import FormDokumenKaryawan from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/dokumen/form';

class DokumenKaryawan extends Component {
    constructor(props){
        super(props)
        this.showModalKaryawanDokumenRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalKaryawanDokumen(e,'ubah')
                        }
                    },
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalKaryawanDokumen(e,'lihat')
                        }
                    }
                ]
            },
            {
                dataField: 'dokumenNo',
                caption: 'No. Dokumen',
            },
            {
                dataField: 'karyawanId',
                caption: 'Nama Karyawan',
                // cellRender: (e) => {
                //     var data = e.data
                //     if(data.karyawanId){
                //         return `NIK : ${data.nik}  - ${data.fullName}`
                //     }else{
                //         return ''
                //     }
                // },
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'dokumenTypeId',
                caption: 'Tipe Dokumen',
                lookup: {
                    dataSource: tipeDokumen(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'dokumenName',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField: 'dokumenDate',
                caption: 'Tanggal Dokumen',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true);
    };

    showModalKaryawanDokumen = (e,type) => {
        if (e.row) {
            if (type === "ubah") {
                this.showModalKaryawanDokumenRef.current.retrieveData(e.row.data)
                this.showModalKaryawanDokumenRef.current.fieldReadOnly(type)
            }else if(type === "lihat"){
                this.showModalKaryawanDokumenRef.current.retrieveData(e.row.data)
                this.showModalKaryawanDokumenRef.current.fieldReadOnly(type)
            }
            
        }
        this.showModalKaryawanDokumenRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalKaryawanDokumen(e)
                    },
                }
            }
        )
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Dokumen Karyawan</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='view-karyawan-dokumen'
                    insertAPI='karyawan-dokumen'
                    updateAPI='karyawan-dokumen'
                    deleteAPI='karyawan-dokumen' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    keyField={'karyawanDokumenId'}
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Dokumen Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormDokumenKaryawan
                    ref={this.showModalKaryawanDokumenRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default DokumenKaryawan