import React, {Component} from 'react'
import { Popup,ScrollView, Form, FileUploader } from 'devextreme-react'
import { instrumentPortoSaham, currency, instrumentCategories, 
    instrumentPortoReksadana, instrumentPortoDeposito, instrumentPortoCorporate, instrumentPortoSuratUtang, instrumentPortoEba, 
    instrumentPortoProperti, instrumentPortoEtf, 
    productDefault, instrumentPortoRepo, instrumentPortoNonPortoLainnya,instruments,
    instrumentPortoCurrentAccount, instrumentPortoSbn
} from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDate, formatUploadFileData } from 'plugin/helper'
import notify from "devextreme/ui/notify";

class FormAddInstrumentPemindahanEfek extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{
                plPengelolaAwal:0
            },
            popupVisible: false,
        }

        this.DataMaster = [
            {
                itemType: 'group',
                name:"instrument",
                caption:'Instrument',
                items:[
                    {
                        dataField:"instrKategoriId",
                        label:{
                            text:'Kategori'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: instrumentCategories(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'categoryName',
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    var dataSource ,displayName1, displayName2
                                    switch (e.selectedItem.categoryName) {
                                        case 'Saham':
                                            dataSource = instrumentPortoSaham(this.props.store, this.props.productId)
                                            displayName1 = 'saham'
                                            break;
                                        case 'Reksadana':
                                            dataSource = instrumentPortoReksadana(this.props.store, this.props.productId)
                                            displayName1 = 'kodeReksa'
                                            displayName2 = 'namaReksa'
                                            break;
                                        case 'Deposito':
                                            dataSource = instrumentPortoDeposito(this.props.store, this.props.productId)
                                            displayName1 = 'noBilyet'
                                            displayName2 = 'jenisDeposito'
                                            break;
                                        case 'SBN':
                                            dataSource = instrumentPortoSbn(this.props.store, this.props.productId)
                                            displayName1 = 'kodeSbn'
                                            displayName2 = 'namaSbn'
                                            break;
                                        case 'Corporate Bond':
                                            dataSource = instrumentPortoCorporate(this.props.store, this.props.productId)
                                            displayName1 = 'kodeObligasi'
                                            displayName2 = 'namaObligasi'
                                            break;
                                        case 'Surat Hutang':
                                            dataSource = instrumentPortoSuratUtang(this.props.store, this.props.productId)
                                            displayName1 = 'noBilyet'
                                            displayName2 = 'jenisDeposito'
                                            break;
                                        case 'EBA':
                                            dataSource = instrumentPortoEba(this.props.store, this.props.productId)
                                            displayName1 = 'kodeEba'
                                            displayName2 = 'namaEba'
                                            break;
                                        case 'Properti':
                                            dataSource = instrumentPortoProperti(this.props.store, this.props.productId)
                                            displayName1 = 'jenisProperti'
                                            displayName2 = 'namaProperti'
                                            break;
                                        case 'ETF':
                                            dataSource = instrumentPortoEtf(this.props.store, this.props.productId)
                                            displayName1 = 'kodeReksa'
                                            displayName2 = 'namaReksa'
                                            break;
                                        case 'Repo':
                                            dataSource = instrumentPortoRepo(this.props.store, this.props.productId)
                                            displayName1 = 'noBilyet'
                                            displayName2 = 'jenisDeposito'
                                            break;
                                        case 'Non Portfolio Lainnya':
                                            dataSource = instrumentPortoNonPortoLainnya(this.props.store, this.props.productId)
                                            displayName1 = 'noBilyet'
                                            displayName2 = 'jenisDeposito'
                                            break;
                                        case 'Current Accounts':
                                            dataSource = instrumentPortoCurrentAccount(this.props.store, this.props.productId)
                                            displayName1 = 'noBilyet'
                                            displayName2 = 'jenisDeposito'
                                            break;
                                        default:
                                            break;
                                    }
                                    var options = this.formMasterRef.current.instance.getEditor('instrId')
                                    options.option({'dataSource':dataSource, 'displayExpr': function (item) { 
                                        return item && item[displayName1] + " - " + item[displayName2]
                                    }})
                                }
                            }
                        },
                    },
                    {
                        dataField:"instrId",
                        label:{
                            text:'Instrument'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: [],
                            valueExpr: 'instrumentId',
                            searchEnabled: true,
                            deferRendering: false,
                            onSelectionChanged: async (e) => {
                                if (e.selectedItem) {
                                    var DataMaster = {}
                                    var dataEfek = await httpRequest(
                                        process.env.REACT_APP_BACKEND_INVESTASI,
                                        this.props.store,
                                        `efek-balance-avgs/by-instrumentId/${e.selectedItem.instrumentId}`,
                                        "GET"
                                    );
                                    DataMaster.instrCurrencyId = dataEfek[0].currencyId
                                    DataMaster.kuantitasAwal = dataEfek[0].quantity
                                    DataMaster.hargaAvg = dataEfek[0].avgPrice
                                    DataMaster.faceValue = dataEfek[0].faceValue
                                    DataMaster.hargaPenutupan = dataEfek[0].price
                                    DataMaster.kursAvg = dataEfek[0].avgKurs
                                    this.formMasterRef.current.instance.updateData(DataMaster)
                                }
                            }
                        }
                    },
                    {
                        dataField:"instrCurrencyId",
                        label:{
                            text:'Currency'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            readOnly:true,
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'currencyCode',
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:"saldoBalance",
                caption:'Saldo Balance',
                items:[
                    {
                        dataField:"kuantitasAwal",
                        label:{
                            text:'Kuantitas Awal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:"kuantitasDipindahkan",
                        label:{
                            text:'Kuantitas Dipindahkan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format:'#,##0.00',
                        },
                        validationRules: [
                            {
                                type: "range",
                                min: 1,
                                message: "Kuantitas harus diisi"
                            },
                            {
                                type: 'custom',
                                validationCallback: (e) => {
                                    if (e.value > this.state.DataMaster.kuantitasAwal) {
                                        return false
                                    }
                                    return true
                                },
                                message: 'Kuantitas tidak boleh lebih dari yang dimiliki'
                            }
                        ]
                    },
                    {
                        dataField:"hargaAvg",
                        label:{
                            text:'Harga Rata Rata'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:'faceValue',
                        label:{
                            text:'Face Value'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:'hargaPenutupan',
                        label:{
                            text:'Harga Penutupan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:'plPengelolaAwal',
                        label:{
                            text:'PL Pengelola Awal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                    {
                        dataField:'kursAvg',
                        label:{
                            text:'Kurs Rata Rata'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                ]
            },
        ]

        this.popupToolbarItem1 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submit()
                        }
                    } 
                },
                visible:this.props.action == 'view' ? false : true,
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Ubah',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.update()
                        }
                    } 
                },
                visible:this.props.action == 'view' ? false : true,
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.pengajuanId = 0
        this.instrumentPerpindahanId = 0

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
    }

    submit = async() => {
        try {
            var DataMaster = {...this.state.DataMaster}
            DataMaster.pemindahanEfekId = this.pengajuanId
            var loadApi = `pemindahan-efek-dtls/pemindahan-efek-instrument-create-update`
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    update = async () => {
        try {
            var loadApi = 'pemindahan-efek-dtls/pemindahan-efek-instrument-create-update'
            var DataMaster = {...this.state.DataMaster}
            DataMaster.instrKategoriId = DataMaster.instrKategoriId.id
            DataMaster.pemindahanEfekId = this.pengajuanId
            DataMaster.id = this.instrumentPerpindahanId
            
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster,
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    getPengajuanId = (id) => {
        this.pengajuanId = id
    }
    
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    retrieveData = async (data) => {
        var DataMaster = {...this.state.DataMaster}
        var dataEfekDtl = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `pemindahan-efek-dtls/${data.id}`,
            "GET"
        );

        var instrCategoryList = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `instrument-categories`,
            "GET"
        );
        var dataInstrCat = instrCategoryList.find(value => value.categoryName == data.kategori)

        this.instrumentPerpindahanId = dataEfekDtl.id
        
        DataMaster.instrKategoriId = dataInstrCat.id
        DataMaster.instrId = dataEfekDtl.instrumentId
        DataMaster.instrCurrencyId = dataEfekDtl.currencyId
        DataMaster.kuantitasAwal = data.kuantitasSaldo
        DataMaster.kuantitasDipindahkan = data.kuantitasDipindahkan
        DataMaster.hargaAvg = data.hargaAvg
        DataMaster.faceValue = data.faceValue
        DataMaster.hargaPenutupan = data.hargaPenutupan
        DataMaster.plPengelolaAwal = data.plPengelolaAwal
        DataMaster.kursAvg = dataEfekDtl.currencyRate
        console.log(dataEfekDtl);
        this.setState({
            DataMaster:DataMaster
        })
    }

    show(){
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            DataMaster:{
                plPengelolaAwal:0
            },
            popupVisible: false,
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                resizeEnabled={true}
                showTitle={true}
                width={'30vw'}
                height="75vh"
                title={`Update Instrument Perpindahan Efek`}
                ref={this.PopupRef}
                toolbarItems={this.props.action == "edit" ? this.popupToolbarItem2 : this.popupToolbarItem1}
            >
                 <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                    <Form
                        ref={this.formMasterRef}
                        colCount={1}
                        id={'formDataMaster'}
                        formData={this.state.DataMaster}
                        items={this.DataMaster}
                        scrollingEnabled={true}
                        labelLocation={"left"}
                    />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormAddInstrumentPemindahanEfek