import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import httpRequest from 'plugin/httprequest'
import { Redirect } from 'react-router';
import notify from 'devextreme/ui/notify'
import { formatDate, formatDefaultDate } from 'plugin/helper';
import { alert, confirm } from 'devextreme/ui/dialog';
import { Form } from 'devextreme-react';

class MonitoringTugas extends Component {
    constructor(props) {
        super(props)

        this.getUserLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null
        this.product = []
        this.prevTabIndex = 1
        this.linkPage = ""
        this.state = {
            redirectLihat: false,
            redirectClose: false,
            filterData: {},
            tabDataSource: [
                {
                    id: 0,
                    text: 'Sebelumnya',
                    badge: '0'
                },
                {
                    id: 1,
                    text: 'Hari Ini',
                    badge: '0'
                },
                {
                    id: 2,
                    text: 'T+1',
                    badge: '0'
                },
            ],
            tabVisibility: [true, false, false]
        }

        this.TabDataSource = [
            {
                id: 0,
                text: 'Sebelumnya'
            },
            {
                id: 1,
                text: 'Hari Ini'
            },
            {
                id: 2,
                text: 'T+1'
            },
        ]

        this.filterItem = [
            {
                itemType: "button",
                buttonOptions: {
                    icon: 'refresh',
                    text: "Tampilkan",
                    type: "default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.loadAllMonit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: async (e) => {
                            if (e.row) {
                                const result = await confirm("Jika Data Belum Muncul, Lakukan Filter Sesuai Tanggal!", "Pemberitahuan!");

                                if (result) {
                                    this.linkPage = e.row.data.linkPage
                                    this.setRedirectLihat(e.row.data)
                                }
                            }
                        }
                    }
                ]
            },
            {
                dataField: 'no',
                caption: 'No',
                alignment: 'center',
                cellRender: (e) => {
                    return e.rowIndex + 1
                },
            }, {
                dataField: 'description',
                caption: 'Deskripsi',
                cellRender: (e) => {

                    return <React.Fragment>
                        <span className="d-block">{`Product: ${e.data.productName}`}</span>
                        <span className="font-weight-bold d-block">{e.data.taskName}</span>
                        <span className="d-block">{e.data.description}</span>
                    </React.Fragment>
                }

            }, {
                dataField: 'doDate',
                caption: 'Do Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            }
        ]

        this.DataGridRefPrev = React.createRef()
        this.DataGridRefNow = React.createRef()
        this.DataGridRefNext = React.createRef()
        this.loadDataGridNow = []
        this.loadDataGridPrev = []
        this.loadDataGridNext = []
        this.dataNow = []
        this.dataPrev = []
        this.dataNext = []
    }

    componentDidMount = async() => {
        await this.loadAllMonit()
    }

    setRedirectLihat = async (data) => {
        try {
            this.setState({
                redirectLihat: true,
            })
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }

    }

    close = async function (data) {
        try {
            let getTask = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `tasks/${data.id}`, 'GET')
            getTask.taskStatusId = 3
            await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `tasks`, 'PUT', {
                values: getTask,
                keys: getTask.id
            }, getTask)

            this.forceRefresh()
        } catch (e) {
            console.log(e);
            notify({ message: 'Terjadi Kesalahan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    redirectLihat = () => {
        if (this.state.redirectLihat) {
            return <Redirect to={this.linkPage} />
        }
    }

    onTabSelectionChanged = (args) => {
        if(args.value < 0) {
            args.value = 1
        }

        const newTabVisibility = this.state.tabVisibility.map((visibility, index) => index === args.value);
    
        this.setState({ tabVisibility: newTabVisibility });
    
        this.prevTabIndex = args.value;
    }

    forceRefresh = () => {
        this.DataGridRefPrev.current.forceRefresh(true)
        this.DataGridRefNow.current.forceRefresh(true)
        this.DataGridRefNext.current.forceRefresh(true)
    }

    loadAllMonit = async () => {
        await this.loadMonitNow()
        await this.loadMonitPrev()
        await this.loadMonitNext()

        const tabDataSource = [
            {
                id: 0,
                text: `Sebelumnya`,
                badge: this.dataPrev.length > 0 ? this.dataPrev[0].length.toString() : '0'
            },
            {
                id: 1,
                text: `Hari Ini`,
                badge: this.dataNow.length > 0 ? this.dataNow[0].length.toString() : '0'
            },
            {
                id: 2,
                text: `T+1`,
                badge: this.dataNext.length > 0 ? this.dataNext[0].length.toString() : '0'
            },
        ]

        this.setState({
            tabDataSource: tabDataSource
        })
    }


    loadMonitNow = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `users/monitoring-tugas/1`);
        if(response){
            this.dataNow.push(response);
            this.loadDataGridNow = response
            // this.DataGridRefNow.current.forceRefresh(true)
        }
        // return response
    }

    loadMonitPrev = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `users/monitoring-tugas/0`);
        if(response){
            this.dataPrev.push(response);
            this.loadDataGridPrev = response
            // this.DataGridRefPrev.current.forceRefresh(true)
        }
    }

    loadMonitNext = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `users/monitoring-tugas/2`);
        if(response){
            this.dataNext.push(response);
            this.loadDataGridNext = response
            // this.DataGridRefNext.current.forceRefresh(true)
        }
    }


    loadDataNow = async () => {
        return this.loadDataGridNow
    }

    loadDataPrev = async () => {
        return this.loadDataGridPrev
    }

    loadDataNext = async () => {
        return this.loadDataGridNext
    }

    onContentReady = (e) => {
        var pager = e.component  
        .getView("pagerView")  
        .element()  
        .dxPager("instance");
        pager._updateLightMode = function() {};  
        pager.repaint();  
    }

    render() {
        const { tabDataSource } = this.state;
        const { tabVisibility } = this.state;
        return (
            <React.Fragment>
                {this.redirectLihat()}
                <div className="mb-3">
                    <Form
                        id={'formFilter'}
                        formData={this.state.filterData}
                        items={this.filterItem}
                        labelLocation={'left'}
                    />
                </div>
                <DevextremeTab
                    // dataSource={tabDataSource}
                    items={ tabDataSource }
                    onOptionChanged={this.onTabSelectionChanged}
                    selectedIndex={1}
                />

                {/* <div id="tabindex-0" className={'d-none mt-2 text-center'} style={{ height: '100%' }}> */}
                <div id="tabindex-0" className={`mt-2 text-center ${tabVisibility[0] ? '' : 'd-none'}`} style={{ height: '100%' }}>
                    <DevExpressDataGrid
                        ref={this.DataGridRefPrev}
                        loadAPI='currencies'
                        insertAPI='currencies'
                        updateAPI='currencies'
                        deleteAPI='currencies'

                        ArraySourceData={this.loadDataPrev}
                        useArraySource={true}

                        backendserver={process.env.REACT_APP_BACKEND}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}
                        height={'auto'}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}
                        showInfo={false}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Monitoring Tugas Data'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='top' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // onToolbarPreparing={this.onToolbarPreparing}
                        onContentReady={this.onContentReady}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabindex-1" className={`mt-2 text-center ${tabVisibility[1] ? '' : 'd-none'}`} style={{ height: '100%' }}>
                    <DevExpressDataGrid
                        ref={this.DataGridRefNow}
                        loadAPI='currencies'
                        insertAPI='currencies'
                        updateAPI='currencies'
                        deleteAPI='currencies'

                        ArraySourceData={this.loadDataNow}
                        useArraySource={true}
                        backendserver={process.env.REACT_APP_BACKEND}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}
                        height={'auto'}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Monitoring Tugas Data'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='top' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabindex-2" className={`mt-2 text-center ${tabVisibility[2] ? '' : 'd-none'}`} style={{ height: '100%' }}>
                    <DevExpressDataGrid
                        ref={this.DataGridRefNext}
                        loadAPI='currencies'
                        insertAPI='currencies'
                        updateAPI='currencies'
                        deleteAPI='currencies'
                        ArraySourceData={this.loadDataNext}
                        useArraySource={true}

                        backendserver={process.env.REACT_APP_BACKEND}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}
                        height={'auto'}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Monitoring Tugas Data'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='top' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default MonitoringTugas
