import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormTiketPerjalananDinas from './formTiket.js';
import httpRequest from 'plugin/httprequest.js';


class TiketPerjalananDinas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.modalEditRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
          ];
        this.columnTiket = [
            {
                // type: 'buttons',
                // buttons: [
                //     'edit',
                //     {
                //         text: 'Ubah',
                //         hint: 'Ubah',
                //         onClick: (e) => {
                //             this.showModalEdit(e.row.data)
                //         }
                //     }
                // ]
            },
            {
                dataField: 'modaTransportasi',
                caption: 'MODA'
            },
            {
                dataField: 'tiket',
                caption: 'Tiket',
                alignment: 'center',
                columns : [
                    {
                        dataField: 'noTiket',
                        caption: 'No. Tiket',
                    },
                    {
                        dataField: 'namaArmada',
                        caption: 'Armada/Maskapai',
                    },
                    {
                        dataField: 'tanggalPerjalanan',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'jam',
                        caption: 'Jam',
                        dataType: 'datetime',
                        format: 'HH:mm'
                    },
                    {
                        dataField: 'dari',
                        caption: 'Dari'
                    },
                    {
                        dataField: 'tujuan',
                        caption: 'Tujuan'
                    },
                ]
            }
        ]
        this.sppdId = 0;
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.sppdId = 0;
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var data = this.state
        var dataSubmit = {...data.dataPersonal}

        // console.log(dataSubmit)
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data) => {  
        this.sppdId = data.id
        this.setState({
            dataPersonal: data,
        })
    }
    // loadDummyData = () => {
    //     var dummy = [
    //         {
    //             id: 1,
    //             noTiket: 'BDG/01/01/23/034/76',
    //             dari: 'Jakarta',
    //             tujuan: 'Bandung'
    //         },
    //     ]
    //     return dummy
    // }
    loadData = async () => {
        var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                `karyawan-sppd-tikets/get-by-karyawanSppdId/${this.sppdId}`,
                "GET"
            );

        return response
    }
    showModalEdit = (data) => {
        this.modalEditRef.current.retrieveData(data)
        this.modalEditRef.current.show()
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Tiket Perjalanan Dinas"}
                width={"70vw"}
                height={"70vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                        <div className="row" style={{paddingTop: '8px'}}>
                            <div className="col-md-12">
                                <DevExpressDataGrid
                                    loadAPI='pinjamans'
                                    insertAPI='pinjamans'
                                    updateAPI='pinjamans'
                                    deleteAPI='pinjamans' 

                                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                                    useArraySource={true}
                                    ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Jurnal Manual"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={'calc(100vh - 470px)'}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnTiket} // taruh konfigurasi kolom disini
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <FormTiketPerjalananDinas
                                    ref={this.modalEditRef}
                                    store={this.props.store}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default TiketPerjalananDinas