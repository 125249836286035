import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import HasilEvaluasiKinerja from 'components/pages/modal/sdm-umum/kepegawaian/kinerja/evaluasi-kinerja/hasilEvaluasi';
import FormKinerja from 'components/pages/modal/sdm-umum/kepegawaian/kinerja/evaluasi-kinerja/index';
import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { addURL } from 'redux/actions/url';

class EvaluasiKinerja extends Component {
    constructor(props){
        super(props)

        this.state = {
            redirect:false
        }

        this.dataGridRef = React.createRef()
        this.formTambahKinerjaRef = React.createRef()
        this.formEditKinerjaRef = React.createRef()
        this.showModalHasilKinerjaRef = React.createRef()
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : (e) => {
                            this.showModal(e.row.data)  
                        } 
                    },
                    {
                        text : 'Hasil',
                        hint : 'Hasil',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : (e) => {  
                            this.showModalHasil(e.row.data)  
                        } 
                    },
                ],
            },
            {
                dataField:"periodeTahun",
                caption:"Periode (Tahun)",
                // dataType:'date',
                // format:'dd MMM yyyy',
                // editorOptions:{
                //     useMaskBehavior: true,
                //     openOnFieldClick: true,
                // }
            },
            {
                dataField:"jumlahKaryawan",
                caption:"Jumlah Karyawan" 
            },
            {
                dataField:"skorAverage",
                caption:"Skor Rata Rata",
                dataType:"number" 
            },
            {
                // dataField:"tanggalPenilaian",
                itemType: 'group',
                caption:"Tanggal Penilaian",
                alignment:"center",
                columns:[
                    {
                        dataField:"tanggalAwal",
                        caption:"Dari",
                        dataType:'date',
                        format:'dd MMM yyyy',
                        editorType: "dxDateBox",
                        editorOptions:{
                            displayFormat:'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField:"tanggalAkhir",
                        caption:"Sampai",
                        dataType:'date',
                        format:'dd MMM yyyy',
                        editorType: "dxDateBox",
                        editorOptions:{
                            displayFormat:'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                         }
                    },
                ]
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModal = (data = null) => {
        if(data){
            this.formEditKinerjaRef.current.show()
            this.formEditKinerjaRef.current.retrieveData(data)
        }else{
            this.formTambahKinerjaRef.current.show()
        }
    }

    showModalHasil = (data) => {
        if (data) {
            this.showModalHasilKinerjaRef.current.retrieveData(data)
        }
        this.showModalHasilKinerjaRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal()
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Evaluasi Kinerja</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='evaluasi-kinerjas'
                    insertAPI='evaluasi-kinerjas'
                    updateAPI='evaluasi-kinerjas'
                    deleteAPI='evaluasi-kinerjas' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    remoteOperations = {true}
                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Evaluasi Kinerja"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Evaluasi Kinerja'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    onToolbarPreparing = {this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormKinerja
                    ref = {this.formTambahKinerjaRef}
                    store = {this.props.store}
                    forceRefresh = {this.forceRefresh}
                    type = {'add'}
                />
                <FormKinerja
                    ref = {this.formEditKinerjaRef}
                    store = {this.props.store}
                    forceRefresh = {this.forceRefresh}
                    type = {'edit'}
                />
                <HasilEvaluasiKinerja
                    ref = {this.showModalHasilKinerjaRef}
                    store = {this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
            </div>
        )
    }
}

export default EvaluasiKinerja