import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import TransaksiPembayaranMpGeneratePembayaranModal from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/bulanan/detailPembayaranModal';
import TransaksiPembayaranMpUploadSkModal from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/bulanan/uploadSkBulanan';
import { months, product } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';

class TransaksiPembayaranMpBulanan extends Component {
    constructor(props){
        super(props)

        this.state = {
            // filter: {
            //     getURL: 'view-pembayaran-mp-bulans/1900-10-01/2070-10-01/1'
            // },
            formFilter: {
                productId: 0,
                tanggalPembayaranStart: null,
                tanggalPembayaranEnd: null,
                getURL: 'view-pembayaran-mp-bulans/1900-10-01/2070-10-01/1'
            },
        }

        this.formFilterRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.pembayaranMpGeneratePembayaranModalRef = React.createRef()
        this.transaksiPembayaranMpDetailModalRef = React.createRef()
        this.transaksiPembayaranMpUploadSkModalRef = React.createRef()

        this.filterItem = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        this.dataGridRef.current.forceRefresh()
                    }
                },
                // colSpan: 3
            },
            {
                dataField: 'tanggalPembayaranStart',
                label : {
                    text: 'Tanggal Pembayaran',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                dataField: 'tanggalPembayaranEnd',
                label : {
                    text: 'S/D',
                    alignment: 'left',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    placeholder: 'dd MMM yyyy',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    type:"default",
                    onClick:() => {
                        this.filterData()
                    },
                },
                horizontalAlignment:"left"
            },
            // {
            //     editorType: 'dxButton',
            //     editorOptions: {
            //         text: 'Filter'
            //     }
            // }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text: 'Unggah SK',
                        hint : 'Unggah SK',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            this.showModalUploadSk()
                        }
                    },
                ],
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                },
            },
            {
                dataField: 'bulan',
                caption: 'Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                },
            },
            {
                dataField: 'tahun',
                caption: 'Tahun',
            },
            {
                dataField: 'totalPensiun',
                caption: 'Total Pensiunan',
                alignment: 'right',
                format: "#,##0",
            },
            {
                dataField: 'summaryNominal',
                caption: 'Total Pembayaran',
                alignment: 'right',
                format: "#,##0.00",
            },
            {
                dataField: 'paymentDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'resume',
                caption: 'Resume',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bankBayar',
                        caption: 'Bank Bayar'
                    },
                    {
                        dataField: 'totalBiayaBank',
                        caption: 'Total Biaya Bank',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'totalFeeBank',
                        caption: 'Total Fee Bank',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'resumeRetur',
                        caption: 'Retur',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'AR/AP Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Maker'
                        },
                        {
                            id: 2,
                            value: 'Checker'
                        },
                        {
                            id: 3,
                            value: 'Approval'
                        },
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                },
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    // loadDummyData = () => {
    //     var dummyData = [
    //         {
    //             id: 1,
    //             produk : 'produk test',
    //             bulan : 'Januari',
    //             tahun : 2020,
    //             totalPensiunan : 3000000,
    //             totalPembayaran : 3000000,
    //             noSkPembayaran : 2501,
    //             tanggalPembayaran : '2020-07-16',
    //             bankBayar : 'BANK BCA',
    //             totalBiayaBank : 2500000,
    //             totalFeeBank : 3000000,
    //             retur : 500000,
    //             arApStatus : 'aktif',
    //         }
    //     ]
    //     this.currentData = JSON.parse(JSON.stringify(dummyData))
    //     return dummyData
    // }

    filterData = async() => {
        var data = this.state.formFilter
        // this.setState({
        //     filter: {
        //         getURL : `view-pembayaran-mp-bulans/${formatDate(data.tanggalPembayaranStart)}/${formatDate(data.tanggalPembayaranEnd)}/${data.productId}`
        //     },
        // })

        this.setState(prevState =>({
            formFilter: {
                ...prevState.formFilter,
                getURL : `view-pembayaran-mp-bulans/${formatDate(data.tanggalPembayaranStart)}/${formatDate(data.tanggalPembayaranEnd)}/${data.productId}`
            }
        }))

        this.dataGridRef.current.forceRefresh(true)
    }

    showModalGeneratePembayaran = () => {
        this.pembayaranMpGeneratePembayaranModalRef.current.show()
    }

    showModalDetail = (data) => {
        this.transaksiPembayaranMpDetailModalRef.current.show()
        this.transaksiPembayaranMpDetailModalRef.current.retrieveData(data)
    }

    showModalUploadSk = () => {
        this.transaksiPembayaranMpUploadSkModalRef.current.show()
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Bulanan</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                    ref={this.formFilterRef}
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    // loadAPI='view-pembayaran-mp-bulans?mpPaymentTypeId.in=3&statusId.in=4'
                    loadAPI={this.state.formFilter.getURL}
                    insertAPI='pembayaran-mp-bulanan'
                    updateAPI='pembayaran-mp-bulanan'
                    deleteAPI='pembayaran-mp-bulanan' 
                    
                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {false}
                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Bulanan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Bulanan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <TransaksiPembayaranMpGeneratePembayaranModal 
                    ref={this.transaksiPembayaranMpDetailModalRef}
                    store={this.props.store}
                />
                <TransaksiPembayaranMpUploadSkModal
                    ref={this.transaksiPembayaranMpUploadSkModalRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default TransaksiPembayaranMpBulanan