import React, { Component } from 'react'
import { currency, productDefault } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormReksadana from 'components/pages/modal/investasi/order/reksadana/edit'
import { addURL } from 'redux/actions/url'
import FormOrderUploadReksadana from 'components/pages/modal/investasi/order/reksadana/upload'
import { Button } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'

class OrderReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridReksadanaRef = React.createRef()
        this.PopupFormReksadanaRef = React.createRef()
        this.PopupFormOrderUploadReksadanaRef = React.createRef()
        this.OrderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text: 'Edit',
                    //     hint: 'Edit',
                    //     onClick: (e) => {
                    //         this.showModalFormReksadana(e)
                    //     }
                    // },
                    {
                        text: 'File',
                        hint: 'File',
                        onClick: (e) => {
                            this.showModalFormUploadReksadana()
                        }

                    }
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'orderCode',
                        caption: 'Kode'
                    },
                    {
                        dataField: 'transactionName',
                        caption: 'Tipe'
                    },
                    {
                        dataField: 'orderDate',
                        caption: 'Tanggal Order',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'instrumen',
                caption: 'Instrumen',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instrumentCode',
                        caption: 'kode'
                    },
                    {
                        dataField: 'instrumentName',
                        caption: 'Nama Reksadana'
                    },
                    // {
                    //     dataField: 'unit',
                    //     caption: 'Unit'
                    // },
                    {
                        dataField: 'amountOrder',
                        caption: 'Amount',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'currencyId',
                        caption: 'Currency',
                        lookup: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyCode'
                        }
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    onSelectionChanged = (e) => {
        var orderId = e.selectedRowKeys
        this.setState({
            orderId: orderId
        })
    }
    generateInstruksi = async () => {
        var id = {
            listOrderId: this.state.orderId
        }
        confirmAlert({
            title: 'Generate',
            message: 'Apakah anda yakin ingin generate data ini?',
            buttons: [
                {
                    label: 'Ya',
                    onClick: async (e) => {
                        if (id.listOrderId != undefined) {
                            try {
                                var insertAPI = 'order-rds/buat-instruksi-rd'
                                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                                    values: id
                                })
                                notify({ message: 'Generate Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                                this.forceRefresh()
                                return response
                            } catch (e) {
                                console.log(e)
                                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        } else {
                            notify({ message: 'Silahkan pilih data untuk di generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    }
                },
                {
                    label: 'Tidak'
                }
            ]
        })
    }
    showModalFormReksadana = () => {
        this.PopupFormReksadanaRef.current.show()
    }
    forceRefresh = () => {
        this.DataGridReksadanaRef.current.forceRefresh(true)
    }
    showModalFormUploadReksadana = () => {
        this.PopupFormOrderUploadReksadanaRef.current.show();
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Order Reksadana</h2>
                <DevExpressDataGrid
                    keyField='orderId'
                    ref={this.DataGridReksadanaRef}
                    loadAPI='order-rds/list?size=9999&status=OPEN'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Reksadana"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Reksadana'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.OrderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Generate Instruksi"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.generateInstruksi.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
                <FormOrderUploadReksadana
                    ref={this.PopupFormOrderUploadReksadanaRef}
                    store={this.props.store}
                />
                <FormReksadana
                    ref={this.PopupFormReksadanaRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default OrderReksadana
