import React, { Component } from 'react'
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'

import { addURL } from '../../redux/actions/url'
import { sessionToken, sessionRole, sessionUser } from '../../redux/actions/session'
import { showLoading } from '../../redux/actions/loading'
import axiosException from "../../plugin/exceptionmessage"


class CreateNewPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            newPassword: '',
            repeatnewpassword: '',
            hidden: true
        };
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        this.login()

        e.preventDefault();
    }

    toggleShow() {
        this.setState({ hidden: !this.state.hidden })
    }

    login = async () => {
        let store = this.props.store;
        var data = {
            newPassword: this.state.newPassword,
            userLogin: this.state.username
        }
        try {
            store.dispatch(showLoading(true))

            await axios.post(process.env.REACT_APP_BACKEND + 'account/submitNewPassword', data, {
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
            

            // store.dispatch(sessionToken(loginResponse.data.id_token))
            // await this.getGlobalParam(loginResponse.data.id_token)
            // // store.dispatch(sessionToken(this.state.username))

            // let userData = await axios.get(process.env.REACT_APP_BACKEND + 'account', {
            //     headers: {
            //         Authorization: 'Bearer ' + loginResponse.data.id_token //the token is a variable which holds the token
            //     }
            // })
            // store.dispatch(sessionRole(userData.data.sysGroups))
            // store.dispatch(sessionUser(userData.data.login))
            store.dispatch(showLoading(false))
            confirmAlert({
                title: 'Berhasil',
                message: 'Sukses Ganti password',
                buttons: [
                    {
                        label: 'Close',
                        onClick: () => {
                            window.location.href = '/'
                        }
                    },
                ]
            });
        } catch (error) {
            confirmAlert({
                title: 'Login Failed',
                message: axiosException(error),
                buttons: [
                    {
                        label: 'Close',
                    },
                ]
            });
            store.dispatch(showLoading(false))
        }

    }

    render() {
        const { username, newPassword, repeatnewpassword, hidden } = this.state;

        return (
            <div>
                <div className="container-fluid d-flex justify-content-center align-items-center" style={{ minHeight: 'calc(100vh)', background: 'url("/assets/img/login-bg.png") no-repeat', backgroundSize: 'cover' }}>
                    <div className="login-clean">
                        <form method="post" onSubmit={this.handleSubmit}>
                            <div className="illustration">
                                <img src="/assets/img/logo.png" alt="App Logo"></img>
                            </div>
                            <div className="text-center mb-4">
                                <span className="font-weight-bold" style={{fontSize: '1.2rem'}}>Dana  Pensiun Digital System</span>
                            </div>
                            <div className="form-group mx-2 text-center">
                                <span className="font-weight-bold text-center" style={{fontSize: '0.8rem', color: 'red'}}>Create New Password</span>
                            </div>
                            <div className="form-group mb-3 mx-2">
                                <input className="form-control form-control-sm" type="text" name="username" value={username} onChange={this.handleChange} placeholder="User ID" />
                            </div>
                            <div className="form-group position-relative mb-3 mx-2">
                                <input className="form-transparent form-control form-control-sm" type={hidden ? "password" : "text"} name="newPassword" value={newPassword} onChange={this.handleChange} placeholder="Kata Sandi Baru"/>
                                <i className={`${hidden ? "far fa-eye-slash" : "far fa-eye"} reveal-password`} onClick={this.toggleShow.bind(this)} />
                            </div>
                            <div className="form-group position-relative mb-5 mx-2">
                                <input className="form-transparent form-control form-control-sm" type={hidden ? "password" : "text"} name="repeatnewpassword" value={repeatnewpassword} onChange={this.handleChange} placeholder="Ulangi Kata Sandi Baru"/>
                                <i className={`${hidden ? "far fa-eye-slash" : "far fa-eye"} reveal-password`} onClick={this.toggleShow.bind(this)} />
                            </div>
                            <div className="form-group  mx-2">
                                <button className="btn btn-green text-white btn-lg btn-block btn-sm" type="submit">Masuk</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateNewPassword