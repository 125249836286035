import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import { dapenProduk, kasUmum, programSdm } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import { formatDate } from 'plugin/helper';
import { alert } from 'devextreme/ui/dialog';

class FormModalEditPengembalianKasUmum extends Component {
    constructor(props) {
        super(props)
        this.userLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null

        this.state = {
            popupVisible: false,
            dataMaster: {},
            type: true
        }
        this.transactionRequestAmount = [];
        this.sum = [];
        this.idPengembalian = 0

        this.formAdd = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'transactionNo',
                        label: {
                            text: 'No Transaksi'
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'kasUmumId',
                        label: {
                            text: 'Kas Umum'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'namaKas', // provides display values
                            searchEnabled: true,
                            // deferRendering: false,
                            onSelectionChanged: async(e) => {                                
                                try {
                                    var data = e.selectedItem;
                                    if (data) {
                                        let kasPayments = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getByMultipleStatusId/21%2C24?size=999`);
                                        let valueRequestAmount = kasPayments.filter(value => value.kasUmumId === e.selectedItem.id);
                                        let sumInitial;

                                        if (valueRequestAmount.length <= 0) {
                                            this.formRef.current.instance.updateData({
                                                totalPenerimaan: data.totalPenerimaan || 0,
                                                totalPengeluaran: data.totalPengeluaran || 0,
                                                saldo: data.saldo || 0,
                                                nominalPengembalian: data.saldo || 0,
                                            })
                                        } else if (valueRequestAmount.length <= 1) {
                                            sumInitial = valueRequestAmount[0].requestAmount;
                                            if (sumInitial > 0 && this.state.type === 'add') {
                                                await alert('Sedang ada pengembalian di kas umum ini dengan status pengajuan/proses', 'Informasi');

                                                this.formRef.current.instance.updateData({
                                                    totalPenerimaan: data.totalPenerimaan || 0,
                                                    totalPengeluaran: data.totalPengeluaran + sumInitial || 0,
                                                    saldo: data.saldo - sumInitial || 0,
                                                    nominalPengembalian: data.saldo - sumInitial || 0,
                                                })
                                            } else {
                                                this.formRef.current.instance.updateData({
                                                    totalPenerimaan: data.totalPenerimaan || 0,
                                                    totalPengeluaran: data.totalPengeluaran || 0,
                                                    saldo: data.saldo || 0,
                                                    nominalPengembalian: data.saldo || 0,
                                                })
                                            }
                                        } else if (valueRequestAmount.length > 1) {
                                            // sumInitial = valueRequestAmount.reduce((x, y) => x.requestAmount + y.requestAmount);
                                            let sum = 0;
                                            for (let x of valueRequestAmount) {
                                                sum += x.requestAmount;
                                            }
                                            
                                            if (sum > 0 && this.state.type === 'add') {
                                                await alert('Sedang ada pengembalian di kas umum ini dengan status pengajuan/proses', 'Informasi!');

                                                this.formRef.current.instance.updateData({
                                                    totalPenerimaan: data.totalPenerimaan || 0,
                                                    totalPengeluaran: data.totalPengeluaran + sum || 0,
                                                    saldo: data.saldo - sum || 0,
                                                    nominalPengembalian: data.saldo - sum || 0,
                                                })
                                            }
                                        } else {
                                            sumInitial = 0
                                        }
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                            }
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'pengajuan',
                caption: 'Pengembalian',
                items: [
                    {
                        dataField: 'tanggalPengembalian',
                        label: {
                            text: 'Tanggal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Tanggal tidak boleh kosong'
                            },
                        ],
                    },
                    {
                        dataField: 'nominalPengembalian',
                        label: {
                            text: 'Nominal'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                        }
                    },
                    {
                        dataField: 'description',
                        label: {
                            text: 'Keterangan'
                        },
                        editorOptions: {
                            readOnly: false
                        },
                        isRequired: true,
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Keterangan tidak boleh kosong'
                            },
                        ],
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'saldoKasBank',
                caption: 'Saldo Kas Bank',
                items: [
                    {
                        dataField: 'totalPenerimaan',
                        label: {
                            text: 'Total Penerimaan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'totalPengeluaran',
                        label: {
                            text: 'Total Pengeluaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'saldo',
                        label: {
                            text: 'Saldo'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        }
                    }
                ]
            },

        ]

        this.formEdit = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            readOnly: false,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField: 'transactionNo',
                        label: {
                            text: 'No Transaksi'
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'kasUmumId',
                        label: {
                            text: 'Kas Umum'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'namaKas', // provides display values
                            searchEnabled: true,
                            readOnly: true,
                            // deferRendering: false,
                            onSelectionChanged: async (e, type) => {
                                try {
                                    var data = e.selectedItem
                                    if (data) {
                                        let kasPayments = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getByMultipleStatusId/21%2C22%2C23%2C24?size=999`);
                                        let valueRequestAmount = kasPayments.filter(value => value.kasUmumId === data.id);
                                        let sumInitial;

                                        let dataPengembalian = valueRequestAmount.filter(value => value.id === this.idPengembalian);
                                        let currentReqAmount = dataPengembalian[0].requestAmount;

                                        if (valueRequestAmount.length <= 1) {
                                            sumInitial = valueRequestAmount[0].requestAmount;
                                            if (sumInitial > 0) {
                                                // await alert('Sedang ada pengembalian di kas umum ini dengan status pengajuan/proses', 'Informasi');
                                                this.formRef.current.instance.updateData({
                                                    totalPenerimaan: data.totalPenerimaan || 0,
                                                    // totalPengeluaran: data.totalPengeluaran + sumInitial - currentReqAmount || 0,
                                                    totalPengeluaran: data.totalPengeluaran,
                                                    // saldo: data.saldo - sumInitial + currentReqAmount || 0,
                                                    saldo: data.saldo - currentReqAmount || 0,
                                                    nominalPengembalian: currentReqAmount || 0,
                                                })
                                            }
                                        } else if (valueRequestAmount.length > 1) {
                                            let sum = 0;
                                            for (let x of valueRequestAmount) {
                                                sum += x.requestAmount;
                                            }
                                            // sumInitial = valueRequestAmount.reduce((x, y) => x.requestAmount + y.requestAmount);
                                            if (sum > 0) {
                                                this.formRef.current.instance.updateData({
                                                    totalPenerimaan: data.totalPenerimaan || 0,
                                                    // totalPengeluaran: data.totalPengeluaran + sum - currentReqAmount || 0,
                                                    totalPengeluaran: data.totalPengeluaran,
                                                    saldo: data.saldo - sum + currentReqAmount || 0,
                                                    nominalPengembalian: currentReqAmount || 0,
                                                })
                                            } else {
                                                this.formRef.current.instance.updateData({
                                                    totalPenerimaan: data.totalPenerimaan || 0,
                                                    // totalPengeluaran: data.totalPengeluaran - currentReqAmount || 0,
                                                    totalPengeluaran: data.totalPengeluaran,
                                                    saldo: data.saldo - currentReqAmount || 0,
                                                    nominalPengembalian: currentReqAmount || 0,
                                                })
                                            }
                                        } else {
                                            sumInitial = 0
                                        }
                                    }
                                } catch (error) {
                                    console.log(error);
                                }
                                
                                // var data = e.selectedItem
                                // if (data) {
                                //     this.formRef.current.instance.updateData({
                                //         totalPenerimaan: data.totalPenerimaan || 0,
                                //         totalPengeluaran: data.totalPengeluaran || 0,
                                //         saldo: data.saldo || 0,
                                //         nominalPengembalian: data.saldo || 0,
                                //     })
                                // }
                            }
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'pengajuan',
                caption: 'Pengembalian',
                items: [
                    {
                        dataField: 'tanggalPengembalian',
                        label: {
                            text: 'Tanggal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            // readOnly: this.readOnly,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'nominalPengembalian',
                        label: {
                            text: 'Nominal'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            // readOnly: this.readOnly,
                        }
                    },
                    {
                        dataField: 'description',
                        label: {
                            text: 'Keterangan'
                        },
                        editorOptions: {
                            // readOnly: this.readOnly,
                        },
                        validationRules: [
                            {
                                type: 'required',
                                message: 'Keterangan tidak boleh kosong'
                            }
                        ]
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'saldoKasBank',
                caption: 'Saldo Kas Bank',
                items: [
                    {
                        dataField: 'totalPenerimaan',
                        label: {
                            text: 'Total Penerimaan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'totalPengeluaran',
                        label: {
                            text: 'Total Pengeluaran'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'saldo',
                        label: {
                            text: 'Saldo'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true,
                        }
                    }
                ]
            },

        ]

        //  this.form = [
        //     {
        //         itemType: 'group',
        //         items: [
        //             {
        //                 dataField: 'programId',
        //                 label: {
        //                     text: 'Program'
        //                 },
        //                 editorType: 'dxSelectBox',
        //                 editorOptions: {
        //                     dataSource: programSdm(this.props.store),
        //                     valueExpr: 'id',
        //                     displayExpr: 'program', // provides display values
        //                     searchEnabled: true,
        //                     readOnly: true,
        //                     deferRendering: false,
        //                 }
        //             },
        //             {
        //                 dataField: 'transactionNo',
        //                 label: {
        //                     text: 'No Transaksi'
        //                 },
        //                 editorOptions: {
        //                     readOnly: true,
        //                 }
        //             },
        //             {
        //                 dataField: 'kasUmumId',
        //                 label: {
        //                     text: 'Kas Umum'
        //                 },
        //                 editorType: 'dxSelectBox',
        //                 editorOptions: {
        //                     dataSource: [],
        //                     valueExpr: 'id',
        //                     displayExpr: 'namaKas', // provides display values
        //                     searchEnabled: true,
        //                     readOnly: true,
        //                     // deferRendering: false,
        //                     onSelectionChanged: async (e) => {
        //                         try {
        //                             var data = e.selectedItem;
        //                             if (data) {
        //                                 let kasPayments = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/getByMultipleStatusId/21%2C24?size=999`);
        //                                 let valueRequestAmount = kasPayments.filter(value => value.kasUmumId === e.selectedItem.id);
        //                                 let sumInitial;
        //                                 let dataPengembalian = valueRequestAmount.filter(value => value.id === this.idPengembalian);

        //                                 if (valueRequestAmount.length === 1) {
        //                                     sumInitial = valueRequestAmount[0].requestAmount;
        //                                     if (sumInitial > 0) {
        //                                         this.formRef.current.instance.updateData({
        //                                             totalPenerimaan: data.totalPenerimaan || 0,
        //                                             totalPengeluaran: data.totalPengeluaran + sumInitial || 0,
        //                                             saldo: data.saldo - sumInitial || 0,
        //                                             nominalPengembalian: data.saldo - sumInitial || 0,
        //                                         })
        //                                     }
        //                                 } else if (valueRequestAmount.length > 1) {
        //                                     // sumInitial = valueRequestAmount.reduce((x, y) => x.requestAmount + y.requestAmount);
        //                                     let currentReqAmount = dataPengembalian[0].requestAmount;
    
        //                                     let sum = 0;
        //                                     for (let x of valueRequestAmount) {
        //                                         sum += x.requestAmount;
        //                                     }
        //                                     if (sum > 0) {
        //                                         this.formRef.current.instance.updateData({
        //                                             totalPenerimaan: data.totalPenerimaan || 0,
        //                                             totalPengeluaran: data.totalPengeluaran + sum || 0,
        //                                             saldo: data.saldo - sum || 0,
        //                                             nominalPengembalian: currentReqAmount || 0,
        //                                         })
        //                                     }
        //                                 } else {
        //                                     sumInitial = 0
        //                                 }
                                        
        //                                 //     totalPenerimaan: data.totalPenerimaan || 0,
        //                                 //     totalPengeluaran: data.totalPengeluaran || 0,
        //                                 //     saldo: data.saldo || 0,
        //                                 //     nominalPengembalian: data.saldo || 0,
        //                                 // })
        //                             }
        //                         } catch (error) {
        //                             console.log(error);
        //                         }
        //                     }
        //                 }
        //             },
        //         ]
        //     },
        //     {
        //         itemType: 'group',
        //         name: 'pengajuan',
        //         caption: 'Pengembalian',
        //         items: [
        //             {
        //                 dataField: 'tanggalPengembalian',
        //                 label: {
        //                     text: 'Tanggal'
        //                 },
        //                 editorType: 'dxDateBox',
        //                 editorOptions: {
        //                     displayFormat: 'dd MMM yyyy',
        //                     useMaskBehavior: true,
        //                     readOnly: true,
        //                     openOnFieldClick: true,
        //                 },
        //             },
        //             {
        //                 dataField: 'nominalPengembalian',
        //                 label: {
        //                     text: 'Nominal'
        //                 },
        //                 editorType: "dxNumberBox",
        //                 editorOptions: {
        //                     format: "#,##0.00",
        //                     readOnly:true
        //                 }
        //             },
        //             {
        //                 dataField: 'description',
        //                 label: {
        //                     text: 'Keterangan'
        //                 },
        //                 editorOptions: {
        //                     readOnly: true
        //                 }
        //             }
        //         ]
        //     },
        //     {
        //         itemType: 'group',
        //         name: 'saldoKasBank',
        //         caption: 'Saldo Kas Bank',
        //         items: [
        //             {
        //                 dataField: 'totalPenerimaan',
        //                 label: {
        //                     text: 'Total Penerimaan'
        //                 },
        //                 editorType: "dxNumberBox",
        //                 editorOptions: {
        //                     format: "#,##0.00",
        //                     readOnly: true,
        //                 }
        //             },
        //             {
        //                 dataField: 'totalPengeluaran',
        //                 label: {
        //                     text: 'Total Pengeluaran'
        //                 },
        //                 editorType: "dxNumberBox",
        //                 editorOptions: {
        //                     format: "#,##0.00",
        //                     readOnly: true,
        //                 }
        //             },
        //             {
        //                 dataField: 'saldo',
        //                 label: {
        //                     text: 'Saldo'
        //                 },
        //                 editorType: "dxNumberBox",
        //                 editorOptions: {
        //                     format: "#,##0.00",
        //                     readOnly: true,
        //                 }
        //             }
        //         ]
        //     },

        // ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: () => {
                        let formValidation = this.validate();
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Simpan",
                    onClick: () => {
                        let formValidation = this.validate();
                        if (formValidation) {
                            this.updateData(this.state.dataMaster.id)
                        }
                    }
                },
                toolbar: "bottom"
            },
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this.state.type)
                },
                toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem3 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.readOnly = false
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    retrieveData = (data, type, readOnly) => {
        this.idPengembalian = data.id;
        this.readOnly = type === 'lihat' ? true : false;
        data.tanggalPengembalian = data.requestDate
        this.setState({
            dataMaster: data,
            type: type
        })
    }

    show = (type) => {
        this.setState({
            popupVisible: true,
            type: type
        })
    }

    hide = (type) => {
        this.setState({
            dataMaster: {},
            popupVisible: false,
            // type: type
        })
        this.readOnly = false
    }

    getKasUmumByUserLogin = async () => {
        let max = -Infinity;
        var data = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-umums?size=9999`, 'GET')
   
        data = data.filter(value => value.otoritasUser == this.userLoged && value.saldo > 0)
        // console.log(data.filter(value => value.saldo > 0));
        this.formRef.current.instance.getEditor('kasUmumId').option('dataSource', data)
    }

    submitData = async () => {
        var dataSubmit = { ...this.state.dataMaster }
        try {
            // if (dataSubmit.nominalPengembalian === -Infinity) {
            //     await alert('Nominal pengembalian tidak boleh melebihi saldo dan harus lebih dari 0!', 'Informasi!');              
            // }

            if (dataSubmit.nominalPengembalian <= 0 || dataSubmit.nominalPengembalian > dataSubmit.saldo) {
                await alert('Nominal pengembalian tidak boleh melebihi saldo dan harus lebih dari 0!', 'Informasi!');
            } else {
                if (this.state.type == 'add') {
                    var postData = {
                        "description": dataSubmit.description,
                        "kasUmumId": dataSubmit.kasUmumId,
                        "nominalPengembalian": dataSubmit.nominalPengembalian,
                        "programId": dataSubmit.programId,
                        "saldo": dataSubmit.saldo,
                        "tanggalPengembalian": formatDate(dataSubmit.tanggalPengembalian),
                        "totalPenerimaan": dataSubmit.totalPenerimaan,
                        "totalPengeluaran": dataSubmit.totalPengeluaran,
                    }
                    var postAPI = 'kas-bank-transactions-simpan-pengembalian'
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'POST', {
                        values: postData
                    })
                }
                if (response) {
                    await alert("Data Berhasil ditambahkan","Berhasil")
                    this.hide()
                    this.props.forceRefresh()
                }
            }
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    

    updateData = async (id) => {
        var dataSubmit = { ...this.state.dataMaster }
        try {
            if (dataSubmit.nominalPengembalian <= 0 || dataSubmit.nominalPengembalian > dataSubmit.saldo) {
                await alert('Nominal pengembalian tidak boleh melebihi saldo dan harus lebih dari 0!', 'Informasi!');
            } else {
                var getDataKas = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions/${id}`, 'GET')
                getDataKas.requestDate = dataSubmit.tanggalPengembalian
                getDataKas.programId = dataSubmit.programId
                getDataKas.kasUmumId = dataSubmit.kasUmumId
                getDataKas.requestAmount = dataSubmit.nominalPengembalian
                getDataKas.transactionAmount = dataSubmit.nominalPengembalian
                getDataKas.description = dataSubmit.description
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `kas-bank-transactions`, 'PUT',{
                    values:getDataKas,
                    key:getDataKas.id
                },getDataKas)

                if (response) {
                    await alert("Data Berhasil diubah","Berhasil")
                    this.hide()
                    this.props.forceRefresh()
                }
            }
        } catch (e) {
            console.log(e)
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Pengembalian Kas Umum'}
                width={"60vw"}
                height={"60vh"}
                toolbarItems={this.state.type === 'add' ? this.PopupToolbarItem : this.state.type === 'edit' ? this.PopupToolbarItem2 : this.PopupToolbarItem3}
                ref={this.PopupRef}
            >
                <ScrollView width='100%' height='100%'>
                    <Form
                        ref={this.formRef}
                        colCount={1}
                        id={'formEdit'}
                        formData={this.state.dataMaster}
                        readOnly={this.readOnly}
                        // items={this.state.type === 'add' ? this.formAdd : this.state.type === 'edit' ? this.formEdit : this.form}
                        items={this.state.type === 'add' ? this.formAdd : this.state.type === 'edit' ? this.formEdit : this.formEdit}
                        labelLocation='left'
                    />
                </ScrollView>
            </Popup>
        )
    }
}

export default FormModalEditPengembalianKasUmum