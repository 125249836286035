import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import { pengkinianType,tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta, SKType, currency, karyawan, sppdBiaya, jabatan, sdmUnit, sppdType, jabatanName } from 'dataSource/lookup';
import { formatUploadFileData } from 'plugin/helper';

class FormPerjalananDinas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataPengajuan: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
            
          ];
        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
            
          ];
        this.FormPenugasanPerjalananDinas = [
            {
                dataField: 'penugasan',
                label: {
                    text: 'No. Penugasan'
                },
            },
            {
                dataField: 'name',
                label: {
                    text: 'Karyawan',
                },
                // editorType: 'dxSelectBox',
                // editorOptions:{
                //     dataSource: karyawan(this.props.store),
                //     valueExpr: 'id', // contains the same values as the "statusId" field provides
                //     displayExpr: function(item){
                //         return item && "NIK : " + item.nik + " - " + item.fullName;
                //     },
                //     searchEnabled: true,
                //     deferRendering: false,
                // }
            },
            {
                dataField: 'jabatan',
                label: {
                    text: 'Jabatan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: jabatanName(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values
                },
                // disabled: true
            },
            {
                dataField: 'unit',
                label: {
                    text: 'Unit'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                },
                // disabled: true
            },
            {
                dataField: 'perjalanan',
                label: {
                    text: 'Tipe Perjalanan Dinas'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sppdType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'sppdName', // provides display values
                }
            },
            {
                dataField: 'hari',
                label: {
                    text: 'Jumlah Hari'
                },
                editorType: 'dxNumberBox'
            },
            {
                dataField: 'tanggalAwal',
                label: {
                    text: 'Dari Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'tanggalAkhir',
                label: {
                    text: 'Sampai Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    readOnly: true
                }
            },
            {
                dataField: 'fileSppd',
                label: {
                    text: 'File SPPD'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
            }
        ]

    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var data = this.state
        var dataSubmit = {...data.dataPengajuan}

        // console.log(dataSubmit)
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data) => {   
        console.log(this.props.action); 
        this.setState({
            dataPengajuan: data,
        })
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Perjalanan Dinas"}
                width={"55vw"}
                height={"50vh"}
                toolbarItems={this.props.action === 'edit' ? this.PopupToolbarItem2 : this.PopupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <Form
                        colCount={1}
                        id={'formPenugasanPerjalananDinas'}
                        formData={this.state.dataPengajuan}
                        items={this.FormPenugasanPerjalananDinas}
                        labelLocation = 'left'
                        readOnly={this.props.action === 'edit' ? true : false}
                    />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormPerjalananDinas