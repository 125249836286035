import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import HtmlEditor, { Toolbar, Item }  from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

import { tax,gender, cities, countries, provinces, educationBackground, grade, unit, maritalStatus, banks,branch, SKKerja, SKKerjaGaji, SKPensiun, statusPeserta } from 'dataSource/lookup';
import { formatUploadFileData } from 'plugin/helper';


class FormOrderUploadReksadana extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            selectedTabIndex: 0,
            dataMaster: {},
            dataUploadInvestasi: {},
            dataUploadDealer: {},
            dataUploadSettlement: {},
            dataUploadKeuangan: {}
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    text: 'ID',
                    alignment: 'left'
                }
            },
            {
                dataField: 'kode',
                label: {
                    text: 'Kode',
                    alignment: 'left'
                }
            },
            {
                dataField: 'tanggal',
                label: {
                    text: 'Tanggal',
                    alignment: 'left'
                }
            },
            {
                dataField: 'instrument',
                label: {
                    text: 'Instrument',
                    alignment: 'left'
                }
            },
            {
                dataField: 'transaksi',
                label: {
                    text: 'Transaksi',
                    alignment: 'left'
                }
            },
            {
                dataField: 'efek',
                label: {
                    text: 'Efek',
                    alignment: 'left'
                }
            },
        ]
        this.dataUploadInvestasi = [
            {
                dataField: "usulanInvestasi",
                label: {
                    text: "Usulan Investasi",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Usulan Investasi must be filled"
                    }
                ]
            },
            {
                dataField: "notaPersetujuan",
                label: {
                    text: "Nota Persetujuan",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Nota Persetujuan must be filled"
                    }
                ]
            },
            {
                dataField: "perhitunganInvestasi",
                label: {
                    text: "Perhitungan Investasi",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Perhitungan Investasi must be filled"
                    }
                ]
            },
        ]
        this.dataUploadDealer = [
            {
                dataField: "orderBroker",
                label: {
                    text: "Order Broker",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Order Broker must be filled"
                    }
                ]
            },
            {
                dataField: "orderMi",
                label: {
                    text: "Order MI",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Order MI must be filled"
                    }
                ]
            },
            {
                dataField: "orderBank",
                label: {
                    text: "Order Bank",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Order Bank must be filled"
                    }
                ]
            },
        ]
        this.dataUploadSettlement = [
            {
                dataField: "instruksiKustodi",
                label: {
                    text: "Instruksi Kustodi",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Instruksi Kustodi must be filled"
                    }
                ]
            },
            {
                dataField: "konfirmasiKustodi",
                label: {
                    text: "Konfirmasi Kustodi",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Konfirmasi Kustodi must be filled"
                    }
                ]
            },
            {
                dataField: "konfirmasiBroker",
                label: {
                    text: "Konfirmasi Broker",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Konfirmasi Broker must be filled"
                    }
                ]
            },
            {
                dataField: "instruksiBank",
                label: {
                    text: "Instruksi Bank",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Instruksi Bank must be filled"
                    }
                ]
            },
        ]
        this.dataUploadKeuangan = [
            {
                dataField: "persetujuanPengeluaran",
                label: {
                    text: "Persetujuan Pengeluaran",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Persetujuan Pengeluaran must be filled"
                    }
                ]
            },
            {
                dataField: "buktiTransaksi",
                label: {
                    text: "Bukti Transaksi",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    onValueChanged: this.onUploadStarted.bind(this),
                },
                isRequired: true,
                    validationRules: [
                    {
                        type: "required",
                        message: "Bukti Transaksi must be filled"
                    }
                ]
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            
            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState(prevState =>({
                    dataMaster: {
                        ...prevState.dataMaster,
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                    }
                }))
            }
    
            fr.readAsDataURL(files)
        }
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    retrieveData = async(data) => {    
        this.setState({
            dataPeserta: data,
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Upload'}
                minWidth={1000}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                readOnly={true}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                    <div className="row" style={{height:"calc(55vh - 60px)", overflowY:"auto"}}>
                        <div className="col-md-12">
                            <h6 className="my-3"><u>Manager Investasi</u></h6>
                            <Form
                                colCount={1}
                                id={'formDataUploadInvestasi'}
                                formData={this.state.dataUploadInvestasi}
                                items={this.dataUploadInvestasi}
                                labelLocation={"left"}
                            />
                            <h6 className="my-3"><u>Dealer</u></h6>
                            <Form
                                colCount={1}
                                id={'formDataUploadDealer'}
                                formData={this.state.dataUploadDealer}
                                items={this.dataUploadDealer}
                                labelLocation={"left"}
                            />
                            <h6 className="my-3"><u>Settlement</u></h6>
                            <Form
                                colCount={1}
                                id={'formDataUploadSettlement'}
                                formData={this.state.dataUploadSettlement}
                                items={this.dataUploadSettlement}
                                labelLocation={"left"}
                            />
                            <h6 className="my-3"><u>Keuangan</u></h6>
                            <Form
                                colCount={1}
                                id={'formDataUploadKeuangan'}
                                formData={this.state.dataUploadKeuangan}
                                items={this.dataUploadKeuangan}
                                labelLocation={"left"}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormOrderUploadReksadana
