import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, currency, kpds, managerInvestasis, productDefault, rekeningBank } from 'dataSource/lookup';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';


class ModalBreakDeposito extends Component {
    constructor(props) {
        super(props)

        this.taxDep = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === 'TAX-DEP') : null
        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: [],
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'KONFIRMASI',
                        items: [
                            {
                                dataField: 'efekBalanceId',
                                label: {
                                    text: 'ID Balance',
                                    alignment: 'left'
                                },
                                editorOptions: {
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'bankId',
                                label: {
                                    text: 'Bank',
                                    alignment: 'left'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'productId',
                                label: {
                                    text: 'Produk',
                                    alignment: 'left'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'productCode',
                                    readOnly: true
                                }
                            },
                            // {
                            //     dataField: 'miId',
                            //     label: {
                            //         text: 'Manager Investasi',
                            //         alignment: 'left'
                            //     },
                            //     editorType: 'dxSelectBox',
                            //     editorOptions: {
                            //         dataSource: managerInvestasis(this.props.store),
                            //         valueExpr: 'id',
                            //         displayExpr: 'miName',
                            //         readOnly: true
                            //     }
                            // },
                            {
                                dataField: 'kpdId',
                                label: {
                                    text: 'No KPD',
                                    alignment: 'left'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: kpds(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'noKontrak',
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'BANK',
                        items: [
                            {
                                dataField: 'rekPencairanId',
                                label: {
                                    text: 'Rekening Pencairan/Bunga',
                                    alignment: 'left',
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: rekeningBank(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.rekNo + ' - ' + e.rekName
                                    },
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'DOKUMEN',
                        items: [
                            {
                                dataField: 'file',
                                label: {
                                    text: 'File Konfirmasi',
                                    alignment: 'left',
                                },
                                editorType: "dxFileUploader",
                                editorOptions: {
                                    selectButtonText: "Select File",
                                    labelText: "Upload File",
                                    uploadMode: "useForm",
                                    accept: 'image/png, image/jpeg, application/pdf',
                                    onValueChanged: this.onUploadStarted.bind(this),
                                },
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'CATATAN',
                        items: [
                            {
                                dataField: 'catatan',
                                label: {
                                    text: 'Catatan',
                                    alignment: 'left',
                                },
                                editorType: 'dxTextArea',
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'BILYET',
                items: [
                    {
                        dataField: 'bilyetNo',
                        label: {
                            text: 'No Bilyet',
                            alignment: 'left',
                        },
                        editorOptions: {
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nominalAccrued',
                        label: {
                            text: 'Nominal Accrued Bunga',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'tglPencairan',
                        label: {
                            text: 'Tanggal Pencairan',
                            alignment: 'left',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            onValueChanged: (e) => {
                                var balanceDate = new Date(this.state.dataMaster.balanceDate)
                                var tanggalPencairan = new Date(e.value)
                                var diffTime = Math.abs(tanggalPencairan - balanceDate)
                                var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                                this.formRef.current.instance.updateData('tenor', diffDays)
                            }
                        },
                    },
                    {
                        dataField: 'nominalDeposito',
                        label: {
                            text: 'Nominal Deposito',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                            onValueChanged: (e) => {
                                var nomDeposito = e.value
                                var nomBunga = this.formRef.current.instance.getEditor('nominalBungaDiterima').option('value')
                                var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                this.countNetAmount(nomDeposito, nomBunga, biayaBank, pph)
                            }
                        },
                    },
                    {
                        dataField: 'ratePenempatan',
                        label: {
                            text: 'Rate Penempatan (%)',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'ratePencairan',
                        label: {
                            text: 'Rate Pencairan (%)',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0
                        },
                    },
                    {
                        dataField: 'tenor',
                        label: {
                            text: 'Tenor s/d Tanggal Pencairan',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'bungaBerjalan',
                        label: {
                            text: 'Bunga Berjalan',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'nominalBungaDiterima',
                        label: {
                            text: 'Nominal Bunga Diterima',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var tax = this.taxDep.intValue / 100
                                var result = e.value * tax
                                this.formRef.current.instance.updateData('pph', result)

                                var nomDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                var nomBunga = e.value
                                var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                this.countNetAmount(nomDeposito, nomBunga, biayaBank, pph)
                            }
                        },
                    },
                    {
                        dataField: 'biayaBank',
                        label: {
                            text: 'Biaya Bank/RTGS',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0,
                            onValueChanged: (e) => {
                                var nomDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                var nomBunga = this.formRef.current.instance.getEditor('nominalBungaDiterima').option('value')
                                var biayaBank = e.value
                                var pph = this.formRef.current.instance.getEditor('pph').option('value')
                                this.countNetAmount(nomDeposito, nomBunga, biayaBank, pph)
                            }
                        },
                    },
                    {
                        dataField: 'pph',
                        label: {
                            text: 'PPH',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                            onValueChanged: (e) => {
                                var nomDeposito = this.formRef.current.instance.getEditor('nominalDeposito').option('value')
                                var nomBunga = this.formRef.current.instance.getEditor('nominalBungaDiterima').option('value')
                                var biayaBank = this.formRef.current.instance.getEditor('biayaBank').option('value')
                                var pph = e.value
                                this.countNetAmount(nomDeposito, nomBunga, biayaBank, pph)
                            }
                        },
                    },
                    {
                        dataField: 'nominalPencairanBersih',
                        label: {
                            text: 'Nominal Pencairan Bersih',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang',
                            alignment: 'left'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            readOnly: true,
                        }
                    },
                    {
                        dataField: 'kurs',
                        label: {
                            text: 'Kurs',
                            alignment: 'left',
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            step: 0
                        },
                    },
                ]
            }
        ]
    }
    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Base64`]: formattedFileData.base64data,
                    [`${fieldName}Base64ContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }
    countNetAmount = (deposito, bunga, bank, pph) => {
        var result = deposito + bunga - bank - pph
        this.formRef.current.instance.updateData('nominalPencairanBersih', result)
    }
    submitData = async () => {
        var uploadFile = {
            fileName: this.state.fileName,
            fileBase64: this.state.fileBase64,
            fileBase64ContentType: this.state.fileBase64ContentType,
        }
        try {
            var responseFile = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                "file-investasis",
                "POST", {
                values: uploadFile
            }
            )
            if (responseFile) {
                await this.submitDataBreak(responseFile.id)
            } else {
                notify({ message: 'Terjadi Kesalahan System , Saat Upload File', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    submitDataBreak = async (fileKonfirmasiId) => {
        var data = this.state.dataMaster
        var dataMaster = {
            bankId: data.bankId,
            biayaBank: data.biayaBank,
            bungaBerjalan: data.bungaBerjalan,
            catatan: data.catatan,
            currencyId: data.currencyId,
            efekBalanceId: data.efekBalanceId,
            fileKonfirmasiId: fileKonfirmasiId,
            kpdId: data.kpdId,
            kurs: data.kurs,
            miId: data.miId,
            noBilyet: data.bilyetNo,
            nominalAccrued: data.nominalAccrued,
            nominalBungaDiterima: data.nominalBungaDiterima,
            nominalDeposito: data.nominalDeposito,
            nominalPencairanBersih: data.nominalPencairanBersih,
            pph: data.pph,
            productId: data.productId,
            ratePencairan: data.ratePencairan,
            ratePenempatan: data.ratePenempatan,
            rekPencairanId: data.rekPencairanId,
            tenor: data.tenor,
            tglPencairan: formatDate(data.tglPencairan)
        }
        if (dataMaster) {
            try {
                var loadAPI = 'efek-balance-avgs/deposito-pencairan-konfirmasi'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, "PUT", {
                        values: dataMaster,
                        key: dataMaster.efekBalanceId
                    }, dataMaster
                )
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                    notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                }
            } catch (e) {
                console.log(e)
                notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    show(data) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: data
        });
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: [],
        })
    }
    previewFile = async() => {
        var src = imageSource(this.state.fileBase64ContentType, this.state.fileBase64)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Break Deposito'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div id="tabindex-0" className={' mt-2 col-md-12'} style={{ height: "calc(57vh - 70px)" }}>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Form
                                        colCount={2}
                                        id={"formMaster"}
                                        formData={this.state.dataMaster}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation="left"
                                    />
                                    <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileBase64 ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Buka File
                                    </button>
                                </div>
                                <ModalPreviewPdf
                                    ref={this.modalPreviewPdfRef}
                                    store={this.props.store}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalBreakDeposito