import React, { Component } from 'react'
import {FileUploader,Popup,Form} from 'devextreme-react'
import { formatNumberAum } from 'plugin/helper'
import { pembayaranUmumDetilByPuId } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'

class ModalDetailInvoice extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{
                pph:0,
                ppn:0,
                nominal:0,
                potongan:0,
                nominalBersih:0
            }
        } 
        
        this.DataMaster = [
            {
                dataField: 'pembayaranUmumDetilId',
                label: {
                    alignment: 'left',
                    text: 'Pembayaraan Detail'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.keterangan + '@' + formatNumberAum(e.pengajuanNominal)
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged:(e) => {
                        var item = e.selectedItem
                        if (e.selectedItem) {
                            this.formRef.current.instance.updateData({
                                keterangan: item.keterangan,
                                nominal: item.pengajuanNominal,
                                ppn: item.ppn,
                                pph: item.pph,
                                potongan: item.potongan,
                                nominalBersih: item.pengajuanNominalNett,
                            })
                        }
                    }
                }
            },
            {
                dataField:'keterangan',
                label:{
                    text:'Keterangan'
                }
            },
            {
                dataField:'nominal',
                label:{
                    text:'Nominal'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:"#,##0.00",
                    readOnly:true,
                    // onValueChanged:(e) => {
                    //     var DataMaster = {...this.state.DataMaster}
                    //     DataMaster.nominalBersih = e.value + DataMaster.ppn - DataMaster.pph - DataMaster.potongan
                    //     this.formRef.current.instance.updateData({nominalBersih:DataMaster.nominalBersih})
                    // }
                }
            },
            {
                dataField:'ppn',
                label:{
                    text:'PPN'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:"#,##0.00",
                    readOnly:true,
                    // onValueChanged:(e) => {
                    //     var DataMaster = {...this.state.DataMaster}
                    //     DataMaster.nominalBersih = DataMaster.nominal + e.value - DataMaster.pph - DataMaster.potongan
                    //     this.formRef.current.instance.updateData({nominalBersih:DataMaster.nominalBersih})
                    // }
                }
            },
            {
                dataField:'pph',
                label:{
                    text:'PPH'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:"#,##0.00",
                    readOnly:true,
                    // onValueChanged:(e) => {
                    //     var DataMaster = {...this.state.DataMaster}
                    //     DataMaster.nominalBersih = DataMaster.nominal + DataMaster.ppn - e.value - DataMaster.potongan
                    //     this.formRef.current.instance.updateData({nominalBersih:DataMaster.nominalBersih})
                    // }
                }
            },
            {
                dataField:'potongan',
                label:{
                    text:'Potongan'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    format:"#,##0.00",
                    readOnly:true,
                    // onValueChanged:(e) => {
                    //     var DataMaster = {...this.state.DataMaster}
                    //     DataMaster.nominalBersih = DataMaster.nominal + DataMaster.ppn - DataMaster.pph - e.value
                    //     this.formRef.current.instance.updateData({nominalBersih:DataMaster.nominalBersih})
                    // }
                }
            },
            {
                dataField:'nominalBersih',
                label:{
                    text:'Nominal Bersih'
                },
                editorType:'dxNumberBox',
                editorOptions:{
                    readOnly:true,
                    format:"#,##0.00"
                }
            },
        ]
        
        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Simpan',
                    onClick: () => {
                        this.props.getDataSource(this.state.DataMaster)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.PopupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.readOnly = false
        this.formRef = React.createRef()
    }

    getNoPengajuan = async (id) => {
        var loadApi = `pembayaran-umums/${id}`
        var getData = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'GET')

        var loadApiDetail = `pembayaran-umum-detils/getByPembayaranUmumId/${id}`
        var getDataDetail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApiDetail, 'GET')

        var totalJumlahCo = 0

        for (var value of getDataDetail) {
            totalJumlahCo += value.cashOut || 0
        }
        getData.nominalCashOut = totalJumlahCo

        var pdId = this.formRef.current.instance.getEditor('pembayaranUmumDetilId')
        // pdId.option('value', '')
        if (getData.id) {
            pdId.option('dataSource', getDataDetail)
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    }

    hide = () => {
        this.readOnly = false
        this.setState({
            popupVisible: false,
            DataMaster:{
                pph:0,
                ppn:0,
                nominal:0,
                potongan:0,
                nominalBersih:0
            }
        })
    }

    retrieveData = (data, readOnly) => {
        this.readOnly = readOnly
        this.setState({
            DataMaster : data
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Detail Invoice'}
                width={700}
                height={500}

                ref={this.PopupRef}

                toolbarItems = {this.readOnly ? this.PopupToolbarItem2 : this.PopupToolbarItem}
            >
                <Form
                    ref={this.formRef} 
                    colCount={1}
                    id={'formDataAkun'}
                    formData={this.state.DataMaster}
                    items={this.DataMaster}
                    cssClass="mt-3"
                    labelLocation='left'
                    // readOnly={this.readOnly}
                />
            </Popup>
        )
    }
}

export default ModalDetailInvoice