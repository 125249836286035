import React, { Component } from 'react'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import DataGridSaham from './comp/dataGridSaham';
import DataGridObligasi from './comp/dataGridObligasi';
import DataGridReksadana from './comp/dataGridReksadana';
import { addURL } from 'redux/actions/url';

class PengajuanPenempatan extends Component {
    constructor(props){
        super(props)

        this.state = {  
            
        }

        this.prevTabIndex = 0
        
        this.columns = [
            {
                dataField: 'tanggal',
                caption: 'Tanggal',
                dataType: 'date',  
                format: 'dd MMM yyyy H:m:s',
                sortOrder: 'desc'
            },
            {
                dataField: 'nomorPengajuan',
                caption: 'Nomor Pengajuan',
            },
            {
                dataField: 'kodeObligasi',
                caption: 'Kode Obligasi',
            },
            {
                dataField: 'namaObligasi',
                caption: 'Nama Obligasi',
            },
            {
                dataField: 'kodeBroker',
                caption: 'Kode Broker',
            },
            {
                dataField: 'namaBroker',
                caption: 'Nama Broker',
            },
            {
                dataField: 'nominal',
                caption: 'Nominal',
                format: '#,##0.00'
            },
        ]

        this.TabDataSource = [
            {
                id : 0,
                text: 'Saham'
            },
            {
                id : 1,
                text: 'Obligasi'
            },
            {
                id : 2,
                text: 'Reksadana'
            },
        ]  

        this.DataGridSahamRef = React.createRef()
        this.DataGridObligasiRef = React.createRef()
        this.DataGridReksadanaRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onTabSelectionChanged = (args) =>{
        console.log(this.prevTabIndex,args)

        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pengajuan Penempatan Investasi</h3>

                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                
                <div id="tabindex-0" className={' mt-2 text-center' } style={{height: '100%'}}>
                    <DataGridSaham store = {this.props.store} ref={this.DataGridSahamRef} columns={this.columns} />
                </div>
                <div id="tabindex-1" className={'d-none mt-2 text-center' } style={{height: '100%'}}>
                    <DataGridObligasi store = {this.props.store} ref={this.DataGridObligasiRef} columns={this.columns} />
                </div>
                <div id="tabindex-2" className={'d-none mt-2 text-center' } style={{height: '100%'}}>
                    <DataGridReksadana store = {this.props.store} ref={this.DataGridReksadanaRef} columns={this.columns} />
                </div>
            </div>
        )
    }
}

export default PengajuanPenempatan
