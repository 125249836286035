import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import { kasUmum, programSdm, rekBankHrd } from 'dataSource/lookup';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';

class FormModalKonfirmasiPengisianKasUmum extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            dataMaster:{}
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField:'programId',
                        label:{
                            text:'Program'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programSdm(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                    {
                        dataField:'transactionNo',
                        label:{
                            text:'No Transaksi'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField:'kasUmumId',
                        label:{
                            text:'Kas Umum'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kasUmum(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'namaKas', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'pengajuan',
                caption:'Pengajuan',
                items: [
                    {
                        dataField:'transactionDate',
                        label:{
                            text:'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true
                        }
                    },
                    {
                        dataField:'requestAmount',
                        label:{
                            text:'Nominal Pengajuan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        }
                    },
                    {
                        dataField:'description',
                        label:{
                            text:'Keterangan Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                name: 'penerimaan',
                caption:'Penerimaan',
                items: [
                    {
                        dataField:'receivedDate',
                        label:{
                            text:'Tanggal Penerimaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField:'receivedAmount',
                        label:{
                            text:'Nominal Penerimaan'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00",
                        }
                    },
                ]
            },
            // {
            //     itemType: 'group',
            //     name: 'sumberPembayaran',
            //     caption:'Sumber Pembayaran',
            //     items: [
            //         {
            //             dataField:'rekBankId',
            //             label:{
            //                 text:'Rekening Bank'
            //             },
            //             editorType: 'dxSelectBox',
            //             editorOptions: {
            //                 dataSource: rekBankHrd(this.props.store),
            //                 valueExpr: 'id',
            //                 displayExpr: 'rekName', // provides display values
            //                 searchEnabled: true,
            //                 deferRendering: false,
            //             }
            //         },
            //     ]
            // },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
    }

    retrieveData = (data) => {
        this.setState({
            dataMaster: data,
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.setState({
            dataMaster: {},
            popupVisible: false
        })
    }


    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}
        try {
            var tanggalPengajuan = (new Date(dataSubmit.transactionDate)).getTimezoneOffset() * 60000; //offset in milliseconds
            tanggalPengajuan = (new Date(new Date(dataSubmit.transactionDate) - tanggalPengajuan)).toISOString();

            var tanggalPenerimaan = (new Date(dataSubmit.receivedDate)).getTimezoneOffset() * 60000; //offset in milliseconds
            tanggalPenerimaan = (new Date(new Date(dataSubmit.receivedDate) - tanggalPenerimaan)).toISOString();
            
            var karyawanUrl = 'karyawans/getByUsername/'+this.props.store.getState().sessionUser
            var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,karyawanUrl,'GET')

            var entitiUrl = 'entitis/findByTypeAndReff/KR/{reff}?reff='+getKaryawan.id
            var getEntiti = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,entitiUrl,'GET')

            var postData = {
                "id" : dataSubmit.id,
                "kasUmumId": dataSubmit.kasUmumId,
                "keteranganPenggunaan": dataSubmit.description,
                "noPengajuan": dataSubmit.transactionNo,
                "nominalPenerimaan": dataSubmit.receivedAmount,
                "nominalPengajuan": dataSubmit.requestAmount,
                "programId": dataSubmit.programId,
                "rekeningBankId": dataSubmit.rekBankId,
                "tanggalPenerimaan": tanggalPenerimaan,
                "tanggalPengajuan": tanggalPengajuan,
                "username" : this.props.store.getState().sessionUser,
                "entityId" : getEntiti.id
            }

            var postAPI = 'kas-umum-receives-konfirmasi'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,postAPI,'POST',{
                values: postData
            })

            let type = 'success'
            var text = 'Data Berhasil Dikonfirmasi!'

            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);

            this.hide()
            this.props.forceRefresh()
        } catch (e) {
            console.log(e)

            notify({ message: e, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Konfirmasi Pengisian Kas Umum'} 
                minWidth={"100vh"}
                minHeight={"40vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
                <Form
                    ref={this.formRef}
                    colCount={1}
                    id={'formPotongan'}
                    formData={this.state.dataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
            </ScrollView>
            </Popup>
        )
    }
}

export default FormModalKonfirmasiPengisianKasUmum 