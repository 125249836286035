import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { currency, banks, productDefault, fundManager, instrumentSubCategoryById, rekeningBank, kpds, depositoNeddConfirmByBank, depositoWasSent, instrumentSubCategoryDeposito, instrumentTransactionTypes, instruments, rekBank } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import ScrollView from 'devextreme-react/scroll-view';

class ModalActionSettlementTransaksiProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: null
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridPenempatanRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.items = [
            {
                dataField: 'orderId',
                label: {
                    alignment: 'left',
                    text: 'No Properti'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'orderCode',
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: async (e) => {
                        var data = e.selectedItem
                        if (data) {
                            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-propertis/findByOrderId/${data.id}`, 'GET')
                            let instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`, 'GET')
                            this.formRef.current.instance.updateData({
                                transactionTypeId: data.orderTypeId,
                                instrumentName: instrument.instrumentName,
                                instrumentId: data.instrumentId,
                                orderPropertiId: response.id
                            })
                        }
                    }
                }
            },
            {
                dataField: 'orderPropertiId',
                label: {
                    alignment: 'left',
                    text: 'Order Properti ID'
                },
                visible: false
            },
            {
                dataField: 'transactionTypeId',
                label: {
                    alignment: 'left',
                    text: 'Transaksi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName',
                    readOnly: true,
                    onValueChanged: (e) => {
                        var orderTypeId = e.value
                        if (orderTypeId == 327) {
                            let uangMuka = this.formRef.current.instance.getEditor('uangMuka').option('value')
                            this.formRef.current.instance.updateData('nilaiTransaksi', uangMuka)
                        } else {
                            let nominal = this.formRef.current.instance.getEditor('hargaUnit').option('value')
                            this.formRef.current.instance.updateData('nilaiTransaksi', nominal)
                        }
                    }
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    alignment: 'left',
                    text: 'Properti'
                },
                visible: false,
                // editorType: 'dxSelectBox',
            },
            {
                dataField: 'instrumentName',
                label: {
                    alignment: 'left',
                    text: 'Properti'
                },
                // editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: instruments(this.props.store),
                    // valueExpr: 'id',
                    // displayExpr: 'instrumentName',
                    readOnly: true,
                }
            },
            {
                dataField: 'tipe',
                label: {
                    alignment: 'left',
                    text: 'Tipe/Unit/Kav'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    step: 0,
                }
            },
            {
                dataField: 'keterangan',
                label: {
                    alignment: 'left',
                    text: 'Keterangan Unit/Kav'
                },
            },
            {
                dataField: 'pembeli',
                label: {
                    alignment: 'left',
                    text: 'Pembeli'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'hargaUnit',
                label: {
                    alignment: 'left',
                    text: 'Harga /unit'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        if (orderTypeId != 327) {
                            this.formRef.current.instance.updateData('nilaiTransaksi', e.value)
                        }
                    }
                }
            },
            {
                dataField: 'uangMuka',
                label: {
                    alignment: 'left',
                    text: 'Uang Muka'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        if (orderTypeId == 327) {
                            this.formRef.current.instance.updateData('nilaiTransaksi', e.value)
                        }
                    }
                }
            },
            {
                dataField: 'nilaiTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Nilai Transaksi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    readOnly: true,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        var nilaiTrs = e.value
                        var komisiNom = this.formRef.current.instance.getEditor('kominiNominal').option('value')
                        var ppn = this.formRef.current.instance.getEditor('ppn').option('value')
                        var ppnBm = this.formRef.current.instance.getEditor('ppnBm').option('value')
                        var pbb = this.formRef.current.instance.getEditor('pbb').option('value')
                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                        var biayaLain = this.formRef.current.instance.getEditor('biayaLain').option('value')
                        this.countTrsBersih(orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain)
                    }
                }
            },
            {
                dataField: 'komisi',
                label: {
                    alignment: 'left',
                    text: 'Komisi %'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var nilaiTrs = this.formRef.current.instance.getEditor('nilaiTransaksi').option('value')
                        var result = nilaiTrs * (e.value / 100)
                        this.formRef.current.instance.updateData('kominiNominal', result)
                    }
                }
            },
            {
                dataField: 'kominiNominal',
                label: {
                    alignment: 'left',
                    text: 'Komisi Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        var nilaiTrs = this.formRef.current.instance.getEditor('nilaiTransaksi').option('value')
                        var komisiNom = e.value
                        var ppn = this.formRef.current.instance.getEditor('ppn').option('value')
                        var ppnBm = this.formRef.current.instance.getEditor('ppnBm').option('value')
                        var pbb = this.formRef.current.instance.getEditor('pbb').option('value')
                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                        var biayaLain = this.formRef.current.instance.getEditor('biayaLain').option('value')
                        this.countTrsBersih(orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain)
                    }
                }
            },
            {
                dataField: 'ppn',
                label: {
                    alignment: 'left',
                    text: 'PPN'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        var nilaiTrs = this.formRef.current.instance.getEditor('nilaiTransaksi').option('value')
                        var komisiNom = this.formRef.current.instance.getEditor('kominiNominal').option('value')
                        var ppn = e.value
                        var ppnBm = this.formRef.current.instance.getEditor('ppnBm').option('value')
                        var pbb = this.formRef.current.instance.getEditor('pbb').option('value')
                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                        var biayaLain = this.formRef.current.instance.getEditor('biayaLain').option('value')
                        this.countTrsBersih(orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain)
                    }
                }
            },
            {
                dataField: 'ppnBm',
                label: {
                    alignment: 'left',
                    text: 'PPN BM'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        var nilaiTrs = this.formRef.current.instance.getEditor('nilaiTransaksi').option('value')
                        var komisiNom = this.formRef.current.instance.getEditor('kominiNominal').option('value')
                        var ppn = this.formRef.current.instance.getEditor('ppn').option('value')
                        var ppnBm = e.value
                        var pbb = this.formRef.current.instance.getEditor('pbb').option('value')
                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                        var biayaLain = this.formRef.current.instance.getEditor('biayaLain').option('value')
                        this.countTrsBersih(orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain)
                    }
                }
            },
            {
                dataField: 'pbb',
                label: {
                    alignment: 'left',
                    text: 'PBB'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        var nilaiTrs = this.formRef.current.instance.getEditor('nilaiTransaksi').option('value')
                        var komisiNom = this.formRef.current.instance.getEditor('kominiNominal').option('value')
                        var ppn = this.formRef.current.instance.getEditor('ppn').option('value')
                        var ppnBm = this.formRef.current.instance.getEditor('ppnBm').option('value')
                        var pbb = e.value
                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                        var biayaLain = this.formRef.current.instance.getEditor('biayaLain').option('value')
                        this.countTrsBersih(orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain)
                    }
                }
            },
            {
                dataField: 'pph',
                label: {
                    alignment: 'left',
                    text: 'PPH'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        var nilaiTrs = this.formRef.current.instance.getEditor('nilaiTransaksi').option('value')
                        var komisiNom = this.formRef.current.instance.getEditor('kominiNominal').option('value')
                        var ppn = this.formRef.current.instance.getEditor('ppn').option('value')
                        var ppnBm = this.formRef.current.instance.getEditor('ppnBm').option('value')
                        var pbb = this.formRef.current.instance.getEditor('pbb').option('value')
                        var pph = e.value
                        var biayaLain = this.formRef.current.instance.getEditor('biayaLain').option('value')
                        this.countTrsBersih(orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain)
                    }
                }
            },
            {
                dataField: 'biayaLain',
                label: {
                    alignment: 'left',
                    text: 'Biaya Lain'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var orderTypeId = this.formRef.current.instance.getEditor('transactionTypeId').option('value')
                        var nilaiTrs = this.formRef.current.instance.getEditor('nilaiTransaksi').option('value')
                        var komisiNom = this.formRef.current.instance.getEditor('kominiNominal').option('value')
                        var ppn = this.formRef.current.instance.getEditor('ppn').option('value')
                        var ppnBm = this.formRef.current.instance.getEditor('ppnBm').option('value')
                        var pbb = this.formRef.current.instance.getEditor('pbb').option('value')
                        var pph = this.formRef.current.instance.getEditor('pph').option('value')
                        var biayaLain = e.value
                        this.countTrsBersih(orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain)
                    }
                }
            },
            {
                dataField: 'nilaiTransaksiBersih',
                label: {
                    alignment: 'left',
                    text: 'Nilai Transaksi Bersih'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Mata Uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    searchEnabled: true,
                    onSelectionChanged: (e) => {
                        let data = e.selectedItem
                        if (data) {
                            this.formRef.current.instance.updateData('kurs', data.rate)
                        }
                    }
                }
            },
            {
                dataField: 'kurs',
                label: {
                    alignment: 'left',
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'biayaBankRtgs',
                label: {
                    alignment: 'left',
                    text: 'Biaya Bank / RTGS'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                }
            },
            {
                dataField: 'rekeningTransferId',
                label: {
                    alignment: 'left',
                    text: 'Rekening Transfer Bayar'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: rekBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                    searchEnabled: true,
                }
            },
        ]
    }
    insertLookup = async (store, productId, transactionDate) => {
        let loadAPI = `orders?orderDate.equals=${transactionDate}&orderTypeId.in=284&orderTypeId.in=283&productId.in=${productId}&statusId.in=OPEN`
        var getOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')
        var getPembeli = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllByType/4`, 'GET')
        var getOrderType = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instrument-transaction-types?instrumentCategoryId.in=8`, 'GET')

        let dataSource = new ArrayStore({
            data: getOrder,
            key: 'id'
        });

        let dataPembeli = new ArrayStore({
            data: getPembeli,
            key: 'id'
        });

        let dataOrderType = new ArrayStore({
            data: getOrderType,
            key: 'id'
        })

        this.formRef.current.instance.getEditor('orderId').option('dataSource', dataSource)
        this.formRef.current.instance.getEditor('pembeli').option('dataSource', dataPembeli)
        this.formRef.current.instance.getEditor('transactionTypeId').option('dataSource', dataOrderType)
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    countTrsBersih = (orderTypeId, nilaiTrs, komisiNom, ppn, ppnBm, pbb, pph, biayaLain) => {
        if (orderTypeId == 284) {
            // Penempatan
            let result = nilaiTrs + komisiNom + ppn + ppnBm + biayaLain - pbb - pph
            this.formRef.current.instance.updateData('nilaiTransaksiBersih', result)
        } else if (orderTypeId == 283) {
            // Penjualan
            let result = nilaiTrs - pbb - pph - biayaLain
            this.formRef.current.instance.updateData('nilaiTransaksiBersih', result)
        } else if (orderTypeId == 327) {
            // Pembayaran Uang Muka
            let result = nilaiTrs + komisiNom + ppn + ppnBm + biayaLain - pbb - pph
            this.formRef.current.instance.updateData('nilaiTransaksiBersih', result)
        } else {
            let result = nilaiTrs + komisiNom + ppn + ppnBm + biayaLain - pbb - pph
            this.formRef.current.instance.updateData('nilaiTransaksiBersih', result)
        }
    }
    show(data) {
        this.insertLookup(this.props.store, data.productId, data.transactionDate)
        this.setState({
            popupVisible: true,
            dataMaster: {
                id: data.id,
                biayaBankRtgs: data.biayaBankRtgs,
                biayaLain: data.biayaLain,
                currencyId: data.currencyId,
                hargaUnit: data.hargaUnit,
                instrumentId: data.instrumentId,
                keterangan: data.keterangan,
                kominiNominal: data.kominiNominal,
                komisi: data.komisi,
                kurs: data.kurs,
                nilaiTransaksi: data.nilaiTransaksi,
                nilaiTransaksiBersih: data.nilaiTransaksiBersih,
                orderPropertiId: data.orderPropertiId,
                orderId: data.orderId,
                pbb: data.pbb,
                pembeli: data.pembeli,
                pph: data.pph,
                ppn: data.ppn,
                ppnBm: data.ppnBm,
                properti: data.properti,
                rekeningTransferId: data.rekeningTransferId,
                tipe: data.tipe,
                transactionTypeId: data.transactionTypeId
            }
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    submitData = () => {
        var data = this.state.dataMaster
        this.props.submitDetailPenempatanData({
            id: data.id,
            biayaBankRtgs: data.biayaBankRtgs,
            biayaLain: data.biayaLain,
            currencyId: data.currencyId,
            hargaUnit: data.hargaUnit,
            instrumentId: data.instrumentId,
            keterangan: data.keterangan,
            kominiNominal: data.kominiNominal,
            komisi: data.komisi,
            kurs: data.kurs,
            nilaiTransaksi: data.nilaiTransaksi,
            nilaiTransaksiBersih: data.nilaiTransaksiBersih,
            orderPropertiId: data.orderPropertiId,
            orderId: data.orderId,
            pbb: data.pbb,
            pembeli: data.pembeli,
            pph: data.pph,
            ppn: data.ppn,
            ppnBm: data.ppnBm,
            properti: data.properti,
            rekeningTransferId: data.rekeningTransferId,
            tipe: data.tipe,
            transactionTypeId: data.transactionTypeId
        })
        this.hide()
    }
    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Properti'}
                width={'40vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <ScrollView>
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    ref={this.formRef}
                                    formData={this.state.dataMaster}
                                    items={this.items}
                                    scrollingEnabled={true}
                                    height={'calc(70vh - 60px)'}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                    </ScrollView>
                </div>
            </Popup>
        )
    }
}

export default ModalActionSettlementTransaksiProperti