import React, { Component } from 'react'
import { product, pesertaAkunStatus } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ProsesDataDplk from './actionProsesDplk';
import httpRequest from 'plugin/httprequest';

class FormDataDplk extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            pesertaId: 0,
            dataPeserta: {}
        }

        this.formRef = React.createRef()
        this.showModalActionViewRef = React.createRef()
        this.DataGridAkunProductRef = React.createRef()

        this.formDataPensiun = {}

        this.dataGridAkun = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalAction(e.row.data, 'edit')
                        }
                    },
                    // {
                    //     text: 'Buka',
                    //     hint: 'Buka',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.showModalAction(e.row.data, 'view')
                    //     }
                    // },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'cardNo',
                caption: 'Card No',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'akunStatusId',
                caption: 'Status Akun Peserta',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]

        this.dataGridAkunView = [
            {
                type: 'buttons',
                buttons: [
                    // {
                    //     text: 'Proses',
                    //     hint: 'Proses',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.showModalAction(e.row.data, 'edit')
                    //     }
                    // },
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalAction(e.row.data, 'view')
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'cardNo',
                caption: 'Card No',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'akunStatusId',
                caption: 'Status Akun Peserta',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]
    }

    passDataToParent = () => {
        var data = {
            dataPensiun: this.state.formDataPensiun,
            dataGridPensiun: this.DataGridAkunProductRef.current.getDataSource(),
        }
        return data
    }
    removeState = () => {
        this.setState({
            // pesertaId: 0,
            formData: {},
        })
    }

    removeItemDataGrid = () => {
        var beforeData = this.DataGridAkunProductRef.current.getDataSource()
        for (var deleteData of beforeData) {
            this.deleteDataProduct(deleteData)
        }
    }

    reloadData = async (pesertaId = 0, dataAkun) => {
        try {
                if (this.props.actionType === 'add') {
                    this.forceRefresh();
                }
                var loadAPI = `pesertas/${pesertaId}`
                var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        
                var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${pesertaId}`
                var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIKepegawaian, 'GET')
        
                var dataPeserta = {
                    ...getPeserta,
                    phdp: getKepegawaian.phdp
                }
        
                this.setState({
                    dataPeserta: dataPeserta,
                    formData: dataAkun,
                    pesertaId: pesertaId
                })
        } catch (error) {
            console.log(error);
        }

        // this.forceRefresh(true)
    }

    retrieveData = async () => {
        try {
            var pesertaId = this.state.pesertaId
            var akunId = this.state.formData.id || this.state.formData.ids
            var result = []
            if (akunId) {
                var loadAPI = `kepersertaan-products/${akunId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                result.push(response)
            } else if (pesertaId) {
                var loadAPI = `kepersertaan-products/getAllSimpleProductByPesertaId/${pesertaId}`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                for (var value of response) {
                    if (this.props.actionType == 'add') {
                        value.akunStatusId = value.statusId || value.akunStatusId
                        if (value.statusId == 1 || value.statusId == 2 || value.statusId == 3 || value.statusId == 6) {
                            result.push(value)
                        }
                    } else {
                        if (value.statusId == 1 || value.statusId == 6) {
                            result.push(value)
                        }
                    }
                }
            }
            return result
        } catch (error) {
            console.log(error);
        }
    }

    showModalAction = (data, type) => {
        if (type == 'edit') {
            this.showModalActionViewRef.current.show()
            this.showModalActionViewRef.current.retrieveData(data, type)
        } else {
            this.showModalActionViewRef.current.show()
            this.showModalActionViewRef.current.retrieveData(data, type)
        }
    }

    forceRefresh = () => {
        this.DataGridAkunProductRef.current.forceRefresh(true)
        let data = this.DataGridAkunProductRef.current.getDataSource()
        let deleteData = [];
        if (data) {
            for (let value of data) {
                deleteData.push(
                    {
                        type: 'remove',
                        key: value.id
                    }
                )
            }
            console.log(deleteData);
            this.DataGridAkunProductRef.current.push(deleteData);
        }
    }

    deleteDataProduct = (data) => {
        this.DataGridPensiunRef.current.push([
            {
                type: 'remove',
                key: data.id
            }
        ])
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    // visible: this.state.selectedTabIndex == 0 ? false : true,
                    hint: "Muat Ulang",
                    onClick: (e) => {
                        this.forceRefresh()
                    }
                }
            }
        );
    }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.DataGridAkunProductRef}
                    loadAPI='kepesertaan-akun-products'
                    insertAPI='kepesertaan-akun-products'
                    updateAPI='kepesertaan-akun-products'
                    deleteAPI='kepesertaan-akun-products'

                    useArraySource={true}
                    ArraySourceData={this.retrieveData}

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Akun PPMP'}
                    popupWidth={1000} //masukan dalam ukuran pixel
                    popupHeight={450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='top' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.props.actionType === 'add' ? this.dataGridAkun : this.dataGridAkunView} // taruh konfigurasi kolom disini
                    // onToolbarPreparing={this.onToolbarPreparingDataAkun}
                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ProsesDataDplk
                    ref={this.showModalActionViewRef}
                    store={this.props.store}
                    submitData={this.props.submitData}
                    actionType={this.props.actionType}
                    reloadData={this.reloadData}
                    forceRefresh={this.props.forceRefresh}
                    removeItemDataGrid={this.removeItemDataGrid}
                    peridoeNs={this.props.peridoeNs}
                // ArraySourceData={true}
                // product={'ppip'}
                />
            </React.Fragment>
        )
    }
}

export default FormDataDplk
