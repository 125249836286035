import React, { Component } from 'react'
import { Popup,Form } from 'devextreme-react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormHasilEvaluasi from './formHasilEvaluasi'
import FormHasilEvaluasiNew from './formHasilEvaluasiNew'
import httpRequest from 'plugin/httprequest'
import { karyawan } from 'dataSource/lookup'
import { Redirect } from 'react-router'

class HasilEvaluasiKinerja extends Component {
    constructor(props){
        super(props)

        this.state = {
            redirectBack:false,
            popupVisible: false,
            DataDocument:{},
            DataMaster:{}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.showModalFormHasilEvaluasiRef = React.createRef()
        this.idKinerja = 0

        this.form = [
            {
                itemType:'group',
                colCount:1,
                items:[
                    {
                        dataField: 'periodeTahun',
                        label: {
                            text: 'Periode'
                        },
                        // editorType: 'dxDateBox',
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField: 'tanggalEvaluasi',
                        label: {
                            text: 'Tanggal Evaluasi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy',
                            readOnly:true
                        }
                    },
                ]
            }
            
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : (e) => {
                            // this.showModal(e.row.data)  
                        } 
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : (e) => {
                            // this.showModal(e.row.data)  
                        } 
                    },
                    {
                        text : 'Penyesuaian Gaji',
                        hint : 'Penyesuaian Gaji',
                        icon: "info",
                        cssClass: "text-info", 
                        onClick : (e) => {  
                            this.setRedirectBack()  
                        } 
                    },
                ],
            },
            {
                dataField:"karyawanId",
                caption:"Nama Karyawan",
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: (item) => {
                        return item && item.nik + ' - ' + item.fullName 
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"createdDate",
                caption:"Tanggal Pengangkatan",
                dataType:'date',
                format:'dd MMM yyyy',
            },
            {
                dataField:"evaluasiDate",
                caption:"Tanggal Penilaian",
                dataType:'date',
                format:'dd MMM yyyy',
            },
            {
                dataField:"hasilEvaluasi",
                caption:"Hasil Evaluasi",
                columns:[
                    {
                        dataField:"evaluasiSkorTotal",
                        caption:"Total Skor" 
                    },
                    {
                        dataField:"evaluasiSkorDescription",
                        caption:"Kinerja" 
                    },
                ]
            },
        ]
    }

    retrieveData = (data) => {
        this.dataGridRef.current.forceRefresh(true);
        this.idKinerja = data.id
        this.setState({
            DataMaster: data
        })
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.idKinerja = 0
        this.setState({
            redirectBack:false,
            popupVisible: false,
            DataDocument:{},
            DataMaster:{}
        })
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text:'Karyawan',
                    onClick: (e) => {
                        this.showModalFormHasilEvaluasi(e)
                    },
                }
            }
        )
    }

    showModalFormHasilEvaluasi = (e) => {
        if (e.value) {
            this.showModalFormHasilEvaluasiRef.current.retrieveData(e.row.data)
        }
        this.showModalFormHasilEvaluasiRef.current.show()
        this.showModalFormHasilEvaluasiRef.current.getIdEvaluasiKinerja(this.idKinerja)
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'evaluasi-karyawans',"GET");
        response = response.filter(value => value.evaluasiKinerjaId === this.idKinerja)

        if (response.length !== 0) {
            this.forceRefresh();
            return response
        } else {
            return []
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }


    setRedirectBack = () => {
        this.setState({
            redirectBack: true
        })
    }

    RedirectBack = () => {
        if (this.state.redirectBack) {
            return <Redirect to='/sdm-umum/kepegawaian/karyawan/gaji-tunjangan' />
        }
    }

    render() {
        return (
            <React.Fragment>
            {this.RedirectBack()}
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Hasil Evaluasi"}
                width={'70vw'}
                height={'90vh'}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto',}}>
                        <Form
                            ref={this.formRef}
                            colCount={3}
                            id={'formPengajuanPemberhentian'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation = 'left'
                        />
                        <DevExpressDataGrid
                            ref = {this.dataGridRef}
                            loadAPI='evaluasi-kinerjas'
                            insertAPI='evaluasi-kinerjas'
                            updateAPI='evaluasi-kinerjas'
                            deleteAPI='evaluasi-kinerjas' 

                            backendserver={process.env.REACT_APP_BACKEND_HRD}
                            useArraySource={true}
                            ArraySourceData={this.loadData}
                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={true}
                            exportFileName={"Evaluasi Kinerja"}
                            allowExportSelectedData={true}
                            selection={"multiple"}
                            
                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Evaluasi Kinerja'}
                            popupWidth={450} //masukan dalam ukuran pixel
                            popupHeight={330} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            onToolbarPreparing = {this.onToolbarPreparing}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                    <FormHasilEvaluasiNew
                        ref={this.showModalFormHasilEvaluasiRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

export default HasilEvaluasiKinerja