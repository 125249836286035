import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { instrumentCategorySubs, banks } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import {summaryValue} from 'plugin/helper'

class DataGridDeposito extends Component {
    constructor(props) {
        super(props)

        this.summary = [
            {  
                displayFormat: 'Total',
                showInColumn : 'tanggalJatuhTempo'
            },
            {
                name : 'saldoNominal',
                column: 'saldoNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'bungaBerjalan',
                column: 'bungaBerjalan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
            {
                name : 'percentKomposisi',
                column: 'percentKomposisi',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat : '{0}'
            },
        ]

        this.columns = [
            {
                dataField: 'no.',
                alignment: 'right',
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'noBilyet',
                caption: 'Nomor Bilyet',
            },
            {
                dataField: 'bankName',
                caption: 'Bank',
                // lookup: {
                //     dataSource: banks(this.props.store),
                //     valueExpr: "id", // contains the same values as the "statusId" field provides
                //     displayExpr: "bankName" // provides display values
                // }
            },
            {
                dataField: 'jenisDeposito',
                caption: 'Jenis',
                // lookup: {
                //     dataSource: instrumentCategorySubs(this.props.store),
                //     valueExpr: "id", // contains the same values as the "statusId" field provides
                //     displayExpr: "subName" // provides display values
                // }
            },
            {
                dataField: 'kpdName',
                caption: 'Pengelola'
            },
            {
                dataField: 'tglPenempatan',
                caption: 'Tanggal Penempatan',
                dataType: 'date',  
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'tenor',
                caption: 'Tenor',
                alignment: 'right'
            },
            {
                dataField: 'tenorType',
                caption: 'Tipe Tenor',
            },
            {
                dataField: 'tglJatuhTempo',
                caption: 'Tanggal Jatuh Tempo',
                dataType: 'date',  
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'saldoNominal',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'rate',
                caption: 'Rate (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'bungaBerjalan',
                caption: 'Bunga Berjalan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'percentKomposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
    }

    // retrieveData = async(param) => {
    //     var loadAPIEfekBalance = `efek-balance-avgs${param}`
    //     var getEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store, loadAPIEfekBalance, 'GET')
    //     var result = []
    //     var efekQty = getEfekBalance.filter((val) => val.quantity > 0)
    //     var filterBilyet = getEfekBalance.filter((val) => val.bilyetNo != null && val.quantity > 0)
    //     var totalAvgPrice = summaryValue(efekQty,'avgPrice')
    //     for(var master of filterBilyet){
    //         var komposisi
    //         if(master.quantity > 0){
    //             komposisi = (master.avgPrice / totalAvgPrice) * 100
    //         }
    //         // if(getEfekBalance.length == 1){
    //         //     komposisi = 100
    //         // }
    //         var loadAPIInstrument = `instruments/${master.instrumentId}`
    //         var getInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store, loadAPIInstrument, 'GET')
    //         var data = {
    //             id: master.id,
    //             bilyetNo: master.bilyetNo,
    //             bankId: getInstrument.bankId,
    //             categorySubId: getInstrument.categorySubId,
    //             balanceDate: master.balanceDate,
    //             tenor: getInstrument.tenor,
    //             tenorType: getInstrument.tenorTypeId,
    //             tanggalJatuhTempo: getInstrument.maturityDate,
    //             faceValue : master.faceValue,
    //             rate: getInstrument.rate,
    //             bungaBerjalan: master.currencyAccruedInterest,
    //             komposisi: komposisi
    //         }

    //         result.push(data)
    //     }
        
    //     return result
    // }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    keyField='noBilyet'
                    loadAPI={`efek-balance-avgs/instrument-portofolio-deposito?productId=${this.props.productId}`}
                    insertAPI='efek-balance-avgs'
                    updateAPI='efek-balance-avgs'
                    deleteAPI='efek-balance-avgs' 

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.retrieveData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Portofolio Deposito"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Portofolio Deposito'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    summaryTotalItem={this.summary}
                    // onToolbarPreparing = {this.onToolbarPreparing}

                    height = {'calc(100vh - 336px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </React.Fragment>
        )
    }
}

export default DataGridDeposito
