import React, { Component } from 'react'
import { ScrollView, Popup, Form } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import notify from "devextreme/ui/notify";
import { dayBase, instrumentObligasi, kpds, months, productDefault } from 'dataSource/lookup';
import ArrayStore from 'devextreme/data/array_store';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class FormModalDetailAmortisasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            DataMaster: {}
        }
        this.popupToolbarItem = [
            // {
            //     widget: 'dxButton',
            //     location: 'after',
            //     options: {
            //         'text': 'Export Excel',
            //         onClick: (e) => {

            //         }
            //     },
            //     toolbar: 'bottom'
            // },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.dataDetail = []
        this.dataMaster = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'productId',
                        label: {
                            text: 'Produk'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: productDefault(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'productCode',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: 'kpdId',
                        label: {
                            text: 'Pengelola'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kpds(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'noKontrak',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: 'instruCode',
                        label: {
                            text: 'Kode Instrument'
                        },
                    },
                    {
                        dataField: 'instruName',
                        label: {
                            text: 'Nama Instrument'
                        },
                    },
                    {
                        dataField: 'buyPrice',
                        label: {
                            text: 'Buy Price'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.0000'
                        }
                    },
                    {
                        dataField: 'buyValue',
                        label: {
                            text: 'Buy Value'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.0000'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'buyDate',
                        label: {
                            text: 'Buy Date'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'maturityDate',
                        label: {
                            text: 'Maturity Date'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'rate',
                        label: {
                            text: 'Rate'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'couponPeriod',
                        label: {
                            text: 'Periode Kupon'
                        },
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            text: 'Face Value'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            step: 0,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'dayBase',
                        label: {
                            text: 'Day Bases'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: dayBase(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                            searchEnabled: true,
                        }
                    },
                ]
            },
        ]
        this.columns = [
            {
                dataField: 'periode',
                caption: 'Periode',
            },
            {
                dataField: 'tanggal',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'penerimaan',
                caption: 'Penerimaan',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'pendapatan',
                caption: 'Pendapatan',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'amortisasi',
                caption: 'Amortisasi',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'bookValue',
                caption: 'Book Value',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
    }
    show = async (data, dataTable) => {
        this.dataDetail = dataTable
        this.setState({
            DataMaster: data,
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {}
        })
    }
    loadData = () => {
        return this.dataDetail
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'70vw'}
                    height="90vh"
                    title={`List Jadwal Amortisasi`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            ref={this.formRef}
                            colCount={2}
                            id={'dataMaster'}
                            formData={this.state.DataMaster}
                            items={this.dataMaster}
                            labelLocation={"left"}
                            readOnly={true}
                        />
                        <DevExpressDataGrid
                            ref={this.dataGridRef}
                            loadAPI='kpas'
                            insertAPI='kpas'
                            updateAPI='kpas'
                            deleteAPI='kpas'

                            backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                            useArraySource={true}
                            ArraySourceData={this.loadData}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={true}
                            exportFileName={"List Jadwal Amortisasi"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'List Jadwal Amortisasi'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}

                            onToolbarPreparing={this.onToolbarPreparing}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalDetailAmortisasi