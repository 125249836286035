import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { dapenProduk, karyawan } from 'dataSource/lookup';
import { alert } from 'devextreme/ui/dialog'
import ScrollView from 'devextreme-react/scroll-view';
import httpRequest from 'plugin/httprequest';

class FormKaryawanDapen extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataKaryawanDapen: {},
            newData : true
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: async() => {
                    await this.submitData()
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
          ];
        this.formKaryawanDapen = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'karyawanId',
                        label: {
                            text: 'Nama Lengkap',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: karyawan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: function(item){
                                return item && "NIK : " + item.nik + " - " + item.fullName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                            onValueChanged: async (e) => {
                                if (e.value) {
                                    var getDataKaryawanDapen = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-dapens/get-by-karyawanId/${e.value}`, 'GET')
                                    
                                    var options = this.formMasterRef.current.instance.getEditor('dapen')
                                    options.option('dataSource', getDataKaryawanDapen)
                                }
                            }
                        },
                    },
                    {
                        dataField: 'dapen',
                        label: {
                            text: 'Dana Pensiun'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'dapenReffCode',
                            searchEnabled: true, 
                            deferRendering: false,
                            onSelectionChanged:(e) => {
                                if (e.selectedItem) {
                                    var DataMaster = {}
                                    var Data = e.selectedItem
                                    DataMaster.produkDapen = Data.produkId
                                    DataMaster.kepesertaanDate = Data.kepesertaanDate
                                    DataMaster.pensiunDate = Data.pensiunDate
                                    DataMaster.phdp = Data.phdp
                                    DataMaster.dapenReffCode = Data.dapenReffCode
                                    DataMaster.iuranNormalKaryawan = Data.iuranNormalKaryawan
                                    DataMaster.iuranManfaatLainKaryawan = Data.iuranManfaatLainKaryawan
                                    DataMaster.iuranNormalPk = Data.iuranNormalPk
                                    DataMaster.iuranManfaatLainPk = Data.iuranManfaatLainPk
                                    this.formMasterRef.current.instance.updateData(DataMaster)
                                }
                            }
                        }
                    },
                    {
                        dataField: 'produkDapen',
                        label: {
                            text: 'Produk Dapen'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly:true,
                            dataSource: dapenProduk(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'produkKode'
                        }
                    },
                    {
                        dataField: 'kepesertaanDate',
                        label: {
                            text: 'Tanggal Kepesertaan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            readOnly:true,
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'pensiunDate',
                        label: {
                            text: 'Tanggal Pensiun'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            readOnly:true,
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: 'phdp',
                        label: {
                            text: 'PHDP'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'dapenReffCode',
                        label: {
                            text: 'Kode Refferal Dapen'
                        },
                        editorOptions: {
                            readOnly:true,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'IURAN KARYAWAN',
                items: [
                    {
                        dataField: 'iuranNormalKaryawan',
                        label: {
                            text: 'Iuran Normal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'iuranManfaatLainKaryawan',
                        label: {
                            text: 'Iuran Manfaat Lain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format: '#,##0.00'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'IURAN PEMBERI KERJA',
                items: [
                    {
                        dataField: 'iuranNormalPk',
                        label: {
                            text: 'Iuran Normal'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: 'iuranManfaatLainPk',
                        label: {
                            text: 'Iuran Manfaat Lain'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format: '#,##0.00'
                        }
                    },
                ]
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataKaryawanDapen: {},
            newData : true
        })
    }
    submitData = async() => {
        var dataSubmit = this.state.dataKaryawanDapen

        if(dataSubmit.pensiunDate < dataSubmit.kepesertaanDate ){
            await alert('Tanggal Kepesertaan tidak boleh melebihi tanggal pensiun !','Validasi')
            return false
        }else{
            if(this.state.newData){
                await this.props.insertData(dataSubmit)
            }else{
                await this.props.updateData(dataSubmit)
            }
        }
        this.hide()
    }

    retrieveData = async(data, type = false) => {    
        this.setState({
            dataKaryawanDapen: data,
            newData : type
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Kepesertaan Dapen"}
                width={"50vw"}
                height={"80vh"}
                toolbarItems={this.PopupToolbarItem}
                >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <Form
                        ref={this.formMasterRef}
                        colCount={1}
                        id={'formDataKepesertaanDapen'}
                        formData={this.state.dataKaryawanDapen}
                        items={this.formKaryawanDapen}
                        labelLocation = 'left'
                    />
                </div>
                </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormKaryawanDapen