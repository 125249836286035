import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import FormDataPersonal from '../../compPeserta/dataPersonal';
import FormDataKepegawaianAndSk from '../../compPeserta/dataKepegawaianAndSk';
import DataGridKeluarga from '../../compPeserta/dataKeluarga';
import DataGridRiwayatPengkinian from '../../compPeserta/dataRiwayat';
import httpRequest from 'plugin/httprequest';

class ShowModalPersiapanPensiun extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }        
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.formDataKepegawaianRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.dataGridRiwayatPengkinianRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.TabDataSource = [
            {
                id : 0,
                text: 'Data Personal'
            },
            {
                id : 1,
                text: 'Data Kepegawaian'
            },
            {
                id : 2,
                text: 'Data Keluarga'
            },
            {
                id : 3,
                text: 'Riwayat Pengkinian'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
            }, 
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                },
            },
            
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataPeserta}

        // console.log(dataSubmit)
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async(data = null) => {
        var loadAPI = 'simple-peserta-families/getAllPesertaFamiliesByPesertaId/'+ data.id
        var dataKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        if(data){
            await this.formDataPersonalRef.current.retrieveData(data)
            await this.formDataKepegawaianRef.current.retrieveData(data.id,data)
            await this.dataGridKeluargaRef.current.reloadData(data.id,dataKeluarga)
            await this.dataGridRiwayatPengkinianRef.current.reloadData(data)
        }

        this.setState({
            dataMaster: data,
            pesertaId: data.id,
        })
    }
    
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'View Persiapan Pensiun'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY : 'scroll', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={3}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation ='left'
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id="tabindex-0" className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataPersonal store={this.props.store} ref={this.formDataPersonalRef}/>
                        </div>
                        <div id="tabindex-1" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataKepegawaianAndSk store={this.props.store} ref={this.formDataKepegawaianRef}/>
                        </div>
                        <div id="tabindex-2" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <DataGridKeluarga store={this.props.store} ref={this.dataGridKeluargaRef}/>
                        </div>
                        <div id="tabindex-3" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <DataGridRiwayatPengkinian store={this.props.store} ref={this.dataGridRiwayatPengkinianRef}/>
                        </div>
                    </div>

                </div>
            </Popup>
        );
    }
}


export default ShowModalPersiapanPensiun