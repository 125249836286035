import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import ModalPreviewImage from '../../compPeserta/previewImage'
import ShowNoteAction from './online/note'

class ShowAktivasiAkun extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataVisitor : {}
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.popupModalPreviewImageRef = React.createRef()
        this.popupShowNoteActionRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Setuju',
                    onClick: () => this.updateStatus(3)
                },
                visible: this.props.type == 'pengajuan' ?  true : false,
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tolak',
                    onClick: () => this.updateStatus(4)
                },
                visible: this.props.type == 'pengajuan' ?  true : false,
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        
        this.visitorItems = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                disabled: true,
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                }
            },
            {
                dataField: 'email',
                label: {
                    alignment: 'left',
                    text: 'Email'
                  }
            },
            {
                dataField: 'mobilePhone',
                label: {
                    alignment: 'left',
                    text: 'No HP'
                  }
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            Data: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    updateStatus = (statusCode) => {
        this.popupShowNoteActionRef.current.show(this.state.dataVisitor.id,statusCode)
        
        this.hide()
    }
    retrieveData = async(data = {}) => {
        this.setState({
            dataVisitor: data,
        })
    }

    showImage = (path) => {
        var data = {
            file : `${process.env.REACT_APP_BACKEND}image-visitor/${path}`
        }
        this.popupModalPreviewImageRef.current.show()
        this.popupModalPreviewImageRef.current.retrieveData(data,'url')
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Aktivasi Akun'}
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY : 'scroll', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <Form
                                colCount={2}
                                id={'formDataVisitor'}
                                formData={this.state.dataVisitor}
                                items={this.visitorItems}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-4 text-center">
                            <h3>Foto Profil</h3>
                            <img src={`${process.env.REACT_APP_BACKEND}image-visitor/${this.state.dataVisitor.imageProfile}`} width="40%" onClick={(e) => this.showImage(this.state.dataVisitor.imageProfile)}/>
                        </div>
                        <div className="col-md-4 text-center">
                            <h3>Foto KTP</h3>
                            <img src={`${process.env.REACT_APP_BACKEND}image-visitor/${this.state.dataVisitor.imageKtp}`} width="40%" onClick={(e) => this.showImage(this.state.dataVisitor.imageKtp)}/>
                        </div>
                        <div className="col-md-4 text-center">
                            <h3>Foto Selfie</h3>
                            <img src={`${process.env.REACT_APP_BACKEND}image-visitor/${this.state.dataVisitor.imageSelfie}`} width="40%" onClick={(e) => this.showImage(this.state.dataVisitor.imageSelfie)}/>
                        </div>
                    </div>
                </div>
                <ModalPreviewImage
                    ref = {this.popupModalPreviewImageRef}
                    store = {this.props.store}
                />
                <ShowNoteAction
                    ref = {this.popupShowNoteActionRef}
                    updateStatus = {this.props.updateStatus}
                    hide = {this.props.hide}
                    store = {this.props.store}
                />
            </Popup>
        )
    }
}

export default ShowAktivasiAkun
