import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import uuidv4  from 'uuid/v4'

class FormModalKaryawanPotongan extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
            popupVisible:false,
        }

        this.formPotongan = [
            {
                dataField:'nik',
                label:{
                    text:'NIK'
                }
            },
            {
                dataField:'namaKaryawan',
                label:{
                    text:'Nama Karyawan'
                }
            },
            {
                dataField:'skNo',
                label:{
                    text:'SK No'
                }
            },
            {
                dataField:'sKTanggal',
                label:{
                    text:'SK Tanggal'
                }
            },
            {
                dataField:'tanggalBerlaku',
                label:{
                    text:'Tanggal Berlaku'
                }
            },
        ]

        this.columns = [
            {
                dataField: 'potongan',
                caption: "Potongan",
            },
            {
                dataField: 'nominalPotongan',
                caption: "Nominal Potongan",
            },
            {
                dataField: 'noPinjaman',
                caption: "No Pinjaman",
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataMaster:data
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster:{},
            popupVisible: false
        })
    }

    submitData = () => {
    
    }

    updateData = () => {
    
    }

    loadDummyData = () => {
        var data = [
            {
                id: uuidv4(),
                potongan:'P0001',
                nominalPotongan:1000000,
                noPinjaman:'ASD/123/2020'
            }
        ]

        return data
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Karyawan Potongan'} 
                width={700}
                height={750}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <Form
                colCount={1}
                id={'formPotongan'}
                formData={this.state.DataMaster}
                items={this.formPotongan}
                labelLocation = 'left'
            />
            <div className='my-3'>
                    <DevExpressDataGrid
                        ref = {this.dataGridRef}
                        loadAPI='daftar-pinjaman-karyawans'
                        insertAPI='daftar-pinjaman-karyawans'
                        updateAPI='daftar-pinjaman-karyawans'
                        deleteAPI='daftar-pinjaman-karyawans' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        useArraySource={true}
                        ArraySourceData={this.loadDummyData}

                        allowAdding={true}
                        allowDeleting={true}
                        allowUpdating={true}

                        exportExcel={true}
                        exportFileName={"Daftar Potongan Karyawan"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}
                        height={400}
                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Potongan Karyawan'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                        onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
            </Popup>
        )
    }
}

export default FormModalKaryawanPotongan
