import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { reportHeaderDetailFilterHeader } from 'dataSource/lookup';
import ModalCashBook from 'components/pages/modal/akutansi/cash-book/view';

class CashBook extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formFilter: {}
    }

    this.modalCashBookRef = React.createRef()
    this.dataGridRef = React.createRef()

    this.columns = [
      {
        dataField: 'timestamp',
        caption: 'Waktu Input',
        dataType: 'datetime',
        format: 'dd MMM yyyy HH:mm:ss'
      },
      {
        dataField: 'seqNo',
        caption: 'Kategori',
        lookup: {
          dataSource: reportHeaderDetailFilterHeader(this.props.store, 1),
          displayExpr: function (item) {
            return item && item.seqNo + " - " + item.description;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        },
      },
      {
        dataField: 'description',
        caption: 'Deskripsi',
      },
      {
        dataField: 'amount',
        caption: 'Nominal',
        dataType: 'number',
        format: '#,##0.00;(#,##0.00);',
        editorType: 'dxNumberBox',
        editorOptions: {
          format: '#,##0.00;(#,##0.00);'
        }
      },
      {
        dataField: 'reffType',
        caption: 'Tipe Reff',
        lookup: {
          dataSource: [
            {
              id: 'IN',
              value: 'Investasi'
            },
            {
              id: 'HR',
              value: 'HRD & Umum'
            },
            {
              id: 'KP',
              value: 'Kepesertaan'
            },
            {
              id: 'CV',
              value: 'Cash Voucher'
            },
            {
              id: 'JV',
              value: 'Journal Voucher'
            },
          ],
          valueExpr: 'id',
          displayExpr: 'value',
        }
      },
      {
        dataField: 'reffNo',
        caption: 'Reff No',
      },
    ]

    this.columnDefaultValue = [
      {
        dataField: 'timestamp',
        value: () => {
          return new Date()
        }
      }
    ]
  }

  componentWillMount() {
    let store = this.props.store;
    store.dispatch(addURL(this.props.location.pathname))
  }
  forceRefresh = () => {
    this.dataGridRef.current.forceRefresh(true)
  }
  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'plus',
          onClick: (e) => {
            this.modalCashBookRef.current.show()
          }
        }
      }
    )
  }

  render() {
    return (
      <div className="container-fluid">
        <h2 className="main-title">Buku Pembantu Kas</h2>

        <DevExpressDataGrid
          ref={this.dataGridRef}
          loadAPI='cash-books'
          insertAPI='cash-books'
          updateAPI='cash-books'
          deleteAPI='cash-books'

          backendserver={process.env.REACT_APP_BACKEND_ACC}

          remoteOperations={true}

          allowAdding={false}
          allowDeleting={false}
          allowUpdating={false}

          exportExcel={true}
          exportFileName={"Buku Pembantu Kas"}
          allowExportSelectedData={true}
          selection={"multiple"}

          showBorders={true}

          paging={true}
          defaultPageSize={10}
          grouping={false}

          //bagian konfigurasi popup saat insert dan edit record
          popupTitle={'Buku Pembantu Kas'}
          popupWidth={700} //masukan dalam ukuran pixel
          popupHeight={500} //masukkan dalam ukuran pixel

          popupFormLabelLocation='left' //accepted value = top, left, right
          popupFormMinColWidth={300} // minimum lebar kolom
          popupFormColCount={1} //jumlah kolom pada form

          //akhir bagian konfigurasi popup

          ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
          ColumnFixing={true} // set false agar kolom tidak dapat di freeze

          FilterRow={true} // set false untuk mematikan fitur filter

          ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

          columnDefaultValue={this.columnDefaultValue}

          onToolbarPreparing={this.onToolbarPreparing}

          //contoh konfigurasi kolom
          //this.columns = [{
          //    dataField: 'kolom1',
          //    caption: 'Ini Kolom 1'
          //}, {
          //    dataField: 'kolom2',
          //    caption: 'Ini Kolom 2'
          //}]
          // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

          store={this.props.store} // jangan di edit edit
        />
        <ModalCashBook store={this.props.store} forceRefresh={this.forceRefresh} ref={this.modalCashBookRef} />
      </div>
    )
  }
}

export default CashBook