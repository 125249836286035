import React, { Component } from 'react'
import Chart, {
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    CommonAxisSettings,
    Grid,
    Export,
    Legend,
    Margin,
    Tooltip,
    Label,
    Format,
} from 'devextreme-react/chart';

import httpRequest from 'plugin/httprequest';
import { getRoaProduct } from 'dataSource/lookup';
import { formatDate } from 'plugin/helper';


class RoaProdukInvestasi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            dataMaster: {
                sysDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            }
        }
        this.series = [
            { valueField: "ppmp", name: "PPMP" },
            { valueField: "ppip", name: "PPIP" },
        ]
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Chart
                            palette="Bright"
                            // dataSource={this.dataSource}
                            dataSource={getRoaProduct(this.props.store, formatDate(this.state.dataMaster.sysDate))}
                            series={this.series}
                        >
                            <CommonSeriesSettings
                                argumentField="bulan"
                                type={'line'}
                            />
                            <CommonAxisSettings>
                                <Grid visible={true} />
                            </CommonAxisSettings>
                            <ArgumentAxis
                                allowDecimals={false}
                                axisDivisionFactor={60}
                            >
                                <Label>
                                    <Format type="decimal" />
                                </Label>
                            </ArgumentAxis>
                            <Legend
                                verticalAlignment="top"
                                horizontalAlignment="right"
                            />
                            <Tooltip enabled={true} format={'decimal'} zIndex={5} />
                        </Chart>
                    </div>
                </div>
            </div>
        )
    }
}
export default RoaProdukInvestasi
