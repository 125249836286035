import React, { Component } from "react";
import DevExpressDataGrid from "components/inheritComponent/devexpressdatagrid";
import { Popup } from 'devextreme-react/popup';
import Form from "devextreme-react/form";
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { Paging, Column } from 'devextreme-react/data-grid';
import { FileUploader, DataGrid, CheckBox } from 'devextreme-react'
import notify from "devextreme/ui/notify";

import { ab2str, cleanQuote, formatNumber } from 'plugin/helper';
import XLSX from 'xlsx';
import httpRequest from "plugin/httprequest";

class UploadKonversiAnak extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            successData: [],
            errorData: [],
            uploadedData: [],
            selectedTabIndex: 0,
        }

        this.show = this.show.bind(this)
        this.fileUploderRef = React.createRef()
        this.hide = this.hide.bind(this)

        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()

        this.successData = []
        this.errorData = []
        this.listDataSuccess = []
        this.prevTabIndex = 0

        this.PopupToolbarItem = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Simpan Data',
                onClick: this.submitData.bind(this)
            },
            toolbar: 'bottom'
        }, {
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Batal',
                onClick: this.hide.bind(this)
            },
            toolbar: 'bottom'
        }]

        this.TabDataSource = [
            {
              id: 0,
              text: "Data Sesuai"
            },
            {
              id: 1,
              text: "Data Tidak Sesuai"
            },
        ];

        this.columns = [
            {
                dataField: 'id',
                caption: "No.",
                cellRender: (data) => {
                  return data.rowIndex + 1
                },
            },
            {
                dataField: 'faktor',
                caption: 'Faktor',
                alignment: 'right'
            },
            {
                dataField: 'periode',
                caption: 'Periode',
                alignment: 'center'
            },
            {
                dataField: 'periodeTahun',
                caption: 'Periode Tahun',
                alignment: 'center'
            }
        ]
    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            successData: [],
            errorData: [],
            uploadedData: []
        });

    }

    onTabSelectionChanged = args => {
        document.getElementById(`tabuploadindex-${args.value}`).classList.remove('d-none');
        
        document.getElementById(`tabuploadindex-${this.prevTabIndex}`).classList.add('d-none');
        
        this.prevTabIndex = args.value
    };

    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    onUploadStarted = (e) => {
        console.log(e)
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            console.log(files)
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if (fileType === 'xls' || fileType === 'xlsx') {
                    var workbook = XLSX.read(dataArrayBuffer, { type: 'array' });
                    var sheet = workbook.Sheets[workbook.SheetNames[3]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)

                    data = dataJSON.map(value => Object.values(value))
                }

                if (fileType === 'csv') {
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                        data = data.filter(value => {
                            regex.lastIndex = 0
                            return regex.test(value)
                        }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)

                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)

                                var splitRes = splitRes.map(splitVal => splitVal.trim())

                                var splitRes = splitRes.map(splitVal => {
                                    if (String(splitVal)) {
                                        return String(splitVal)
                                    } else {
                                        return splitVal
                                    }
                                })

                                return splitRes
                            }
                        })
                    } else {
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                var errorMessage = []

                for (var uploadedData of data) {
                    if (Array.isArray(uploadedData)) {
                        errorMessage = []
                        successData.push({
                            faktor: uploadedData[1],
                            periode: uploadedData[0],
                            periodeTahun: uploadedData[2],
                        })
                    }
                }
                if (errorData.length > 0) {
                    let type = "error";
                    let text = "Ada beberapa data unggahan yang gagal diunggah, harap periksa tab data unggahan yang gagal";
                    notify(
                        {
                            message: text,
                            width: "AUTO",
                            shading: true,
                            position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                }
                this.successData = successData
                this.errorData = errorData
                this.editDataGrid(this.successData, 'success')
            }
            reader.readAsArrayBuffer(files);
        }
    }
    
    editDataGrid = async (data, type) => {
        if (type === 'success') {
            var dataGrid = this.dataGridSesuaiRef
        } else {
            var dataGrid = this.dataGridTakSesuaiRef
        }
        await this.deleteDataGrid(dataGrid)
        for (var value of data) {
            try {
                var key = value.id
                if (key) {
                    dataGrid.current.push([
                        {
                            type: 'update',
                            data: value,
                            key: key
                        }
                    ])
                } else {
                    dataGrid.current.push([
                        {
                            type: 'insert',
                            data: value
                        }
                    ])
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    deleteDataGrid = (dataGrid) => {
        var data = dataGrid.current.getDataSource()

        for (var value of data) {
            dataGrid.current.push([
                {
                    type: 'remove',
                    key: value.id
                }
            ])
        }
    }

    forceRefresh = () => {
        this.dataGridSesuaiRef.current.forceRefresh(true)
        this.dataGridTakSesuaiRef.current.forceRefresh(true)
    }
    submitData = async () => {
        var dataSesuai = this.successData
        // var dataTakSesuai = this.errorData
        try{
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `faktor-anaks/uploadFaktorAnak`, 'POST', {
                values: dataSesuai
            })

            if (response) {
                notify({ message: 'Sukses!', width: 'AUTO', position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.setState({
                    successData: []
                })
                this.hide()
                this.props.forceRefresh()                 
            } else {
                notify({ message: 'Salah!', width: 'AUTO', position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
            
        } catch (e) {
            console.log(e);
        }
    }

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns]

        columns = columns.map(value => {
            var modifiedValue = {...value}
            delete modifiedValue.lookup

            return modifiedValue
        })

        columns.unshift({
            type: 'buttons',
            buttons: [{
                text : 'Error Detail',
                hint : 'Error Detail',
                icon: "warning",
                cssClass: "text-danger", 
                onClick : (e) => {
                    let type = "error";
                    let text = e.row.data.errorMessage.join("\n");

                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                },
            }]
        })
        return columns
    }

    render(){
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Upload Data Konversi Sekarang"}
                width={"90vw"}
                height={650}
                toolbarItems={this.PopupToolbarItem}
            >
                <div className="d-flex flex-row">
                    <div style={{width:"300px"}}>
                        <FileUploader
                        accept={".txt,.xls,.xlsx,.csv"}
                        uploadMode={"useForm"}
                        onValueChanged={this.onUploadStarted.bind(this)}
                        labelText="Upload File Disini"
                        showFileList={false}
                        name={"file"}
                        value={this.state.uploadedData}
                        ref={this.fileUploderRef}
                        />
                    </div>
                </div>
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    selectedIndex={this.state.selectedTabIndex}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabuploadindex-0" className={' mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridSesuaiRef}
                        keyField={'faktor'}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataSuccess}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Sukses"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Sukses'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                <div id="tabuploadindex-1" className={'d-none mt-2 col-md-12 text-center' } >
                    <DevExpressDataGrid
                        ref={this.dataGridTakSesuaiRef}
                        keyField={'faktor'}
                        loadAPI=''
                        insertAPI=''
                        updateAPI=''
                        deleteAPI='' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData = {this.retrieveDataFailed}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={false}
                        exportFileName={"Data Gagal"}

                        allowExportSelectedData={false}

                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Gagal'}
                        popupWidth={500} //masukan dalam ukuran pixel
                        popupHeight={300} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={false} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        // onToolbarPreparing = {this.onToolbarPreparing}

                        height = {'calc(70vh - 250px)'}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
            </Popup>
        )
    }
}

export default UploadKonversiAnak;