import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import Form from 'devextreme-react/form'
import { Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url'
import ViewPenerimaBonus from 'components/pages/modal/sdm-umum/payroll/bonusTahunan/viewPenerimaBonus'
import httpRequest from 'plugin/httprequest'
import { statusPembayaranRekapGaji } from 'dataSource/lookup'
import { agama } from 'dataSource/lookup'


class DaftarTransaksiBonus extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{}
        }

        
        this.filterData = []
        this.DataGridPengajuanRef = React.createRef()

        this.daftarTransaksiColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'View',
                        hint : 'View',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalPenerimaThr()
                        }
                    },
                ],
            },
            {
                dataField:"bonus",
                caption:"Bonus",
                alignment:'center',
                columns:[
                    {
                        dataField:"tunjanganName",
                        caption:"Tipe",
                    },
                    {
                        dataField:"bonusTahun",
                        caption:"Tahun",
                    },
                    {
                        dataField:"nilaiPersen",
                        caption:"% Gaji",
                    },
                ]
            },
            {
                dataField:"nik",
                caption:"NIK",
            },
            {
                dataField:"fullName",
                caption:"Nama",
            },
            {
                dataField:"jabatanName",
                caption:"Jabatan",
            },
            {
                dataField:"unitName",
                caption:"Unit",
            },
            {
                dataField:"religionId",
                caption:"Agama",
                lookup: {
                    dataSource: agama(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField:"nominalBonus",
                caption:"Nominal Bonus",
                dataType: 'number',
                format: '#,##0.00'
            },
            // {
            //     dataField:"pajak",
            //     caption:"Pajak",
            //     dataType: 'number',
            //     format: '#,##0.00'
            // },
            // {
            //     dataField:"bonusBersih",
            //     caption:"Bonus Bersih",
            //     dataType: 'number',
            //     format: '#,##0.00'
            // },
            {
                dataField:"statusId",
                caption:"Status",
                lookup: {
                    dataSource: statusPembayaranRekapGaji(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.filter = [
            {
                dataField:"agama",
                label:{
                    text:"Agama",
                    alignment:"left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: agama(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.popupDaftarTransaksiBonusRef = React.createRef()
    }

    componentDidMount = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-transaksi-bonuses?statusId.in=2`,
            'GET'
        )
        this.filterData = datas
        this.forceRefresh()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    forceRefresh = async() => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }
    
    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-transaksi-bonuses?statusId.in=2`,
            'GET'
        )
        if (state.agama && state.tahun) {
            this.filterData = datas.filter(value => value.religionId == state.agama && value.bonusTahun == state.tahun)
        }else if (state.agama) {
            this.filterData = datas.filter(value => value.religionId == state.agama)
        } else if (state.tahun) {
            this.filterData = datas.filter(value => value.bonusTahun == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    }

    showModalPenerimaThr = () => {
        this.popupDaftarTransaksiBonusRef.current.show()
    }

    loadData = async() => {
        return this.filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Transaksi Bonus</h3>
                <Form
                    colCount={4}
                    id={'formRekap'}
                    formData={this.state.DataMaster}
                    items={this.filter}
                    labelLocation={'left'}
                />
                <DevExpressDataGrid
                        ref = {this.DataGridPengajuanRef}
                        loadAPI='daftar-transaksi-bonus'
                        insertAPI='daftar-transaksi-bonus'
                        updateAPI='daftar-transaksi-bonus'
                        deleteAPI='daftar-transaksi-bonus' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData= {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Penerima Bonus"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Penerima Bonus'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.daftarTransaksiColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        height = {400}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ViewPenerimaBonus
                        ref={this.popupDaftarTransaksiBonusRef}
                        store={this.props.store}
                    />
            </div>
        )
    }
}


export default DaftarTransaksiBonus