import React, {Component} from 'react'
import { Popup,ScrollView, Form, FileUploader } from 'devextreme-react'
import { banks, currency, rekBankByProduct } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'
import { formatDate, formatUploadFileData } from 'plugin/helper'
import notify from "devextreme/ui/notify";

class FormAddInstrumentPemindahanRek extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{
                srcCurrencyId:1001,
                dstCurrencyId:1001,
                srcNominalTransfer:0
            },
            popupVisible: false,
        }

        this.DataMaster = [
            {
                itemType: 'group',
                name:"rekSumber",
                caption:'Rekening Sumber',
                items:[
                    {
                        dataField:"srcRekBankId",
                        label:{
                            text:'No Rekening'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: [],
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'rekNo',
                            onSelectionChanged : (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData(
                                        {
                                            srcNamaRekening:e.selectedItem.rekName,
                                            srcBankId:e.selectedItem.bankId,
                                            srcKursAvg:e.selectedItem.avgKurs
                                        }
                                    )
                                }
                            }
                        }
                    },
                    {
                        dataField:"srcNamaRekening",
                        label:{
                            text:'Nama Rekening'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"srcBankId",
                        label:{
                            text:'Bank'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            readOnly:true,
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.bankCode + " - " + item.bankName;
                            }
                        }
                    },
                    {
                        dataField:"srcCurrencyId",
                        label:{
                            text:'Currency'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            readOnly:true,
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.currencyCode + " - " + item.currencyName;
                            },
                        }
                    },
                    {
                        dataField:"srcKursAvg",
                        label:{
                            text:'Kurs Rata-Rata'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                            onValueChanged:(e) => {
                                if (e.value) {
                                    var DataMaster = {...this.state.DataMaster}
                                    var selisihKurs = DataMaster.nilaiKursTransfer - e.value
                                    this.formMasterRef.current.instance.updateData(
                                        {
                                            selisihKursTransfer:selisihKurs,
                                        }
                                    )
                                }
                            }
                        }
                    },
                    {
                        dataField:"srcNominalTransfer",
                        label:{
                            text:'Nominal Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:"rekTujuan",
                caption:'Rekening Tujuan',
                items:[
                    {
                        dataField:"dstRekBankId",
                        label:{
                            text:'No Rekening'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            dataSource: [],
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: 'rekNo',
                            onSelectionChanged : (e) => {
                                if (e.selectedItem) {
                                    this.formMasterRef.current.instance.updateData(
                                        {
                                            dstNamaRekening:e.selectedItem.rekName,
                                            dstBankId:e.selectedItem.bankId,
                                            dstKursAvg:e.selectedItem.avgKurs
                                        }
                                    )
                                }
                            }
                        }
                    },
                    {
                        dataField:"dstNamaRekening",
                        label:{
                            text:'Nama Rekening'
                        },
                        editorOptions:{
                            readOnly:true
                        }
                    },
                    {
                        dataField:"dstBankId",
                        label:{
                            text:'Bank'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            readOnly:true,
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.bankCode + " - " + item.bankName;
                            },
                        }
                    },
                    {
                        dataField:"dstCurrencyId",
                        label:{
                            text:'Currency'
                        },
                        editorType:'dxSelectBox',
                        editorOptions:{
                            readOnly:true,
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.currencyCode + " - " + item.currencyName;
                            },
                        }
                    },
                    {
                        dataField:"dstKursAvg",
                        label:{
                            text:'Kurs Rata-Rata'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                name:"kursTransfer",
                caption:'Kurs Transfer',
                items:[
                    {
                        dataField:"nilaiKursTransfer",
                        label:{
                            text:'Nilai Kurs Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format:'#,##0.00',
                            onValueChanged:(e) => {
                                if (e.value) {
                                    var DataMaster = {...this.state.DataMaster}
                                    var selisihKurs = e.value - DataMaster.srcKursAvg
                                    this.formMasterRef.current.instance.updateData(
                                        {
                                            selisihKursTransfer:selisihKurs,
                                        }
                                    )
                                }
                            }
                        }
                    },
                    
                    {
                        dataField:"selisihKursTransfer",
                        label:{
                            text:'Selisih Kurs Transfer'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly:true,
                            format:'#,##0.00',
                        }
                    },
                ]
            },
        ]

        this.popupToolbarItem1 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submit()
                        }
                    } 
                },
                visible:this.props.action == 'view' ? false : true,
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Ubah',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.update()
                        }
                    } 
                },
                visible:this.props.action == 'view' ? false : true,
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.pengajuanId = 0
        this.instrumentPerpindahanId = 0

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
    }

    submit = async() => {
        try {
            var DataMaster = {...this.state.DataMaster}
            DataMaster.pemindahanRekeningId = this.pengajuanId
            var loadApi = `pemindahan-rekening-dtls/pemindahan-rekening-dtl-create-update`
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    update = async () => {
        try {
            var loadApi = 'pemindahan-rekening-dtls/pemindahan-rekening-dtl-create-update'
            var DataMaster = {...this.state.DataMaster}
            DataMaster.pemindahanRekeningId = this.pengajuanId
            DataMaster.id = this.instrumentPerpindahanId
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster,
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e);
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    getPengajuanId = (id) => {
        this.pengajuanId = id
    }

    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }

    retrieveData = async (data) => {
        this.instrumentPerpindahanId = data.id
        await this.getDataRekBank()
        var DataMaster = {...this.state.DataMaster}
        DataMaster.srcRekBankId = data.sourceRekBankId
        DataMaster.dstRekBankId = data.destinationRekBankId
        DataMaster.nilaiKursTransfer = data.kursPerpindahan
        DataMaster.selisihKursTransfer = data.selisihKurs
        this.setState({
            DataMaster:DataMaster
        })
    }

    getDataRekBank = async () => {
        try {
            var loadAPI = `rek-banks-by-productId?productId=${this.props.productId}`
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                loadAPI,
                "GET"
            );

            if (response) {
                var optionsSrcBank = this.formMasterRef.current.instance.getEditor('srcRekBankId')
                var optionsDstBank = this.formMasterRef.current.instance.getEditor('dstRekBankId')

                optionsSrcBank.option('dataSource',response)
                optionsDstBank.option('dataSource',response)
            }
        } catch (e) {
        
        }
    }

    show(){
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            DataMaster:{
                srcCurrencyId:1001,
                dstCurrencyId:1001,
                srcNominalTransfer:0
            },
            popupVisible: false,
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                resizeEnabled={true}
                showTitle={true}
                width={'30vw'}
                height="75vh"
                title={`Update Instrument Perpindahan Efek`}
                ref={this.PopupRef}
                toolbarItems={this.props.action == "edit" ? this.popupToolbarItem2 : this.popupToolbarItem1}
            >
                 <ScrollView width='100%' height='100%'>
                    <div className="container-fluid">
                    <Form
                        ref={this.formMasterRef}
                        colCount={1}
                        id={'formDataMaster'}
                        formData={this.state.DataMaster}
                        items={this.DataMaster}
                        scrollingEnabled={true}
                        labelLocation={"left"}
                    />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default FormAddInstrumentPemindahanRek