import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import Form from 'devextreme-react/form'
import { formatDate } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import ConfirmClosingDays from 'components/pages/modal/investasi/confirmClosingDay'

class ProsesAkhirHari extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        
        this.state = {
            filterData: {
                sysDateNow: new Date(this.sysDate ? this.sysDate.dateValue : null),
                // sysDateNext: this.date.setDate(this.date.getDate() + 1)
                sysDateNext: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
            selectedData: []
        }
        
        this.datagridRef = React.createRef()
        this.modalConfirmDay = React.createRef()

        this.columns = [
            {
                dataField: 'logProses',
                caption: 'Log Proses Akhir Hari',
                columns: [
                    {
                        dataField: 'proses',
                        caption: 'Proses'
                    },
                    {
                        dataField: 'lastClosingDate',
                        caption: 'Tanggal Proses Terakhir'
                    },
                    {
                        dataField: 'closingDate',
                        caption: 'Tanggal Proses'
                    },
                    {
                        dataField: 'statusId',
                        caption: 'Aksi',
                        dataType: 'boolean'
                    }
                ]
            }
        ]

        this.filterItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'sysDateNow',
                        label: {
                            text: 'Tanggal Generate Awal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                        disabled: false
                    },
                    {
                        dataField: 'sysDateNext',
                        label: {
                            text: 'Tanggal Generate Akhir'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                        disabled: false
                    },
                ]
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    loadData = async () => {
        var data = this.state.filterData
        var response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/closingDaily/${formatDate(data.sysDateNow)}/1/4`);
        response = response.filter(value => value.tipeClosingId === 1)

        var dataResponse = []
        for(var value of response){
            var statusId = false
            if(value.statusId === 1){
                statusId = true
            }else{
                statusId =  false
            }

            var dataResult = {
                // id: value.id,
                bulan: value.bulan,
                closingDate: value.closingDate,
                lastClosingDate: value.lastClosingDate,
                modulId: value.modulId,
                statusId: statusId,
                tahun: value.tahun,
                tipeClosingId: value.tipeClosingId
            }

            dataResponse.push(dataResult)
        }

        const lookup =  [
            'Generate Closing Day',
        ]

        var result = []
        
        if(dataResponse.length > 0){
            for(let i = 0; i < 1; i++){

                for(var closingData of dataResponse){
                    result.push({
                        id: i+1,
                        proses: lookup[i],
                        bulan: closingData.bulan,
                        closingDate: closingData.closingDate,
                        lastClosingDate: closingData.lastClosingDate,
                        modulId: closingData.modulId,
                        statusId: closingData.statusId,
                        tahun: closingData.tahun,
                        tipeClosingId: closingData.tipeClosingId,
                    })
                }
            }
        }else{
            for(let i = 0; i < 1; i++){
                result.push({
                    id: i+1,
                    proses: lookup[i]
                })
            }
        }

        return result; 
    }

    onSelectionChanged = (e) => {
        var data = e.selectedRowsData
    
        this.setState({
            selectedData: data
        })
    }

    prosesEndDay = async() => {
        this.modalConfirmDay.current.show();
        // try{
        //     var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/closingDailyBalance/${tanggal}/${tanggal}`, 'POST', { 
        //         values: {} 
        //     });

        //     if(response){
        //         // var selectedData = this.state.selectedData
        //         var data = {
        //             bulan: this.state.filterData.sysDateNow.getMonth()+1,
        //             modulId: 4,
        //             prosesId: 1,
        //             systemDate: tanggal,
        //             tahun: this.state.filterData.sysDateNow.getFullYear(),
        //         }

        //         await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `closings/saveToClosing`, 'POST', { 
        //             values: data
        //         });

        //         this.props.store.dispatch(showLoading(false))
        //         notify({ message: 'Proses Generate Akhir Hari berhasil dilakukan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        //         this.datagridRef.current.forceRefresh(true)
        //     }

        //     return response
        // }catch(e){
        //     console.log(e)

        //     this.props.store.dispatch(showLoading(false))
        //     notify({ message: 'Proses Generate Akhir Hari gagal dilakukan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        //     this.datagridRef.current.forceRefresh(true)
        // }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Proses',
                    onClick: async(e) => {
                        await this.prosesEndDay()
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <h2 className="main-title">Proses Akhir Hari</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <Form
                                colCount={1}
                                id={'formFilter'}
                                formData={this.state.filterData}
                                items={this.filterItem}
                                labelLocation="left"
                            />
                        </div>
                        <div className="mt-2 col-md-12">
                            <DevExpressDataGrid
                                ref={this.datagridRef}
                                loadAPI='-'
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND}
                                useArraySource={true}
                                ArraySourceData={this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={false}
                                exportFileName={"-"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                showPageSizeSelector={false}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'-'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}
                                onSelectionChanged={this.onSelectionChanged}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>

                <ConfirmClosingDays
                    ref={this.modalConfirmDay}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    tanggal={this.state.filterData}
                />
            </React.Fragment>
        )
    }
}

export default ProsesAkhirHari