import firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyCYbpUMrYY42fdXx-QVnznTX_LvvtpSzdI",
    authDomain: "dapen-pegadaian-75243.firebaseapp.com",
    databaseURL: "https://dapen-pegadaian-75243.firebaseio.com",
    projectId: "dapen-pegadaian-75243",
    storageBucket: "dapen-pegadaian-75243.appspot.com",
    messagingSenderId: "430760106731",
    appId: "1:430760106731:web:83c558c0d6951def55fcae",
    measurementId: "G-PVS8GCF4H7"
};

var DBName = 'dapen-pegadaian'  
var fire = firebase.initializeApp(firebaseConfig)


export default fire
export {DBName}