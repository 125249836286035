import React from 'react';
import ReactDOM from 'react-dom'
import TreeList, { Editing, Popup, Paging, Pager, Form, ColumnChooser, ColumnFixing, FilterRow, Column, Scrolling, Sorting, } from 'devextreme-react/tree-list';
import notify from 'devextreme/ui/notify';
import CustomStore from 'devextreme/data/custom_store';
import uuidv4  from 'uuid/v4'
import DataSource from 'devextreme/data/data_source';

import {httpRequest} from 'plugin/httprequest'
import {search,searchIndex, formatDate} from 'plugin/helper'

/* contoh pemanggilan komponen
	<DevExtremeTreeList 
		loadAPI = 'api-url'
		insertAPI = 'api-url'
		updateAPI = 'api-url'
		deleteAPI = 'api-url'

		allowAdding={true or false}
		allowDeleting={true or false}
		allowUpdating={true or false}


		showBorders = {true or false}

		paging = {true or false}
		defaultPageSize = {10 jumlah baris default untuk paging}
		
		//bagian konfigurasi popup saat insert dan edit record
		popupTitle = {'judul popup taruh disini'}
		popupWidth = {300} //masukan dalam ukuran pixel
		popupHeight = {310} //masukkan dalam ukuran pixel

		popupFormLabelLocation = 'top' //accepted value = top, left, right
		popupFormMinColWidth = {300} // minimum lebar kolom
		popupFormColCount = {1} //jumlah kolom pada form
		
		//akhir bagian konfigurasi popup

		ColumnChooser = {true} // set false agar kolom tidak dapat di pindah pindah
		ColumnFixing = {true} // set false agar kolom tidak dapat di freeze

		FilterRow = {true} // set false untuk mematikan fitur filter

		ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
		
		//contoh konfigurasi kolom
		//this.columns = [{
        //    dataField: 'kolom1',
        //    caption: 'Ini Kolom 1'
        //}, {
        //    dataField: 'kolom2',
        //    caption: 'Ini Kolom 2'
        //}]
		// detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
		
		store = {this.props.store} // jangan di edit edit
		/>
*/

class DevExtremeTreeList extends React.Component {
    constructor(props) {
        super(props);
        
        this.dataSource = new CustomStore({
            key: this.props.keyField,
            load: async (loadOptions) => {
                let params = "?";
                
                if(this.props.loadAPI.includes('?')){
                    params = '&'+params.slice(0,1)
                }

                params = params.slice(0, -1);

                var response

                if(this.props.ArraySourceData && (this.ArraySourceData.length === 0 || this.refreshForced)){
                    if(typeof this.props.ArraySourceData === 'function'){
                        this.ArraySourceData = await this.props.ArraySourceData()
                    }else{
                        this.ArraySourceData = this.props.ArraySourceData
                    }
                }

                if(this.refreshForced){
                    this.refreshForced = false
                }

                if((this.ArraySourceData.length === 0 || !this.props.useArraySource) && !this.props.ArraySourceData){
                    response = await httpRequest(this.props.backendserver,this.props.store, this.props.loadAPI)
                    this.ArraySourceData = response
                }else{
                    response = this.ArraySourceData
                }

                return response
            },
            insert: async (values) => {
                if(this.useArraySource){
                    values.id = uuidv4()
                    this.ArraySourceData.push(values)

                }else{
                    await httpRequest(this.props.backendserver,this.props.store, this.props.insertAPI, 'POST', {
                        values: values
                    })

                    this.forceRefresh()
                }
            },
            update: async (key,values) => {
                if(this.useArraySource){
                    var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == key)
                    
                    for(var entry of Object.entries(values)){
                        let keyObject = entry[0];
                        let value = entry[1];

                        this.ArraySourceData[rowUpdated][keyObject] = value
                    }
                }else{
                    await httpRequest(this.props.backendserver,this.props.store, this.props.updateAPI, 'PUT', {
                        values: values,
                        key: key
                    }, this.focusedRow)

                    this.forceRefresh()
                }
            },
            remove: async (key) => {
                if(this.useArraySource){
                    if(key.length != 36){
                        this.DeletedIndex.push(key)
                    }

                    var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == key)
                    
                    this.ArraySourceData.splice(rowUpdated,1)
                    
                }else{
                    await httpRequest(this.props.backendserver,this.props.store, this.props.deleteAPI, 'DELETE', key)
                    this.forceRefresh()
                }
            },
            onPush: (changes) => {
                for(var change of changes){
                    switch (change.type) {
                        case 'insert':
                            var keyField = this.props.keyField || 'id'

                            if(change.data[keyField] == null){
                                change.data[keyField] = uuidv4()
                            }
                            this.ArraySourceData.push(change.data)
                            break;
                        case 'update':
                            var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == change.key)
                    
                            for(var entry of Object.entries(change.data)){
                                let keyObject = entry[0];
                                let value = entry[1];

                                this.ArraySourceData[rowUpdated][keyObject] = value
                            }
                            break;
                        case 'remove':
                            if(change.key.length != 36){
                                this.DeletedIndex.push(change.key)
                            }
        
                            var rowUpdated = this.ArraySourceData.findIndex(element => element[this.props.keyField || 'id'] == change.key)
                            
                            this.ArraySourceData.splice(rowUpdated,1)
                            break;
                        default:
                            break;
                    }
                }
            }
        })

        this.state = {
            dataSource:  new DataSource({
                store: this.dataSource,
                reshapeOnPush: true
            }),
            requests: [],
            refreshMode: 'reshape',
            contextMenu:[],
            autoExpandAll: false
        };

        this.columns = []

        this.refreshForced = false
        this.focusedRow= {}
        this.TreeListRef = React.createRef()

        this.ArraySourceData = []
        this.DeletedIndex = []
        this.useArraySource = this.props.useArraySource === undefined ? false : this.props.useArraySource
        this.totalCount = 0

        this.menuRightClick = typeof this.props.menuRightClick === 'boolean' ? this.props.menuRightClick : true

        this.useNotify = typeof this.props.useNotify === 'boolean' ? this.props.useNotify : true
    }

    get DataGrid(){
        return this.TreeListRef.current.instance
    }

    componentWillMount = () => {
        this.columnDraw()
    }

    componentDidMount = () => {
        this.updateEditor({
            allowAdding : this.props.allowAdding,
            allowUpdating : this.props.allowUpdating,
            allowDeleting : this.props.allowDeleting,
        })
    }

    onEditingStart = (e) => {
        if(this.props.editingMode !== 'cell' && this.props.editingMode !== 'batch'){
            this.focusedRow = e.data
            // this.setState({
            //     focusedRow: e.data
            // })
        }
        // console.log('editing',e)
    }

    onInitNewRow = (e) => {
        if(this.props.columnDefaultValue !== null && this.props.columnDefaultValue !== undefined){
            this.props.columnDefaultValue.forEach((element,index)=>{
                e.data[element.dataField] = element.value
            })
        }
        // console.log('initNew',e)
    }

    onRowInserting = (e) => {
        // console.log('inserting',e)
    }

    onRowInserted = (e) => {
        let type = 'success'
        let text = 'Data successfully inserted!'

        if(this.useNotify)
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
    }

    onRowUpdating = (e) => {
        // console.log('updating',e)
    }

    onRowUpdated = (e) => {
        let type = 'success'
        let text = 'Data successfully updated!'

        if(this.useNotify)
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
    }

    onRowRemoving = (e) => {
        // console.log('removing',e)
    }

    onRowRemoved = (e) => {
        let type = 'success'
        let text = 'Data successfully deleted!'

        if(this.useNotify)
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
    }

    onContextMenuPreparing = (e) => {
        if(this.menuRightClick){
            var contextMenu = []
    
            // if(this.props.allowAdding){
            //     contextMenu.push({
            //         text: "insert",
            //         onItemClick: () => {
            //             this.DataGrid.insertRow();
            //         }
            //     });
            // }
    
            if(this.props.allowUpdating){
                contextMenu.push({
                    text: "Ubah",
                    onItemClick: () => {
                        this.DataGrid.editRow(e.row.rowIndex);
                    }
                });
            }
    
            if(this.props.allowDeleting){
                contextMenu.push({
                    text: "Hapus",
                    onItemClick: () => {
                        this.DataGrid.deleteRow(e.row.rowIndex);
                    }
                });
            }
            
            var buttonConfig = search('buttons','type',this.props.ColumnConfiguration)
            if(buttonConfig){
                buttonConfig.buttons.forEach((value,index) => {
                    if(typeof value === 'object'){
                        contextMenu.push({
                            text: value.text,
                            onItemClick: () => {value.onClick(e)}
                        });
                    }
                })
            }
    
            if(e.row){
                if (e.row.rowType === "data") {
                    e.items = contextMenu;
                }
            }
        }
    }

    columnDraw = () => {
        let columns = this.props.ColumnConfiguration

        var buttonIndex = searchIndex('buttons','type',columns)

        if(buttonIndex !== undefined){
            if(this.props.grouping){
                columns[buttonIndex].buttons.push({
                    text:"Expand / Collapse All",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.setState({
                            autoExpandAll: true
                        })
                    },
                })
            }
            columns[buttonIndex].caption = 'Action'
            columns[buttonIndex].visible = !this.menuRightClick
            // console.log(columns[buttonIndex])
        }else{
            if(this.props.editingMode !== 'cell'){
                var buttons = []
                if(this.props.allowUpdating){
                    buttons.push('edit')
                }

                if(this.props.allowDeleting){
                    buttons.push('delete')
                }

                if(this.props.grouping){
                    buttons.push({
                        itemType:"button",
                        buttonOptions:{
                            text:"Expand / Collapse All",
                            // type:"default",
                            elementAttr:{class:"bg-dapen-default"},
                            onClick:() => {
                                this.setState({
                                    autoExpandAll: true
                                })
                            },
                        },
                        horizontalAlignment:"left"
                    })
                }

                if(buttons.length > 0){
                    columns.push({
                        type: 'buttons',
                        caption: "Action",
                        buttons: buttons,
                        visible: !this.menuRightClick
                    })
                }
            }
        }
        

        this.columns = columns

        // console.log(this.columns)

        this.expandedRowKey = null
        this.loadedRow = []
    }
    onSelectionChanged = (e) => {
        // console.log(e)
    }

    forceRefresh = (param = false) => {
        this.refreshForced = param
        this.DataGrid.refresh(true)
    }

    repaint = () => {
        this.DataGrid.repaint()
    }
    
    getDataSource = () => {
        return this.ArraySourceData
    }

    injectDataToDataSource = async (data) => {
        var datasource = this.state.dataSource

        if(Array.isArray(data)){
            for(var row of data){
                await datasource.insert(row)
            }
        }else{
            await datasource.insert(data)
        }
    }

    getDeletedIndex = () => {
        return this.DeletedIndex
    }

    emptyDeletedIndex = () => {
        this.DeletedIndex = []
    }

    onRowExpanded = (e) => {
        if(!this.loadedRow.some(value => value === e.key) && this.props.useDelayExpand){
            this.DataGrid.collapseRow(e.key)
            this.expandedRowKey = e.key
        }
    }
    
    forceExpand = () => {
        if(this.expandedRowKey){
            this.loadedRow.push(this.expandedRowKey)
            setTimeout(() => {this.DataGrid.expandRow(this.expandedRowKey)},1)
            
        }
    }

    push = (data) => {
        this.dataSource.push(data)
    }

    updateEditor = (prop) => {
        this.TreeListRef.current.instance.beginUpdate()
        this.TreeListRef.current.instance.option('editing.allowAdding',prop.allowAdding)
        this.TreeListRef.current.instance.option('editing.allowUpdating',prop.allowUpdating)
        this.TreeListRef.current.instance.option('editing.allowDeleting',prop.allowDeleting)
        this.TreeListRef.current.instance.endUpdate()
    }

    getDatagridComponentOption = (optionName) => {
        return  this.TreeListRef.current.instance.option(optionName)
    }

    setDatagrid = (optionName,optionValue) =>{
        this.TreeListRef.current.instance.option(optionName,optionValue)
    }

    render() {
        const { refreshMode, dataSource } = this.state;
        return (
            <TreeList
            ref= {this.TreeListRef}
            id={'grid'}
            keyExpr= {this.props.keyField || 'id'}
            parentIdExpr = {this.props.parentIdExpr}
            showBorders={this.props.showBorders}
            dataSource={dataSource}
            repaintChangesOnly={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            columnMinWidth={100}

            autoExpandAll = {this.props.autoExpandAll}

            onEditingStart={this.onEditingStart}
            onInitNewRow={this.props.onInitNewRow || this.onInitNewRow}
            onRowInserting={this.props.onRowInserting || this.onRowInserting}
            onRowInserted={this.props.onRowInserted ||this.onRowInserted}
            onRowUpdating={ this.props.onRowUpdating || this.onRowUpdating}
            onRowUpdated={this.props.onRowUpdated || this.onRowUpdated}
            onRowRemoving={this.onRowRemoving}
            onRowRemoved={this.props.onRowRemoved ||this.onRowRemoved}
            onFocusedCellChanged={this.props.onFocusedCellChanged || this.onFocusedCellChanged}
            onEditorPreparing={this.props.onEditorPreparing}
            onContextMenuPreparing={this.onContextMenuPreparing}
            
            onRowExpanded = {this.onRowExpanded}

            // columns={this.state.columns}
            onToolbarPreparing={this.props.onToolbarPreparing}
            selection={{ 
                mode: this.props.selection || 'single',
                showCheckBoxesMode: 'always',
                allowSelectAll:true,
                // deferred: true,
                selectAllMode: this.props.selectAllMode || "allPages"
            }}
            onSelectionChanged={this.props.onSelectionChanged || this.onSelectionChanged}

            height = {this.props.height || 'calc(100vh - 305px)'}

            hoverStateEnabled={true}
            rowAlternationEnabled={true}
            >   
                <FilterRow visible={this.props.FilterRow || false} />

                <Paging defaultPageSize={this.props.defaultPageSize || 10} enabled={this.props.paging}/>
                <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20]}
                showInfo={true} />

                <Sorting
                    mode = {this.props.sortingMode || 'multiple'}
                />

                {
                    this.columns.map((column) => {
                        return <Column 
                            key = {column.dataField || column.name || 'buttons'}
                            dataField={column.dataField} 
                            name = {column.name}
                            caption={column.caption} 
                            lookup={column.lookup} 
                            cellRender={column.cellRender} 
                            alignment={column.alignment || 'left'} 
                            cssClass={column.cssClass}
                            format = {column.format}
                            dataType = {column.dataType}
                            width = {column.width}
                            type = {column.type}
                            buttons = {column.buttons}
                            editorType = {column.editorType}
                            editorOptions = {column.editorOptions}
                            visible = {column.visible}
                            sortOrder = {column.sortOrder}
                            allowEditing = {column.allowEditing}
                            calculateCellValue = {column.calculateCellValue}
                            setCellValue = {column.setCellValue}
                            groupIndex={column.groupIndex}
                            fixed={column.fixed}
                            fixedPosition={column.fixedPosition}
                            validationRules ={column.validationRules}
                        >
                            {
                                (column.columns || []).map((columnDetail) => {
                                    return <Column 
                                        key = {columnDetail.dataField || columnDetail.name || 'buttons'}
                                        dataField={columnDetail.dataField} 
                                        name = {columnDetail.name}
                                        caption={columnDetail.caption} 
                                        lookup={columnDetail.lookup} 
                                        cellRender={columnDetail.cellRender} 
                                        alignment={columnDetail.alignment || 'left'} 
                                        cssClass={columnDetail.cssClass}
                                        format = {columnDetail.format}
                                        dataType = {columnDetail.dataType}
                                        width = {columnDetail.width}
                                        type = {columnDetail.type}
                                        buttons = {columnDetail.buttons}
                                        editorType = {columnDetail.editorType}
                                        editorOptions = {columnDetail.editorOptions}
                                        visible = {columnDetail.visible}
                                        sortOrder = {columnDetail.sortOrder}
                                        allowEditing = {columnDetail.allowEditing}
                                        calculateCellValue = {columnDetail.calculateCellValue}
                                        setCellValue = {columnDetail.setCellValue}
                                        groupIndex={columnDetail.groupIndex}
                                        validationRules ={columnDetail.validationRules}
                                    />
                                })
                            }
                        </Column>
                    })
                }

                <Editing
                    refreshMode={refreshMode}
                    mode={this.props.editingMode || 'popup'}
                    useIcons= {true}
                    // allowAdding={this.props.allowAdding}
                    // allowDeleting={this.props.allowDeleting}
                    // allowUpdating={this.props.allowUpdating}
                >
                    <Popup 
                        title={this.props.popupTitle} 
                        showTitle={true} 
                        width={this.props.popupWidth || 600}
                        height={this.props.popupHeight || 300}
                        dragEnabled= {true}
                        resizeEnabled= {true}
                    >
                    </Popup>
                    <Form
                        id={'form'}
                        // formData={this.state.focusedRow}
                        showColonAfterLabel={true}
                        labelLocation={this.props.popupFormLabelLocation || 'top'}
                        minColWidth={this.props.popupFormMinColWidth || 300}
                        colCount={this.props.popupFormColCount || 1}
                        items = {this.props.formItems}
                    />
                    
                </Editing>

                <ColumnChooser enabled={this.props.ColumnChooser || false} />
                <ColumnFixing enabled={this.props.ColumnFixing || false} />
                <Scrolling showScrollbar="always" />
                {/* <MasterDetail 
                    enabled={this.props.allowEnabledMasterDetail}
                    component={this.props.masterDetailComponent}
                    autoExpandAll={this.props.autoExpandmasterDetail}
                /> */}
            </TreeList>
        );
    }
}

export default DevExtremeTreeList;
