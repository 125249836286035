import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import { currency, productDefault, rekeningBank } from 'dataSource/lookup';
import ModalOperasionalPenyewaan from 'components/pages/modal/investasi/aset-non-portfolio/operasional/penyewaan/index';
import { addURL } from 'redux/actions/url';
import ModalListAsetProperti from 'components/pages/modal/investasi/aset-non-portfolio/operasional/penyewaan/list-aset';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';

class OperasionalPenyewaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {}
        }

        this.modalOperasionalPenyewaanRef = React.createRef()
        this.modalListAsetRef = React.createRef()
        this.modalActionInvoiceOperasionalPenyewaanRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalOperasionalPenyewaan('edit', e.row.data)
                        }
                    },
                    {
                        text: 'List Aset',
                        hint: 'List Aset',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalListAset(e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            var data = e.row.data
                            if(data.status == 'OPEN'){
                                confirmAlert({
                                    title: 'Kirim data',
                                    message: 'Anda yakin ingin mengirim data ini?',
                                    buttons:  [
                                        {
                                            label: 'Ya',
                                            onClick: (e) => {
                                                this.kirimData(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            }else{
                                confirmAlert({
                                    title: 'Sudah terkirim!',
                                    message: 'Data telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            console.log('Cancelled')
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'konfirmasiNo',
                caption: 'No. Konfirmasi',
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'jumlahAset',
                caption: 'Jumlah Aset',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'penerimaanSewa',
                caption: 'Penerimaan Sewa',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pengajuanDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tenan',
                        caption: 'Tenan'
                    },
                    {
                        dataField: 'nominalTotal',
                        caption: 'Nominal Sewa',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'periodeTagihan',
                        caption: 'Periode Tagihan',
                    },
                    {
                        dataField: 'nominalTagihan',
                        caption: 'Nominal Tagihan',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'nominalTransaksi',
                        caption: 'Nominal Diterima',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'receiveRekBankId',
                        caption: 'Rekening Bank',
                        lookup: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.rekNo + ' - ' + e.rekName
                            }
                        }
                    },
                    {
                        dataField: 'currencyRate',
                        caption: 'Kurs'
                    },
                ]
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    kirimData = async(data) => {
        let submitData = {
            bankTransactionId: data.id,
            konfirmasiNo: data.konfirmasiNo,
        }
        if(submitData){
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transaction/kirim-penerimaan-properti-sewa', 'POST', {values: submitData})
                this.forceRefresh()
                notify({ message: 'Data berhasil dikirim!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Transaksi',
                    icon: 'plus',
                    onClick: async(e) => {
                        var noKonfirm = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transactions/generate-no-konfirmasi/PENERIMAAN_SEWA_ASSET', 'POST', {values: {}})
                        var data = {
                            transactionTypeId: 282,
                            currencyId: 1001,
                            kurs: 1,
                            noKonfirmasi: noKonfirm
                        }
                        this.showModalOperasionalPenyewaan('add', data)
                    },
                }
            },
        )
    }

    showModalOperasionalPenyewaan = (type, data) => {
        this.modalOperasionalPenyewaanRef.current.show(type, data)
    }

    showModalListAset = (data = null) => {
        this.modalListAsetRef.current.show()
        this.modalListAsetRef.current.retrieveData(data)
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Transaksi Penerimaan Sewa Properti</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='bank-transactions??size=9999&status.in=OPEN&transactionTypeId.in=282'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Penyewaan Aset"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Penyewaan Aset'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalOperasionalPenyewaan
                    ref={this.modalOperasionalPenyewaanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalListAsetProperti
                    ref={this.modalListAsetRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default OperasionalPenyewaan