import React, { Component } from 'react'
import { Popup,Form,FileUploader } from 'devextreme-react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf'
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage'
import httpRequest from 'plugin/httprequest'
import DevExtremeTreeList from 'components/inheritComponent/devextremetreelist'
import { jabatan, karyawan, sdmUnit } from 'dataSource/lookup'
import notify from "devextreme/ui/notify";

class FormHasilEvaluasiNew extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            DataDocument:{},
            DataMaster:{}
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.treelistRef = React.createRef()
        this.dataGridListRef = React.createRef()
        this.filterByGroup = []
        this.idKinerja = 0

        this.form = [
            {
                dataField: 'periodeTahun',
                label: {
                    text: 'Periode'
                },
                // editorType: 'dxDateBox',
                editorOptions: {
                    // useMaskBehavior: true,
                    // openOnFieldClick: true,
                    // displayFormat: 'dd MMM yyyy',
                    readOnly:true
                }
            },
            {
                dataField: 'evaluasiDate',
                label: {
                    text: 'Tanggal Evaluasi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Nama Karyawan'
                },
                editorType:'dxSelectBox',
                editorOptions: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'fullName',
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged:(e) => {
                        if (e.selectedItem) {
                            var DataMaster = {}
                            DataMaster.jabatanId = e.selectedItem.jabatanId
                            DataMaster.unitId = e.selectedItem.unitId
                            this.formRef.current.instance.updateData(DataMaster)
                        }
                    }
                }
            },
            {
                dataField: 'jabatanId',
                label: {
                    text: 'Jabatan'
                },
                editorType:'dxSelectBox',
                editorOptions: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                }
            },
            {
                dataField: 'unitId',
                label: {
                    text: 'Unit Kerja'
                },
                editorType:'dxSelectBox',
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName',
                    searchEnabled: true,
                    deferRendering: false,
                    readOnly:true
                }
            },
        ]

        this.columns = [
            {
                dataField:"kompetensi",
                caption:"Kompetensi",
                allowEditing: false,
                cellRender: (e) => {
                    let kompetensiId = e.row.data.kompetensiId;
                    
                    kompetensiId = kompetensiId.slice(0, 5);
                    if (kompetensiId === 'group') {
                        return <b>{e.value}</b>
                    } else {
                        return <span className='ml-3'>{e.value}</span>
                    }
                }
            },
            {
                dataField:"bobotPercentage",
                caption:"Bobot (%)",
                allowEditing: false,
            },
            {
                dataField:"nilai",
                caption:"Nilai",
                setCellValue : async (newData, value, currentRowData) => {
                    newData.bobotxNilai = value * currentRowData.bobotPercentage / 100
                    if (value > currentRowData.targetNilai) {
                        notify({ message: 'Nilai melebihi Target Nilai', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
                        newData.nilai = currentRowData.targetNilai
                    }else{
                        newData.nilai = value
                    }
                }
            },
            {
                dataField:"targetNilai",
                caption:"Target Nilai (Max)" ,
                allowEditing: false,
            },
            {
                dataField:"bobotxNilai",
                caption:"Bobot x Nilai",
                allowEditing: false,
                // dataType: 'number',
                // format: '#,##0',
                // editorType: 'dxNumberBox',
                // editorOptions: {
                //     format: '#,##0',
                // },
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Close",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];
    }

    retrieveData = async(data) => {    
        this.setState({
            DataMaster: data,
        })
    }

    getIdEvaluasiKinerja = (idKinerja) => {
        this.idKinerja = idKinerja
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataDocument = { ...this.state.DataDocument };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;

            console.log(DataDocument);
            this.setState({
                DataDocument: DataDocument
            });
        };

        fr.readAsDataURL(files);
        }
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`], state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    submitData = async (e) => {
        try {
            var dataMaster = {...this.state.DataMaster}
            var dataDetail = this.dataGridListRef.current.getDataSource()

            dataMaster.evaluasiKinerjaId = this.idKinerja
            dataMaster.evaluasiDate = formatDate(dataMaster.evaluasiDate)
            dataDetail = dataDetail.slice(0,13)
            var detail = []
            for(var value of dataDetail){
                let dataValue = {
                    bobot: value.bobotPercentage,
                    groupKompetensiId: value.groupKompetensiId ? parseInt(value.groupKompetensiId.slice(6)) : null,
                    karyawanId: dataMaster.karyawanId,
                    kompetensiId: value.kompetensiId ? parseInt(value.kompetensiId.slice(6)) : null,
                    nilai: parseInt(value.nilai), 
                    skor: value.bobotxNilai,
                    target: value.targetNilai
                }
                detail.push(dataValue);

                // console.log(dataValue)
                // dataValue.kompetensiId = dataValue.kompetensiId ? parseInt(dataValue.kompetensiId.slice(6)) : null
                // dataValue.groupKompetensiId = dataValue.groupKompetensiId ? parseInt(dataValue.groupKompetensiId.slice(6)) : null
                // dataValue.karyawanId = dataMaster.karyawanId
                // dataValue.nilai = dataValue.nilai
                // dataValue.skor = dataValue.bobotxNilai
                // dataValue.bobot = dataValue.bobotPercentage
                // detail.push(dataValue)
            }
            var data = {...dataMaster}
            // data.kompetensiDetails = detail
            data.kompetensiDetails = detail.slice(2, 4)
            console.log(data);
            // return null;
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'save-hasil-evaluasi',"POST", {
                values: data,
            });

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
        
    }

    show() {
        this.dataGridListRef.current.forceRefresh(true) 
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.idKinerja = 0
        this.setState({
            popupVisible: false,
            DataDocument:{},
            DataMaster:{}
        })
    }

    loadData = async () => {
        var parent = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'group-kompetensis',"GET",);
        var child = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,'kompetensi/getAllGroupKompetensi',"GET",);

        parent = parent.map(value => {
            return {
                "bobotPercentage": null,
                "kompetensiId": 'group-'+value.id,
                "kompetensi": value.namaGroup,
                "targetNilai": null,
                "groupKompetensiId": null
              }
        })
        child = child.map(value => {
            value.kompetensiId = 'child-'+value.kompetensiId
            value.groupKompetensiId = 'group-'+value.groupKompetensiId
            return value
        })

        var response =  [...parent,...child]
        response = response.sort((a,b)=>{
            if(a.kompetensiId > b.kompetensiId){
                return 1
            }
            if(a.kompetensiId < b.kompetensiId){
                return -1
            }
        })

        return response.toReversed();
    }

    allowUpdating = (e) => {
        return e.row.data.groupKompetensiId !== null
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Hasil Evaluasi"}
                width={'50vw'}
                height={'auto'}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="container-fluid">
                        <Form
                            ref={this.formRef}
                            colCount={3}
                            id={'formPengajuanPemberhentian'}
                            formData={this.state.DataMaster}
                            items={this.form}
                            labelLocation = 'left'
                        />
                        <div className="row">
                            <div className="col-sm-4">
                                {/* <label style={{fontSize:"13px"}}>Dokumen</label> */}
                                <FileUploader
                                    accept={".png,.jpg.jpeg"}
                                    uploadMode={"useForm"}
                                    onValueChanged={this.onUploadStarted.bind(this)}
                                    labelText={this.state.DataDocument.dokumenName || 'Upload File'}
                                    showFileList={false}
                                    name={"dokumen"}
                                    // disabled={this.props.from == 'pengajuan' ? false : true}
                                />
                            </div>
                            <div className="col-sm-4 pt-3">
                                <button onClick={() => this.previewFile(this.state.DataDocument, 'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                            </div>
                        </div>
                        
                        <DevExpressDataGrid
                                ref={this.dataGridListRef}
                                menuRightClick={false}
                                keyField={'kompetensiId'}
                                // parentIdExpr = {'groupKompetensiId'}

                                loadAPI='kompetensi/getAllGroupKompetensi'
                                insertAPI='sys-menus'
                                updateAPI='sys-menus'
                                deleteAPI='sys-menus'

                                backendserver={process.env.REACT_APP_BACKEND_HRD}


                                useNotify = {false}
                                autoExpandAll = {true}
                                useArraySource = {true}
                                ArraySourceData = {this.loadData}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={this.allowUpdating}
                                editingMode="row"

                                exportExcel={false}
                                exportFileName={"Jurnal Manual"}
                                allowExportSelectedData={true}
                                selection={"none"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Jurnal Manual Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={300} //masukkan dalam ukuran pixel

                                height={'calc(100vh - 660px)'}

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                // FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                summaryTotalItem={this.sumJurnalInfo}

                                // onRowInserted={this.onRowInsertedJournalDetail}
                                // onRowUpdated={this.onRowUpdatedJournalDetail}
                                // onRowRemoved={this.onRowRemovedJournalDetail}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                                />
                        {/* <DevExtremeTreeList
                        ref = {this.treelistRef}
                        keyField={'kompetensiId'}
                        parentIdExpr = {'groupKompetensiId'}

                        menuRightClick = {false}

                        loadAPI='kompetensi/getAllGroupKompetensi'
                        insertAPI='sys-menus'
                        updateAPI='sys-menus'
                        deleteAPI='sys-menus'

                        backendserver={process.env.REACT_APP_BACKEND_HRD}

                        useNotify = {false}
                        autoExpandAll = {true}
                        useArraySource = {true}
                        ArraySourceData = {this.loadData}

                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={this.allowUpdating}

                        editingMode = 'row'

                        onRowUpdating = {this.onRowUpdating}

                        exportExcel={false}
                        exportFileName={"User Group"}
                        allowExportSelectedData={false}
                        selection={'none'}
                        showBorders={true}

                        paging={false}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Data Menu'}
                        popupWidth={400} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        height = {'calc(70vh - 150px)'}

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        // onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    /> */}

                    </div>
                    <ModalPreviewPdf
                        ref={this.modalPreviewPdfRef}
                        store={this.props.store}
                    />
                    <ModalPreviewImage
                        ref={this.modalPreviewImageRef}
                        store={this.props.store}
                    />
                </Popup>
        )
    }
}

export default FormHasilEvaluasiNew