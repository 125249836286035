import React, { Component } from 'react'
import { currency, banks, product, instrumentCategories, statusKeuangan, custodies, rekCustody, pejabat} from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import uuidv4 from 'uuid/v4'
import FormInstruksiKustodiAktif from 'components/pages/modal/investasi/instruksi/kustodi/aktif/index'
import httpRequest from 'plugin/httprequest'
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify'
import { formatDefaultFullDate, formatNumber, formatNumberAum, formatNumberNav, PrintElem } from 'plugin/helper'
import { reportFile } from 'plugin/reportRequest'

class InstruksiKustodiAktif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }

        this.rekBrokerData = []
        this.brokerData = []
        this.bankData = []
        this.karyawanData = []
        this.jabatanData = []
        this.globalData = {}

        this.dataGridInstruksiKustodiRef = React.createRef()
        this.formPenempatanRef = React.createRef()
        this.orderColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModalPenempatan(e.row.data)
                        }
                    },
                    {
                        text: 'Cetak Instruksi',
                        hint: 'Cetak Instruksi',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.instruksiCode == null) {
                                confirmAlert({
                                    title: 'Data belum lengkap!',
                                    message: 'Silahkan lengkapi data terlebih dahulu',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            } else {
                                this.cetakInstruksi(data)
                            }
                        }
                    },
                    // {
                    //     text: 'Kirim',
                    //     hint: 'Kirim',
                    //     onClick: (e) => {
                    //         var data = e.row.data
                    //         confirmAlert({
                    //             title: 'Apakah yakin?',
                    //             message: 'Apakah anda yakin ingin mengirim instruksi ini?',
                    //             buttons: [
                    //                 {
                    //                     label: 'Ya',
                    //                     onClick: () => {
                    //                         this.sendInstruksi(data)
                    //                     }
                    //                 },
                    //                 {
                    //                     label: 'Tidak'
                    //                 }
                    //             ]
                    //         })
                    //     }
                    // },
                    // {
                    //     text: 'Batal',
                    //     hint: 'Batal',
                    //     onClick: (e) => {
                    //         var data = e.row.data
                    //         confirmAlert({
                    //             title: 'Apakah yakin?',
                    //             message: 'Apakah anda yakin ingin membatalkan instruksi ini?',
                    //             buttons: [
                    //                 {
                    //                     label: 'Ya',
                    //                     onClick: (e) => {
                    //                         this.cancelInstruksi(data)
                    //                     }
                    //                 },
                    //                 {
                    //                     label: 'Tidak',
                    //                 }
                    //             ]
                    //         })
                    //     }
                    // },
                ]
            },
            {
                dataField: 'instruksiId',
                caption: 'ID'
            },
            {
                dataField: 'custodyId',
                caption: 'Bank Kustodi',
                lookup: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'custodyName'
                }
            },
            {
                dataField: 'rekCustodyId',
                caption: 'No Akun / Rekening',
                lookup: {
                    dataSource: rekCustody(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                },
            },
            {
                dataField: 'instruksi',
                caption: 'Instruksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'instruksiCode',
                        caption: 'Nomor'
                    },
                    {
                        dataField: 'instruksiDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'pejabat1',
                        caption: 'Pejabat 1',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                    {
                        dataField: 'pejabat2',
                        caption: 'Pejabat 2',
                        lookup: {
                            dataSource: pejabat(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName'
                        }
                    },
                ]
            },
            {
                dataField: 'categoryId',
                caption: 'Kategori',
                lookup: {
                    dataSource: instrumentCategories(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categoryName'
                }
            },
            {
                dataField: 'transactionName',
                caption: 'Transaksi',
            },
            // {
            //     dataField: 'transactionDate',
            //     caption: 'Tanggal Transaksi',
            //     dataType: 'date',
            //     format: 'dd MMM yyyy'
            // },
            // {
            //     dataField: 'tanggalJatuhTempo',
            //     caption: 'Tanggal Jatuh Tempo',
            //     dataType: 'date',
            //     format: 'dd MMM yyyy'
            // },
            {
                dataField: 'totalTransaction',
                caption: 'Total Transaksi',
                dataType: 'number',
                format: '#,##0'
            },
            {
                dataField: 'statusKeuanganId',
                caption: 'Status Keuangan',
                lookup: {
                    dataSource: statusKeuangan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName'
                }
            },
            // {
            //     dataField: 'currencyId',
            //     caption: 'Mata Uang',
            //     lookup: {
            //         dataSource: currency(this.props.store),
            //         valueExpr: 'id',
            //         displayExpr: 'currencyCode'
            //     }
            // },
            {
                dataField: 'status',
                caption: 'Status'
            },
        ]
    }

    componentWillMount = async () => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var getRekBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'rek-brokers', 'GET')
        var getBroker = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'brokers', 'GET')
        var getBank = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'banks', 'GET')
        var getGlobalParam = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'global-parameters/get-by-paramCode/1 FP', 'GET')
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawans?size=9999', 'GET')
        var getjabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'jabatans', 'GET')

        this.rekBrokerData = getRekBroker
        this.brokerData = getBroker
        this.bankData = getBank
        this.globalData = getGlobalParam
        this.karyawanData = getKaryawan
        this.jabatanData = getjabatan
    }
    forceRefresh = () => {
        this.dataGridInstruksiKustodiRef.current.forceRefresh(true)
    }
    cancelInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        if (instruksiId) {
            try {
                var insertAPI = 'order-stoks/batal-instruksi-custody'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: [
                        instruksiId
                    ]
                })
                notify({ message: 'Pembatalan Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }
    sendInstruksi = async (data) => {
        var instruksiId = data.instruksiId
        var loadAPI = []
        var insertAPI = []
        if (data.categoryId == 1) {
            loadAPI = `order-stok-instruksi-custody/${instruksiId}`
            insertAPI = 'order-stoks/send-instruksi-custody'
        } else if (data.categoryId == 9) {
            loadAPI = `order-etf-instruksi-custody/${instruksiId}`
            insertAPI = 'order-etfs/send-instruksi-custody'
        } else if (data.categoryId == 4) {
            loadAPI = `order-sbns/get-instruksi-custody/${instruksiId}`
            insertAPI = 'order-sbns/send-instruksi-custody'
        }
        var responseDetailInstruksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var fileInstruksi = responseDetailInstruksi.custodyInstruksiDTO.fileInstruksi
        if (data.pejabat1 || data.pejabat2 == null) {
            if (fileInstruksi != null) {
                if (instruksiId) {
                    try {
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                            values: [
                                instruksiId
                            ]
                        })
                        notify({ message: 'Pengiriman Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                        this.forceRefresh()
                        return response
                    } catch (e) {
                        console.log(e)
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } else {
                notify({ message: 'Mohon lengkapi file instruksi terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        } else {
            notify({ message: 'Mohon lengkapi data terlebih dahulu!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
        }
    }
    cetakInstruksi = async (data) => {
        var loadAPI = []
        if (data.categoryId == 1) {
            loadAPI = `order-stok-instruksi-custody/${data.instruksiId}`
        } else if (data.categoryId == 9) {
            loadAPI = `order-etf-instruksi-custody/${data.instruksiId}`
        } else if (data.categoryId == 4 || 5 || 6 || 7) {
            loadAPI = `order-sbns/get-instruksi-custody/${data.instruksiId}`
        }
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var result = []
        var dataMaster = detail.custodyInstruksiDTO
        if (dataMaster.orderTypeId == 212) {
        } else if (dataMaster.orderTypeId == 211) {
            var total = 0
            var no = 0
            const dirut = this.karyawanData.filter(val => val.jabatanId === 5)
            for (var saham of detail.custodyTransactionInfoList) {
                var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${saham.instrumentId}`, 'GET')
                total = total + saham.amountNet
                var dataSaham = {
                    no: ++no,
                    jumlahLembar: formatNumber(saham.quantity),
                    tradeDate: formatDefaultFullDate(saham.trsDate),
                    settlementDate: formatDefaultFullDate(saham.settleDate),
                    jumlahPembayaran: formatNumberAum(saham.amountNet),
                    namaSaham: dataInstrument.instrumentCode,
                    broker: this.brokerData.find(val => val.id == saham.brokerId).brokerName
                }
                result.push(dataSaham)
            }
            var data = {
                template: {
                    'shortid': 'rklnU1KFLD',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "instruksiNo": data.instruksiCode,
                    "instruksiDate": formatDefaultFullDate(detail.custodyTransactionInfoList[0].settleDate),
                    "saham": result,
                    "instrumentName": data.sahamBuy,
                    "rekNo": this.rekBrokerData.find(val => val.id == detail.custodyTransactionInfoList[0].rekBrokerId).rekNo,
                    "rekName": this.rekBrokerData.find(val => val.id == detail.custodyTransactionInfoList[0].rekBrokerId).rekName,
                    "rekBankName": "Bank -",
                    "rekBankCabang": "Jakarta",
                    "brokerName": data.brokerName,
                    "instruksiKepada": dataMaster.instruksiKepada,
                    "instruksiUp": dataMaster.instruksiUp,
                    "instruksiFax": this.globalData.stringValue,
                    "noFaximile": dataMaster.instruksiFax,
                    "pejabat1": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat1)).fullName,
                    "pejabat2": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat2)).fullName,
                    "jabatan1": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan1)).jabatanName,
                    "jabatan2": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan2)).jabatanName,
                    "pejabatDirut": dirut[0].fullName,
                    "brokerName": result[0].broker,
                    "totalPembayaran": formatNumberAum(total)
                },
                options: {
                    reportName: "INSTRUKSI-" + data.instruksiCode
                }
            }
            reportFile(data, true, `INSTRUKSI-${dataMaster.instruksiCode}.pdf`)
            // var print = await reportRequest(this.props.store, data)
            // PrintElem(print.data)
            // return print
        } else if (dataMaster.orderTypeId == 302) {
            // Pembelian ETF
            var total = 0
            var no = 0
            for (var saham of detail.custodyTransactionInfoList) {
                var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${saham.instrumentId}`, 'GET')
                total = total + saham.amountNet
                var dataSaham = {
                    no: ++no,
                    jumlahLembar: formatNumber(saham.quantity),
                    tradeDate: formatDefaultFullDate(saham.trsDate),
                    settlementDate: formatDefaultFullDate(saham.settleDate),
                    jumlahPembayaran: formatNumberAum(saham.amountNet),
                    namaSaham: dataInstrument.instrumentCode,
                    broker: this.brokerData.find(val => val.id == saham.brokerId).brokerName
                }
                result.push(dataSaham)
            }
            var data = {
                template: {
                    'shortid': 'B1eBKFQDsd',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "instruksiNo": data.instruksiCode,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "saham": result,
                    "instrumentName": data.sahamBuy,
                    "rekNo": this.rekBrokerData.find(val => val.id == detail.custodyTransactionInfoList[0].rekBrokerId).rekNo,
                    "rekName": this.rekBrokerData.find(val => val.id == detail.custodyTransactionInfoList[0].rekBrokerId).rekName,
                    "rekBankName": "Bank -",
                    "rekBankCabang": "Jakarta",
                    "brokerName": data.brokerName,
                    "instruksiKepada": dataMaster.instruksiKepada,
                    "instruksiUp": dataMaster.instruksiUp,
                    "instruksiFax": this.globalData.stringValue,
                    "noFaximile": dataMaster.instruksiFax,
                    "pejabat1": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat1)).fullName,
                    "pejabat2": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat2)).fullName,
                    "jabatan1": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan1)).jabatanName,
                    "jabatan2": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan2)).jabatanName,
                    "brokerName": result[0].broker,
                    "totalPembayaran": formatNumberAum(total)
                },
                options: {
                    reportName: "INSTRUKSI-" + data.instruksiCode
                }
            }
            reportFile(data, true, `INSTRUKSI-${dataMaster.instruksiCode}.pdf`)
            // var print = await reportRequest(this.props.store, data)
            // PrintElem(print.data)
            // return print
        } else if (dataMaster.orderTypeId == 303) {
            // Penjualan ETF
            var total = 0
            var no = 0
            for (var saham of detail.custodyTransactionInfoList) {
                var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${saham.instrumentId}`, 'GET')
                total = total + saham.amountNet
                var dataSaham = {
                    no: ++no,
                    jumlahLembar: formatNumber(saham.quantity),
                    tradeDate: formatDefaultFullDate(saham.trsDate),
                    settlementDate: formatDefaultFullDate(saham.settleDate),
                    jumlahPembayaran: formatNumberAum(saham.amountNet),
                    namaSaham: dataInstrument.instrumentCode,
                    broker: this.brokerData.find(val => val.id == saham.brokerId).brokerName
                }
                result.push(dataSaham)
            }
            var data = {
                template: {
                    'shortid': 'BkxoQ3apj_',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "instruksiNo": data.instruksiCode,
                    "instruksiDate": formatDefaultFullDate(data.instruksiDate),
                    "saham": result,
                    "instrumentName": data.sahamBuy,
                    "rekNo": this.rekBrokerData.find(val => val.id == detail.custodyTransactionInfoList[0].rekBrokerId).rekNo,
                    "rekName": this.rekBrokerData.find(val => val.id == detail.custodyTransactionInfoList[0].rekBrokerId).rekName,
                    "rekBankName": "Bank -",
                    "rekBankCabang": "Jakarta",
                    "brokerName": data.brokerName,
                    "instruksiKepada": dataMaster.instruksiKepada,
                    "instruksiUp": dataMaster.instruksiUp,
                    "instruksiFax": this.globalData.stringValue,
                    "noFaximile": dataMaster.instruksiFax,
                    "pejabat1": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat1)).fullName,
                    "pejabat2": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat2)).fullName,
                    "jabatan1": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan1)).jabatanName,
                    "jabatan2": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan2)).jabatanName,
                    "brokerName": result[0].broker,
                    "totalPembayaran": formatNumberAum(total)
                },
                options: {
                    reportName: "INSTRUKSI-" + data.instruksiCode
                }
            }
            reportFile(data, true, `INSTRUKSI-${dataMaster.instruksiCode}.pdf`)
            // var print = await reportRequest(this.props.store, data)
            // PrintElem(print.data)
            // return print
        } else if (dataMaster.orderTypeId == 243 || 253) {
            // Pembelian SBN
            var dataSbn = detail.custodyTransactionInfoList[0]
            var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataSbn.instrumentId}`, 'GET')
            var dataCust = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `custodies/${dataMaster.custodyId}`, 'GET')
            var dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-banks/${dataMaster.rekBankId}`, 'GET')
            var dataRekCust = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-custodies/custodyId/${dataCust.id}`)
            var data = {
                template: {
                    'shortid': 'Byxc82FtUv',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "custodyName": dataMaster.instruksiKepada,
                    "date": formatDefaultFullDate(dataMaster.instruksiDate),
                    "up": dataMaster.instruksiUp,
                    "noSurat": dataMaster.instruksiCode,
                    "noFaximile": dataMaster.instruksiFax,
                    "noFax": this.globalData.stringValue,

                    "transactionType": dataSbn.cbestTransactionType,
                    "securitiesSeries": dataInstrument.instrumentName,
                    "faceAmount": formatNumberAum(dataSbn.faceValue),
                    "coupon": formatNumberNav(dataInstrument.rate) + ' %',
                    "price": formatNumberNav(dataSbn.price) + ' %',
                    "accruedInterst": formatNumberAum(dataSbn.accruedInterest),
                    "procced": formatNumberAum(dataSbn.amountTrs),
                    "netProcced": formatNumberAum(dataSbn.amountNet),
                    "totalPayment": formatNumberAum(dataSbn.amountNet),
                    "transactionDate": formatDefaultFullDate(dataSbn.transactionDate),
                    "settlementDate": formatDefaultFullDate(dataSbn.settleDate),
                    "counterparty": dataCust.custodyName,
                    "accNo": dataRekCust.rekNo,
                    "beneficiary": dataSbn.brokerId ? this.brokerData.find(val => val.id == dataSbn.brokerId).brokerName : this.bankData.find(val => val.id == dataSbn.bankId).bankName,
                    "aquicitonPrice": formatNumberNav(dataSbn.price) + ' %',
                    "aquicitonDate": (dataSbn.acquisitionDate),
                    "accruedIntax": "0",
                    "capitalGainTax": formatNumberAum(dataSbn.pphCapitalGain),
                    "pejabat1": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat1)).fullName,
                    "pejabat2": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat2)).fullName,
                    "jabatan1": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan1)).jabatanName,
                    "jabatan2": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan2)).jabatanName,
                    "custody": dataCust.custodyName,
                    "code": '2.1.2.008',
                    "attn": "Kreshna Narendra Ksatriya",
                    "description": `Dana sebesar  Rp ${formatNumberAum(dataSbn.amountNet)}  dapat  Saudara Kredit ke rekening giro Nomor ${dataRekBank.rekNo} atas nama ${dataRekBank.rekName} pada ${this.bankData.find(value => value.id === dataRekBank.bankId).bankname} Cabang ${dataRekBank.cabang}.`
                },
                options: {
                    reportName: "INSTRUKSI-" + data.instruksiCode
                }
            }

            reportFile(data, true, `INSTRUKSI-${dataMaster.instruksiCode}.pdf`)
        } else if (dataMaster.orderTypeId == 242 || 254) {
            // Penjualan SBN
            var dataSbn = detail.custodyTransactionInfoList[0]
            var dataInstrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${dataSbn.instrumentId}`, 'GET')
            var dataCust = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `custodies/${dataMaster.custodyId}`, 'GET')
            var dataRekBank = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-banks/${dataMaster.rekBankId}`, 'GET')
            var dataRekCust = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `rek-custodies/custodyId/${dataCust.id}`)
            var data = {
                template: {
                    'shortid': 'SJlY_0YKLP',
                    'recipe': 'chrome-pdf'
                },
                data: {
                    "custodyName": dataMaster.instruksiKepada,
                    "date": formatDefaultFullDate(dataMaster.instruksiDate),
                    "up": dataMaster.instruksiUp,
                    "noSurat": dataMaster.instruksiCode,
                    "noFaximile": this.globalData.stringValue,
                    "noFax": dataMaster.instruksiFax,

                    "transactionType": dataSbn.cbestTransactionType,
                    "securitiesSeries": dataInstrument.instrumentName,
                    "faceAmount": formatNumberAum(dataSbn.faceValue),
                    "coupon": formatNumberNav(dataInstrument.rate) + ' %',
                    "price": formatNumberNav(dataSbn.price) + ' %',
                    "accruedInterst": formatNumberAum(dataSbn.accruedInterest),
                    "procced": formatNumberAum(dataSbn.amountTrs),
                    "netProcced": formatNumberAum(dataSbn.amountNet),
                    "totalPayment": formatNumberAum(dataSbn.amountNet),
                    "transactionDate": formatDefaultFullDate(dataSbn.transactionDate),
                    "settlementDate": formatDefaultFullDate(dataSbn.settleDate),
                    "counterparty": dataCust.custodyName,
                    "accNo": dataRekCust.rekNo,
                    "beneficiary": dataSbn.brokerId ? this.brokerData.find(val => val.id == dataSbn.brokerId).brokerName : this.bankData.find(val => val.id == dataSbn.bankId).bankName,
                    "aquicitonPrice": formatNumberNav(dataSbn.price) + ' %',
                    "aquicitonDate": (dataSbn.acquisitionDate),
                    "accruedIntax": "0",
                    "capitalGainTax": formatNumberAum(dataSbn.pphCapitalGain),
                    "pejabat1": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat1)).fullName,
                    "pejabat2": this.karyawanData.find(val => val.id === parseInt(dataMaster.pejabat2)).fullName,
                    "jabatan1": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan1)).jabatanName,
                    "jabatan2": this.jabatanData.find(val => val.id === parseInt(dataMaster.jabatan2)).jabatanName,
                    "custody": dataCust.custodyName,
                    "code": '2.1.2.008',
                    "attn": "Kreshna Narendra Ksatriya",
                    "description": `Dana sebesar  Rp ${formatNumberAum(dataSbn.amountNet)}  dapat  Saudara Kredit ke rekening giro Nomor ${dataRekBank.rekNo} atas nama ${dataRekBank.rekName} pada ${this.bankData.find(value => value.id === dataRekBank.bankId).bankname} Cabang ${dataRekBank.cabang}.`
                },
                options: {
                    reportName: "INSTRUKSI-" + data.instruksiCode
                }
            }
            reportFile(data, true, `INSTRUKSI-${dataMaster.instruksiCode}.pdf`)
            // var print = await reportRequest(this.props.store, data)
            // PrintElem(print.data)
            // return print
        }
    }
    showModalPenempatan = async (data) => {
        var loadAPI = []
        if (data.categoryId == 1) {
            loadAPI = `order-stok-instruksi-custody/${data.instruksiId}`
        } else if (data.categoryId == 9) {
            loadAPI = `order-etf-instruksi-custody/${data.instruksiId}`
        } else if (data.categoryId == 4 || 5 || 6 || 7) {
            loadAPI = `order-sbns/get-instruksi-custody/${data.instruksiId}`
        }
        var detailInstruksi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET')
        var dataMaster = detailInstruksi.custodyInstruksiDTO

        var loadCustody = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `custodies/${dataMaster.custodyId}`, 'GET')
        
        var result = []
        for (var dataDetail of detailInstruksi.custodyTransactionInfoList) {
            if (dataMaster.categoryId == 9) {
                // ETF
                var getData = {
                    id: dataDetail.id,
                    brokerId: dataDetail.brokerId,
                    settleNo: dataDetail.settleNo,
                    settleDate: dataDetail.settleDate,
                    transactionTypeId: dataDetail.transactionTypeId,
                    instrumentId: dataDetail.instrumentId,
                    quantity: dataDetail.quantity,
                    price: dataDetail.price,
                    amountTrs: dataDetail.amountTrs,
                    amountNet: dataDetail.amountNet,
                }
            } else if (dataMaster.categoryId == 1) {
                // Saham
                var getData = {
                    id: dataDetail.id,
                    brokerId: dataDetail.brokerId,
                    settleNo: dataDetail.settleNo,
                    settleDate: dataDetail.settleDate,
                    transactionTypeId: dataDetail.transactionTypeId,
                    instrumentId: dataDetail.instrumentId,
                    quantity: dataDetail.quantity,
                    price: dataDetail.price,
                    amountTrs: dataDetail.amountTrs,
                    amountNet: dataDetail.amountNet,
                }
            } else if (dataMaster.categoryId == 4 || 5 || 6 || 7) {
                // SBN
                var getData = {
                    id: dataDetail.id,
                    brokerId: dataDetail.brokerId,
                    bankId: dataDetail.bankId,
                    settleNo: dataDetail.settleNo,
                    settleDate: dataDetail.settleDate,
                    transactionTypeId: dataDetail.transactionTypeId,
                    instrumentId: dataDetail.instrumentId,
                    quantity: dataDetail.faceValue,
                    price: dataDetail.price,
                    amountTrs: dataDetail.amountTrs,
                    amountNet: dataDetail.amountNet,
                }
            }
            result.push(getData)
        }
        var allData = {
            ...dataMaster,
            detail: result,
            custody: loadCustody
        }
        this.formPenempatanRef.current.show(allData)
    }
    loadData = async () => {
        var dataStok = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksi-stok/list?status=OPEN`, 'GET')
        var dataEtf = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksi-etf/list?status=OPEN`, 'GET')
        var dataSbn = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `custody-instruksi-sbn/list?status=OPEN`, 'GET')
        var result = []
        var allData = [
            ...dataStok,
            ...dataEtf,
            ...dataSbn
        ]
        for (var data of allData) {
            var dataResult = {
                instruksiId: data.instruksiId,
                custodyId: data.custodyId,
                instruksiCode: data.instruksiCode,
                instruksiDate: data.instruksiDate,
                pejabat1: data.pejabat1,
                pejabat2: data.pejabat2,
                transactionName: data.transactionName,
                categoryId: data.categoryId,
                brokerId: data.brokerId,
                totalTransaction: data.totalTransaction,
                statusKeuanganId: data.statusKeuanganId,
                rekCustodyId: data.rekCustodyId,
                status: data.status,
            }
            result.push(dataResult)
        }
        return result
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Instruksi Bank Kustodi</h2>
                <DevExpressDataGrid
                    keyField='instruksiId'
                    ref={this.dataGridInstruksiKustodiRef}
                    loadAPI='custody-instruksi-stok/list?status=OPEN'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Instruksi Kustodi"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instruksi Kustodi'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormInstruksiKustodiAktif
                    ref={this.formPenempatanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default InstruksiKustodiAktif