import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { pengkinianType, statusPeserta } from 'dataSource/lookup';
import ShowModalPengkinianPesertaRegistrasiAktif from 'components/pages/modal/kepesertaan/pengkinianPeserta/aktif/showModalRegistrasiAktif';

class RiwayatPengkinianAktif extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterItem: {}
        }

        this.popupPesertaRegistrasiAktif = React.createRef();

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            console.log(e.row.data);
                            this.showModalPengkinianPesertaRegistrasiAktif(e.row.data.pesertaId, e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'ID'
            },
            {
                dataField: 'createdDate',
                caption: 'Tanggal Pengkinian',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'pengkinianTypeId',
                caption: 'Jenis Pengkinian',
                lookup: {
                    dataSource: pengkinianType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'typeName',
                    value: 'pengkinianTypeId'
                }
            },
            {
                dataField: 'nik',
                name: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                name: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pesertaStatusId',
                caption: 'Peserta Status',
                lookup: {
                    dataSource: statusPeserta(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName',
                }
            },
            {
                dataField: 'noSk',
                caption: 'No. SK'
            },
            {
                dataField: 'online',
                caption: 'Media'
            },
            {
                dataField: 'updateData',
                caption: 'Update Data',
                columns: [
                    {
                        dataField: 'updatedStatus',
                        caption: 'Status'
                    },
                    {
                        dataField: 'updatedDate',
                        caption: 'Tanggal',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'fullName',
                        name : 'updatedBy',
                        caption: 'User',
                    },
                ]
            },
            {
                dataField: 'updatedNote',
                caption: 'Keterangan',
            },
        ]
        this.filterItem = [
            {
                dataField: "tanggal1",
                label: {
                    text: "Dari",
                    alignment: "left",
                    location: "left"
                },
            },
            {
                dataField: "tanggal2",
                label: {
                    text: "Sampai",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengkinianPesertaRegistrasiAktif = (pesertaId, dataPengkinian) => {
        this.popupPesertaRegistrasiAktif.current.show()
        this.popupPesertaRegistrasiAktif.current.retrieveData(pesertaId, dataPengkinian);
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Riwayat</h2>
                <DevExpressDataGrid
                    loadAPI='view-pengkinian-peserta-bo-aktifs?updatedStatus.in=true'
                    insertAPI='pengkinian-peserta-bo-aktifs'
                    updateAPI='pengkinian-peserta-bo-aktifs'
                    deleteAPI='pengkinian-peserta-bo-aktifs'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {true}
                    
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Riwayat Pengkinian Aktifs"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Riwayat Pengkinian Aktif Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                  <ShowModalPengkinianPesertaRegistrasiAktif
                    ref={this.popupPesertaRegistrasiAktif}
                    actionType={'view'}
                    submitData={this.updateData}
                    forceRefresh={this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default RiwayatPengkinianAktif