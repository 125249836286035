import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import { provinces, cities, currency, productDefault, instrumentTransactionTypes, orderPlOpened, entitySP } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import httpRequest from 'plugin/httprequest';
import ArrayStore from 'devextreme/data/array_store';
import { formatDate, formatUploadFileData } from 'plugin/helper';
import notify from 'devextreme/ui/notify';
import { showLoading } from 'redux/actions/loading';


class ModalTransaksiPenyertaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: {},
            actionType: null
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.forms = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    text: 'No Konfirmasi'
                },
                editorOptions: {
                    readOnly: true
                },
            },
            {
                dataField: 'produkId',
                label: {
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    searchEnabled: true,
                },
            },
            {
                dataField: 'entitasId',
                label: {
                    text: 'Entitas'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: entitySP(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true,
                },
            },
            {
                dataField: 'transactionDate',
                label: {
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    onValueChanged: (e) => {
                        var date = e.value
                        var tanggalTransaksi = formatDate(date)
                        
                        var order = this.formRef.current.instance.getEditor('orderId')
    
                        order.option('dataSource',orderPlOpened(this.props.store, tanggalTransaksi))
                    }
                },
            },
            {
                dataField: 'settlementDate',
                label: {
                    text: 'Tanggal Settlement'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                },
            },
            {
                dataField: 'orderId',
                label: {
                    text: 'No Order'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'orderCode',
                    searchEnabled: true,
                    onSelectionChanged: async (e) => {
                        var data = e.selectedItem
                        if (data) {
                            var dataOrderPl = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-pls?orderId.in=${data.id}`, 'GET')
                            this.formRef.current.instance.updateData({
                                transactionTypeId: data.orderTypeId,
                                jmlLembarSaham: dataOrderPl[0].quantityOrder,
                                hargaPerLembar: dataOrderPl[0].priceOrder,
                                nominalTransaksi: dataOrderPl[0].netNominalOrder,
                            })
                        }
                    }
                },
            },
            {
                itemType: 'empty'
            },
            {
                dataField: 'transactionTypeId',
                label: {
                    text: 'Tipe Transaksi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName',
                    readOnly: true
                },
            },
            {
                dataField: 'jmlLembarSaham',
                label: {
                    text: 'Jumlah Lembar Saham'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    step: 0,
                },
            },
            {
                dataField: 'hargaPerLembar',
                label: {
                    text: 'Harga / Lembar'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    step: 0,
                },
            },
            {
                dataField: 'nominalTransaksi',
                label: {
                    text: 'Nominal Transaksi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.0000',
                    step: 0,
                    onValueChanged: (e) => {
                        var biaya = this.formRef.current.instance.getEditor('biaya').option('value')
                        this.formRef.current.instance.updateData('netNominalTransaksi', biaya + e.value)
                    }
                },
            },
            {
                dataField: 'biaya',
                label: {
                    text: 'Biaya'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                    onValueChanged: (e) => {
                        var nomTrs = this.formRef.current.instance.getEditor('nominalTransaksi').option('value')
                        this.formRef.current.instance.updateData('netNominalTransaksi', nomTrs + e.value)
                    }
                },
            },
            {
                dataField: 'netNominalTransaksi',
                label: {
                    text: 'Net Nominal Transaksi'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                },
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Mata Uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    searchEnabled: true,
                    onSelectionChanged: (e) => {
                        var data = e.selectedItem
                        this.formRef.current.instance.updateData('kurs', data.rate)
                    }
                },
            },
            {
                dataField: 'kurs',
                label: {
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                    step: 0,
                },
            },
            {
                itemType: 'empty'
            },
            {
                dataField: "file",
                label: {
                    text: "Dokumen",
                    alignment: 'left'
                },
                editorType: "dxFileUploader",
                editorOptions: {
                    selectButtonText: "Select File",
                    labelText: "Upload File",
                    uploadMode: "useForm",
                    accept: 'image/png, image/jpeg, application/pdf',
                    onValueChanged: this.onUploadStarted.bind(this),
                },
            },

        ]
    }

    show = async () => {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
        // var dataorder = await this.orderPlOpened(this.props.store)
        // this.formRef.current.instance.getEditor('orderId').option('dataSource', dataorder)
    }

    hide() {
        this.setState({
            popupVisible: false,
            Data: {},
            typeData: {}
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                this.setState({
                    [`${fieldName}Instruksi`]: formattedFileData.base64data,
                    [`${fieldName}ContentType`]: formattedFileData.mime,
                    [`fileName`]: files.name,
                })
            }

            fr.readAsDataURL(files)
        }
    }

    // orderPlOpened = async (store, transactionDate) => {
    //     let loadAPI = `orders?orderDate.equals=${transactionDate}&orderTypeId.in=291&orderTypeId.in=292&statusId.in=OPEN`
    //     var getOrder = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

    //     let dataSource = new ArrayStore({
    //         data: getOrder,
    //         key: 'id'
    //     });

    //     return dataSource
    // }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true))

        var dataMaster = this.state.dataMaster
        var dataSubmit = {
            ...dataMaster,
            fileInstruksi: this.state.fileInstruksi,
            fileContentType: this.state.fileContentType,
            fileName: this.state.fileName,
        }
        if (dataSubmit) {
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'order-pls/simpan-penyertaan-langsung', 'POST', {
                    values: dataSubmit
                })
                if (response) {
                    await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `order-pls/konfirmasi-penempatan/${response.id}`, 'POST', {})

                    this.props.store.dispatch(showLoading(false))
                    notify({ message: 'Sukses Menyimpan Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.hide()
                    this.props.forceRefresh()
                    return response
                }
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    retrieveData = async (type, data) => {
        this.setState({
            dataMaster: data,
            actionType: type
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Konfirmasi Penyertaan Modal'}
                width={'60vw'}
                height={'90vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <Form
                        colCount={1}
                        id={'formDataMaster'}
                        formData={this.state.dataMaster}
                        items={this.forms}
                        ref={this.formRef}
                        labelLocation={"left"}
                    />
                </div>
            </Popup>
        )
    }
}

export default ModalTransaksiPenyertaan
