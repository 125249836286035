import React, { Component } from 'react'
import { identity } from '../parts/staticDataColumnConfig'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify'

class Identity extends Component {
    constructor(props) {
        super(props)

        this.columns = identity(this.deleteData)

        this.dataGridRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    deleteData = (data) => {
        confirmAlert({
            title: 'Konfirmasi',
            message: 'Apakah anda yakin ingin menghapus data ini?',
            buttons: [
                {
                    label: 'Ya',
                    onClick: async() => {
                        await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `staticdata-wf/identity-types`, 'DELETE', data.id);

                        notify({ message: 'Berhasil Hapus Data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                        this.forceRefresh()
                    }
                },
                {
                    label: 'Tidak'
                }
            ]
        })
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Identitas</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='identity-types'
                    insertAPI='staticdata-wf/identity-types'
                    updateAPI='staticdata-wf/identity-types'
                    deleteAPI='staticdata-wf/identity-types' 

                    backendserver={process.env.REACT_APP_BACKEND}

                    allowAdding={true}
                    allowDeleting={false}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Identities"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Identity Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default Identity