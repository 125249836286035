
import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalPengajuanPenghapusanAset from 'components/pages/modal/sdm-umum/umum/penghapusan/pengajuanPenghapusan';
import { addURL } from 'redux/actions/url';
import FormModalPengajuanPemeliharaanAset from 'components/pages/modal/sdm-umum/umum/pemeliharaan-aset/pengajuan';
import FormModalSupplierPemeliharaanAset from 'components/pages/modal/sdm-umum/umum/pemeliharaan-aset/supplier';
import FormModalBiayaPemeliharaanAset from 'components/pages/modal/sdm-umum/umum/pemeliharaan-aset/biaya';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import { confirmAlert } from 'react-confirm-alert';
import { programs } from 'dataSource/lookup';

class PemeliharaanAset extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text: 'Supplier',
                    //     hint: 'Supplier',
                    //     cssClass: 'text-success',
                    //     onClick: (e) => {
                    //         this.showModalSupplierPemeliharaanAset(e)
                    //     }
                    // },
                    {
                        text: 'Biaya',
                        hint: 'Biaya',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalBiayaPemeliharaanAset(e)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            // this.showModalBiayaPemeliharaanAset(e)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin mengirim data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.kirim(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField:'programId',
                caption:'Program',
                lookup:{
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                }
            },
            {
                dataField:'noPemeliharaan',
                caption:'No Pemeliharaan'
            },
            {
                dataField:'registrasiNo',
                caption:'No Registrasi Aset'
            },
            {
                dataField:'tglPengajuan',
                caption:'Tanggal Pengajuan',
                dataType:'date',
                format:"dd MMM yyyy"

            },
            {
                dataField: 'aset',
                caption: 'Aset',
                alignment:'center',
                columns:[
                    {
                        dataField:'merk',
                        caption:'Merk'
                    },
                    {
                        dataField:'categoryName',
                        caption:'Kategori'
                    },
                    {
                        dataField:'categorySubName',
                        caption:'Sub Kategori'
                    },
                    {
                        dataField:'spesifikasi',
                        caption:'Spesifikasi Aset'
                    },
                ]
            },
            {
                dataField: 'pemeliharaan',
                caption: 'Pemeliharaan',
                alignment:'center',
                columns:[
                    {
                        dataField:'supplier',
                        caption:'Supplier'
                    },
                    {
                        dataField:'tanggal',
                        caption:'Tanggal'
                    },
                    {
                        dataField:'status',
                        caption:'Status'
                    },
                ]
            },
        ]

        this.showModalSupplierPemeliharaanAsetRef = React.createRef()
        this.showModalBiayaPemeliharaanAsetRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalSupplierPemeliharaanAset = (e) => {
        if (e.row) {
            this.showModalSupplierPemeliharaanAsetRef.current.retrieveData(e.row.data)
        }
        this.showModalSupplierPemeliharaanAsetRef.current.show()
    }

    showModalBiayaPemeliharaanAset = (e) => {
        if (e.row) {
            this.showModalBiayaPemeliharaanAsetRef.current.retrieveData(e.row.data)
        }
        this.showModalBiayaPemeliharaanAsetRef.current.show()
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    kirim = async (data) => {
        try {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pemeliharaans/kirimPemeliharaan/${data.id}`, 'POST', {
                values: {},
            })

            if (response) {
                this.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }


    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-pemeliharaan-assets', 'GET')
        response = response.filter(value => value.statusId == 2)

        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Pemeliharaan Aset</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='daftar-penagjuan-pengadaan'
                    insertAPI='daftar-penagjuan-pengadaan'
                    updateAPI='daftar-penagjuan-pengadaan'
                    deleteAPI='daftar-penagjuan-pengadaan'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pemeliharaan Aset"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pemeliharaan Aset'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalSupplierPemeliharaanAset
                    ref={this.showModalSupplierPemeliharaanAsetRef}
                    store={this.props.store}
                    from={'pemeliharaan'}
                />
                <FormModalBiayaPemeliharaanAset 
                    ref={this.showModalBiayaPemeliharaanAsetRef}
                    store={this.props.store}
                    from={'pemeliharaan'}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PemeliharaanAset