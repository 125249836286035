import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import Form from 'devextreme-react/form';
import ModalSettlementTransaksiSaham from 'components/pages/modal/investasi/settlement/konfirmasi/saham/index';
import ModalFileSettlementTransaksiSaham from 'components/pages/modal/investasi/settlement/konfirmasi/saham/file';
import { addURL } from 'redux/actions/url';
import { broker } from 'dataSource/lookup';
import ModalUploadKpdSaham from 'components/pages/modal/investasi/settlement/konfirmasi/saham/uploadKpd';
import { formatDate } from 'plugin/helper';
import { Button } from 'devextreme-react'
import httpRequest from 'plugin/httprequest';
import ViewJurnalDetails from 'components/pages/modal/investasi/viewJurnalTrans';
import ModalDetailTransaksiSaham from 'components/pages/modal/investasi/settlement/konfirmasi/saham/detailTransaksi';

class SettlementTransaksiSaham extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            dataMaster: {},
            filterItem: {
                startDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate: new Date(this.sysDate ? this.sysDate.dateValue : null)
            },
        }

        this.modalAlokasiRef = React.createRef()
        this.modalSettlementTransaksiSahamRef = React.createRef()
        this.modalFileSettlementTransaksiSahamRef = React.createRef()
        this.modalUploadKpdRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.showModalViewJurnalRef = React.createRef()
        this.modalDetailTransaksiRef = React.createRef()

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            startDate: formatDate(e.value)
                        })
                    }
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            endDate: formatDate(e.value)
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text : 'Lihat Jurnal',
                    //     hint : 'Lihat Jurnal',
                    //     cssClass: 'text-success',
                    //     onClick : (e) => {
                    //         this.showModalViewJurnal(e)
                    //     }
                    // },
                    {
                        text: 'View',
                        hint: 'View',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.modalDetailTransaksiRef.current.show(e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'konfirmasiId',
                caption: 'ID',
                alignment: 'right',
                width: '75px'
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'Nomor Konfirmasi',
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'trsDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Settle',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'totalTransaksi',
                caption: 'Jumlah Transaksi',
                alignment: 'right'
            },
            {
                dataField: 'totalInstrument',
                caption: 'Jumlah Saham',
                alignment: 'right'
            },
            {
                dataField: 'netAmount',
                caption: 'Netting Amount',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right'
            },

        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var d = new Date().getTime()
        var start = d - (30 * 24 * 3600 * 1000)
        var startDate = new Date(start)
        this.setState({
            startDate: formatDate(startDate)
        })
    }

    showModalViewJurnal = (e) => {
        if (e.row) {
            this.showModalViewJurnalRef.current.retrieveData(e.row.data)
            this.showModalViewJurnalRef.current.newData(true)
        } else {
            this.showModalViewJurnalRef.current.newData(false)
        }

        this.showModalViewJurnalRef.current.show()
    }
    onSelectionChanged = (e) => {
        var orderId = e.selectedRowKeys
        this.setState({
            orderId: orderId
        })
    }
    generateInstruksi = async () => {
        var id = {
            listConfirmationId: this.state.orderId
        }
        // return false
        if (this.state.orderId != undefined) {
            try {
                var insertAPI = 'order-stoks/buat-instruksi-custody'
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                    values: id
                })
                notify({ message: 'Generate Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.forceRefresh()
                return response
            } catch (e) {
                console.log(e)
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } else {
            notify({ message: 'Silahkan pilih data untuk di generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Upload KPD',
                    onClick: (e) => {
                        this.showModalUploadKPD()
                    },
                }
            },
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         text: 'Upload Broker',
            //         onClick: (e) => {
            //             console.log(e)
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Konfirmasi',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalSettlementTransaksiSaham('add')
                    },
                }
            },
        )
    }

    showModalSettlementTransaksiSaham = (type, data = null) => {
        this.modalSettlementTransaksiSahamRef.current.show()
        this.modalSettlementTransaksiSahamRef.current.retrieveData(type, data)
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    showModalFileSettlementTransaksiSaham = (type, data = null) => {
        this.modalFileSettlementTransaksiSahamRef.current.show()
        this.modalFileSettlementTransaksiSahamRef.current.retrieveData(type, data)
    }

    showModalUploadKPD = () => {
        this.modalUploadKpdRef.current.show()
        this.modalUploadKpdRef.current.getKpd();
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfirmasi Saham</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    keyField={'konfirmasiId'}
                    ref={this.dataGridRef}
                    loadAPI={`transaction-confirmations/stok/list?endDate=${formatDate(this.state.filterItem.endDate)}&startDate=${formatDate(this.state.filterItem.startDate)}&status=2&size=9999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Konfirmasi Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Konfirmasi Saham'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup
                    height={'calc(100vh - 311px)'}

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onSelectionChanged={this.onSelectionChanged}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Generate Instruksi"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.generateInstruksi.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
                <ModalSettlementTransaksiSaham
                    ref={this.modalSettlementTransaksiSahamRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ViewJurnalDetails
                    ref={this.showModalViewJurnalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />

                <ModalFileSettlementTransaksiSaham
                    ref={this.modalFileSettlementTransaksiSahamRef}
                    store={this.props.store}
                />
                <ModalUploadKpdSaham
                    ref={this.modalUploadKpdRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalDetailTransaksiSaham
                    ref={this.modalDetailTransaksiRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default SettlementTransaksiSaham