import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { kecamatan } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify';
import { formatDefaultFullDate, formatNpwp, formatNumberAum, formatNumberNav } from 'plugin/helper'
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";


class LaporanDaftarKaryawan extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIP',
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'nomorIdentitas',
                caption: 'Nomor Identitas',
            },
            {
                dataField: 'jenisKelamin',
                caption: 'Jenis Kelamin',
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan',
            },
            {
                dataField: 'unitName',
                caption: 'Unit Kerja',
            },
            {
                dataField: 'stsKar',
                caption: 'Status Karyawan',
            },
            {
                dataField: 'stsKpg',
                caption: 'Kategori Jabatan',
            },
            {
                dataField: 'stPajak',
                caption: 'Status Pajak',
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'usiaTahun',
                caption: 'Usia',
                alignment: 'center'
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir',
            },
            {
                dataField: 'educationName',
                caption: 'Pendidikan Terakhir',
            },
            {
                dataField: 'schoolName',
                caption: 'Sekolah / Universitas',
            },
            {
                dataField: 'motherName',
                caption: 'Nama Ibu Kandung',
            },
            {
                dataField: 'religionName',
                caption: 'Agama',
            },
            {
                dataField: 'bloodType',
                caption: 'Golongan Darah',
            },
            {
                dataField: 'npwp',
                caption: 'NPWP',
            },
            {
                dataField: 'noBpjsKesehatan',
                caption: 'No BPJS Kesehatan',
            },
            {
                dataField: 'noBpjsKetenagakerjaan',
                caption: 'No BPJS Ketenagakerjaan',
            },
            {
                dataField: 'kodeAbsen',
                caption: 'Kode/Absen',
            },
            {
                dataField: 'tanggalEfektifKerja',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'masaKerjaTahun',
                caption: 'Masa Kerja',
                alignment: 'center'
            },
            {
                dataField: 'nomorHp',
                caption: 'Nomor HP',
            },
            {
                dataField: 'email',
                caption: 'Email',
            },
            {
                dataField: 'noRekening',
                caption: 'No Rekening',
            },
            {
                dataField: 'bankRekening',
                caption: 'Bank Rekening',
            },
            {
                dataField: 'alamatIdentitas',
                caption: 'Alamat Identitas',
            },
            {
                dataField: 'alamatDomisili',
                caption: 'Alamat Domisili',
            },
        ]
        this.selectedRowsData = [];
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    exportExcel = async() => {
        let data = [];
        const firstTableRowNum = 8;
        // let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-report-karyawans?size=9999');
        let response = this.selectedRowsData;

        for (let value of response){
            let dataValue = {
                nik: value.nik ? value.nik : '-',
                fullName: value.fullName ? value.fullName : '-',
                nomorIdentitas: value.nomorIdentitas ? value.nomorIdentitas : '-',
                jenisKelamin: value.jenisKelamin ? value.jenisKelamin : '-',
                jabatanName: value.jabatanName ? value.jabatanName : '-',
                unitName: value.unitName ? value.unitName : '-',
                stsKar: value.stsKar ? value.stsKar : '-',
                stsKpg: value.stsKpg ? value.stsKpg : '-',
                stPajak: value.stPajak ? value.stPajak : '-',
                birthDate: value.birthDate ? formatDefaultFullDate(value.birthDate) : '-',
                usiaTahun: value.usiaTahun ? value.usiaTahun : '-',
                birthPlace: value.birthPlace ? value.birthPlace : '-',
                educationName: value.educationName ? value.educationName : '-',
                schoolName: value.schoolName ? value.schoolName : '-',
                motherName: value.motherName ? value.motherName : '-',
                religionName: value.religionName ? value.religionName : '-',
                bloodType: value.bloodType ? value.bloodType : '-',
                npwp: value.npwp ? formatNpwp(value.npwp) : '-',
                noBpjsKesehatan: value.noBpjsKesehatan ? value.noBpjsKesehatan : '-',
                noBpjsKetenagakerjaan: value.noBpjsKetenagakerjaan ? value.noBpjsKetenagakerjaan : '-',
                kodeAbsen: value.kodeAbsen ? value.kodeAbsen : '-',
                tanggalEfektifKerja: value.tanggalEfektifKerja ? formatDefaultFullDate(value.tanggalEfektifKerja) : '-',
                masaKerjaTahun: value.masaKerjaTahun ? value.masaKerjaTahun : '-',
                nomorHp: value.nomorHp ? value.nomorHp : '-',
                email: value.email ? value.email : '-',
                noRekening: value.noRekening ? value.noRekening : '-',
                namaRekening: value.namaRekening ? value.namaRekening : '-',
                alamatIdentitas: value.alamatIdentitas ? value.alamatIdentitas : '-',
                alamatDomisili: value.alamatDomisili ? value.alamatIdentitas : '-',
            }

            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }
        console.log(data);
        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR KARYAWAN');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'NIP'},
                {name: 'Nama'},
                {name: 'No Identitas'},
                {name: 'Jenis Kelamin'},
                {name: 'Jabatan'},
                {name: 'Unit Kerja'},
                {name: 'Status Karyawan'},
                {name: 'Kategori Jabatan'},
                {name: 'Status Pajak'},
                {name: 'Tanggal Lahir'},
                {name: 'Usia'},
                {name: 'Tempat Lahir'},
                {name: 'Pendidikan Terakhir'},
                {name: 'Sekolah / Universitas'},
                {name: 'Nama Ibu Kandung'},
                {name: 'Agama'},
                {name: 'Golongan Darah'},
                {name: 'NPWP'},
                {name: 'No BPJS Kesehatan'},
                {name: 'No BPJS Ketenagakerjaan'},
                {name: 'Kode/Absen'},
                {name: 'Tanggal Efektif'},
                {name: 'Masa Kerja'},
                {name: 'Nomor HP'},
                {name: 'Email'},
                {name: 'No Rekening'},
                {name: 'Nama Rekening'},
                {name: 'Alamat Identitas'},
                {name: 'Alamat Domisili'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            // for (let i = 9; i <= 10000; i++){
            //     worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            //     worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            //     worksheet.getCell('K'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            // }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'DAFTAR KARYAWAN';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}

            // var WidthColums = 20;
            // var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = 35;

            const Data2 = worksheet.getColumn(2);
            Data2.width = 35;

            const Data3 = worksheet.getColumn(3);
            Data3.width = 35;

            const Data4 = worksheet.getColumn(4);
            Data4.width = 35;

            const Data5 = worksheet.getColumn(5);
            Data5.width = 35;

            const Data6 = worksheet.getColumn(6);
            Data6.width = 35;

            const Data7 = worksheet.getColumn(7);
            Data7.width = 35;

            const Data8 = worksheet.getColumn(8);
            Data8.width = 35;

            const Data9 = worksheet.getColumn(9);
            Data9.width = 35;

            const Data10 = worksheet.getColumn(10);
            Data10.width = 35;

            const Data11 = worksheet.getColumn(11);
            Data11.width = 35;
            Data11.alignment = {vertical: 'middle', horizontal: 'center'};

            const Data12 = worksheet.getColumn(12);
            Data12.width = 35;

            const Data13 = worksheet.getColumn(13);
            Data13.width = 35;

            const Data14 = worksheet.getColumn(14);
            Data14.width = 35;

            const Data15 = worksheet.getColumn(15);
            Data15.width = 35;

            const Data16 = worksheet.getColumn(16);
            Data16.width = 35;

            const Data17 = worksheet.getColumn(17);
            Data17.width = 35;

            const Data18 = worksheet.getColumn(18);
            Data18.width = 35;

            const Data19 = worksheet.getColumn(19);
            Data19.width = 35;

            const Data20 = worksheet.getColumn(20);
            Data20.width = 35;

            const Data21 = worksheet.getColumn(21);
            Data21.width = 35;

            const Data22 = worksheet.getColumn(22);
            Data22.width = 35;

            const Data23 = worksheet.getColumn(23);
            Data23.width = 35;
            Data23.alignment = {vertical: 'middle', horizontal: 'center'};

            const Data24 = worksheet.getColumn(24);
            Data24.width = 35;

            const Data25 = worksheet.getColumn(25);
            Data25.width = 35;

            const Data26 = worksheet.getColumn(26);
            Data26.width = 35;

            const Data27 = worksheet.getColumn(27);
            Data27.width = 35;

            const Data28 = worksheet.getColumn(28);
            Data28.width = 35;
            
            const Data29 = worksheet.getColumn(29);
            Data29.width = 35;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR_KARYAWAN.xlsx`
                );
            });
        } catch (error) {
            console.log(error);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        if (this.selectedRowsData.length > 0) {
                            this.exportExcel()
                        } else {
                            notify({ message: 'Silahkan Pilih Data', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    },
                }
            }
        ) 
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Karyawan</h2>

                <DevExpressDataGrid
                    loadAPI='view-report-karyawans?size=9999'
                    insertAPI='view-report-karyawans'
                    updateAPI='view-report-karyawans'
                    deleteAPI='view-report-karyawans'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    
                    exportExcel={false}
                    exportFileName={"Rerport Data Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Karyawan Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    remoteOperations={false}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanDaftarKaryawan