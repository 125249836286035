import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import {Form, FileUploader,Popup } from 'devextreme-react'
import { jabatan, karyawan, months, programs, sdmUnit, unit } from 'dataSource/lookup'
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';

class FormPengajuanRapel extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            DataDocument:{},
            DataMaster:{
                dasarPerhitunganGajiTunjangan:0,
                dasarPerhitunganGapok:0,
                dasarPerhitunganNominal:0,
            }
        }

        this.dataGridRef = React.createRef()
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()

        this.PopupRef = React.createRef()

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];
 
        this.formPengajuan = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: "programId",
                        label: {
                            text: "Program",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField: "noPengajuan",
                        label: {
                            text: "No Pengajuan",
                        },
                        editorOptions: {
                            readOnly: true,
                        }
                    },
                    {
                        dataField: "keterangan",
                        label: {
                            text: "Keterangan"
                        }
                    },
                    {
                        dataField: "tanggalPengajuan",
                        label: {
                            text: "Tanggal Pengajuan"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "tunjanganType",
                        label: {
                            text: "Tunjangan",
                            alignment: 'left',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: 'tunjanganName',
                            searchEnabled: true,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount:2,
                        items: [
                            {
                                dataField: 'bulanDari',
                                label: {
                                    text: 'Periode Gaji Dari Bulan'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField: 'tahunDari',
                                label: {
                                    text: 'Tahun'
                                },
                                editorType: 'dxNumberBox',
                                
                            },
                            {
                                dataField: 'bulanSampai',
                                label: {
                                    text: 'Periode Gaji Sampai Bulan'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'value',
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField: 'tahunSampai',
                                label: {
                                    text: 'Tahun'
                                },
                                editorType: 'dxNumberBox',
                                
                            },
                        ]
                    },
                    
                    {
                        dataField: "tanggalPembayaran",
                        label: {
                            text: "Tanggal Pembayaran"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                itemType: 'group',
                                items: [

                                    {
                                        dataField: "dasarPerhitungan",
                                        label: {
                                            text: "Dasar Perhitungan"
                                        },
                                        editorType: 'dxRadioGroup',
                                        editorOptions: {
                                            layout: "vertical",
                                            items: [
                                                '% Gaji Pokok',
                                                '% Gaji + Tunjangan Tetap',
                                                'Nominal',
                                            ],
                                            onValueChanged:(e) => {
                                                if (e.value) {
                                                    if (e.value == '% Gaji Pokok') {
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganGapok').option({'readOnly':this.props.from == 'daftar' ? true : false})
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganGajiTunjangan').option({'readOnly':this.props.from == 'daftar' ? true : true,'value':0})
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganNominal').option({'readOnly':this.props.from == 'daftar' ? true : true,'value':0})
                                                    }else if (e.value == '% Gaji + Tunjangan Tetap') {
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganGapok').option({'readOnly':this.props.from == 'daftar' ? true : true,'value':0})
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganGajiTunjangan').option({'readOnly':this.props.from == 'daftar' ? true : false})
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganNominal').option({'readOnly':this.props.from == 'daftar' ? true : true,'value':0})
                                                    }else{
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganGapok').option({'readOnly':this.props.from == 'daftar' ? true : true,'value':0})
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganGajiTunjangan').option({'readOnly':this.props.from == 'daftar' ? true : true,'value':0})
                                                        this.formRef.current.instance.getEditor('dasarPerhitunganNominal').option({'readOnly':this.props.from == 'daftar' ? true : false})
                                                    }
                                                }
                                            }
                                        }
                                    },
                                ]
                            },
                            {
                                itemType: 'group',
                                items: [
                                    {
                                        dataField: 'dasarPerhitunganGapok',
                                        label: {
                                            visible: false,
                                        },
                                        editorType: 'dxNumberBox',
                                        editorOptions: {
                                            step: 0,
                                            format: '#,##0.00',
                                            readOnly:true
                                        }
                                    },
                                    {
                                        dataField: 'dasarPerhitunganGajiTunjangan',
                                        label: {
                                            visible: false,
                                        },
                                        editorType: 'dxNumberBox',
                                        editorOptions: {
                                            step: 0,
                                            format: '#,##0.00',
                                            readOnly:true
                                        }
                                    },
                                    {
                                        dataField: 'dasarPerhitunganNominal',
                                        label: {
                                            visible: false,
                                        },
                                        editorType: 'dxNumberBox',
                                        editorOptions: {
                                            step: 0,
                                            format: '#,##0.00',
                                            readOnly:true
                                        }
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        itemType: 'empty'
                    },
                    {
                        dataField: 'karyawanPenerima',
                        label: {
                            text: 'Karyawan Penerima'
                        },
                        editorType: 'dxRadioGroup',
                        editorOptions: {
                            layout: "vertical",
                            items: [
                                'Semua',
                                'Personal',
                            ],
                        }
                    }
                ]
            },
        ]
    }

    getListTunjanganType = async () => {
        var ListTunjanganType = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `tunjangan-types?size=999`, 'GET')
        // ListTunjanganType = ListTunjanganType.filter(value => value.tunjanganGroupId === 6)
        this.formRef.current.instance.getEditor('tunjanganType').option('dataSource', ListTunjanganType)
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataDocument:{},
            DataMaster:{
                dasarPerhitunganGajiTunjangan: 0,
                dasarPerhitunganGapok: 0,
                dasarPerhitunganNominal: 0,
            }
        })
    }

    submitData = async() => {
        try {
            var loadApi = 'karyawan-rapels/simpan-master-rapel'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.karyawanPenerima = DataMaster.karyawanPenerima == "Semua" ? 1 : 2
            DataMaster.dasarPerhitungan = DataMaster.dasarPerhitungan == "% Gaji Pokok" ? 1 : DataMaster.dasarPerhitungan == "% Gaji + Tunjangan Tetap" ? 2 : 3
            DataMaster.tanggalPengajuan = formatDate(new Date(DataMaster.tanggalPengajuan))
            DataMaster.tanggalPembayaran = formatDate(new Date(DataMaster.tanggalPembayaran))
            DataMaster.bulanDari = DataMaster.bulanDari
            DataMaster.bulanSampai = DataMaster.bulanSampai 
            DataMaster.tahunDari = DataMaster.tahunDari
            DataMaster.tahunSampai = DataMaster.tahunSampai

            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                values: DataMaster,
            })

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            
        }
    }

    updateData = async () => {
        try {
            var loadApi = 'karyawan-rapels'
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.id = DataMaster.id
            DataMaster.pengajuanNo = DataMaster.noPengajuan
            DataMaster.tunjanganTypeId = DataMaster.tunjanganType
            DataMaster.nilaiPersenGapok = DataMaster.dasarPerhitunganGapok
            DataMaster.nilaiPersenGapokTunjangan = DataMaster.dasarPerhitunganGajiTunjangan
            DataMaster.nilaiNominal = DataMaster.dasarPerhitunganNominal
            DataMaster.penerima = DataMaster.karyawanPenerima == "Semua" ? 1 : 2
            DataMaster.dasarPerhitunganId = DataMaster.dasarPerhitungan == "% Gaji Pokok" ? 1 : DataMaster.dasarPerhitungan == "% Gaji + Tunjangan Tetap" ? 2 : 3
            DataMaster.pengajuanTanggal = formatDate(new Date(DataMaster.tanggalPengajuan))
            DataMaster.tanggalPembayaran = formatDate(new Date(DataMaster.tanggalPembayaran))

            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'PUT', {
                values: DataMaster,
                key: DataMaster.id,
            },DataMaster)

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            
        }
    }

    retrieveData = async(data) => { 
        var DataMaster = {...this.state.DataMaster}
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-rapels/${data.id}`, 'GET')
        DataMaster.id = response.id
        DataMaster.programId = response.programId
        DataMaster.noPengajuan = response.pengajuanNo
        DataMaster.tanggalPengajuan = response.pengajuanTanggal
        DataMaster.tunjanganType = response.tunjanganTypeId
        DataMaster.bulanDari = response.bulanDari
        DataMaster.bulanSampai = response.bulanSampai
        DataMaster.tahunDari = response.tahunDari
        DataMaster.tahunSampai = response.tahunSampai
        DataMaster.dasarPerhitungan = response.dasarPerhitunganId === 1 ?  "% Gaji Pokok" : response.dasarPerhitunganId === 2  ? "% Gaji + Tunjangan Tetap" : "Nominal"
        DataMaster.dasarPerhitunganGapok = response.nilaiPersenGapok
        DataMaster.dasarPerhitunganGajiTunjangan = response.nilaiPersenGapokTunjangan
        DataMaster.dasarPerhitunganNominal = response.nilaiNominal
        DataMaster.karyawanPenerima = response.penerima == 1 ? "Semua" : "Personal"
        DataMaster.keterangan = response.keterangan
        DataMaster.tanggalPembayaran = response.tanggalPembayaran
        DataMaster.statusId = response.statusId
        DataMaster.statusTransaksiId = response.statusTransaksiId
        DataMaster.transactionTypeId = response.transactionTypeId
        DataMaster.transactionTypeSubId = response.transactionTypeSubId
        DataMaster.createdBy = response.createdBy
        DataMaster.lastModifiedBy = response.lastModifiedBy

        this.setState({
            DataMaster: DataMaster,
            DataDocument:{
                dokumenFile: response.dokumenFile,
                dokumenFileContentType: response.dokumenFileContentType,
                dokumenName: response.dokumenName,
            }
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData

            var DataDocument = { ...this.state.DataDocument }

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)

                DataDocument[`${fieldName}File`] = formattedFileData.base64data
                DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime
                DataDocument[`${fieldName}Name`] = files.name
                this.setState({
                    DataDocument: DataDocument
                })
            }

            fr.readAsDataURL(files)
        }
    }

    generateNoPengajuan = async() => {
        var generateNo = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `generate-no-automatic/KaryawanRapel/pengajuanNo/RAPEL`,
            'POST',{values:{}}
        )

        this.formRef.current.instance.updateData('noPengajuan', generateNo)
    }

    previewFile = async() => {
        var src = imageSource(this.state.DataDocument.dokumenFileContentType, this.state.DataDocument.dokumenFile)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
 
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Form Rapel"}
                width={'50vw'}
                height={'90vh'}
                toolbarItems={this.props.action == 'edit' ? this.PopupToolbarItem2 : this.PopupToolbarItem}
                >
                    <div className="container-fluid" style={{overflowY: 'auto',}}>
                    <Form
                    ref={this.formRef}
                        colCount={1}
                        id={'formPengajuanPemberhentian'}
                        formData={this.state.DataMaster}
                        items={this.formPengajuan}
                        labelLocation = 'left'
                        readOnly={this.props.from == 'daftar' ? true : false}
                    />
                    <h6 className="item-title">Dokumen</h6>
                        <FileUploader
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText="Upload Dokumen"
                            showFileList={false}
                            name={"dokumen"}
                            disabled={this.props.from == 'pengajuan' ? false : true}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-8">{this.state.DataDocument.dokumenName}</label>
                        </div>
                        <div className="col-md-6 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument, 'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                        <ModalPreviewPdf 
                            ref={this.modalPreviewPdfRef}
                            store={this.props.store}
                        />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormPengajuanRapel