import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Popup } from "devextreme-react/popup";
import { Form } from "devextreme-react";
import { anggaranItem, sdmUnit } from 'dataSource/lookup';

class DetailRevisiBudgetPerPeriode extends Component {
    constructor(props) {
        super(props);
        this.state = {
          popupVisible: false,
          formData: {},
        };
    
        this.form = [
            {
                dataField: 'anggaranCode',
                label:{
                    alignment:"left",
                    text:"Kode Anggaran"
                },
            },
            {
                dataField: 'anggaranName',
                label:{
                    alignment:"left",
                    text:"Nama Anggaran"
                },
                editorType: 'dxNumberBox',
            },
            {
                dataField: 'unitId',
                label:{
                    alignment:"left",
                    text:"Unit"
                },
                editorType:"dxSelectBox",
                editorOptions: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value',
                }
            }
        ]

        this.columns = [
            {
                dataField: 'periode',
                caption: 'Periode',
            },
            {
                dataField:'nominal',
                caption: 'Nominal Anggaran',
                alignment: 'left',
                columns: [
                    {
                        dataField: 'nominalRequest',
                        caption: 'Pengajuan',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'nominalRevisi',
                        caption: 'Revisi',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                ]
            },
            {
                dataField:'selisih',
                caption: 'Selisih',
                alignment: 'left',
                columns: [
                    {
                        dataField: 'nominalSelisih',
                        caption: 'Nominal',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'persenSelisih',
                        caption: '(%))',
                        dataType: 'number',
                        format: '#,##0.00 %',
                    },
                ]
            },
        ]
    
        this.PopupToolbarItem = [
          {
            widget: "dxButton",
            location: "after",
            options: {
              text: "Ok",
              onClick: async () => {
                this.submitData()
              }
            },
            toolbar: "bottom"
          },
          {
            widget: "dxButton",
            location: "after",
            options: {
              text: "Cancel",
              onClick: this.hide.bind(this)
            },
            toolbar: "bottom"
          }
        ];
    
        this.formRef = React.createRef()
        this.datagridRef = React.createRef()
    }

    retrieveData = async (data = null) => {
        const dataAnggaranItem = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-items/${data.anggaranItemId}`)
        this.formRef.current.instance.updateData({
            anggaranCode: dataAnggaranItem.anggaranCode,
            anggaranName: dataAnggaranItem.anggaranName,
            unitId: data.unitId,
        })

        this.datagridRef.current.forceRefresh(true)
    }

    show = () => {
        this.setState({
            popupVisible: true,
        });
    };

    hide = () => {
        this.setState({
          popupVisible: false,
          formData: {},
        });
      };

    loadData = async () => {
        if(!this.state.formData.id){
            return []
        }

        const response = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`anggaran-details/getByAnggaranId/${this.state.formData.id}`)
        return response
    }

    submitData = () => {

    }

    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                resizeEnabled = {true}
                showTitle={true}
                title="Detail Anggaran"
                width={"90vw"}
                height={"auto"}
                toolbarItems={this.PopupToolbarItem}
                > 
                    <div className="mb-3">
                        <Form
                            colCount={1}
                            id={"formCOA"}
                            formData={this.state.formData}
                            items={this.form}
                            ref={this.formRef}
                            labelLocation="left"
                            readOnly = {true}
                        />
                        
                        <DevExpressDataGrid
                            ref= {this.datagridRef}
                            loadAPI='anggarans'
                            insertAPI='anggarans'
                            updateAPI='anggarans'
                            deleteAPI='anggarans' 

                            backendserver={process.env.REACT_APP_BACKEND_ACC}

                            useArraySource = {true}
                            ArraySourceData = {this.loadData}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            exportExcel={true}
                            exportFileName={"Detail Revisi Anggaran Per Periode"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Detail Revisi Anggaran Per Periode Data'}
                            popupWidth={'70vw'} //masukan dalam ukuran pixel
                            popupHeight={'400px'} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default DetailRevisiBudgetPerPeriode