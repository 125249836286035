import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import { currency, productDefault } from 'dataSource/lookup';
import ModalTransaksiProperti from 'components/pages/modal/investasi/aset-non-portfolio/transaksi/properti/index';
import ModalFileTransaksiProperti from 'components/pages/modal/investasi/aset-non-portfolio/transaksi/properti/file';
import ModalPertimbanganTransaksiProperti from 'components/pages/modal/investasi/aset-non-portfolio/transaksi/properti/pertimbangan';
import { addURL } from 'redux/actions/url';
import ModalSettlementTransaksiProperti from 'components/pages/modal/investasi/settlement/konfirmasi/properti/index';
import { formatDate, getSystemDate } from 'plugin/helper';
import Form from 'devextreme-react/form';
import ModalDetailTransaksiProperti from 'components/pages/modal/investasi/settlement/konfirmasi/properti/detailTransaksi';
import httpRequest from 'plugin/httprequest';

class TransaksiProperti extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                startDate: this.sysDate ? new Date(this.sysDate.dateValue) : null,
                endDate: this.sysDate ? new Date(this.sysDate.dateValue) : null,
            }
        }

        this.dataGridRef = React.createRef()
        this.modalKategoriAsetAlokasiRef = React.createRef()
        this.modalTransaksiPropertiRef = React.createRef()
        this.modalDetailTransaksiRef = React.createRef()
        this.modalFileTransaksiPropertiRef = React.createRef()
        this.modalPertimbanganTransaksiPropertiRef = React.createRef()

        this.filterItem = [
            {
                dataField: "startDate",
                label: {
                    text: "Dari tanggal",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            startDate: e.value
                        })
                    }
                }
            },
            {
                dataField: "endDate",
                label: {
                    text: "s/d",
                    alignment: "left",
                    location: 'left'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    onValueChanged: (e) => {
                        this.setState({
                            endDate: e.value
                        })
                    }
                }
            },
        ]
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'View',
                        hint: 'View',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.modalDetailTransaksiRef.current.show(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'noKonfirmasi',
                caption: 'No',
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Properti'
            },
            {
                dataField: 'instrumentName',
                caption: 'Properti'
            },
            {
                dataField: 'trsDate',
                caption: 'Tanggal Transaksi',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Settlement',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'totalTransaksi',
                caption: 'Jumlah Transaksi',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'totalInstrument',
                caption: 'Jumlah Unit / Kav / Tipe'
            },
            {
                dataField: 'netAmount',
                caption: 'Nilai Pembelian'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Transaksi',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalTransaksiProperti('add')
                    },
                }
            },
        )
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    showModalTransaksiProperti = (type, data = null) => {
        this.modalTransaksiPropertiRef.current.show()
        this.modalTransaksiPropertiRef.current.retrieveData(type, data)
    }

    showModalFileTransaksiProperti = (data = null) => {
        this.modalFileTransaksiPropertiRef.current.show()
        this.modalFileTransaksiPropertiRef.current.retrieveData(data)
    }

    showModalPertimbanganTransaksiProperti = (data = null) => {
        this.modalPertimbanganTransaksiPropertiRef.current.show()
        this.modalPertimbanganTransaksiPropertiRef.current.retrieveData(data)
    }

    loadData = async () => {
        var datas = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `order-propertis/getListSettleProperti/{statusId}/{startDate}/{endDate}?endDate=${formatDate(this.state.filterData.endDate)}&startDate=${formatDate(this.state.filterData.startDate)}&status=2`,
            'GET'
        )

        return datas
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Konfirmasi Properti</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.filterData}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    keyField="konfirmasiId"
                    ref={this.dataGridRef}
                    loadAPI={`order-propertis/getListSettleProperti/2/${this.state.filterData.startDate ? formatDate(this.state.filterData.startDate) : null}/${this.state.filterData.endDate ? formatDate(this.state.filterData.endDate) : null}?size=9999`}
                    insertAPI='portfolios'
                    updateAPI='portfolios'
                    deleteAPI='portfolios'
                    // useArraySource={true}
                    // ArraySourceData={this.loadData}
                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Rebalance Portfolio"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Rebalance Portfolio'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 251px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalSettlementTransaksiProperti
                    ref={this.modalTransaksiPropertiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalFileTransaksiProperti
                    ref={this.modalFileTransaksiPropertiRef}
                    store={this.props.store}
                />
                <ModalPertimbanganTransaksiProperti
                    ref={this.modalPertimbanganTransaksiPropertiRef}
                    store={this.props.store}
                />
                <ModalDetailTransaksiProperti
                    ref={this.modalDetailTransaksiRef}
                    store={this.props.store}
                />

            </div>
        )
    }
}

export default TransaksiProperti