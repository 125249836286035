import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { managerInvestasis, custodies, currency, instrumentsCategoryEtf } from 'dataSource/lookup'
import ModalEtf from '../modal/static-data/investasi/etf/index'
import { confirmAlert } from 'react-confirm-alert'
import notify from 'devextreme/ui/notify'
import httpRequest from 'plugin/httprequest'

class ExchangeTradedFund extends Component {
    constructor(props) {
        super(props)

        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModal(e)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-success',
                        onClick: async(e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteAPI(e.row.data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ]
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode ETF'
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama ETF'
            },
            {
                dataField: 'instrumentCategorySubName',
                caption: 'Jenis ETF',
            },
            {
                dataField: 'miName',
                caption: 'Manager Investasi',
            },
            {
                dataField: 'custodyId',
                caption: 'Kustodi',
                lookup: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'custodyName'
                }
            },
            {
                dataField: 'currencyId',
                caption: 'Mata Uang',
                lookup: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyCode'
                }
            },
            {
                dataField: 'syariah',
                caption: 'Syariah'
            },
        ]
        this.modalRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModal = (e) => {
        if (e.row) {
            this.modalRef.current.retrieveData(e.row.data)
            this.modalRef.current.newData(true)
        } else {
            this.modalRef.current.newData(false)
        }
        this.modalRef.current.show()
    }

    deleteAPI = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/instruments`, 'DELETE', data.id);

            notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
            this.forceRefresh()
        } catch (error) {
            notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'ETF',
                    onClick: (e) => {
                        this.showModal(e)
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Exchange Traded Fund (ETF)</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='instruments/etf'
                    insertAPI='staticdata-wf/instruments'
                    updateAPI='staticdata-wf/instruments'
                    deleteAPI='staticdata-wf/instruments'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Instrumen ETF"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instrumen ETF Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={2} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalEtf
                    ref={this.modalRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default ExchangeTradedFund