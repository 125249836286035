import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { akunInvestasi, banks, months, product, productDefault, programs } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate, formatNumberAum } from 'plugin/helper'
import httpRequest from 'plugin/httprequest'
import ArrayStore from 'devextreme/data/array_store';
import { showLoading } from 'redux/actions/loading';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class LaporanDaftarTunjangan extends Component {
    constructor(props) {
        super(props)

        this.dgRef = React.createRef()
        this.formRef = React.createRef()

        this.state = {
            filterData: {
                karyawanId: 0
            }
        }

        this.columns = [
            {
                dataField: 'program',
                caption: 'Program',
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan',
            },
            {
                dataField: 'tunjanganName',
                caption: 'Tunjangan',
            },
            {
                dataField: 'groupName',
                caption: 'Group',
            },
            {
                dataField: 'dasarNilai',
                caption: 'Dasar Nilai',
            },
            {
                dataField: 'percentValue',
                caption: 'Persen',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'nominal',
                caption: 'Nominal',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00',
            },
            {
                dataField: 'akunName',
                caption: 'Akun'
            }
        ]

        this.filterItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'programId',
                        label: {
                            text: 'Program',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.program
                            },
                            onValueChanged: (e) => {
                                this.karyawanByProgram(e.value)
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        dataField: 'karyawanId',
                        label: {
                            text: 'Karyawan',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.fullName
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            },
        ]
    }
    karyawanByProgram = async (programId) => {
        let loadAPI = `karyawans?size=99999`
        var getKaryawan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'GET')

        var filteredData = getKaryawan.filter(val => val.programId == programId)
        let dataSource = new ArrayStore({
            data: filteredData,
            key: 'id'
        })
        this.formRef.current.instance.getEditor('karyawanId').option('dataSource', dataSource)
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        ) 
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `view-report-gaji-daftar-tunjangans?karyawanId.in=${this.state.filterData.karyawanId}&size=9999`);
        let store = this.props.store;
        store.dispatch(showLoading(true));

        for (let value of response){
            let dataValue = {
                program: value.program,
                nik: value.nik,
                fullName: value.fullName,
                jabatanName: value.jabatanName,
                tunjanganName: value.tunjanganName,
                groupName: value.groupName,
                dasarNilai: value.dasarNilai,
                percentValue: value.percentValue ? formatNumberAum(value.percentValue) : 0,
                nominal: value.nominal ? formatNumberAum(value.nominal) : 0,
                akunName: value.akunName
            };
            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }

        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR TUNJANGAN');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                theme: 'TableStyleLight15',
                showFirstColumn: true,
                // border: {
                //     top: {style:'thin'},
                //     left: {style:'thin'},
                //     bottom: {style:'thin'},
                //     right: {style:'thin'}
                // }
              },
              columns: [
                {name: 'Program'},
                {name: 'NIK'},
                {name: 'Nama Lengkap'},
                {name: 'Jabatan'},
                {name: 'Tunjangan'},
                {name: 'Group'},
                {name: 'Dasar Nilai'},
                {name: 'Persen'},
                {name: 'Nominal'},
                {name: 'Akun'},
              ],
              rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }
            
            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('I'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('E2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('E2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E2').font = {size: 18, bold: true}
            
            worksheet.getCell('E3').value = 'LAPORAN DAFTAR TUNJANGAN';
            worksheet.getCell('E3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('E3').font = {size: 18, bold: true}
            
            var WidthColums = 20;
            var WidthColums2 = 35;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums2;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = 45;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_DAFTAR_TUNJANGAN.xlsx`
                );
            });

        } catch (error) {
            console.log(error)
        }

        store.dispatch(showLoading(false));
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Laporan Daftar Tunjangan</h2>
                <div className="row">
                    <div className="col-md-7">
                        <Form
                            ref={this.formRef}
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI={`view-report-gaji-daftar-tunjangans?karyawanId.in=${this.state.filterData.karyawanId}&size=9999`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Laporan Daftar Tunjangan"}
                    allowExportSelectedData={true}
                    selection={true}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanDaftarTunjangan