import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { alert } from 'devextreme/ui/dialog';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { participants, pensiunType, product,pesertaAkunStatus,banks } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import { confirmAlert } from 'react-confirm-alert'
import ModalPengajuanDplk from 'components/pages/modal/kepesertaan/pesertaDapen/dplk/pengajuan/index';
import { formatNumber } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class PengajuanPengalihanDplk extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterData: {},
            dataMaster: {}
        }

        this.prevTabIndex = 0

        this.dataGridRef = React.createRef()
        this.popupNewPengajuanDplkRef = React.createRef()
        this.popupDetailPengajuanDplkRef = React.createRef()
        this.popupUploadPesertaRef = React.createRef()
        this.popupModalKeluargaRef = React.createRef()

        this.PengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPengajuanDplk('view', e.row.data)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.kirim(e.row.data)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'Id',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nikPeserta',
                name:'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                name:'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy',

            },
            {
                dataField: 'tglDialihkan',
                caption: 'Tanggal Berhenti',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'productId',
                caption: 'Akun Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode" // provides display values
                }
            },
            {
                dataField: 'skPemberhentianNo',
                caption: 'No SK PHK',
            },
            {
                name: 'dataAkun',
                caption: 'Data Akun',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'cardNo',
                        caption: 'No. Kartu',
                    },
                    {
                        dataField: 'iuranTotal',
                        caption: 'Total Iuran',
                        // format: '#,##0.00',
                        cellRender : (e) => {
                            var data = e.row.data
                            var total = data.iuranManfaatLainPeserta + data.iuranManfaatLainPk + data.iuranNormalPeserta + data.iuranNormalPk + data.iuranSukarelaPeserta

                            return formatNumber(total)
                        },
                        alignment: 'right',
                    },
                    {
                        dataField: 'totalUnit',
                        caption: 'Total Unit',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'nilaiPerUnit',
                        caption: 'Nilai Per Unit',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'pengembangan',
                        caption: 'Pengembangan',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'nominalTransfer',
                        caption: 'Nominal Transter',
                        format: '#,##0.00'
                    },
                    // {
                    //     name: 'akumulasiIuran',
                    //     caption: 'Akumulasi Iuran',
                    //     cellRender: (e) => {
                    //         var data = e.data
                    //         var akumulasiIuran = (data.iuranManfaatLainPeserta || 0) + (data.iuranManfaatLainPk || 0) + (data.iuranNormalPeserta || 0) + (data.iuranNormalPk || 0)

                    //         return formatNumberAum(akumulasiIuran)
                    //     }
                    // },
                ]
            },
            {
                name: 'dplkTujuan',
                caption: 'DPLK Tujuan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'dplkCode',
                        caption: 'Kode',
                    },
                    {
                        dataField: 'dplkName',
                        caption: 'Nama DPLK',
                    },
                ]
            },
            {
                name: 'bankTransferPengalihan',
                caption: 'Bank Transfer Pengalihan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'pengalihanRekeningName',
                        caption: 'Nama Bank',
                    },
                    {
                        dataField: 'pengalihanRekeningNo',
                        caption: 'No Rekening',
                    },
                    {
                        dataField: 'pengalihanRekeningBankId',
                        caption: 'Nama Rekening',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "bankName" // provides display values
                        }
                    },
                ]
            },
            {
                dataField: 'akunStatusId',
                caption: 'Status',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]


        this.filterItem = [
            {
                dataField: 'pemberiKerja',
                label: {
                    text: 'Pemberi Kerja',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: participants(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'participantName',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        this.dataGridRef.current.forceRefresh()
                    }
                }
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengajuanDplk = async(type, data = null) => {
        let periodeTahun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'pembayaran-mp/periodeTahun', 'GET')
        if (type == 'add') {
            this.popupNewPengajuanDplkRef.current.show(periodeTahun)
            this.popupNewPengajuanDplkRef.current.retrieveData()
        } else {
            this.popupDetailPengajuanDplkRef.current.show(periodeTahun)
            this.popupDetailPengajuanDplkRef.current.retrieveData(data, type)
        }
    }

    // retrieveData = async() => {
    //     var result = [
    //         {
    //             id:1
    //         }
    //     ]
    //     return result
    // }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Pengajuan',
                    icon: "plus",
                    onClick: (e) => {
                        this.showModalPengajuanDplk('add')
                    },
                }
            }
        );
    }

    kirim = async (data) => {
        try {
            var kirimData = {
                kepersertaanProductId : data.id,
                statusKeuanganId : 2
            }
            var loadAPI = 'kirim-dplk'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'POST', {
                values:kirimData
            })

            await alert('Berhasil mengirim pengajuan dplk baru', 'Berhasil!')
            this.forceRefresh()
        } catch (e) {
            await alert(e, 'Gagal!')
            this.forceRefresh()
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Pengajuan Pengalihan DPLK</h2>
                <Form
                    colCount={3}
                    id={'formPemberiKerja'}
                    formData={this.state.pemberiKerja}
                    items={this.filterItem}
                />
                <div className={' mt-2 text-center'} style={{ height: '100%' }}>
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        // loadAPI='view-simple-kepesertaan-products?akunStatusId.in=12'
                        loadAPI='view-simple-kepesertaan-products?akunStatusId.equals=12&size=9999'
                        insertAPI='kepersertaan-products'
                        updateAPI='kepersertaan-products'
                        deleteAPI='kepersertaan-products'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        remoteOperations={false}

                        // useArraySource={true}
                        // ArraySourceData={this.retrieveData}
                        
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pengajuan Pengalihan DPLK"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pengajuan Pengalihan DPLK'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.PengajuanColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}
                        height={'calc(100vh - 300px)'}

                        onToolbarPreparing={this.onToolbarPreparing}
                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>

                <ModalPengajuanDplk
                    ref={this.popupNewPengajuanDplkRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'add'}
                />
                <ModalPengajuanDplk
                    ref={this.popupDetailPengajuanDplkRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'view'}
                />
            </div>
        )
    }
}

export default PengajuanPengalihanDplk