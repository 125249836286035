import React, { Component } from 'react'
import { product } from 'dataSource/lookup'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormDeposito from 'components/pages/modal/investasi/order/deposito/penempatan'
import { addURL } from 'redux/actions/url'
import FormOrderUploadDeposito from 'components/pages/modal/investasi/order/deposito/upload'
import FormPencairanDeposito from 'components/pages/modal/investasi/order/deposito/pencairan'
import FormPerpanjanganDeposito from 'components/pages/modal/investasi/order/deposito/perpanjangan'
import FormPertimbanganDeposito from 'components/pages/modal/investasi/order/deposito/pertimbangan'
import { Button } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import notify from 'devextreme/ui/notify'
import { confirmAlert } from 'react-confirm-alert'

class OrderIpoSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
        }
        this.DataGridDepositoRef = React.createRef()
        this.currentData = []
        this.orderColumns = [
            {
                dataField: 'productCode',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'productCode',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'order',
                caption: 'Order',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'orderCode',
                        caption: 'Kode Order',
                    },
                    {
                        dataField: 'transactionName',
                        caption: 'Tipe Order',
                    },
                    {
                        dataField: 'brokerCode',
                        caption: 'Broker',
                    },
                    {
                        dataField: 'orderDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'emiten',
                caption: 'Emiten',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'emitenName',
                        caption: 'Emiten',
                    },
                    {
                        dataField: 'amountOrder',
                        caption: 'Nominal Order',
                        dataType: 'number',
                        format: '#,##0.00',
                        alignment: 'right'
                    },
                ]
            },
            {
                dataField: 'orderStatus',
                caption: 'Status'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModalPertimbangan = () => {
        this.popupFormPertimbangan.current.show();
    }
    showModalFormUploadDeposito = () => {
        this.popupFormUploadDepositoRef.current.show();
    }
    forceRefresh = () => {
        this.DataGridDepositoRef.current.forceRefresh(true)
    }
    onSelectionChanged = (e) => {
        var orderId = e.selectedRowKeys
        this.setState({
            orderId: orderId
        })
    }
    generateInstruksi = () => {
        var id = {
            listOrderId: this.state.orderId
        }
        confirmAlert({
            title: 'Generate',
            message: 'Apakah anda yakin ingin generate data ini?',
            buttons: [
                {
                    label: 'Ya',
                    onClick: async (e) => {
                        if (id.listOrderId != undefined) {
                            try {
                                var insertAPI = 'order-ipos/buat-instruksi-ipo'
                                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, insertAPI, 'POST', {
                                    values: id
                                })
                                notify({ message: 'Generate Instruksi berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                                this.forceRefresh()
                                return response
                            } catch (e) {
                                console.log(e)
                                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            }
                        } else {
                            notify({ message: 'Silahkan pilih data untuk di generate', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    }
                },
                {
                    label: 'Tidak'
                }
            ]
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Order IPO Saham</h2>
                <DevExpressDataGrid
                    ref={this.DataGridDepositoRef}
                    keyField="orderId"
                    loadAPI='order-ipos/list?size=9999&status=OPEN'
                    insertAPI='order-depositos'
                    updateAPI='order-depositos'
                    deleteAPI='order-depositos'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Deposito"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Deposito'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.orderColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{ class: "bg-dapen" }}
                        text={"Generate Instruksi"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.generateInstruksi.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div>
            </div>
        )
    }
}

export default OrderIpoSaham