import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { akunHR, payrollKategoriSub } from 'dataSource/lookup';

class PengaturanTipePinjaman extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                dataField:"loanCode",
                caption:"Kode"
            },
            {
                dataField:"loanName",
                caption:"Pinjaman"
            },
            {
                dataField:"loanAkunId",
                caption:"Akun Pinjaman",
                lookup:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"cicilanAkunId",
                caption:"Akun Cicilan",
                lookup:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'akunName', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField:"payrollCategorySubId",
                caption:"Kategori Payroll",
                lookup:{
                    dataSource: payrollKategoriSub(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: 'categorySubName',
                    searchEnabled: true,
                    deferRendering: false
                } 
            },
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Tipe Pinjaman</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='loan-types'
                    insertAPI='loan-types'
                    updateAPI='loan-types'
                    deleteAPI='loan-types' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}

                    exportExcel={true}
                    exportFileName={"Tipe Pinjaman"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Pinjaman'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default PengaturanTipePinjaman