import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { participants } from 'dataSource/lookup'
import { FileUploader } from 'devextreme-react';
import { base64toFile, cleanQuote, formatUploadFileData, generateTextFile } from "plugin/helper";
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import { showLoading } from 'redux/actions/loading';

class IntegrasiServerDirektori extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploadedData:[],
            DataDocument: {},
        }

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Download',
                        hint: 'Download',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.downloadFile(e)
                        }
                    },
                    {
                        text: 'Simpan Ke DB',
                        hint: 'Simpan Ke DB',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.saveToDb(e)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'fileName',
                caption: 'File Name',
            },
            {
                dataField: 'participantId',
                caption: 'Pemberi Kerja',
                lookup: {
                    dataSource: participants(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "participantName" // provides display values
                }
            },
        ]
        this.dataGridRef = React.createRef()
        this.fileUploderRef = React.createRef()
        this.currentData = []
        this.dataUpload = null
        this.fileName = ''
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh()
    }

    onUploadStarted = async (e) => {
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];

            var formData = new FormData();

            formData.append("file", files);
            this.props.store.dispatch(showLoading(true))
            try {
                var loadApi = `h-2-h-files/upload-File`
                var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'POST', {
                    values: formData
                })

                if (response) {
                    notify(
                        {
                        message: 'Sukses Upload Data',
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        'success',
                        600
                    );
                    this.dataGridRef.current.forceRefresh(true)
                }
                this.props.store.dispatch(showLoading(false))
            } catch (e) {
                console.log(e)
                notify(
                    {
                    message: e,
                    width: "AUTO",
                    shading: true,
                    position: { at: "center", my: "center", of: window }
                    },
                    'error',
                    600
                );
                this.props.store.dispatch(showLoading(false))
            }
        }
    }

    saveToDb = async (e) => {
        try {
            var loadApi = `h-2-h-files/save-to-db/${e.row.data.id}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'POST',{
                values:e.row.data.id
            })
    
            if (response) {
                notify(
                    {
                    message: 'Sukses Upload Data',
                    width: "AUTO",
                    shading: true,
                    position: { at: "center", my: "center", of: window }
                    },
                    'success',
                    600
                );
                this.dataGridRef.current.forceRefresh(true)
            }
        } catch (e) {
            console.log(e)
            
            notify(
                {
                message: 'Terjadi Kesalahan System!',
                width: "AUTO",
                shading: true,
                position: { at: "center", my: "center", of: window }
                },
                'error',
                600
            );
            this.props.store.dispatch(showLoading(false))
        }
    }

    downloadFile = async (e) => {
        try {
            var loadApi = `file-cores/download-file/${e.row.data.fileCoreId}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'GET')
            var csv = generateTextFile(response,e.row.data.fileName ,'text/csv','.csv')
            return csv
        } catch (e) {
            notify(
                {
                message: 'Terjadi Kesalahan System!',
                width: "AUTO",
                shading: true,
                position: { at: "center", my: "center", of: window }
                },
                'error',
                600
            );
        }
        
    }

    loadData = async() => {
        var loadApi = `h-2-h-files/get-by-statusProses/${1}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApi, 'GET')

        return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Direktori Server</h2>
                <FileUploader
                    accept={".csv"}
                    uploadMode={"useForm"}
                    onValueChanged={this.onUploadStarted.bind(this)}
                    labelText="Upload File Here"
                    showFileList={false}
                    name={"fileIntegrasi"}
                    value={this.state.uploadedData}
                    ref={this.fileUploderRef}
                />
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='peserta-pensiuns/getIntegrasiServerDirektori/2'
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas'

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Direktori Server"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Direktori Server'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter
                    onToolbarPreparing = {this.onToolbarPreparing}
                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default IntegrasiServerDirektori
