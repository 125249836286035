import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { educationBackground, tipeSertifikasi } from 'dataSource/lookup';
import ModalKaryawanPendidikan from './profesi/modalKaryawanPendidikan';
import ModalKaryawanSertifikasi from './profesi/modalKaryawanSertifikasi';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import ScrollView from 'devextreme-react/scroll-view';
import { formatDate } from 'plugin/helper';


class ProfesiDaftarKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.prevTabIndex = 0
        this.PopupRef = React.createRef()
        this.modalKaryawanPendidikanRef = React.createRef()
        this.modalKaryawanSertifikasiRef = React.createRef()
        this.DataGridKaryawanPenRef = React.createRef()
        this.DataGridKaryawanSerRef = React.createRef()
        this.karyawanId = ''

        this.TabDataSource = [
            {
                id: 0,
                text: 'Riwayat Pendidikan'
            },
            {
                id: 1,
                text: 'Sertifikasi'
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];

        this.columnPendidikan = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.removeDataPen(e.row.data)
                        }
                    },
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalKaryawanPendidikan(e, false)
                        }
                    },
                ]
            },
            {
                dataField: 'educationId',
                caption: 'Pendidikan',
                lookup:{
                    dataSource: educationBackground(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'educationName' 
                }
            },
            {
                dataField: 'institusi',
                caption: 'Institusi/Lembaga'
            },
            {
                dataField: 'tahun',
                caption: 'Tahun Lulus'
            },
            {
                dataField: 'indexPrestasi',
                caption: 'Index Prestasi'
            },
        ]

        this.columnSertifikasi = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.removeDataSer(e.row.data)
                        }
                    },
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalKaryawanSertifikasi(e, false)
                        }
                    },
                ]
            },
            {
                dataField: 'noSertifikasi',
                caption: 'No. Sertifikasi'
            },
            {
                dataField: 'sertifikasiId',
                caption: 'Tipe Sertifikasi',
                lookup:{
                    dataSource: tipeSertifikasi(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'sertifikasiName', // provides display values
                }
            },
            {
                dataField: 'institusi',
                caption: 'Institusi/Lembaga'
            },
            {
                dataField: 'tanggalSertifikasi',
                caption: 'Tanggal Sertifikat',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'tanggalKadaluarsa',
                caption: 'Tanggal Kadaluarsa',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'desc',
                caption: 'Keterangan',
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.DataGridKaryawanPenRef.current.emptyDeletedIndex()
        this.DataGridKaryawanSerRef.current.emptyDeletedIndex()
        this.setState({
            popupVisible: false,
        })
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindexProfesi-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexProfesi-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onToolbarPreparingPen = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    // text: 'Karyawan',
                    onClick: (e) => {
                        this.showModalKaryawanPendidikan(e, false)
                    },
                }
            }
        )
    }

    onToolbarPreparingSer = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    // text: 'Karyawan',
                    onClick: (e) => {
                        this.showModalKaryawanSertifikasi(e, false)
                    },
                }
            }
        )
    }

    loadDataPen = async () => {
        if (this.karyawanId == '') {
           var response = [ ]
        }else{
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-pendidikan/getAllByKaryawanId/${this.karyawanId}`, 'GET')
        
        }
        return response
    }
    
    loadDataSer = async () => {
        if (this.karyawanId == '') {
            var response = [ ]
         }else{
             var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-sertifikasi/getAllByKaryawanId/${this.karyawanId}`, 'GET')
         }
        return response
    }

    showModalKaryawanPendidikan = (e) => {
        if (e.row) {
            this.modalKaryawanPendidikanRef.current.retrieveData(e.row.data)
        }
        this.modalKaryawanPendidikanRef.current.show()
    }

    showModalKaryawanSertifikasi = (e) => {
        if (e.row) {
            this.modalKaryawanSertifikasiRef.current.retrieveData(e.row.data)
        }
        this.modalKaryawanSertifikasiRef.current.show()
    }

    submitData = async() => {
        try {
            var dataPen = this.DataGridKaryawanPenRef.current.getDataSource()
            var dataSer = this.DataGridKaryawanSerRef.current.getDataSource()
            
            for(var karyawanPen of dataPen){
                if (typeof karyawanPen.id === "string") {
                    delete karyawanPen.id
                     this.addDataKaryawanPen(karyawanPen,this.karyawanId)
                }else{
                     this.editDataKaryawanPen(karyawanPen)
                }
            }
            var getDeletedPen = this.DataGridKaryawanPenRef.current.getDeletedIndex()
            for(var dataDeletePen of getDeletedPen){
                await this.deleteDataKaryawanPen(dataDeletePen)
            }

            for(var karyawanSer of dataSer){
                if (typeof karyawanSer.id === "string") {
                    delete karyawanSer.id
                     this.addDataKaryawanSer(karyawanSer,this.karyawanId)
                }else{
                     this.editDataKaryawanSer(karyawanSer)
                }
            }
            
            var getDeletedSer = this.DataGridKaryawanSerRef.current.getDeletedIndex()
            for(var dataDeleteSer of getDeletedSer){
                    this.deleteDataKaryawanSer(dataDeleteSer)
            }

            notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            this.hide()

        } catch (e) {
            console.log(e)
        }
    }

    getDataKaryawanId = (data) => {    
        this.karyawanId = data

        this.DataGridKaryawanPenRef.current.forceRefresh(true)
        this.DataGridKaryawanSerRef.current.forceRefresh(true)
    }

    getDataSourcePend = (data) => {
        try{
            var key = data.id

            if(key){
                this.DataGridKaryawanPenRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Karyawan Pendidikan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.DataGridKaryawanPenRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Karyawan Pendidikan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    getDataSourceSerti = (data) => {
        try{
            var key = data.id

            if(key){
                this.DataGridKaryawanSerRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Karyawan Sertifikasi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.DataGridKaryawanSerRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Karyawan Sertifikasi", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    addDataKaryawanPen = async (data, karyawanId) => {
        var loadAPI = `karyawan-pendidikans`
        var dataPen = {
            karyawanId:karyawanId,
            educationId:data.educationId,
            institusi:data.institusi,
            tahun:data.tahun,
            indexPrestasi:data.indexPrestasi,
        }
        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: dataPen,
        })
    }

    editDataKaryawanPen = async (data) => {
        var loadAPI = `karyawan-pendidikans`

        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
            values: data,
            key:data.id
        },data)
    }

    deleteDataKaryawanPen = async (data) => {
        var loadAPI = `karyawan-pendidikans`

        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE', data)
    }

    addDataKaryawanSer = async (data, karyawanId) => {
        var loadAPI = `karyawan-sertifikasis`
        var dataSer = {
            karyawanId:karyawanId,
            noSertifikasi:data.noSertifikasi,
            sertifikasiId:data.sertifikasiId,
            institusi:data.institusi,
            desc:data.desc,
            tanggalSertifikasi:data.tanggalSertifikasi ? formatDate(data.tanggalSertifikasi) : null,
            tanggalKadaluarsa:data.tanggalKadaluarsa ? formatDate(data.tanggalKadaluarsa) : null,
        }
        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: dataSer,
        })
    }

    editDataKaryawanSer = async (data) => {
        var loadAPI = `karyawan-sertifikasis`

        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
            values: data,
            key:data.id
        },data)
    }

    deleteDataKaryawanSer = async (data) => {
        var loadAPI = `karyawan-sertifikasis`

        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE', data)
    }

    removeDataPen = (data) => {
        this.DataGridKaryawanPenRef.current.push([
            {
                type: 'remove',
                key : data.id
            }
        ])
        notify({ message: "Sukses Delete Riwayat Pendidikan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    }

    removeDataSer = (data) => {
        this.DataGridKaryawanSerRef.current.push([
            {
                type: 'remove',
                key : data.id
            }
        ])
        notify({ message: "Sukses Delete Sertifikasi Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    }

    
    render() {
        return (
            <Popup
            className={'popup'}
            visible={this.state.popupVisible}
            onHiding={this.hide}
            dragEnabled={true}
            resizeEnabled={true}
            showTitle={true}
            title={'Profesi Karyawan'}
            minWidth={500}
            minHeight={700}

            ref={this.PopupRef}

            toolbarItems = {this.PopupToolbarItem}
        >
        <ScrollView width='100%' height='100%'>
            <div className="container-fluid" style={{height: '100%'}}>
                <div className="row">
                    <div className="col-md-12">
                        <DevextremeTab
                            dataSource={this.TabDataSource}
                            onOptionChanged={this.onTabSelectionChanged}
                        />
                    </div>
                    <div id="tabindexProfesi-0" className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                        <h5 className="font-weight-bold my-3">Riwayat Pendidikan</h5>
                            <DevExpressDataGrid
                            ref={this.DataGridKaryawanPenRef}
                                loadAPI='karyawan-pendidikans'
                                insertAPI='karyawan-pendidikans'
                                updateAPI='karyawan-pendidikans'
                                deleteAPI='karyawan-pendidikans' 

                                backendserver={process.env.REACT_APP_BACKEND_HRD}
                                useArraySource={true}
                                ArraySourceData={this.loadDataPen}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Pendidikan Per Karyawan"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Jurnal Manual Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={300} //masukkan dalam ukuran pixel

                                height={500}

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnPendidikan} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}
                                onToolbarPreparing = {this.onToolbarPreparingPen}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ModalKaryawanPendidikan 
                                ref={this.modalKaryawanPendidikanRef}
                                store={this.props.store}
                                getDataSourcePend={this.getDataSourcePend}
                            />
                    </div>
                    <div id="tabindexProfesi-1" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <h5 className="font-weight-bold my-3">Sertifikasi</h5>
                                <DevExpressDataGrid
                                ref={this.DataGridKaryawanSerRef}
                                    loadAPI='karyawan-sertifikasis'
                                    insertAPI='karyawan-sertifikasis'
                                    updateAPI='karyawan-sertifikasis'
                                    deleteAPI='karyawan-sertifikasis' 

                                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                                    useArraySource={true}
                                    ArraySourceData={this.loadDataSer}
                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={true}
                                    exportFileName={"Sertifikasi Per karyawan"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Jurnal Manual Data'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    height={500}

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnSertifikasi} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing={this.onToolbarPreparingSer}
                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                                <ModalKaryawanSertifikasi 
                                ref={this.modalKaryawanSertifikasiRef}
                                store={this.props.store}
                                getDataSourceSerti={this.getDataSourceSerti}
                            />
                    </div>
                </div>
            </div>
            </ScrollView>
        </Popup>
        )
    }
}

export default ProfesiDaftarKaryawan