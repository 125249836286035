import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { banks } from 'dataSource/lookup';
import FormModalInstruDeposito from '../modal/static-data/investasi/deposito/index';
import httpRequest from 'plugin/httprequest';
import { confirm } from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify';

class InstruDepo extends Component {
    constructor(props) {
        super(props)

        this.modalRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            this.showModal(e)
                        }
                    },
                    // {
                    //     text: 'Hapus',
                    //     onClick: async (e) => {
                    //         let data = e.row.data
                    //         let result = await confirm("Apakah anda yakin ingin menghapus data ini?", "Konfirmasi");

                    //         if (result) {
                    //             this.deleteAPI(data)
                    //         }
                    //     }
                    // },
                ]
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument',
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Instrument',
            },
            {
                dataField: 'bilyetNo',
                caption: 'No Bilyet',
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'tenor',
                caption: 'Tenor',
                cellRender: (e) => {
                    return <span>{e.value} {e.row.data.tenorType}</span>
                }
            },
            {
                dataField: 'rate',
                caption: 'Rate',
                format: "#,## %",
                alignment: 'right'
            },
        ]
    }

    deleteAPI = async(data) => {
        var id = data.id
        if(id) {
            try {
                var deleteAPI = 'staticdata-wf/instruments'
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, deleteAPI, 'DELETE', id);
    
                notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success', 1000);
                this.forceRefresh()
            } catch (error) {
                notify({ message: error, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        }
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal(e)
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true);
    }

    showModal = async(e) => {
        if (e.row) {
            var data = e.row.data
            var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.id}`)
            var customGetData = {
                id: data.id,
                instrumentCode: data.instrumentCode,
                instrumentName: data.instrumentName,
                bilyetNo: instrument.bilyetNo,
                bankId: instrument.bankId,
                tenor: instrument.tenor,
                tenorTypeId: instrument.tenorTypeId,
                rate: instrument.rate,
                currencyId: instrument.currencyId,
                rateTypeId: instrument.rateTypeId,
                categorySubId: instrument.categorySubId
            }
            this.modalRef.current.retrieveData(customGetData)
            this.modalRef.current.newData(true)
        }else{
            this.modalRef.current.newData(false)
        }
        this.modalRef.current.show()
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Deposito</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='instruments/deposito'
                    insertAPI='staticdata-wf/instruments'
                    updateAPI='staticdata-wf/instruments'
                    deleteAPI='staticdata-wf/instruments'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Instrumen Saham"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Instrumen Saham Data'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={2} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter
                    
                    height={'calc(100vh - 220px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalInstruDeposito
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default InstruDepo
