import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import httpRequest from 'plugin/httprequest'
import notify from "devextreme/ui/notify";
import { productDefault, custodies, banks, rekeningBank, kpds, pejabat, months } from 'dataSource/lookup'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import ModalBiayaManagement from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/biaya-management/index'
import FormKonfirmasiSubmitManagementFee from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/biaya-management/formKonfirmasiSubmit'
import { Form } from 'devextreme-react';
import { formatDate } from 'plugin/helper'
import { confirmAlert } from 'react-confirm-alert'
import ModalReverse from 'components/pages/modal/investasi/settlement/transaksi-lain-lain/biaya-custodian/reverse';

class BiayaManagementTransaksi extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            FilterItem:{
                startDate:new Date(this.sysDate ? this.sysDate.dateValue : null),
                endDate:new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }
        this.prevTabIndex = 0
        this.modalInsertRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.modalViewRef = React.createRef()
        this.modalReverseRef = React.createRef()
        this.dataGridRiwayatRef = React.createRef()
        this.dataGridBiayaRef = React.createRef()
        this.showModalKonfirmasiSubmitSinvestRef = React.createRef()

        this.FilterItem = [
            {
              dataField: "startDate",
              label: {
                text: "Tanggal Terima",
                alignment: "left",
                location: "left"
              },
              editorType: "dxDateBox",
              editorOptions: {
                useMaskBehavior: true,
                width: '30%',
                displayFormat: 'dd MMM yyyy'
              }
            },
            {
              dataField: "endDate",
              label: {
                text: "s/d",
                alignment: "left",
                location: "left"
              },
              editorType: "dxDateBox",
              editorOptions: {
                useMaskBehavior: true,
                width: '30%',
                displayFormat: 'dd MMM yyyy'
              }
            },
            {
              itemType: "button",
              buttonOptions: {
                text: "Filter",
                // type:"default",
                elementAttr: { class: "bg-dapen-default" },
                onClick: () => {
                  this.filterSubmit()
                },
              },
              horizontalAlignment: "left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    // {
                    //     text: 'Instruksi',
                    //     hint: 'Instruksi',
                    //     onClick: (e) => {
                    //         // this.showModalEditKonfirmasi(e)
                    //     }
                    // },
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            if (e.row.data) {
                                if (e.row.data.status == 'OPEN') {
                                    this.showModalEditBiaya(e)
                                }else{
                                    notify({ message: `Status ${e.row.data.status} Tidak Bisa di Ubah`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                }
                            }
                            
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            // this.batalPenempatan(e.row.data)
                        }
                    },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        onClick: (e) => {
                            if (e.row.data) {
                                if (e.row.data.status != 'AP') {
                                    confirmAlert({
                                        title: 'Konfirmasi Proses',
                                        message: 'Apakah akan di lakukan sekarang?',
                                        buttons: [
                                          {
                                            label: 'Ya',
                                            onClick: () => {
                                              this.prosesBiaya(e)
                                            }
                                          },
                                          {
                                            label: 'Tidak'
                                          }
                                        ]
                                      })
                                }else{
                                    notify({ message: `Status ${e.row.data.status} Tidak Bisa di Proses`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                                }
                            }
                        }
                    },
                    // {
                    //     text: 'Konfirmasi Debet',
                    //     hint: 'Konfirmasi Debet',
                    //     onClick: (e) => {
                    //         if (e.row.data.status == 'AP') {
                    //             this.showModalKonfirmasiSubmitSinvest(e)
                    //         }else{
                    //             notify({ message: `Status ${e.row.data.status} Tidak Bisa di Konfimasi`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    //         }
                    //     }
                    // },
                ]
            },
            {
                dataField: 'noInstruksi',
                caption: 'No. Instruksi'
            },
            {
                dataField: 'namaProduk',
                caption: 'Produk',
            },
            {
                dataField: 'kpd',
                caption: 'KPD',
            },
            {
                dataField: 'noRekDebet',
                caption: 'No Rekening Debet',
            },
            {
                dataField: 'tagihan',
                caption: 'Tagihan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'noTagihan',
                        caption: 'Nomor Tagihan'
                    },
                    {
                        dataField: 'tglTagihan',
                        caption: 'Tanggal Tagihan',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'totalAum',
                        caption: 'Total AUM',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'totalMf',
                        caption: 'Total MF',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'ppn',
                        caption: 'PPN',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'pph23',
                        caption: 'PPH 23',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'totalTagihan',
                        caption: 'Total Tagihan',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'periode',
                caption: 'Periode',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'periodeTahun',
                        caption: 'Tahun'
                    },
                    {
                        dataField: 'periodeBulan',
                        caption: 'Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                        }
                    },
                ]
            },
            {
                dataField: 'tglPembayaran',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            // {
            //     dataField: 'pejabat',
            //     caption: 'Pejabat',
            //     alignment: 'center',
            //     columns: [
            //         {
            //             dataField: 'pejabat1',
            //             caption: 'Pejabat 1',
            //             lookup: {
            //                 dataSource: pejabat(this.props.store),
            //                 valueExpr: 'value',
            //                 displayExpr: 'value',
            //             }
            //         },
            //         {
            //             dataField: 'pejabat2',
            //             caption: 'Pejabat 2',
            //             lookup: {
            //                 dataSource: pejabat(this.props.store),
            //                 valueExpr: 'value',
            //                 displayExpr: 'value',
            //             }
            //         },
            //     ]
            // },
            {
                dataField: 'status',
                caption: 'Status'
            },
        ]

        this.columnsRiwayat = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalViewBiaya(e)
                        }
                    },
                    {
                        text: 'Reverse',
                        hint: 'Reverse',
                        onClick: (e) => {
                            this.showModalReverse(e)
                        }
                    },
                ]
            },
            {
                dataField: 'noInstruksi',
                caption: 'No. Instruksi'
            },
            {
                dataField: 'namaProduk',
                caption: 'Produk',
            },
            {
                dataField: 'kpd',
                caption: 'KPD',
            },
            {
                dataField: 'noRekDebet',
                caption: 'No Rekening Debet',
            },
            {
                dataField: 'tagihan',
                caption: 'Tagihan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'noTagihan',
                        caption: 'Nomor Tagihan'
                    },
                    {
                        dataField: 'tglTagihan',
                        caption: 'Tanggal Tagihan',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'totalAum',
                        caption: 'Total AUM',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'totalMf',
                        caption: 'Total MF',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'ppn',
                        caption: 'PPN',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'pph23',
                        caption: 'PPH 23',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'totalTagihan',
                        caption: 'Total Tagihan',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'periode',
                caption: 'Periode',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'periodeTahun',
                        caption: 'Tahun'
                    },
                    {
                        dataField: 'periodeBulan',
                        caption: 'Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value',
                        }
                    },
                ]
            },
            {
                dataField: 'tglPembayaran',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            // {
            //     dataField: 'pejabat',
            //     caption: 'Pejabat',
            //     alignment: 'center',
            //     columns: [
            //         {
            //             dataField: 'pejabat1',
            //             caption: 'Pejabat 1',
            //             lookup: {
            //                 dataSource: pejabat(this.props.store),
            //                 valueExpr: 'value',
            //                 displayExpr: 'value',
            //             }
            //         },
            //         {
            //             dataField: 'pejabat2',
            //             caption: 'Pejabat 2',
            //             lookup: {
            //                 dataSource: pejabat(this.props.store),
            //                 valueExpr: 'value',
            //                 displayExpr: 'value',
            //             }
            //         },
            //     ]
            // },
            {
                dataField: 'status',
                caption: 'Status'
            },
        ]

        this.TabDataSource = [
            {
                id: 1,
                text: 'TAGIHAN'
            },
            {
                id: 2,
                text: 'RIWAYAT'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    forceRefresh = () => {
        this.dataGridBiayaRef.current.forceRefresh(true)
    }

    filterSubmit = () => {
        this.setState({
            FilterItem:this.state.FilterItem
        })

        this.dataGridRiwayatRef.current.forceRefresh(true)
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'TAGIHAN',
                    onClick: (e) => {
                        this.modalInsertRef.current.show()
                        this.modalInsertRef.current.generateNoKonf(true)
                    },
                },
            }
        )
    }

    prosesBiaya = async (e) => {
        try {
            if (e.row.data.status == 'OPEN') {
                var loadApi = `bank-transactions/tagihan-biaya-management-fee-konfirmasi/${e.row.data.id}`
                var response =   await httpRequest(
                    process.env.REACT_APP_BACKEND_INVESTASI,
                    this.props.store,
                    loadApi,
                    "PUT",{
                        key:e.row.data.id,
                        values: {}
                    },
                );

                if (response) {
                    this.forceRefresh()
                    notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
                }
            }else{
                notify({ message: 'Data Gagal diproses', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
            }
            
        } catch (e) {
            console.log(e)
            notify({ message: 'Terjadi Kesalahan System', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        
        }
    }

    showModalKonfirmasiSubmitSinvest = (e) => {
        if (e.row) {
            this.showModalKonfirmasiSubmitSinvestRef.current.retrieveData(e.row.data)
        }
        this.showModalKonfirmasiSubmitSinvestRef.current.show()
    }

    showModalEditBiaya = (e) => {
        if (e.row) {
            this.modalEditRef.current.retrieveData(e.row.data)
        }
        this.modalEditRef.current.show()
    }

    showModalViewBiaya = (e) => {
        if (e.row) {
            this.modalViewRef.current.retrieveData(e.row.data)
        }
        this.modalViewRef.current.show()
    }

    showModalReverse = (e) => {
        this.modalReverseRef.current.show(e.row.data)
    }
    render() {
        return (
            <div className="container-fluid">
                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div id="tabindex-0">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="main-title">Daftar Tagihan Biaya Management Fee</h2>
                            <DevExpressDataGrid
                            ref={this.dataGridBiayaRef}
                                loadAPI='bank-transactions/tagihan-biaya-management-fee-list'
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Daftars"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Tagihan Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}
                                onToolbarPreparing={this.onToolbarPreparing}

                                // remoteOperations = {true}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ModalBiayaManagement
                                forceRefresh={this.forceRefresh}
                                ref={this.modalInsertRef}
                                store={this.props.store}
                                action={"add"}
                            />
                            <ModalBiayaManagement
                                forceRefresh={this.forceRefresh}
                                ref={this.modalEditRef}
                                store={this.props.store}
                                action={"edit"}
                            />
                            <FormKonfirmasiSubmitManagementFee
                                forceRefresh={this.forceRefresh}
                                ref={this.showModalKonfirmasiSubmitSinvestRef}
                                store={this.props.store}
                            />
                        </div>
                    </div>
                </div>
                <div id="tabindex-1" className="d-none">
                    <div className="row">
                        <div className="col-md-12">
                        <h2 className="main-title">Riwayat Tagihan Biaya Management Fee</h2>
                            <Form
                                colCount={1}
                                id={'dataMaster'}
                                formData={this.state.FilterItem}
                                items={this.FilterItem}
                                labelLocation={"left"} 
                            />
                            <DevExpressDataGrid
                                ref={this.dataGridRiwayatRef}
                                loadAPI={`bank-transactions/tagihan-biaya-management-fee-riwayat/${formatDate(this.state.FilterItem.startDate)}/${formatDate(this.state.FilterItem.endDate)}`}
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Riwayats"}
                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Riwayat Data'}
                                popupWidth={500} //masukan dalam ukuran pixel
                                popupHeight={200} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={1} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columnsRiwayat} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                // remoteOperations = {true}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                            <ModalBiayaManagement
                                forceRefresh={this.forceRefresh}
                                ref={this.modalViewRef}
                                store={this.props.store}
                                action={"view"}
                            />
                            <ModalReverse
                                ref={this.modalReverseRef}
                                store={this.props.store}
                                forceRefresh={this.forceRefresh}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BiayaManagementTransaksi