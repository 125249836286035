import React, { Component } from 'react'
import { Popup, ScrollView, Form } from 'devextreme-react'
import httpRequest from 'plugin/httprequest'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import { banks, currency, grade, jabatan, karyawan, sdmUnit, unit } from 'dataSource/lookup'
import { reportFile } from 'plugin/reportRequest'
import { formatDefaultMonthYear, formatNumberAum } from 'plugin/helper'

class ViewPenggajianKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {}
        }

        this.rapelColumns = [
            {
                dataField: "tunjangan",
                caption: "Tunjangan",
            },
            {
                dataField: "nominalTunjangan",
                caption: "Nominal Tunjangan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]

        this.lemburColumns = [
            {
                dataField: "tanggal",
                caption: "Tanggal",
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: "uangLembur",
                caption: "Nominal Lembur",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]

        this.cicilanPinjamanColumns = [
            {
                dataField: "pinjaman",
                caption: "Pinjaman",
            },
            {
                dataField: "nominalCicilan",
                caption: "Nominal Cicilan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]

        this.IuranDapenColumns = [
            {
                dataField: "iuran",
                caption: "Iuran",
            },
            {
                dataField: "nominalIuran",
                caption: "Nominal Iuran",
                dataType: 'number',
                format: '#,##0.00'
            },
        ]

        this.potonganColumns = [
            {
                dataField: "potongan",
                caption: "Potongan",
            },
            {
                dataField: "nominalPotongan",
                caption: "Nominal Potongan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]

        this.tunjanganColumns = [
            {
                dataField: "tunjangan",
                caption: "Tunjangan",
            },
            {
                dataField: "nominalTunjangan",
                caption: "Nominal Tunjangan",
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
        ]

        this.DataMaster = [
            {
                itemType: "group",
                colCount: 1,
                items: [
                    {
                        dataField: "karyawanId",
                        label: {
                            text: "Nama Karyawan",
                            alignment: "left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: karyawan(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'fullName',
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true,
                            onSelectionChanged: async (e) => {
                                console.log(e);
                                if (e.selectedItem) {
                                    var data = {
                                        nik: e.selectedItem.nik,
                                        jabatanId: e.selectedItem.jabatanId,
                                        gradeId: e.selectedItem.gradeId,
                                        unitId: e.selectedItem.unitId,
                                        gajiRekNo: e.selectedItem.gajiRekNo,
                                        gajiRekName: e.selectedItem.gajiRekName,
                                        gajiRekBankId: e.selectedItem.gajiRekBankId,
                                        gajiRekBranch: e.selectedItem.gajiRekBranch,
                                        gajiCurrencyId: e.selectedItem.gajiCurrencyId
                                    }

                                    this.formRef.current.instance.updateData(data)
                                }
                            }
                        }
                    },
                    {
                        dataField: "nik",
                        label: {
                            text: "NIK",
                            alignment: "left"
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'jabatanId',
                        label: {
                            text: 'Jabatan'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: jabatan(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'jabatanName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            placeholder: '',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'gradeId',
                        label: {
                            text: 'Grade'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: grade(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'gradeCode', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                            placeholder: '',
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'unitId',
                        label: {
                            alignment: 'left',
                            text: 'Unit'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: sdmUnit(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'unitName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                            readOnly: true
                        },
                    },
                    {
                        dataField: "gapok",
                        label: {
                            text: "Gaji Pokok",
                            alignment: "left"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: "gapokSebelumPajak",
                        label: {
                            text: "Gaji Sebelum Pajak",
                            alignment: "left"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00'
                        }
                    },
                    {
                        dataField: "rapel",
                        label: {
                            text: "Rapel",
                            alignment: "left"
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "potPph21",
                        label: {
                            text: "Potongan PPH 21",
                            alignment: "left"
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "gajiDiterimaBersih",
                        label: {
                            text: "Gaji Diterima Bersih",
                            alignment: "left"
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            readOnly: true,
                            format: '#,##0.00'
                        }
                    },

                ]
            },
            {
                itemType: 'group',
                colCount: 1,
                items: [
                    {
                        dataField: "gajiRekNo",
                        label: {
                            text: "No. Rekening",
                            alignment: "left"
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "gajiRekName",
                        label: {
                            text: "Nama Rekening",
                            alignment: "left"
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "gajiRekBankId",
                        label: {
                            text: "Bank",
                            alignment: "left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            readOnly: true,
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            searchEnabled: true,
                            deferRendering: false,
                            displayExpr: function (item) {
                                return item && item.bankCode + " - " + item.bankName;
                            },
                        }
                    },
                    {
                        dataField: "gajiRekBranch",
                        label: {
                            text: "Cabang",
                            alignment: "left"
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: "gajiCurrencyId",
                        label: {
                            text: "Mata Uang",
                            alignment: "left"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'currencyName',
                            readOnly: true,
                        }
                    },
                ]
            }


        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                    text: "Tutup",
                    onClick: this.hide.bind(this)
                },
                toolbar: "bottom"
            }
        ];

        this.TabDataSource = [
            {
                id: 0,
                text: 'Tunjangan'
            },
            {
                id: 1,
                text: 'Potongan'
            },
            {
                id: 2,
                text: 'Cicilan Pinjaman'
            },
            {
                id: 3,
                text: 'Lembur'
            },
        ]


        this.prevTabIndex = 0
        this.formRef = React.createRef()
        this.dataGridCicilanRef = React.createRef()
        this.dataGridLemburRef = React.createRef()
        this.dataGridPotonganRef = React.createRef()
        this.dataGridTunjanganRef = React.createRef()
        this.karyawanId = ''
        this.listDataTunjangan = []
        this.listDataPotongan = []
        this.listDataLembur = []
        this.listCicilanPinjaman = []
        this.intensifKinerjaRapel = 0;
        this.tpph = 0;
        this.dplkAskomTunjanganBisnis = 0;
        this.pph = 0;
        this.bpjsPerTgk = 0;
        this.bpjsPerKes = 0;
        this.bpjsPerKrywnTgk = 0;
        this.koperasiKresna = 0;
        this.bpjsKesKrywn = 0;
        this.potonganUpah = 0;
        this.potonganIntensif = 0;
        this.bpjsKesPer = 0;
        this.ykpp = 0;
        this.potSp = 0;
        this.bpjsPerTgkPotongan = 0;
        this.penghasilanDasar = 0;
        this.gajiDiTransfer = 0;
        this.periodeBulan = "";
        this.statusPajak = "";
        this.statusKaryawan = "";
    }

    onTabSelectionChanged = (args) => {

        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    retrieveData = async (data, dataMaster, payrollId) => {
        var dataSlipGaji = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umum/slip-gaji/getByKaryawanAndKaryawanPayrollId/${data.k_id}/${payrollId}`, 'GET')
        dataSlipGaji.gajiDiterimaBersih = data.gajiDiTransfer;
        dataSlipGaji.gapok = data.penghasilan_dasar
        var dataLembur = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-lembur-details/findAllByKaryawanId/${data.k_id}`, 'GET')

        this.listDataTunjangan.push(
            {
                tunjangan: 'Jamsostek',
                nominalTunjangan: dataSlipGaji.tunjJamsostek
            },
            {
                tunjangan: 'Kepegawaian',
                nominalTunjangan: dataSlipGaji.tunjKepegawaian
            },
            {
                tunjangan: 'Lain-Lain',
                nominalTunjangan: dataSlipGaji.tunjLainLain
            },
            {
                tunjangan: 'Medical',
                nominalTunjangan: dataSlipGaji.tunjMedical
            },
        )

        this.listDataPotongan.push(
            {
                potongan: 'JHT-JP',
                nominalPotongan: dataSlipGaji.potJhtJp
            },
            {
                potongan: 'jamsostek',
                nominalPotongan: dataSlipGaji.potJamsostek
            },
            {
                potongan: 'Cicilan Pinjaman',
                nominalPotongan: dataSlipGaji.potCicilan
            },
            {
                potongan: 'Lain-Lain',
                nominalPotongan: dataSlipGaji.potLainLain
            },
        )

        this.listCicilanPinjaman.push(
            {
                pinjaman: dataSlipGaji.pinjamanName,
                nominalCicilan: dataSlipGaji.cicilanAmount
            }
        )

        this.listDataLembur = dataLembur

        this.dataGridTunjanganRef.current.forceRefresh(true)
        this.dataGridPotonganRef.current.forceRefresh(true)
        this.dataGridLemburRef.current.forceRefresh(true)
        this.dataGridCicilanRef.current.forceRefresh(true)
        this.setState({
            DataMaster: dataSlipGaji
        })
        this.statusKaryawan = data.sts_karyawan;
        this.intensifKinerjaRapel = data.t15;
        this.tpph = data.t16;
        this.dplkAskomTunjanganBisnis = data.p26 * data.t18;
        this.pph = data.p51;
        this.bpjsPerTgk = data.t20;
        this.bpjsPerTgkPotongan = data.t20;
        this.bpjsPerKes = data.t21;
        this.bpjsPerKrywnTgk = data.p17;
        this.koperasiKresna = data.p18;
        this.bpjsKesKrywn = data.p19;
        this.potonganUpah = data.p20;
        this.potonganIntensif = data.p21;
        this.bpjsKesPer = data.p11;
        this.ykpp = data.t11;
        this.potSp = data.p25;
        this.dplkAskom = data.p26;
        this.periodeBulan = dataMaster.pembayaranDate;
        this.statusPajak = data.sts_pajak;
        this.gajiDiTransfer = data.gajiDiTransfer;
    }

    submitData = () => {

    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {

        this.listDataTunjangan = []
        this.listDataPotongan = []
        this.listDataLembur = []
        this.setState({
            popupVisible: false
        })
    }

    loadDataTunjangan = () => {
        return this.listDataTunjangan
    }

    loadDataPotongan = () => {
        return this.listDataPotongan
    }

    loadDataLembur = () => {
        return this.listDataLembur
    }

    loadDataCicilan = () => {
        return this.listCicilanPinjaman
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
          {
              location: 'after',
              widget: 'dxButton',
              options: {
                  icon: 'print',
                  onClick: (e) => {
                    this.print()
                  },
              }
          }
        )
    }

    print = async () => {
       let response = this.state.DataMaster;
       let slipGajiDetailTunjangan = [];
       let slipGajiDetailPotongan = [];
       let arrGapok = [];
       let arrStsPajak = [];
       let arrNik = [];
       let arrIntensifKinerjaRapel = [];
       let arrGajiRekName = [];
       let arrJabatanId = [];
       let arrStatusKaryawan = [];
       let arrPeriodeBulan = [];
    
       if (response){
        // let getJabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, `jabatans/${response.jabatanId}`);
        let getJabatan = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'jabatans?size=9999')
        let statusKaryawan = this.statusKaryawan;
        let statusPajak = this.statusPajak;
        let intensifKinerjaRapel = this.intensifKinerjaRapel;

       let dataResultTunjangan = {
            intensifKinerjaRapel: this.intensifKinerjaRapel ? formatNumberAum(this.intensifKinerjaRapel) : 0,
            tpph: this.tpph ? formatNumberAum(this.tpph) : 0,
            dplkAskomTunjanganBisnis: this.dplkAskomTunjanganBisnis ? formatNumberAum(this.dplkAskomTunjanganBisnis) : 0,
            pph: this.pph ? formatNumberAum(this.pph) : 0,
            bpjsPerTgk: this.bpjsPerTgk ? formatNumberAum(this.bpjsPerTgk) : 0,
            bpjsPerKes: this.bpjsPerKes ? formatNumberAum(this.bpjsPerKes) : 0
       }

       slipGajiDetailTunjangan.push(dataResultTunjangan);

       let dataResultPotongan = {
            bpjsPerTgkPotongan: this.bpjsPerTgkPotongan ? formatNumberAum(this.bpjsPerTgkPotongan) : 0,
            bpjsPerKrywnTgk: this.bpjsPerKrywnTgk ? formatNumberAum(this.bpjsPerKrywnTgk) : 0,
            koperasiKresna: this.koperasiKresna ? formatNumberAum(this.koperasiKresna) : 0,
            bpjsKesKrywn: this.bpjsKesKrywn ? formatNumberAum(this.bpjsKesKrywn) : 0,
            potonganUpah: this.potonganUpah ? formatNumberAum(this.potonganUpah) : 0,
            potonganIntensif: this.potonganIntensif ? formatNumberAum(this.potonganIntensif) : 0,
            bpjsKesPer: this.bpjsKesPer ? formatNumberAum(this.bpjsKesPer) : 0,
            ykpp: this.ykpp ? formatNumberAum(this.ykpp) : 0,
            potSp: this.potSp ? formatNumberAum(this.potSp) : 0,
            dplkAskom: this.dplkAskom ? formatNumberAum(this.dplkAskom) : 0
       }

       slipGajiDetailPotongan.push(dataResultPotongan);

        arrGapok.push(response.gapok ? formatNumberAum(response.gapok) : 0);
        arrNik.push(response.nik);
        arrGajiRekName.push(response.gajiRekName);
        arrJabatanId.push(this.state.DataMaster.jabatanId ? getJabatan.find(val => val.id === this.state.DataMaster.jabatanId).jabatanName : '-');
        arrStatusKaryawan.push(statusKaryawan);
        arrStsPajak.push(statusPajak);
        arrIntensifKinerjaRapel.push(formatNumberAum(intensifKinerjaRapel) ? formatNumberAum(intensifKinerjaRapel) : 0);
        // arrPeriodeBulan.push(this.periodeBulan);

        var gajiKotor = response.gapok + this.intensifKinerjaRapel + this.tpph + this.dplkAskomTunjanganBisnis + this.pph + this.bpjsPerTgk + this.bpjsPerKes;
        var totalPotongan = this.bpjsPerTgk + this.bpjsPerKrywnTgk + this.koperasiKresna + this.bpjsKesKrywn + this.potonganUpah + this.potonganIntensif + this.bpjsKesPer + this.ykpp + this.potSp + this.dplkAskom;
        var gajiNetto = this.gajiDiTransfer;   
    }

       reportFile({
        template: {'shortid': 'Hkl5DRauVo'},
        data: {
            'gapok': arrGapok,
            'intensifKinerjaRapel': arrIntensifKinerjaRapel,
            'detailTunjangan': slipGajiDetailTunjangan,
            'gajiKotor': formatNumberAum(gajiKotor),
            'detailPotongan': slipGajiDetailPotongan,
            'totalPotongan': formatNumberAum(totalPotongan),
            'gajiNetto': formatNumberAum(gajiNetto),
            'nik': arrNik,
            'name': arrGajiRekName,
            'jabatan': arrJabatanId,
            'statusKaryawan': arrStatusKaryawan,
            'statusPajak': arrStsPajak,
            'periodeBulan': formatDefaultMonthYear(this.periodeBulan)
        },
        options: {
            reportName: 'Slip Gaji'
        }
       }, true, `Slip Gaji`)
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Slip Gaji'}
                width={'50vw'}
                heigth={'30vh'}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <ScrollView width='100%' height='100%'>
                    <div className='container-fluid'>
                        <Form
                            ref={this.formRef}
                            colCount={2}
                            id={'formFilter'}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation={"left"}
                            scrollingEnabled={true}
                        />

                    </div>
                    <DevextremeTab
                        dataSource={this.TabDataSource}
                        onOptionChanged={this.onTabSelectionChanged}
                    />
                    <div id="tabindex-0" className={' mt-2 text-center'} style={{ height: '100%' }}>
                        <DevExpressDataGrid
                            ref={this.dataGridTunjanganRef}
                            loadAPI='currencies'
                            insertAPI='currencies'
                            updateAPI='currencies'
                            deleteAPI='currencies'

                            ArraySourceData={this.loadDataTunjangan}
                            useArraySource={true}

                            backendserver={process.env.REACT_APP_BACKEND}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            height={'auto'}
                            keyField={'tunjangan'}
                            showBorders={true}

                            paging={false}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Monitoring Tugas Data'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='top' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.tunjanganColumns} // taruh konfigurasi kolom disini
                            onToolbarPreparing={this.onToolbarPreparing}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                    <div id="tabindex-1" className={'d-none mt-2 text-center'} style={{ height: '100%' }}>
                        <DevExpressDataGrid
                            ref={this.dataGridPotonganRef}
                            loadAPI='currencies'
                            insertAPI='currencies'
                            updateAPI='currencies'
                            deleteAPI='currencies'

                            ArraySourceData={this.loadDataPotongan}
                            useArraySource={true}

                            backendserver={process.env.REACT_APP_BACKEND}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            height={'auto'}

                            showBorders={true}
                            keyField={'potongan'}
                            paging={false}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Monitoring Tugas Data'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='top' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.potonganColumns} // taruh konfigurasi kolom disini
                            onToolbarPreparing={this.onToolbarPreparing}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                    <div id="tabindex-2" className={'d-none mt-2 text-center'} style={{ height: '100%' }}>
                        <DevExpressDataGrid
                            ref={this.dataGridCicilanRef}
                            loadAPI='currencies'
                            insertAPI='currencies'
                            updateAPI='currencies'
                            deleteAPI='currencies'

                            ArraySourceData={this.loadDataCicilan}
                            useArraySource={true}

                            backendserver={process.env.REACT_APP_BACKEND}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            height={'auto'}

                            showBorders={true}
                            keyField={'pinjaman'}
                            paging={false}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Monitoring Tugas Data'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='top' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.cicilanPinjamanColumns} // taruh konfigurasi kolom disini

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                    <div id="tabindex-3" className={'d-none mt-2 text-center'} style={{ height: '100%' }}>
                        <DevExpressDataGrid
                            ref={this.dataGridLemburRef}
                            loadAPI='currencies'
                            insertAPI='currencies'
                            updateAPI='currencies'
                            deleteAPI='currencies'

                            ArraySourceData={this.loadDataLembur}
                            useArraySource={true}

                            backendserver={process.env.REACT_APP_BACKEND}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            height={'auto'}
                            keyField={'tanggal'}
                            showBorders={true}

                            paging={false}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Monitoring Tugas Data'}
                            popupWidth={700} //masukan dalam ukuran pixel
                            popupHeight={500} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='top' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={3} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.lemburColumns} // taruh konfigurasi kolom disini

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ViewPenggajianKaryawan