import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'

const Status = [
    {
        id: 1,
        value: 'WAITING'
    },
    {
        id: 2,
        value: 'FAILED'
    },
    {
        id: 3,
        value: 'VERIFIED'
    },
    {
        id: 4,
        value: 'BLOCKED'
    },
]
class ShowNoteAction extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster : {}
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.popupModalPreviewImageRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Kirim',
                    onClick: () => this.updateStatus()
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        
        this.visitorItems = [
            {
                dataField: 'description',
                label: {
                    alignment: 'left',
                    text: 'Deskripsi'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    height: '200px'
                }
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show(id,statusCode) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: {
                id : id,
                statusCode : statusCode
            },
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    updateStatus = () => {
        var status = Status.find(value => value.id === this.state.dataMaster.statusCode)
        
        this.props.updateStatus(this.state.dataMaster,status.value)
        
        if(this.props.hide){
            this.props.hide()
        }
        this.hide()
    }
    retrieveData = async(data = {}) => {
        this.setState({
            dataMaster: data,
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Aktivasi Akun'}
                width={500}
                height={400}

                ref={this.PopupRef}

                toolbarItems = {this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <Form
                                colCount={1}
                                id={'formdataMaster'}
                                formData={this.state.dataMaster}
                                items={this.visitorItems}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ShowNoteAction
