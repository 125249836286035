import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import notify from 'devextreme/ui/notify'

import httpRequest from 'plugin/httprequest';
import FormDataKepegawaian from '../../../compPeserta/dataKepegawaian';
import FormDataPersonal from '../../../compPeserta/dataPersonal';
import DataGridKeluarga from '../../../compPeserta/dataKeluarga';
import FormDataPensiunDitunda from '../comp/formDataPensiun';
import { compareObj } from 'plugin/helper';

class ModalPesertaDitunda extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            pesertaId: '',
            dataMaster : {},
            actionType: 'add'
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.popupKeluargaRef = React.createRef()
        
        this.formDataKepegawaianRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.formDataPensiunRef = React.createRef()
        this.dataGridDokumenRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options:{
                    'text': 'Tutup',
                    onClick: () => {
                        this.hide()
                        this.removeState()
                    } 
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 0,
                text: 'Data Personal'
            },
            {
                id: 1,
                text: 'Data Kepegawaian'
            },
            {
                id: 2,
                text: 'Data Keluarga'
            },
            {
                id: 3,
                text: 'Data Pensiun'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'id',
                label: {
                    alignment: 'left',
                    text: 'ID'
                },
                disabled: true,
            },
            {
                dataField: 'nik',
                label: {
                    alignment: 'left',
                    text: 'NIK'
                },
                isRequired: true,
                validationRules: [
                    {
                        type: 'required',
                        message: 'NIK Harus Diisi'
                    },
                    {
                        type: "pattern",
                        pattern: /\(?([0-9])\)?/,
                        message: "NIK harus menggunakan angka"
                    },
                    // {
                    //     type: 'stringLength',
                    //     max: 16,
                    //     min : 16
                    // }
                ],
            },
            {
                dataField: 'fullName',
                label: {
                    alignment: 'left',
                    text: 'Nama Lengkap'
                },
                validationRules: [
                    {
                        type: 'required',
                        message: 'Nama Lengkap Harus Diisi'
                    },
                ],
            },
        ]
    }
    get Popup(){
        return this.popupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabIndexModalRegistrasi${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    
    retrieveData = async(type,data = null) => {
        var loadAPIPeserta = `pesertas/${data.pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIPeserta, 'GET')

        var loadAPIKeluarga = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${data.pesertaId}`
        var getKeluarga = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKeluarga, 'GET')

        var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${data.pesertaId}`
        var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')

        var loadAPIAkun = `kepersertaan-products/${data.id}`
        var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
        getAkun.statusId = getAkun.akunStatusId || getAkun.statusId
        var peserta = getPeserta
        peserta.efektifDate = getKepegawaian.efektifDate
        if(type !== 'add'){
            await this.formDataPersonalRef.current.retrieveData(getPeserta)
            await this.formDataKepegawaianRef.current.retrieveData(data.id,getKepegawaian)
            await this.dataGridKeluargaRef.current.reloadData(data.id,getKeluarga)
            await this.formDataPensiunRef.current.reloadData(peserta,([getAkun] || []))
            this.setState({
                pesertaId: getPeserta.id,
                actionType: type,
                dataMaster: getPeserta
            })
        }else{
            this.setState({
                pesertaId: 0,
                actionType: type,
                dataMaster: {}
            })
        }
    }

    removeState = () => {
        this.formDataPersonalRef.current.removeState()
        this.formDataKepegawaianRef.current.removeState()
        this.formDataPensiunRef.current.removeState()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Peserta Pensiun Ditunda'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY : 'scroll', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formMasterRef}
                                colCount={3}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                readOnly={this.state.actionType == 'add' ? false : false}
                                labelLocation = 'left'
                            />
                        </div>
                        <div className="col-md-12">
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                        </div>
                        <div id={`tabIndexModalRegistrasi${this.props.actionType}-0`} className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataPersonal store={this.props.store} actionType={this.props.actionType} ref={this.formDataPersonalRef}/>
                        </div>
                        <div id={`tabIndexModalRegistrasi${this.props.actionType}-1`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataKepegawaian store={this.props.store} actionType={this.props.actionType} ref={this.formDataKepegawaianRef}/>
                        </div>
                        <div id={`tabIndexModalRegistrasi${this.props.actionType}-2`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <DataGridKeluarga store={this.props.store} actionType={this.props.actionType} ref={this.dataGridKeluargaRef}/>
                        </div>
                        <div id={`tabIndexModalRegistrasi${this.props.actionType}-3`} className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                            <FormDataPensiunDitunda store={this.props.store} actionType={this.props.actionType} ref={this.formDataPensiunRef} actionType={this.props.actionType}/>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalPesertaDitunda
