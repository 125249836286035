import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import { banks, mpType, product, returType, statusRetur } from 'dataSource/lookup';
import ModalActionTransaksiMpRetur from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/retur/action';
import ModalKonfirmasiTransaksiMpRetur from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/retur/konfirmasi';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { alert } from 'devextreme/ui/dialog';
import ModalProsesTransaksiMpRetur from 'components/pages/modal/kepesertaan/pembayaranMp/transaksiMp/retur/proses';
import { formatDate } from 'plugin/helper';

class TransaksiPembayaranRetur extends Component {
    constructor(props){
        super(props)

        this.state = {
            formFilter: {},
            getUrl: 'view-simple-pembayaran-returs?statusId.in=1&statusId.in=2&statusId.in=3'
        }

        this.dataGridRef = React.createRef()
        this.modalAddRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.modalLihatRef = React.createRef()
        this.modalProsesTransaksiMpReturRef = React.createRef()
        this.modalKonfirmasiTransaksiMpReturRef = React.createRef()

        this.filterItem = [
            {
                dataField:'greaterReturDate',
                label:{
                    text: 'Tanggal Retur',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy',
                }
            },
            {
                dataField:'lessReturDate',
                label:{
                    text: 'S/D',
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                editorType: 'dxButton',
                editorOptions: {
                    text: 'Filter',
                    onClick: (e) => {
                        this.forceRefresh()
                        var filter = this.state.formFilter
                        var tanggalReturDari = filter.greaterReturDate
                        var tanggalReturSampai = filter.lessReturDate
                        this.setState({
                            getUrl: tanggalReturDari && tanggalReturSampai ? `view-simple-pembayaran-returs?statusId.in=1&statusId.in=2&statusId.in=3&returDate.greaterThanOrEqual=${formatDate(tanggalReturDari)}&returDate.lessThanOrEqual=${formatDate(tanggalReturSampai)}` : `view-simple-pembayaran-returs?statusId.in=1&statusId.in=2&statusId.in=3`
                        })
                    }
                }
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Edit',
                        hint : 'Edit',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            var status = e.row.data
                            console.log(status)
                            if(status.statusId == 3 || status.statusId == 2) {
                                alert('Tidak bisa melakukan Edit data', 'Information');
                            } else {
                                this.showModal(e.row.data)
                            }
                        }
                    },
                    // {
                    //     text: 'Batal',
                    //     hint : 'Batal',
                    //     cssClass: 'text-success',
                    //     onClick : (e) => {
                    //         confirmAlert({
                    //             title: 'Apakah yakin?',
                    //             message: 'Apakah anda yakin ingin membatalkan retur ini?',
                    //             buttons: [
                    //                 {
                    //                     label: 'Ya',
                    //                     onClick: () => {
                    //                         this.batal(e.row.data)
                    //                     }
                    //                 },
                    //                 {
                    //                     label: 'Tidak'
                    //                 }
                    //             ]
                    //         })
                    //     }
                    // },
                    // {
                    //     text: 'Kirim',
                    //     hint : 'Kirim',
                    //     cssClass: 'text-info',
                    //     onClick : (e) => {
                    //         this.kirim()
                    //     }
                    // },
                    // {
                    //     text: 'Cetak',
                    //     hint : 'Cetak',
                    //     cssClass: 'text-info',
                    //     onClick : (e) => {
                    //         this.cetak()
                    //     }
                    // },
                    {
                        text: 'Lihat',
                        hint: 'lihat',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalLihat(e.row.data)
                        }
                    },
                    {
                        text: 'Konfirmasi Bank',
                        hint : 'Konfirmasi Bank',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            var status = e.row.data
                            console.log(status)
                            if(status.statusId == 3 || status.statusId == 2) {
                                alert('Tidak bisa melakukan Konfirmasi Bank', 'Information');
                            } else {
                                this.konfirmasi(e.row.data)
                            }
                        }
                    },
                    {
                        text: 'Proses Susulan',
                        hint : 'Proses Susulan',
                        cssClass: 'text-info',
                        onClick : (e) => {
                            var status = e.row.data
                            console.log(status)
                            if(status.statusId == 3 || status.statusId == 1) {
                                alert('Tidak bisa melakukan Proses Susulan', 'Information');
                            } else {
                                this.proses(e.row.data)
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Peserta'
            },
            {
                dataField: 'returDate',
                caption: 'Tanggal Retur',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                },
            },
            {
                dataField: 'mpTypeId',
                caption: 'Tipe MP',
                lookup: {
                    dataSource: mpType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "mpName", // provides display values
                },
            },
            {
                dataField: 'transaksiRetur',
                caption: 'Transaksi Retur',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'returDate',
                        caption: 'Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                    {
                        dataField: 'mpRekeningName',
                        caption: 'Nama Rekening',
                    },
                    {
                        dataField: 'mpRekeningNo',
                        caption: 'Nomor Rekening',
                    },
                    {
                        dataField: 'mpRekeningBankId',
                        caption: 'Bank Rekening',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "initialName", // provides display values
                        },
                    },
                    {
                        dataField: 'mpNominal',
                        caption: 'Nominal',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                ]
            },
            {
                dataField: 'returTypeId',
                caption: 'Tipe Retur',
                lookup: {
                    dataSource: returType(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                },
            },
            {
                dataField: 'returDescription',
                caption: 'Keterangan',
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusRetur(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    loadDummyData = () => {
        var dummyData = [
            {
                id: 1,
                produk : 'produk test',
                bulan : 'Januari',
                tahun : 2020,
                totalPensiunan : 3000000,
                totalPembayaran : 3000000,
                noSkPembayaran : 2501,
                tanggalPembayaran : '2020-07-16',
                bankBayar : 'BANK BCA',
                totalBiayaBank : 2500000,
                totalFeeBank : 3000000,
                retur : 500000,
                arApStatus : 'aktif',
            }
        ]
        this.currentData = JSON.parse(JSON.stringify(dummyData))
        return dummyData
    }

    showModal = (data = null) => {
        if(data){
            this.modalEditRef.current.show()
            this.modalEditRef.current.retrieveData(data)
        }else{
            this.modalAddRef.current.show()
        }
    }

    showModalLihat = (data = null) => {
        if(data){
            this.modalLihatRef.current.show()
            this.modalLihatRef.current.retrieveData(data)
        }else{
            this.modalAddRef.current.show()
        }
    }

    cetak = async() => {
        
    }

    kirim = async() => {

    }

    konfirmasi = (data) => {
        this.modalKonfirmasiTransaksiMpReturRef.current.show()
        this.modalKonfirmasiTransaksiMpReturRef.current.retrieveData(data)
    }

    proses = (data) => {
        this.modalProsesTransaksiMpReturRef.current.show()
        this.modalProsesTransaksiMpReturRef.current.retrieveData(data)
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Retur MP Bulanan',
                    onClick: (e) => {
                        this.showModal()
                    },
                }
            }
        )
    }

    batal = async(data) => {
        try {
            var loadAPIMaster = `pembayaran-mp-returs`
            var getMaster = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIMaster +'/'+data.id, 'GET')

            var loadAPIDetil = `pembayaran-mp-detils`
            var getDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIDetil +'/'+getMaster.pembayaranMpDetilId, 'GET')
    
            getDetil.retur = 2
            getDetil.statusKeuanganId = 0
            
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil, 'PUT', {
                key: getDetil.id,
                values: getDetil
            },getDetil)
    
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIMaster, 'DELETE', data.id)

            var message = `Data berhasil dibatalkan!`
            var title = 'Berhasil'
        } catch (e) {
            var message = e
            var title = 'Gagal'
        }
        await alert(message, title)
        this.forceRefresh()
    }

    insertData = async(data) => {
        try {
            // var loadAPIDetil = 'pembayaran-mp-detils'
            // var getDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil + '/' + data.pembayaranMpDetilId, 'GET')

            var loadAPIViewDetil = `view-pembayaran-mp-detils?idPembayaranMpDetil.equals=${data.pembayaranMpDetilId}&nikPeserta.equals=${data.nik}`
            var getViewDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIViewDetil , 'GET')

            var loadAPIDetil = 'pembayaran-mp-detils'
            var getDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil + '/' + getViewDetil[0].idPembayaranMpDetil, 'GET')
            
            var dataDetil = getDetil;
            // dataDetil.retur = 1
            // dataDetil.statusKeuanganId = 6

            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil, 'PUT', {
                key: dataDetil.id,
                values: dataDetil
            },getDetil)
            
            delete data.id
            var postAPI = 'pembayaran-mp-returs'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
                values: data
            })

            var message = `Data berhasil ditambahkan!`
            var title = 'Berhasil'
        } catch (e) {
            var message = e
            var title = 'Gagal'
        }
        await alert(message, title)
        this.forceRefresh()
    }

    updateData = async(data) => {
        try {
            var updateAPI = 'pembayaran-mp-returs'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                key: data.id,
                values: data
            },data)

            var loadAPIDetil = 'pembayaran-mp-detils'
            var getDetil = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil +'/'+data.pembayaranMpDetilId, 'GET')

            var updateDetil = {
                ...getDetil,
                mpRekeningNo : data.mpRekeningNo,
                mpRekeningName : data.mpRekeningName,
                mpRekeningBankId : data.mpRekeningBankId,
            }
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetil, 'PUT', {
                key: getDetil.id,
                values: updateDetil
            },getDetil)

            var message = `Data berhasil diubah!`
            var title = 'Berhasil'
        } catch (e) {
            var message = e
            var title = 'Gagal'
        }
        await alert(message, title)
        this.forceRefresh()
    }

    submitKonfirmasi = async(data) => {
        try {
            var postAPI = 'pembayaran-mp-returs/konfirmasiBank'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, postAPI, 'POST', {
                values: data
            })

            var message = `Data berhasil dikonfirmasi!`
            var title = 'Berhasil'
        } catch (e) {
            var message = e
            var title = 'Gagal'
        }
        await alert(message, title)
        this.forceRefresh()
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Retur</h2>
                <Form
                    colCount={4}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                    labelLocation='left'
                />
                            
                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='pembayaran-mp-bulanan'
                    updateAPI='pembayaran-mp-bulanan'
                    deleteAPI='pembayaran-mp-bulanan' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {true}
                    // useArraySource = {true}
                    // ArraySourceData = {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pembayaran MP Retur"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pembayaran MP Retur'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalActionTransaksiMpRetur
                    ref={this.modalEditRef}
                    action={'edit'}
                    title={'Form Edit Retur'}
                    store={this.props.store}
                    submitData = {this.updateData}
                />
                <ModalActionTransaksiMpRetur
                    ref={this.modalAddRef}
                    action={'add'}
                    title={'Form Add Retur'}
                    store={this.props.store}
                    submitData = {this.insertData}
                />
                <ModalActionTransaksiMpRetur
                    ref={this.modalLihatRef}
                    action={'lihat'}
                    title={'Form Lihat Retur'}
                    store={this.props.store}
                    submitData = {this.insertData}
                />
                <ModalKonfirmasiTransaksiMpRetur
                    ref={this.modalKonfirmasiTransaksiMpReturRef}
                    store={this.props.store}
                    submitData= {this.submitKonfirmasi}
                />
                <ModalProsesTransaksiMpRetur 
                    ref={this.modalProsesTransaksiMpReturRef}
                    store={this.props.store}
                    submitData= {this.submitProses}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default TransaksiPembayaranRetur