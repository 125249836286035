import React, { Component } from 'react'
import { journalType, statusJournal } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url'
import ViewDetailTransaction from 'components/pages/modal/akutansi/journalImport/detailTransaction';
import { showLoading } from 'redux/actions/loading';
import { confirm } from 'devextreme/ui/dialog';
import { Form } from 'devextreme-react';
import { formatDate } from 'plugin/helper';

class JournalImportTransaction extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            DataMaster: {
                valueDate: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Lihat Daftar Transaksi',
                        hint: 'Lihat Daftar Transaksi',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.modalViewDetailTransaksiRef.current.getValueDate(this.state.DataMaster)
                            this.modalViewDetailTransaksiRef.current.setDataTransaksi(e.row.data.transType)
                            this.modalViewDetailTransaksiRef.current.show()
                        }
                    },
                ]
            },
            {
                dataField: 'transType',
                caption: 'Tipe Transaksi',
            },
            {
                dataField: 'transName',
                caption: 'Nama Transaksi',
            },
            {
                dataField: 'balance',
                caption: 'Saldo Telah Balance',
                dataType: 'boolean',
                calculateCellValue: (e) => {
                    return !(e.balance !== 0)
                }
            },
        ]

        this.datagridRef = React.createRef()
        this.modalViewDetailTransaksiRef = React.createRef()

        this.selectedRowsData = []

        this.filter = [
            {
                dataField: 'valueDate',
                label: {
                    text: 'Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
            },
            // {
            //     itemType: "button",
            //     buttonOptions: {
            //         text: "Filter",
            //         // type:"default",
            //         elementAttr: { class: "bg-dapen-default" },
            //         onClick: () => {
            //             this.filterSubmit()
            //         },
            //     },
            //     horizontalAlignment: "left"
            // },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    text: 'Proses impor jurnal',
                    onClick: async (e) => {
                        if (this.selectedRowsData.length === 0) {
                            notify({ message: 'Mohon pilih salah satu tipe transaksi yang akan di import!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                            return false
                        }
                        let result = await confirm("Apakah anda yakin untuk melakukan proses impor jurnal?", "Konfirmasi");
                        if (result) {
                            this.importJurnal()
                        }
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    }

    loadData = async () => {
        var dataKepesertaan = {}

        try {
            dataKepesertaan = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `incoming-transaction`)
        } catch (e) {
            console.log(e)
        }

        var dataInvestasi
        try {
            dataInvestasi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal-dtls/un-mapping-akun-coa/count`)
        } catch (e) {
            console.log(e)
        }

        var dataHrd = []
        try {
            dataHrd = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `incoming-transaction`)
        } catch (e) {
            console.log(e)
        }

        return [
            {
                transType: 'KPS',
                transName: 'Kepesertaan',
                balance: dataKepesertaan.kepesertaan
            },
            {
                transType: 'HRD',
                transName: 'HRD dan Umum',
                balance: dataHrd[0] ? dataHrd[0].db - dataHrd[0].cr : 0
            },
            {
                transType: 'INV',
                transName: 'Investasi',
                balance: dataInvestasi
            },
        ]
    }

    importJurnal = async () => {
        var errorKps = true
        var errorSdm = true
        var errorInv = true
        var dataTransType = this.selectedRowsData

        if (dataTransType.filter(value => value.balance > 0).length > 0) {
            notify({ message: 'Terdapat akun yang belum di mapping pada jurnal', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            return false
        }

        this.props.store.dispatch(showLoading(true))

        try {
            for (var transType of dataTransType) {
                switch (transType.transType) {
                    case 'KPS':
                        let kps = await this.importTransKepesertaan()
                        errorKps = kps
                        break;
                    case 'HRD':
                        let sdm = await this.importTransSdm()
                        errorSdm = sdm
                        break;
                    case 'INV':
                        let inv = await this.importTransInvestasi()
                        errorInv = inv
                        break;

                    default:
                        break;
                }
            }

            if (!errorKps) {
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Terdapat data error pada import jurnal Kepesertaan', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                return false
            }

            if (!errorSdm) {
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Terdapat data error pada import jurnal Hrd dan Umum', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                return false
            }

            if (!errorInv) {
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Terdapat data error pada import jurnal Investasi', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                return false
            }
            if (errorKps && errorSdm && errorInv) {
                this.props.store.dispatch(showLoading(false))
                notify({ message: 'Import Jurnal Transaksi Berhasil', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
            }
        } catch (e) {
            console.log(e)

            this.props.store.dispatch(showLoading(false))

            return false
        }

        // this.datagridRef.current.forceRefresh(true)

        this.props.store.dispatch(showLoading(false))
    }

    importTransKepesertaan = async () => {
        try {
            var baseCurrency = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/9 BASECCY`)
            var departementKep = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/9 DEPKEP`)
            var jouTypeKep = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/9 JOUTKPS`)

            var datas = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-transaction-detils/getTemplateJournal/${formatDate(this.state.DataMaster.valueDate)}`)

            var datas = datas.map(value => {
                if (!value.currencyId) {
                    value.currencyId = baseCurrency.intValue
                    value.dealExrate = 1
                    value.journaTypeId = jouTypeKep.intValue
                    value.departmentId = departementKep.intValue
                }
                value.produkId = value.productId

                return value
            })

            var processImport = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/import`, 'POST', { values: datas })

            if (processImport) {
                var updateCoaIdKep = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-transaction-detils/transactionAccount/${formatDate(this.state.DataMaster.valueDate)}`)
                return true
            }
        } catch (e) {
            console.log(e)
            return false
        }
    }

    importTransSdm = async () => {
        try {
            var baseCurrency = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/9 BASECCY`)
            var departementKep = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/9 DEPKEP`)
            var jouTypeKep = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `global-parameters/get-by-paramCode/9 JOUTKPS`)

            var datas = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umums/getTemplateJournal/${formatDate(this.state.DataMaster.valueDate)}`)

            var datas = datas.map(value => {
                if (!value.currencyId) {
                    value.currencyId = baseCurrency.intValue
                    value.dealExrate = 1
                    value.journaTypeId = jouTypeKep.intValue
                    value.departmentId = departementKep.intValue
                }

                return value
            })


            var processImport = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `accounting-report/import`, 'POST', { values: datas })
            if (processImport) {
                var updateCoaIdSdm = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `pembayaran-umums/transactionAccount/${formatDate(this.state.DataMaster.valueDate)}`)
                return true
            }
        } catch (e) {
            console.log(e)
            return false
        }
    }

    importTransInvestasi = async () => {
        try {
            var processImport = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal-dtls/posting/${formatDate(this.state.DataMaster.valueDate)}`, 'PUT', { values: {} }, {})

            return true
        } catch (e) {
            console.log(e);
            return false
        }
    }

    onSelectionChanged = (e) => {
        this.selectedRowsData = e.selectedRowsData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Impor Jurnal Transaksi</h3>
                <Form
                    colCount={4}
                    id={'form'}
                    formData={this.state.DataMaster}
                    items={this.filter}
                    labelLocation={'left'}
                />
                <DevExpressDataGrid
                    ref={this.datagridRef}
                    loadAPI='journal-headers'
                    insertAPI='journal-headers'
                    updateAPI='journal-headers'
                    deleteAPI='journal-headers'

                    keyField={'transType'}

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Jurnal Voucher"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={false}
                    showPageSizeSelector={false}   
                    defaultPageSize={0}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Jurnal Voucher Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={300} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ViewDetailTransaction store={this.props.store} ref={this.modalViewDetailTransaksiRef} />
            </div>
        )
    }
}

export default JournalImportTransaction