import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import FormModalProgramDapenPengajuanPensiun from 'components/pages/modal/sdm-umum/kepegawaian/pensiun-iuran/pengajuan-pensiun/edit'
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url'
import { confirmAlert } from 'react-confirm-alert';
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import { statusPembayaranRekapGaji } from 'dataSource/lookup';

class ProgramDapenPengajuanPensiun extends Component {
    constructor(props){
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        onClick : (e) => {
                            this.showModalPengajuanPensiunEdit(e)
                        }
                    },
                    {
                        text : 'Setuju',
                        hint : 'Setuju',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Ingin mengirim data pengajuan ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.kirim(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                            
                        }
                    },
                    {
                        text : 'Tolak',
                        hint : 'Tolak',
                        onClick : (e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Ingin menolak data pengajuan ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: (e) => {
                                            this.tolak(data)
                                        }
                                    },
                                    {
                                        label: 'Tidak',
                                    }
                                ]
                            })
                            
                        }
                    },
                ],
            },
            {
                dataField:'nik',
                caption:'NIK'
            },
            {
                dataField:'fullName',
                caption:'Nama Karyawan'
            },
            {
                dataField:'phkType',
                caption:'Jenis Pemberhentian'
            },
            // {
            //     dataField:'besaranPesangon',
            //     caption:'Besaran Pesangon',
            //     alignment:'center',
            //     columns:[
            //         {
            //             dataField:'gaji',
            //             caption:'Gaji (%)'
            //         },
            //         {
            //             dataField:'nominal',
            //             caption:'Nominal'
            //         },
            //     ]
            // },
            {
                dataField:'pesangonNominal',
                caption:'Total Nominal Pesangon',
                dataType:'number',
                format:'#,##0.00'
            },
            {
                dataField:'tanggalPengajuan',
                caption:'Tanggal Pengajuan',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'tanggalEfektif',
                caption:'Tanggal Efektif',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'pesangonPaydate',
                caption:'Tanggal Pembayaran Pesangon',
                dataType:'date',
                format:'dd MMM yyyy'
            },
            {
                dataField:'pensiunType',
                caption:'Jenis Pensiun Dapen'
            },
            {
                dataField:'statusId',
                caption:'Status',
                lookup:{
                    dataSource: statusPembayaranRekapGaji(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                }
            },
        ]

        this.showModalPengajuanPensiunRef = React.createRef()
        this.showModalPengajuanPensiunInsRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    kirim = async (data) => {
        try {
            var loadAPI = 'karyawan-pensiuns'
            var getKaryawanPensiun = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI + `/${data.id}`, 'GET')
            
            getKaryawanPensiun.statusId = 2
            console.log(getKaryawanPensiun);
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                values: getKaryawanPensiun,
                key:getKaryawanPensiun.id
            },getKaryawanPensiun)

            if (response) {
                notify({ message: "Sukses Input Lembur Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.forceRefresh()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    tolak = async (data) => {
        try {
            var loadAPI = 'karyawan-pensiuns'
            var getKaryawanPensiun = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI + `/${data.id}`, 'GET')
            
            getKaryawanPensiun.statusId = 3
            console.log(getKaryawanPensiun);
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
                values: getKaryawanPensiun,
                key:getKaryawanPensiun.id
            },getKaryawanPensiun)

            if (response) {
                notify({ message: "Sukses Input Lembur Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
                this.forceRefresh()
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "error", 600);
        }
    }

    showModalPengajuanPensiunEdit = (e) => {
        if (e.row) {
            this.showModalPengajuanPensiunRef.current.retrieveData(e.row.data)
        }
        this.showModalPengajuanPensiunRef.current.show()
    }
    showModalPengajuanPensiunInst = (e) => {
        this.showModalPengajuanPensiunInsRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalPengajuanPensiunInst(e)
                    }
                }
            }
        )
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pengajuan Pensiun</h3>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='view-regist-pensiun-lists?statusId.in=1&size=9999'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    // useArraySource = {true}
                    // ArraySourceData= {this.loadDummyData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Pengajuan Pensiun"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalProgramDapenPengajuanPensiun 
                    ref={this.showModalPengajuanPensiunRef}
                    store={this.props.store}
                    action={'edit'}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalProgramDapenPengajuanPensiun 
                    ref={this.showModalPengajuanPensiunInsRef}
                    store={this.props.store}
                    action={'add'}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default ProgramDapenPengajuanPensiun