import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import { alert } from 'devextreme/ui/dialog';
import httpRequest from 'plugin/httprequest';
import DropDownBox from 'devextreme-react/drop-down-box';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { ScrollView } from '../../../../../../../node_modules/devextreme-react';
import { hubunganKeluarga, pensiunType, mpPenerimaType, product, statusKeluarga, pesertaById } from 'dataSource/lookup';
import { Form } from "devextreme-react";

class ShowModalActionAddPesertaArsip extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.dataGridLookupRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.pesertaId = 0
        this.dataPeserta = {}
        this.lookupColumns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]
        
        this.columns = [
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'relationCode',
                caption: 'Hubungan',
                lookup: {
                    dataSource: hubunganKeluarga(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            // {
            //     dataField: 'umur',
            //     caption: 'Umur',
            // },
            {
                dataField: 'statusPenghasilan',
                caption: 'Berpenghasilan',
            },
            {
                dataField: 'statusSekolah',
                caption: 'Status Pendidikan',
            },
            {
                dataField: 'mariedId',
                caption: 'Status Kawin',
                lookup: {
                    dataSource: statusKeluarga(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            }
        ]
        
        this.formItem = [
            {
                itemType: 'group',
                colCount : 2,
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'arsipDate',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Pengarsipan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    width: '100%',
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: 'arsipSkNo',
                                label: {
                                    alignment: 'left',
                                    text: 'No SK Pengarsipan'
                                },
                            },
                            {
                                dataField: 'tanggalSkPengarsipan',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal SK Pengarsipan'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    width: '100%',
                                    displayFormat: 'dd MMM yyyy'
                                }
                            },
                            {
                                dataField: 'arsipKeterangan',
                                label: {
                                    alignment: 'left',
                                    text: 'Keterangan'
                                },
                            },
                        ],
                    },
                    {
                        itemType: 'group',
                        caption : 'Info Pensiun Peserta',
                        items: [
                            {
                                dataField: 'birthDate',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Lahir'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    width: '100%',
                                    displayFormat: 'dd MMM yyyy',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'pensiunType',
                                label: {
                                    alignment: 'left',
                                    text: 'Jenis Pensiun'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: pensiunType(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'statusName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'tanggalBerhenti',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Berhenti'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    width: '100%',
                                    displayFormat: 'dd MMM yyyy',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'pensiunDate',
                                label: {
                                    alignment: 'left',
                                    text: 'Tanggal Pensiun'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    useMaskBehavior: true,
                                    width: '100%',
                                    displayFormat: 'dd MMM yyyy',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'productId',
                                label: {
                                    alignment: 'left',
                                    text: 'Akun Produk'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: product(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'productCode', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                }
                            },
                            {
                                dataField: 'mpPenerimaTypeId',
                                label: {
                                    alignment: 'left',
                                    text: 'Penerima MP'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: mpPenerimaType(this.props.store),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'penerimaName', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                    readOnly: true
                                }
                            },
                        ],
                    }
                ]
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });

        this.forceRefresh()
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
        });

        this.pesertaId = 0
        this.dataPeserta = {}
        this.dropDownBoxRef.current.instance.option('value','')
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    onValueChanged = (e) => {
        // var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.pesertaId = fieldValue[0]
        
        // this.setState({
        //     [fieldName]: fieldValue
        // })
    }

    retrieveData = async(pesertaId) => {
        var loadAPI = `pesertas/${pesertaId}`
        var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
        this.dataPeserta = getPeserta
        console.log('dataPeserta', this.dataPeserta)
        console.log('dataMaster', this.state.dataMaster)

        var loadAPIKepegawaian = `peserta-kepegawaians/getByPesertaId/${pesertaId}`
        var getKepegawaian = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIKepegawaian, 'GET')
        
        var loadAPIListAkun = `view-kepesertaan-products?pesertaId.in=${pesertaId}`
        var getListAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIListAkun, 'GET')
        
        var dataForm = {
            ...getPeserta,
            tanggalBerhenti : getKepegawaian.tanggalBerhenti ? getKepegawaian.tanggalBerhenti : null
        }
        if(getListAkun.length !== 0){
            var loadAPIAkun = `kepersertaan-products/${getListAkun[0].id}`
            var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIAkun, 'GET')
            dataForm = {
                ...dataForm,
                ...getAkun
            }

        }

        this.formMasterRef.current.instance.updateData(dataForm)
        this.dataPeserta = getPeserta
    }
    passDataToParent = () => {
        var data = this.state.dataAkun

        return data
    }
    submitData = async() => {
        var data = this.state.dataMaster
        var dataPeserta = this.dataPeserta
        var updateData = {
            ...dataPeserta,
            id : dataPeserta.id,
            arsipSkNo : data.arsipSkNo,
            arsipDate : data.arsipDate,
            arsipKeterangan : data.arsipKeterangan,
            statusPeserta : 11
        }
        try {
            var updateAPI = 'pesertas'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, updateAPI, 'PUT', {
                key: dataPeserta.id,
                values:updateData
            },
            dataPeserta)

            await alert('Data berhasil ditambahkan!', 'Success!')

            this.hide()
            this.props.forceRefresh()
        } catch (e) {
            console.log(e)
            await alert(e, 'Gagal!')
        }
    }
    
    onToolbarPreparingDataAkun = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Tambah Iuran',
                    icon: 'plus',
                    onClick: (e) => {
                        this.props.showModalActionIuranDataAkun('add')
                    },
                }
            }
        )
    }
    
    retrieveDataGrid = async() => {
        try{
            var loadAPI = `simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`
            var getData = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPI, 'GET')
            
            return getData
        }catch(e){
            return []
        }
    }

    onSelectionChanged = (e) => {
        this.pesertaId = e.selectedRowKeys[0]
        this.dropDownBoxRef.current.instance.option('value',e.selectedRowKeys)
        this.retrieveData(this.pesertaId)
        this.forceRefresh()
    }
    
    renderDatagrid = (e) => {
        return(
            <DevExpressDataGrid
                ref={this.dataGridLookupRef}
                loadAPI= {'view-pesertas?statusPeserta.in=4'}
                insertAPI='pesertas'
                updateAPI='pesertas'
                deleteAPI='pesertas'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Peserta Arsip"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations = {true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}
                
                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Peserta Arsip'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.lookupColumns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged = {this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }
    render() {
        var toolbar = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this),
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Pengarsipan Peserta`} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={toolbar}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid" >
                    <div className="col-md-12">
                        <div className="form-group row">
                            <label className="col-sm-1 col-form-label">Peserta</label>
                            <div className="col-sm-11">
                                <DropDownBox
                                    ref={this.dropDownBoxRef}
                                    valueExpr="id"
                                    deferRendering={false}
                                    displayExpr={(item) => {
                                        return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                    }}
                                    showClearButton={true}
                                    dataSource={pesertaById(this.props.store)}
                                    onValueChanged={this.onValueChanged}
                                    contentRender={this.renderDatagrid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                                <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation='left'
                                readOnly = {this.props.actionType == 'view' ? true : false}
                            />
                        </div>
                        <div className={'mt-2 col-md-12 text-center' }>
                            <DevExpressDataGrid
                                ref={this.dataGridRef}
                                loadAPI= {`simple-peserta-families/getAllPesertaFamiliesByPesertaId/${this.pesertaId}`}
                                insertAPI='kepersertaan-products'
                                updateAPI='kepersertaan-products'
                                deleteAPI='kepersertaan-products'

                                backendserver={process.env.REACT_APP_BACKEND_CORE}
                                
                                useArraySource = {true}
                                ArraySourceData = {this.retrieveDataGrid}
                                
                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Pengajuan Peserta Arsip"}
                                allowExportSelectedData={true}
                                selection={"single"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={5}

                                showPageSizeSelector={false}
                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Pengajuan Peserta Arsip'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing={this.onToolbarPreparing}

                                // onSelectionChanged = {this.onSelectionChanged}

                                height={'auto'}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>

                </div>
                </ScrollView>
            </Popup>
        );
    }
}


export default ShowModalActionAddPesertaArsip