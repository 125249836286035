import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { months, statusPembayaranRekapGaji } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import {Form} from 'devextreme-react';
import FormModalPembayaranTunjanganLain from 'components/pages/modal/sdm-umum/payroll/tunjangan-lain/form';

import FormDaftarKaryawanPenerima from 'components/pages/modal/sdm-umum/payroll/tunjangan-lain/daftarKaryawanPenerima';
import httpRequest from 'plugin/httprequest';
import { formatDefaultFullDate, formatNumberAum } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";

class DaftarPembayaranTunjanganLain extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{}
        }

        
        this.DataGridPengajuanRef = React.createRef()
        this.showModalDaftarPembayaranTunjanganLainRef = React.createRef()
        this.filterData = []

        this.formMaster = [
            {
                dataField:"bulan",
                label:{
                    text:"Bulan",
                    alignment:"left",
                    location:"left",
                },
                editorType:'dxSelectBox',
                editorOptions:{
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled:true
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                    location:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        onClick : (e) => {
                            this.showModalDaftarPembayaranTunjanganLain(e)
                        }
                    },
                ],
            },
            {
                dataField:'tunjanganName',
                caption:'Tunjangan'
            },
            {
                dataField:'keterangan',
                caption:'Keterangan'
            },
            {
                dataField:'jumlahKaryawan',
                caption:'Jumlah Karyawan'
            },
            {
                dataField:'besaran',
                caption:'Besaran Tunjangan',
                columns:[
                    {
                        dataField:'nilaiPersenGapok',
                        caption:'% Gaji',
                    },
                    {
                        dataField:'nilaiPersenGapokTunjangan',
                        caption:'% Gaji + Tunjangan',
                    },
                    {
                        dataField:'nilaiNominal',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField:'totalNominalTunjanganLain',
                caption:'Total Nominal Tunjangan Lain',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField:'penerima',
                caption:'Penerima',
                lookup: {
                    dataSource: [
                        {
                            id:1,
                            value:'Semua'
                        },
                        {
                            id:2,
                            value:'Personal'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField:'tanggalPengajuan',
                caption:'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'periode',
                caption:'Periode Gaji',
                columns:[
                    {
                        dataField:'bulanDari',
                        caption:'Dari Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id',
                            displayExpr: 'value'
                        }
                           
                    },
                    {
                        dataField:'tahunDari',
                        caption:'Dari Tahun',
                    },
                ]
            },
            {
                dataField:'statusId',
                caption:'Status',
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    componentDidMount = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-tunjangan-lains?statusId.in=2`,
            'GET'
        )
        this.filterData = datas
        this.forceRefresh()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalDaftarPembayaranTunjanganLain = (e) => {
        if (e.row) {
            this.showModalDaftarPembayaranTunjanganLainRef.current.retrieveData(e.row.data)
        }
        this.showModalDaftarPembayaranTunjanganLainRef.current.show()
    }

    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'plus',
    //                 onClick: (e) => {
    //                     this.showModalDaftarPembayaranTunjanganLain(e)
    //                 },
    //             }
    //         }
    //     )
    // }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        )
    }

    exportExcel = async () => {
        let data = [];
        const firstTableRowNum = 8;
        const styletable = { vertical: 'top', horizontal: 'center' }
        let filter = this.state.DataMaster;
        let getData =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-tunjangan-lains?statusId.in=2`,
            'GET'
        );

        if (filter.bulan){
            getData = getData+`bulan.greaterThanOrEqual=${months(filter.bulan)}&`
        }

        if (filter.tahun){
            getData = getData+`tahun.lessThanOrEqual=${filter.tahun}&`
        }

        for (let value of getData){
            console.log(value);
            let dataValue = {
                tunjanganName: value.tunjanganName,
                keterangan: value.keterangan,
                jumlahKaryawan: value.jumlahKaryawan,
                nilaiPersenGapok: value.nilaiPersenGapok ? formatNumberAum(value.nilaiPersenGapok) : 0,
                nilaiPersenGapokTunjangan: value.nilaiPersenGapokTunjangan ? formatNumberAum(value.nilaiPersenGapokTunjangan) : 0,
                nilaiNominal: value.nilaiNominal ? formatNumberAum(value.nilaiNominal) : 0,
                totalNominalTunjanganLain: value.totalNominalTunjanganLain ? formatNumberAum(value.totalNominalTunjanganLain) : 0,
                penerima: value.penerima === 1 ? 'Semua' : value.penerima === 2 ? 'Personal' : '',
                tanggalPengajuan: value.tanggalPengajuan ? formatDefaultFullDate(value.tanggalPengajuan) : '',
                bulanDari: value.bulanDari === 1 ? 'Januari' : value.bulanDari === 2 ? 'Februari' : value.bulanDari === 3 ? 'Maret' : value.bulanDari === 4 ? 'April' : value.bulanDari === 5 ? 'Mei' : value.bulanDari === '6' ? 'Juni' : value.bulanDari === 7 ? 'Juli' : value.bulanDari === 8 ? 'Agustus' : value.bulanDari === 9 ? 'September' : value.bulanDari === 10 ? 'Oktober' : value.bulanDari === 11 ? 'November' : value.bulanDari === 12 ? 'Desember' : '',
                tahunDari: value.tahunDari,
                statusId: value.statusId
            };
            const ObjectValue = Object.values(dataValue);
            data.push(ObjectValue);
        }
        try {
            const workbook = new Excel.Workbook();
            let worksheet = workbook.addWorksheet('Pengajuan Tunjangan Lain');
            // let headStyle = {header:'Ultima visita',key:'last_visit',style: { numFmt: 'DD/MM/YYYY HH:mm:ss' },width: 22};
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                    theme: 'TableStyleLight15',
                    showFirstColom: true,   
                },
                columns: [
                    {name: 'Tunjangan'},
                    {name: 'Keterangan'},
                    {name: 'Jumlah Karyawan'},
                    {name: '%Gaji'},
                    {name: '%Gaji + Tunjangan', horizontal: 'center'},
                    {name: 'Nilai Nominal'},
                    {name: 'Total Nominal Tunjangan Lain'},
                    {name: 'Penerima'},
                    {name: 'Tanggal Pengajuan'},
                    {name: 'Dari Bulan'},
                    {name: 'Dari Tahun'},
                    {name: 'Status'},
                ],
                rows: data
            });
            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('E'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('F'+i).alignment = {vertical: 'middle', horizontal: 'right'};
                worksheet.getCell('G'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            worksheet.getCell('D2').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('D2').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D2').font = {size: 18, bold: true}
            
            worksheet.getCell('D3').value = 'DAFTAR PENGAJUAN TUNJANGAN LAIN';
            worksheet.getCell('D3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('D3').font = {size: 18, bold: true}

            for (let i = 0; i < getData.length; i++){
                worksheet.getCell('C4').value = `BULAN: ${getData[i].bulanDari === 1 ? 'Januari' : getData[i].bulanDari === 2 ? 'Februari' : getData[i].bulanDari === 3 ? 'Maret' : getData[i].bulanDari === 4 ? 'April' : getData[i].bulanDari === 5 ? 'Mei' : getData[i].bulanDari === 6 ? 'Juni' : getData[i].bulanDari === 7 ? 'Juli' : getData[i].bulanDari === 8 ? 'Agustus' : getData[i].bulanDari === 9 ? 'September' : getData[i].bulanDari === 10 ? 'Oktober' : getData[i].bulanDari === 11 ? 'November' : getData[i].bulanDari === 12 ? 'Desember' : ''}`
                worksheet.getCell('C4').alignment = { vertical: 'top', horizontal: 'right' };
                worksheet.getCell('C4').font = {size: 13, bold: false}

                worksheet.getCell('E4').value = `Tahun : ${getData[i].tahunDari ? getData[i].tahunDari : 'Semua'}`
                worksheet.getCell('E4').alignment = { vertical: 'top', horizontal: 'left' };
                worksheet.getCell('E4').font = {size: 13, bold: false}
            }
            const WidthColums = 30;

            const Data1 = worksheet.getColumn(1);
            Data1.width = WidthColums;

            const Data2 = worksheet.getColumn(2);
            Data2.width = WidthColums;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;
            
            const Data12 = worksheet.getColumn(12);
            Data12.width = WidthColums;
            
            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `LAPORAN_PENGAJUAN_TUNJANGAN_LAIN.xlsx`
                )
            })
        } catch (error) {
            console.log(error);
        }
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-tunjangan-lains?statusId.in=2`,
            'GET'
        )

        var listData = []
        for(var data of datas){
            data.tanggalPengajuan = new Date(data.tanggalPengajuan)
            listData.push(data)
        }
        if (state.bulan && state.tahun) {
            this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan && value.tanggalPengajuan.getFullYear() == state.tahun)
        }else if (state.bulan) {
            this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan)
        } else if (state.tahun) {
            this.filterData = listData.filter(value => value.tanggalPengajuan.getFullYear() == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.tanggalPengajuan.getMonth() + 1) == state.bulan && value.tanggalPengajuan.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    
    }

    loadData = () => {
        return this.filterData
    }



    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pengajuan Tunjangan Lain</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref = {this.DataGridPengajuanRef}
                    loadAPI='regist-pengkinian-pengajuan-aktifs'
                    insertAPI='regist-pengkinian-pengajuan-aktifs'
                    updateAPI='regist-pengkinian-pengajuan-aktifs'
                    deleteAPI='regist-pengkinian-pengajuan-aktifs' 

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData= {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Pengajuan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tipe Komponen'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormDaftarKaryawanPenerima 
                    ref={this.showModalDaftarPembayaranTunjanganLainRef}
                    store={this.props.store}
                    from={'daftar'}
                />
            </div>
        )
    }
}

export default DaftarPembayaranTunjanganLain