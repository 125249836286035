import React, { Component } from 'react'
import { Button, FileUploader, DataGrid } from "devextreme-react";
import { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import httpRequest from 'plugin/httprequest';
import { summaryDate, formatDefaultDate, formatDate } from 'plugin/helper';

class DetailAudit extends Component {
    constructor(props) {
        super(props)

        this.dataSource = new CustomStore({
            load: async (loadOptions) => {
                let data = this.props.dataDetail
                let dataAudit = data.auditEvent
                // if(dataAudit.length !== 1) {
                //     dataAudit[0].statusData = 'Pemeriksaan'
                //     dataAudit[1].statusData = 'Persetujuan'
                // } else {
                //     dataAudit[0].statusData = 'Pemeriksaan'
                // }

                return dataAudit
            },
            key: 'taskId',
            insert: (values) => {
            },
            update: (key, values) => {

            },
            remove: (key) => {

            }
        })

        this.dataGridDetalRef = React.createRef()
        this.columns = [
            {
                dataField: 'startTime',
                caption: 'Start Time'
            },
            {
                dataField: 'endTime',
                caption: 'End Time'
            },
            // {
            //     dataField: 'statusData',
            //     caption: 'Pemeriksaan / Persetujuan'
            // },
            {
                dataField: 'durationInMillis',
                caption: 'Duration'
            },
            {
                dataField: 'actionBy',
                caption: 'Action By'
            },
            {
                dataField: 'notes',
                caption: 'Notes'
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 0,
                            value: 'In Progres'
                        },
                        {
                            id: 1,
                            value: 'Approved'
                        },
                        {
                            id: 2,
                            value: 'Rejected'
                        }
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value', // provides display values
                }
            },
        ]
    }

    getDataSource = () => {
        this.dataGridDetalRef.current.getDataSource()
    }
    render() {
        return (
            <DataGrid
                id={"detailRiwayat"}
                ref={this.dataGridDetalRef}
                showBorders={false}
                dataSource={this.dataSource}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                // height={100}
                showScrollbar={"always"}
            // columns={this.columns}
            >
                {
                    this.columns.map((column) => {
                        return <Column
                            dataField={column.dataField}
                            caption={column.caption}
                            lookup={column.lookup}
                            cellRender={column.cellRender}
                            alignment={column.alignment || 'left'}
                            cssClass={column.cssClass}
                            format={column.format}
                            dataType={column.dataType}
                            width={column.width}
                            type={column.type}
                            buttons={column.buttons}
                            editorType={column.editorType}
                            editorOptions={column.editorOptions}
                            visible={column.visible}
                            sortOrder={column.sortOrder}
                            allowEditing={column.allowEditing}
                            calculateCellValue={column.calculateCellValue}
                        />
                    })
                }
                {/* <Paging enabled={false} /> */}
            </DataGrid>
        )
    }
}

export default DetailAudit