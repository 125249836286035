import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import PembayaranMpSekaligusUploadSk from 'components/pages/modal/kepesertaan/pembayaranMp/sekaligus/pembayaranMpSekaligusUploadSk'
import PembayaranMpPembayaranModal from 'components/pages/modal/kepesertaan/pembayaranMp/comp/showModal';
import { banks, product, hubunganKeluarga, pensiunType, tipePembayaranMp } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import PembayaranMpSekaligusCatatan from 'components/pages/modal/kepesertaan/pembayaranMp/sekaligus/catatan';
import { alert } from 'devextreme/ui/dialog';

class PemeriksaanPembayaranMpSekaligus extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formFilter: {},
            getUrl : 'view-pembayaran-mp-sekaliguses?mpPaymentTypeId.in=1&mpPaymentTypeId.in=2&pesertaBulanan.in=false&pesertaSekaligus100.in=true&statusId.in=2'
        }

        this.dataGridRef = React.createRef()
        this.pembayaranMpGeneratePembayaranModalRef = React.createRef()
        this.pembayaranMpDetailModalRef = React.createRef()
        this.popupUploadSk = React.createRef()
        this.popupCatatanKirim = React.createRef()
        this.popupCatatanReject = React.createRef()
        
        this.selectedId = []
        
        this.filterItem = [
            {
                dataField: 'produk',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        if(data.value){
                            this.setState({
                                getUrl: `view-pembayaran-mp-sekaliguses?mpPaymentTypeId.in=1&mpPaymentTypeId.in=2&pesertaBulanan.in=false&pesertaSekaligus100.in=true&statusId.in=2&productId.in=${data.value}`
                            })
                        }else{
                            this.setState({
                                getUrl: 'view-pembayaran-mp-sekaliguses?mpPaymentTypeId.in=1&mpPaymentTypeId.in=2&pesertaBulanan.in=false&pesertaSekaligus100.in=true&statusId.in=2'
                            })
                        }
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                width: 100,
                buttons: [
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-info',
                        onClick: (e) => {
                            this.showModalCatatanKirim(e.row.data.pembayaranMpId)
                        }
                    },
                    {
                        text: 'Tolak',
                        hint: 'Tolak',
                        cssClass: 'text-info',
                        onClick: async(e) => {
                            this.showModalCatatanReject(e.row.data.pembayaranMpId)
                        }
                    },
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        visible: false,
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalDetail(e.row.data)
                        }
                    },
                    {
                        text: 'Unggah SK',
                        hint: 'Unggah SK',
                        cssClass: 'text-info',
                        onClick: (e) => {
                            this.showModalUploadSk(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'ID',
                cellRender: (e) =>{
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                name: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                name: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "statusName", // provides display values
                },
            },
            {
                dataField: 'birthDate',
                name:'birthDate',
                caption: 'Tanggal Lahir',
                dataType: "date",
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'pensiunDate',
                name: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                dataType: "date",
                format: 'dd MMM yyyy'
            },
            {
                name: 'akunProduct',
                caption: 'Akun Produk',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'cardNo',
                        name: 'cardNo',
                        caption: 'No. Kartu'
                    },
                    {
                        dataField: 'productId',
                        caption: 'Produk',
                        lookup: {
                            dataSource: product(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "productCode" // provides display values
                        },
                    },
                    {
                        dataField: 'nominalPembayaran',
                        name: 'mpSekaligus',
                        caption: 'MP Sekaligus',
                        dataType: 'number',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                name: 'penerimaHp',
                caption: 'Penerima HP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'penerimaName',
                        caption: 'Nama',
                    },
                    {
                        dataField: 'relationCode',
                        caption: 'Hubungan',
                        lookup: {
                            dataSource: hubunganKeluarga(),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "value" // provides display values
                        }
                    },
                ]
            },
            {
                name: 'bankTransferMp',
                caption: 'Bank Tranfer MP',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'mpRekeningBankId',
                        name: 'bankId',
                        caption: 'Bank',
                        lookup: {
                            dataSource: banks(this.props.store),
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: "bankName" // provides display values
                        }
                    },
                    {
                        dataField: 'mpRekeningNo',
                        name: 'noRekening',
                        caption: 'No Rekening'
                    },
                    {
                        dataField: 'mpRekeningName',
                        name: 'namaRekening',
                        caption: 'Nama Rekening'
                    },
                ]
            },
            {
                dataField: 'mpPaymentTypeId',
                caption: 'Tipe Pembayaran MP',
                lookup: {
                    dataSource: tipePembayaranMp(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "typeName", // provides display values
                }
            },
            {
                dataField: 'statusId',
                caption: 'AR/AP Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Maker'
                        },
                        {
                            id: 2,
                            value: 'Checker'
                        },
                        {
                            id: 3,
                            value: 'Approval'
                        },
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                },
            },
            {
                dataField: 'description',
                caption: 'Keterangan'
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalUploadSk = (data) => {
        this.popupUploadSk.current.show()
        this.popupUploadSk.current.retrieveData(data)
    }
    
    showModalCatatanKirim = (pembayaranMpId) => {
        this.popupCatatanKirim.current.show(pembayaranMpId)
    }

    showModalCatatanReject = (pembayaranMpId) => {
        this.popupCatatanReject.current.show(pembayaranMpId)
    }

    showModalDetail = async(data) => {
        var loadAPI = `pembayaran-mps/${data.pembayaranMpId}`
        var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        this.pembayaranMpDetailModalRef.current.show(getPembayaranMp)
        this.pembayaranMpDetailModalRef.current.retrieveDataSekaligus(data)
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Kirim',
                    elementAttr: { class: "bg-dapen" },
                    onClick: () => {
                        this.kirimSelectedData(this.selectedId)
                    },
                }
            }
        )
    }

    kirim = async (pembayaranMpId, catatan) => {
        try {
            var loadAPI = `pembayaran-mps/${pembayaranMpId}`
            var data = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

            var dataUpdate = data
            dataUpdate.description = catatan
            dataUpdate.statusId = 3

            var loadAPI = 'pembayaran-mps'
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: dataUpdate.id,
                values: dataUpdate
            },data)

            if (response) {
                try {
                    var loadAPIRefId = `tasks?referenceId.equals=${pembayaranMpId}&taskStatusId.equals=1&taskTypeId.equals=64`;
                    var getAPITask = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPIRefId, 'GET');
                    
                    if (getAPITask === 0) {
                        await alert('Data berhasil dikirim', 'Pemberitahuan');
                        this.forceRefresh();
                        return null;
                    } else {
                        var valueTask = getAPITask[0];
                        valueTask.taskStatusId = 2
        
                        let response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'tasks', 'PUT', {
                            key: valueTask.id,
                            values: valueTask
                        }, valueTask);
        
                        if (response) {
                            var loadApiPost = `pembayaran-mps/persetujuanMpSekaligus/${pembayaranMpId}`;
                            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApiPost, 'POST', {
                                values: {}
                            });
                            
                            await alert('Data berhasil dikirim', 'Pemberitahuan');
                            this.forceRefresh();
                            return null;
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            var text = `Data berhasil dikirim!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }

    tolak = async (pembayaranMpId, catatan) => {
        try {
            var loadAPI = `pembayaran-mps/${pembayaranMpId}`
            var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

            var dataUpdate = getPembayaranMp
            dataUpdate.description = catatan
            dataUpdate.statusId = 1
            
            var loadAPIUpdate = 'pembayaran-mps'
            let response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIUpdate, 'PUT', {
                key: dataUpdate.id,
                values: dataUpdate
            },getPembayaranMp)

            if (response) {
                try {
                    var loadAPITask = `tasks?referenceId.equals=${pembayaranMpId}&taskStatusId.equals=1&taskTypeId.equals=64`;
                    var getAPITask = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, loadAPITask, 'GET');
               
                    var valueTask = getAPITask[0];
                    valueTask.taskStatusId = 2
        
                    await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, 'tasks', 'PUT', {
                        key: valueTask.id,
                        values: valueTask
                    }, valueTask);
                } catch (error) {
                    console.log(error);
                }
            }

            var text = `Data berhasil ditolak!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }
    kirimSelectedData = async(selectedId) => {
        if(selectedId.length !== 0){
            confirmAlert({
                title: 'Konfirmasi',
                message: "Apakah Anda Yakin Ingin Mengirim Pembayaran Ini?",
                buttons: [
                  {
                    label: 'Iya',
                    onClick:  async() => {
                        try {
                            for(var value of selectedId){
                                var loadAPI = 'pembayaran-mps'
                                var getDataMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + value, 'GET')
                                var dataUpdate = getDataMp
                                dataUpdate.statusId = 3
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                                    key: dataUpdate.id,
                                    values: dataUpdate
                                },getDataMp)
                            }
                            var text = `Data berhasil dikirim!`
                            var type = 'success'
                        } catch (e) {
                            console.log(e)
                            var text = e
                            var type = 'error'
                        }
                
                        this.forceRefresh()
                        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                    }
                  },
                  {
                    label: 'Tidak',
                    onClick: () => {

                    }
                  },
                ]
            });
        }else{
            var text = `Silahkan centang data terlebih dahulu!`
            var type = 'error'
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }
    }

    onSelectionChanged = (e) => {
        this.selectedId = e.selectedRowKeys
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Pemeriksaan Pembayaran Sekaligus</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />
                <div
                    className="mt-2 text-center">
                    <DevExpressDataGrid
                        ref={this.dataGridRef}
                        loadAPI={this.state.getUrl}
                        insertAPI='pembayaran-mps'
                        updateAPI='pembayaran-mps'
                        deleteAPI='pembayaran-mps'

                        backendserver={process.env.REACT_APP_BACKEND_CORE}
                        keyField={'pembayaranMpId'}
                        remoteOperations = {true}
                        
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Pemeriksaan Pembayaran MP Sekaligus"}
                        allowExportSelectedData={true}
                        selection={"multiple"}

                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Pemeriksaan Pembayaran MP Sekaligus'}
                        popupWidth={700} //masukan dalam ukuran pixel
                        popupHeight={500} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={3} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                        SummaryConfiguration={this.summary}

                        onToolbarPreparing={this.onToolbarPreparing}
                        onSelectionChanged={this.onSelectionChanged}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                </div>
                {/* <div className="my-3 float-right">
                    <Button
                        width={250}
                        elementAttr={{class:"bg-dapen"}}
                        text={"Proses Transakis Iuran"}
                        type={"success"}
                        stylingMode={"contained"}
                        onClick={this.submitData.bind(this)}
                        className={"mx-2"}
                        visible={true}
                    />
                </div> */}
                <PembayaranMpPembayaranModal
                    ref={this.pembayaranMpDetailModalRef}
                    store={this.props.store}
                    actionType={'view'}
                    type={'Sekaligus'}
                />
                <PembayaranMpSekaligusCatatan
                    ref={this.popupCatatanKirim}
                    store={this.props.store}
                    kirim = {this.kirim}
                />
                <PembayaranMpSekaligusCatatan
                    ref={this.popupCatatanReject}
                    store={this.props.store}
                    kirim = {this.tolak}
                />
                <PembayaranMpSekaligusUploadSk
                    ref={this.popupUploadSk}
                    forceRefresh = {this.forceRefresh}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default PemeriksaanPembayaranMpSekaligus