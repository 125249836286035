import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatDate, formatUploadFileData } from 'plugin/helper'
import ScrollView from 'devextreme-react/scroll-view';
import { assetSdm, programs } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormModalPengajuanPemeliharaanAset extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            DataMaster:{},
            fileUploadReceipt:[],
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: "programId",
                        label: {
                            text: "Program",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: programs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'program',
                            searchEnabled: true,
                            onValueChanged: async(e) => {
                                if (e.value) {
                                    var getAssetByProgram = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'assets', 'GET')
                                    getAssetByProgram = getAssetByProgram.filter(value => value.programId == e.value)
                                    this.formRef.current.instance.getEditor('assetId').option('dataSource', getAssetByProgram)
                                }
                            }
                        }
                    },
                    {
                        dataField:'noPemeliharaan',
                        label:{
                            text:'No Pemeliharaan'
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:'assetId',
                        label:{
                            text:'No Registrasi Aset'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            displayExpr: (item) => {
                                return item && item.assetKode + ' - ' + item.assetName
                            }, // provides display values
                            searchEnabled: true,
                        }
                    },
                    {
                        dataField:'tanggalPengajuan',
                        label:{
                            text:'Tanggal Pengajuan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField:'keterangan',
                        label:{
                            text:'Keterangan'
                        },
                        editorType:'dxTextArea'
                    },
                ]
            },
            {
                itemType: 'group',
                name: 'informasi-aset', 
                caption:'Informasi Aset',
                items: [
                    {
                        dataField:'groupAset',
                        label:{
                            text:'Group Aset'
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:'jenisAset',
                        label:{
                            text:'Jenis Aset'
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:'tipeAset',
                        label:{
                            text:'Tipe Aset'
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:'unit',
                        label:{
                            text:'Unit'
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                    {
                        dataField:'karyawan',
                        label:{
                            text:'Karyawan'
                        },
                        editorOptions: {
                            readOnly:true
                        }
                    },
                ]
            },
            
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Kirim",
                onClick:this.kirimPengajuan.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.updateData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        data.tanggalPengajuan = data.tglPengajuan
        this.setState({
            DataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}Name`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }


    submitData = async() => {
        try {
            var DataMaster = {...this.state.DataMaster}
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pemeliharaans/pengajuan', 'POST', {
                values: DataMaster,
            })
            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    updateData = async() => {
        try {
            var DataMaster = {...this.state.DataMaster}
            DataMaster.tanggalPemeliharaan = formatDate(DataMaster.tanggalPengajuan)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pemeliharaans', 'PUT', {
                values: DataMaster,
                key:DataMaster.id
            },DataMaster)
            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    kirimPengajuan = async () => {
        try {
            var DataMaster = {...this.state.DataMaster}
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pemeliharaans/pengajuan', 'POST', {
                values: DataMaster,
            })
            if (response) {
                try {
                    var kirim = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pemeliharaans/kirimPengajuan/${response.id}`, 'POST', {
                        values: {},
                    })
                    if (kirim) {
                        this.hide()
                        this.props.forceRefresh()
                        notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    }
                } catch (e) {
                    console.log(e);
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
                
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Pengajuan Pemeliharaan Aset'} 
                width={"100vh"}
                height={"40vh"}
                toolbarItems={this.newData ? this.PopupToolbarItem : this.PopupToolbarItem2}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
                <Form
                    ref={this.formRef}
                    colCount={2}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
            </ScrollView>
            </Popup>
        )
    }
}

export default FormModalPengajuanPemeliharaanAset