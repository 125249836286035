import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { pesertaById } from 'dataSource/lookup';
import { formatUploadFileData, formatUploadFileNameAndExtention } from 'plugin/helper';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { confirmAlert } from 'react-confirm-alert';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog'
class ModalInsertPengkinianPasif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            actionType: ''
        }

        this.pesertaId = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
        this.dropDownBoxRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: async () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            await this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'periodeNs',
                colSpan: 6,
                label: {
                    text: 'Periode',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    readOnly: false,
                    dataSource: [],
                    valueExpr: 'tahun',
                    displayExpr: 'tahun',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
                isRequired: true,
                validationRules: [
                    {
                        type: "required",
                        message: "Data Harus Diisi"
                    }
                ]
            },
            {
                dataField: 'noSk',
                label: {
                    text: 'No. SK Pengkinian'
                }
            },
            {
                dataField: 'skType',
                label: {
                    text: 'Jenis SK'
                }
            },
            {
                dataField: 'updatedNote',
                label: {
                    text: 'Catatan Pengkinian'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    height: 200
                }
            }
        ]

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]
    }

    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show(data) {
        let ns = []
        for(let val of data) {
            ns.push({
                tahun: val
            })
        }
        this.formRef.current.instance.getEditor('periodeNs').option('dataSource', ns);
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide() {
        this.dropDownBoxRef.current.instance.option('value',"")
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData: {}
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }

    submitData = async () => {
        var dataSubmit = {
            ...this.state.dataMaster,
            pesertaId: this.pesertaId
        }

        if (this.formRef.current.instance.validate() && !this.pesertaId) {
            this.hide()
            confirmAlert({
                title: 'Informasi',
                message: "Harap isi data peserta dan catatan pengkinian!",
                buttons: [
                    {
                        label: 'Tutup',
                        onClick: () => {

                        }
                    },
                ]
            });
        } else {
            dataSubmit.pesertaStatusId = 4
            dataSubmit.pengkinianTypeId = 1
            dataSubmit.statusCode = 4
            dataSubmit.online = false
            dataSubmit.updatedStatus = false

            try {
                var loadAPI = 'pengkinian-peserta-bo-aktifs'

                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'POST', {
                    values: dataSubmit
                })
                var text = `Data berhasil ditambah!`
                var title = 'Success'
            } catch (e) {
                console.log(e)
                var text = e
                var title = 'Failed'
            }
            await alert(text, title)
            this.props.forceRefresh()
            this.hide()
        }
    }

    onUploadStarted = (e, jenisData) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]

            var fr = new FileReader()

            var formattedFileData
            var formattedFileNameAndExtention

            fr.onload = (efr) => {
                formattedFileData = formatUploadFileData(efr.target.result)
                formattedFileNameAndExtention = formatUploadFileNameAndExtention(files.name)
                this.setState(prevState => ({
                    [jenisData]: {
                        ...prevState[jenisData],
                        [`${fieldName}Data`]: formattedFileData.base64data,
                        [`${fieldName}DataContentType`]: formattedFileData.mime,
                        [`${fieldName}Name`]: formattedFileNameAndExtention.name,
                        webExtentionFile: formattedFileNameAndExtention.extention,
                    }
                }))
            }

            fr.readAsDataURL(files)
        }
    }

    onSelectionChanged = async(e) => {
        this.pesertaId = e.selectedRowKeys[0]
        let nikPeserta = e.selectedRowsData[0].nik;
        this.dropDownBoxRef.current.instance.option('value', e.selectedRowKeys)
        
        let kepesertaanProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `kepersertaan-products/getByPesertaId/${this.pesertaId}`, 'GET');

        if (kepesertaanProduct.length !== 0) {  
            this.formRef.current.instance.updateData({periodeNs: kepesertaanProduct[0].nsPeriode})
        } else {
            alert('Data Kepesertaan Product Tidak Ditemukan', 'Informasi');
            this.formRef.current.instance.updateData({periodeNs: null})
        }
    }

    renderDatagrid = (e) => {
        return (
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI={'view-pesertas?statusPeserta.in=4'}
                insertAPI='pengkinian-peserta-bo-aktifs'
                updateAPI='pengkinian-peserta-bo-aktifs'
                deleteAPI='pengkinian-peserta-bo-aktifs'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations={true}

                paging={true}
                defaultPageSize={5}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged={this.onSelectionChanged}

                height={'100%'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengkinian Peserta Pasif'}
                width={1000}
                height={500}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid" style={{ overflowY: 'auto', height: '100%' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Peserta</label>
                                <div className="col-sm-10">
                                    <DropDownBox
                                        ref={this.dropDownBoxRef}
                                        valueExpr="id"
                                        deferRendering={false}
                                        displayExpr={(item) => {
                                            return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                        }}
                                        showClearButton={true}
                                        onOptionChanged={this.onDropdownOptionChanged}
                                        dataSource={pesertaById(this.props.store)}
                                        // onValueChanged={this.onValueChanged}
                                        contentRender={this.renderDatagrid}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataMaster'}
                                formData={this.state.dataMaster}
                                items={this.dataMaster}
                                labelLocation="left"
                                scrollingEnabled={true}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        );
    }
}


export default ModalInsertPengkinianPasif