import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import Form from 'devextreme-react/form';
import { Button } from 'devextreme-react';
import ViewPenggajianKaryawan from 'components/pages/modal/sdm-umum/payroll/gaji/slipGaji';
import { karyawan, months, programs, statusPembayaranRekapGaji } from 'dataSource/lookup';
import FormModalPengajuanGajiKaryawan from 'components/pages/modal/sdm-umum/payroll/gaji/pengajuanGaji';
import FormModalRekapTrs from 'components/pages/modal/sdm-umum/payroll/gaji/viewRekapTrs';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import FormModalRekapGaji from 'components/pages/modal/sdm-umum/payroll/gaji/viewRekapGaji.';
import FormDetailGaji from 'components/pages/modal/sdm-umum/payroll/gaji/viewDetailGaji';
import { confirmAlert } from 'react-confirm-alert'
import { showLoading } from 'redux/actions/loading';
import { formatDefaultFullDate, formatNumberAum } from 'plugin/helper';
import Excel from 'exceljs/dist/exceljs';
import saveAs from "file-saver";
import { alert } from 'devextreme/ui/dialog';

class PengajuanGaji extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {}
        }

        this.username = props.store.getState().sessionUser
        this.pengajuanColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail Gaji',
                        hint: 'Detail Gaji',
                        onClick: (e) => {
                            this.showModalDetilGaji(e)
                        }
                    },
                    {
                        text: 'Rekap PPH',
                        hint: 'Rekap PPH',
                        onClick: (e) => {
                            this.showModalRekapGaji(e)
                        }
                    },
                    {
                        text: 'Rekap Transaksi',
                        hint: 'Rekap Transaksi',
                        onClick: (e) => {
                            this.showModalRekapTrs(e)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        onClick: (e) => {
                            this.kirim(e)
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin ingin membatalkan data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.batal(e)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ]
                            })
                        }
                    },
                ],
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'pengajuanNo',
                caption: 'No Pengajuan',
            },
            {
                dataField: 'pengajuanDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'pembayaranDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'periodeGaji',
                caption: 'Periode',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'tahun',
                        caption: 'Tahun',
                    },
                    {
                        dataField: 'bulan',
                        caption: 'Bulan',
                    },
                    {
                        dataField: 'tanggalDari',
                        caption: 'Dari Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tanggalSampai',
                        caption: 'Sampai Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'transaksiGaji',
                caption: 'Transaksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'jumlahKaryawan',
                        caption: 'Jumlah Karyawan',
                        dataType: 'number',
                    },
                    {
                        dataField: 'totalTransactionNominal',
                        caption: 'Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]

        this.showModalDetilGajiRef = React.createRef()
        this.showModalPengajuanGajiRef = React.createRef()
        this.showModalRekapGajiRef = React.createRef()
        this.showModalRekapTrsRef = React.createRef()
        this.DataGridPengajuanRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalPengajuanGaji = () => {
        this.showModalPengajuanGajiRef.current.show()
        this.showModalPengajuanGajiRef.current.generateNoPengajuan(true)
    }

    showModalRekapGaji = (e) => {
        if (e.row) {
            this.showModalRekapGajiRef.current.retrieveData(e.row.data.id)
        }
        this.showModalRekapGajiRef.current.show()
    }

    showModalRekapTrs = (e) => {
        if (e.row) {
            this.showModalRekapTrsRef.current.retrieveData(e.row.data);
        }
        this.showModalRekapTrsRef.current.show()
    }
    showModalDetilGaji = (e) => {
        if (e.row) {
            this.showModalDetilGajiRef.current.retrieveData(e.row.data)
        }
        this.showModalDetilGajiRef.current.show()
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'download',
                    hint: 'Export',
                    onClick: (e) => {
                        this.exportExcel()
                    },
                }
            }
        )
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Pengajuan',
                    onClick: (e) => {
                        this.showModalPengajuanGaji(e)
                    },
                }
            }
        )
    }

    exportExcel = async () => {
        let data = [];
        let response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-daftar-pembayaran-gajis', 'GET');
        const firstTableRowNum = 8;

        for (let value of response){
            let getProgram = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, `programs/${value.programId}`);
            let dataValue = {  
                programId: getProgram.program,
                pengajuanNo: value.pengajuanNo,
                pengajuanDate: value.pengajuanDate ? formatDefaultFullDate(value.pengajuanDate) : '-',
                pembayaranDate: value.pembayaranDate ? formatDefaultFullDate(value.pembayaranDate) : '-',
                tahun: value.tahun,
                bulan: value.bulan,
                tanggalDari: value.tanggalDari ? formatDefaultFullDate(value.tanggalDari) : '-',
                tanggalSampai: value.tanggalSampai ? formatDefaultFullDate(value.tanggalSampai) : '-',
                jumlahKaryawan: value.jumlahKaryawan,
                totalTransactionNominal: value.totalTransactionNominal ? formatNumberAum(value.totalTransactionNominal) : 0,
                statusId: value.statusId === 1 ? "Pengajuan" : value.statusId === 2 ? "Telah disetujui" : value.statusId === 3 ? "Ditolak" : '-',
            }
            const objectValue = Object.values(dataValue);
            data.push(objectValue);
        }
        try {
            const workbook = new Excel.Workbook();
            var worksheet = workbook.addWorksheet('DAFTAR PENGAJUAN PEMBAYARAN GAJI');
            worksheet.addTable({
                name: 'MyTable',
                ref: `A${firstTableRowNum}`,
                headerRow: true,
                totalsRow: false,
                style: {
                  theme: 'TableStyleLight15',
                  showFirstColumn: true,
                  // border: {
                  //     top: {style:'thin'},
                  //     left: {style:'thin'},
                  //     bottom: {style:'thin'},
                  //     right: {style:'thin'}
                  // }
                },
                columns: [
                    {name: 'Program'},
                    {name: 'No Pengajuan'},
                    {name: 'Tanggal Pengajuan'},
                    {name: 'Tanggal Pembayaran'},
                    {name: 'Tahun'},
                    {name: 'Bulan'},
                    {name: 'Dari Tanggal'},
                    {name: 'Sampai Tanggal'},
                    {name: 'Jumlah Karyawan'},
                    {name: 'Nominal'},
                    {name: 'Status'},
                  ],
                  rows: data,
            });

            let lastRowNum = worksheet.lastRow.number;
            const lastTableRowNum = lastRowNum;

            for (let i = firstTableRowNum; i <= lastTableRowNum; i++){
                const row = worksheet.getRow(i);
                row.eachCell({includeEmpty: true}, (cell => {    
                    cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true}
                }))
            }

            worksheet.getCell('C3').value = 'DANA PENSIUN PEGADAIAN';
            worksheet.getCell('C3').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('C3').font = {size: 18, bold: true}
            
            worksheet.getCell('C4').value = 'REKAP PENGAJUAN PEMBAYARAN GAJI';
            worksheet.getCell('C4').alignment = { vertical: 'top', horizontal: 'center' };
            worksheet.getCell('C4').font = {size: 18, bold: true}

            for (let i = 9; i <= 10000; i++){
                worksheet.getCell('J'+i).alignment = {vertical: 'middle', horizontal: 'right'};
            }

            var WidthColums = 20;
                
            const Data1 = worksheet.getColumn(1);
            Data1.width = 35;

            const Data2 = worksheet.getColumn(2);
            Data2.width = 40;

            const Data3 = worksheet.getColumn(3);
            Data3.width = WidthColums;

            const Data4 = worksheet.getColumn(4);
            Data4.width = WidthColums;

            const Data5 = worksheet.getColumn(5);
            Data5.width = WidthColums;

            const Data6 = worksheet.getColumn(6);
            Data6.width = WidthColums;

            const Data7 = worksheet.getColumn(7);
            Data7.width = WidthColums;

            const Data8 = worksheet.getColumn(8);
            Data8.width = WidthColums;

            const Data9 = worksheet.getColumn(9);
            Data9.width = WidthColums;

            const Data10 = worksheet.getColumn(10);
            Data10.width = WidthColums;

            const Data11 = worksheet.getColumn(11);
            Data11.width = WidthColums;

            await workbook.xlsx.writeBuffer().then(function(buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    `DAFTAR_PENGAJUAN_PEMBAYARAN_GAJI.xlsx`
                )
            })
        } catch (error) {
            console.log(error);
        }
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    kirim = async (e) => {
        this.props.store.dispatch(showLoading(true))
        if (e.row) {
            try {
                // var loadApi = 'send-pembayaran-gaji';
                // var data = {
                //      karyawanPayrollId: e.row.data.id
                // }
                // var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, loadApi, 'POST', {
                //     values: data
                // })
                var loadApi = `karyawan-payrolls/setPembayaranUmumPayroll/${e.row.data.id}/${this.username}`;
                // var data = {
                //     // karyawanPayrollId: e.row.data.id
                // }
                var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'POST', {
                    values: {}
                })

                if (response) {
                    this.props.store.dispatch(showLoading(false))
                    this.forceRefresh()
                    notify({ message: 'Data berhasil dikirim!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
                    // await alert(response[0].status_description, 'Informasi');
                }
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e);
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    batal = async (e) => {
        this.props.store.dispatch(showLoading(true))
        if (e.row) {
            try {
                var loadApi = `pembayaran-gaji/deletePembayaranGaji`
                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadApi, 'DELETE', e.row.data.id)

                this.props.store.dispatch(showLoading(false))
                this.forceRefresh()
                notify({ message: "Data dibatalkan!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 1000);
            } catch (e) {
                this.props.store.dispatch(showLoading(false))
                console.log(e);
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    loadData = async () => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'view-daftar-pembayaran-gajis', 'GET')
        response = response.filter(value => value.statusId == 1);
        return response

        // var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'karyawan-payrolls/getViewDaftarPembayaranGaji/', 'GET')
        // response = response.filter(value => value.statusId == 1)
        // return response
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Pengajuan Pembayaran Gaji</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.DataGridPengajuanRef}
                    loadAPI='view-daftar-pembayaran-gajis'
                    insertAPI='karyawan-lemburs'
                    updateAPI='karyawan-lemburs'
                    deleteAPI='karyawan-lemburs'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}
                    useArraySource={true}
                    ArraySourceData={this.loadData}
                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={false}
                    exportFileName={"Pengajuan Gaji"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Gaji'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.pengajuanColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalPengajuanGajiKaryawan
                    ref={this.showModalPengajuanGajiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalRekapGaji
                    ref={this.showModalRekapGajiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalRekapTrs
                    ref={this.showModalRekapTrsRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormDetailGaji
                    ref={this.showModalDetilGajiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PengajuanGaji