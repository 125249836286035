import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { Popup } from 'devextreme-react/popup'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import FormDataPensiunPasif from '../../../compPeserta/dataPensiunPasif';
import { pesertaById } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { ScrollView } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';

import { confirmAlert } from 'react-confirm-alert';

class ModalRegistrasiPasif extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            DataMaster: {},
            pesertaId: 0,
            periodeNs: {},
            tahun: 0
        }

        this.pesertaId = 0
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.popupRef = React.createRef()

        this.formMasterRef = React.createRef()
        this.formDataKepegawaianRef = React.createRef()
        this.formDataPersonalRef = React.createRef()
        this.dataGridKeluargaRef = React.createRef()
        this.formDataAkunRef = React.createRef()
        this.formDataPensiunRef = React.createRef()
        this.dropDownBoxRef = React.createRef()
        this.dropDownRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.TabDataSource = [
            {
                id: 4,
                text: 'Data Pensiun'
            }
        ]

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap',
            },
        ]

        this.filterItem = [
            {
                dataField: 'periodeNs',
                label: {
                    text: 'Periode',
                    alignment: "left",
                    location: "left",
                    visible: false
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'tahun',
                    displayExpr: 'tahun',
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        if (data.value) {
                            this.setState({
                                tahun: data.value
                            })
                        }
                    }
                }
            },
        ]
    }
    get Popup() {
        return this.popupRef.current.instance
    }

    show = async(data) => {
        let ns = []
        for(let val of data) {
            ns.push({
                tahun: val
            })
        }
        this.formMasterRef.current.instance.getEditor('periodeNs').option('dataSource', ns);
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });

        this.prevTabIndex = 0
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {},
            pesertaId: 0,
            periodeNs: {},
            tahun: 0
        })
        this.props.forceRefresh()
    }

    getPerhitunganPeserta = async (productId) => {
        // var tahun = 2020
        var tahun = this.state.tahun
        var loadAPI = `peserta-pensiuns/getPerhitunganPensiun/${this.pesertaId}/${productId}/${tahun}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        return response
    }

    getPesertaPensiun = async () => {
        var result = {}
        try {
            var loadAPI = `simple-pesertas/getSimpleById/${this.state.DataMaster.id}`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
            result = response.pesertaPensiun
        } catch (e) {
            console.log(e)
        }

        return result
    }
    onValueChanged = (e) => {
        var fieldValue = e.value
        this.pesertaId = fieldValue
        this.formDataPensiunRef.current.reloadData(this.pesertaId, this.state.DataMaster)
    }
    setSelectedRow = (id) => {
        this.dropDownBoxRef.current.instance.option('value', id)
    }
    submitData = async (data = {}) => {
        var prosesSubmitData = async (data) => {

            if (data.mpPaymentTypeId == 1) {
                // data.mpBulanan = data.mpBulanan ? data.mpBulanan * 80 / 100 : 0
                data.mpBulanan = data.mpBulanan80 || 0
                data.mpBulananPeserta = data.mpBulanan80NoRound || 0
            } else {
                data.mpBulanan = data.mpBulanan || 0
                data.mpBulananPeserta = data.mpBulananPeserta || 0
            }
            data.mpBulanPending = 0

            var loadAPIPeserta = 'pesertas'
            var updateAPI = 'kepersertaan-products'
            if (data) {
                switch (data.mpPenerimaType) {
                    case 1:
                        data.mpSekaligus = data.mpSekaligusPeserta || 0
                        break;
                    case 2:
                        data.mpSekaligus = data.mpSekaligusJadud || 0
                        break;
                    case 3:
                        data.mpSekaligus = data.mpSekaligusAnak || 0
                        break;
                    case 4:
                        data.mpSekaligus = data.mpSekaligusPeserta || 0
                        break;
                    default:
                        data.mpSekaligus20 = 0
                }
                data.iuranManfaatLainPeserta = data.iuranManfaatLainPeserta || 0
                data.iuranManfaatLainPk = data.iuranManfaatLainPk || 0
                data.iuranNormalPeserta = data.iuranNormalPeserta || 0
                data.iuranNormalPk = data.iuranNormalPk || 0
                data.iuranSukarelaPeserta = data.iuranSukarelaPeserta || 0
                data.mpBulanPending = data.mpBulanPending || 0
                // data.mpBulanan = data.mpBulanan || 0
                data.mpBulanan80 = data.mpBulanan80 || 0
                data.mpBulanan80NoRound = data.mpBulanan80NoRound || 0
                data.mpBulananNoRound = data.mpBulananNoRound || 0
                data.mpBulananAnak = data.mpBulananAnak || 0
                data.mpBulananJadud = data.mpBulanan || 0
                // data.mpBulananJadud = data.mpBulananJadud || 0
                // data.mpBulananPeserta = data.mpBulananPeserta || 0
                data.mpSekaligus = data.mpSekaligus || 0
                data.mpSekaligus20 = data.mpSekaligus20 || 0
                data.mpSekaligus20NoRound = data.mpSekaligus20NoRound || 0
                data.mpSekaligusNoRound = data.mpSekaligusNoRound || 0
                data.mpSekaligusJadud = data.mpSekaligusJadud || 0
                data.mpSekaligusPeserta = data.mpSekaligusPeserta || 0
                data.nominalPemberhentian = data.nominalPemberhentian || 0
                try {
                    var loadAPIBank = `rekbanks/${data.paymentRekbankId}`
                    var getBank = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIBank, 'GET')

                    data.akunStatusId = data.akunStatusId == 1 ? 6 : data.akunStatusId
                    if (getBank) {
                        if (getBank.bankId == data.mpRekeningBankId) {
                            data.feeBank = 0
                        } else {
                            data.feeBank = getBank.feeBank || 0
                        }
                    }

                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, updateAPI, 'PUT', {
                        key: data.id,
                        values: data
                    },
                        data)

                    // UPDATE PESERTA
                    var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `${loadAPIPeserta}/${data.pesertaId}`, 'GET')
                    var dataUpdatePeserta = getPeserta
                    dataUpdatePeserta.statusPeserta = 3
                    dataUpdatePeserta.usiaPensiunTahun = data.tahunUsiaPesertaPensiun
                    dataUpdatePeserta.usiaPensiunBulan = data.bulanUsiaPesertaPensiun
                    dataUpdatePeserta.pensiunDate = data.pensiunDate
                    dataUpdatePeserta.pemberhentianSkNo = data.skPemberhentianNo
                    dataUpdatePeserta.pensiunSkNo = data.skPensiunNo

                    if (data.masaKerja) {
                        var mkTahun = parseInt(data.masaKerja.split('Tahun')[0])
                        var mkBulan = data.masaKerja.split('Tahun')[1] ? parseInt((data.masaKerja.split('Tahun')[1]).split('Bulan')[0]) : 0
                        // var usiaPenTahun = parseInt(data.usiaPesertaPensiun.split('Tahun')[0])
                        // var usiaPenBulan = data.usiaPesertaPensiun.split('Tahun')[1] ? parseInt((data.usiaPesertaPensiun.split('Tahun')[1]).split('Bulan')[0]) : 0
                        dataUpdatePeserta.masaKerjaTahun = mkTahun
                        dataUpdatePeserta.masaKerjaBulan = mkBulan
                        // if(mkTahun != data.masaKerjaTahun && mkBulan != data.masaKerjaBulan){
                        // }
                    }

                    await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIPeserta, 'PUT', {
                        key: dataUpdatePeserta.id,
                        values: dataUpdatePeserta
                    },
                        getPeserta)

                    var text = 'Berhasil Menyimpan Data'
                    await alert(text, 'Success!')
                    this.hide()
                } catch (e) {
                    console.log(e)
                    await alert(e, 'Failed!')
                }
            }
        }

        if (data.tahunUsiaPesertaPensiun >= 56) {
            prosesSubmitData(data)
        } else {
            prosesSubmitData(data)
            // confirmAlert({
            //     title: 'Umur peserta diatas 56 tahun',
            //     message: "Apakah anda yakin ingin melanjutkan ?",
            //     buttons: [
            //         {
            //             label: 'Simpan',
            //             onClick: () => {
            //                 prosesSubmitData(data)
            //             }
            //         },
            //         {
            //             label: 'Batal',
            //             onClick: () => { }
            //         },
            //     ]
            // });
        }
        // this.hide()
    }

    retrieveData = async (data = null) => {
        var loadAPI = `peserta-pensiuns/loadDataRegistPensiun/${data.nik}`
        var response = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
        var nsPeriode = response[0].ns_periode;
        if (data) {
            this.setState({
                pesertaId: data.pesertaId,
                DataMaster: data,
            })
            this.formMasterRef.current.instance.getEditor('periodeNs').option('value', nsPeriode);
            this.pesertaId = data.pesertaId
            this.setSelectedRow(data.pesertaId)
            await this.formDataPensiunRef.current.reloadData(data.pesertaId, data)
        } else {
            // await this.formDataPensiunRef.current.reloadData()
            this.setState({
                pesertaId: 0,
                DataMaster: {}
            })

            this.pesertaId = 0
            this.formDataPensiunRef.current.forceRefresh()
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabModalIndex${this.props.actionType}-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabModalIndex${this.props.actionType}-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onSelectionChanged = (e) => {
        this.dropDownBoxRef.current.instance.option('value', e.selectedRowKeys)
    }

    renderDatagrid = (e) => {
        return (
            <DevExpressDataGrid
                ref={this.dataGridRef}
                loadAPI={this.props.actionType == 'add' ? 'view-pesertas?statusPeserta.in=1,2,3,5,6' : 'view-pesertas'}
                insertAPI='lookup-pesertas/getByLookupByStatusPeserta/2'
                updateAPI='lookup-pesertas/getByLookupByStatusPeserta/2'
                deleteAPI='lookup-pesertas/getByLookupByStatusPeserta/2'

                backendserver={process.env.REACT_APP_BACKEND_CORE}

                allowAdding={false}
                allowDeleting={false}
                allowUpdating={false}

                exportExcel={false}
                exportFileName={"Pengajuan Pengkinian Peserta Aktif"}
                allowExportSelectedData={true}
                selection={"single"}

                showBorders={true}

                remoteOperations={true}

                paging={true}
                defaultPageSize={5}
                showPageSizeSelector={false}

                //bagian konfigurasi popup saat insert dan edit record
                popupTitle={'Pengajuan Pengkinian Peserta Aktif'}
                popupWidth={700} //masukan dalam ukuran pixel
                popupHeight={500} //masukkan dalam ukuran pixel

                popupFormLabelLocation='left' //accepted value = top, left, right
                popupFormMinColWidth={300} // minimum lebar kolom
                popupFormColCount={3} //jumlah kolom pada form

                //akhir bagian konfigurasi popup

                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                FilterRow={true} // set false untuk mematikan fitur filter

                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                // SummaryConfiguration={this.summary}

                onToolbarPreparing={this.onToolbarPreparing}

                onSelectionChanged={this.onSelectionChanged}

                height={'auto'}

                //contoh konfigurasi kolom
                //this.columns = [{
                //    dataField: 'kolom1',
                //    caption: 'Ini Kolom 1'
                //}, {
                //    dataField: 'kolom2',
                //    caption: 'Ini Kolom 2'
                //}]
                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                store={this.props.store} // jangan di edit edit
            />
        )
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Pengajuan Peserta Pasif'}
                minWidth={500}
                minHeight={500}

                ref={this.popupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="col-md-12">
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Periode</label>
                            <div className='col-sm-10'>
                                <Form
                                    formData={this.state.periodeNs}
                                    items={this.filterItem}
                                    ref={this.formMasterRef}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Peserta</label>
                            <div className="col-sm-10">
                                <DropDownBox
                                    ref={this.dropDownBoxRef}
                                    value={this.state.DataMaster.pesertaId}
                                    valueExpr="id"
                                    deferRendering={false}
                                    displayExpr={(item) => {
                                        return item && 'NIK: ' + item.nik + ', Nama: ' + item.fullName;
                                    }}
                                    showClearButton={true}
                                    dataSource={pesertaById(this.props.store)}
                                    onValueChanged={this.onValueChanged}
                                    contentRender={this.renderDatagrid}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <DevextremeTab
                            dataSource={this.TabDataSource}
                            onOptionChanged={this.onTabSelectionChanged}
                        />
                    </div>
                    <ScrollView width='100%' height='100%'>
                        <div id={`tabModalIndex${this.props.actionType}-4`} className={'mt-2 col-md-12 text-center'}>
                            <FormDataPensiunPasif store={this.props.store} submitData={this.submitData} actionType={this.props.actionType} ref={this.formDataPensiunRef} getPesertaPensiun={this.getPesertaPensiun} getPerhitunganPeserta={this.getPerhitunganPeserta} peridoeNs={this.state.tahun} />
                        </div>
                    </ScrollView>
                </div>
            </Popup>
        )
    }
}

export default ModalRegistrasiPasif