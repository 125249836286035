import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { productDefault } from 'dataSource/lookup';


class ModalKomite extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            dataMaster: [],
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.column = [
            {
                dataField: 'no',
                caption: 'No.',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'startTime',
                caption: 'Tanggal',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'assignee',
                caption: 'Nama User',
            },
            {
                dataField: 'jabatan',
                caption: 'Jabatan',
                calculateCellValue: (e) => {
                    let str = e.taskName.split(' ')
                    return str[0]
                }
            },
            {
                dataField: 'notes',
                caption: 'Komentar',
            },
            {
                dataField: 'status',
                caption: 'Keputusan',
                lookup: {
                    dataSource: [
                        {
                            id: 0,
                            value: 'Belum memberikan keputusan'
                        },
                        {
                            id: 1,
                            value: 'Menyetujui'
                        },
                        {
                            id: 2,
                            value: 'Menolak'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
    }

    show(data) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster: data
        });
        this.dataGridRef.current.forceRefresh(true)
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: [],
        })
    }
    loadData = () => {
        return this.state.dataMaster
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Daftar Persetujuan'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div id="tabindex-0" className={' mt-2 col-md-12'} style={{ height: "calc(57vh - 70px)" }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <DevExpressDataGrid
                                        keyField='taskId'
                                        ref={this.dataGridRef}
                                        loadAPI='kategoris'
                                        insertAPI='kategoris'
                                        updateAPI='kategoris'
                                        deleteAPI='kategoris'

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.loadData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={true}
                                        exportFileName={"Komite Pengajuan Investasi"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}
                                        height={400}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Komite Pengajuan Investasi'}
                                        popupWidth={700} //masukan dalam ukuran pixel
                                        popupHeight={500} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.column} // taruh konfigurasi kolom disini
                                        // SummaryConfiguration={this.summary}

                                        onToolbarPreparing={this.onToolbarPreparing}
                                        // summaryTotalItem={this.sumKategoriAset}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalKomite