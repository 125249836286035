import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { currency, getInstrumentById, instruments, instrumentTransactionTypes, kpds, productDefault, rekeningBank } from 'dataSource/lookup'
import ScrollView from 'devextreme-react/scroll-view'
import httpRequest from 'plugin/httprequest'
import ArrayStore from 'devextreme/data/array_store';
import { formatDate, getSystemDate } from 'plugin/helper'
import notify from 'devextreme/ui/notify'

class ModalOperasionalPenyewaan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'No Konfirmasi'
                },
                editorOptions: {
                    readOnly: true,
                }
            },
            {
                dataField: 'productId',
                label: {
                    alignment: 'left',
                    text: 'Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode',
                    onValueChanged: async (e) => {
                        var selectProperti = this.formRef.current.instance.getEditor('efekBalanceAvgId')
                        var selectKpd = this.formRef.current.instance.getEditor('kpdId')
                        var dataKpd = await this.kpdByProduct(this.props.store, e.value)
                        selectKpd.option('dataSource', dataKpd)
                        var dataProperti = await this.efekBalanceAvgProperti(this.props.store, e.value, selectKpd.option('value'))
                        selectProperti.option('dataSource', dataProperti)
                    },
                },
            },
            {
                dataField: 'kpdId',
                label: {
                    alignment: 'left',
                    text: 'Pengelola'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'noKontrak',
                    onValueChanged: async (e) => {
                        var productId = this.formRef.current.instance.getEditor('productId').option('value')
                        var selectProperti = this.formRef.current.instance.getEditor('efekBalanceAvgId')
                        var dataProperti = await this.efekBalanceAvgProperti(this.props.store, productId, e.value)
                        var data = dataProperti._array;
                        var dataArr = [];

                        for (let value of data) {
                            let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${value.instrumentId}`, 'GET');
                            dataArr.push(response);
                        }
                        selectProperti.option('dataSource', dataArr);
                        // selectProperti.option('dataSource', dataProperti)
                    }
                },
            },
            {
                dataField: 'efekBalanceAvgId',
                label: {
                    alignment: 'left',
                    text: 'Aset'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.id + ' - ' + e.instrumentName
                    },
                    onSelectionChanged: (e) => {
                        var slctd = e.selectedItem
                        if (slctd) {
                            this.formRef.current.instance.getEditor('instrumentId').option('value', slctd.id)
                        }
                    }
                },
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: getInstrumentById(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.instrumentCode + ' - ' + e.instrumentName
                    },
                    readOnly: true
                }
            },
            {
                dataField: 'receiveDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Terima'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    width: '100%',
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'transactionTypeId',
                label: {
                    alignment: 'left',
                    text: 'Tipe Transaksi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentTransactionTypes(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'transactionName',
                    readOnly: true
                },
            },
            {
                dataField: 'entitasId',
                label: {
                    alignment: 'left',
                    text: 'KPA'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'entityName',
                    searchEnabled: true
                }
            },
            {
                dataField: 'nominalTotal',
                label: {
                    alignment: 'left',
                    text: 'Nominal Sewa'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'periodeTagihan',
                label: {
                    alignment: 'left',
                    text: 'Tagihan Periode'
                },
            },
            {
                dataField: 'nominalTagihan',
                label: {
                    alignment: 'left',
                    text: 'Tagihan Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'nominalTransaksi',
                label: {
                    alignment: 'left',
                    text: 'Nominal Diterima'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'pph',
                label: {
                    alignment: 'left',
                    text: 'PPH'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'ppn',
                label: {
                    alignment: 'left',
                    text: 'PPN'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    alignment: 'left',
                    text: 'Currency'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.currencyCode + ' - ' + e.currencyName
                    },
                },
            },
            {
                dataField: 'kurs',
                label: {
                    alignment: 'left',
                    text: 'Kurs'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                }
            },
            {
                dataField: 'receiveRekbankId',
                label: {
                    alignment: 'left',
                    text: 'Rekening Bank Terima'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: rekeningBank(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e && e.rekNo + ' - ' + e.rekName
                    },
                },
            },
            {
                dataField: 'keterangan',
                label: {
                    alignment: 'left',
                    text: 'Keterangan'
                },
            },
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show(type, data) {
        this.setState({
            dataMaster: data,
            actionType: type,
            popupVisible: true,
        });
        this.pengelolaAset()
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData: {}
        })
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })
    }
    pengelolaAset = async() => {
        var getPengelola = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `entitis/getAllByType/PA`, 'GET')

        let dataPengelola = new ArrayStore({
            data: getPengelola,
            key: 'id'
        });
        this.formRef.current.instance.getEditor('entitasId').option('dataSource', dataPengelola)
    }
    kpdByProduct = async (store, productId) => {
        let loadAPI = `kpds?productId.in=${productId}`
        var getKpd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getKpd,
            key: 'id'
        });

        return dataSource
    }
    validate = () => {
        var formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid
    }
    efekBalanceAvgProperti = async (store, productId, kpdId) => {
        let loadAPI = `efek-balance-avgs?categoryId.in=8&kdpId.in=${kpdId}&productId.in=${productId}`
        var getProp = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET')

        let dataSource = new ArrayStore({
            data: getProp,
            key: 'id'
        });

        return dataSource
    }
    submitData = async () => {
        var data = this.state.dataMaster
        var submitData = {
            currencyId: data.currencyId,
            currencyRate: data.kurs,
            efekBalanceAvgId: data.efekBalanceAvgId,
            entitasId: data.entitasId,
            instrumentId: data.instrumentId,
            keterangan: data.keterangan,
            konfirmasiDate: formatDate(data.receiveDate),
            konfirmasiNo: data.noKonfirmasi,
            kpdId: data.kpdId,
            nominalBersih: data.nominalTransaksi,
            nominalTagihan: data.nominalTagihan,
            nominalTotal: data.nominalTotal,
            nominalTransaksi: data.nominalTransaksi,
            pengajuanDate: getSystemDate(this.props.store),
            periodeTagihan: data.periodeTagihan,
            pph: data.pph,
            ppn:data.ppn,
            productId: data.productId,
            receiveDate: formatDate(data.receiveDate),
            receiveRekbankId: data.receiveRekbankId,
            transactionDate: formatDate(data.receiveDate),
            transactionTypeId: data.transactionTypeId,
        }
        if(submitData){
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'bank-transaction/simpan-penerimaan-properti-sewa', 'POST', {values: submitData})
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Simpan data berhasil!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
            }
        }
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Transaksi Penyewaan Aset'}
                width={700}
                height={700}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    ref={this.formRef}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default ModalOperasionalPenyewaan