import React, { Component } from 'react';
import { Popup,ScrollView,Form,FileUploader } from 'devextreme-react'
import { cities, citiesFilter, educationBackground, jabatan, months, programs, provinces, sdmUnit } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest' 
import notify from "devextreme/ui/notify";

import { confirmAlert } from 'react-confirm-alert'

class FormModalKandidatRekruitmen extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
            DataDocument:{},
            uploadedDataIjazah:[],
            uploadedDataKeahlian:[],
            uploadedDataKtp:[],
            uploadedDataPelatihan:[]
        }

        this.formMasterRef = React.createRef()
        
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.rekruitmenId = 0

        this.DataMaster = [
            {
                dataField:"kodeRekruitmen",
                label:{
                    text:"Kode Rekruitmen"
                },
                editorOptions:{
                    readOnly:true
                }
            },
            {
                dataField:"fullName",
                label:{
                    text:"Nama Lengkap"
                }
            },
            {
                dataField:"noKtp",
                label:{
                    text:"No Ktp"
                }
            },
            {
                dataField:"tempatLahir",
                label:{
                    text:"Tempat Lahir"
                },
            },
            {
                dataField:"email",
                label:{
                    text:"Email"
                },
            },
            {
                dataField:"noHp",
                label:{
                    text:"No Hp"
                },
            },
            {
                dataField:"tanggalLahir",
                label:{
                    text:"Tanggal Lahir"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField:"alamat",
                label:{
                    text:"Alamat"
                }
            },
            {
                dataField: 'provinsiId',
                label: {
                    alignment: 'left',
                    text: 'Provinsi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (data) => {
                        var selectCity = this.formMasterRef.current.instance.getEditor('kotaId')
                        // selectCity.option('value', '')
                        if(data.value){
                            selectCity.option('dataSource',citiesFilter(this.props.store,data.value,true))
                        }
                    }
                },
            },
            {
                dataField: 'kotaId',
                label: {
                    alignment: 'left',
                    text: 'Kota'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (data) => {
                        // var selectKecamatan = this.formRef.current.instance.getEditor('identityKecamatanId')
                        // // selectKecamatan.option('value', '')
                        // if(data.value){
                        //     selectKecamatan.option('dataSource',kecamatansFilter(this.props.store,data.value,true))
                        // }
                    }
                },
            },
            {
                itemType:'group',
                caption:'Pengalaman',
                items:[
                    {
                        dataField:"expInstitusiName1",
                        label:{
                            text:"Nama Institusi 1"
                        }
                    },
                    {
                        itemType:'group',
                        colCount:4,
                        items:[
                            {
                                dataField:"expBulanDari1",
                                label:{
                                    text:"Bulan"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField:"expTahunDari1",
                                label:{
                                    text:"Tahun"
                                },
                                editorType: 'dxNumberBox',
                            },
                            {
                                dataField:"expBulanSampai1",
                                label:{
                                    text:"s/d  Bulan"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField:"expTahunSampai1",
                                label:{
                                    text:"Tahun"
                                },
                                editorType: 'dxNumberBox',
                            },
                        ]
                    },
                    {
                        dataField:"expPosisi1",
                        label:{
                            text:"Posisi/Jabatan"
                        },
                    },
                    {
                        dataField:"expInstitusiName2",
                        label:{
                            text:"Nama Institusi 2"
                        }
                    },
                    {
                        itemType:'group',
                        colCount:4,
                        items:[
                            {
                                dataField:"expBulanDari2",
                                label:{
                                    text:"Bulan"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField:"expTahunDari2",
                                label:{
                                    text:"Tahun"
                                },
                                editorType: 'dxNumberBox',
                            },
                            {
                                dataField:"expBulanSampai2",
                                label:{
                                    text:"s/d  Bulan"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField:"expTahunSampai2",
                                label:{
                                    text:"Tahun"
                                },
                                editorType: 'dxNumberBox',
                            },
                        ]
                    },
                    {
                        dataField:"expPosisi2",
                        label:{
                            text:"Posisi/Jabatan"
                        },
                    },
                    {
                        dataField:"expInstitusiName3",
                        label:{
                            text:"Nama Institusi 3"
                        }
                    },
                    {
                        itemType:'group',
                        colCount:4,
                        items:[
                            {
                                dataField:"expBulanDari3",
                                label:{
                                    text:"Bulan"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField:"expTahunDari3",
                                label:{
                                    text:"Tahun"
                                },
                                editorType: 'dxNumberBox',
                            },
                            {
                                dataField:"expBulanSampai3",
                                label:{
                                    text:"s/d  Bulan"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: months(),
                                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                                    displayExpr: 'value', // provides display values'
                                    searchEnabled: true,
                                    deferRendering: false,
                                },
                            },
                            {
                                dataField:"expTahunSampai3",
                                label:{
                                    text:"Tahun"
                                },
                                editorType: 'dxNumberBox',
                            },
                        ]
                    },
                    {
                        dataField:"expPosisi3",
                        label:{
                            text:"Posisi/Jabatan"
                        },
                    },
                ]
            },
            {
                itemType:'group',
                caption:'Pendidikan Terakhir',
                items:[
                    {
                        dataField:"edLembaga",
                        label:{
                            text:"Lembaga"
                        }
                    },
                    {
                        dataField:"edLevelId",
                        label:{
                            text:"Level"
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: educationBackground(this.props.store),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'educationName', // provides display values'
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:"edJurusan",
                        label:{
                            text:"Jurusan"
                        }
                    },
                    {
                        dataField:"edTahunLulus",
                        label:{
                            text:"Tahun Lulus"
                        },
                        editorType: 'dxNumberBox',
                    },
                    {
                        dataField:"edIpk",
                        label:{
                            text:"IPK"
                        },
                        editorType: 'dxNumberBox',
                    },
                ]
            }
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
    }

    show() { 
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.rekruitmenId = 0
        this.setState({
            popupVisible: false,
            DataMaster:{}
        })
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}ContentType`],state[`${fileName}`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    retrieveData = async(data) => {
        this.setState({
            DataMaster: data,
            DataDocument:data,
        })
    }

    getRekruitmenId = (rekruitmenId) => {
        this.rekruitmenId = rekruitmenId
    }

    onUploadStartedIjazah = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}`] = formattedFileData.base64data;
            DataDocument[`${fieldName}ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    onUploadStartedKeahlian = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}`] = formattedFileData.base64data;
            DataDocument[`${fieldName}ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };
    
    onUploadStartedKtp = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}`] = formattedFileData.base64data;
            DataDocument[`${fieldName}ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    onUploadStartedPelatihan = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}`] = formattedFileData.base64data;
            DataDocument[`${fieldName}ContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    submitData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.rekurtmenId = this.rekruitmenId
            var loadApi = 'rekurtmen-kandidats'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Input Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);  
        }
    }

    updateData = async() => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.rekurtmenId = this.rekruitmenId
            DataMaster.id = DataMaster.id
            var loadApi = 'rekurtmen-kandidats'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "PUT",{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Update Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);  
        }
    }

    render() {
        return (
        <React.Fragment>
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Kandidat"}
                width={"40vw"}
                height={"90vh"}
                toolbarItems={this.props.from == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <ScrollView height="100%" width="100%">
                    <div className="container-fluid">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            formData={this.state.DataMaster}
                            items={this.DataMaster}
                            labelLocation = 'left'
                        />
                        {/* Ijazah */}
                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStartedIjazah.bind(this)}
                            labelText="Ijazah File"
                            showFileList={false}
                            name={"ijazahFile"}
                            value={this.state.uploadedDataIjazah}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-4">{ this.state.DataDocument.ijazahFileName}</label>
                        </div>
                        <div className="col-md-4 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument,'ijazahFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.ijazahFileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                        {/* File Sertifikat Keahlian */}
                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStartedKeahlian.bind(this)}
                            labelText="Sertifikat Keahlian"
                            showFileList={false}
                            name={"sertifikatFile"}
                            value={this.state.uploadedDataKeahlian}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-4">{ this.state.DataDocument.sertifikatFileName}</label>
                        </div>
                        <div className="col-md-4 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument,'sertifikatFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.sertifikatFileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                        {/* KTP */}
                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStartedKtp.bind(this)}
                            labelText="KTP File"
                            showFileList={false}
                            name={"ktpFile"}
                            value={this.state.uploadedDataKtp}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-4">{ this.state.DataDocument.ktpFileName}</label>
                        </div>
                        <div className="col-md-4 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument,'ktpFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.ktpFileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                        {/* File Keterangan Pelatihan */}
                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStartedPelatihan.bind(this)}
                            labelText="Pelatihan File"
                            showFileList={false}
                            name={"pelatihanFile"}
                            value={this.state.uploadedDataPelatihan}
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-2">File Name:</label>
                            <label className="col-4">{ this.state.DataDocument.pelatihanFileName}</label>
                        </div>
                        <div className="col-md-4 text-right">
                            <button onClick={() => this.previewFile(this.state.DataDocument,'pelatihanFile')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.pelatihanFileName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                Preview
                            </button>
                        </div>
                    </div>
                    </ScrollView>
                </Popup>
                
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormModalKandidatRekruitmen
