import React, { Component } from 'react'
import { Form } from "devextreme-react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { frekuensis, instrumentCategorySubs, product } from 'dataSource/lookup';
import ModalBreakDeposito from 'components/pages/modal/investasi/portfolio/maturity/index';
import httpRequest from 'plugin/httprequest';
import ModalRolloverMaturity from 'components/pages/modal/investasi/portfolio/maturity/rollover/index';
import ModalNewBilyet from 'components/pages/modal/investasi/portfolio/maturity/bilyet/index';
import { daysDiff, formatDate } from 'plugin/helper';

class PortofolioMaturity extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.state = {
            filterItem: {
                categorySubIdList: null,
                startDate: formatDate(this.date),
                endDate: formatDate(this.date),
                maturityDate: formatDate(this.date)
            },
        }

        this.modalRef = React.createRef()
        this.modalRolloverRef = React.createRef()
        this.modalBilyetRef = React.createRef()
        this.dgRef = React.createRef()
        this.dataSource = []
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Cairkan Deposito',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                    {
                        text: 'Rollover',
                        onClick: (e) => {
                            this.modalRollover(e.row.data)
                        }
                    },
                    // {
                    //     text: 'Ubah Bilyet',
                    //     onClick: (e) => {
                    //         this.modalBilyet(e.row.data)
                    //     }
                    // },
                ]

            },
            {
                dataField: 'categoryName',
                caption: 'Kategori'
            },
            {
                dataField: 'instrumentCode',
                caption: 'Kode Instrument'
            },
            {
                dataField: 'instrumentName',
                caption: 'Nama Instrument'
            },
            {
                dataField: 'maturityDate',
                caption: 'Jatuh Tempo',
                dataType: 'date',
                format: 'dd MMM yyyy',
                cellRender: (data) => {
                    // if(data === )
                    var days = daysDiff(data.value, this.date)

                    if (days === -3) {
                        return <span className={`text-warning`}>{data.text}</span>

                    } else if (days === -1 || days === 0) {
                        return <span className={`text-danger`}>{data.text}</span>
                    } else {
                        return <span>{data.text}</span>
                    }

                },
            },
            {
                dataField: 'rate',
                caption: 'Rate (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'couponPeriod',
                caption: 'Periode Kupon',
                lookup: {
                    dataSource: frekuensis(),
                    valueExpr: 'value',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'couponLastDate',
                caption: 'Coupon Last Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'couponNextDate',
                caption: 'Coupon Next Date',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'totalNominal',
                caption: 'Nominal Penempatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalAccrued',
                caption: 'Nominal Bunga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiSaatIni',
                caption: 'Nilai Saat Ini',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
                calculateCellValue: (e) => {
                    var nomPlace = e.totalNominal
                    var nomBunga = e.nominalAccrued
                    return nomPlace + nomBunga
                }
            },
        ]
        this.filterItem = [
            {
                itemType: 'group',
                colCount: 4,
                items: [
                    {
                        dataField: "categorySubIdList",
                        label: {
                            text: "Kategori",
                            alignment: "left",
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: instrumentCategorySubs(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'subName',
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 4,
                items: [
                    {
                        dataField: "maturityDate",
                        label: {
                            text: "Tanggal Jatuh tempo",
                            alignment: "left",
                            location: "left"
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 4,
                items: [
                    {
                        dataField: "startDate",
                        label: {
                            text: "Tanggal",
                            alignment: "left",
                            location: "left"
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        dataField: "endDate",
                        label: {
                            text: "s/d",
                            alignment: "left",
                            location: "left"
                        },
                        editorType: "dxDateBox",
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy'
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: async () => {
                                this.filterSubmit()
                                // this.forceRefresh()


                                // this.setState({
                                //     filterItem: this.state.filterItem
                                // })
                                // this.dgRef.current.forceRefresh(true)
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]
        this.summaryMaturity = [
            {
                showInColumn: 'couponNextDate',
                displayFormat: 'TOTAL :'
            },
            {
                column: 'totalNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumNominal',
                displayFormat: '{0}'
            },
            {
                column: 'nilaiSaatIni',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'sumNilaiSaatIni',
                displayFormat: '{0}'
            }
        ]
    }
    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    filterSubmit = async () => {
        var data = this.state.filterItem
        var endDate = formatDate(data.endDate)
        var startDate = formatDate(data.startDate)
        var maturityDate = formatDate(data.maturityDate)
        var response

        if (data.categorySubIdList !== null) {
            response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/deposito-pencairan-maturity-list?categorySubIdList=${data.categorySubIdList}&endDate=${endDate}&&maturityDate=${maturityDate}&size=9999&startDate=${startDate}`, 'GET')
        } else {
            response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/deposito-pencairan-maturity-lists?size=9999`, 'GET')
        }

        var result = response.filter(val => val.totalNominal !== 0)

        this.dataSource = result
        this.forceRefresh()
    }

    loadData = () => {
        return this.dataSource
        // return result
    }

    // onCellPrepared = (e) => {
    //     if(e.rowType == 'data') {
    //         var value = this.formRef.current.instance.getEditor('maturityDate').option('value')

    //         if(value){
    //             var days = daysDiff(value, this.date)

    //             if(days === -3){
    //                 e.cellElement.style.backgroundColor = '#fff208'

    //             }else if(days === -1 || days === 0){
    //                 e.cellElement.style.backgroundColor = '#ff0808'
    //             }else{
    //                 e.cellElement.style.backgroundColor = '#ff0808'
    //             }
    //         }
    //     }
    // }

    modalRollover = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/${data.id}`)
        var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${detail.instrumentId}`)

        var kuponLastDate = new Date(instrument.couponLastDate)
        var kuponNextDate = new Date(instrument.couponNextDate)
        var diffYear = kuponNextDate.getFullYear() - kuponLastDate.getFullYear()
        var diffMount = kuponNextDate.getMonth() - kuponLastDate.getMonth()
        var diffDate = kuponNextDate.getDate() - kuponLastDate.getDate()
        var daysCount
        var nominalAccruedBunga
        var nominalBunga

        if (instrument.dayBase == 'BASE_30_360') {
            daysCount = 360 * (diffYear) + 30 * (diffMount) + (diffDate)
            nominalAccruedBunga = daysCount * instrument.rate / 100 * detail.faceValue / 360
            nominalBunga = nominalAccruedBunga
        }

        if (instrument.dayBase == 'BASE_30_365') {
            daysCount = 365 * (diffYear) + 30 * (diffMount) + (diffDate)
            nominalAccruedBunga = daysCount * instrument.rate / 100 * detail.faceValue / 365
            nominalBunga = nominalAccruedBunga
        }

        if (instrument.dayBase == 'BASE_ACT_360') {
            kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
            var timeDiff = (new Date(kuponNextDate).getTime() - kuponLastDate) / 1000
            daysCount = Math.floor(timeDiff / (86400))
            nominalAccruedBunga = daysCount * instrument.rate / 100 * detail.faceValue / 360
            nominalBunga = nominalAccruedBunga
        }

        if (instrument.dayBase == 'BASE_ACT_ACT' || instrument.dayBase == 'BASE_ACT_365') {
            kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
            var timeDiff = (new Date(kuponNextDate).getTime() - kuponLastDate) / 1000
            daysCount = Math.floor(timeDiff / (86400))
            nominalAccruedBunga = daysCount * instrument.rate / 100 * detail.faceValue / 365
            nominalBunga = nominalAccruedBunga
        }

        var customGetData = {
            ...data,
            ...detail,
            nominalDeposito: data.totalNominal,
            bungaBerjalan: detail.acruedInterest,
            currencyRate: detail.avgKurs,
            efekBalanceId: data.id,
            nominalBunga: nominalBunga,
            tglPerpanjangan: data.maturityDate,
            tenorPerpanjangan: instrument.tenor,
            tenorType: instrument.tenorTypeId,
            bungaPercent: instrument.rate,
            noBilyetLama: detail.bilyetNo,
            nominalAccruedBunga: nominalAccruedBunga,
            // dayBase: instrument.dayBase,
            // couponLastDate: instrument.couponLastDate,
            // couponNextDate: instrument.couponNextDate
        }

        this.modalRolloverRef.current.show(customGetData)
    }
    modalBilyet = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/${data.id}`)
        var customGetData = {
            oldBilyet: detail.bilyetNo,
            instrumentId: detail.instrumentId,
        }
        this.modalBilyetRef.current.show(customGetData)
    }
    showModal = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avgs/${data.id}`)
        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            `instruments/${detail.instrumentId}`,
            "GET"
        );
        var kuponLastDate = new Date(response.couponLastDate)
        var kuponNextDate = new Date(response.couponNextDate)
        var diffYear = kuponNextDate.getFullYear() - kuponLastDate.getFullYear()
        var diffMount = kuponNextDate.getMonth() - kuponLastDate.getMonth()
        var diffDate = kuponNextDate.getDate() - kuponLastDate.getDate()
        var daysCount
        var interestDiTerima
        if (response.dayBase == 'BASE_30_360') {
            daysCount = 360 * (diffYear) + 30 * (diffMount) + (diffDate)
            interestDiTerima = daysCount * response.rate / 100 * detail.faceValue / 360
        }

        if (response.dayBase == 'BASE_30_365') {
            daysCount = 365 * (diffYear) + 30 * (diffMount) + (diffDate)
            interestDiTerima = daysCount * response.rate / 100 * detail.faceValue / 365
        }

        if (response.dayBase == 'BASE_ACT_360') {
            kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
            var timeDiff = (new Date(kuponNextDate).getTime() - kuponLastDate) / 1000
            daysCount = Math.floor(timeDiff / (86400))
            interestDiTerima = daysCount * response.rate / 100 * detail.faceValue / 360
        }

        if (response.dayBase == 'BASE_ACT_ACT' || response.dayBase == 'BASE_ACT_365') {
            kuponLastDate = new Date(kuponLastDate.setHours(0)).getTime()
            var timeDiff = (new Date(kuponNextDate).getTime() - kuponLastDate) / 1000
            daysCount = Math.floor(timeDiff / (86400))
            interestDiTerima = daysCount * response.rate / 100 * detail.faceValue / 365
        }

        var customGetData = {
            ...data,
            ...detail,
            nominalDeposito: data.totalNominal,
            ratePenempatan: data.rate,
            bungaBerjalan: interestDiTerima,
            kurs: detail.avgKurs,
            efekBalanceId: data.id,
            nominalBungaDiterima: interestDiTerima
        }
        this.modalRef.current.show(customGetData)
    }
    forceRefresh = () => {
        this.dgRef.current.forceRefresh(true)
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Maturity Deposito</h2>
                <Form
                    colCount={1}
                    id={'formFilter'}
                    formData={this.state.filterItem}
                    items={this.filterItem}
                    labelLocation={'left'}
                />
                <DevExpressDataGrid
                    loadAPI='-'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    ref={this.dgRef}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Daftar Maturity"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Monitor Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={400} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing={this.onToolbarPreparing}
                    summaryTotalItem={this.summaryMaturity}

                    // onCellPrepared={this.onCellPrepared}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalBreakDeposito
                    ref={this.modalRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalRolloverMaturity
                    ref={this.modalRolloverRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <ModalNewBilyet
                    ref={this.modalBilyetRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default PortofolioMaturity