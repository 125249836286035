import React, { Component } from 'react'
import { Popup, Form } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import { akunHR, kasUmum, programs, status, statusAktifNonAktif, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';

class FormJurnalModalRiwayatPengisianKasUmum extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            dataMaster:{},
            getUrl : 'transaction-details'
        }

        this.form = [
            {
                itemType: 'group',
                caption: 'Transaksi',
                items: [
                    {
                        dataField:'transactionNo',
                        label:{
                            text:'No Transaksi'
                        }
                    },
                    {
                        dataField:'transactionNo',
                        label:{
                            text:'No Referensi'
                        }
                    },
                    {
                        dataField:'kasUmumId',
                        label:{
                            text:'Kas Umum'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: kasUmum(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'namaKas', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'transactionTypeId',
                        label:{
                            text:'Tipe Transaksi'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: tipeTransaksiSdmUmum(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'transactionTypeName', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'transactionDate',
                        label:{
                            text:'Tanggal Transaksi'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField:'transactionDate',
                        label:{
                            text:'Tanggal Penyelesaian'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField:'transactionDate',
                        label:{
                            text:'Tanggal Pembayaran'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField:'statusId',
                        label:{
                            text:'Status'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: status(),
                            valueExpr: 'id',
                            displayExpr: 'value', // provides display values
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                    {
                        dataField:'transactionAmount',
                        label:{
                            text:'Nominal Transaksi'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        }
                    },
                    {
                        dataField:'totalBiayaBank',
                        label:{
                            text:'Biaya Bank'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        }
                    },
                    {
                        dataField:'totalBiayaTransaksi',
                        label:{
                            text:'Total Biaya & Pajak'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        }
                    },
                    {
                        dataField:'totalNominalTransaction',
                        label:{
                            text:'Net Nominal Transaksi'
                        },
                        editorType: "dxNumberBox",
                        editorOptions: {
                            format: "#,##0.00"
                        }
                    },
                    {
                        dataField:'description',
                        label:{
                            text:'Keterangan'
                        }
                    },
                ]
            }
        ]

        this.dataGridColumn = [
            {
                dataField: 'akunId',
                caption: 'Akun',
                lookup:{
                    dataSource: akunHR(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: (item) => {
                        return item.akunCode + ' - ' + item.akunName
                    },
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'description',
                caption: 'Keterangan'
            },
            {
                dataField: 'db',
                caption: 'DB',
                format: '#,##0.00'
            },
            {
                dataField: 'cr',
                caption: 'CR',
                format: '#,##0.00'
            }
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Tutup",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    retrieveData = (data) => {
        console.log(data);
        this.setState({
            dataMaster: data,
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }

    hide = () => {
        this.setState({
            dataMaster: {},
            popupVisible: false
        })
    }


    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        await this.props.submitData(dataSubmit)
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Riwayat Jurnal'} 
                minWidth={"100vh"}
                minHeight={"40vh"}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formPotongan'}
                                formData={this.state.dataMaster}
                                items={this.form}
                                labelLocation = 'left'
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI={this.state.getUrl}
                                insertAPI='transaction-details'
                                updateAPI='transaction-details'
                                deleteAPI='transaction-details' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}
                                // remoteOperations = {true}

                                useArraySource = {true}
                                ArraySourceData = {[]}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Riwayat Jurnal Detail"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Riwayat Jurnal Detail'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                height={'calc(65vh - 150px)'}
                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </ScrollView>
            </Popup>
        )
    }
}

export default FormJurnalModalRiwayatPengisianKasUmum 