import React, { Component } from "react";
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import { coa } from "dataSource/lookup";
import { confirm } from 'devextreme/ui/dialog'

class ModalInsertAnggaran extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataMaster: {
                actionType: false
            },
            popupVisible: false
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.formRef = React.createRef()

        this.isNew = false

        this.formCoa = [
            {
                dataField: 'coaId',
                label:{
                    text:"Chart of Account",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: coa(this.props.store),
                    valueExpr: 'id',
                    // displayExpr: 'coaName', // provides display values
                    displayExpr: (item) => {
                        return item && item.coaCode + ' - ' + item.coaName
                    }, // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
                // lookup: {
                //     dataSource: coa(this.props.store),
                //     valueExpr: 'id',
                //     displayExpr: (item) => {
                //         return item && item.coaCode + ' - ' + item.coaName
                //     }, // provides display values
                // }
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide = async(e) => {
        var result
        if (this.state.popupVisible === true && !this.isNew) {
            if (typeof e.cancel === 'boolean') {
                e.cancel = true
            }
            var result = await confirm('Apakah anda yakin untuk membatalkan input data ini?', 'Konfirmasi')
        } else {
            result = true
        }
        if (result) {
            if(!this.isNew) {
                if (typeof e.cancel === 'boolean') {
                    e.cancel = false
                }
            }

            this.setState({
                DataMaster: {},
                popupVisible: false,
            })
        }
    }

    submitData = async () => {
        let data = {...this.state.DataMaster};
        this.props.addDataGrid({
            id: data.id,
            coaId: data.coaId
        });
        this.isNew = true
        this.hide();
    }

    retrieveData = (data, type) => {
        this.setState({
            DataMaster: {
                ...data,
                actionType: type
            }
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={'Coa Anggaran'} 
                width={700}
                height={200}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <Form
                colCount={1}
                id={'formCoa'}
                formData={this.state.DataMaster}
                items={this.formCoa}
                labelLocation = 'left'
                ref={this.formRef}
            />
            </Popup>
        )
    }
}

export default ModalInsertAnggaran