import React, { Component } from "react";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { banks, product } from 'dataSource/lookup'
import Form from 'devextreme-react/form'
import { formatDate } from 'plugin/helper'
import httpRequest from "plugin/httprequest";

class InterestDepositoPerBank extends Component {
    constructor(props){
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            filterData: {
                bankId:0,
                date: new Date(this.sysDate ? this.sysDate.dateValue : null),
            }
        }

        this.filterItem = [
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'bankId',
                        label: {
                            text: 'Bank',
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: function (item) {
                                return item && item.bankCode + " - " + item.bankName;
                            },
                            searchEnabled: true,
                            deferRendering: false,
                        }
                    },
                ]
            },
            {
                itemType: 'group',
                colCount: 2,
                items:[
                    {
                        dataField: 'date',
                        label: {
                            text: 'Tanggal'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            openOnFieldClick: true,
                            useMaskBehavior: true,
                        }
                    },
                    {
                        itemType: "button",
                        buttonOptions: {
                            text: "Filter",
                            // type:"default",
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: () => {
                                this.setState({
                                    filterData: this.state.filterData
                                })
                            },
                        },
                        horizontalAlignment: "left"
                    },
                ]
            }
        ]

        this.columns = [
            {
                dataField: 'bilyetNo',
                caption: 'No Bilyet',
            },
            {
                dataField: 'nominal',
                caption: 'Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'rate',
                caption: 'Rate',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'daybase',
                caption: 'Basis Hari',
            },
            {
                dataField: 'tglPenempatan',
                caption: 'Tanggal Penenpatan / Tanggal Perpanjangan',
                dataType:'date',
                format:"dd MMM yyyy"
            },
            {
                dataField: 'tglJatuhTempo',
                caption: 'Tanggal Jatuh Tempo',
                dataType:'date',
                format:"dd MMM yyyy"
            },
            {
                dataField: 'jmlHari',
                caption: 'Jumlah Hari',
            },
            {
                dataField: 'bungaPerHari',
                caption: 'Bunga / Hari',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'totalAccrued',
                caption: 'Total Bunga',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'percentPajak',
                caption: 'Pajak (20%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'totalBungaBersih',
                caption: 'Total Bunga Bersih',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00',
            },
        ]
        
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    loadData = async () => {
        if (this.state.filterData.bankId) {
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_INVESTASI,
                this.props.store,
                `efek-balance-avgs/report-accrued-interest-deposito/${formatDate(this.state.filterData.date)}/${this.state.filterData.bankId}`,
                "GET"
            );
            return 
        }

        return []
        
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Report Accrued Interest Deposito</h2>
                <div className="row">
                    <div className="col-md-5">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.filterData}
                            items={this.filterItem}
                            labelLocation={'left'}
                        />
                    </div>
                </div>
                <DevExpressDataGrid
                    loadAPI={`efek-balance-avgs/report-accrued-interest-deposito/${formatDate(this.state.filterData.date)}/${this.state.filterData.bankId}`}
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}
                    // useArraySource={true}
                    // ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Report Accrued Interest Deposito"}
                    allowExportSelectedData={true}
                    selection={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={20}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    summaryTotalItem={this.sumHasilInvestasi}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default InterestDepositoPerBank