import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { ScrollView } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';

class PembayaranMpBulananCatatan extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {}
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        this.formItem = [
            {
                dataField: 'catatan',
                label: {
                    alignment: 'left',
                    text: 'Catatan'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    height: 200
                },
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Submit',
                    onClick: async() => {
                        var result = await confirm('Apakah Anda Yakin ?', 'Konfirmasi')
                        if(result){
                            await this.submitData()
                        }
                    },
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show(data) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            dataMaster : data
        });
    }
    
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster : {},
            catatan : ''
        });
    }

    submitData = async() => {
        var catatan = this.state.catatan
        var data = this.state.dataMaster
        await this.props.kirim(data,catatan)

        this.hide()
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Catatan Pembayaran'} 
                width={500}
                height={400}
                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <ScrollView height="100%" width="100%">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={1}
                                id={'formData'}
                                formData={this.state}
                                items={this.formItem}
                                labelLocation = "top"
                            />
                        </div>
                    </div>
                </div>
                </ScrollView>
            </Popup>
        )
    }
}

export default PembayaranMpBulananCatatan