import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { productDefault } from 'dataSource/lookup';


class ModalKajian extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            kajian: '',
            kajianContentType: 'text/html',
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.kajianPengajuanRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.toolbarItem = [
            'background', 'bold', 'color', 'italic', 'link', 'image', 'strike', 'subscript', 'superscript', 'underline', 'blockquote', 'header'
            , 'increaseIndent', 'decreaseIndent', 'orderedList', 'bulletList', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'codeBlock'
            , 'variable', 'separator', 'undo', 'redo', 'clear', 'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow', 'insertColumnLeft',
            'insertColumnRight', 'deleteColumn'
        ]
    }

    show(data) {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            kajian: data.kajian,
            kajianContentType: data.kajianContentType
        });
        this.kajianPengajuanRef.current.instance.option('value', this.state.kajian)
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: [],
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'View Kajian'}
                minWidth={'70vh'}
                minHeight={'50vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div id="tabindex-0" className={' mt-2 col-md-12'} style={{ height: "calc(57vh - 70px)" }}>
                            <div className="row">
                                <div className="col-md-12">
                                <HtmlEditor
                                        ref={this.kajianPengajuanRef}
                                        height="calc(100vh - 380px)"
                                        value={this.state.kajian}
                                        valueType={this.state.kajianContentType}
                                        name={'kajian'}
                                        // onValueChanged={this.onValueChanged}
                                        readOnly={true}
                                    >
                                        <MediaResizing enabled={true} />
                                        <Toolbar>
                                            {
                                                this.toolbarItem.map((toolbarItem) => {
                                                    return (
                                                        <Item
                                                            formatName={toolbarItem}
                                                        />
                                                    )
                                                })
                                            }
                                        </Toolbar>
                                    </HtmlEditor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalKajian