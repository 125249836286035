import React, { Component } from 'react'
import Tabs from "devextreme-react/tabs";
import { Popup } from 'devextreme-react/popup';
import { Paging, Column } from 'devextreme-react/data-grid';
import { FileUploader,DataGrid, CheckBox } from 'devextreme-react'
import { branch, grade, unit, jabatan, educationBackground, cities, provinces, tax, countries, maritalStatus, gender, jenisIdentitas, sdmUnit } from 'dataSource/lookup'
import notify from "devextreme/ui/notify";

class UploadKaryawan extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible:false,
            successData: [],
            errorData: [],
            uploadedData: [],
            useHeader:false,
            selectedTabIndex: 0,
        }

        this.show = this.show.bind(this)
        this.fileUploderRef = React.createRef()
        this.hide = this.hide.bind(this)

        this.PopupToolbarItem = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Simpan Data',
                onClick: this.submitData.bind(this)
            },
            toolbar: 'bottom'
        }, {
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Batal',
                onClick: this.hide.bind(this)
            },
            toolbar: 'bottom'
        }]

        this.TabDataSource = [
            {
              id: 0,
              text: "Success Upload Data"
            },
            {
              id: 1,
              text: "Failed Upload Data"
            },
        ];

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK'
            },
            {
                dataField: 'fullName',
                caption: 'Nama Lengkap'
            },
            {
                dataField: 'birthPlace',
                caption: 'Tempat Lahir'
            },
            {
                dataField: 'jenisIdentitas',
                caption: 'Jenis Identitas',
                lookup: {
                    dataSource: jenisIdentitas(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                lookup: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }  
            },
            {
                dataField: 'identityNo',
                caption: 'No Identitas',
                dataType: 'number',
            },
            {
                dataField: 'sex',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                },
            },
            {
                dataField: 'email',
                caption: 'Email'
            },
            {
                dataField: 'numberFamily',
                caption: 'Jumlah Anggota Keluarga'
            },
            {
                dataField: 'identityAddress1',
                caption: 'Alamat Identitas Baris 1'
            },
            {
                dataField: 'phoneNo',
                caption: 'No Telephone'
            },
            {
                dataField: 'identityAddress2',
                caption: 'Alamat Identitas Baris 2'
            },
            {
                dataField: 'mobileNo',
                caption: 'No HP'
                // editorType: 'dxNumberBox',
            },
            {
                dataField: 'merried',
                caption: 'Status Kawin',
                lookup: {
                    dataSource: maritalStatus(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityCountryId',
                caption: 'Negara',
                lookup: {
                    dataSource: countries(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'countryName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'taxId',
                caption: 'Status Pajak',
                lookup: {
                    dataSource: tax(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'taxCode', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'identityProvinceId',
                caption: 'Provinsi',
                lookup: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'taxNo',
                caption: 'NPWP'
            },
            {
                dataField: 'identityCityId',
                caption: 'Kota',
                lookup: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'educationId',
                caption: 'Pendidikan',
                lookup: {
                    dataSource: educationBackground(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'educationName', // provides display values'
                },
            },
            {
                dataField: 'identityPostalCode',
                caption: 'Kode Pos'
            },
            {
                dataField: 'fingerPrintCode',
                caption: 'Kode Fingerprint'
            },
            {
                dataField: 'workingDate',
                caption: 'Tanggal Mulai Kerja',
                lookup: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }  
            },
            {
                dataField: 'activeDate',
                caption: 'Tanggal Aktif',
                lookup: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%'
                }  
            },
            {
                dataField: 'masaKerja',
                caption: 'Masa Kerja (Tahun)'
            },
            {
                dataField: 'jabatanId',
                caption: 'Posisi',
                lookup: {
                    dataSource: jabatan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'jabatanName', // provides display values
                },
            },
            {
                dataField: 'lastUnitId',
                caption: 'Unit Kerja Terakhir',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                },
            },
            {
                dataField: 'unitId',
                caption: 'Unit Kerja',
                lookup: {
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                },
            },
            {
                dataField: 'branchId',
                caption: 'Cabang',
                lookup: {
                    dataSource: branch(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'branchName', // provides display values
                },
            },
            {
                dataField: 'gradeId',
                caption: 'Grade',
                lookup: {
                    dataSource: grade(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'gradeCode', // provides display values
                },
            },
            {
                dataField: 'phdp',
                caption: 'PHDP'
            },
        ]
    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            successData: [],
            errorData: [],
            uploadedData: []
        });

    }

    onValueChanged = (e) => {
        this.setState({
            useHeader:e.value
        })
    }

    onTabSelectionChanged = args => {
        if (args.name === "selectedIndex") {
          this.setState({
            selectedTabIndex: args.value
          });
        }
    };

    onUploadStarted = () => {
    
    }
    
    submitData = () => {
    
    }

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns]

        columns = columns.map(value => {
            var modifiedValue = {...value}
            delete modifiedValue.lookup

            return modifiedValue
        })

        columns.unshift({
            type: 'buttons',
            buttons: [{
                text : 'Error Detail',
                hint : 'Error Detail',
                icon: "warning",
                cssClass: "text-danger", 
                onClick : (e) => {
                    let type = "error";
                    let text = e.row.data.errorMessage.join("\n");

                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                },
            }]
        })
        
        return columns
    }

    render() {
        return (
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Upload Data Karyawan"}
                width={"90vw"}
                height={650}
                toolbarItems={this.PopupToolbarItem}
                >
                    <div className="d-flex flex-row">
                    <div style={{width:"300px"}}>
                        <FileUploader
                        accept={".txt,.xls,.xlsx,.csv"}
                        uploadMode={"useForm"}
                        onValueChanged={this.onUploadStarted.bind(this)}
                        labelText="Upload File Disini"
                        showFileList={false}
                        name={"file"}
                        value={this.state.uploadedData}
                        ref={this.fileUploderRef}
                        />
                    </div>
                    <div className="d-flex flex-row align-items-center mb-2">
                        <CheckBox 
                            value={this.state.useHeader} 
                            onValueChanged={this.onValueChanged}
                        />
                        <span className="ml-2">Use Header</span>
                    </div>
                </div>
            <Tabs
              dataSource={this.TabDataSource}
              selectedIndex={this.state.selectedTabIndex}
              onOptionChanged={this.onTabSelectionChanged}
            />
            <div
              className={
                (this.state.selectedTabIndex !== 0 ? "d-none" : "") +
                " mt-2 text-center"
              }
            >
              <DataGrid
                id={"successDataGrid"}
                showBorders={false}
                dataSource={this.state.successData}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                height={400}
                showScrollbar={"always"}
                // columns={this.columns}
              >
               {
                    this.columns.map((column) => {
                        return <Column 
                            dataField={column.dataField} 
                            caption={column.caption} 
                            lookup={column.lookup} 
                            cellRender={column.cellRender} 
                            alignment={column.alignment || 'left'} 
                            cssClass={column.cssClass}
                            format = {column.format}
                            dataType = {column.dataType}
                            width = {column.width}
                            type = {column.type}
                            buttons = {column.buttons}
                            editorType = {column.editorType}
                            editorOptions = {column.editorOptions}
                            visible = {column.visible}
                            sortOrder = {column.sortOrder}
                            allowEditing = {column.allowEditing}
                            calculateCellValue = {column.calculateCellValue}
                        />
                    })
                }
                <Paging enabled={false} />
              </DataGrid>
            </div>
            <div
              className={
                (this.state.selectedTabIndex !== 1 ? "d-none" : "") +
                " mt-2 text-center"
              }
            >
              <DataGrid
                id={"errorDatagrid"}
                showBorders={false}
                dataSource={this.state.errorData}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                columnMinWidth={50}
                height={400}
                showScrollbar={"always"}
                // columns={this.errorDataGridErrorButtonColumn()}
              >
               {
                    this.errorDataGridErrorButtonColumn().map((column) => {
                        return <Column 
                            dataField={column.dataField} 
                            caption={column.caption} 
                            lookup={column.lookup} 
                            cellRender={column.cellRender} 
                            alignment={column.alignment || 'left'} 
                            cssClass={column.cssClass}
                            format = {column.format}
                            dataType = {column.dataType}
                            width = {column.width}
                            type = {column.type}
                            buttons = {column.buttons}
                            editorType = {column.editorType}
                            editorOptions = {column.editorOptions}
                            visible = {column.visible}
                            sortOrder = {column.sortOrder}
                            allowEditing = {column.allowEditing}
                            calculateCellValue = {column.calculateCellValue}
                        />
                    })
                }
                <Paging enabled={false} />
              </DataGrid>
            </div>
                </Popup>
        )
    }
}

export default UploadKaryawan