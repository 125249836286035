import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url';
import { entityTypes } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import Form from 'devextreme-react/form';
import { alert } from 'devextreme/ui/dialog';
import ArrayStore from 'devextreme/data/array_store';

class AuditLog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            DataForm: {
                service: null,
                entityType: null,
                startDate: new Date(),
                endDate: new Date()
            }
        }
        
        this.getEntity = [];

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        onClick: (e) => {
                            alert(`<div style="max-height:80vh;max-width:80vw;overflow:auto"><pre>${JSON.stringify(JSON.parse(e.row.data.entityValue), null, 2)}</pre><div>`, 'Audit Trail Entity Value')
                        },
                    },
                ],
            },
            {
                dataField: 'action',
                caption: 'Action Type',
            },
            {
                dataField: 'entityId',
                caption: 'Entity ID'
            },
            {
                dataField: 'entityType',
                caption: 'Entity Type',
            },
            {
                dataField: 'entityValue',
                caption: 'Entity Value',
                width: 200
            },
            {
                dataField: 'modifiedBy',
                caption: 'Modified By',
            },
            {
                dataField: 'modifiedDate',
                caption: 'Modified Time',
                dataType: 'date',
                format: 'd MMM yyyy HH:mm:ss',
            },
        ]

        this.FormItem = [
            {
                dataField: 'service',
                label: {
                    text: 'Service',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: process.env.REACT_APP_BACKEND,
                            value: 'Gateway'
                        },
                        {
                            id: process.env.REACT_APP_BACKEND_ACC,
                            value: 'Akuntansi'
                        },
                        {
                            id: process.env.REACT_APP_BACKEND_CORE,
                            value: 'Kepesertaan'
                        },
                        {
                            id: process.env.REACT_APP_BACKEND_HRD,
                            value: 'SDM UMUM'
                        },
                        {
                            id: process.env.REACT_APP_BACKEND_INVESTASI,
                            value: 'Investasi'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: async(e) => {
                        var getEntity = await httpRequest(e.value, this.props.store, `audits/entity/all`, 'GET')
                        let data = [];

                        this.getEntity = getEntity;

                        for (let value of getEntity) {
                            let dataValue = value.split('.');
                            let stringWithSpaces = dataValue[5].split(/(?=[A-Z])/).join(' ');
                            data.push(stringWithSpaces);
                        }

                        let dataEntity = new ArrayStore({
                            data: data,
                            key: 'id'
                        });

                        this.formRef.current.instance.getEditor('entityType').option('dataSource', dataEntity)
                    }
                },
            },
            {
                dataField: 'entityType',
                label: {
                    text: 'Entity Type',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                },
                colSpan: 2
            },
            {
                dataField: 'startDate',
                label: {
                    text: 'Start Date',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'endDate',
                label: {
                    text: 'End Date',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Submit",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        this.dataGridRef.current.forceRefresh(true)
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.formRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    loadData = async (params) => {
        const { service, entityType, startDate, endDate } = this.state.DataForm
    
        let entityTypeParam = [];

        for (let value of this.getEntity) {
            let dataSplit = value.split('.')[5];
            let entitiData = entityType !== null ? entityType.replace(/\s/g, "") : entityType;
            if (dataSplit === entitiData) {
                entityTypeParam.push(value);
            }
        }

        if (service && entityType && startDate && endDate) {
            var data = await httpRequest(service, this.props.store, `audits/entity/changes/get-by-duration-date?endDate=${formatDate(endDate)}&entityType=${entityTypeParam[0]}&size=9999&startDate=${formatDate(startDate)}${'&' + params.slice(1, params.length)}`)
            return data
        } else {
            return []
        }
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Audit Log</h2>
                <Form
                    colCount={6}
                    id={'form'}
                    formData={this.state.DataForm}
                    items={this.FormItem}
                    scrollingEnabled={true}
                    ref={this.formRef}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='audit-log'
                    insertAPI='audit-log'
                    updateAPI='audit-log'
                    deleteAPI='audit-log'

                    backendserver={process.env.REACT_APP_BACKEND}

                    ArraySourceData={this.loadData}
                    useArraySource={true}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Audit Log"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Audit Log'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default AuditLog
