import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import ModalEditFixedAsset from 'components/pages/modal/akutansi/fixedAsset/edit';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ModalUploadFixedAsset from 'components/pages/modal/akutansi/fixedAsset/upload';
import FixedAssetMutation from 'components/pages/modal/akutansi/fixedAsset/mutation';
import httpRequest from 'plugin/httprequest';
import reportRequest, { reportFile } from 'plugin/reportRequest';
import { asetKategoriSdm, assetStatus, subAsetKategoriSdm } from 'dataSource/lookup';

class FixedAsset extends Component {
    constructor(props) {
        super(props)

        this.DataGridRef = React.createRef()
        this.modalEditRef = React.createRef()
        this.modalUploadFixedAssetRef = React.createRef()
        this.modalFixedAssetMutationRef = React.createRef()
        this.assetCategory = []
        this.assetSubCategory = []

        this.columns = [
            // {
            //     type: 'buttons',
            //     width: 100,
            //     buttons: [
            //         'edit',
            //         {
            //             text: 'Edit',
            //             hint: 'Edit',
            //             cssClass: 'text-success',
            //             onClick: (e) => {
            //                 this.showModalEdit(e.row.data)
            //             }
            //         },
            //         {
            //             text: 'Mutasi',
            //             hint: 'Mutasi',
            //             cssClass: 'text-success',
            //             onClick: (e) => {
            //                 this.showModalMutasi(e.row.data)
            //             }
            //         },
            //         {
            //             text: 'Cetak',
            //             hint: 'Cetak',
            //             cssClass: 'text-success',
            //             onClick: (e) => {
            //                 this.print(e.row.data)
            //             }
            //         },
            //     ]
            // },
            {
                dataField: 'assetKode',
                caption: 'Kode Aset Tetap',
            },
            {
                dataField: 'assetName',
                caption: 'Aset Tetap',
            },
            // {
            //     dataField: 'spesifikasi',
            //     caption: 'Deskripsi',
            // },
            {
                dataField: 'tanggalPerolehan',
                caption: 'Tanggal Pembelian',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:'assetCategoryId',
                caption:'Jenis Aset',
                lookup : {
                    dataSource: asetKategoriSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categoryName',
                },
            },
            {
                dataField:'assetCategorySubId',
                caption:'Tipe Aset',
                lookup : {
                    dataSource: subAsetKategoriSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'categorySubName',
                },
            },
            {
                dataField: 'nilaiPerolehan',
                caption: 'Harga Beli',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'amortisasiNominal',
                caption: 'Penyusutan Perbulan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiBuku',
                caption: 'Nilai Buku',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'akumulasiPenyusutan',
                caption: 'Akumulasi Penyusutan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiResidu',
                caption: 'Residu',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'umurEkonomis',
                caption: 'Umur Ekonomis (Bulan)',
                alignment: 'right'
            },
            // {
            //     dataField: 'disposalCode',
            //     caption: 'Kode Disposal',
            // },
            // {
            //     dataField: 'disposalDate',
            //     caption: 'Tanggal Disposal',
            //     dataType: 'date',
            //     format: 'dd-MMM-yyyy',
            // },
            // {
            //     dataField: 'disposalNote',
            //     caption: 'Alasan Disposal',
            // },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: assetStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName'
                }
            },
            {
                dataField: 'createdDate',
                caption: 'Tanggal Input',
                dataType: 'date',
                format: 'd MMM yyyy h:m:s',
            },
            {
                dataField: 'createdBy',
                caption: 'Diinput Oleh',
            },
        ]
    }

    componentWillMount = async() => {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))

        var assetCategory = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-categories', 'GET')
        var assetSubCategory = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-category-subs', 'GET')

        this.assetCategory = assetCategory
        this.assetSubCategory = assetSubCategory
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'upload',
            //         text: 'Upload Aset Tetap',
            //         onClick: (e) => {
            //             this.modalUploadFixedAssetRef.current.show()
            //         },
            //     }
            // },
            // {
            //     location: 'after',
            //     widget: 'dxButton',
            //     options: {
            //         icon: 'plus',
            //         text: 'Tambah Aset Tetap',
            //         onClick: (e) => {
            //             this.showModalInsert()
            //         },
            //     }
            // },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    hint: 'Print to PDF',
                    onClick: (e) => {
                        this.cetak()
                    },
                }
            },
        )
    }
    
    cetak = async () => {
        var newDataFixedAsset = []
        var dataFixedAsset = await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store,`assets?size=9999`)

        for(var data of dataFixedAsset){
            // data.
            data.assetCategoryId = this.assetCategory.find(val => val.id == data.assetCategoryId).categoryName
            data.assetCategorySubId = this.assetSubCategory.find(val => val.id == data.assetCategorySubId).categorySubName

            switch (data.statusId) {
                case 1:
                    data.statusId = "Pengajuan Pengadaan"
                    break;
                case 2:
                    data.statusId = "Aktif"
                    break;
                case 3:
                    data.statusId = "Non Aktif / Rusak"
                    break;
                case 4:
                    data.statusId = "Dalam Perbaikan"
                    break;
                case 5:
                    data.statusId = "Terjual"
                    break;
                case 6:
                    data.statusId = "Disposal"
                    break;

                default:
                    data.statusId = ""
                    break;
            }
            newDataFixedAsset.push(data)
        }

        var data = {
            fixedAssetMutation: newDataFixedAsset
        }

        // console.log(newDataFixedAsset);
        
        reportFile({
            template: { 'shortid': 'BJlHsF7rV9' },
            data: data,
            options: {
                reportName: `Daftar Aset Tetap`
            }
        }, true, `Daftar Aset Tetap.pdf`)
    }
    forceRefresh = () => {
        this.DataGridRef.current.forceRefresh(true)
    }
    showModalEdit = (data = {}) => {
        this.modalEditRef.current.show()
        this.modalEditRef.current.retrieveData(data)
    }
    showModalInsert = () => {
        this.modalEditRef.current.show()
    }

    showModalMutasi = (data) => {
        this.modalFixedAssetMutationRef.current.show(data)
    }

    print = async (data) => {
        var dataDepartemen = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`departments/${data.departmentId}`)

        data.departmentCode = dataDepartemen.departmentCode
        data.departmentName = dataDepartemen.departmentName

        var dataMutasi = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`fixed-asset-mutations/getByFixedAssetId/${data.id}`)
        data.fixedAssetMutation = dataMutasi

        reportFile({
            template: { 'shortid': 'BkxzrwEki_' },
            data: data,
            options: {
                reportName: `aset-tetap-${data.fixedAssetCode}`
            }
        },true,`aset-tetap-${data.fixedAssetCode}`)
    }

    // loadPerAssetType = async (assetType) => {
    //     const dataGroupAsset = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`fixed-asset-groups/findAllByAssetType/${assetType}`)

    //     let result = []
    //     for(const groupAsset of dataGroupAsset){
    //         const dataAsset = await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`fixed-asset/getAllByFixedAssetGroupId/${groupAsset.id}`)

    //         result = [...result,...dataAsset]
    //     }

    //     return result
    // }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title font-weight-bold">Aset</h2>
                <h3 className="sub-title">Aset Tetap</h3>

                <DevExpressDataGrid
                    ref={this.DataGridRef}
                    loadAPI='view-assets'
                    insertAPI='assets'
                    updateAPI='assets'
                    deleteAPI='assets'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Aset Tetap"}
                    allowExportSelectedData={true}
                    selection={'multiple'}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'nilaiPerolehan'
                        ]
                    }

                    // useArraySource={true}
                    // ArraySourceData={this.loadDummyData}
                    remoteOperations={true}
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Aset Tetap'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 262px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ModalEditFixedAsset
                    ref={this.modalEditRef}
                    store={this.props.store}
                    type={'edit'}
                    forceRefresh={this.forceRefresh}
                />

                <ModalUploadFixedAsset store={this.props.store} ref={this.modalUploadFixedAssetRef} forceRefresh={this.forceRefresh}/>
                
                <FixedAssetMutation store={this.props.store} ref={this.modalFixedAssetMutationRef}/>
            </div>
        )
    }
}

export default FixedAsset