import React, { Component } from 'react';
import { Popup,ScrollView,Form,FileUploader } from 'devextreme-react'
import { programs } from 'dataSource/lookup';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import { formatDate, formatUploadFileData, imageSource } from 'plugin/helper';
import httpRequest from 'plugin/httprequest' 
import notify from "devextreme/ui/notify";


class FormPengajuanRekruitmen extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
            DataDocument:{},
            uploadedData:[]
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.DataMaster = [
            {
                dataField: "programId",
                label: {
                    text: "Program",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'program',
                    searchEnabled: true,
                }
            },
            {
                dataField: "kodeRekurtmen",
                label: {
                    text: "Kode Rekruitmen",
                },
            },
            {
                dataField: "headHunter",
                label: {
                    text: "Head Hunter",
                },
            },
            {
                dataField: "tanggalPengajuan",
                label: {
                    text: "Tanggal Pengajuan"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
            {
                itemType:'group',
                colCount:2,
                items:[
                    {
                        dataField: "tanggalPublikasiDari",
                        label: {
                            text: "Tanggal Publikasi Dari"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "tanggalPublikasiSampai",
                        label: {
                            text: "Sampai"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                ]
            },
            
            {
                itemType:'group',
                colCount:2,
                items:[
                    {
                        dataField: "tanggalAdministrasiDari",
                        label: {
                            text: "Tanggal Administrasi Dari"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "tanggalAdministrasiSampai",
                        label: {
                            text: "Sampai"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                ]
            },
            {
                itemType:'group',
                colCount:2,
                items:[
                    {
                        dataField: "tanggalSeleksiDari",
                        label: {
                            text: "Tanggal Seleksi Dari"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "tanggalSeleksiSampai",
                        label: {
                            text: "Sampai"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                ]
            },
            {
                itemType:'group',
                colCount:2,
                items:[
                    {
                        dataField: "tanggalProbisiDari",
                        label: {
                            text: "Tanggal Probisi Dari"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                    {
                        dataField: "tanggalProbisiSampai",
                        label: {
                            text: "Sampai"
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }
                    },
                ]
            },
            {
                dataField: "tanggalPengangkatan",
                label: {
                    text: "Tanggal Pengangkatan"
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                }
            },
        ]

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`],state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`namaDokumen`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    show() { 
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            DataMaster:{},
            DataDocument:{}
        })
    }

    retrieveData = async(data) => {   
        this.setState({
            DataMaster: data,
            DataDocument: data,
        })
    }

    submitData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.tanggalAdministrasiDari = formatDate(DataMaster.tanggalAdministrasiDari)
            DataMaster.tanggalAdministrasiSampai = formatDate(DataMaster.tanggalAdministrasiSampai)
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)
            DataMaster.tanggalPengangkatan = formatDate(DataMaster.tanggalPengangkatan)
            DataMaster.tanggalProbisiDari = formatDate(DataMaster.tanggalProbisiDari)
            DataMaster.tanggalProbisiSampai = formatDate(DataMaster.tanggalProbisiSampai)
            DataMaster.tanggalPublikasiDari = formatDate(DataMaster.tanggalPublikasiDari)
            DataMaster.tanggalPublikasiSampai = formatDate(DataMaster.tanggalPublikasiSampai)
            DataMaster.tanggalSeleksiDari = formatDate(DataMaster.tanggalSeleksiDari)
            DataMaster.tanggalSeleksiSampai = formatDate(DataMaster.tanggalSeleksiSampai)
            DataMaster.statusId = 1

            var loadApi = 'rekurtmen'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Input Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);  
        }
    }

    updateData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.tanggalAdministrasiDari = formatDate(DataMaster.tanggalAdministrasiDari)
            DataMaster.tanggalAdministrasiSampai = formatDate(DataMaster.tanggalAdministrasiSampai)
            DataMaster.tanggalPengajuan = formatDate(DataMaster.tanggalPengajuan)
            DataMaster.tanggalPengangkatan = formatDate(DataMaster.tanggalPengangkatan)
            DataMaster.tanggalProbisiDari = formatDate(DataMaster.tanggalProbisiDari)
            DataMaster.tanggalProbisiSampai = formatDate(DataMaster.tanggalProbisiSampai)
            DataMaster.tanggalPublikasiDari = formatDate(DataMaster.tanggalPublikasiDari)
            DataMaster.tanggalPublikasiSampai = formatDate(DataMaster.tanggalPublikasiSampai)
            DataMaster.tanggalSeleksiDari = formatDate(DataMaster.tanggalSeleksiDari)
            DataMaster.tanggalSeleksiSampai = formatDate(DataMaster.tanggalSeleksiSampai)
            DataMaster.statusId = 1

            var loadApi = 'rekurtmen'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "PUT",{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Input Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 3000); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 3000);  
        }
    }

    render() {
        return (
            <React.Fragment>
            <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={"Form Rekruitmen"}
                width={"40vw"}
                height={"60vh"}
                toolbarItems={this.props.action == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <ScrollView height="100%" width="100%">
                        <div className="container-fluid">
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                formData={this.state.DataMaster}
                                items={this.DataMaster}
                                labelLocation = 'left'
                            />
                            <FileUploader
                                disabled={this.props.action == 'view' ? true : false}
                                accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                                uploadMode={"useForm"}
                                onValueChanged={this.onUploadStarted.bind(this)}
                                labelText="Upload File Here"
                                showFileList={false}
                                name={"dokumen"}
                                value={this.state.uploadedData}
                                ref={this.fileUploderRef}
                            />
                                <div className="row">
                                    <label className="col-2">File Name:</label>
                                    <label className="col-4">{ this.state.DataDocument.namaDokumen}</label>
                                </div>
                                <div className="col-md-4 text-right">
                                    <button onClick={() => this.previewFile(this.state.DataDocument,'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.namaDokumen ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Preview
                                    </button>
                                </div>
                        </div>
                    </ScrollView>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormPengajuanRekruitmen