import React, { Component } from 'react'
import { ScrollView,Popup,Form } from 'devextreme-react';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";
import { formatDate } from 'plugin/helper';

class FormModalGenerateJurnalMtm extends Component {
    constructor(props){
        super(props)
        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            popupVisible: false,
            DataMaster: {
                tanggalTransaksi:new Date(this.systemDate ? this.systemDate.dateValue : null),
            },
            newData:false,
        }

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Proses',
                    onClick: this.prosesData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField:'lastGenerateMtmDate',
                label:{
                    text:'Generate Transaksi Terakhir'
                },
                editorType:'dxDateBox',
                editorOptions:{
                    displayFormat:'dd MMM yyyy',
                    readOnly:true
                },
            },
            {
                dataField:'tanggalTransaksi',
                label:{
                    text:'Tanggal Transaksi'
                },
                editorType:'dxDateBox',
                editorOptions:{
                    displayFormat:'dd MMM yyyy',
                    readOnly:true
                },
            },
            {
                itemType: 'group',
                name:'hargaPenutupan',
                caption:'Harga/Nab Penutupan',
                items: [
                    {
                        dataField: 'saham',
                        label: {
                            text: 'Saham'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'reksadana',
                        label: {
                            text: 'Reksadana'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'etf',
                        label: {
                            text: 'ETF'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'sbn',
                        label: {
                            text: 'SBN'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'obligasiKor',
                        label: {
                            text: 'Obligasi Korporasi'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                    {
                        dataField: 'eba',
                        label: {
                            text: 'EBA'
                        },
                        editorOptions:{
                            readOnly:true
                        },
                    },
                ]
            },
        ]

        this.resultGenerate = {}

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()
    }

    generateMtm = async () => {
        var loadAPI = `instrument-performances/get-status-performance-each-category-by-priceDate/${formatDate(this.state.DataMaster.tanggalTransaksi)}`

        var response = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadAPI,
            "GET"
        );
        
        this.resultGenerate = response
        var DataMaster = {...this.state.DataMaster}
        DataMaster.lastGenerateMtmDate = this.resultGenerate.lastGenerateMtmDate
        DataMaster.saham = this.resultGenerate.sahamAvailable == 0 ? "Tidak Tersedia" : "Tersedia"
        DataMaster.reksadana = this.resultGenerate.reksaAvailable == 0 ? "Tidak Tersedia" : "Tersedia"
        DataMaster.etf = this.resultGenerate.etfAvailable == 0 ? "Tidak Tersedia" : "Tersedia"
        DataMaster.obligasiKor = this.resultGenerate.bondAvailable == 0 ? "Tidak Tersedia" : "Tersedia"
        DataMaster.eba = this.resultGenerate.ebaAvailable == 0 ? "Tidak Tersedia" : "Tersedia"
        DataMaster.sbn = this.resultGenerate.sbnAvailable == 0 ? "Tidak Tersedia" : "Tersedia"
        this.setState({
            DataMaster:DataMaster
        })

        return response
        
    }

    prosesData = async () => {
        try {
            if (this.state.DataMaster.saham == "Tersedia" || this.state.DataMaster.reksadana == "Tersedia" || 
                this.state.DataMaster.etf == "Tersedia" || this.state.DataMaster.obligasiKor == "Tersedia" || this.state.DataMaster.eba == "Tersedia" ||
                this.state.DataMaster.sbn == "Tersedia") {
                var loadAPI = `transaction/generate-transaksi-mtm`
    
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,loadAPI,"POST",{
                    values: {}
                });
                if (response) {
                    this.hide()
                    this.props.forceRefresh()
                }
                notify({ message: 'Proses Sukses!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }else{
                notify({ message: 'Generate tidak tersedia!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
            }
        } catch (e) {
            console.log(e)
            notify({ message: 'Terjadi Kesalahan System', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    newData = (newData) => {
        this.setState({
          newData : newData
        })
    }

    retrieveData = (data) => {
        
        this.setState({
            DataMaster: data,
        })
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            DataMaster: {
                tanggalTransaksi:new Date(this.systemDate ? this.systemDate.dateValue : null),
                saham:null,
                reksadana:null,
                etf:null,
                obligasiKor:null,
                eba:null,
                sbn:null,
            },
        })
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'45vw'}
                    height="42vh"
                    title={`Generate Transaksi Mark To Market`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={1}
                            id={'dataMaster'}
                            formData={this.state.DataMaster}
                            items={this.dataMaster}
                            labelLocation={"left"}  
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalGenerateJurnalMtm