import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import notify from 'devextreme/ui/notify';
import { currency, kjpp, productDefault } from 'dataSource/lookup';
import ModalValuasiProperti from 'components/pages/modal/investasi/aset-non-portfolio/valuasi/properti/index';
import ModalInvoiceValuasiProperti from 'components/pages/modal/investasi/aset-non-portfolio/valuasi/properti/invoice';
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';

class ValuasiProperti extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dataMaster: {}
        }

        this.modalValuasiPropertiRef = React.createRef()
        this.modalJurnalRef = React.createRef()
        this.dgRef = React.createRef()

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Edit',
                        hint: 'Edit',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            data = {
                                catatanValuasi: data.valuasiNote,
                                categoryId: 8,
                                currencyId: data.currencyId,
                                currencyRate: data.currencyRate,
                                fileDokumen: data.fileDokumen,
                                fileDokumenContentType: data.fileDokumenContentType,
                                kjpp: data.kjpp,
                                noValuasi: data.valuasiNo,
                                productId: data.productId,
                                tanggalAkhirPenilaian: data.tanggalAkhir,
                                tanggalMulaiPenilaian: data.tanggalMulai,
                                tanggalTransaksi: data.transactionDate,
                                valuasiTahun: data.valuasiTahun,
                                id: data.id,
                                valuasiTanggal: data.valuasiTanggal
                            }
                            this.showModalValuasiProperti('edit', data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            // this.showModalValuasiProperti('edit', e.row.data)
                        }
                    },
                    {
                        text: 'Proses',
                        hint: 'Proses',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.prosesValuasiProperti(e.row.data)
                        }
                    },
                    {
                        text: 'View Jurnal',
                        hint: 'View Jurnal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalJurnal(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
            },
            {
                dataField: 'valuasiTanggal',
                caption: 'Tanggal Valuasi',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'valuasiNo',
                caption: 'No. Valuasi',
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'kjpp',
                caption: 'KJPP',
                lookup: {
                    dataSource: kjpp(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'kjppName'
                }
            },
            {
                dataField: 'unitAmount',
                caption: 'Jumlah Unit',
            },
            {
                dataField: 'bookValue',
                caption: 'Total Nilai Buku',
            },
            {
                dataField: 'totalNilaiWajar',
                caption: 'Total Nilai Wajar',
            },
            {
                dataField: 'totalSpi',
                caption: 'Total SPI',
            },
            {
                dataField: 'valuasiNote',
                caption: 'Keterangan',
            },
            {
                dataField: 'proceed',
                caption: 'Proses',
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Transaksi',
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalValuasiProperti('add')
                    },
                }
            },
        )
    }

    prosesValuasiProperti = async (data) => {
        var prosesData = {
            categoryId: data.categoryId,
            currencyId: data.currencyId,
            currencyRate: data.currencyRate,
            fileDocumentId: data.fileDocumentId,
            fileDokumen: data.fileDokumen,
            fileDokumenContentType: data.fileDokumenContentType,
            id: data.id,
            kjpp: data.kjpp,
            proceed: true,
            productId: data.productId,
            tanggalAkhir: data.tanggalAkhir,
            tanggalMulai: data.tanggalMulai,
            transactionDate: data.transactionDate,
            valuasiNo: data.valuasiNo,
            valuasiNote: data.valuasiNote,
            valuasiTahun: data.valuasiTahun,
            valuasiTanggal: data.valuasiTanggal
        }
        if(prosesData){
            try {
                var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, 'efek-valuasis/proses-valuasu-properti', 'POST', {values: prosesData})
                if(response){
                    notify({ message: 'Data berhasil di proses!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    this.forceRefresh()
                }
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    showModalValuasiProperti = (type, data) => {
        if (type == 'add') {
            data = {
                noValuasi: 'AUTO'
            }
        }
        this.modalValuasiPropertiRef.current.show(type, data)
    }

    showModalJurnal = async (data) => {
        return false
        var transactionId = data.id
        var dataTransaction = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-jurnal-dtls/report-transaksi-portofolio-jurnal-detail?transactionId=${transactionId}`, 'GET')
        var result = []
        for (var transaksi of dataTransaction) {
            var getData = {
                description: transaksi.description,
                currencyId: transaksi.currencyId,
                akunName: transaksi.akunName,
                db: transaksi.db,
                cr: transaksi.cr,
                transactionJurnalId: transaksi.transactionJurnalId,
            }
            result.push(getData)
        }
        this.modalJurnalRef.current.show(result)
    }

    forceRefresh = () => {
        this.dgRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Valuasi Properti</h2>

                <DevExpressDataGrid
                    ref={this.dgRef}
                    loadAPI='efek-valuasis?categoryId.in=8'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Valuasi Properti"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Valuasi Properti'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <ModalValuasiProperti
                    ref={this.modalValuasiPropertiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}

export default ValuasiProperti