import React, { Component } from 'react'
import { Form, Button, DataGrid, FileUploader, CheckBox } from 'devextreme-react';
import Popup from 'devextreme-react/popup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ScrollView from 'devextreme-react/scroll-view';
import { imageSource } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';

class ModalDetailTransaksiProperti extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            formData: {},
            popupVisible: false,
        }
        this.modalPreviewPdfRef = React.createRef()
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'instrumentCode'
            },
            {
                name: 'quantity',
                column: 'quantity',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'amountTrs',
                column: 'amountTrs',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
            {
                name: 'amountNet',
                column: 'amountNet',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
        ]
        this.columns = [
            {
                dataField: 'instrumentName',
                caption: 'Properti',
            },
            {
                dataField: 'settleDate',
                caption: 'Tanggal Settle',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'paymentDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'quantity',
                caption: 'Quantity',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'price',
                caption: 'Price',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'amountTrs',
                caption: 'Nilai Transaksi',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'amountNet',
                caption: 'Nilai Transaksi Bersih',
                dataType: 'number',
                format: '#,##0.00',
                alignment: 'right',
            },
            {
                dataField: 'description',
                caption: 'Deskripsi',
            },
        ]
        this.formItem = [
            {
                dataField: 'noKonfirmasi',
                label: {
                    alignment: 'left',
                    text: 'No. Konfirmasi'
                },
            },
            {
                dataField: 'transactionDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Transaksi'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                },
            },
            {
                dataField: 'transactionName',
                label: {
                    alignment: 'left',
                    text: 'Tipe Transaksi'
                },
            },
        ]
        this.PopupRef = React.createRef()
        this.datagridRef = React.createRef()
        this.formRef = React.createRef()
        this.data = []
    }
    show = async (data) => {
        var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/detail-konfirmasi-properti/${data.konfirmasiId}`, 'GET')
        var responseKonfirmasi = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `transaction-confirmations/${data.konfirmasiId}`, 'GET')
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0,
            formData: {
                noKonfirmasi: responseKonfirmasi.referensiNo,
                transactionDate: responseKonfirmasi.transactionDate,
                transactionName: response[0].transactionName,
            },
            fileInstruksi: responseKonfirmasi.fileInstruksi,
            fileInstruksiContentType: responseKonfirmasi.fileInstruksiContentType
        });
        this.data = response
        this.forceRefresh()
    }
    hide = () => {
        this.setState({
            popupVisible: false,
        });
        this.data = []
    };
    forceRefresh = () => {
        this.datagridRef.current.forceRefresh(true)
    };
    previewFile = async () => {
        var src = imageSource(this.state.fileInstruksiContentType, this.state.fileInstruksi)
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }
    loadData = () => {
        return this.data
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Data Detail Transaksi Properti'}
                    width={'60vw'}
                    height={'60vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'form'}
                                        formData={this.state.formData}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation={'left'}
                                        readOnly={true}
                                    />
                                </div>
                                <div className='col-md-6'>
                                    <button onClick={() => this.previewFile()} className={`btn btn-light btn-sm border rounded ${this.state.fileInstruksi ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                        Buka File
                                    </button>
                                </div>
                                <div className='col-md-12' style={{ paddingTop: '20px' }}>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}
                                        loadAPI=''
                                        insertAPI=''
                                        updateAPI=''
                                        deleteAPI=''

                                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                                        useArraySource={true}
                                        ArraySourceData={this.loadData}

                                        allowAdding={false}
                                        allowDeleting={false}
                                        allowUpdating={false}

                                        exportExcel={false}
                                        exportFileName={"Data Detail Transaksi Properti"}

                                        allowExportSelectedData={false}

                                        showBorders={true}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Data Detail Transaksi Properti'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={3} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={true} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                        summaryTotalItem={this.summary}
                                        // SummaryConfiguration={this.summary}
                                        // onToolbarPreparing = {this.onToolbarPreparing}

                                        height={'calc(60vh - 246px)'}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                    <ModalPreviewPdf
                                        ref={this.modalPreviewPdfRef}
                                        store={this.props.store}
                                    />
                                </div>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalDetailTransaksiProperti