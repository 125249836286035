import React, { Component } from 'react'
import { Form } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { addURL } from 'redux/actions/url';
import PembayaranMpUploadSkModal from 'components/pages/modal/kepesertaan/pembayaranMp/bulanan/uploadSkBayar';
import PembayaranMpPembayaranModal from 'components/pages/modal/kepesertaan/pembayaranMp/comp/showModal';
import { months, product } from 'dataSource/lookup';
import PembayaranMpGeneratePembayaranModal from 'components/pages/modal/kepesertaan/pembayaranMp/bulanan/generatePembayaranModal';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert'
import { showLoading } from 'redux/actions/loading';
import { alert } from 'devextreme/ui/dialog';
import PembayaranMpBulananCatatan from 'components/pages/modal/kepesertaan/pembayaranMp/bulanan/catatan';

class PengajuanPembayaranMpBulanan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formFilter: {},
            getUrl : 'view-pembayaran-mp-bulans?mpPaymentTypeId.in=3&statusId.in=1'
        }

        this.dataGridListRef = React.createRef()
        this.pembayaranMpGeneratePembayaranModalRef = React.createRef()
        this.pembayaranMpDetailModalRef = React.createRef()
        this.pembayaranMpUploadSkModalRef = React.createRef()
        this.popupCatatan = React.createRef()

        this.selectedId = []

        this.filterItem = [
            {
                dataField: 'produk',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location: "left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                    width: '100%',
                    onValueChanged: (data) => {
                        var loadAPI
                        if(data.value){
                            loadAPI = `view-pembayaran-mp-bulans?mpPaymentTypeId.in=3&statusId.in=1&productId.in=${data.value}`
                        }else{
                            loadAPI = 'view-pembayaran-mp-bulans?mpPaymentTypeId.in=3&statusId.in=1'
                        }
                        this.setState({
                            getUrl: loadAPI
                        })
                    }
                }
            },
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-info',
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data)
                        }
                    },
                    {
                        text: 'Detail',
                        hint: 'Detail',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalDetail('view', e.row.data)
                        }
                    },
                    {
                        text: 'Hapus',
                        hint: 'Hapus',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Menghapus Pembayaran Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.hapus(e.row.data)                             
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                    {
                        text: 'Unggah SK',
                        hint: 'Unggah SK',
                        cssClass: 'text-info',
                        onClick: (e) => {
                            this.showModalUploadSk(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'productId',
                caption: 'Product',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "productCode", // provides display values
                },
            },
            {
                dataField: 'bulan',
                caption: 'Bulan',
                lookup: {
                    dataSource: months(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value", // provides display values
                },
            },
            {
                dataField: 'tahun',
                caption: 'Tahun',
            },
            {
                dataField: 'totalPensiun',
                caption: 'Total Pensiunan',
                alignment: 'right',
                format: "#,##0",
            },
            {
                dataField: 'summaryNominal',
                caption: 'Total Pembayaran',
                alignment: 'right',
                format: "#,##0",
            },
            {
                dataField: 'paymentDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'resume',
                caption: 'Resume',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bankBayar',
                        caption: 'Bank Bayar'
                    },
                    {
                        dataField: 'totalBiayaBank',
                        caption: 'Total Biaya Bank',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'totalFeeBank',
                        caption: 'Total Fee Bank',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                    {
                        dataField: 'resumeRetur',
                        caption: 'Retur',
                        alignment: 'right',
                        format: "#,##0.00",
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'AR/AP Status',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Maker'
                        },
                        {
                            id: 2,
                            value: 'Checker'
                        },
                        {
                            id: 3,
                            value: 'Approval'
                        },
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                },
            },
            {
                dataField: 'description',
                caption: 'Keterangan'
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Generate Pembayaran',
                    onClick: (e) => {
                        this.showModalDetail('generate', {})
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Kirim',
                    elementAttr: { class: "bg-dapen" },
                    onClick: () => {
                        this.kirimSelectedData(this.selectedId)
                    },
                }
            }
        )
    }

    showModalDetail = (type, data) => {
        if (type === 'generate') {
            this.pembayaranMpGeneratePembayaranModalRef.current.show()
            // console.log(data);
        }
        if (type === 'view') {
            this.pembayaranMpDetailModalRef.current.show()
            this.pembayaranMpDetailModalRef.current.retrieveDataBulanan(data)
        }
    }

    showModalCatatan = (data) => {
        this.popupCatatan.current.show(data)
    }

    showModalUploadSk = (data) => {
        this.pembayaranMpUploadSkModalRef.current.show()
        this.pembayaranMpUploadSkModalRef.current.retrieveData(data)
    }

    kirim = async (data,catatan) => {
        try {
            var loadAPI = `pembayaran-mps/${data.id}`
            var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

            var dataUpdate = getPembayaranMp
            dataUpdate.description = catatan
            dataUpdate.statusId = 2

            var loadAPIUpdate = 'pembayaran-mps'
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIUpdate, 'PUT', {
                key: dataUpdate.id,
                values: dataUpdate
            },data)

            var loadApiPost = `pembayaran-mps/pemeriksaanMpBulanan/${getPembayaranMp.id}`;
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApiPost, 'POST', {
                values: {}
            });
            
            var text = `Data berhasil dikirim!`
            var type = 'success'
            this.forceRefresh()
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
    }

    hapus = async(data) => {
        let store = this.props.store
        store.dispatch(showLoading(true))
        try {            
            var loadAPIDetail = `pembayaran-mp-detils/deleteByPembayaranMpId`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIDetail, 'DELETE', data.id)
            
            var loadAPIMaster = `pembayaran-mps`
            await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store, loadAPIMaster, 'DELETE', data.id)
    
            var message = `Data berhasil dihapus!`
            var title = 'Berhasil'
        } catch (e) {
            var message = e
            var title = 'Gagal'
            store.dispatch(showLoading(false))
        }
        store.dispatch(showLoading(false))
        await alert(message, title)
        this.forceRefresh()

    }
    forceRefresh = () =>{
        this.dataGridListRef.current.forceRefresh(true)
    }

    kirimSelectedData = async(selectedId) => {
        if(selectedId.length !== 0){
            confirmAlert({
                title: 'Konfirmasi',
                message: "Apakah Anda Yakin Ingin Mengirim Pembayaran Ini?",
                buttons: [
                  {
                    label: 'Iya',
                    onClick:  async() => {
                        try {
                            for(var value of selectedId){
                                var loadAPI = `pembayaran-mps/${value}`
                                var getPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')
                                
                                var dataUpdate = getPembayaranMp
                                dataUpdate.statusId = 2
                                
                                var loadAPIUpdate = 'pembayaran-mps'
                                await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIUpdate, 'PUT', {
                                    key: dataUpdate.id,
                                    values: dataUpdate
                                },getPembayaranMp)
                            }
                            
                            var text = `Data berhasil dikirim!`
                            var type = 'success'
                            this.forceRefresh()
                        } catch (e) {
                            console.log(e)
                            var text = e
                            var type = 'error'
                        }
                        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
                    }
                  },
                  {
                    label: 'Tidak',
                    onClick: () => {

                    }
                  },
                ]
            });
        }else{
            var text = `Silahkan centang data terlebih dahulu!`
            var type = 'error'
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }
    }

    onSelectionChanged = (e) => {
        this.selectedId = e.selectedRowKeys
    }

    // loadDataGrid = async(param) => {
    //     var loadAPI = 'simple-pembayaran-mps/getSimpleAllByMpPaymentType/3/1'
    //     var getMasterPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

    //     if(getMasterPembayaranMp.length !== 0){
    //         this.pembayaranMpId = getMasterPembayaranMp[0].id
    //         var loadAPIDetail = `pembayaran-mp-detils/getByMpMasterId/${getMasterPembayaranMp[0].id}${param}`
    //         var getDetailPembayaranMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIDetail, 'GET')

    //         return getDetailPembayaranMp
    //     }else{
    //         return []
    //     }
    // }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Perencanaan Pembayaran MP Bulanan</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridListRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='pembayaran-mps'
                    updateAPI='pembayaran-mps'
                    deleteAPI='pembayaran-mps'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}
                    remoteOperations = {true}
                    
                    // useArraySource={true}
                    // ArraySourceData={this.loadDataGrid}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Perencanaan Pembayaran MP Bulanan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Perencanaan Pembayaran MP Bulanan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}
                    onSelectionChanged={this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <PembayaranMpGeneratePembayaranModal
                    ref={this.pembayaranMpGeneratePembayaranModalRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                    type={'Bulanan'}
                />
                <PembayaranMpPembayaranModal
                    ref={this.pembayaranMpDetailModalRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                    type={'Bulanan'}
                />
                <PembayaranMpUploadSkModal
                    ref={this.pembayaranMpUploadSkModalRef}
                    store={this.props.store}
                    forceRefresh = {this.forceRefresh}
                />
                <PembayaranMpBulananCatatan
                    ref={this.popupCatatan}
                    store={this.props.store}
                    kirim = {this.kirim}
                />
            </div>
        )
    }
}

export default PengajuanPembayaranMpBulanan