import React, { Component } from 'react'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import FormRiwayatJabatan from './comp/formRiwayatJabatan';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { Form } from "devextreme-react";
import { statusPegawai } from 'dataSource/lookup'
import { addURL } from 'redux/actions/url';

class DaftarRiwayatPegawai extends Component {
    constructor(props){
        super(props)

        this.state = {  
            selectedTabIndex: 0,
            formDataMain: {}
        }

        this.formItemsMain = [
            {
                dataField: 'namaPegawai',
                label: {
                    text: 'Nama Pegawai',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'nomorInduk',
                label: {
                    text: 'Nomor Induk',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
            },
            {
                dataField: 'statusPegawai',
                label: {
                    text: 'Status Pegawai',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: statusPegawai(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    // searchEnabled: true,
                    // deferRendering: false
                }
            },
            {
                dataField: 'kodeSidikJari',
                label: {
                    text: 'Kode Sidik Jari',
                    alignment: "left",
                    location:"left"
                },
                editorType: 'dxTextBox',
                readOnly : true
            },
        ]

        this.columnsMainData = [
            {
                dataField: 'id',
                caption: 'No.',
            },
            {
                dataField: 'namaJabatan',
                caption: 'Nama Jabatan',
            },
            {
                dataField: 'nomorSK',
                caption: 'Nomor SK',
            },
            {
                dataField: 'tanggalSK',
                caption: 'Tanggal SK',
                dataType: 'date',  
                format: 'dd-MMM-yyyy',
            },
            {
                dataField: 'tunjanganJabatan',
                caption: 'Tunjangan Jabatan',
            },
            {
                dataField: 'status',
                caption: 'Status',
                lookup: {
                    dataSource: [
                        {
                            id: 'A',
                            value: 'Aktif'
                        },
                        {
                            id: 'N',
                            value: 'Non-Aktif'
                        },
                    ],
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    value: 'id'
                }
            },
        ]

        this.TabDataSource = [
            {
                id : 0,
                text: 'Riwayat Jabatan'
            },
            {
                id : 1,
                text: 'Riwayat Golongan'
            },
            {
                id : 2,
                text: 'Riwayat Gaji Pokok'
            },
            {
                id : 3,
                text: 'Riwayat Insentif'
            },
            {
                id : 4,
                text: 'Riwayat Pelatihan'
            },
        ]  

        this.formRiwayatJabatanRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }
    
    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Riwayat Pegawai</h3>

                <div className="my-5">
                    <Form
                        colCount={4}
                        id={'form'}
                        formData={this.state.formDataMain}
                        items={this.formItemsMain}
                        scrollingEnabled={true}
                        ref={this.formRef}
                    />
                </div>

                <DevextremeTab
                    dataSource={this.TabDataSource}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div className={(this.state.selectedTabIndex !== 0 ? 'd-none' : '') + ' mt-2 text-center' } style={{height: '100%'}}>
                    <FormRiwayatJabatan store={this.props.store} ref={this.formRiwayatJabatanRef}/>
                </div>

                <DevExpressDataGrid
                    loadAPI='daftar-riwayat-pegawais'
                    insertAPI='daftar-riwayat-pegawais'
                    updateAPI='daftar-riwayat-pegawais'
                    deleteAPI='daftar-riwayat-pegawais' 

                    backendserver={process.env.REACT_APP_BACKEND_ACC}

                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    exportExcel={true}
                    exportFileName={"Daftar Pegawai"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Riwayat Pegawai'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columnsMainData} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default DaftarRiwayatPegawai
