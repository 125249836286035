import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import ScrollView from 'devextreme-react/scroll-view';
import Box, { Item } from 'devextreme-react/box';
import { formatUploadFileData } from 'plugin/helper'

class FormModalPenerimaanPemesanan extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster: {},
            popupVisible:false,
            fileUploadReceipt:[],
        }

        this.form = [
            {
                dataField:'noPenerimaan',
                label:{
                    text:'No Penerimaan'
                }
            },
            {
                dataField:'noPemesanan',
                label:{
                    text:'No Pemesanan'
                }
            },
            {
                dataField:'noPengajuan',
                label:{
                    text:'No Pengajuan'
                }
            },
            {
                dataField:'jumlahPemesanan',
                label:{
                    text:'Jumlah Pemesanan'
                }
            },
            {
                dataField:'noDokumen',
                label:{
                    text:'No Dokumen'
                }
            },
            {
                dataField:'tanggalPenerimaan',
                label:{
                    text:'Tanggal Penerimaan'
                }
            },
            {
                dataField:'jumlahDiterima',
                label:{
                    text:'Jumlah Diterima'
                }
            },
            {
                dataField:'diterimaOleh',
                label:{
                    text:'Diterima Oleh'
                }
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: () => {
                    console.log('success')
                }
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataMaster = { ...this.state.DataMaster };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataMaster[`${fieldName}Name`] = files.name;
            DataMaster[fieldName] = formattedFileData.base64data;
            DataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

            this.setState({
                DataMaster: DataMaster
            });
        };

        fr.readAsDataURL(files);
        }
    }

    retrieveData = (data, type) => {
        this.setState({
            DataMaster:data
        })
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster: {},
            popupVisible: false
        })
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Edit Penerimaan'} 
                width={700}
                height={500}
                toolbarItems={this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
                <Form
                    colCount={1}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
                <Box direction={"row"} width={"100%"}>
                    <Item ratio={1}>
                        <FileUploader
                            accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                            uploadMode={'useForm'}
                            onValueChanged={this.onUploadStarted.bind(this)} 
                            labelText="File Serah Terima"
                            showFileList={false}
                            name={'file'}
                            value = {this.state.fileUploadReceipt}
                            disabled={false }
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-4">File Name:</label>
                            <label className="col-8">{ this.state.DataMaster.fileName}</label>
                        </div>
                    </Item>
                    <Item ratio={1}>
                        <FileUploader
                            accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                            uploadMode={'useForm'}
                            onValueChanged={this.onUploadStarted.bind(this)} 
                            labelText="File Surat Pengantar"
                            showFileList={false}
                            name={'file'}
                            value = {this.state.fileUploadReceipt}
                            disabled={false }
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-4">File Name:</label>
                            <label className="col-8">{ this.state.DataMaster.fileName}</label>
                        </div>
                    </Item>
                </Box>
            </ScrollView>
            </Popup>
        )
    }
}

export default FormModalPenerimaanPemesanan