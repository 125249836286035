import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormKaryawanDapen from 'components/pages/modal/sdm-umum/kepegawaian/karyawan/dapen/form';
import { dapenProduk, karyawan } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { alert } from 'devextreme/ui/dialog'
import { confirmAlert } from 'react-confirm-alert';

class KaryawanPesertaDapen extends Component {
    constructor(props){
        super(props)

        this.modalDapenRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.selectedId = []
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        onClick: (e) => {
                            this.showModal(e.row.data)
                        }
                    },
                ]
            },
            {
                dataField: 'nik',
                caption: 'NIK',
                lookup: {
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'nik',
                }
            },
            {
                dataField: 'karyawanId',
                caption: 'Nama Lengkap',
                lookup:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr:"fullName",
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                dataField: 'dapen',
                caption: 'Dana Pensiun',
            },
            {
                dataField: 'produkId',
                caption: 'Produk',
                lookup: {
                    dataSource: dapenProduk(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'produkKode',
                }
            },
            {
                dataField: 'phdp',
                caption: 'PHDP',
                format: '#,##0.00'
            },
            {
                dataField: 'status',
                caption: 'Status',
            },
            {
                dataField: 'iuranKaryawan',
                caption: 'Iuran Karyawan',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'iuranNormalKaryawan',
                        caption: 'Normal',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'iuranManfaatLainKaryawan',
                        caption: 'Manfaat Lain',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'iuranPemberiKerja',
                caption: 'Iuran Pemberi Kerja',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'iuranNormalPk',
                        caption: 'Normal',
                        format: '#,##0.00'
                    },
                    {
                        dataField: 'iuranManfaatLainPk',
                        caption: 'Manfaat Lain',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'kepesertaanDate',
                caption: 'Tanggal Kepesertaan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'dapenReffCode',
                caption: 'Kode Refferal Dapen'
            }
        ]
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    showModal = (data = null) => {
        if(data){
            this.modalDapenRef.current.retrieveData(data, false)
        }
        this.modalDapenRef.current.show()
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal()
                    },
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Hapus',
                    onClick: (e) => {
                        this.deleteSelectedData(this.selectedId)
                    },
                }
            },
        )
    }

    deleteSelectedData = async(selectedId) => {
        if(selectedId.length !== 0){
            confirmAlert({
                title: 'Konfirmasi',
                message: "Apakah Anda Yakin Ingin Menghapus Peserta Ini?",
                buttons: [
                    {
                        label: 'Iya',
                        onClick:  async() => {
                            try {
                                for(var value of selectedId){
                                    var deleteAPI = 'karyawan-dapens'
                                    await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, deleteAPI, 'DELETE', value)
                        
                                }
                                await alert('Data Berhasil Dihapus !','Success')
                                this.forceRefresh()
                            } catch (e) {
                                await alert(e,'Failed')
                            }
                        }
                    },
                    {
                        label: 'Tidak',
                        onClick: () => {}
                    },
                ]
            });
            
        }else{
            await alert('Pilih data terlebih dahulu!','Informasi')
        }
    }

    insertData = async(data) => {
        try {
            var postAPI = 'karyawan-dapens'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, postAPI, 'POST', {
                key : data.id,
                values: data
            })

            await alert('Data Berhasil Disimpan !','Success')
            this.forceRefresh()
        } catch (e) {
            await alert(e,'Failed')
        }
    }

    updateData = async(data) => {
        try {
            var updateAPI = 'karyawan-dapens'
            await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, updateAPI, 'PUT', {
                key : data.id,
                values: data
            }, data)

            await alert('Data Berhasil Diubah !','Success')
            this.forceRefresh()
        } catch (e) {
            await alert(e,'Failed')
        }
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    onSelectionChanged = (e) => {
        this.selectedId = e.selectedRowKeys
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Daftar Peserta Dana Pensiun</h2>

                <DevExpressDataGrid
                    ref = {this.dataGridRef}
                    loadAPI='karyawan-dapens'
                    insertAPI='karyawan-dapens'
                    updateAPI='karyawan-dapens'
                    deleteAPI='karyawan-dapens' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Peserta Dana Pensiun"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Karyawan'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing = {this.onToolbarPreparing}
                    onSelectionChanged = {this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormKaryawanDapen
                    ref={this.modalDapenRef}
                    store={this.props.store}
                    insertData = {this.insertData}
                    updateData = {this.updateData}
                />
            </div>
        )
    }
}


export default KaryawanPesertaDapen