import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import httpRequest from 'plugin/httprequest'
import { Redirect } from 'react-router';
import notify from 'devextreme/ui/notify'
import { pensiunType } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';

class ProsesPensiun extends Component {
    constructor(props){
        super(props)
        
        this.columns = [
            {
                dataField: 'no',
                caption: 'No',
                alignment: 'center',
                cellRender: (e) => {
                    return e.rowIndex + 1
                },
                width: "50px"
            },
            {
                dataField: 'fullName',
                caption: 'Nama'
            },
            {
                dataField: 'pensiunType',
                caption: 'Jenis Pensiun',
                lookup: {
                    dataSource: pensiunType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'statusName'
                }
            },
            {
                dataField: 'pensiunDate',
                caption: 'Tanggal Pensiun',
                dataType: "date",
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'status',
                caption: 'Status'
            }
        ]
    }

    loadData = async() => {
        let getData = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'view-peserta-pasifs?size=9999&status.equals=1');

        let arrData = [];

        for (let value of getData){
            let result = {
                id: value.id,
                fullName: value.fullName,
                pensiunType: value.pensiunType,
                pensiunDate: value.pensiunDate,
                status: value.status === 1 ? 'Aktif' : '-'
            }

            arrData.push(result);
        }

        return arrData;
    }

    render(){
        return (
            <div className='container-fluid'>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='view-peserta-pasifs?size=9999&status.equals=1'
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource = {true}
                    ArraySourceData = {this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    height={'auto'}

                    exportExcel={false}
                    exportFileName={"Pengajuan Peserta Pensiun Ditunda"}
                    allowExportSelectedData={false}
                    // selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={5}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Pengajuan Peserta Pensiun Ditunda'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default ProsesPensiun;