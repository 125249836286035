import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import { Form, Button, DataGrid, FileUploader, CheckBox } from 'devextreme-react';
import { Paging, Column } from 'devextreme-react/data-grid';
import { gender, participants, months, kpds, productDefault, rekeningBank, product, mpType } from 'dataSource/lookup';
import { addURL } from 'redux/actions/url';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import { ab2str, yyyymmddToDate, formatNumber, cleanQuote, ddmmyyyyToDate, formatDate } from 'plugin/helper';
import XLSX from 'xlsx'
import Popup from 'devextreme-react/popup';
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { showLoading } from 'redux/actions/loading';
import { confirm } from 'devextreme/ui/dialog';
import ScrollView from 'devextreme-react/scroll-view';
import { alert } from 'devextreme/ui/dialog';

class FormModalGenerateMpSusulan extends Component {
    constructor(props) {
        super(props);

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.state = {
            redirectBack: false,
            uploadedData: [],
            // selectedTabIndex: 0,
            useHeader: false,
            formData: {},
            popupVisible: false,
        }

        this.prevTabIndex = 0

        this.TabDataSource = [
            {
                id: 0,
                text: "Data Sesuai"
            },
            {
                id: 1,
                text: "Data Tidak Sesuai"
            },
        ];

        this.summary = [
            {
                displayFormat: 'Total',
                showInColumn: 'no'
            },
            {
                column: 'mpNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'mpNominal',
                displayFormat: '{0}'
            },
            {
                column: 'mpNominalPengajuan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                name: 'mpNominalPengajuan',
                displayFormat: '{0}'
            },
            {
                name: 'nominalPajak',
                column: 'nominalPajak',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nominalPembayaran',
                column: 'nominalPembayaran',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: (e) => {
                        this.submitData()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                dataField: 'no',
                caption: 'No.',
                cellRender: (e) => {
                    return e.rowIndex + 1
                }
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'mpRekeningName',
                caption: 'Nama Penerima',
            },
            {
                dataField: 'bulan',
                caption: 'Bulan MP',
                lookup: {
                    dataSource: months(),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
            {
                dataField: 'tahun',
                caption: 'Tahun MP',
            },
            {
                dataField: 'mpNominal',
                caption: 'MP Bruto',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'mpNominalPengajuan',
                caption: 'Nominal Susulan',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalPajak',
                caption: 'Pajak',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalPembayaran',
                caption: 'Jumlah Bayar',
                dataType: 'number',
                alignment: 'right',
                format: '#,##0.00'
            },
            {
                dataField: 'susulanThp',
                caption: 'Susulan Tahap',
                alignment: 'right',
            },
            {
                dataField: 'bankName',
                caption: 'Nama Bank',
            },
            {
                dataField: 'mpRekeningNo',
                caption: 'No Rekening',
                alignment: 'right',
            },
            // {
            //     dataField: 'jumlahKeseluruhan',
            //     caption: 'Jumlah',
            // },
        ]

        this.formItem = [
            {
                dataField: 'productId',
                label: {
                    text: 'Produk',
                    alignment: "left",
                    location: 'left',
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: product(this.props.store),
                    displayExpr: function (item) {
                        return item && item.productCode + " - " + item.productName;
                    },
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: async (e) => {
                        try {
                            let data = e.selectedItem;
                            if (data.programId === 1) {
                                let dataMp = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, 'manfaat-pensiun-types', 'GET');
                                let dataMpId = dataMp.filter(value => value.mpCode === 'MP_SSL');
                                // this.formRef.current.instance.updateData({
                                //     mpTypeId: dataMpId[0].mpName
                                // })
                                this.formRef.current.instance.getEditor('mpTypeId').option('dataSource', dataMpId);
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }
            },
            {
                dataField: 'mpTypeId',
                label: {
                    text: 'Tipe MP'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    // dataSource: mpType(this.props.store),
                    dataSource: [],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "mpName", // provides display values
                    // readOnly: true
                }
            },
            {
                dataField: 'dasarNilaiMp',
                label: {
                    text: 'Dasar Nilai MP'
                },
                editorOptions: {
                    value: 'Nominal',
                    readOnly: true
                },
            },
            {
                dataField: 'persentase',
                label: {
                    text: 'Presentasi MP Bulanan'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
            {
                dataField: 'mpNominal',
                label: {
                    text: 'Nominal'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00',
                },
            },
            {
                dataField: 'requestDate',
                label: {
                    text: 'Tanggal Pengajuan'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'paymentDate',
                label: {
                    text: 'Tanggal Bayar'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    width: '100%',
                    openOnFieldClick: true
                }
            },
            {
                dataField: 'description',
                label: {
                    text: 'Keterangan'
                }
            },
        ]

        this.fileUploderRef = React.createRef()
        this.PopupRef = React.createRef()
        this.dataGridSesuaiRef = React.createRef()
        this.dataGridTakSesuaiRef = React.createRef()
        this.formRef = React.createRef()
        this.successData = []
        this.errorData = []

    }

    checkMonth = (monthString) => {
        var dataBulan = [
            {
                id: 1,
                value: 'Januari'
            },
            {
                id: 2,
                value: 'Februari'
            },
            {
                id: 3,
                value: 'Maret'
            },
            {
                id: 4,
                value: 'April'
            },
            {
                id: 5,
                value: 'Mei'
            },
            {
                id: 6,
                value: 'Juni'
            },
            {
                id: 7,
                value: 'Juli'
            },
            {
                id: 8,
                value: 'Agustus'
            },
            {
                id: 9,
                value: 'September'
            },
            {
                id: 10,
                value: 'Oktober'
            },
            {
                id: 11,
                value: 'November'
            },
            {
                id: 12,
                value: 'Desember'
            },
        ]

        for (var data of dataBulan) {
            if (data.value === monthString) {
                return data.id
            }
        }
    }

    onUploadStarted = (e) => {
        if (e.value.length > 0) {
            this.props.store.dispatch(showLoading(true))
        }
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if (fileType === 'xls' || fileType === 'xlsx') {
                    var workbook = XLSX.read(dataArrayBuffer, { type: 'array' });
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)

                    data = dataJSON.map(value => Object.values(value))
                }

                if (fileType === 'csv') {
                    var regex = RegExp(/,/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                        data = data.filter(value => {
                            regex.lastIndex = 0
                            return regex.test(value)
                        }
                        )
                        data = data.map((value, index) => {
                            value = cleanQuote(value)

                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)

                                var splitRes = splitRes.map(splitVal => splitVal.trim())

                                var splitRes = splitRes.map(splitVal => {
                                    if (String(splitVal)) {
                                        return String(splitVal)
                                    } else {
                                        return splitVal
                                    }
                                })

                                return splitRes
                            }
                        })
                    } else {
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                var errorMessage = []
                var sum = 0

                for (var uploadedData of data) {
                    if (Array.isArray(uploadedData)) {
                        errorMessage = []
                        if (uploadedData.length !== 13) {
                            this.props.store.dispatch(showLoading(false));
                            let message = await alert('Salah satu data tidak boleh kosong', 'Error!');

                            if(message) {
                                this.hide();
                                return null;
                            }

                            errorMessage.push('Format file salah')

                            errorData.push({
                                errorMessage: errorMessage,
                                no: uploadedData[0],
                                nik: uploadedData[1],
                                mpRekeningName: uploadedData[2],
                                bulan: this.checkMonth(uploadedData[3]),
                                tahun: uploadedData[4],
                                mpNominal: uploadedData[5],
                                mpNominalPengajuan: parseInt(uploadedData[6]),
                                nominalPajak: parseInt(uploadedData[7]),
                                nominalPembayaran: parseInt(uploadedData[8]),
                                susulanThp: uploadedData[9],
                                bankName: uploadedData[10],
                                mpRekeningNo: uploadedData[11],
                                jumlahKeseluruhan: uploadedData[12],
                                mpRekeningBankId: getPeserta.mpRekeningBankId ? getPeserta.mpRekeningBankId : null,
                                paymentRekbankId: getPeserta.paymentRekbankId ? getPeserta.paymentRekbankId : null
                            })
                            continue
                        } else {
                            try {
                                var loadApiByNik = `kepersertaan-products/getByNikAndProductId/${uploadedData[1]}/${this.state.formData.productId}`
                                var getPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApiByNik, 'GET')
                                var loadApiById = `kepersertaan-products/${getPeserta.ids}`
                                var getPesertaById = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadApiById, 'GET')
    
                                if (getPesertaById.mpRekeningNo === uploadedData[11]) {
                                    successData.push({
                                        nik: uploadedData[1],
                                        mpRekeningName: uploadedData[2],
                                        bulan: this.checkMonth(uploadedData[3]),
                                        tahun: uploadedData[4],
                                        mpNominal: uploadedData[5],
                                        mpNominalPengajuan: parseInt(uploadedData[6]),
                                        nominalPajak: parseInt(uploadedData[7]),
                                        nominalPembayaran: parseInt(uploadedData[8]),
                                        susulanThp: uploadedData[9],
                                        bankName: uploadedData[10],
                                        mpRekeningNo: uploadedData[11],
                                        jumlahKeseluruhan: uploadedData[12],
                                        mpRekeningBankId: getPeserta.mpRekeningBankId ? getPeserta.mpRekeningBankId : null,
                                        paymentRekbankId: getPeserta.paymentRekbankId ? getPeserta.paymentRekbankId : null
                                    })
                                    sum += uploadedData[5]
                                } else {
                                    errorMessage.push('Data Rekening Bank belum diisi')
    
                                    errorData.push({
                                        errorMessage: errorMessage,
                                        no: uploadedData[0],
                                        nik: uploadedData[1],
                                        mpRekeningName: uploadedData[2],
                                        bulan: this.checkMonth(uploadedData[3]),
                                        tahun: uploadedData[4],
                                        mpNominal: uploadedData[5],
                                        mpNominalPengajuan: parseInt(uploadedData[6]),
                                        nominalPajak: parseInt(uploadedData[7]),
                                        nominalPembayaran: parseInt(uploadedData[8]),
                                        susulanThp: uploadedData[9],
                                        bankName: uploadedData[10],
                                        mpRekeningNo: uploadedData[11],
                                        jumlahKeseluruhan: uploadedData[12],
                                        mpRekeningBankId: getPeserta.mpRekeningBankId ? getPeserta.mpRekeningBankId : null,
                                        paymentRekbankId: getPeserta.paymentRekbankId ? getPeserta.paymentRekbankId : null
                                    })
                                    continue
                                }
                            } catch (e) {
                                console.log(e)
                                errorMessage.push('NIK Tidak ditemukan')
                                this.props.store.dispatch(showLoading(true))
                                errorData.push({
                                    errorMessage: errorMessage,
                                    no: uploadedData[0],
                                    nik: uploadedData[1],
                                    mpRekeningName: uploadedData[2],
                                    bulan: this.checkMonth(uploadedData[3]),
                                    tahun: uploadedData[4],
                                    mpNominal: uploadedData[5],
                                    mpNominalPengajuan: parseInt(uploadedData[6]),
                                    nominalPajak: parseInt(uploadedData[7]),
                                    nominalPembayaran: parseInt(uploadedData[8]),
                                    susulanThp: uploadedData[9],
                                    bankName: uploadedData[10],
                                    mpRekeningNo: uploadedData[11],
                                    jumlahKeseluruhan: uploadedData[12],
                                    // mpRekeningBankId: getPeserta.mpRekeningBankId ? getPeserta.mpRekeningBankId : null,
                                    // paymentRekbankId: getPeserta.paymentRekbankId ? getPeserta.paymentRekbankId : null
                                })
                                continue
                            }
                        }
                    }
                }
                this.formRef.current.instance.updateData({ mpNominal: sum })
                // this.setState({
                //     formData: {
                //         mpNominal: sum
                //     }
                // })
                this.successData = successData
                this.errorData = errorData
                this.forceRefresh()

                if (errorData.length > 0) {
                    let type = "error";
                    let text = "Ada beberapa data unggahan yang gagal diunggah, harap periksa tab data unggahan yang gagal";
                    notify(
                        {
                            message: text,
                            width: "AUTO",
                            shading: true,
                            position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                }

                this.props.store.dispatch(showLoading(false))
            }
            reader.readAsArrayBuffer(files);
        }
    }

    show = async () => {
        this.setState({
            popupVisible: true,
            formData: {
                dasarNilaiMp: 'Nominal',
                persentase: 0.00,
                mpNominal: 0.00
            }
        });
    }


    hide = () => {
        this.setState({
            redirectBack: false,
            uploadedData: [],
            // selectedTabIndex: 0,
            useHeader: false,
            formData: {},
            popupVisible: false,
        });
        this.successData = []
        this.errorData = []
        this.forceRefresh()
    };

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns]
        columns.unshift({
            type: 'buttons',
            buttons: [
                {
                    text: "Error Detail",
                    hint: "Error Detail",
                    icon: "warning",
                    cssClass: "text-danger",
                    onClick: e => {
                        let type = "error";
                        let text = e.row.data.errorMessage;

                        notify(
                            {
                                message: text,
                                width: "AUTO",
                                shading: true,
                                position: { at: "center", my: "center", of: window }
                            },
                            type,
                            2000
                        );
                    }
                }
            ]
        });

        return columns;
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onValueChanged = (e) => {
        this.setState({
            useHeader: e.value
        })
    }

    submitData = async () => {
        this.props.store.dispatch(showLoading(true));
        if (this.successData.length > 0) {
            try {
                let state = this.state.formData
                let data = {
                    dasarNilaiMp: state.dasarNilaiMp,
                    description: state.description,
                    mpNominal: state.mpNominal,
                    mpTypeId: state.mpTypeId,
                    paymentDate: formatDate(state.paymentDate),
                    persentase: state.persentase,
                    productId: state.productId,
                    requestDate: formatDate(state.requestDate),
                    mpSusulanDTOList: this.successData
                }
                let result = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, `pembayaran-mp-susulan/simpan`, 'POST', {
                    values: data
                })

                if (result) {
                    this.props.store.dispatch(showLoading(false));
                    await alert('Sukses Menyimpan Data', 'Sukses')
                    this.hide()
                    this.props.forceRefresh();
                }
            } catch (e) {
                console.log(e);
                await alert('Internal Server Error', 'Sukses')
            }
        } else {
            let type = "error";
            let text = "Harus upload file";
            notify(
                {
                    message: text,
                    width: "AUTO",
                    shading: true,
                    position: { at: "center", my: "center", of: window }
                },
                type,
                2000
            );
        }

    }

    retrieveDataSuccess = () => {
        return this.successData
    }

    retrieveDataFailed = () => {
        return this.errorData
    }

    forceRefresh = () => {
        this.dataGridSesuaiRef.current.forceRefresh(true)
        this.dataGridTakSesuaiRef.current.forceRefresh(true)
    };

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={'Form Generate Pembayaran Manfaat Pensiun Pembayaran Susulan'}
                    minWidth={'70vh'}
                    minHeight={'50vh'}

                    ref={this.PopupRef}

                    toolbarItems={this.popupToolbarItem}
                >
                    <ScrollView>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Form
                                        colCount={1}
                                        id={'form'}
                                        formData={this.state.formData}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation={'left'}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row mt-4">
                                <div style={{ width: "350px" }}>
                                    <FileUploader
                                        accept={'.txt,.xls,.xlsx,.csv'}
                                        uploadMode={'useForm'}
                                        onValueChanged={this.onUploadStarted.bind(this)}
                                        labelText="Upload File"
                                        showFileList={false}
                                        name={'reksadanaAsset'}

                                        value={this.state.uploadedData}

                                        ref={this.fileUploderRef}
                                    />
                                </div>
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <CheckBox
                                        value={this.state.useHeader}
                                        onValueChanged={this.onValueChanged}
                                    />
                                    <span className="ml-2">Use Header</span>
                                </div>
                            </div>
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                            <div id="tabindex-0" className={' mt-2 text-center'}>
                                <DevExpressDataGrid
                                    ref={this.dataGridSesuaiRef}
                                    keyField={'nik'}
                                    loadAPI=''
                                    insertAPI=''
                                    updateAPI=''
                                    deleteAPI=''

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                                    useArraySource={true}
                                    ArraySourceData={this.retrieveDataSuccess}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Data Sukses"}

                                    allowExportSelectedData={false}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Sukses'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                    // SummaryConfiguration={this.summary}
                                    summaryTotalItem={this.summary}
                                    // onToolbarPreparing = {this.onToolbarPreparing}

                                    height={'calc(70vh - 175px)'}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <div id="tabindex-1" className={'d-none mt-2 text-center'} >
                                <DevExpressDataGrid
                                    ref={this.dataGridTakSesuaiRef}
                                    keyField={'nik'}
                                    loadAPI=''
                                    insertAPI=''
                                    updateAPI=''
                                    deleteAPI=''

                                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                                    useArraySource={true}
                                    ArraySourceData={this.retrieveDataFailed}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}

                                    exportExcel={false}
                                    exportFileName={"Data Sukses"}

                                    allowExportSelectedData={false}

                                    showBorders={true}

                                    paging={false}
                                    defaultPageSize={10}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Data Sukses'}
                                    popupWidth={500} //masukan dalam ukuran pixel
                                    popupHeight={300} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={3} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={false} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.errorDataGridErrorButtonColumn()} // taruh konfigurasi kolom disini
                                    // SummaryConfiguration={this.summary}
                                    summaryTotalItem={this.summary}
                                    // onToolbarPreparing = {this.onToolbarPreparing}

                                    height={'calc(70vh - 175px)'}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormModalGenerateMpSusulan