import React, { Component } from "react";
import { Popup } from "devextreme-react/popup";
import { Form, SelectBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { alert } from "devextreme/ui/dialog"
import httpRequest from "plugin/httprequest";
import { chartOfAccount, chartOfAccountGroup } from 'components/pages/parts/staticDataColumnConfig'
import { reportHeaderDetailFilterHeader } from "dataSource/lookup";
import ViewJournalVoucher from "../journalVoucher/view";

class ModalCashBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      formData: {},
    };

    this.form = [
      {
        dataField: 'timestamp',
        label: {
          alignment: "left",
          text: "Waktu Input"
        },
        editorType: 'dxDateBox',
        editorOptions: {
          displayFormat: 'dd MMM yyyy',
          useMaskBehavior: true,
          openOnFieldClick: true
        }
      },
      {
        dataField: "seqNo",
        label: {
          alignment: "left",
          text: "Kategori"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: reportHeaderDetailFilterHeader(this.props.store, 1),
          displayExpr: function (item) {
            return item && item.seqNo + " - " + item.description;
          },
          valueExpr: 'id',
          searchEnabled: true,
          deferRendering: false,
        }
      },
      {
        dataField: 'description',
        label: {
          alignment: "left",
          text: "Deskripsi"
        },
      },
      {
        dataField: 'amount',
        label: {
          alignment: "left",
          text: "Nominal"
        },
        editorType: 'dxNumberBox',
        editorOptions: {
          format: '#,##0.00;(#,##0.00);'
        }
      },
      {
        dataField: 'reffType',
        label: {
          alignment: "left",
          text: "Tipe Reff"
        },
        editorType: "dxSelectBox",
        editorOptions: {
          dataSource: [
            {
              id: 'IN',
              value: 'Investasi'
            },
            {
              id: 'HR',
              value: 'HRD & Umum'
            },
            {
              id: 'KP',
              value: 'Kepesertaan'
            },
            {
              id: 'CV',
              value: 'Cash Voucher'
            },
            {
              id: 'JV',
              value: 'Journal Voucher'
            },
          ],
          valueExpr: 'id',
          displayExpr: 'value',
          onValueChanged: (e) => {
            this.formRef.current.instance.itemOption('buttonJournalVoucher', 'visible', e.value === 'JV')

            const reffNoEditor = this.formRef.current.instance.getEditor('reffNo')
            reffNoEditor.option('readOnly', e.value === 'JV')
            this.formRef.current.instance.updateData({ reffNo: e.value === 'JV' ? '<AUTO>' : '' })
          }
        },
      },
      {
        dataField: 'reffNo',
        label: {
          alignment: "left",
          text: "Reff No"
        },
        editorType: 'dxTextBox'
      },
      {
        name: 'buttonJournalVoucher',
        visible: false,
        itemType: 'button',
        buttonOptions: {
          text: 'Buat Jurnal Voucher',
          onClick: (e) => {
            this.popupModalJournalVoucherInsertRef.current.show()
          }
        }
      }
    ]

    this.PopupToolbarItem = [
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Simpan",
          onClick: async () => {
            this.submitData()
          }
        },
        toolbar: "bottom"
      },
      {
        widget: "dxButton",
        location: "after",
        options: {
          text: "Batal",
          onClick: this.hide.bind(this)
        },
        toolbar: "bottom"
      }
    ];

    this.formRef = React.createRef()
    this.popupModalJournalVoucherInsertRef = React.createRef()
  }


  retrieveData = (data = null) => {
    this.formRef.current.instance.updateData(data)
  }

  submitData = async () => {
    let data = this.state.formData
    let submitData = {
      amount: data.amount,
      description: data.description,
      reffNo: data.reffNo,
      reffType: data.reffType,
      seqNo: data.seqNo,
      timestamp: data.timestamp.toISOString()
    }
    if(submitData){
      try {
        const resultSubmitCashBook = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `cash-books`, 'POST', {
          values: submitData
        })
        if(resultSubmitCashBook){
          notify({ message: 'Data berhasil di input!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
          if (submitData.reffType === 'JV') {
            const dataJurnal = await this.popupModalJournalVoucherInsertRef.current.submit()
          }
          this.props.forceRefresh()
          this.hide()
        }
      } catch (e) {
        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        console.log(e)
      }
    }
  }

  show = () => {
    this.setState({
      popupVisible: true,
    });

    this.popupModalJournalVoucherInsertRef.current.cashBookSubmit = false
  };

  hide = () => {
    this.setState({
      popupVisible: false,
      formData: {},
    });
  };

  changeButtonCaption = (reffNo) => {
    this.formRef.current.instance.updateData({ reffNo: reffNo })
    const buttonJournalVoucher = this.formRef.current.instance.getButton('buttonJournalVoucher')
    buttonJournalVoucher.option('text', 'Ubah Jurnal Voucher')
  }

  render() {
    return (
      <React.Fragment>
        <Popup
          className={"popup"}
          visible={this.state.popupVisible}
          onHiding={this.hide}
          resizeEnabled={true}
          showTitle={true}
          title="Tambah Data Buku Pembantu Kas"
          width={"500px"}
          height={"auto"}
          toolbarItems={this.PopupToolbarItem}
        >
          <div className="mb-3">
            <Form
              colCount={1}
              id={"formCOA"}
              formData={this.state.formData}
              items={this.form}
              ref={this.formRef}
              labelLocation="left"
            />
          </div>
        </Popup>
        <ViewJournalVoucher
          ref={this.popupModalJournalVoucherInsertRef}
          store={this.props.store}
          forceRefresh={this.forceRefresh}
          type={'cashbook'}
          changeButtonCaption={this.changeButtonCaption}
        />
      </React.Fragment>

    );
  }
}

export default ModalCashBook