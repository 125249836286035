import React, { Component } from 'react'
import notify from 'devextreme/ui/notify';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, pesertaAkunStatus, product } from 'dataSource/lookup';
import ViewDataPensiunPasif from './viewDataPensiunPasif';
import httpRequest from 'plugin/httprequest';
import ModalCatatan from './catatan';
import { alert } from 'devextreme/ui/dialog';

class FormDataPensiun extends Component {
    constructor(props) {
        super(props)

        this.state = {
            formData: {},
            pesertaId: 0,
            getUrl: 'kepersertaan-products'
        }

        this.DataGridPensiunRef = React.createRef()
        this.modalRef = React.createRef()
        this.showModalCatatanPendingRef = React.createRef()
        this.showModalCatatanUnPendingRef = React.createRef()

        this.dataGridPensiun = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Buka',
                        hint: 'Buka',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalAction(e.row.data)
                        }
                    },
                    {
                        text: 'Pending Akun',
                        hint: 'Pending Akun',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data, 'pending')
                        }
                    },
                    {
                        text: 'Unpending Akun',
                        hint: 'Unpending Akun',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalCatatan(e.row.data)
                        }
                    }          
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'cardNo',
                caption: 'Card No',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'statusId',
                caption: 'Status Akun Peserta',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
            {
                dataField: 'pendingStatus',
                caption: 'Status Pending',
            },
        ]
    }

    passDataToParent = () => {
        var data = {
            dataPensiun: this.state.formDataPensiun,
            dataGridPensiun: this.DataGridPensiunRef.current.getDataSource(),
        }
        return data
    }

    removeState = () => {
        this.setState({
            pesertaId: 0,
            getUrl: 'kepersertaan-products'
        })
    }

    retrieveData = async (pesertaId) => {
        this.setState({
            pesertaId: pesertaId,
            getUrl: `kepersertaan-products/getAllSimpleProductByPesertaId/${pesertaId}`
        })
    }

    showModalAction = async (data) => {
        var loadAPI = `pesertas/${this.state.pesertaId}`
        var getDataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        var loadAPIAkun = `kepersertaan-products/${data.id}`
        var getAkun = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIAkun, 'GET')

        var dataPerhitungan = null

        this.modalRef.current.show()
        this.modalRef.current.retrieveData(getAkun, dataPerhitungan, getDataPeserta)
    }

    showModalCatatan = (data, type) => {
        if (type === 'pending') {
            this.showModalCatatanPendingRef.current.show(data)   
        } else {
            this.showModalCatatanUnPendingRef.current.show(data)
        }
    }

    unPending = async (data, catatan) => {
        try {
            let loadAPIProduct = 'kepersertaan-products';
            let dataProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct + '/' + data.id, 'GET');

            let dataUpdateProduct = dataProduct;
            if (!dataUpdateProduct.pendingStatus) {
                dataUpdateProduct.pendingDate = new Date();
            }
            dataUpdateProduct.pendingStatus = false;
            dataUpdateProduct.pendingNote = catatan;

            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct, 'PUT', {
                key: dataUpdateProduct.id,
                values: dataUpdateProduct
            }, dataProduct);

            var text = `Upending Akun Berhasil!`
            var title = 'Berhasil'
        } catch (e) {
            console.log(e)
            var text = e
            var title = 'Gagal'
        }

        this.forceRefresh()
        await alert(text, title)
    }

    pending = async (data, catatan) => {
        try {
            var loadAPIProduct = 'kepersertaan-products'
            var dataProduct = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct + '/' + data.id, 'GET')

            var dataUpdateProduct = dataProduct
            if (!dataUpdateProduct.pendingStatus) {
                dataUpdateProduct.pendingDate = new Date()
            }
            dataUpdateProduct.pendingStatus = !dataUpdateProduct.pendingStatus
            dataUpdateProduct.pendingNote = catatan

            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPIProduct, 'PUT', {
                key: dataUpdateProduct.id,
                values: dataUpdateProduct
            },
                dataProduct)

            var text = `Pending Akun Berhasil!`
            var title = 'Berhasil'

        } catch (e) {
            console.log(e)
            var text = e
            var title = 'Gagal'
        }

        this.forceRefresh()
        await alert(text, title)
    }

    forceRefresh = () => {
        this.DataGridPensiunRef.current.forceRefresh(true)
    }
    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid
                    ref={this.DataGridPensiunRef}
                    loadAPI={this.state.getUrl}
                    insertAPI='kepersertaan-products'
                    updateAPI='kepersertaan-products'
                    deleteAPI='kepersertaan-products'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Data Pensiun'}
                    popupWidth={1000} //masukan dalam ukuran pixel
                    popupHeight={450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='top' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.dataGridPensiun} // taruh konfigurasi kolom disini
                    onToolbarPreparing={this.onToolbarPreparingDataAkun}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ViewDataPensiunPasif
                    store={this.props.store}
                    ref={this.modalRef}
                    forceRefresh={this.forceRefresh}
                    removeItemDataGrid={this.removeState}
                    peridoeNs={this.props.peridoeNs}
                    pensiunan={true}
                />
                <ModalCatatan
                    ref={this.showModalCatatanPendingRef}
                    actionType={'pending'}
                    store={this.props.store}
                    submitData={this.pending}
                />

                <ModalCatatan
                    ref={this.showModalCatatanUnPendingRef}
                    actionType={'unPending'}
                    store={this.props.store}
                    unPending={this.unPending}
                />
            </React.Fragment>
        )
    }
}

export default FormDataPensiun
