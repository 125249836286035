import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { kecamatan } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import { cities } from 'dataSource/lookup'

class LaporanKaryawanDokumen extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'fullName',
                caption: 'Nama',
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan',
            },
            {
                dataField: 'birthDate',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'activeDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'dokumenNo',
                caption: 'No Dokumen',
            },
            {
                dataField: 'dokumenName',
                caption: 'Tipe Dokumen',
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan',
            },
            {
                dataField: 'dokumenDate',
                caption: 'Tanggal Dokumen',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Dokumen Karyawan</h2>

                <DevExpressDataGrid
                    loadAPI='view-report-karyawan-dokumen-karyawans'
                    insertAPI='view-report-karyawan-dokumen-karyawans'
                    updateAPI='view-report-karyawan-dokumen-karyawans'
                    deleteAPI='view-report-karyawan-dokumen-karyawans'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Report Dokumen Karyawan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Report Karyawan Data'}
                    popupWidth={500} //masukan dalam ukuran pixel
                    popupHeight={200} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}

                    remoteOperations={true}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
            </div>
        )
    }
}

export default LaporanKaryawanDokumen