import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import Tabs from "devextreme-react/tabs";
import XLSX from 'xlsx'

import { httpRequest } from 'plugin/httprequest'
import { DataGrid, FileUploader,CheckBox } from 'devextreme-react';
import { Paging,Column } from 'devextreme-react/data-grid';
import { ab2str, yyyymmddToDate } from 'plugin/helper';
import { reksadanaTransaction, transactionType } from 'dataSource/lookup';
import { showLoading } from 'redux/actions/loading';

class UploadBroker extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            successData: [],
            errorData: [],
            uploadedData: [],
            selectedTabIndex: 0,
            useHeader:false
        }

        this.TabDataSource = [
            {
              id: 0,
              text: "Success Upload Data"
            },
            {
              id: 1,
              text: "Failed Upload Data"
            },
          ];

        this.fileUploderRef = React.createRef()

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.columns = [
            {
                dataField:'rowNumber',
                caption: "No.",
                cellRender:(data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'brokerCode',
                caption: 'Kode Broker',
            },
            {
                dataField: 'brokerName',
                caption: 'Nama Broker',
            },
            {
                dataField: 'totalAssets',
                caption: 'Total Aset',
                format: "#,##0.00",
                alignment: "right",
            }, 
            {
                dataField: 'mkbd',
                caption: 'MKBD',
                format: "#,##0.00",
                alignment: "right",
            }, 
            {
                dataField: 'tradeVolume',
                caption: 'Volume (Milyar Unit Saham)',
                format: "#,##0.00",
                alignment: "right",
            }, 
            {
                dataField: 'tradeValue',
                caption: 'Value (Triliun Rupiah)',
                format: "#,##0.00",
                alignment: "right",
            }, 
            {
                dataField: 'tradeBei',
                caption: 'Persentase BEI',
                format: "#,##0.00 %",
                alignment: "right",
            }, 
            {
                dataField: 'nominalOmzet',
                caption: 'Omzet (Miliar)',
                format: "#,##0.00",
                alignment: "right",
            }, 
            {
                dataField: 'laba',
                caption: 'Laba (Miliar)',
                format: "#,##0.00",
                alignment: "right",
            }, 
            {
                dataField: 'netMargin',
                caption: 'Margin Bersih (%)',
                format: "#,##0.00 %",
                alignment: "right",
            }, 
            {
                dataField: 'persentaseOmzet',
                caption: 'Omzet (%)',
                format: "#,##0.00 %",
                alignment: "right",
            }, 
            {
                dataField: 'persentaseLaba',
                caption: 'Laba (%)',
                format: "#,##0.00 %",
                alignment: "right",
            }, 
            
        ]

        this.PopupToolbarItem = [{
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Simpan',
                onClick: this.submitData.bind(this)
            },
            toolbar: 'bottom'
        }, {
            widget: 'dxButton',
            location: 'after',
            options: {
                'text': 'Batal',
                onClick: this.hide.bind(this)
            },
            toolbar: 'bottom'
        }]

        this.brokerData = []
    }

    componentDidMount = async () =>{
        let loadAPI = `brokers`;

        var data = await httpRequest(
            process.env.REACT_APP_BACKEND_INVESTASI,
            this.props.store,
            loadAPI,
            "GET"
        );

        this.brokerData = data
    }

    submitData = async () => {
        var message

        if(this.state.errorData.length > 0){
            message = 'Terdapat beberapa data yang gagal di upload, lanjutkan untuk menyimpan data?'
        }else{
            message = 'Lanjutkan menyimpan data?'
        }

        var result = await confirm(message, "Konfirmasi")
        if(result){
            try {
                let text = 'Sukses Simpan Data!'
                let succesData = this.state.successData;
                let data = succesData.map(value => ({
                    "brokerCode": value.brokerCode,
                    "brokerName": value.brokerName,
                    "laba": value.laba,
                    "marginBersih": value.netMargin,
                    "mkbd": value.mkbd,
                    "nominalOmzet": value.nominalOmzet,
                    "prosentaseLaba": value.persentaseLaba,
                    "prosentaseOmzet": value.persentaseOmzet,
                    "totalAset": value.totalAssets,
                    "tradePercentBei": value.tradeBei,
                    "tradeValue": value.tradeValue,
                    "tradeVolume": value.tradeVolume,
                }));
                
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `brokers/upload-list`, 'PUT', {
                    values: data,
                }, data)

                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                this.props.forceRefresh()
                this.hide()
            } catch (e) {
                console.log(e)
                notify({ message: 'Error', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
            // try{
            //     let type = 'success'
            //     let text = 'Asset has been added successfully!'
    
            //     var { successData } = this.state
                
            //     for(var data of successData){
            //         await this.addDataAsset(data)
            //     }            
    
            //     notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
    
            //     this.props.forceRefresh()

            //     this.hide()
            // }catch(e){
            //     console.log(e)
            //     notify({ message: 'Error', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            // }
        }     
    }

    onUploadStarted = (e) => {
        if(e.value.length > 0){
            this.props.store.dispatch(showLoading(true))
        }
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i]
            var fileName = files.name
            var fileSplit = fileName.split('.')
            var fileType = fileSplit[fileSplit.length - 1]

            var reader = new FileReader();
            reader.onload = async (e) => {
                var dataArrayBuffer = new Uint8Array(e.target.result);
                var dataString = ab2str(dataArrayBuffer)

                var data = dataString.split(/(\n\r)|(\n)|(\r)/g)

                if(fileType === 'xls' || fileType === 'xlsx' ){
                    var workbook = XLSX.read(dataArrayBuffer, {type: 'array'});
                    var sheet = workbook.Sheets[workbook.SheetNames[0]];

                    var dataJSON = XLSX.utils.sheet_to_json(sheet)
                    
                    data = dataJSON.map(value => Object.values(value))
                }

                if(fileType === 'txt'){
                    var regex = RegExp(/\|/g)
                    var regexBetweenTwoDoubleQuote = RegExp(/""(.*)""/g)
                    var regexBetweenTwoQuote = RegExp(/"(.*)"/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                       data = data.filter(value => 
                            {
                                regex.lastIndex = 0
                                return regex.test(value)
                            }
                        )
                        data = data.map((value, index) => {
                            //clean start char from quote
                            value = value.replace(/^"/g,'')

                            //clean end char from quote
                            value = value.replace(/"$/g,'')
                            if(regexBetweenTwoDoubleQuote.test(value)){
                                var valueBetweenTwoQuote = value.match(regexBetweenTwoDoubleQuote)
                                
                                var cleanValueBetweenTwoQuote = valueBetweenTwoQuote[0].replace(/,/g,'.')
                                cleanValueBetweenTwoQuote = cleanValueBetweenTwoQuote.replace(/""/g,'')
                                value = value.replace(valueBetweenTwoQuote,cleanValueBetweenTwoQuote)
                            }

                            if(regexBetweenTwoQuote.test(value)){
                                var valueBetweenTwoQuote = value.match(regexBetweenTwoQuote)
                                
                                var cleanValueBetweenTwoQuote = valueBetweenTwoQuote[0].replace(/,/g,'.')
                                cleanValueBetweenTwoQuote = cleanValueBetweenTwoQuote.replace(/"/g,'')
                                value = value.replace(valueBetweenTwoQuote,cleanValueBetweenTwoQuote)
                            }
                            
                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/\|/g)
    
                                var splitRes = splitRes.map(splitVal => splitVal.trim())
        
                                var splitRes = splitRes.map(splitVal => {
                                    if(String(splitVal)){
                                        return String(splitVal)
                                    }else{
                                        return splitVal
                                    }
                                })
    
                                return splitRes
                            }
                        })
                    }else{
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                if(fileType === 'csv'){
                    var regex = RegExp(/,/g)
                    var regexBetweenTwoDoubleQuote = RegExp(/""(.*)""/g)
                    var regexBetweenTwoQuote = RegExp(/"(.*)"/g)
                    if (data.filter(value => regex.test(value)).length > 0) {
                       data = data.filter(value => 
                            {
                                regex.lastIndex = 0
                                return regex.test(value)
                            }
                        )
                        data = data.map((value, index) => {
                            //clean start char from quote
                            value = value.replace(/^"/g,'')

                            //clean end char from quote
                            value = value.replace(/"$/g,'')

                            if(regexBetweenTwoDoubleQuote.test(value)){
                                var valueBetweenTwoQuote = value.match(regexBetweenTwoDoubleQuote)
                                
                                var cleanValueBetweenTwoQuote = valueBetweenTwoQuote[0].replace(/,/g,'.')
                                cleanValueBetweenTwoQuote = cleanValueBetweenTwoQuote.replace(/""/g,'')
                                value = value.replace(valueBetweenTwoQuote,cleanValueBetweenTwoQuote)
                            }
                            if(regexBetweenTwoQuote.test(value)){
                                var valueBetweenTwoQuote = value.match(regexBetweenTwoQuote)
                                
                                var cleanValueBetweenTwoQuote = valueBetweenTwoQuote[0].replace(/,/g,'.')
                                cleanValueBetweenTwoQuote = cleanValueBetweenTwoQuote.replace(/"/g,'')
                                value = value.replace(valueBetweenTwoQuote,cleanValueBetweenTwoQuote)
                            }
                            
                            if (!(this.state.useHeader && index == 0)) {
                                var splitRes = value.split(/,/g)
    
                                var splitRes = splitRes.map(splitVal => splitVal.trim())
        
                                var splitRes = splitRes.map(splitVal => {
                                    if(String(splitVal)){
                                        return String(splitVal)
                                    }else{
                                        return splitVal
                                    }
                                })
    
                                return splitRes
                            }
                        })
                    }else{
                        notify({ message: 'Wrong file! Not contain pipe delimiter', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }

                data = data.filter(value => value)

                var successData = []
                var errorData = []

                var errorMessage = []

                for(var uploadedData of data){
                    if(Array.isArray(uploadedData)){
                        errorMessage = []

                        if(uploadedData.length !== 12){
                            errorMessage.push('Invalid file format')
                        }

                        if(!this.brokerData.find(value => value.brokerCode === uploadedData[0])){
                            errorMessage.push('Broker code not found on system')
                        }                            

                        if(errorMessage.length > 0){
                            errorData.push({
                                brokerCode : uploadedData[0],
                                brokerName: uploadedData[1],
                                totalAssets: uploadedData[2],
                                mkbd: uploadedData[3],
                                tradeVolume: uploadedData[4],
                                tradeValue: uploadedData[5],
                                tradeBei: uploadedData[6],
                                nominalOmzet: uploadedData[7],
                                laba: uploadedData[8],
                                netMargin: uploadedData[9],
                                persentaseOmzet: uploadedData[10],
                                persentaseLaba: uploadedData[11],
                                errorMessage: errorMessage
                            })
                            continue
                        }
                        
                        successData.push({
                            brokerCode : uploadedData[0],
                            brokerName: uploadedData[1],
                            totalAssets: uploadedData[2],
                            mkbd: uploadedData[3],
                            tradeVolume: uploadedData[4],
                            tradeValue: uploadedData[5],
                            tradeBei: uploadedData[6],
                            nominalOmzet: uploadedData[7],
                            laba: uploadedData[8],
                            netMargin: uploadedData[9],
                            persentaseOmzet: uploadedData[10],
                            persentaseLaba: uploadedData[11],
                        })
                    }
                }

                if(errorData.length > 0){
                    let type = "error";
                    let text = "There are some upload data that failed to upload, please check failed upload data tab";
    
                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                }

                this.setState({
                    successData: successData,
                    errorData: errorData
                })

                this.props.store.dispatch(showLoading(false))
            }
            reader.readAsArrayBuffer(files);
        }   
    }

    show = () => {
        this.setState({
            popupVisible: true
        })
    }

    hide = () => {
        this.setState({
            popupVisible: false,
            successData: [],
            errorData: [],
            uploadedData: []
        });

    }

    onTabSelectionChanged = args => {
        if (args.name === "selectedIndex") {
          this.setState({
            selectedTabIndex: args.value
          });
        }
    };

    errorDataGridErrorButtonColumn = () => {
        var columns = [...this.columns]
        columns.unshift({
            type: 'buttons',
            buttons: [{
                text : 'Error Detail',
                hint : 'Error Detail',
                icon: "warning",
                cssClass: "text-danger", 
                onClick : (e) => {
                    let type = "error";
                    let text = e.row.data.errorMessage.join("\n");

                    notify(
                        {
                        message: text,
                        width: "AUTO",
                        shading: true,
                        position: { at: "center", my: "center", of: window }
                        },
                        type,
                        2000
                    );
                },
            }]
        })
        
        return columns
    }

    onValueChanged = (e) => {
        this.setState({
          useHeader:e.value
        })
      }

    render(){
        return(
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Upload Performa Broker'}
                width={'90vw'}
                height={650}
                toolbarItems={this.PopupToolbarItem}
            >
            <div className="d-flex flex-row">
                <div style={{width:"300px"}}>
                <FileUploader
                    accept={'.txt,.xls,.xlsx,.csv'}
                    uploadMode={'useForm'}
                    onValueChanged={this.onUploadStarted.bind(this)}
                    labelText="Upload Performa Broker File Disini"
                    showFileList={false}
                    name={'performaBroker'}

                    value = {this.state.uploadedData}

                    ref={this.fileUploderRef}
                />
                </div>
                <div className="d-flex flex-row align-items-center mb-2">
                    <CheckBox 
                        value={this.state.useHeader} 
                        onValueChanged={this.onValueChanged}
                    />
                    <span className="ml-2">Use Header</span>
                    </div>
                </div>
                <Tabs
                    dataSource={this.TabDataSource}
                    selectedIndex={this.state.selectedTabIndex}
                    onOptionChanged={this.onTabSelectionChanged}
                />
                <div
                    className={
                    (this.state.selectedTabIndex !== 0 ? "d-none" : "") +
                    " mt-2 text-center"
                    }
                >
                    <DataGrid
                        id={'successDataGrid'}
                        showBorders={false}
                        dataSource={this.state.successData}
                        repaintChangesOnly={true}
                        columnAutoWidth={true}
                        columnMinWidth={50}
                        height={400}
                        showScrollbar={'always'}
                        // columns={this.columns}
                    >
                    {
                            this.columns.map((column) => {
                                return <Column 
                                    dataField={column.dataField} 
                                    caption={column.caption} 
                                    lookup={column.lookup} 
                                    cellRender={column.cellRender} 
                                    alignment={column.alignment || 'left'} 
                                    cssClass={column.cssClass}
                                    format = {column.format}
                                    dataType = {column.dataType}
                                    width = {column.width}
                                    type = {column.type}
                                    buttons = {column.buttons}
                                    editorType = {column.editorType}
                                    editorOptions = {column.editorOptions}
                                    visible = {column.visible}
                                    sortOrder = {column.sortOrder}
                                    allowEditing = {column.allowEditing}
                                    calculateCellValue = {column.calculateCellValue}
                                />
                            })
                        }
                        <Paging enabled={false} />                    
                    </DataGrid>
                </div>
                <div
                    className={
                    (this.state.selectedTabIndex !== 1 ? "d-none" : "") +
                    " mt-2 text-center"
                    }
                >
                    <DataGrid
                        id={'errorDatagrid'}
                        showBorders={false}
                        dataSource={this.state.errorData}
                        repaintChangesOnly={true}
                        columnAutoWidth={true}
                        columnMinWidth={50}
                        height={400}
                        showScrollbar={'always'}
                        // columns={this.errorDataGridErrorButtonColumn()}
                    >
                    {
                            this.errorDataGridErrorButtonColumn().map((column) => {
                                return <Column 
                                    dataField={column.dataField} 
                                    caption={column.caption} 
                                    lookup={column.lookup} 
                                    cellRender={column.cellRender} 
                                    alignment={column.alignment || 'left'} 
                                    cssClass={column.cssClass}
                                    format = {column.format}
                                    dataType = {column.dataType}
                                    width = {column.width}
                                    type = {column.type}
                                    buttons = {column.buttons}
                                    editorType = {column.editorType}
                                    editorOptions = {column.editorOptions}
                                    visible = {column.visible}
                                    sortOrder = {column.sortOrder}
                                    allowEditing = {column.allowEditing}
                                    calculateCellValue = {column.calculateCellValue}
                                />
                            })
                        }
                        <Paging enabled={false} />                    
                    </DataGrid>
                </div>
                
            </Popup>
        )
    }
}

export default UploadBroker