import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { addURL } from 'redux/actions/url'
import { Form } from "devextreme-react";
import httpRequest from 'plugin/httprequest';

class BatchEndYears extends Component {
    constructor(props) {
        super(props)
        
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.id = 2;
        this.state = {
            formData: {
                sysDateNow:new Date(this.sysDate ? this.sysDate.dateValue : null),
                sysDateNext: this.date.setDate(this.date.getDate() + 1)
            }
        }

        this.formItem = [
            {
                itemType: 'group',
                colSpan: 1,
                items: [
                    {
                        dataField: 'sysDateNow',
                        label: {
                            text: 'Tanggal Sistem Saat Ini'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        },
                    },
                    {
                        dataField: 'sysDateNext',
                        label: {
                            text: 'Tanggal Sistem Berikutnya'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                        }, 
                    }
                ]
            },
            {
                itemType: 'group',
                colCount: 1,
                items: [
                    {
                        dataField: 'sysYear',
                        label: {
                            text: 'Periode Proses'
                        },
                    },
                ]
            }
        ]

        this.columns = [
            {
                dataField: 'logProsesAkhirTahun',
                caption: 'Log Proses Akhir Tahun',
                columns: [
                    {
                        dataField: 'moduleName',
                        caption: 'Nama Modul'
                    },
                    {
                        dataField: 'tanggalProsesTerakhir',
                        caption: 'Tanggal Proses Terakhir'
                    },
                    {
                        dataField: 'tanggalProsesEndMonth',
                        caption: 'Tanggal Proses Akhir Hari'
                    },
                    {
                        dataField: 'action',
                        caption: 'Aksi',
                        dataType: 'boolean'
                    }
                ]
            }
        ]
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    loadData = async() => {
        let response = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `sys-moduls/${this.id}`);
        return response; 
    }
    prosesEndDay = () => {
        console.log(this.state.formData)
    }
    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Proses',
                    onClick: (e) => {
                        this.prosesEndDay()
                    },
                }
            }
        )
    }
    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Proses Akhir Tahun</h2>
                <div className="row">
                    <div className="col-md-6">
                        <Form
                            colCount={1}
                            id={'formFilter'}
                            formData={this.state.formData}
                            items={this.formItem}
                            labelLocation="left"
                        />
                    </div>
                    <div className="mt-2 col-md-12">
                        <DevExpressDataGrid
                            loadAPI='-'
                            insertAPI='-'
                            updateAPI='-'
                            deleteAPI='-'

                            backendserver={process.env.REACT_APP_BACKEND}
                            useArraySource={true}
                            ArraySourceData={this.loadData}

                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}
                            exportExcel={false}
                            exportFileName={"-"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'-'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={200} //masukkan dalam ukuran pixel

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                            SummaryConfiguration={this.summary}

                            onToolbarPreparing={this.onToolbarPreparing}

                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default BatchEndYears