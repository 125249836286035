import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, branch, currency, productDefault, broker, custodies, managerInvestasis, aperds, akunInvestasi } from 'dataSource/lookup';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import ModalRekeningBankInvestasi from './modalForm/bank';
import ModalRekeningKustodiInvestasi from './modalForm/kustodi';
import ModalRekeningBrokerInvestasi from './modalForm/broker';
import ModalRekeningManagerInvestasi from './modalForm/managerInvest';
import ModalRekeningAperdInvestasi from './modalForm/aperd';
import { confirmAlert } from 'react-confirm-alert'


class ModalRekeningInvestasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {
                productId: 0
            }
        }
        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridBankRef = React.createRef()
        this.dataGridBrokerRef = React.createRef()
        this.dataGridKustodiRef = React.createRef()
        this.dataGridManageInvestasiRef = React.createRef()
        this.dataGridAperdRef = React.createRef()

        this.modalBankRef = React.createRef()
        this.modalEditBankRef = React.createRef()
        this.modalBrokerRef = React.createRef()
        this.modalEditBrokerRef = React.createRef()
        this.modalKustodiRef = React.createRef()
        this.modalEditKustodiRef = React.createRef()
        this.modalManageInvestasiRef = React.createRef()
        this.modalEditManageInvestasiRef = React.createRef()
        this.modalAperdRef = React.createRef()
        this.modalEditAperdRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this),
                    elementAttr: {
                        style: 'margin-top: -50%' // Gaya CSS langsung
                    }
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'productId',
                label: {
                    text: 'Kode Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'productName',
                label: {
                    text: 'Nama Produk'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productName'
                }
            },
        ]

        this.TabDataSource = [
            {
                id: 0,
                text: 'Bank'
            },
            {
                id: 1,
                text: 'Kustodi'
            },
            {
                id: 2,
                text: 'Broker'
            },
            {
                id: 3,
                text: 'Manager Investasi'
            },
            {
                id: 4,
                text: 'APERD'
            },
            // {
            //     id: 5,
            //     text: 'CBEST'
            // },
        ]

        this.columnRekBank = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalEdit('bank', data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async(e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteData('bank', data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ],
                                overlayClassName: 'react-custom-confirm-alert-overlay',
                            })
                        }
                    }
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'rekNo',
                caption: 'No. Rekening',
            },
            {
                dataField: 'rekName',
                caption: 'Nama Rekening',
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'jenis',
                caption: 'Jenis Rekening',
            },
            {
                dataField: 'akunId',
                caption: 'Akun',
                lookup: {
                    dataSource: akunInvestasi(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'akunName'
                }
            },
            {
                dataField: 'statusId',
                caption: 'Status'
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            }
        ]

        this.columnRekKustodi = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalEdit('kustodi', data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async(e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteData('kustodi', data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ],
                                overlayClassName: 'react-custom-confirm-alert-overlay',
                            })
                        }
                    }
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'rekNo',
                caption: 'No. Rekening',
            },
            {
                dataField: 'rekName',
                caption: 'Nama Rekening',
            },
            {
                dataField: 'custodyId',
                caption: 'Kustodi',
                lookup: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'custodyName'
                }
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            }
        ]

        this.columnRekBroker = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalEdit('broker', data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async(e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteData('broker', data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ],
                                overlayClassName: 'react-custom-confirm-alert-overlay',
                            })
                        }
                    }
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'rekNo',
                caption: 'No. Rekening',
            },
            {
                dataField: 'rekName',
                caption: 'Nama Rekening',
            },
            {
                dataField: 'brokerId',
                caption: 'Broker',
                lookup: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'brokerName'
                }
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]

        this.columnRekMi = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalEdit('mi', data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async(e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteData('mi', data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ],
                                overlayClassName: 'react-custom-confirm-alert-overlay',
                            })
                        }
                    }
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'rekNo',
                caption: 'No. Rekening',
            },
            {
                dataField: 'rekName',
                caption: 'Nama Rekening',
            },
            {
                dataField: 'miId',
                caption: 'Manager Investasi',
                lookup: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName'
                }
            },
            {
                dataField: 'bankId',
                caption: 'Bank',
                lookup: {
                    dataSource: banks(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'bankName'
                }
            },
            {
                dataField: 'cabang',
                caption: 'Cabang',
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            }
        ]

        this.columnRekAperd = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        onClick: (e) => {
                            var data = e.row.data
                            this.showModalEdit('aperd', data)
                        }
                    },
                    {
                        text: 'Hapus',
                        onClick: async(e) => {
                            var data = e.row.data
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: 'Apakah anda yakin untuk menghapus data ini?',
                                buttons: [
                                    {
                                        label: 'Ya',
                                        onClick: () => {
                                            this.deleteData('aperd', data)
                                        }
                                    },
                                    {
                                        label: 'Tidak'
                                    }
                                ],
                                overlayClassName: 'react-custom-confirm-alert-overlay',
                            })
                        }
                    }
                ]
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: productDefault(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'rekNo',
                caption: 'No. Rekening',
            },
            {
                dataField: 'rekName',
                caption: 'Nama Rekening',
            },
            {
                dataField: 'aperdId',
                caption: 'Aperd',
                lookup: {
                    dataSource: aperds(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'aperdName'
                }
            },
            {
                dataField: 'tanggalEfektif',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: {
                productId: data,
                productName: data,
            }
        })
    }

    hide() {
        this.cleanDataGrid()
        this.setState({
            popupVisible: false,
            dataMaster: {
                productId: 0
            }
        })
    }

    cleanDataGrid = () => {
        this.dataGridBankRef.current.getDeletedIndex()
        this.dataGridBrokerRef.current.getDeletedIndex()
        this.dataGridAperdRef.current.getDeletedIndex()
        this.dataGridKustodiRef.current.getDeletedIndex()
        this.dataGridManageInvestasiRef.current.getDeletedIndex()
        // var deleteData = []
        // if (data) {
        //     for (var value of data) {
        //         deleteData.push(
        //             {
        //                 type: 'remove',
        //                 key: value.id
        //             }
        //         )
        //     }
        //     this.dataGridRef.current.push(deleteData)
        // }
    }

    showModalEdit = (jenis, data) => {
        if (jenis == 'bank') {
            this.modalEditBankRef.current.show(jenis)
            this.modalEditBankRef.current.retrieveData('edit', data, jenis)
        } else if (jenis == 'kustodi') {
            this.modalEditKustodiRef.current.show(jenis)
            this.modalEditKustodiRef.current.retrieveData('edit', data, jenis)
        } else if (jenis == 'broker') {
            this.modalEditBrokerRef.current.show(jenis)
            this.modalEditBrokerRef.current.retrieveData('edit', data, jenis)
        } else if (jenis == 'mi') {
            this.modalEditManageInvestasiRef.current.show(jenis)
            this.modalEditManageInvestasiRef.current.retrieveData('edit', data, jenis)
        } else if (jenis == 'aperd') {
            this.modalEditAperdRef.current.show(jenis)
            this.modalEditAperdRef.current.retrieveData('edit', data, jenis)
        }
    }

    showModalAdd = (jenis) => {
        if (jenis == 'bank') {
            this.modalBankRef.current.show(jenis)
        } else if (jenis == 'kustodi') {
            this.modalKustodiRef.current.show(jenis)
        } else if (jenis == 'broker') {
            this.modalBrokerRef.current.show(jenis)
        } else if (jenis == 'mi') {
            this.modalManageInvestasiRef.current.show(jenis)
        } else if (jenis == 'aperd') {
            this.modalAperdRef.current.show(jenis)
        }
    }

    deleteData = async(type, data) => {
        if(type == 'bank') {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/rek-banks`, 'DELETE', data.id);
    
                notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.forceRefresh()
            } catch (error) {
                notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        } else if(type == 'kustodi') {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/rek-custodies`, 'DELETE', data.id);
    
                notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.forceRefresh()
            } catch (error) {
                notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        } else if(type == 'broker') {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/rek-brokers`, 'DELETE', data.id);
    
                notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.forceRefresh()
            } catch (error) {
                notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        } else if(type == 'mi') {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/rek-mis`, 'DELETE', data.id);
    
                notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.forceRefresh()
            } catch (error) {
                notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        } else if(type == 'aperd') {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `staticdata-wf/rek-aperds`, 'DELETE', data.id);
    
                notify({ message: 'Berhasil hapus data', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'success', 600);
                this.forceRefresh()
            } catch (error) {
                notify({ message: 'Terjadi kelasahan!', width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, 'error', 600);
            }
        }
    }

    onTabSelectionChanged = (args) => {
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onToolbarPreparingBank = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Rekening Bank',
                    onClick: (e) => {
                        this.showModalAdd('bank')
                    },
                }
            }
        )
    }

    onToolbarPreparingKustodi = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Rekening Kustodi',
                    onClick: (e) => {
                        this.showModalAdd('kustodi')
                    },
                }
            }
        )
    }

    onToolbarPreparingBroker = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Rekening Broker',
                    onClick: (e) => {
                        this.showModalAdd('broker')
                    },
                }
            }
        )
    }

    onToolbarPreparingManagerInvest = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Rekening Manager Investasi',
                    onClick: (e) => {
                        this.showModalAdd('mi')
                    },
                }
            }
        )
    }

    onToolbarPreparingAperd = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    text: 'Tambah Rekening Aperd',
                    onClick: (e) => {
                        this.showModalAdd('aperd')
                    },
                }
            }
        )
    }

    forceRefresh = () => {
        this.dataGridBankRef.current.forceRefresh(true)
        this.dataGridKustodiRef.current.forceRefresh(true)
        this.dataGridBrokerRef.current.forceRefresh(true)
        this.dataGridManageInvestasiRef.current.forceRefresh(true)
        this.dataGridAperdRef.current.forceRefresh(true)
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'75vw'}
                    height="auto"
                    title={`Form Data Rekening`}
                    ref={this.PopupRef}
                    toolbarItems={this.popupToolbarItem}
                >
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={'dataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                    readOnly={true}
                                />
                            </div>
                            <DevextremeTab
                                dataSource={this.TabDataSource}
                                onOptionChanged={this.onTabSelectionChanged}
                            />
                            <div id="tabindex-0" className=" col-md-12">
                                <DevExpressDataGrid
                                    ref={this.dataGridBankRef}
                                    loadAPI={`rek-banks-by-productId?productId=${this.state.dataMaster.productId}`}
                                    // loadAPI={`rek-banks`}
                                    insertAPI='rek-banks'
                                    updateAPI='rek-banks'
                                    deleteAPI='staticdata-wf/rek-banks'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                    // useArraySource={true}
                                    // ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    exportExcel={true}
                                    exportFileName={"Rekening Bank"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}
                                    height={'calc(100vh - 501px)'}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Form Edit Rekening'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={350} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnRekBank} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing={this.onToolbarPreparingBank}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <div id="tabindex-1" className="d-none col-md-12">
                                <DevExpressDataGrid
                                    ref={this.dataGridKustodiRef}
                                    loadAPI={`rek-custodies?productId.in=${this.state.dataMaster.productId}`}
                                    insertAPI='rek-custodies'
                                    updateAPI='rek-custodies'
                                    deleteAPI='staticdata-wf/rek-custodies'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                    // useArraySource={true}
                                    // ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    exportExcel={true}
                                    exportFileName={"Rekening Kustodi"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}
                                    height={'calc(100vh - 501px)'}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Form Edit Rekening'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={350} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnRekKustodi} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing={this.onToolbarPreparingKustodi}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <div id="tabindex-2" className="d-none col-md-12">
                                <DevExpressDataGrid
                                    ref={this.dataGridBrokerRef}
                                    loadAPI={`rek-brokers?productId.in=${this.state.dataMaster.productId}`}
                                    insertAPI='rek-brokers'
                                    updateAPI='rek-brokers'
                                    deleteAPI='staticdata-wf/rek-brokers'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                    // useArraySource={true}
                                    // ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    exportExcel={true}
                                    exportFileName={"Rekening Broker"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}
                                    height={'calc(100vh - 501px)'}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Form Edit Rekening'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={350} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnRekBroker} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing={this.onToolbarPreparingBroker}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <div id="tabindex-3" className="d-none col-md-12">
                                <DevExpressDataGrid
                                    ref={this.dataGridManageInvestasiRef}
                                    loadAPI={`rek-mis?productId.in=${this.state.dataMaster.productId}`}
                                    insertAPI='rek-mis'
                                    updateAPI='rek-mis'
                                    deleteAPI='staticdata-wf/rek-mis'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                    // useArraySource={true}
                                    // ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    exportExcel={true}
                                    exportFileName={"Rekening Manager Investasi"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}
                                    height={'calc(100vh - 501px)'}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Form Edit Rekening'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={350} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnRekMi} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing={this.onToolbarPreparingManagerInvest}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                            <div id="tabindex-4" className="d-none col-md-12">
                                <DevExpressDataGrid
                                    ref={this.dataGridAperdRef}
                                    loadAPI={`rek-aperds?productId.in=${this.state.dataMaster.productId}`}
                                    insertAPI='rek-aperds'
                                    updateAPI='rek-aperds'
                                    deleteAPI='staticdata-wf/rek-aperds'

                                    backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                    // useArraySource={true}
                                    // ArraySourceData={this.loadData}

                                    allowAdding={false}
                                    allowDeleting={false}
                                    allowUpdating={false}
                                    exportExcel={true}
                                    exportFileName={"Rekening Aperd"}
                                    allowExportSelectedData={true}
                                    selection={"multiple"}

                                    showBorders={true}

                                    paging={true}
                                    defaultPageSize={10}
                                    height={'calc(100vh - 501px)'}

                                    //bagian konfigurasi popup saat insert dan edit record
                                    popupTitle={'Form Edit Rekening'}
                                    popupWidth={700} //masukan dalam ukuran pixel
                                    popupHeight={350} //masukkan dalam ukuran pixel

                                    popupFormLabelLocation='left' //accepted value = top, left, right
                                    popupFormMinColWidth={300} // minimum lebar kolom
                                    popupFormColCount={1} //jumlah kolom pada form

                                    //akhir bagian konfigurasi popup

                                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                    FilterRow={true} // set false untuk mematikan fitur filter

                                    ColumnConfiguration={this.columnRekAperd} // taruh konfigurasi kolom disini
                                    SummaryConfiguration={this.summary}
                                    onToolbarPreparing={this.onToolbarPreparingAperd}

                                    //contoh konfigurasi kolom
                                    //this.columns = [{
                                    //    dataField: 'kolom1',
                                    //    caption: 'Ini Kolom 1'
                                    //}, {
                                    //    dataField: 'kolom2',
                                    //    caption: 'Ini Kolom 2'
                                    //}]
                                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                    store={this.props.store} // jangan di edit edit
                                />
                            </div>
                        </div>
                    </div>
                    <ModalRekeningBankInvestasi
                        ref={this.modalBankRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'add'}
                    />
                    <ModalRekeningBankInvestasi
                        ref={this.modalEditBankRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'edit'}
                    />
                    <ModalRekeningKustodiInvestasi
                        ref={this.modalKustodiRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'add'}
                    />
                    <ModalRekeningKustodiInvestasi
                        ref={this.modalEditKustodiRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'edit'}
                    />
                    <ModalRekeningBrokerInvestasi
                        ref={this.modalBrokerRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'add'}
                    />
                    <ModalRekeningBrokerInvestasi
                        ref={this.modalEditBrokerRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'edit'}
                    />
                    <ModalRekeningManagerInvestasi
                        ref={this.modalManageInvestasiRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'add'}
                    />
                    <ModalRekeningManagerInvestasi
                        ref={this.modalEditManageInvestasiRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'edit'}
                    />
                    <ModalRekeningAperdInvestasi
                        ref={this.modalAperdRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'add'}
                    />
                    <ModalRekeningAperdInvestasi
                        ref={this.modalEditAperdRef}
                        store={this.props.store}
                        forceRefresh={this.forceRefresh}
                        actionType={'edit'}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalRekeningInvestasi