import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { jabatan } from 'components/pages/parts/staticDataColumnConfig'
import { addURL } from 'redux/actions/url'
import { jabatanGolongan } from 'dataSource/lookup'
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest';
import { confirmAlert } from 'react-confirm-alert';
import { confirm } from 'devextreme/ui/dialog';
import FormModalJabatan from './modalAddJabatan'

class SdmJabatan extends Component {
    constructor(props){
        super(props)
        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-primary',
                        onClick : async(e) => {
                            this.showModal('edit', e.row.data)
                        }
                    },
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-primary',
                        onClick : async(e) => {
                            let result = await confirm('Apakah anda yakin ingin menghapus data ini?', 'Konfirmasi')

                            if(result) {
                                this.delete(e.row.data)
                            }
                        }
                    },
                ],
            },
            {
                dataField: 'jabatanCode',
                caption: 'Kode Jabatan',
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
            {
                dataField: 'jabatanName',
                caption: 'Jabatan',
                validationRules : [
                    {
                        type: "required",
                    }
                ]
            },
            {
                dataField: 'jabatanGolonganId',
                caption: 'Golongan Jabatan',
                lookup: {
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaJagol',
                    searchEnabled: true,
                },
                validationRules : [
                    {
                        type: "required",
                    }
                ],
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'namaJagol',
                    searchEnabled: true,
                }
            },
            {
                dataField: 'instruksiTtd',
                caption: 'Instruksi TTD',
                dataType: 'boolean',
                editorType: "dxCheckBox" 
            },
            {
                dataField: 'instruksiTtdUrutan',
                caption: 'Urutan Instruksi TTD',
                dataType: 'number',
                lookup: {
                    dataSource: [
                        {
                            id: 1,
                            value: '1'
                        },
                        {
                            id: 2,
                            value: '2'
                        },
                        {
                            id: 3,
                            value: '3'
                        },
                        {
                            id: 4,
                            value: '4'
                        },
                        {
                            id: 5,
                            value: '5'
                        },
                    ],
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                } 
            },
            {
                dataField: 'authorize',
                caption: 'Authorize',
                dataType: 'boolean',
                editorType: "dxCheckBox" 
            },
        ]
        
        this.selectedRowKeys = []
        this.dataGridRef = React.createRef()
        this.showModalAddRef = React.createRef()
        this.showModalEditRef = React.createRef()
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModal = (type, data) => {
        if(type === 'edit') {
            this.showModalEditRef.current.show()
            this.showModalEditRef.current.retrieveData(data)
        } else {
            this.showModalAddRef.current.show()
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'trash',
                    text: 'hapus',
                    onClick: (e) => {
                        confirmAlert({
                            title: 'Konfirmasi',
                            message: 'Apakah anda yakin ingin menghapus data ini?',
                            buttons: [
                                {
                                    label: 'Ya',
                                    onClick: () => {
                                        this.selectedDelete(e)
                                    }
                                },
                                {
                                    label: 'Tidak'
                                }
                            ]
                        })
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModal('add')
                    },
                }
            },
        )
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    delete = async(data) => {
        try {
            await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'jabatans', 'DELETE', data.id)
            this.forceRefresh()
            notify({message: "Sukses Hapus Data",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        } catch (e) {
            console.log(e);
            notify({message: e,width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
        }
    }

    selectedDelete = async () => {
        var errorMessage = []
        if (this.selectedRowKeys.length === 0) {
            notify({message: "Silahkan pilih yang akan di hapus",width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',3000);
            return null
        }
        
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD,this.props.store, 'jabatans', 'DELETE', this.selectedRowKeys[i])
            } catch (e){
                console.log(e);
                errorMessage.push('Sebagian data tidak bisa dihapus, karena sudah digunakan')
            }

        }
        
        if (errorMessage.length > 0) {
            notify({message: errorMessage.join('\n'),width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'error',5000);
            this.forceRefresh()
            return
        }

        this.forceRefresh()
        notify({message: 'Data Berhasil Dihapus',width: "AUTO",shading: true,position: { at: "center", my: "center", of: window }},'success',3000);
        
    }

    onSelectionChanged = (e) => {
        this.selectedRowKeys = e.selectedRowKeys
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Jabatan</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='jabatans?size=9999'
                    insertAPI='jabatans'
                    updateAPI='jabatans'
                    deleteAPI='jabatans' 

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Jabatan"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Jabatan Data'}
                    popupWidth={600} //masukan dalam ukuran pixel
                    popupHeight={450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    SummaryConfiguration={this.summary}
                    onToolbarPreparing = {this.onToolbarPreparing}
                    onSelectionChanged = {this.onSelectionChanged}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />

                <FormModalJabatan 
                    ref={this.showModalAddRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'add'}
                />  

                <FormModalJabatan 
                    ref={this.showModalEditRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                    actionType={'edit'}
                /> 
            </div>
        )
    }
}

export default SdmJabatan
