import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import ShowModalPersiapanPensiun from 'components/pages/modal/kepesertaan/pesertaDapen/aktif/showPersiapanPensiun'
import { addURL } from 'redux/actions/url'
import { branch, gender, unit } from 'dataSource/lookup'
import notify from 'devextreme/ui/notify';
import httpRequest from 'plugin/httprequest'
import { confirmAlert } from 'react-confirm-alert'

class PersiapanPensiun extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: ' Buka',
                        hint: ' Buka',
                        cssClass: 'text-primary',
                        onClick: (e) => {
                            this.showModalPersiapanPensiun(e.row.data.pesertaId)
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            confirmAlert({
                                title: 'Konfirmasi',
                                message: "Apakah Anda Yakin Ingin Verifikasi Peserta Ini?",
                                buttons: [
                                  {
                                    label: 'Iya',
                                    onClick: () => {
                                        this.kirim(e.row.data.pesertaId)
                                    }
                                  },
                                  {
                                    label: 'Tidak',
                                    onClick: () => {

                                    }
                                  },
                                ]
                            });
                        }
                    },
                ],
            },
            {
                dataField: 'tanggalPensiun',
                caption: 'Tanggal Pensiun',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'nik',
                caption: 'NIK',
            },
            {
                dataField: 'namaLengkap',
                caption: 'Nama',
            },
            {
                dataField: 'tempatLahir',
                caption: 'Tempat Lahir',
            },
            {
                dataField: 'tanggalLahir',
                caption: 'Tanggal Lahir',
                dataType: 'date',
                format: 'dd MMM yyyy',
                alignment: 'right'
            },
            {
                dataField: 'jenisKelamin',
                caption: 'Jenis Kelamin',
                lookup: {
                    dataSource: gender(),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "value" // provides display values
                }
            },
            {
                dataField: 'unitPeserta',
                caption: 'Unit',
                lookup: {
                    dataSource: unit(this.props.store),
                    valueExpr: "id", // contains the same values as the "statusId" field provides
                    displayExpr: "unitName" // provides display values
                }
            },
            {
                dataField: 'alamatKtp',
                caption: 'Alamat KTP',
            },
            {
                dataField: 'noHp',
                caption: 'No Hp',
            },
            {
                dataField: 'emailPeserta',
                caption: 'Email',
            },
        ]

        this.popupPersiapanPensiunRef = React.createRef()
        this.dataGridRef = React.createRef()
        this.currentData = []
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'plus',
    //                 text: '',
    //                 onClick: (e) => {
    //                     this.showModalPersiapanPensiun(e)
    //                 }
    //             }
    //         }
    //     )
    // }


    kirim = async (pesertaId) => {
        try {
            var loadAPI = 'pesertas'
            var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI + '/' + pesertaId, 'GET')
    
            var dataUpdate = dataPeserta
            dataUpdate.statusPeserta = 3
    
            await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'PUT', {
                key: dataUpdate.id,
                values: dataUpdate
            },
                dataPeserta)
            var text = `Data berhasil dikirim!`
            var type = 'success'
        } catch (e) {
            console.log(e)
            var text = e
            var type = 'error'
        }
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        this.forceRefresh()
    }
    
    showModalPersiapanPensiun = async(data) => {
        var loadAPI = `simple-pesertas/getSimpleById/${data}`
        var dataPeserta = await httpRequest(process.env.REACT_APP_BACKEND_CORE, this.props.store, loadAPI, 'GET')

        this.popupPersiapanPensiunRef.current.show();
        this.popupPersiapanPensiunRef.current.retrieveData(dataPeserta);
    }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Persiapan Pensiun</h2>

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='peserta-pensiuns/getPersiapanPensiun/2'
                    insertAPI='pesertas'
                    updateAPI='pesertas'
                    deleteAPI='pesertas'

                    keyField={'pesertaId'}

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}
                    exportExcel={true}
                    exportFileName={"Persiapan Pensiun"}
                    allowExportSelectedData={true}
                    selection={"multiple"}
                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Persiapan Pensiun'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter
                    // onToolbarPreparing = {this.onToolbarPreparing}
                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <ShowModalPersiapanPensiun
                    ref={this.popupPersiapanPensiunRef}
                    store={this.props.store}
                />
            </div>
        )
    }
}

export default PersiapanPensiun
