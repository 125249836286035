import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import { iuranType } from 'dataSource/lookup';

class ModalActionIuranDataAkun extends Component {
    constructor(props){
        super(props)

        this.state = {
            popupVisible: false,
            formData: {},
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.PopupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        // console.log(this.state.formData)
                        this.props.editDataIuran(this.state.formData)
                        this.hide()
                    }
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            },
        ]
        this.dataMaster = [
            {
                dataField: 'iuranTypeId',
                label: {
                    alignment: 'left',
                    text: 'Jenis Iuran'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: iuranType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'iuranName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'efektifDate',
                label: {
                    alignment: 'left',
                    text: 'Tanggal Efektif'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'dd MMM yyyy'
                }
            },
            {
                dataField: 'skNo',
                label: {
                    alignment: 'left',
                    text: 'No SK'
                },
            },
            {
                dataField: 'iuranNominal',
                label: {
                    alignment: 'left',
                    text: 'Nilai Iuran'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: '#,##0.00'
                }
            },

        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide() {
        this.setState({
            popupVisible: false,
            formData: {},
        });
    }

    retrieveData = (data) => {
        this.setState({
            formData: data
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Kewajiban Iuran Peserta'}
                width={500}
                height={300}

                ref={this.PopupRef}

                toolbarItems={this.PopupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formDataActionDataIuran'}
                                formData={this.state.formData}
                                items={this.dataMaster}
                                scrollingEnabled={true}
                                labelLocation='left'
                            />
                        </div>
                    </div>

                </div>
            </Popup>
        );
    }
}


export default ModalActionIuranDataAkun