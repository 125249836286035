import React, { Component } from "react";
import { alert,confirm } from 'devextreme/ui/dialog';
import Form from "devextreme-react/form";
import { Popup } from 'devextreme-react/popup';
import httpRequest from "plugin/httprequest";
import notify from 'devextreme/ui/notify';
import { formatDate } from "plugin/helper";

class CreateJournalVoucher extends Component {
    constructor(props){
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        
        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.state = {
            // dataMaster: {
            //     sysDateNow:new Date(this.sysDate ? this.sysDate.dateValue : null),
            // },
            dataMaster: {},
            dataForm: {},
            popUpVisible: false
        }

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.popUpRef = React.createRef();
        this.formRef = React.createRef();

        this.id = 0;

        this.toolBarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    text: 'Simpan',
                    onClick: async (e) => {
                        const result = await confirm("Apakah anda ingin menyimpan data ini?", "Konfirmasi?");
                        const formValidation = this.validate();
                        if (result && formValidation){
                            await this.submitData();
                        }
                    }
                },
                toolbar: 'bottom'
            },
        ]

        this.dataDate = [
            {
                dataField: 'sysDateNow',
                label: {
                    text: 'Pilih Tanggal Jurnal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    displayFormat: 'dd MMM yyyy',
                    useMaskBehavior: true,
                    openOnFieldClick: true,
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill the date"
                    }
                ]
            }
        ]
    }

    componentDidMount = async() => {
       await this.retrieveData()
    }

    validate = () => {
        let formValidation = this.formRef.current.instance.validate();
        return formValidation.isValid;
    }

    show(){
        this.setState({
            popUpVisible: true
        })
    }

    hide(){
        this.setState({
            popUpVisible: false,
            dataMaster: {}
        })
    }

    retrieveData = async(data) => {
        var data = data

        if(data){
            this.setState({
                dataMaster: data
            })
        }
    }
    
    submitData = async() => {
        var data = this.state.dataMaster
        var date = this.state.dataForm
        var inputDate = formatDate(date.sysDateNow)

        try{
            await httpRequest(process.env.REACT_APP_BACKEND_ACC,this.props.store,`journal-recurring/generateJournal/${data.id}/${inputDate}`)
    
            let type = 'success'
            let text = 'Journal voucher berhasil dibuat' 
    
            notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window}, closeOnOutsideClick: true }, type);
        }catch(e){
            alert(e,'Error')
        }

        this.hide();
    }
    

    render(){
        return(
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popUpVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    showTitle={true}
                    title={'Form Buat Journal Voucher'}
                    width={'20vw'}
                    height={'20vh'}
                    toolbarItems={this.toolBarItem}
                >
                 <Form 
                    ref={this.formRef}
                    formData={this.state.dataForm}
                    colCount={1}
                    items={this.dataDate}
                    labelLocation={'left'}
                />
                </Popup>
            </React.Fragment>
        )
    }
}

export default CreateJournalVoucher;
