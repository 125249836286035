import React, { Component } from 'react'
import { Popup, Form, FileUploader } from 'devextreme-react'
import { formatDate, formatUploadFileData } from 'plugin/helper'
import ScrollView from 'devextreme-react/scroll-view';
import Box, { Item } from 'devextreme-react/box';
import { banks, currency, programs, sdmSupplier, tipeSubTransaksiSdmUmum, tipeTransaksiSdmUmum } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import notify from "devextreme/ui/notify";

class FormModalBiayaPemeliharaanAset extends Component {
    constructor(props){
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null
        this.userLoged = props.store.getState().sessionUser ? props.store.getState().sessionUser : null

        this.state = {
            popupVisible:false,
            DataMaster:{
                currencyId:1001,
                currencyRate:1
            },
            DataDocument:{},
            fileUploadReceipt:[],
        }

        this.form = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption:'Pengajuan Pemeliharaan',
                        items: [
                            {
                                dataField: "programId",
                                label: {
                                    text: "Program",
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: programs(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'program',
                                    searchEnabled: true,
                                    readOnly:true
                                }
                            },
                            {
                                dataField:'noPemeliharaan',
                                label:{
                                    text:'No Pemeliharaan'
                                },
                                editorOptions: {
                                    readOnly:true
                                }
                            },
                            {
                                dataField:'noRegAsset',
                                label:{
                                    text:'No Reg Aset'
                                },
                                editorOptions: {
                                    // readOnly:true
                                }
                            },
                            {
                                dataField:'tanggalTransaksi',
                                label:{
                                    text:'Tanggal Transaksi'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField:'tanggalPembayaran',
                                label:{
                                    text:'Tanggal Pembayaran'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                    openOnFieldClick: true,
                                }
                            },
                            {
                                dataField:'supplierId',
                                label:{
                                    text:'Supplier'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: sdmSupplier(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (item) => {
                                        return item && item.kodeSupplier + ' - ' + item.namaSupplier
                                    }, // provides display values
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField:'penempatanBiayaId',
                                label:{
                                    text:'Penempatan Biaya'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id:1,
                                            value:'Pengurangan Depresiasi'
                                        },
                                        {
                                            id:2,
                                            value:'Biaya Langsung'
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption:'Transaksi Biaya',
                        items: [
                            {
                                dataField:'tipeTransaksi',
                                label:{
                                    text:'Tipe Transaksi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: tipeTransaksiSdmUmum(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'transactionTypeName', // provides display values
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField:'subTipeTransaksi',
                                label:{
                                    text:'Sub Tipe Transaksi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: tipeSubTransaksiSdmUmum(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'tipeSubName', // provides display values
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField:'nominalPengajuan',
                                label:{
                                    text:'Nominal Pengajuan'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                            {
                                dataField:'ppn',
                                label:{
                                    text:'PPN'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                            {
                                dataField:'pph',
                                label:{
                                    text:'PPH'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                            {
                                dataField:'biayaLain',
                                label:{
                                    text:'Biaya Lain'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                            {
                                dataField:'potongan',
                                label:{
                                    text:'Potongan'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                            {
                                dataField:'nominalBersih',
                                label:{
                                    text:'Nominal Bersih'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                            {
                                dataField:'uangMuka',
                                label:{
                                    text:'Uang Muka'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                            {
                                dataField:'keterangan',
                                label:{
                                    text:'Keterangan'
                                },
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    {
                        itemType:'group',
                        caption:'Metode Pembayaran',
                        items:[
                            {
                                dataField:'transfer',
                                label:{
                                    text:'Transfer'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id:true,
                                            value:'Yes'
                                        },
                                        {
                                            id:false,
                                            value:'No'
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField:'cash',
                                label:{
                                    text:'Cash'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [
                                        {
                                            id:true,
                                            value:'Yes'
                                        },
                                        {
                                            id:false,
                                            value:'No'
                                        },
                                    ],
                                    valueExpr: 'id',
                                    displayExpr: 'value', // provides display values
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField:'cashOut',
                                label:{
                                    text:'Cash Out'
                                },
                                editorType:'dxNumberBox',
                                editorOptions:{
                                    format:"#,##0.00"
                                }
                            },
                        ]
                    },
                    {
                        itemType:'group',
                        caption:'Rekening Pembayaraan',
                        items:[
                            {
                                dataField:'nomorRekening',
                                label:{
                                    text:'No Rekening'
                                }
                            },
                            {
                                dataField:'namaRekening',
                                label:{
                                    text:'Nama Rekening'
                                }
                            },
                            {
                                dataField:'rekBankId',
                                label:{
                                    text:'Bank'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: banks(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'bankName', // provides display values
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField:'rekBankBranch',
                                label:{
                                    text:'Cabang'
                                }
                            },
                        ]
                    },
                    {
                        itemType:'group',
                        caption:'Currency',
                        items:[
                            {
                                dataField:'currencyId',
                                label:{
                                    text:'Currency'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyName', // provides display values
                                    searchEnabled: true,
                                }
                            },
                            {
                                dataField:'currencyRate',
                                label:{
                                    text:'Kurs'
                                }
                            },
                        ]
                    },
                    
                ]
            },
            
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Close",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.formRef = React.createRef()
        this.newData = true
    }

    retrieveData = (data) => {
        this.newData = false
        this.setState({
            DataDocument: data,
            DataMaster: data,
        })
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
        var files = e.value[i];
        
        var fr = new FileReader();

        var formattedFileData;

        var DataDocument = { ...this.state.DataDocument };

        fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);

            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;

            this.setState({
                DataDocument: DataDocument
            });
        };

        fr.readAsDataURL(files);
        }
    }

    show = () => {
        this.setState({
            popupVisible: true,
        })
    }
    
    hide = () => {
        this.setState({
            DataMaster:{
                currencyId:1001,
                currencyRate:1
            },
            DataDocument: {},
            popupVisible: false
        })
    }


    submitData = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.tanggalTransaksi = formatDate(DataMaster.tanggalTransaksi)
            DataMaster.tanggalPembayaran = formatDate(DataMaster.tanggalPembayaran)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pemeliharaans/simpanPemeliharaan', 'POST', {
                values: DataMaster,
            })
            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    kirimPengajuan = async () => {
        try {
            var DataMaster = {...this.state.DataMaster,...this.state.DataDocument}
            DataMaster.tanggalTransaksi = formatDate(DataMaster.tanggalTransaksi)
            DataMaster.tanggalPembayaran = formatDate(DataMaster.tanggalPembayaran)
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'asset-pemeliharaans/simpanPemeliharaan', 'POST', {
                values: DataMaster,
            })
            if (response) {
                try {
                    var kirim = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `asset-pemeliharaans/kirimPemeliharaan/${response.id}`, 'POST', {
                        values: {},
                    })
                    if (kirim) {
                        this.hide()
                        this.props.forceRefresh()
                        notify({ message: "Sukses!", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    }
                } catch (e) {
                    console.log(e);
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                }
                
            }
        } catch (e) {
            console.log(e);
            notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        }
    }

    updateData = () => {
    
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={false}
                showTitle={true}
                title={'Form Biaya'} 
                width={"100vh"}
                height={"75vh"}
                toolbarItems={this.props.from == 'riwayat' ? [] : this.PopupToolbarItem}
                ref={this.PopupRef}
            >
            <ScrollView width='100%' height='100%'>
                <Form
                    ref={this.formRef}
                    colCount={2}
                    id={'formPotongan'}
                    formData={this.state.DataMaster}
                    items={this.form}
                    labelLocation = 'left'
                />
                <Box direction={"row"} width={"100%"}>
                    <Item ratio={1}>
                        <FileUploader
                            accept={'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf'} 
                            uploadMode={'useForm'}
                            onValueChanged={this.onUploadStarted.bind(this)} 
                            labelText="File Dokumen"
                            showFileList={false}
                            name={'document'}
                            value = {this.state.fileUploadReceipt}
                            disabled={false }
                            ref={this.fileUploderRef}
                        />
                        <div className="row">
                            <label className="col-4">File Name:</label>
                            <label className="col-8">{ this.state.DataDocument.documentName}</label>
                        </div>
                    </Item>
                </Box>
            </ScrollView>
            </Popup>
        )
    }
}

export default FormModalBiayaPemeliharaanAset