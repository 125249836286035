import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { product } from 'dataSource/lookup'
import { formatDate, formatDefaultDate, formatDefaultFullDate, formatDefaultMonthYear, getSystemDate } from 'plugin/helper';
import React, { Component } from 'react'
import { Form } from "devextreme-react";
import { reportFile } from 'plugin/reportRequest';
import { showLoading } from "redux/actions/loading";
import uuidv4 from 'uuid/v4'
import { addURL } from 'redux/actions/url';
import httpRequest from 'plugin/httprequest';

export default class AkuntansiAsetNeto extends Component {
    constructor(props) {
        super(props)

        this.systemDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            tanggal: this.systemDate ? new Date(this.systemDate.dateValue) : null,
            formFilter: {
                tanggal: this.systemDate ? new Date(this.systemDate.dateValue) : null,
                produkId: null
            }
        }

        this.dataGridRef = React.createRef()

        this.filterItem = [
            {
                dataField: "tanggal",
                label: {
                    text: "Tanggal",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxDateBox",
                editorOptions: {
                    useMaskBehavior: true,
                    width: '100%',
                    displayFormat: 'MMM yyyy',
                    calendarOptions: {
                        maxZoomLevel: 'year'
                    },
                    openOnFieldClick: true,
                    onValueChanged: () => {
                        this.dataGridRef.current.DataGrid.deleteColumn('currentPeriod')
                        this.dataGridRef.current.DataGrid.deleteColumn('prevPeriod')
            
                        let period = formatDefaultFullDate(this.state.formFilter.tanggal).split(' ');
                        period = `${period[1]} ${period[2]}`;
                        console.log(period);
            
                        this.setState({
                          tanggal: period
                        })
                    }
                }
            },
            {
                dataField: "produkId",
                label: {
                    text: "Produk",
                    alignment: "left",
                    location: "left"
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: product(this.props.store),
                    displayExpr: function (item) {
                        return item && item.productCode + " - " + item.productName;
                    },
                    valueExpr: 'id',
                    searchEnabled: true,
                    deferRendering: false,
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    // type:"default",
                    elementAttr: { class: "bg-dapen-default" },
                    onClick: () => {
                        const column = this.changeColumn()
                        this.dataGridRef.current.changeColumn(column)
                        this.dataGridRef.current.forceRefresh(true)
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.columns = this.changeColumn()
    }

      getMonthYear = (date) => {
        if (date) {
        //   let period = formatDefaultFullDate(date).split(' ');
        //   period = `${period[1]} ${period[2]}`;
        //   return period;
            let periodDate = this.state.formFilter.tanggal ? this.state.formFilter.tanggal : null;
            let selectedMonth = periodDate.getMonth();
            let selectedYear = periodDate.getFullYear();
            periodDate = formatDefaultDate(new Date(selectedYear, selectedMonth + 1, 0)).split(' ');
            periodDate = `${periodDate[1]} ${periodDate[2]}`;
            return periodDate;
        }
        return ''; // Handle the case when date is not available
      }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    changeColumn = () => {
        var filteredDate
        if (this.state) {
            filteredDate = new Date(this.state.formFilter.tanggal)
        } else {
            filteredDate = this.systemDate ? new Date(this.systemDate.dateValue) : null
        }

        const prevDate = new Date(filteredDate.getFullYear() - 1, 12, 0, 0, 0, 0, 0)
        const currentDate = new Date(filteredDate.getFullYear(), filteredDate.getMonth() + 1, 0, 0, 0, 0, 0)

        return [
            {
                dataField: 'description',
                caption: 'Uraian',
                cellRender: (e) => {
                    const paddingLevel = 10 * e.row.data.level
                    return <span className={`${e.row.data.seqNo === null || e.row.data.level === 0 ? 'font-weight-bold' : ''}`} style={{ paddingLeft: `${paddingLevel}px` }}>{e.text}</span>
                }
            },
            {
                dataField: 'currentAmount',
                caption: `${formatDefaultMonthYear(currentDate)}`,
                format: '#,##0.00',
                alignment: 'right',
                width: '300px',
                cellRender: (e) => {
                    // const paddingLevel = 30 * e.row.data.level
                    return <span className={`${e.row.data.seqNo === null || e.row.data.level === 0 ? 'font-weight-bold' : ''}`}>{e.text}</span>
                }
            },
            {
                dataField: 'prevAmount',
                caption: `${formatDefaultMonthYear(prevDate)}`,
                format: '#,##0.00',
                alignment: 'right',
                width: '300px',
                cellRender: (e) => {
                    // const paddingLevel = 30 * e.row.data.level
                    return <span className={`${e.row.data.seqNo === null || e.row.data.level === 0 ? 'font-weight-bold' : ''}`}>{e.text}</span>
                }
            },
        ]
    }

    loadData = async (OJKProdukId = null, OJKTanggal = null) => {
        const produkId = OJKProdukId || this.state.formFilter.produkId
        const tanggal = OJKTanggal || this.state.formFilter.tanggal

        if (produkId === null || produkId === '') {
            return []
        }

        var systemDate = new Date(this.systemDate ? this.systemDate.dateValue : null)
        const systemMonth = systemDate.getMonth()
        const systemYear = systemDate.getFullYear();
        systemDate = new Date(systemYear, systemMonth + 1, 0, 0, 0, 0, 0);

        var selectedDate = new Date(tanggal);
        const selectedMonth = selectedDate.getMonth()
        const selectedYear = selectedDate.getFullYear();
        selectedDate = new Date(selectedYear, selectedMonth + 1, 0, 0, 0, 0, 0);

        var lastYearDate
        if (OJKProdukId) {
            lastYearDate = new Date(selectedYear, selectedMonth, 0, 0, 0, 0, 0)
            this.lastYearDate = lastYearDate
        } else {
            lastYearDate = new Date(selectedYear - 1, 12, 0, 0, 0, 0, 0)
            this.lastYearDate = lastYearDate
        }

        var dataCoaPosition
        if (selectedDate < systemDate) {
            dataCoaPosition = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(selectedDate)}`)
            if (dataCoaPosition.length === 0) {
                return []
            }
        } else {
            dataCoaPosition = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-positions/getByProdukId/${produkId}`)
        }

        var dataCoaPositionLastYear = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `coa-position-dailies/getByProdukIdAndValueDate/${produkId}/${formatDate(lastYearDate)}`)

        dataCoaPosition = dataCoaPosition.map(value => {
            const dataPositionLastYear = dataCoaPositionLastYear.find(valueLast => value.coaId == valueLast.coaId)

            value.amount = value.balanceAmountBaseCurrency
            if (dataPositionLastYear) {
                value.prevAmount = dataPositionLastYear.balanceAmountBaseCurrency
            } else {
                value.prevAmount = 0
            }
            value.level = 1
            return value
        })

        var dataReportHeader = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, 'report-headers/getAllReportType/LK')
        dataReportHeader = dataReportHeader.find(value => value.reportCode === 'LAN')
        var dataMapping = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-header-details/getAllByReportHeaderId/${dataReportHeader.id}`)

        var dataMappingParent = dataMapping.filter(value => value.parentId === null)
        if (dataMappingParent.length > 0) {
            dataMappingParent = dataMappingParent.sort((a, b) => {
                if (a.seqNo > b.seqNo) {
                    return 1
                }
                if (a.seqNo === b.seqNo) {
                    return 0
                }
                if (a.seqNo < b.seqNo) {
                    return -1
                }
            })
        }

        var data = [], index = 0, total = 0, prevTotal = 0

        const loopData = async (dataMap, dataParent, dataBalance, dataset, level = 0) => {
            var totalAmount = 0, prevTotalAmount = 0

            var dataMappingChild = dataMap.filter(value => value.parentId === dataParent.id)

            var dataMappingCoa = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `report-coas/getAllByReportHeaderDetailsId/${dataParent.id}`)

            for (const coa of dataMappingCoa) {
                const position = dataBalance.find(value => value.coaId === coa.coaId)
                if (position) {
                    totalAmount = totalAmount + (position.amount * (coa.type === 'p' ? 1 : -1))
                    prevTotalAmount = prevTotalAmount + (position.prevAmount * (coa.type === 'p' ? 1 : -1))
                    // totalAmount = totalAmount + Math.abs(position.amount)
                    // prevTotalAmount = prevTotalAmount + Math.abs(position.prevAmount)
                }
            }

            if (dataMappingChild.length > 0) {
                dataset.push({
                    id: index++,
                    description: dataParent.description,
                    currentAmount: null,
                    prevAmount: null,
                    level: level,
                    reff: dataParent.id,
                    seqNo: null
                })

                dataMappingChild = dataMappingChild.sort((a, b) => {
                    if (a.seqNo > b.seqNo) {
                        return 1
                    }
                    if (a.seqNo === b.seqNo) {
                        return 0
                    }
                    if (a.seqNo < b.seqNo) {
                        return -1
                    }
                })

                for (const child of dataMappingChild) {
                    let res = await loopData(dataMap, child, dataBalance, dataset, level + 1)
                    totalAmount = totalAmount + res.totalAmount
                    prevTotalAmount = prevTotalAmount + res.prevTotalAmount
                }

                dataset.push({
                    id: index++,
                    description: 'Total ' + dataParent.description,
                    currentAmount: totalAmount,
                    prevAmount: prevTotalAmount,
                    level: level,
                    reff: dataParent.id,
                    seqNo: null
                })
            } else {
                dataset.push({
                    id: index++,
                    description: dataParent.description,
                    currentAmount: totalAmount,
                    prevAmount: prevTotalAmount,
                    level: level,
                    reff: dataParent.id,
                    seqNo: dataParent.seqNo
                })
            }

            return {
                totalAmount: totalAmount,
                prevTotalAmount: prevTotalAmount
            }
        }

        for (const parent of dataMappingParent) {
            const res = await loopData(dataMapping, parent, dataCoaPosition, data)

            total = total + res.totalAmount
            prevTotal = prevTotal + res.prevTotalAmount
        }

        const totalAset = data.find(value => value.description === 'Total ' + dataMappingParent[0].description)
        const totalLiabilitas = data.find(value => value.description === 'Total ' + dataMappingParent[1].description)

        data[data.length - 1].currentAmount = totalAset.currentAmount - totalLiabilitas.currentAmount
        data[data.length - 1].prevAmount = totalAset.prevAmount - totalLiabilitas.prevAmount

        return data
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'print',
                    onClick: (e) => {
                        this.print()
                    },
                }
            }
        )
    }

    print = () => {
        this.props.store.dispatch(showLoading(true))

        var endPeriod = this.state.formFilter.tanggal, prevPeriodDate, startPeriod;
        if (!(endPeriod instanceof Date)) {
            endPeriod = new Date(endPeriod)
        }
        const selectedMonth = endPeriod.getMonth()
        const selectedYear = endPeriod.getFullYear();

        prevPeriodDate = new Date(selectedYear, 0, 0);
        endPeriod = new Date(selectedYear, selectedMonth + 1, 0);
        startPeriod = new Date(selectedYear, 0, 1);
        var data = this.dataGridRef.current.getDataSource()

        let periodDate = formatDefaultFullDate(endPeriod).split(' ');
        periodDate = periodDate[1] + ' ' + periodDate[2];

        reportFile({
            template: { 'shortid': 'r1xfS7QxnK' },
            data: {
                currentPeriod: formatDefaultDate(endPeriod),
                prevPeriod: formatDefaultDate(prevPeriodDate),
                dataAsetNeto: data
            },
            options: {
                reportName: `Aset Neto ${periodDate}`
            }
        }, true, `Aset Neto ${periodDate}`)

        this.props.store.dispatch(showLoading(false))
    }

    render() {
        return (
            <div className="container-fluid">
                <h2 className="main-title">Laporan Aset Neto</h2>
                <Form
                    colCount={3}
                    id={'formFilter'}
                    formData={this.state.formFilter}
                    items={this.filterItem}
                />

                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='trial-balances'
                    insertAPI='trial-balances'
                    updateAPI='trial-balances'
                    deleteAPI='trial-balances'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={`Aset Neto ${this.getMonthYear(this.state.tanggal)}`}
                    allowExportSelectedData={false}
                    selection={"none"}

                    exportWithFormatNum={true}
                    exceptFieldExportFormtNum={
                        [
                            'currentAmount',
                            'prevAmount'
                        ]
                    }

                    showBorders={true}

                    paging={false}
                    showPageSizeSelector = {false}
                    defaultPageSize={10}
                    grouping={false}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Laporan Aset Neto'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                    FilterRow={false} // set false untuk mematikan fitur filter

                    height={'calc(100vh - 250px)'}

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    sortingMode={'none'}

                    onToolbarPreparing={this.onToolbarPreparing}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                {/* <span style={{ fontSize: '14px' }}>* Data per tanggal {this.state.tanggal ? formatDefaultDate(this.state.tanggal) : ''}</span> */}
            </div>
        )
    }
}
