import React, { Component } from 'react'
import { FileUploader, Form, Popup, ScrollView } from 'devextreme-react'
import notify from "devextreme/ui/notify";
import {sppdBiaya,karyawan, jabatan, sdmUnit, citiesFilter, provinces, cities, provincesFilter, countries, programs, sppdType, jabatanGolongan} from 'dataSource/lookup';
import { formatUploadFileData,dateAddDays, imageSource, formatDate, daysDiff } from 'plugin/helper';
import ModalPreviewPdf from 'components/pages/modal/kepesertaan/compPeserta/previewPdf';
import ModalPreviewImage from 'components/pages/modal/kepesertaan/compPeserta/previewImage';
import httpRequest from 'plugin/httprequest';

class FormPenugasanPerjalananDinas extends Component {
    constructor(props) {
        super(props)
        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.state = {
            dataPengajuan: {
                countryId:130,
                // tanggalAwal:new Date(),
                hari:0
            },
            DataDocument:{},
            uploadedData:[]
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.modalPreviewPdfRef = React.createRef()
        this.modalPreviewImageRef = React.createRef()
        this.disableEvent = false

        this.PopupToolbarItem = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.submitData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];
        this.PopupToolbarItem2 = [
            {
                widget: "dxButton",
                location: "after",
                options: {
                  text: "Simpan",
                  onClick: this.updateData.bind(this)
                },
                toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            },
        ];

        this.FormPenugasanPerjalananDinas = [
            {
                dataField: 'programId',
                label: {
                    text: 'Program'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    readOnly: this.props.action == 'edit' ? true : false,
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                },
            },
            {
                dataField: 'noPenugasan',
                label: {
                    text: 'No. Penugasan'
                },
                editorOptions: {
                    readOnly:true
                },
            },
            {
                dataField: 'karyawanId',
                label: {
                    text: 'Karyawan',
                },
                editorType: 'dxSelectBox',
                editorOptions:{
                    dataSource: karyawan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: function(item){
                        return item && "NIK : " + item.nik + " - " + item.fullName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged:async(e) => {
                        if (e.selectedItem) {
                            var getJagol = await httpRequest(
                                process.env.REACT_APP_BACKEND_HRD,
                                this.props.store,
                                `jabatans/${e.selectedItem.jabatanId}`,
                                "GET"
                            );
                
                            this.formMasterRef.current.instance.updateData({golonganJabatanId:getJagol.jabatanGolonganId,unitId:e.selectedItem.unitId})
                        }
                    }
                }
            },
            {
                dataField: 'golonganJabatanId',
                label: {
                    text: 'Golongan Jabatan'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    readOnly: this.props.action == 'edit' ? true : false,
                    dataSource: jabatanGolongan(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'namaJagol', // provides display values
                },
            },
            {
                dataField: 'unitId',
                label: {
                    text: 'Unit'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    readOnly: this.props.action == 'edit' ? true : false,
                    dataSource: sdmUnit(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'unitName', // provides display values
                },
            },
            {
                dataField: 'sppdTypeId',
                label: {
                    text: 'Tipe Perjalanan Dinas'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: sppdType(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'sppdName', // provides display values
                }
            },
            {
                dataField: 'countryId',
                label: {
                    alignment: 'left',
                    text: 'Negara'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: countries(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'countryName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (data) => {
                        var selectProvince = this.formMasterRef.current.instance.getEditor('provinceId')
                        // selectProvince.option('value', '')
                        if(data.value){
                            selectProvince.option('dataSource',provincesFilter(this.props.store,data.value,true))
                        }
                    }
                },
            },
            {
                dataField: 'provinceId',
                label: {
                    alignment: 'left',
                    text: 'Provinsi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: provinces(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'provinceName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (data) => {
                        var selectCity = this.formMasterRef.current.instance.getEditor('cityId')
                        // selectCity.option('value', '')
                        if(data.value){
                            selectCity.option('dataSource',citiesFilter(this.props.store,data.value,true))
                        }
                    }
                },
            },
            {
                dataField: 'cityId',
                label: {
                    alignment: 'left',
                    text: 'Kota'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: cities(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'cityName', // provides display values'
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: (data) => {
                        // var selectKecamatan = this.formMasterRef.current.instance.getEditor('identityKecamatanId')
                        // // selectKecamatan.option('value', '')
                        // if(data.value){
                        //     selectKecamatan.option('dataSource',kecamatansFilter(this.props.store,data.value,true))
                        // }
                    }
                },
            },
            {
                dataField: 'tanggalAwal',
                label: {
                    text: 'Dari Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%',
                    onValueChanged:(e) => {
                        if (e.value && !this.disableEvent) {
                            var tglAwal = e.value;
                            var tglAkhir = this.formMasterRef.current.instance.getEditor('tanggalAkhir').option('value');

                            var dayDiffs = daysDiff(tglAkhir, tglAwal) + 1;

                            // console.log(dayDiffs)
                            // var timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000;
                            // var daysCount = Math.floor(timeDiff / (86400)) + 1;
                            // var range = this.getDaysArray(new Date(tglAwal), new Date(tglAkhir));
                            // let res = []
                            // for (let r of range) {
                            //     let dt = {
                            //         day: r.getDay()
                            //     }
                            //     res.push(dt)
                            // }

                            if (this.props.action !== 'edit') {
                                if (tglAwal && tglAkhir !== null) {
                                    this.formMasterRef.current.updateData({hari: dayDiffs})
                                }
                            } else {
                                if (tglAwal && tglAkhir !== null) {
                                    this.formMasterRef.current.instance.updateData({hari: dayDiffs});
                                }
                            }

                        }
                    }
                }
            },
            {
                dataField: 'tanggalAkhir',
                label: {
                    text: 'Sampai Tanggal'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                    width: '100%',
                    onValueChanged:(e) => {
                        // if (e.value && !this.disableEvent) {
                        //     var tglAwal = this.formMasterRef.current.instance.getEditor('tanggalAwal').option('value');
                        //     var tglAkhir = e.value;
                        //     var timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000
                        //     var daysCount = Math.floor(timeDiff / (86400)) + 1
                        //     var range = this.getDaysArray(new Date(tglAwal), new Date(tglAkhir))
                        //     let res = []
                        //     for (let r of range) {
                        //         let dt = {
                        //             day: r.getDay()
                        //         }
                        //         res.push(dt)
                        //     }

                        //     if (this.props.action !== 'edit') {
                        //         if (tglAwal && tglAkhir !== null) {
                        //             this.formMasterRef.current.instance.updateData({hari: daysCount})
                        //         }
                        //     } else {
                        //         if (tglAwal && tglAkhir !== null) {
                        //             this.formMasterRef.current.instance.updateData({hari: daysCount});
                        //         }
                        //     }
                        // }
                        if (e.value && !this.disableEvent) {
                                var tglAwal = this.formMasterRef.current.instance.getEditor('tanggalAwal').option('value');
                                var tglAkhir = e.value;

                                var dayDiffs = daysDiff(tglAkhir, tglAwal) + 1
                            // var timeDiff = (new Date(tglAkhir).getTime() - tglAwal) / 1000;
                            // var daysCount = Math.floor(timeDiff / (86400)) + 1;
                            // var range = this.getDaysArray(new Date(tglAwal), new Date(tglAkhir));
                            // let res = []
                            // for (let r of range) {
                            //     let dt = {
                            //         day: r.getDay()
                            //     }
                            //     res.push(dt)
                            // }

                            if (this.props.action !== 'edit') {
                                if (tglAwal && tglAkhir !== null) {
                                    this.formMasterRef.current.instance.updateData({hari: dayDiffs})
                                }
                            } else {
                                if (tglAwal && tglAkhir !== null) {
                                    this.formMasterRef.current.instance.updateData({hari: dayDiffs});
                                }
                            }

                        }
                    }
                }
            },
            {
                dataField: 'hari',
                label: {
                    text: 'Jumlah Hari'
                },
                editorType: 'dxNumberBox',
                editorOptions:{
                    readOnly: true,
                    // onValueChanged:(e) => {
                    //     if (e.value && !this.disableEvent) {
                    //         var DataMaster = this.state.dataPengajuan
                    //         var endDate = dateAddDays(DataMaster.tanggalAwal,e.value)
    
                    //         this.formMasterRef.current.instance.updateData({tanggalAkhir:endDate})
                    //     }
                    // }
                }
            },
            {
                dataField: 'paymentDate',
                label: {
                    text: 'Tanggal Pembayaran'
                },
                editorType: 'dxDateBox',
                editorOptions: {
                    openOnFieldClick:true,
                    useMaskBehavior:true,
                    displayFormat:"dd MMM yyyy",
                }
            },
            {
                dataField: 'keterangan',
                label: {
                    text: 'Keterangan'
                },
                editorType: 'dxTextArea',
                editorOptions: {
                    height: '200px'
                }
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    generateNoPenugasan = async() => {
        var generateNo = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `generate-no-automatic/KaryawanSppd/penugasanNo/PENUGASAN`,
            'POST',{values:{}}
        )

        this.formMasterRef.current.instance.updateData('noPenugasan', generateNo)
    }
    

    getDaysArray(start, end) {
        for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    };
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataPengajuan: {
                countryId:130,
                // tanggalAwal:new Date(this.sysDate ? this.sysDate.dateValue : null),
                hari:0
            }
        })
    }

    previewFile = async(state, fileName) => {
        var src = imageSource(state[`${fileName}FileContentType`],state[`${fileName}File`])
        this.modalPreviewPdfRef.current.show()
        this.modalPreviewPdfRef.current.retrieveData(src)
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        this.fieldName = fieldName
        for (var i = 0; i < e.value.length; i++) {
          var files = e.value[i];
          
          var fr = new FileReader();
    
          var formattedFileData;
    
          var DataDocument = { ...this.state.DataDocument };
    
          fr.onload = efr => {
            formattedFileData = formatUploadFileData(efr.target.result);
    
            DataDocument[`${fieldName}Name`] = files.name;
            DataDocument[`${fieldName}File`] = formattedFileData.base64data;
            DataDocument[`${fieldName}FileContentType`] = formattedFileData.mime;
            this.setState({
              DataDocument: DataDocument
            });
          };
    
          fr.readAsDataURL(files);
        }
    };

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        try {
            var DataMaster = {...this.state.dataPengajuan,...this.state.DataDocument}
            DataMaster.tanggalDari = formatDate(DataMaster.tanggalAwal)
            DataMaster.tanggalSampai = formatDate(DataMaster.tanggalAkhir)
            DataMaster.pembayaranDate = formatDate(DataMaster.paymentDate)
            DataMaster.jumHari = DataMaster.hari

            var loadApi = 'simpan-perjalanan-dinas-master'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "POST",{
                    values: DataMaster
                }
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    updateData = async() => {
        try {
            var DataMaster = {...this.state.dataPengajuan,...this.state.DataDocument}
            var loadApi = 'karyawan-sppds'
            var response = await httpRequest(
                process.env.REACT_APP_BACKEND_HRD,
                this.props.store,
                loadApi,
                "PUT",{
                    values: DataMaster,
                    key:DataMaster.id
                },DataMaster
            );

            if (response) {
                this.hide()
                this.props.forceRefresh()
                notify({ message: 'Sukses Proses Data!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600); 
            }
        } catch (e) {
            console.log(e)
            notify({ message: `Terjadi Kesalahan System (${e})`, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);  
        }
    }

    retrieveData = async(data) => {   
        this.disableEvent = true 
        var fileDokumen = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `karyawan-sppds/${data.id}`,
            "GET"
        );
        this.setState({
            dataPengajuan: data,
            DataDocument:fileDokumen
        })

        this.disableEvent = false
    }
    
    render() {
        return (
            <React.Fragment>
                <Popup
                className={"popup"}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                showTitle={true}
                title={"Form Perjalanan Dinas"}
                width={"55vw"}
                height={"60vh"}
                toolbarItems={this.props.action == 'add' ? this.PopupToolbarItem : this.PopupToolbarItem2}
                >
                    <ScrollView height="100%" width="100%">
                        <div className="container-fluid">
                        <Form
                            ref={this.formMasterRef}
                            colCount={1}
                            id={'formPenugasanPerjalananDinas'}
                            formData={this.state.dataPengajuan}
                            items={this.FormPenugasanPerjalananDinas}
                            labelLocation = 'left'
                        />
                        <FileUploader
                            disabled={this.props.action == 'view' ? true : false}
                            accept={".png,.jpg,.jpeg,.gif,.bmp,application/pdf"}
                            uploadMode={"useForm"}
                            onValueChanged={this.onUploadStarted.bind(this)}
                            labelText={this.state.DataDocument.dokumenName || "Upload File Here"}
                            showFileList={false}
                            name={"dokumen"}
                            value={this.state.uploadedData}
                            ref={this.fileUploderRef}
                        />
                            <div className="col-md-4 text-right">
                                <button onClick={() => this.previewFile(this.state.DataDocument,'dokumen')} className={`btn btn-light btn-sm border rounded ${this.state.DataDocument.dokumenName ? '' : 'd-none'}`} style={{ width: '122px' }}>
                                    Preview
                                </button>
                            </div>
                        </div>
                    </ScrollView>
                </Popup>
                <ModalPreviewPdf
                    ref={this.modalPreviewPdfRef}
                    store={this.props.store}
                />
                <ModalPreviewImage
                    ref={this.modalPreviewImageRef}
                    store={this.props.store}
                />
            </React.Fragment>
        )
    }
}

export default FormPenugasanPerjalananDinas