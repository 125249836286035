import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item } from "devextreme-react/html-editor";
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, currency, kpds, managerInvestasis, productDefault, rekeningBank } from 'dataSource/lookup';
import { formatDate, formatUploadFileData } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';
import notify from 'devextreme/ui/notify'


class ModalNewBilyet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popupVisible: false,
            dataMaster: [],
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
        this.formItem = [
            {
                itemType: 'group',
                caption: 'PERUBAHAN NO BILYET',
                items: [
                    {
                        dataField: 'instrumentId',
                        label: {
                            text: 'ID Instrument',
                            alignment: 'left'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'oldBilyet',
                        label: {
                            text: 'No Bilyet Lama',
                            alignment: 'left'
                        },
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'newBilyet',
                        label: {
                            text: 'No Bilyet Baru',
                            alignment: 'left'
                        },
                    },
                ]
            },
        ]
    }
    submitData = async () => {
        return false
    }
    show(data) {
        this.setState({
            popupVisible: true,
            dataMaster: data
        });
    }
    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: [],
        })
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Edit Bilyet'}
                width={'30vw'}
                height={'28vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={' mt-2 col-md-12'}>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <Form
                                        colCount={1}
                                        id={"formMaster"}
                                        formData={this.state.dataMaster}
                                        items={this.formItem}
                                        ref={this.formRef}
                                        labelLocation="left"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalNewBilyet