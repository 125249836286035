import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest'
import { managerInvestasis, custodies, currency,instrumentTypes, instrumentsCategoryEtf, instrumentSubCategoryById } from 'dataSource/lookup';
import { ScrollView } from 'devextreme-react';


class ModalEtf extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            newData:false,
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.popupToolbarItem2 = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.updateData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                dataField: 'instrumentCode',
                label: {
                    text: 'Kode ETF'
                }
            },
            {
                dataField: 'instrumentName',
                label: {
                    text: 'Nama ETF'
                }
            },
            {
                dataField: 'categorySubId',
                label: {
                    text: 'Jenis ETF'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: instrumentsCategoryEtf(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'subName',
                    searchEnabled: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'status',
                label: {
                    text: 'Status'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 1,
                            value: 'Active'
                        },
                        {
                            id: 2,
                            value: 'Delisting'
                        },
                        {
                            id: 3,
                            value: 'Suspend'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'managerInvestasiId',
                label: {
                    text: 'Manager Investasi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: managerInvestasis(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'miName',
                    searchEnabled: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'custodyId',
                label: {
                    text: 'Kustodi'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: custodies(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'custodyName',
                    searchEnabled: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'isinCode',
                label: {
                    text: 'Isin Code'
                }
            },
            {
                dataField: 'currencyId',
                label: {
                    text: 'Mata Uang'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: currency(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'currencyName',
                    searchEnabled: true,
                    openOnFieldClick: true,
                }
            },
            {
                dataField: 'syariah',
                label: {
                    text: 'Syariah'
                },
                editorType: 'dxCheckBox'
            },
        ]
    }

    newData = (newData) => {
        this.setState({
          newData : newData
        })
    }
 
    retrieveData = (data) => {
        var DataMaster = {...this.state.dataMaster}
        DataMaster.id = data.id
        DataMaster.instrumentCode = data.instrumentCode
        DataMaster.instrumentName = data.instrumentName
        DataMaster.categorySubId = data.instrumentCategorySubId
        DataMaster.managerInvestasiId = data.miId
        DataMaster.status = data.status
        DataMaster.custodyId = data.custodyId
        DataMaster.isinCode = data.isinCode
        DataMaster.currencyId = data.currencyId
        DataMaster.syariah = data.syariah

        this.setState({
            dataMaster: DataMaster,
        })
    }

    submitData = async () => {
        try {
            let type = 'success'
            let text = 'Sukses input !'
            var loadAPI = `staticdata-wf/instruments`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'POST', {
                values: this.state.dataMaster,
            })

            if (response) {
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.hide()
            } else {
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
        }
    }

    updateData = async () => {
        try {
            let type = 'success'
            let text = 'Sukses ubah !'
            var loadAPI = `staticdata-wf/instruments`
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'PUT', {
                values: this.state.dataMaster,
                key:this.state.dataMaster.id
            },this.state.dataMaster)

            if (response) {
                notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                this.hide()
            } else {
                notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        } catch (e) {
            console.log(e)
        }
    }

    show() {
        this.setState({
            popupVisible: true,
        })
    }

    hide() {
        this.setState({
            popupVisible: false,
            dataMaster: {}
        })
    }
    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={false}
                    resizeEnabled={true}
                    showTitle={true}
                    width={'55vw'}
                    height="50vh"
                    title={`Form ETF`}
                    ref={this.PopupRef}
                    toolbarItems={this.state.newData ? this.popupToolbarItem2 : this.popupToolbarItem}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={1}
                            id={'dataMaster'}
                            formData={this.state.dataMaster}
                            items={this.dataMaster}
                            labelLocation={"left"}
                        />
                    </ScrollView>
                </Popup>
            </React.Fragment>
        )
    }
}

export default ModalEtf