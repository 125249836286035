import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'

class ModalKategoriAsetAlokasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: console.log('simpan')
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Close',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Edit',
                        hint : 'Edit',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.props.showModalActionKategoriAsetAlokasi('edit', e.row.data)
                        }
                    }
                ],
            },
            {
                dataField: 'id',
                caption: 'No'
            },
            {
                dataField: 'kategori',
                caption: 'Kategori'
            },
            {
                dataField: 'subKategori',
                caption: 'Sub Kategori'
            },
            {
                dataField: 'alokasi',
                caption: 'Alokasi (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'notifikasiMinus',
                caption: 'Notifikasi Minus (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'notifikasiPlus',
                caption: 'Notifikasi Plus (%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
    }
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.setState({
            popupVisible: false,
            dataMaster: {},
            typeData : {}
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    submitData = async() => {
        var dataSubmit = {...this.state.dataMaster}

        //  
        await this.props.submitData(dataSubmit)
        this.hide()
    }

    

    retrieveData = async(data) => {    
        this.setState({
            dataMaster: data,
        })
    }

    loadDummyData = () => {
        var dummy = [
            {
                id: 1,
                kategori: 'Telah di Alokasi',
                subKategori: 'sub1',
                sektor: 'Telah Di Alokasi',
                alokasi: 70,
                notifikasiMinus: 40,
                notifikasiPlus: 30,
            },
            {
                id: 2,
                kategori: 'Telah di Alokasi',
                subKategori: 'sub2',
                sektor: 'Telah Di Alokasi',
                alokasi: 70,
                notifikasiMinus: 40,
                notifikasiPlus: 30,
            },
            {
                id: 3,
                kategori: 'Telah di Alokasi',
                subKategori: 'sub3',
                sektor: 'Telah Di Alokasi',
                alokasi: 70,
                notifikasiMinus: 40,
                notifikasiPlus: 30,
            },
        ]


        return dummy
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    text: 'Kategori',
                    icon: 'plus',
                    onClick: (e) => {
                        this.props.showModalActionKategoriAsetAlokasi('add')
                    }
                }
            }
        )
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Kategori Aset'}
                minWidth={800}
                minHeight={450}

                ref={this.PopupRef}

                toolbarItems = {this.popupToolbarItem}
            >
                <div className="container-fluid" style={{overflowY: 'auto', height: '100%'}}>
                    <div className="row">
                        <div className="col-md-12">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                loadAPI='kategori-aset'
                                insertAPI='kategori-aset'
                                updateAPI='kategori-aset'
                                deleteAPI='kategori-aset' 

                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                useArraySource = {true}
                                ArraySourceData = {this.loadDummyData}

                                allowAdding={false}
                                allowDeleting={true}
                                allowUpdating={false}

                                exportExcel={true}
                                exportFileName={"Konfirmasi Deposito"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Konfirmasi Deposito'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                // SummaryConfiguration={this.summary}

                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalKategoriAsetAlokasi