import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader } from 'devextreme-react';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { imageSource, formatUploadFileData } from 'plugin/helper';
import { dayBase } from 'dataSource/lookup';
import { confirm } from 'devextreme/ui/dialog'


class FormEditorJournalType extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
        }

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formRef = React.createRef()

        this.isNew = false

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
        ]

        this.dataMaster = [
            {
                dataField: 'journalTypeCode',
                label: {
                    text: 'Journal Type Code'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                },
                isRequired: true
            },
            {
                dataField: 'journalTypeDesc',
                label: {
                    text: 'Journal Type Description'
                },
                colSpan: 2,
                editorType: 'dxTextBox',
                editorOptions: {
                },
                isRequired: true
            },
            {
                dataField: 'manual',
                label: {
                    text: 'Manual'
                },
                editorType: 'dxCheckBox',
            },
            {
                dataField: 'serviceName',
                label: {
                    text: 'Service Name'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [
                        {
                            id: 'KPS',
                            value: 'Kepesertaan'
                        },
                        {
                            id: 'INV',
                            value: 'Investasi'
                        },
                        {
                            id: 'SDM',
                            value: 'SDM & Umum'
                        },
                        {
                            id: 'ACC',
                            value: 'Akuntansi'
                        },
                    ],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    onValueChanged : (e) => {
                        this.onChangeService(e.value)
                    }
                },
                isRequired: true
            },
            {
                dataField: 'transactionType',
                label: {
                    text: 'Transaction Type'
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: [],
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true,
                    showClearButton: true
                }
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    getData = (data) => {
        this.formRef.current.instance.updateData(data)
    }

    show(dataMaster) {
        this.setState({
            popupVisible: true,
        });

    }

    hide = async(e) => {
        var result
        if (this.state.popupVisible === true && !this.isNew) {
            if (typeof e.cancel === 'boolean') {
                e.cancel = true
            }
            var result = await confirm('Apakah anda yakin untuk membatalkan input data ini?', 'Konfirmasi')
        } else {
            result = true
        }

        if (result) {
            if(!this.isNew) {
                if (typeof e.cancel === 'boolean') {
                    e.cancel = false
                }
            }

            this.setState({
                popupVisible: false,
                dataMaster: {},
            })
        }
    }

    onChangeService = async (serviceName) => {
        let data = []
        switch(serviceName){
            case 'KPS' : data = await httpRequest(process.env.REACT_APP_BACKEND_CORE,this.props.store,'kepersertaan-transaction-types?size=9999')
                         data = data.map(value => {
                            return {
                                id: value.id,
                                value: value.transactionName
                            }
                         })
                         break;
            case 'INV' : data = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI,this.props.store,'instrument-transaction-types')
                        data = data.map(value => {
                        return {
                            id: value.id,
                            value: value.transactionName
                        }
                        })
                         break;
            case 'SDM' : data = []
                         break;
            case 'ACC' : data = []
                         break;
            default: break;
        }

        const editorTransactionType = this.formRef.current.instance.getEditor('transactionType')
        editorTransactionType.option('dataSource',data)
    }

    submitData = async () => {
        var data = this.state.dataMaster
        const validation = this.formRef.current.instance.validate()

        if(validation.isValid){
            if (data.id) {
                try {
                    let type = 'success'
                    let text = 'Data Successfully Created!'
                    var updateAPI = `staticdata-wf/journal-types`
                    var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, updateAPI, 'PUT', {
                        values: this.state.dataMaster,
                        key: this.state.dataMaster.id
                    }, this.state.dataMaster)
    
                    if (response) {
                        notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                        this.isNew = true
                        this.hide()
                    } else {
                        notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                } catch (e) {
                    notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                    console.log(e)
                }
            } else {
                if (data) {
                    try {
                        let type = 'success'
                        let text = 'Data Successfully Updated!'
                        var insertAPI = `staticdata-wf/journal-types`
                        var response = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, insertAPI, 'POST', {
                            values: data,
                        })
                        if (response) {
                            notify({ message: text, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, type, 600);
                            this.isNew = true
                            this.hide()
                        } else {
                            notify({ message: 'Error, something when wrong!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                        }
                    } catch (e) {
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 1000);
                        console.log(e)
                    }
                }
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Journal Type Data`}
                    width={'500px'}
                    height="300px"

                    ref={this.PopupRef}

                    toolbarItems = {this.popupToolbarItem}
                >
                    <Form
                        ref = {this.formRef}
                        colCount={1}
                        id={'formDataMaster'}
                        formData={this.state.dataMaster}
                        items={this.dataMaster}
                        labelLocation={"left"}
                    />
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormEditorJournalType
