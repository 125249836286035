import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import notify from "devextreme/ui/notify";

import { banks, instrumentSubCategoryDeposito, currency, instruments, instrumentCategories, instrumentCategorySubs, ratings, emitens, custodies, rekeningBank, availableInstrumentCorporate, broker } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate, formatDefaultDate } from 'plugin/helper';
import ArrayStore from 'devextreme/data/array_store';


class FormSubInstrumentPelepasanObligasi extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
            instrumentId: 0
        }

        this.prevTabIndex = 0

        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formMasterRef = React.createRef()
        this.formDetailRef = React.createRef()

        this.formItem = [
            {
                itemType: 'group',
                items: [
                    {
                        dataField: 'noPengajuan',
                        label: {
                            text: 'No. Pengajuan'
                        },
                        editorOptions: {
                            readOnly: true, 
                        }
                    },
                    {
                        dataField: 'efekBalanceBuyDateId',
                        label: {
                            text: 'Instrument'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [],
                            valueExpr: 'id',
                            // displayExpr: 'instrumentCode',
                            displayExpr: function (item) {
                                return item && item.instrumentCode + " - " + formatDefaultDate(item.buyDate)
                            },
                            searchEnabled: true,
                            onSelectionChanged: async (e) => {
                                var data = e.selectedItem
                                if (data) {
                                    var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`, 'GET')
                                    var detail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/${data.id}`, 'GET')
                                    this.formMasterRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)

                                    this.formDetailRef.current.instance.getEditor('ratingId').option('value', instrument.rating1Id)
                                    this.formDetailRef.current.instance.getEditor('currencyId').option('value', instrument.currencyId)
                                    this.formDetailRef.current.instance.getEditor('emitenId').option('value', instrument.emitenId)
                                    this.formDetailRef.current.instance.getEditor('categoryId').option('value', 4)
                                    this.formDetailRef.current.instance.getEditor('instSubCategoryId').option('value', instrument.categorySubId)
                                    this.formDetailRef.current.instance.updateData('sbnCode', instrument.instrumentCode)
                                    this.formDetailRef.current.instance.updateData('sbnName', instrument.instrumentName)
                                    this.formDetailRef.current.instance.updateData('isinCode', instrument.isinCode)
                                    this.formDetailRef.current.instance.updateData('custodyId', instrument.custodyId)
                                    this.formDetailRef.current.instance.updateData('price', instrument.price)
                                    this.formDetailRef.current.instance.updateData('priceDate', instrument.priceDate)
                                    this.formDetailRef.current.instance.updateData('dayBases', instrument.dayBase)
                                    this.formDetailRef.current.instance.updateData('maturityDate', instrument.maturityDate)
                                    this.formDetailRef.current.instance.updateData('rate', instrument.rate)
                                    this.formDetailRef.current.instance.updateData('rateTypeId', instrument.rateTypeId)
                                    this.formDetailRef.current.instance.updateData('periodeKupon', instrument.couponPeriod)
                                    this.formDetailRef.current.instance.updateData('couponLastDate', instrument.couponLastDate)
                                    this.formDetailRef.current.instance.updateData('couponNxtDate', instrument.couponNextDate)
                                    this.formDetailRef.current.instance.updateData('nilaiPortofolio', detail.faceValue)
                                    this.formDetailRef.current.instance.updateData('accruedInterest', detail.accruedInterest)
                                    this.formDetailRef.current.instance.updateData('hargaPerolehan', detail.buyPrice)
                                    this.formDetailRef.current.instance.updateData('nilaiPerolehan', (detail.faceValue * detail.buyPrice) / 100)
                                    this.setState({
                                        instrumentId: data.instrumentId
                                    })   
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose instrument"
                            }
                        ]
                    },
                    {
                        dataField: 'tanggalPenjualan',
                        label: {
                            text: 'Tanggal Order',
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: 'dd MMM yyyy',
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'price',
                        label: {
                            text: 'Harga (%)'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.0000',
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill price"
                            }
                        ]
                    },
                    {
                        dataField: 'faceValue',
                        label: {
                            text: 'Face Value'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please fill face value"
                            }
                        ]
                    },
                    {
                        dataField: 'currencyId',
                        label: {
                            text: 'Mata Uang'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: currency(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.currencyCode + ' - ' + e.currencyName
                            },
                            readOnly: true,
                            onValueChanged: async (e) => {
                                var curr = await httpRequest(process.env.REACT_APP_BACKEND_ACC, this.props.store, `currencies/${e.value}`, 'GET')
                                this.formMasterRef.current.instance.updateData('currencyRate', curr.rate)
                            }
                        }
                    },
                    {
                        dataField: 'currencyRate',
                        label: {
                            text: 'Kurs'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'rekBankId',
                        label: {
                            text: 'Rekening Transfer'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: rekeningBank(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.rekNo + ' - ' + e.rekName
                            },
                            openOnFieldClick: true,
                            searchEnabled: true
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose rekening bank"
                            }
                        ]
                    },
                    {
                        dataField: 'institusiOrderId',
                        label: {
                            text: 'Institusi Order'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: [
                                {
                                    id: 1,
                                    value: 'Broker'
                                },
                                {
                                    id: 2,
                                    value: 'Bank'
                                },
                            ],
                            valueExpr: 'id',
                            displayExpr: 'value',
                            onValueChanged: (e) => {
                                var value = e.value
                                var brokerId = this.formMasterRef.current.instance.getEditor('brokerId')
                                var bankId = this.formMasterRef.current.instance.getEditor('bankId')
                                if (value == 1) {
                                    if (this.props.type !== 'view') {
                                        brokerId.option('readOnly', false)
                                        bankId.option('readOnly', true)
                                    }
                                    bankId.option('value', null)
                                } else {
                                    if (this.props.type !== 'view') {
                                        bankId.option('readOnly', false)
                                        brokerId.option('readOnly', true)
                                    }
                                    brokerId.option('value', null)
                                }
                            }
                        },
                        validationRules: [
                            {
                                type: "required",
                                message: "Please choose institusi order"
                            }
                        ]
                    },
                    {
                        dataField: 'bankId',
                        name: 'bankId',
                        label: {
                            text: 'Bank Order'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: banks(this.props.store),
                            valueExpr: 'id',
                            displayExpr: 'bankName',
                            searchEnabled: true,
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'brokerId',
                        label: {
                            text: 'Broker Order'
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                            dataSource: broker(this.props.store),
                            valueExpr: 'id',
                            displayExpr: (e) => {
                                return e && e.brokerCode + ' - ' + e.brokerName
                            },
                            searchEnabled: true,
                            readOnly: true
                        },
                    },
                ]
            },
        ]
        this.formDetailItem = [
            {
                itemType: 'group',
                items: [
                    {
                        itemType: 'group',
                        caption: 'INFORMASI Obligasi',
                        items: [
                            {
                                dataField: 'sbnCode',
                                label: {
                                    text: 'Kode Obligasi'
                                },
                            },
                            {
                                dataField: 'sbnName',
                                label: {
                                    text: 'Nama Obligasi'
                                },
                            },
                            {
                                dataField: 'categoryId',
                                label: {
                                    text: 'Kategori'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: instrumentCategories(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'categoryName'
                                }
                            },
                            {
                                dataField: 'instSubCategoryId',
                                label: {
                                    text: 'Sub Kategori'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: instrumentCategorySubs(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'subName'
                                }
                            },
                            {
                                dataField: 'isinCode',
                                label: {
                                    text: 'Isin Code'
                                },
                            },
                            // {
                            //     dataField: 'market',
                            //     label: {
                            //         text: 'Masrket'
                            //     },
                            // },
                            {
                                dataField: 'currencyId',
                                label: {
                                    text: 'Mata Uang'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: currency(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'currencyCode',
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField: 'ratingId',
                                label: {
                                    text: 'Rating'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: ratings(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: (e) => {
                                        return e && e.ratingName + ' (' + e.ratingCode + ')'
                                    }
                                }
                            },
                            // {
                            //     dataField: 'remark',
                            //     label: {
                            //         text: 'Remark'
                            //     },
                            // },
                            // {
                            //     dataField: 'commondCode',
                            //     label: {
                            //         text: 'Commond Code'
                            //     },
                            // },
                            {
                                dataField: 'emitenId',
                                label: {
                                    text: 'Emiten'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: emitens(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'emitenName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField: 'custodyId',
                                label: {
                                    text: 'Bank Kustodi'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: custodies(this.props.store),
                                    valueExpr: 'id',
                                    displayExpr: 'custodyName',
                                    searchEnabled: true,
                                    deferRendering: false,
                                }
                            },
                            {
                                dataField: 'price',
                                label: {
                                    text: 'Harga'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'priceDate',
                                label: {
                                    text: 'Harga Tanggal Terakhir'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy'
                                }
                            }
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                items: [
                    // {
                    //     itemType: 'group',
                    //     caption: 'INFORMASI HARGA TERAKHIR',
                    //     items: [
                    //         {
                    //             dataField: 'hargaWajar',
                    //             label: {
                    //                 text: 'Harga wajar'
                    //             },
                    //             editorType: 'dxNumberBox',
                    //             editorOptions: {
                    //                 format: '#,##0.00'
                    //             }
                    //         },
                    //         {
                    //             dataField: 'hargaTertinggi',
                    //             label: {
                    //                 text: 'Harga tertinggi'
                    //             },
                    //             editorType: 'dxNumberBox',
                    //             editorOptions: {
                    //                 format: '#,##0.00'
                    //             }
                    //         },
                    //         {
                    //             dataField: 'hargaTerendah',
                    //             label: {
                    //                 text: 'Harga terendah'
                    //             },
                    //             editorType: 'dxNumberBox',
                    //             editorOptions: {
                    //                 format: '#,##0.00'
                    //             }
                    //         },
                    //     ]
                    // },
                    // {
                    //     itemType: 'group',
                    //     caption: 'INFORMASI PENAWARAN',
                    //     items: [
                    //         {
                    //             dataField: 'periodePenawaran',
                    //             label: {
                    //                 text: 'Periode Penawaran'
                    //             },
                    //         },
                    //         {
                    //             dataField: 'issueDate',
                    //             label: {
                    //                 text: 'Tanggal Issue'
                    //             },
                    //             editorType: 'dxDateBox',
                    //             editorOptions: {
                    //                 displayFormat: 'dd MMM yyyy',
                    //                 useMaskBehavior: true
                    //             },
                    //         },
                    //         {
                    //             dataField: 'listedDate',
                    //             label: {
                    //                 text: 'Tanggal Listing'
                    //             },
                    //             editorType: 'dxDateBox',
                    //             editorOptions: {
                    //                 displayFormat: 'dd MMM yyyy',
                    //                 useMaskBehavior: true
                    //             },
                    //         },
                    //         {
                    //             dataField: 'efektifDate',
                    //             label: {
                    //                 text: 'Tanggal Efektif'
                    //             },
                    //             editorType: 'dxDateBox',
                    //             editorOptions: {
                    //                 displayFormat: 'dd MMM yyyy',
                    //                 useMaskBehavior: true
                    //             },
                    //         },
                    //         {
                    //             dataField: 'certificateNo',
                    //             label: {
                    //                 text: 'Certificate No.'
                    //             }
                    //         },
                    //         {
                    //             dataField: 'leadUnderwriter',
                    //             label: {
                    //                 text: 'Lead Underwriter'
                    //             }
                    //         },
                    //         {
                    //             dataField: 'agenPenjual',
                    //             label: {
                    //                 text: 'Agen Penjual'
                    //             }
                    //         },
                    //         {
                    //             dataField: 'truestee',
                    //             label: {
                    //                 text: 'Truestee'
                    //             }
                    //         },
                    //     ]
                    // },
                    {
                        itemType: 'group',
                        caption: 'INFORMASI BUNGA',
                        items: [
                            {
                                dataField: 'maturityDate',
                                label: {
                                    text: 'Maturity Date'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                }
                            },
                            // {
                            //     dataField: 'tipeAccued',
                            //     label: {
                            //         text: 'Tipe Accued'
                            //     },
                            // },
                            {
                                dataField: 'rateTypeId',
                                label: {
                                    text: 'Jenis Kupon'
                                }
                            },
                            {
                                dataField: 'rate',
                                label: {
                                    text: 'Rate Kupon'
                                },
                            },
                            {
                                dataField: 'periodeKupon',
                                label: {
                                    text: 'Periode Kupon'
                                },
                            },
                            {
                                dataField: 'dayBases',
                                label: {
                                    text: 'Basis Hari'
                                },
                            },
                            {
                                dataField: 'couponLastDate',
                                label: {
                                    text: 'Tanggal Kupon Terakhir'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                }
                            },
                            {
                                dataField: 'couponNxtDate',
                                label: {
                                    text: 'Tanggal Kupon Berikutnya'
                                },
                                editorType: 'dxDateBox',
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    useMaskBehavior: true,
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        caption: 'INFORMASI PORTOFOLIO',
                        items: [
                            {
                                dataField: 'nilaiPortofolio',
                                label: {
                                    text: 'Nilai Portofolio'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            // {
                            //     dataField: 'penerimaanKupon',
                            //     label: {
                            //         text: 'Penerimaan Kupon'
                            //     },
                            //     editorType: 'dxNumberBox',
                            //     editorOptions: {
                            //         format: '#,##0.00'
                            //     }
                            // },
                            {
                                dataField: 'accruedInterest',
                                label: {
                                    text: 'Bunga Berjalan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            // {
                            //     dataField: 'nilaiPenempatanInstrument',
                            //     label: {
                            //         text: 'Nilai Penempatan Instrument'
                            //     },
                            // },
                            // {
                            //     dataField: 'komposisiInstrument',
                            //     label: {
                            //         text: 'Komposisi Instrument'
                            //     },
                            // },
                            // {
                            //     dataField: 'komposisiEmiten',
                            //     label: {
                            //         text: 'Komposisi Emiten'
                            //     },
                            // },
                            {
                                dataField: 'hargaPerolehan',
                                label: {
                                    text: 'Harga Perolehan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                            {
                                dataField: 'nilaiPerolehan',
                                label: {
                                    text: 'Nilai Perolehan'
                                },
                                editorType: 'dxNumberBox',
                                editorOptions: {
                                    format: '#,##0.00'
                                }
                            },
                        ]
                    }
                ]
            }
        ]
        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }
    get Popup() {
        return this.PopupRef.current.instance
    }

    show (){
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data) => {
        console.log(data);
        if(data){
            let instrument = {}
            if (data.instrumentId) {
                instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`)
            }
            let seletcInstrument = this.formMasterRef.current.instance.getEditor('efekBalanceBuyDateId')
            seletcInstrument.option('dataSource', availableInstrumentCorporate(this.props.store, data.productId, data.kpdId))

            // let dataEfekBalance = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-buy-dates/${data.efekBalanceBuyDateId
            // }`, 'GET');

            this.setState({
                data: {
                    id: data.id,
                    noPengajuan: data.noPengajuan,
                    tanggalPenjualan: data.tanggalPenjualan,
                    instrumentId: data.instrumentId,
                    price: data.price,
                    faceValue: data.faceValue,
                    currencyId: data.currencyId,
                    currencyRate: data.currencyRate,
                    institusiOrderId: data.institusiOrderId,
                    rekBankId: data.rekBankId,
                    brokerId: data.brokerId,
                    bankId: data.bankId,
                    productId: data.productId,
                    // efekBalanceBuyDateId: data.efekBalanceBuyDateId,
                },
                dataDetail: {
                    ratingId: instrument.rating1Id,
                    currencyId: instrument.currencyId,
                    emitenId: instrument.emitenId,
                    categoryId: 4,
                    instSubCategoryId: instrument.categorySubId,
                    sbnCode: instrument.instrumentCode,
                    sbnName: instrument.instrumentName,
                    dayBases: instrument.dayBase,
                    maturityDate: instrument.maturityDate,
                    rate: instrument.rate,
                    accruedInterest: data.accruedInterest,
                    nilaiPortofolio: data.nilaiPortofolio,
                    couponLastDate: instrument.couponLastDate,
                    couponNxtDate: instrument.couponNextDate
                },
            });

            // if (this.state.data) {
            //     console.log('test');
            //     this.formMasterRef.current.instance.getEditor('efekBalanceBuyDateId').option('dataSource', this.availableCorporateInstrument(this.props.store, data.productId, data.kpdId))
            // }
        }
    }

    // addData = async (data) => {
    //     if (data) {
    //         console.log(data);
    //         this.setState({
    //             data: data
    //         })
    //     }
    // }

    hide() {
        this.setState({
            popupVisible: false,
        })
        this.props.forceRefresh()
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    submitData = () => {
        var data = this.state.data
        var detail = this.state.dataDetail
        if (data.bankId == undefined && data.brokerId == undefined) {
            notify({ message: 'Harap pilih bank/broker sesuai institusi order terpilih!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600)
        } else {
            this.props.submitDetailPelepasanData({
                id: data.id,
                noPengajuan: data.noPengajuan,
                tanggalPenjualan: formatDate(data.tanggalPenjualan),
                instrumentId: this.state.instrumentId,
                price: data.price,
                faceValue: data.faceValue,
                currencyId: data.currencyId,
                currencyRate: data.currencyRate,
                institusiOrderId: data.institusiOrderId,
                rekBankId: data.rekBankId,
                brokerId: data.brokerId,
                bankId: data.bankId,
                maturityDate: detail.maturityDate,
                ratingId: detail.ratingId,
                rate: detail.rate,
                instSubCategoryId: detail.instSubCategoryId,
                accruedInterest: detail.accruedInterest,
                nilaiPortofolio: detail.nilaiPortofolio,
                productId: data.productId,
                efekBalanceBuyDateId: data.efekBalanceBuyDateId,
            })

            this.hide()
        }
    }

    availableCorporateInstrument = async (dataMaster) => {   
        let loadAPI = `efek-balance-buy-dates/get-list-simple/${1}/${dataMaster.kpdId}/5`;
        let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, loadAPI, 'GET');
        let data = [];

        for (let value of response) {
            let dataValue = {
                ...value,
                id: value.id,
                instrumentCode: value.instrumentCode
                // instrumentCode: value.instrumentCode + " - " + formatDefaultDate(value.buyDate)
            }

            data.push(dataValue);
        }

        let filterData = data.filter(value => value.instrumentId === dataMaster.instrumentId);

        this.formMasterRef.current.instance.updateData('efekBalanceBuyDateId', filterData[0])
    }

    // availableCorporateInstrument = async (store, productId, kpdId) => {   
    //     let loadAPI = `efek-balance-buy-dates/get-list-simple/${productId}/${kpdId}/5`;
    //     let response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, store, loadAPI, 'GET');
    //     console.log(response);
    //     let stateData = this.state.data;
    //     let data = [];

    //     for (let value of response) {
    //         let dataValue = {
    //             ...value,
    //             id: value.id,
    //             instrumentCode: value.instrumentCode
    //             // instrumentCode: value.instrumentCode + " - " + formatDefaultDate(value.buyDate)
    //         }

    //         data.push(dataValue);
    //     }

    //     let filterData = data.filter(value => value.instrumentId === stateData.instrumentId);

    //     let dataArr = [];

    //     dataArr.push(filterData[0]);
    //     console.log(dataArr);
    //     let dataSource = new ArrayStore({
    //         data: dataArr,
    //         key: 'id'
    //     })

    //     console.log(dataSource);

    //     return dataSource
    // }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Usulan Pelepasan Investasi Obligasi'}
                width={'80vw'}
                height={'80vh'}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className={'mt-2 col-md-12'}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form
                                        colCount={1}
                                        id={'formMaster'}
                                        ref={this.formMasterRef}
                                        formData={this.state.data}
                                        items={this.formItem}
                                        scrollingEnabled={false}
                                        labelLocation={"left"}
                                        readOnly={this.props.type == 'view' ? true : false}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <Form
                                        colCount={2}
                                        id={'formMaster1'}
                                        ref={this.formDetailRef}
                                        formData={this.state.dataDetail}
                                        items={this.formDetailItem}
                                        scrollingEnabled={true}
                                        height={'calc(100vh - 380px)'}
                                        labelLocation={"left"}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default FormSubInstrumentPelepasanObligasi