import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import { Form } from "devextreme-react";
import { addURL } from 'redux/actions/url';
import { instrumentCategories, instruments, kpds, productDefault } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import { formatDate, getSystemDate } from 'plugin/helper';
import ArrayStore from 'devextreme/data/array_store';

class PortofolioKPD extends Component {
    constructor(props) {
        super(props)

        this.sysDate = props.store.getState().getParam ? props.store.getState().getParam.find(value => value.paramCode === '0 SYSDATE') : null

        this.date = new Date(this.sysDate ? this.sysDate.dateValue : null);

        this.dataSource = []
        this.state = {
            dataMaster: {},
            filterData: {
                productId: 1,
                // kpdId: 'MANDIRI MANJEMEN INVESTASI',
                kpdId: 3,
                reportDate: formatDate(this.date)
            },
        }
        this.formRef = React.createRef()
        this.filterItem = [
            {
                itemType: 'group',
                colCount: 2,
                items: [
                    {
                        itemType: 'group',
                        items: [
                            {
                                dataField: 'productId',
                                label: {
                                    text: 'Produk',
                                    alignment: "left"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: productDefault(this.props.store),
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "productCode", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                            {
                                dataField: 'kpdId',
                                label: {
                                    text: 'KPD',
                                    alignment: "left"
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                    dataSource: [],
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "noKontrak", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                        ]
                    },
                    {
                        dataField: 'Filter',
                        label: {
                            visible: false,
                        },
                        editorType: 'dxButton',
                        editorOptions: {
                            text: 'Filter',
                            elementAttr: { class: "bg-dapen-default" },
                            onClick: async(e) => {
                                let kpdId = this.formRef.current.instance.getEditor('kpdId').option('value');
                                let findIdKpd = this.data.find(value => value.id === kpdId).id;
                                this.setState({
                                    filterData: this.state.filterData
                                })
                                // this.loadData(this.state.filterData.productId, this.state.filterData.kpdId)
                                this.loadData(this.state.filterData.productId, findIdKpd)
                            }
                        }
                    }
                ]
            },
            {
                itemType: 'group',
                colSpan: 2,
                items: [
                    {
                        dataField: "miName",
                        label: {
                            text: "Manager Investasi",
                        },
                        editorOptions: {
                            readOnly: true,
                            width: '38%',
                        }
                    },
                    {
                        dataField: 'reportDate',
                        label: {
                            text: 'Tanggal Laporan',
                            alignment: "left",
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            displayFormat: 'dd MMM yyyy',
                            width: '38%',
                            readOnly: false
                        },
                    },
                    {
                        dataField: "custodyName",
                        label: {
                            text: "Kustodi",
                        },
                        editorOptions: {
                            readOnly: true,
                            width: '38%',
                        }
                    },
                    {
                        itemType: 'group',
                        colCount: 2,
                        items: [
                            {
                                dataField: "startDate",
                                label: {
                                    text: "Periode Kontrak",
                                },
                                editorType: "dxDateBox",
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    readOnly: true
                                }
                            },
                            {
                                dataField: "endDate",
                                label: {
                                    text: "s/d",
                                },
                                editorType: "dxDateBox",
                                editorOptions: {
                                    displayFormat: 'dd MMM yyyy',
                                    readOnly: true
                                }
                            },
                        ]
                    },
                    {
                        dataField: "kontrakValue",
                        label: {
                            text: "Nominal Nilai Kontrak",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            readOnly: true,
                            width: '38%',
                            inputAttr: {
                                style: 'text-align: right;'
                            }
                        }
                    },
                    {
                        dataField: "target",
                        label: {
                            text: "Target Keuntungan (%)",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            width: '38%',
                            readOnly: true,
                        }
                    },
                    {
                        dataField: "nominal",
                        label: {
                            text: "Total Penempatan Investasi",
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: '#,##0.00',
                            width: '38%',
                            readOnly: true,
                        }
                    },
                ]
            },

        ]

        this.summary = [
            // {
            //     displayFormat: 'Total',
            //     showInColumn: 'id'
            // },
            {
                name: 'nilaiPenempatan',
                column: 'nilaiPenempatan',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'accuredInterest',
                column: 'accuredInterest',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'unralizedNominal',
                column: 'unralizedNominal',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'nilaiSekarang',
                column: 'nilaiSekarang',
                summaryType: 'sum',
                valueFormat: '#,##0.00',
                displayFormat: '{0}'
            },
            {
                name: 'komposisi',
                column: 'komposisi',
                summaryType: 'sum',
                valueFormat: '#,##0',
                displayFormat: '{0}'
            },
        ]

        this.columns = [
            {
                dataField: 'id',
                caption: 'No',
                alignment: "right",
                cellRender: (e) => {
                    return e.rowIndex + 1 + '.'
                }
            },
            {
                dataField: 'categori',
                caption: 'Jenis Instrument',
            },
            {
                dataField: 'nilaiPenempatan',
                caption: 'Nilai Penempatan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiAccrued',
                caption: 'Bunga Berjalan',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'unrealizedPlPersen',
                caption: 'Unrealized P/L(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
                // format: '#,##0.0000'
            },
            {
                dataField: 'unrealizedPlNominal',
                caption: 'Unrealized P/L Nominal',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nilaiSekarang',
                caption: 'Nilai Saat Ini',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'komposisi',
                caption: 'Komposisi(%)',
                alignment: 'right',
                dataType: 'number',
                format: '#,##0.00'
                // format: '#,##0.0000'
            },
        ]
    }

    componentDidMount() {
        this.insertLookup()
        this.loadData(this.state.filterData.productId, this.state.filterData.kpdId)
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }
    insertLookup = async () => {
        var getKpd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpds`, 'GET')
        let dataKpd = new ArrayStore({
            data: getKpd,
            key: 'id'
        });

        this.data = dataKpd._array;
        // console.log(dataKpd._array);
        if (getKpd) {
            this.formRef.current.instance.getEditor('kpdId').option('dataSource', dataKpd)
        }
    }

    loadData = async (productId, kpdId) => {
        let kpd = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kpds/${kpdId}`, 'GET');
        let dataProduct = await httpRequest(process.env.REACT_APP_BACKEND, this.props.store, `products/${productId}`, 'GET')
        if (dataProduct) {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `view-portofolio-kpds?noKontrak.in=${kpd.noKontrak}&productName.in=${dataProduct.productName}`, 'GET')

            // var response = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avg-dailies/resume-portofolio-kpd/${productId}/${kpdId}/${formatDate(this.state.filterData.reportDate)}`, 'GET');
            if (response.length > 0) {
                this.formRef.current.instance.updateData({
                    miName: response[0].miName,
                    custodyName: response[0].custodyName,
                    startDate: response[0].startDate,
                    endDate: response[0].endDate,
                    kontrakValue: parseInt(response[0].kontrakValue) || 0,
                    nominal: response[0].nominal || 0,
                    target: response[0].target || 0,
                })
            } else {
                this.formRef.current.instance.updateData({
                    kontrakValue: 0,
                    nominal: 0,
                    target: 0,
                })
            }
        }

        let responseDetail = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `efek-balance-avg-dailies/resume-portofolio-kpd/${this.state.filterData.productId}/${this.state.filterData.kpdId}/${formatDate(this.state.filterData.reportDate)}`, 'GET');
        let counter = 1
        let data = []

        for (let value of responseDetail) {
            console.log(value);
            let dataValue = {
                // "no": counter++,
                "id": counter++,
                "komposisi": value.komposisi,
                "categori": value.kategori,
                "productId": value.productId,
                "noKontrak": value.noKontrak,
                "portofolioId": value.portofolioId,
                "nilaiAccrued": value.nilaiAccrued,
                "unrealizedPlPersen": value.unrealizedPlPersen,
                "unrealizedPlNominal": value.unrealizedPlNominal,
                "tglHarga": value.tglHarga,
                "nilaiPenempatan": value.nilaiPenempatan,
                "nilaiSekarang": value.nilaiSekarang
              }
            data.push(dataValue)
        }

        data.sort((a, b) => a.no - b.no);

        this.dataSource = data
    }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <h3 className="main-title">Resume Portfolio KPD</h3>
                    <div className='row'>
                        <div className="col-md-6">
                            <Form
                                ref={this.formRef}
                                colCount={1}
                                id={'formFilter'}
                                formData={this.state.filterData}
                                items={this.filterItem}
                                labelLocation="left"
                            />
                        </div>
                        <div className="mt-2 col-md-12">
                            <DevExpressDataGrid
                                // loadAPI={`view-portofolio-kpd-dtls?noKontrak.in=${this.state.filterData.kpdId}&productId.in=${this.state.filterData.productId}`}
                                loadAPI={`efek-balance-avg-dailies/resume-portofolio-kpd/${this.state.filterData.productId}/${this.state.filterData.kpdId}/${formatDate(this.state.filterData.reportDate)}`}
                                insertAPI='-'
                                updateAPI='-'
                                deleteAPI='-'

                                useArraySource={true}
                                ArraySourceData={this.dataSource}

                                backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}
                                exportExcel={true}
                                exportFileName={"Resume Portofolio KPD"}
                                exportWithFormatNum={true}
                                exceptFieldExportFormtNum={
                                    [
                                        'nilaiPenempatan',
                                        'nilaiAccrued',
                                        'unrealizedPlPersen',
                                        'unrealizedPlNominal',
                                        'nilaiSekarang',
                                        'komposisi'
                                    ]
                                }

                                allowExportSelectedData={true}
                                selection={"multiple"}

                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Resume Portofolio KPD'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                                summaryTotalItem={this.summary}

                                // onToolbarPreparing = {this.onToolbarPreparing}

                                height={'100%'}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PortofolioKPD
