import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import Form from 'devextreme-react/form';
import { months, programs, statusPembayaranRekapGaji } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import FormModalRekapGaji from 'components/pages/modal/sdm-umum/payroll/gaji/viewRekapGaji.';
import FormModalRekapTrs from 'components/pages/modal/sdm-umum/payroll/gaji/viewRekapTrs';
import FormDetailGaji from 'components/pages/modal/sdm-umum/payroll/gaji/viewDetailGaji';

class DaftarTransaksiGaji extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selectedTabIndex: 0,
            DataRekap: {},
            DataMaster: {},
        }

        this.formMaster = [
            {
                dataField: "bulan",
                label: {
                    text: "Bulan",
                    alignment: "left",
                    location: "left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: months(),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'value', // provides display values
                    searchEnabled: true
                }
            },
            {
                dataField: "tahun",
                label: {
                    text: "Tahun",
                    alignment: "left",
                    location: "left",
                }
            },
            {
                itemType: "button",
                buttonOptions: {
                    text: "Filter",
                    elementAttr: { class: "bg-dapen-default", },
                    onClick: () => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment: "left"
            },
        ]

        this.gajiColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Detail Gaji',
                        hint: 'Detail Gaji',
                        onClick: (e) => {
                            this.showModalDetilGaji(e)
                        }
                    },
                    {
                        text: 'Rekap PPH',
                        hint: 'Rekap PPH',
                        onClick: (e) => {
                            this.showModalRekapGaji(e)
                        }
                    },
                    {
                        text: 'Rekap Transaksi',
                        hint: 'Rekap Transaksi',
                        onClick: (e) => {
                            this.showModalRekapTrs(e)
                        }
                    },
                ],
            },
            {
                dataField: 'programId',
                caption: 'Program',
                lookup: {
                    dataSource: programs(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'program', // provides display values
                    searchEnabled: true,
                    deferRendering: false,
                },
            },
            {
                dataField: 'pengajuanNo',
                caption: 'No Pengajuan',
            },
            {
                dataField: 'pengajuanDate',
                caption: 'Tanggal Pengajuan',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'pembayaranDate',
                caption: 'Tanggal Pembayaran',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'periodeGaji',
                caption: 'Periode',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'bulan',
                        caption: 'Bulan',
                        lookup: {
                            dataSource: months(),
                            valueExpr: 'id', // contains the same values as the "statusId" field provides
                            displayExpr: 'value', // provides display values
                        }
                    },
                    {
                        dataField: 'tahun',
                        caption: 'Tahun',
                    },
                    {
                        dataField: 'tanggalDari',
                        caption: 'Dari Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                    {
                        dataField: 'tanggalSampai',
                        caption: 'Sampai Tanggal',
                        dataType: 'date',
                        format: 'dd MMM yyyy'
                    },
                ]
            },
            {
                dataField: 'transaksiGaji',
                caption: 'Transaksi',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'jumlahKaryawan',
                        caption: 'Jumlah Karyawan',
                        dataType: 'number',
                    },
                    {
                        dataField: 'totalTransactionNominal',
                        caption: 'Nominal',
                        dataType: 'number',
                        alignment: 'right',
                        format: '#,##0.00'
                    },
                ]
            },
            {
                dataField: 'statusId',
                caption: 'Status',
                lookup: {
                    dataSource: statusPembayaranRekapGaji(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'value'
                }
            },
        ]
        this.DataGridPengajuanRef = React.createRef()
        this.filterData = []
        this.popupTransaksiGajiRef = React.createRef()

        this.showModalRekapGajiRef = React.createRef()
        this.showModalRekapTrsRef = React.createRef()
        this.showModalDetilGajiRef = React.createRef()
    }

    componentDidMount = async () => {
        this.defaultData()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    defaultData = async () => {
        var datas = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-pembayaran-gajis`,
            'GET'
        )

        this.filterData = datas
        if (datas) {
            this.forceRefresh()
        }
    }

    showModalPenggajianKaryawan = () => {
        this.popupTransaksiGajiRef.current.show()
    }

    forceRefresh = () => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    showModalDetilGaji = (e) => {
        if (e.row) {
            this.showModalDetilGajiRef.current.retrieveData(e.row.data)
        }
        this.showModalDetilGajiRef.current.show()
    }

    showModalRekapGaji = (e) => {
        if (e.row) {
            this.showModalRekapGajiRef.current.retrieveData(e.row.data.id)
        }
        this.showModalRekapGajiRef.current.show()
    }

    showModalRekapTrs = (e) => {
        if (e.row) {
            this.showModalRekapTrsRef.current.retrieveData(e.row.data);
        }
        this.showModalRekapTrsRef.current.show()
    }

    // showModalRekapTrs = () => {
    //     this.showModalRekapTrsRef.current.show()
    // }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        // var datas = await httpRequest(
        //     process.env.REACT_APP_BACKEND_HRD,
        //     this.props.store,
        //     `view-daftar-pembayaran-gajis?statusId.in=2`,
        //     'GET'
        // )

        var datas = await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-daftar-pembayaran-gajis?statusId.in=2`,
            'GET'
        )

        if (state.bulan && state.tahun) {
            this.filterData = datas.filter(value => (value.bulan) == state.bulan && value.tahun == state.tahun)
        } else if (state.bulan) {
            this.filterData = datas.filter(value => (value.bulan) == state.bulan)
        } else if (state.tahun) {
            this.filterData = datas.filter(value => value.tahun == state.tahun)
        } else {
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.pengajuanDate.getMonth() + 1) == state.bulan && value.pengajuanDate.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()

    }

    loadData = async () => {
        return this.filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Transaksi Gaji</h3>
                <div className="my-3">
                    <Form
                        colCount={4}
                        id={'formMaster'}
                        formData={this.state.DataMaster}
                        items={this.formMaster}
                    />
                </div>
                <DevExpressDataGrid
                    ref={this.DataGridPengajuanRef}
                    loadAPI='daftar-penerima-thr'
                    insertAPI='daftar-penerima-thr'
                    updateAPI='daftar-penerima-thr'
                    deleteAPI='daftar-penerima-thr'

                    backendserver={process.env.REACT_APP_BACKEND_CORE}

                    useArraySource={true}
                    ArraySourceData={this.loadData}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Daftar Transaksi Gaji"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Daftar Transaksi Gaji'}
                    popupWidth={450} //masukan dalam ukuran pixel
                    popupHeight={330} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={1} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.gajiColumns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={600}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalRekapGaji
                    ref={this.showModalRekapGajiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalRekapTrs
                    ref={this.showModalRekapTrsRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormDetailGaji
                    ref={this.showModalDetilGajiRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                {/* <ViewDaftarTransaksiGaji
                        ref={this.popupTransaksiGajiRef}
                        store={this.props.store}
                    /> */}
            </div>
        )
    }
}

export default DaftarTransaksiGaji