import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from "devextreme/ui/notify";
import { broker, managerInvestasis, product, stock, fundManager, sektors } from 'dataSource/lookup';
import httpRequest from 'plugin/httprequest';
import DevextremeTab from 'components/inheritComponent/devextremeTab';
import HtmlEditor, { Toolbar, Item, MediaResizing } from "devextreme-react/html-editor";
import { alert } from 'devextreme/ui/dialog';
import { sektor } from 'components/pages/parts/staticDataColumnConfig';


class ModalInstrumentSaham extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            // selectedTabIndex: 0,
            data: {},
            dataDetail: {},
        }

        this.prevTabIndex = 0
        this.productId = 0
        this.instSubCategoryId = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()
        this.formDetailRef = React.createRef()
        this.formMasterRef = React.createRef()

        this.formItem = [
            {
                dataField: 'requestNo',
                label: {
                    text: 'No Pengajuan'
                },
                editorType: 'dxTextBox',
                editorOptions: {
                    readOnly: true
                }
            },
            {
                dataField: 'instrumentId',
                label: {
                    text: 'Instrument'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: stock(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.instrumentCode + " - " + item.instrumentName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onValueChanged: async (e) => {
                        console.log(e)
                        var instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${e.value}`)
                        this.instSubCategoryId = instrument.categorySubId
                        var emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `emitens/${instrument.emitenId}`)
                        if(emiten.sektorId !== null) {
                            var sektor = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `sektors/${emiten.sektorId}`)
                            this.formDetailRef.current.instance.updateData('sektor', sektor.sektorName)
                        }
                        this.formDetailRef.current.instance.updateData('emiten', emiten.emitenName)
                        this.formDetailRef.current.instance.updateData('hargaPenutupan', instrument.price)
                        this.formMasterRef.current.instance.updateData('minOrdPrice', instrument.price)
                        this.formMasterRef.current.instance.updateData('maxOrdPrice', instrument.price)
                        this.formDetailRef.current.instance.updateData('tanggalHargaPenutupan', instrument.priceDate)
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Instrument saham"
                    }
                ]
            },
            {
                dataField: 'ordQty',
                label: {
                    text: 'Kuantitas (Lembar)'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0"
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill Quantity Order"
                    }
                ]
            },
            {
                dataField: 'priceType',
                label: {
                    text: 'Harga'
                },
                editorType: 'dxRadioGroup',
                editorOptions: {
                    layout: 'horizontal',
                    items: ['FIXED', 'RANGE', 'BEST PRICE'],
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose Price Type"
                    }
                ]
            },
            {
                dataField: 'minOrdPrice',
                label: {
                    text: 'Harga Minimum'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill minimum price"
                    }
                ]
            },
            {
                dataField: 'maxOrdPrice',
                label: {
                    text: 'Harga Maximum'
                },
                editorType: 'dxNumberBox',
                editorOptions: {
                    format: "#,##0.00"
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill maximum price"
                    }
                ]
            },
            {
                dataField: 'brokerId',
                label: {
                    text: 'Broker'
                },
                editorType: "dxSelectBox",
                editorOptions: {
                    dataSource: broker(this.props.store),
                    valueExpr: 'id',
                    displayExpr: function (item) {
                        return item && item.brokerCode + " - " + item.brokerName;
                    },
                    searchEnabled: true,
                    deferRendering: false,
                    onSelectionChanged: (e) => {
                        var data = e.selectedItem

                        if (data) {
                            this.formMasterRef.current.instance.getEditor('brokerCommission').option('value', data.feeBuy)
                        }
                    }
                },
                validationRules: [
                    {
                        type: "required",
                        message: "Please choose broker"
                    }
                ]
            },
            {
                dataField: 'brokerCommission',
                label: {
                    text: 'Komisi (%)'
                },
                editorType: 'dxNumberBox',
                // editorOptions: {
                //     format: "#,##0.0000000000000000"
                // },
                validationRules: [
                    {
                        type: "required",
                        message: "Please fill broker commission"
                    }
                ]
            },
            {
                dataField: 'catatan',
                label: {
                    text: 'Catatan'
                },
                editorType: 'dxTextArea',
            }
        ]

        this.formDetailItem = [
            {
                itemType: 'group',
                caption: 'INFORMASI SAHAM',
                items: [
                    {
                        dataField: 'emiten',
                        label: {
                            text: 'Emiten'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    {
                        dataField: 'sektor',
                        label: {
                            text: 'Sektor'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                            readOnly: true
                        }
                    },
                    // {
                    //     dataField: 'klasifikasiSaham',
                    //     label: {
                    //         text: 'Klasifikasi Saham'
                    //     },
                    //     editorType: 'dxTextBox',
                    //     editorOptions: {
                    //         readOnly: true
                    //     }
                    // },
                    {
                        dataField: 'hargaPenutupan',
                        label: {
                            text: 'Harga Penutupan'
                        },
                        editorType: 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                            readOnly: true
                        },
                    },
                    {
                        dataField: 'tanggalHargaPenutupan',
                        label: {
                            text: 'Tanggal Harga Penutupan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            displayFormat: "d MMM yyyy",
                            useMaskBehavior: true,
                            openOnFieldClick: true,
                            width: '100%',
                            readOnly: true
                        }
                    },
                ]
            }
        ]

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: () => {
                        var formValidation = this.validate()
                        if (formValidation) {
                            this.submitData()
                        }
                    }
                },
                toolbar: 'bottom',
                visible: this.props.type == 'view' ? false : true
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]
    }

    get Popup() {
        return this.PopupRef.current.instance
    }
    validate = () => {
        var formValidation = this.formMasterRef.current.instance.validate();
        return formValidation.isValid
    }
    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0
        });
    }

    retrieveData = async (data) => {
        if(data){
            this.productId = data.productId
            var instrument = {}, emiten = {}
            if (data.instrumentId) {
                instrument = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `instruments/${data.instrumentId}`)

                emiten = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `emitens/${instrument.emitenId}`)
            }

            this.setState({
                data: {
                    id: data.id,
                    requestNo: data.requestNo,
                    instrumentId: data.instrumentId,
                    ordQty: data.ordQty,
                    priceType: data.priceType,
                    brokerId: data.brokerId,
                    komisiPercent: data.komisiPercent,
                    brokerCommission: data.brokerCommission,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    catatan: data.catatan,
                    tanggalPenempatan: data.tanggalPenempatan || new Date(),
                },
                dataDetail: {
                    emiten: emiten.emitenName,
                    hargaPenutupan: instrument.price,
                    tanggalHargaPenutupan: instrument.priceDate
                },
            });
        }
    }

    hide() {
        this.productId = 0
        this.instSubCategoryId = 0
        this.setState({
            popupVisible: false,
        })
    }

    submitData = async () => {
        var data = this.state.data
        let validateBatasan = await httpRequest(process.env.REACT_APP_BACKEND_INVESTASI, this.props.store, `kebijakan-spesifikasi-value/get-validasi-pengajuan-inv/${this.productId}/${this.instSubCategoryId}/${data.maxOrdPrice * data.ordQty}`, 'GET')
        if (validateBatasan.length > 0) {
            if (validateBatasan[0].potensiselisih >= 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")

                this.props.submitDetailPenempatanData({
                    id: data.id,
                    instrumentId: data.instrumentId,
                    ordQty: data.ordQty,
                    priceType: data.priceType.replace(" ", "_"),
                    brokerId: data.brokerId,
                    komisiPercent: data.komisiPercent,
                    brokerCommission: data.brokerCommission,
                    minOrdPrice: data.minOrdPrice,
                    maxOrdPrice: data.maxOrdPrice,
                    catatan: data.catatan,
                    tanggalPenempatan: data.tanggalPenempatan,
                })

                this.hide()

            } else if (validateBatasan[0].potensiselisih < 0) {
                await alert(validateBatasan[0].notifikasi, "Informasi")
            } else {
                await alert("Terdapat Kesalahan Data", "Informasi")
            }
        } else {
            await alert("Batasan Resiko Belum di Setting", "Informasi")
        }
    }

    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={`Form Usulan Penempatan Investasi Saham`}
                width={'70vw'}
                height="70vh"

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <Form
                                ref={this.formMasterRef}
                                colCount={1}
                                id={'form'}
                                formData={this.state.data}
                                items={this.formItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={this.props.type == 'view' ? true : false}
                            />
                        </div>
                        <div className="col-md-6 border-left">
                            <Form
                                ref={this.formDetailRef}
                                colCount={1}
                                id={'formDetail'}
                                formData={this.state.dataDetail}
                                items={this.formDetailItem}
                                scrollingEnabled={true}
                                labelLocation={"left"}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default ModalInstrumentSaham
