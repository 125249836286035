import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import DevextremeTab from 'components/inheritComponent/devextremeTab'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import notify from 'devextreme/ui/notify';
import { SKTypeHRD, tipeDokumen } from 'dataSource/lookup';
import ModalSkKaryawan from './file/modalSkKaryawan';
import httpRequest from 'plugin/httprequest';
import { formatDate } from 'plugin/helper';
import ModalDokKaryawan from './file/modalDokKaryawan';

class FileDaftarKaryawan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)
        this.prevTabIndex = 0
        this.PopupRef = React.createRef()
        this.modalSkKaryawanRef = React.createRef()
        this.modalDokKaryawanRef = React.createRef()

        this.TabDataSource = [
            {
                id: 0,
                text: 'Dokumen'
            },
            {
                id: 1,
                text: 'Surat Keputusan'
            },
        ]

        this.PopupToolbarItem = [
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Simpan",
                onClick: this.submitData.bind(this)
              },
              toolbar: "bottom"
            },
            {
              widget: "dxButton",
              location: "after",
              options: {
                text: "Batal",
                onClick: this.hide.bind(this)
              },
              toolbar: "bottom"
            }
        ];
        this.columnDokumen = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.removeDataDok(e.row.data)
                        }
                    },
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            this.showModalDokKaryawan(e, false)
                        }
                    },
                    {
                        text: 'Lihat',
                        hint: 'Lihat',
                        onClick: (e) => {
                            this.showModalDokKaryawan(e, true)
                        }
                    },
                ]
            },
            {
                dataField: 'dokumenNo',
                caption: 'No. Dokumen',
            },
            {
                dataField: 'dokumenTypeId',
                caption: 'Detail',
                lookup:{
                    dataSource: tipeDokumen(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'dokumenName', // provides display values
                }
            },
            {
                dataField: 'dokumenDate',
                caption: 'Tanggal Dokumen',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField:"keterangan",
                caption:"Keterangan"
            },
        ]
        this.columnSuratKeputusan = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Hapus',
                        hint : 'Hapus',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.removeDataSk(e.row.data)
                        }
                    },
                    {
                        text : 'Ubah',
                        hint : 'Ubah',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalInsertSkKaryawan(e, false)
                        }
                    },
                    {
                        text : 'Lihat',
                        hint : 'Lihat',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalInsertSkKaryawan(e, true)
                        }
                    },
                ],
            },
            {
                dataField: 'skNo',
                caption: 'No. SK',
            },
            {
                dataField: 'skTypeId',
                caption: 'Jenis SK',
                lookup:{
                    dataSource: SKTypeHRD(this.props.store),
                    valueExpr: 'id', // contains the same values as the "statusId" field provides
                    displayExpr: 'typeName' 
                }
            },
            {
                dataField: 'skDate',
                caption: 'Tanggal SK',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
            {
                dataField: 'efektifDate',
                caption: 'Tanggal Efektif',
                dataType: 'date',
                format: 'dd MMM yyyy'
            },
        ]

        this.karyawanId = ''
        this.DataGridSkKarRef = React.createRef()
        this.DataGridDokRef = React.createRef()
    }

    show() {
        this.setState({
            popupVisible: true,
        });
    }

    hide(){
        this.DataGridSkKarRef.current.emptyDeletedIndex()
        this.DataGridDokRef.current.emptyDeletedIndex()
        this.setState({
            popupVisible: false,
        })
    }

    onValueChanged = (e) =>{
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        }) 
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindexFile-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindexFile-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    onToolbarPreparingSK = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    // text: 'Karyawan',
                    onClick: (e) => {
                        this.showModalInsertSkKaryawan(e)
                    },
                }
            }
        )
    }

    onToolbarPreparingDoc = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    // text: 'Karyawan',
                    onClick: (e) => {
                        this.showModalDokKaryawan(e)
                    },
                }
            }
        )
    }

    showModalInsertSkKaryawan = (e, readOnly) => {
        if (e.row) {
            this.modalSkKaryawanRef.current.retrieveData(e.row.data, readOnly)
        }
        this.modalSkKaryawanRef.current.show()
    }

    showModalDokKaryawan = (e, readOnly) => {
        if (e.row) {
            this.modalDokKaryawanRef.current.retrieveData(e.row.data, readOnly)
        }
        this.modalDokKaryawanRef.current.show()
    }

    loadDataDok = async () => {
        if (this.karyawanId == '') {
            var response = [ ]
         }else{
             var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-dokumen/getAllByKaryawanId/${this.karyawanId}`, 'GET')
         
         }
         return response
    }

    loadDataSk = async () => {
        if (this.karyawanId == '') {
            var response = [ ]
         }else{
             var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `karyawan-sks/getAllByKaryawanId/${this.karyawanId}`, 'GET')
         
         }
         return response
    }
 
    submitData = async() => {
        try {
            var dataSk = this.DataGridSkKarRef.current.getDataSource()
            var dataDok = this.DataGridDokRef.current.getDataSource()
            
            for(var karyawansk of dataSk){
                if (typeof karyawansk.id === "string") {
                    delete karyawansk.id
                     this.addDataSKKaryawan(karyawansk,this.karyawanId)
                }else{
                     this.editDataSkKaryawan(karyawansk)
                }
            }
            var getDeletedSk = this.DataGridSkKarRef.current.getDeletedIndex()
            for(var dataDeleteSk of getDeletedSk){
                await this.deleteDataSkKaryawan(dataDeleteSk)
            }

            for(var karyawanDok of dataDok){
                if (typeof karyawanDok.id === "string") {
                    delete karyawanDok.id
                     this.addDataDokKaryawan(karyawanDok,this.karyawanId)
                }else{
                     this.editDataDokKaryawan(karyawanDok)
                }
            }
            
            var getDeletedDok = this.DataGridDokRef.current.getDeletedIndex()
            for(var dataDeleteDok of getDeletedDok){
                    this.deleteDataDokKaryawan(dataDeleteDok)
            }

            notify({ message: "Sukses !", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            this.hide()

        } catch (e) {
            console.log(e)
        }
        
    }

    getDataKaryawanId = (karyawanId) => {
        this.karyawanId = karyawanId

        this.DataGridDokRef.current.forceRefresh(true)
        this.DataGridSkKarRef.current.forceRefresh(true)
    }

    getDataSourceSk = (data) => {
        try{
            var key = data.id

            if(key){
                this.DataGridSkKarRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update SK Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.DataGridSkKarRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input SK Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
            
        }catch(e){
            console.log(e)
        }
    }

    getDataSourceDok = (data) => {
        try{
            var key = data.id

            if(key){
                this.DataGridDokRef.current.push([
                    {
                        type: 'update',
                        data : data,
                        key : key
                    }
                ])
                notify({ message: "Sukses Update Dokumen Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }else{
                this.DataGridDokRef.current.push([
                    {
                        type: 'insert',
                        data : data
                    }
                ])
                notify({ message: "Sukses Input Dokumen Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
            }
        }catch(e){
            console.log(e)
        }
    }

    addDataSKKaryawan = async (data, karyawanId) => {
        var loadAPI = `karyawan-sks`
        var dataSK = {
            karyawanId:karyawanId,
            efektifDate:formatDate(data.efektifDate),
            file:data.file,
            fileContentType:data.fileContentType,
            fileName:data.fileName,
            skDate:formatDate(data.skDate),
            skNo:data.skNo,
            skTypeId:data.skTypeId,
        }
        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: dataSK,
        })

        // if (response) {
        //     this.hide()
        //     notify({ message: "Sukses Tambah SK", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
        // } else {
        //     notify({ message: "Error", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        // }
    }

    editDataSkKaryawan = async (data) => {
        var loadAPI = `karyawan-sks`

        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
            values: data,
            key:data.id
        },data)
        
        // if (response) {
        //     this.hide()
        //     notify({ message: "Sukses Update SK", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
        // } else {
        //     notify({ message: "Error", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        // }

        // try{
        //     var key = data.id

        //     if(key){
        //         this.DataGridSkKarRef.current.push([
        //             {
        //                 type: 'update',
        //                 data : data,
        //                 key : key
        //             }
        //         ])
        //     }else{
        //         this.DataGridSkKarRef.current.push([
        //             {
        //                 type: 'insert',
        //                 data : data
        //             }
        //         ])
        //     }
        // }catch(e){
        //     console.log(e)
        // }
    }

    deleteDataSkKaryawan = async (data) => {
        var loadAPI = `karyawan-sks`

        var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE', data)
        // this.hide()
        // this.DataGridSkKarRef.current.push([
        //     {
        //         type: 'remove',
        //         key : data.id
        //     }
        // ])
    }

    addDataDokKaryawan = async (data, karyawanId) => {
        var loadAPI = `karyawan-dokumen`
        var dataDok = {
            karyawanId:karyawanId,
            keterangan:data.keterangan,
            file:data.file,
            fileContentType:data.fileContentType,
            fileName:data.fileName,
            dokumenDate:formatDate(data.dokumenDate),
            dokumenNo:data.dokumenNo,
            dokumenTypeId:data.dokumenTypeId,
        }
        
        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'POST', {
            values: dataDok,
        })
    }

    editDataDokKaryawan = async (data) => {
        var loadAPI = `karyawan-dokumen`

        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'PUT', {
            values: data,
            key:data.id
        },data)
    }

    deleteDataDokKaryawan = async (data) => {
        var loadAPI = `karyawan-dokumen`

        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, loadAPI, 'DELETE', data)
    }

    removeDataSk = (data) => {
        this.DataGridSkKarRef.current.push([
            {
                type: 'remove',
                key : data.id
            }
        ])
        notify({ message: "Sukses Delete SK Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    }

    removeDataDok = (data) => {
        this.DataGridDokRef.current.push([
            {
                type: 'remove',
                key : data.id
            }
        ])

        notify({ message: "Sukses Delete Dokumen Karyawan", width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, "success", 600);
    }

    render() {
        return (
            <Popup
            className={'popup'}
            visible={this.state.popupVisible}
            onHiding={this.hide}
            dragEnabled={true}
            resizeEnabled={true}
            showTitle={true}
            title={'File Karyawan'}
            minWidth={500}
            minHeight={500}

            ref={this.PopupRef}

            toolbarItems = {this.PopupToolbarItem}
        >
            <div className="container-fluid" style={{overflowY : 'scroll', height: '100%'}}>
                <div className="row">
                    <div className="col-md-12">
                        <DevextremeTab
                            dataSource={this.TabDataSource}
                            onOptionChanged={this.onTabSelectionChanged}
                        />
                    </div>
                    <div id="tabindexFile-0" className={' mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                        <h5 className="font-weight-bold my-3">Dokumen Karyawan</h5>
                        <DevExpressDataGrid 
                            ref={this.DataGridDokRef}
                            loadAPI={this.karyawanId ? `karyawan-dokumen/getAllByKaryawanId/${this.karyawanId}` : []}
                            insertAPI='karyawan-dokumen'
                            updateAPI='karyawan-dokumen'
                            deleteAPI='karyawan-dokumen' 

                            backendserver={process.env.REACT_APP_BACKEND_HRD}
                            useArraySource={true}
                            ArraySourceData={this.loadDataDok}
                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={true}
                            exportFileName={"Dokumen Karyawan"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Jurnal Manual Data'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            height={500}

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columnDokumen} // taruh konfigurasi kolom disini
                            onToolbarPreparing = {this.onToolbarPreparingDoc}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                        <ModalDokKaryawan
                            ref={this.modalDokKaryawanRef}
                            store={this.props.store}
                            getDataSourceDok={this.getDataSourceDok}
                        />
                    </div>
                    <div id="tabindexFile-1" className={'d-none mt-2 col-md-12 text-center' } style={{minHeight: 'calc(50vh)'}}>
                        <h5 className="font-weight-bold my-3">Surat Keputusan</h5>
                        <DevExpressDataGrid
                        ref = {this.DataGridSkKarRef}
                            loadAPI={this.karyawanId ? `karyawan-sks/getAllByKaryawanId/${this.karyawanId}` : []}
                            insertAPI='karyawan-sks'
                            updateAPI='karyawan-sks'
                            deleteAPI='karyawan-sks' 

                            backendserver={process.env.REACT_APP_BACKEND_HRD}

                            useArraySource={true}
                            ArraySourceData={this.loadDataSk}
                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={false}

                            exportExcel={true}
                            exportFileName={"SK Karyawan"}
                            allowExportSelectedData={true}
                            selection={"multiple"}

                            showBorders={true}

                            paging={true}
                            defaultPageSize={10}

                            //bagian konfigurasi popup saat insert dan edit record
                            popupTitle={'Jurnal Manual Data'}
                            popupWidth={500} //masukan dalam ukuran pixel
                            popupHeight={300} //masukkan dalam ukuran pixel

                            height={500}

                            popupFormLabelLocation='left' //accepted value = top, left, right
                            popupFormMinColWidth={300} // minimum lebar kolom
                            popupFormColCount={1} //jumlah kolom pada form

                            //akhir bagian konfigurasi popup

                            ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                            ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                            FilterRow={true} // set false untuk mematikan fitur filter

                            ColumnConfiguration={this.columnSuratKeputusan} // taruh konfigurasi kolom disini
                            onToolbarPreparing = {this.onToolbarPreparingSK}
                            //contoh konfigurasi kolom
                            //this.columns = [{
                            //    dataField: 'kolom1',
                            //    caption: 'Ini Kolom 1'
                            //}, {
                            //    dataField: 'kolom2',
                            //    caption: 'Ini Kolom 2'
                            //}]
                            // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                            store={this.props.store} // jangan di edit edit
                        />
                        <ModalSkKaryawan
                            ref={this.modalSkKaryawanRef}
                            store={this.props.store}
                            getDataSourceSk={this.getDataSourceSk}
                        />
                    </div>
                </div>
            </div>
        </Popup> 
        )
    }
}

export default FileDaftarKaryawan