import React, { Component } from 'react'
import { product, pesertaAkunStatus } from 'dataSource/lookup';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import ShowModalActionAkunPeserta from '../pesertaDapen/akunPeserta/actionModal';

class FormDataAkun extends Component {
    constructor(props) {
        super(props)

        this.state = {
            product: '',
            formDataAkun: [],
        }

        this.formDataAkun = []
        this.formRef = React.createRef()
        this.showModalActionViewRef = React.createRef()
        this.showModalActionViewRefView = React.createRef()
        this.DataGridAkunProductRef = React.createRef()

        this.dataGridAkun = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : this.props.actionType === 'view' ? 'Detail' : 'Ubah',
                        hint : this.props.actionType === 'view' ? 'Detail' : 'Ubah',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalActionDataAkunRegistrasiPengkinianAktif(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'cardNo',
                caption: 'Card No',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'statusId',
                caption: 'Status Akun Peserta',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]

        this.dataGridAkun2 = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'View',
                        hint : 'View',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.showModalActionDataAkunRegistrasiPengkinianAktifView(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'cardNo',
                caption: 'Card No',
            },
            {
                dataField: 'productId',
                caption: 'Produk',
                lookup: {
                    dataSource: product(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'productCode'
                }
            },
            {
                dataField: 'statusId',
                caption: 'Status Akun Peserta',
                lookup: {
                    dataSource: pesertaAkunStatus(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'description'
                }
            },
        ]
    }

    passDataToParent = () => {
        var data = this.formDataAkun
        return data
    }
    
    removeState = () => {
        this.deleteDataAkun(this.DataGridAkunProductRef.current.getDataSource())
        this.formDataAkun = []
    }

    forceRefresh = (param = false) => {
        this.DataGridAkunIuranRef.current.forceRefresh(param)
    }
    
    reloadData = (pesertaId,dataAkun) => {
        var data = []
        for(var value of dataAkun){
            data.push({
                dataAkun: value
            })
        }
        this.pesertaId = pesertaId
        this.formDataAkun = dataAkun
        console.log(this.formDataAkun, 'reloaded')
        // this.editDataAkun(this.state.formDataAkun)
        this.forceRefresh(true)
    }

    retrieveData = () => {
        return this.formDataAkun
    }

    showModalActionDataAkunRegistrasiPengkinianAktif = (data) => {
        this.showModalActionViewRef.current.retrieveData(data)
        this.showModalActionViewRef.current.show()
    }
    showModalActionDataAkunRegistrasiPengkinianAktifView = (data) => {
        this.showModalActionViewRefView.current.retrieveData(data)
        this.showModalActionViewRefView.current.show()
    }

    // onToolbarPreparingDataAkun = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 icon: 'plus',    
    //                 onClick: (e) => {
    //                     this.showModalKeluarga({},'tambah')
    //                 }
    //             }
    //         }
    //     )
    // }

    editDataAkun = async (data) => {
        try{
            if(Array.isArray(data)){
                var editData = []
                for(var value of data){
                    var key = value.id
        
                    if(key){
                        editData.push(
                            {
                                type: 'update',
                                data : value,
                                key : key
                            }
                        )
                    }else{
                        editData.push(
                            {
                                type: 'insert',
                                data : value
                            }
                        )
                    }
                }
                this.DataGridAkunProductRef.current.push(editData)
            }else{
                var key = data.id
    
                if(key){
                    this.DataGridAkunProductRef.current.push([
                        {
                            type: 'update',
                            data : data,
                            key : key
                        }
                    ])
                }else{
                    this.DataGridAkunProductRef.current.push([
                        {
                            type: 'insert',
                            data : data
                        }
                    ])
                }
            }
            
            
            // let type = 'success'
            // let text = 'Campaign Updates has changed successfully!'

            // notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);
        }catch(e){
            console.log(e)
        }
    }

    deleteDataAkun = (data) => {
        var deletedData = []
        if(Array.isArray(data)){
            for(var value of data){
                deletedData.push(
                    {
                        type: 'remove',
                        key : value.id
                    }
                )
            }
        }else{
            deletedData.push(
                {
                    type: 'remove',
                    key : data.id
                }
            )
        }
        this.DataGridAkunProductRef.current.push(deletedData)
    }

    forceRefresh = (param = false) => {
        this.DataGridAkunProductRef.current.forceRefresh(param)
    }

    submitData = (data) => {
        var currentDataAkun = this.formDataAkun
        var current = currentDataAkun.findIndex(val => val.id == data.id)
        currentDataAkun[current] = data
        // this.setState({
        //     formDataAkun : currentDataAkun
        // })

        this.formDataAkun = currentDataAkun
        console.log(this.formDataAkun, 'submit data form akun')
        this.props.saveDataAkun(currentDataAkun)
        this.forceRefresh(true)
    }

    render() {
        return (
            <React.Fragment>
                <DevExpressDataGrid 
                    ref = {this.DataGridAkunProductRef}
                    loadAPI = 'kepesertaan-akun-products'
                    insertAPI = 'kepesertaan-akun-products'
                    updateAPI = 'kepesertaan-akun-products'
                    deleteAPI = 'kepesertaan-akun-products'

                    useArraySource = {true}
                    ArraySourceData = {this.retrieveData}

                    backendserver = {process.env.REACT_APP_BACKEND_CORE}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    showBorders = {true}

                    paging = {true}
                    defaultPageSize = {10}
                    
                    editingMode={'popup'}
                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle = {'Data Akun PPMP'}
                    popupWidth = {1000} //masukan dalam ukuran pixel
                    popupHeight = {450} //masukkan dalam ukuran pixel

                    popupFormLabelLocation = 'top' //accepted value = top, left, right
                    popupFormMinColWidth = {300} // minimum lebar kolom
                    popupFormColCount = {3} //jumlah kolom pada form
                    
                    //akhir bagian konfigurasi popup

                    ColumnChooser = {false} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing = {false} // set false agar kolom tidak dapat di freeze

                    FilterRow = {true} // set false untuk mematikan fitur filter
                    
                    ColumnConfiguration={this.props.from == 'akun_peserta_aktif' ? this.dataGridAkun2 : this.dataGridAkun} // taruh konfigurasi kolom disini
                    onToolbarPreparing = {this.onToolbarPreparingDataAkun}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/
                    
                    store = {this.props.store} // jangan di edit edit
                />
                <ShowModalActionAkunPeserta 
                    ref = {this.showModalActionViewRef}
                    store = {this.props.store}
                    submitData = {this.submitData}
                    // actionType = {'edit'}
                    actionType = {this.props.actionType}
                    ArraySourceData = {true}
                    product = {'ppip'}
                />
                {/* <ShowModalActionAkunPeserta 
                    ref = {this.showModalActionViewRefView}
                    store = {this.props.store}
                    submitData = {this.submitData}
                    actionType = {'view'}
                    ArraySourceData = {true}
                    product = {'ppip'}
                /> */}
            </React.Fragment>
        )
    }
}

export default FormDataAkun
