import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import FormModalTagihanProyek from 'components/pages/modal/sdm-umum/umum/proyek/tagihanProyek';
import httpRequest from 'plugin/httprequest';
import React, { Component } from 'react'
import { addURL } from 'redux/actions/url';
import notify from 'devextreme/ui/notify';
import { confirmAlert } from 'react-confirm-alert';
import { invoiceType, projectSdm } from 'dataSource/lookup';
import FormModalPenutupanProyek from 'components/pages/modal/sdm-umum/umum/proyek/penutupanProyek';

class TagihanProyek extends Component {
    constructor(props) {
        super(props)

        this.columns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text: 'Ubah',
                        hint: 'Ubah',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.statusTransaksiId == 1) {
                                this.showModalTagihanProyek(data)
                            } else {
                                this.modalViewTagihanRef.current.show()
                                this.modalViewTagihanRef.current.retrieveData(data)
                            }
                        }
                    },
                    {
                        text: 'Batal',
                        hint: 'Batal',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.statusTransaksiId == 1) {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin ingin membatalkan tagihan ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.batalTagihan(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Tagihan terkirim!',
                                    message: 'Tagihan telah dikirim',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                    {
                        text: 'Kirim',
                        hint: 'Kirim',
                        cssClass: 'text-success',
                        onClick: (e) => {
                            var data = e.row.data
                            if (data.statusTransaksiId == 1) {
                                confirmAlert({
                                    title: 'Konfirmasi',
                                    message: 'Apakah anda yakin ingin mengirim tagihan ini?',
                                    buttons: [
                                        {
                                            label: 'Ya',
                                            onClick: () => {
                                                this.kirimTagihan(data)
                                            }
                                        },
                                        {
                                            label: 'Tidak'
                                        }
                                    ]
                                })
                            } else {
                                confirmAlert({
                                    title: 'Tagihan terkirim!',
                                    message: 'Tagihan sudah dikirim, tidak bisa dikirim lagi!',
                                    buttons: [
                                        {
                                            label: 'OK'
                                        }
                                    ]
                                })
                            }
                        }
                    },
                ]
            },
            {
                dataField: 'id',
                caption: 'No',
                cellRender: (e) => {
                    var no = e.rowIndex + 1
                    return no
                }
            },
            {
                dataField: 'invoiceNo',
                caption: 'No Tagihan'
            },
            {
                dataField: 'projectId',
                caption: 'Proyek',
                lookup: {
                    dataSource: projectSdm(this.props.store),
                    valueExpr: 'id',
                    displayExpr: (e) => {
                        return e.projectCode + ' - ' + e.projectName
                    }
                }
            },
            {
                dataField: 'reffNo',
                caption: 'No Referensi'
            },
            {
                dataField: 'invoiceDate',
                caption: 'Tanggal Tagihan',
                dataType: 'date',
                format: 'dd MMM yyyy',
            },
            {
                dataField: 'invoiceTypeId',
                caption: 'Jenis Tagihan',
                lookup: {
                    dataSource: invoiceType(this.props.store),
                    valueExpr: 'id',
                    displayExpr: 'invoiceName'
                }
            },
            {
                dataField: 'tagihanDp',
                caption: 'Tagihan DP',
                dataType: 'number',
                format: '#,##0.00',
            },
            {
                dataField: 'kontrak',
                name: 'tagihan-termin',
                caption: 'Tagihan Termin',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'terminKe',
                        caption: 'Termin Ke'
                    },
                    {
                        dataField: 'tagihanTermin',
                        caption: 'Tagihan Termin'
                    },
                    {
                        dataField: 'invoiceNominal',
                        caption: 'Nominal Tagihan',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'ppn',
                        caption: 'PPn',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                    {
                        dataField: 'invoiceNominalNett',
                        caption: 'Total Nominal Tagihan',
                        dataType: 'number',
                        format: '#,##0.00',
                    },
                ]
            },
            {
                dataField: 'pembayaran',
                caption: 'Pembayaran',
                alignment: 'center',
                columns: [
                    {
                        dataField: 'dueDate',
                        caption: 'Jatuh Tempo',
                        dataType: 'date',
                        format: 'dd MMM yyyy',
                    },
                ]
            },
        ]

        this.showModalTagihanProyekRef = React.createRef()
        this.showModalPenutupanRef = React.createRef()
        this.modalViewTagihanRef = React.createRef()
        this.dataGridRef = React.createRef()
    }

    componentWillMount() {
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    }

    showModalTagihanProyek = async (data) => {
        if (data) {
            var response = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `project-invoices/${data.id}`, 'GET')
            this.showModalTagihanProyekRef.current.retrieveData(response)
        }
        this.showModalTagihanProyekRef.current.show()
    }

    kirimTagihan = async (data) => {
        var detail = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `project-invoices/${data.id}`, 'GET')
        var projects = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `projects/${detail.projectId}`, 'GET')
        // var submitData = {
        //     currencyId: detail.currencyId,
        //     currencyRate: detail.currencyRate,
        //     dueDate: detail.dueDate,
        //     fileBast: detail.fileBast,
        //     fileInvoice: detail.fileInvoice,
        //     giroTujuanBankCabang: detail.giroTujuanBankCabang,
        //     giroTujuanBankId: detail.giroTujuanBankId,
        //     giroTujuanName: detail.giroTujuanName,
        //     giroTujuanNo: detail.giroTujuanNo,
        //     id: detail.id,
        //     invoiceDate: detail.invoiceDate,
        //     invoiceNo: detail.invoiceNo,
        //     invoiceNominal: detail.invoiceNominal,
        //     invoiceNominalNett: detail.invoiceNominalNett,
        //     invoiceTypeId: detail.invoiceTypeId,
        //     keterangan: detail.keterangan,
        //     pph: detail.pph,
        //     ppn: detail.ppn,
        //     programId: detail.programId,
        //     projectId: detail.projectId,
        //     reffNo: detail.reffNo,
        //     rekTujuanBankId: detail.rekTujuanBankId,
        //     rekTujuanBankName: detail.rekTujuanBankName,
        //     rekTujuanCabang: detail.rekTujuanCabang,
        //     rekTujuanNo: detail.rekTujuanNo,
        //     terminKe: detail.terminKe,
        //     transactionTypeId: detail.transactionTypeId,
        //     transactionTypeSubId: detail.transactionTypeSubId
        // }
        var submitPembayaranUmum = {
            noPengajuan: 'PU-INV-' + Math.floor(Math.random() * 100000),
            programId: detail.programId,
            tanggalPengajuan: detail.invoiceDate,
            requestBy: this.props.store.getState().sessionUser,
            unitId: null,
            keterangan: detail.keterangan,
            reffId: detail.id,
            reffTransaction: 'INVOICE PROYEK'
        }
        if (submitPembayaranUmum) {
            try {
                var responsePembayaranUmum = await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umums', 'POST', { values: submitPembayaranUmum })
                if (responsePembayaranUmum) {
                    var submitPembayaranUmumDetail = {
                        pembayaranUmumId: responsePembayaranUmum.id,
                        transactionTypeId: detail.transactionTypeId,
                        transactionTypeSubId: detail.transactionTypeSubId,
                        keterangan: detail.keterangan,
                        pengajuanNominal: detail.invoiceNominal,
                        ppn: detail.ppn,
                        pph: detail.pph,
                        biayaLain: 0,
                        potongan: 0,
                        pengajuanNominalNett: detail.invoiceNominalNett,
                        biayaBank: 0,
                        cash: false,
                        transfer: true,
                        dpAmount: 0,
                        cashOut: 0,
                        tujuanRekBankId: detail.rekTujuanBankId,
                        tujuanRekNo: detail.rekTujuanNo,
                        tujuanRekName: detail.rekTujuanBankName,
                        tujuanRekCabang: detail.rekTujuanCabang,
                        supplierId: projects.supplierId,
                        projectId: detail.projectId,
                    }
                    var submitPembayaranUmumDetailDokumen = {
                        pembayaranUmumId: responsePembayaranUmum.id,
                        dokumenName: 'INVOICE',
                        dokumenFile: detail.fileInvoice,
                        dokumenFileContentType: null,
                    }
                    var updateInvoice = {
                        id: detail.id,
                        statusTransaksiId: 2,
                    }
                    var updateProject = {
                        id: detail.projectId,
                        statusId: 2,
                    }
                    try {
                        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-detils', 'POST', { values: submitPembayaranUmumDetail })
                        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'pembayaran-umum-dokumen', 'POST', { values: submitPembayaranUmumDetailDokumen })
                        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'project-invoices', 'PUT', { values: updateInvoice, key: updateInvoice.id }, detail)
                        await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, 'projects', 'PUT', { values: updateProject, key: updateProject.id }, projects)
                        this.forceRefresh()
                        notify({ message: 'Tagihan berhasil dikirim!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
                    } catch (e) {
                        notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
                    }
                }
            } catch (e) {
                notify({ message: 'Terjadi kesalahan sistem!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
        // if (submitData) {
        //     try {
        //         await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `projects-kirim-invoice`, 'POST', { values: submitData })
        //         this.forceRefresh()
        //         notify({ message: 'Tagihan berhasil dikirim!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
        //     } catch (e) {
        //         notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
        //     }
        // }
    }

    batalTagihan = async (data) => {
        if (data) {
            try {
                await httpRequest(process.env.REACT_APP_BACKEND_HRD, this.props.store, `project-invoices`, 'DELETE', data.id)
                this.forceRefresh()
                notify({ message: 'Data berhasil dibatalkan!', width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'success', 600);
            } catch (e) {
                notify({ message: e, width: 'AUTO', shading: true, position: { at: 'center', my: 'center', of: window } }, 'error', 600);
            }
        }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'plus',
                    onClick: (e) => {
                        this.showModalTagihanProyek()
                    }
                }
            }
        )
    }
    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Tagihan Proyek</h3>
                <DevExpressDataGrid
                    ref={this.dataGridRef}
                    loadAPI='project-invoices'
                    insertAPI='-'
                    updateAPI='-'
                    deleteAPI='-'

                    backendserver={process.env.REACT_APP_BACKEND_HRD}

                    allowAdding={false}
                    allowDeleting={false}
                    allowUpdating={false}

                    exportExcel={true}
                    exportFileName={"Tagihan Proyek"}
                    allowExportSelectedData={true}
                    selection={"multiple"}

                    showBorders={true}

                    paging={true}
                    defaultPageSize={10}

                    //bagian konfigurasi popup saat insert dan edit record
                    popupTitle={'Tagihan Proyek'}
                    popupWidth={700} //masukan dalam ukuran pixel
                    popupHeight={500} //masukkan dalam ukuran pixel

                    popupFormLabelLocation='left' //accepted value = top, left, right
                    popupFormMinColWidth={300} // minimum lebar kolom
                    popupFormColCount={3} //jumlah kolom pada form

                    //akhir bagian konfigurasi popup

                    ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                    ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                    FilterRow={true} // set false untuk mematikan fitur filter

                    ColumnConfiguration={this.columns} // taruh konfigurasi kolom disini
                    // SummaryConfiguration={this.summary}

                    onToolbarPreparing={this.onToolbarPreparing}

                    height={'calc(100vh - 300px)'}

                    //contoh konfigurasi kolom
                    //this.columns = [{
                    //    dataField: 'kolom1',
                    //    caption: 'Ini Kolom 1'
                    //}, {
                    //    dataField: 'kolom2',
                    //    caption: 'Ini Kolom 2'
                    //}]
                    // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                    store={this.props.store} // jangan di edit edit
                />
                <FormModalTagihanProyek
                    ref={this.showModalTagihanProyekRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
                <FormModalTagihanProyek
                    ref={this.modalViewTagihanRef}
                    store={this.props.store}
                    actionType={'view'}
                />
                <FormModalPenutupanProyek 
                    ref={this.showModalPenutupanRef}
                    store={this.props.store}
                    forceRefresh={this.forceRefresh}
                />
            </div>
        )
    }
}


export default TagihanProyek