import React, { Component } from 'react'
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import Form from 'devextreme-react/form'
import { Button } from 'devextreme-react'
import { addURL } from 'redux/actions/url'
import ViewDaftarTransaksiThr from 'components/pages/modal/sdm-umum/payroll/viewDaftarTransaksiThr'
import { agama, jabatan, karyawan, statusPembayaranRekapGaji } from 'dataSource/lookup'
import httpRequest from 'plugin/httprequest'


class DaftarTransaksiThr extends Component {
    constructor(props){
        super(props)

        this.state = {
            DataMaster:{},
        }

        this.filterData = []
        this.DataGridPengajuanRef = React.createRef()
        // this.daftarTransaksiColumns = [
        //     {
        //         type: 'buttons',
        //         buttons: [
        //             'edit',
        //             {
        //                 text : 'Detail',
        //                 hint : 'Detail',
        //                 cssClass: 'text-primary',
        //                 onClick : (e) => {
        //                     this.showModalPenerimaThr()
        //                 }
        //             },
        //         ],
        //     },
        //     {
        //         dataField:"thr",
        //         alignment: 'center',
        //         caption:"THR",
        //         columns: [
        //             {
        //                 dataField: 'tunjanganName',
        //                 caption: 'Tipe'
        //             },
        //             {
        //                 dataField: 'thrTahun',
        //                 caption: 'Tahun'
        //             },
        //             {
        //                 dataField: 'nilaiPersen',
        //                 caption: '% Gaji'
        //             },
        //         ]
        //     },
        //     {
        //         dataField:"nik",
        //         caption:"NIK",
        //         // lookup: {
        //         //     dataSource: karyawan(this.props.store),
        //         //     valueExpr: 'id',
        //         //     displayExpr: 'nikPanjang'
        //         // }
        //     },
        //     {
        //         dataField:"fullName",
        //         caption:"Nama Lengkap",
        //         // lookup: {
        //         //     dataSource: karyawan(this.props.store),
        //         //     valueExpr: 'id',
        //         //     displayExpr: 'fullName'
        //         // }
        //     },
        //     {
        //         dataField:"jabatanId",
        //         caption:"Jabatan",
        //         lookup: {
        //             dataSource: jabatan(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'jabatanName'
        //         }
        //     },
        //     {
        //         dataField:"unitId",
        //         caption:"Unit",
        //     },
        //     {
        //         dataField:"religionId",
        //         caption:"Agama",
        //         lookup: {
        //             dataSource: agama(),
        //             valueExpr: 'id',
        //             displayExpr: 'value'
        //         }
        //     },
        //     {
        //         dataField:"keterangan",
        //         caption:"Keterangan",
        //     },
        //     {
        //         dataField:"nominalThr",
        //         caption:"Nominal THR",
        //         dataType: 'number',
        //         format: '#,##0.00'
        //     },
        //     {
        //         dataField:"pph",
        //         caption:"Pajak",
        //         dataType: 'number',
        //         format: '#,##0.00'
        //     },
        //     {
        //         dataField:"thrBersih",
        //         caption:"THR Bersih",
        //         dataType: 'number',
        //         format: '#,##0.00'
        //     },
        //     {
        //         dataField:"statusId",
        //         caption:"Status",
        //         lookup: {
        //             dataSource: statusPembayaranRekapGaji(this.props.store),
        //             valueExpr: 'id',
        //             displayExpr: 'value'
        //         }
        //     },
        // ]

        this.daftarTransaksiColumns = [
            {
                type: 'buttons',
                buttons: [
                    'edit',
                    {
                        text : 'Detail',
                        hint : 'Detail',
                        cssClass: 'text-primary',
                        onClick : (e) => {
                            this.showModalPenerimaThr(e.row.data)
                        }
                    },
                ],
            },
            {
                dataField: 'tunjanganName',
                caption: 'Nama Tunjangan'
            },
            {
                dataField: 'keterangan',
                caption: 'Keterangan'
            },
            {
                dataField: 'nilaiPersen',
                caption: 'Persentase (%)',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'nominalThr',
                caption: 'Nominal',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'pph',
                caption: 'PPH',
                dataType: 'number',
                format: '#,##0.00'
            },
            {
                dataField: 'thrTahun',
                caption: 'Tahun'
            },
            {
                dataField: 'bersih',
                caption: 'THR Bersih',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]
        this.filter = [
            {
                dataField:"agama",
                label:{
                    text:"Agama",
                    alignment:"left",
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                    dataSource: agama(),
                    valueExpr: 'id',
                    displayExpr: 'value',
                    searchEnabled: true
                }
            },
            {
                dataField:"tahun",
                label:{
                    text:"Tahun",
                    alignment:"left",
                }
            },
            {
                itemType:"button",
                buttonOptions:{
                    text:"Filter",
                    // type:"default",
                    elementAttr:{class:"bg-dapen-default"},
                    onClick:() => {
                        this.filterSubmit()
                    },
                },
                horizontalAlignment:"left"
            },
        ]

        this.DataGridPengajuanRef = React.createRef()
        this.popupDaftarTransaksiThrRef = React.createRef()
    }

    componentDidMount = async() => {
        this.defaultData()
        // var datas =  await httpRequest(
        //     process.env.REACT_APP_BACKEND_HRD,
        //     this.props.store,
        //     `view-master-thrs`,
        //     'GET'
        // )
        // this.filterData = datas
    }

    defaultData = async() => {
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-master-thrs`,
            'GET'
        )
        this.filterData = datas
        if (this.filterData) {
            this.forceRefresh()
        }
    }

    forceRefresh = async() => {
        this.DataGridPengajuanRef.current.forceRefresh(true)
    }

    componentWillMount(){
        let store = this.props.store;
        store.dispatch(addURL(this.props.location.pathname))
    } 

    showModalPenerimaThr = (data) => {
        this.popupDaftarTransaksiThrRef.current.show()
        this.popupDaftarTransaksiThrRef.current.retrieveData(data)
    }

    filterSubmit = async () => {
        const state = this.state.DataMaster
        var datas =  await httpRequest(
            process.env.REACT_APP_BACKEND_HRD,
            this.props.store,
            `view-master-thrs`,
            'GET'
        )
        if (state.agama && state.tahun) {
            this.filterData = datas.filter(value => value.religionId == state.agama && value.thrTahun == state.tahun)
        }else if (state.agama) {
            this.filterData = datas.filter(value => value.religionId == state.agama)
        } else if (state.tahun) {
            this.filterData = datas.filter(value => value.thrTahun == state.tahun)
        }else{
            this.filterData = datas
        }
        // this.filterData = listData.filter(value => (value.pengajuanTanggal.getMonth() + 1) == state.bulan && value.pengajuanTanggal.getFullYear() == state.tahun)
        // this.loadData()
        this.forceRefresh()
    }

    loadData = () => {
        return this.filterData
    }

    render() {
        return (
            <div className="container-fluid">
                <h3 className="main-title">Daftar Transaksi THR</h3>
                <Form
                    colCount={4}
                    id={'formRekap'}
                    formData={this.state.DataMaster}
                    items={this.filter}
                    labelLocation={'left'}
                />
                <DevExpressDataGrid
                        ref = {this.DataGridPengajuanRef}
                        loadAPI='view-daftar-transaksi-thrs'
                        insertAPI='daftar-transaksi-thr'
                        updateAPI='daftar-transaksi-thr'
                        deleteAPI='daftar-transaksi-thr' 

                        backendserver={process.env.REACT_APP_BACKEND_CORE}

                        useArraySource = {true}
                        ArraySourceData= {this.loadData}
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={false}

                        exportExcel={true}
                        exportFileName={"Daftar Penerima THR"}
                        allowExportSelectedData={true}
                        selection={"multiple"}
                        
                        showBorders={true}

                        paging={true}
                        defaultPageSize={10}

                        //bagian konfigurasi popup saat insert dan edit record
                        popupTitle={'Daftar Penerima THR'}
                        popupWidth={450} //masukan dalam ukuran pixel
                        popupHeight={330} //masukkan dalam ukuran pixel

                        popupFormLabelLocation='left' //accepted value = top, left, right
                        popupFormMinColWidth={300} // minimum lebar kolom
                        popupFormColCount={1} //jumlah kolom pada form

                        //akhir bagian konfigurasi popup

                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                        FilterRow={true} // set false untuk mematikan fitur filter

                        ColumnConfiguration={this.daftarTransaksiColumns} // taruh konfigurasi kolom disini
                        // SummaryConfiguration={this.summary}

                        onToolbarPreparing = {this.onToolbarPreparing}

                        //contoh konfigurasi kolom
                        //this.columns = [{
                        //    dataField: 'kolom1',
                        //    caption: 'Ini Kolom 1'
                        //}, {
                        //    dataField: 'kolom2',
                        //    caption: 'Ini Kolom 2'
                        //}]
                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                        store={this.props.store} // jangan di edit edit
                    />
                    <ViewDaftarTransaksiThr
                        ref={this.popupDaftarTransaksiThrRef}
                        store={this.props.store}
                    />
            </div>
        )
    }
}


export default DaftarTransaksiThr