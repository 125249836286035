import React, { Component } from 'react'
import { Popup } from 'devextreme-react/popup';
import Form from 'devextreme-react/form';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid';
import { banks, hubunganKeluarga, months, product } from 'dataSource/lookup';
import reportRequest from 'plugin/reportRequest';
import { PrintElem, formatDate, formatDefaultDate } from 'plugin/helper';
import httpRequest from 'plugin/httprequest';

class TransaksiMpGenerateSPTDetail extends Component {
    constructor(props){
        super(props)

        this.state = {
            dataMaster: {},
            // getUrl : 'pph-21-details'
        }
        
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.pembayaranMpId = 0

        this.PopupRef = React.createRef()
        this.dataGridRef = React.createRef()

        this.id = 0

        this.popupToolbarItem = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Tutup',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.formItem = [
            {
                itemType: 'group',
                caption: 'Periode Laporan',
                items: [
                    {
                        dataField: 'tahun',
                        label: {
                            alignment: 'left',
                            text: 'Tahun'
                        }
                    },
                    {
                        itemType: 'group',
                        colCount : 2,
                        items: [
                            {
                                dataField: 'bulanDari',
                                label: {
                                    alignment: 'left',
                                    text: 'Periode MP Dari'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                dataSource: months(),
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "value", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                            {
                                dataField: 'tahunDari',
                                label: {
                                    alignment: 'left',
                                    text: 'Tahun'
                                }
                            },
                        ]
                    },
                    {
                        itemType: 'group',
                        colCount : 2,
                        items: [
                            {
                                dataField: 'bulanSampai',
                                label: {
                                    alignment: 'left',
                                    text: 'Periode MP Sampai'
                                },
                                editorType: 'dxSelectBox',
                                editorOptions: {
                                dataSource: months(),
                                    valueExpr: "id", // contains the same values as the "statusId" field provides
                                    displayExpr: "value", // provides display values
                                    searchEnabled: true,
                                    deferRendering: false,
                                    width: '100%',
                                }
                            },
                            {
                                dataField: 'tahunSampai',
                                label: {
                                    alignment: 'left',
                                    text: 'Tahun'
                                }
                            },
                        ]
                    },
                ]
            },
            {
                itemType: 'group',
                caption: 'Pemotong',
                items: [
                    {
                        dataField: 'tanggalPotongan',
                        label: {
                            alignment: 'left',
                            text: 'Tanggal Potongan'
                        },
                        editorType: 'dxDateBox',
                        editorOptions: {
                            useMaskBehavior: true,
                            width: '100%',
                            displayFormat: 'dd MMM yyyy'
                        }  
                    },
                    {
                        dataField: 'pejabatNama',
                        label: {
                            alignment: 'left',
                            text: 'Nama Pejabat'
                        }
                    },
                    {
                        dataField: 'pejabatNpwp',
                        label: {
                            alignment: 'left',
                            text: 'Nama NPWP'
                        }
                    },
                ]
            }
        ]

        this.dataGridColumn = [
            {
                type: 'buttons',
                buttons: [
                    {
                        text: 'Cetak',
                        hint : 'Cetak',
                        cssClass: 'text-success',
                        onClick : (e) => {
                            this.cetak(e.row.data)
                        }
                    }
                ],
            },
            {
               dataField: 'id',
               caption: 'No',
               cellRender: (e) => {
                   return e.rowIndex + 1
               }
            }, 
            {
               dataField: 'nik',
               caption: 'NIK'
            },
            {
               dataField: 'npwp',
               caption: 'NPWP'
            },
            {
               dataField: 'nama',
               caption: 'Nama Pensiunan'
            },
            // {
            //    dataField: 'statusMeninggal',
            //    caption: 'Status Meninggal',
            // },
            {
               dataField: 'valAg49',
               caption: 'PTKP',
               format: '#,##0.00'
            },
            {
               dataField: 'valAg50',
               caption: 'GAJI/PENSIUAN (THT/JHT)',
               format: '#,##0.00'
            },
            // {
            //    dataField: 'pemotongNama',
            //    caption: 'Nama Penerima',
            //    cellRender: (e) => {
            //        var data = e.row.data
            //        if(data.pensiunPenerimaTypeId === 1){
            //             return data.namaPeserta
            //         }else{
            //             return "-"
            //        }
            //    }
            // },
            // {
            //    dataField: 'relasiCode',
            //    caption: 'Hubungan Keluarga',
            //    lookup:{
            //         dataSource: hubunganKeluarga(),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'value' 
            //     }
            // },
            {
               dataField: 'valAg51',
               caption: 'Tunjangan Lainnya'
            },
            {
               dataField: 'valAg52',
               caption: 'Honorarium'
            },
            {
               dataField: 'valAg53',
               caption: 'Premi Asurans'
            },
            {
               dataField: 'valAg54',
               caption: 'Hubungan Keluarga'
            },
            {
               dataField: 'valAg55',
               caption: 'Penerimaan Natura'
            },
            {
               dataField: 'valAg56',
               caption: 'Tantiem (Bonus DLL)'
            },
            {
               dataField: 'valAg59',
               caption: 'Penghasilan Bruto'
            },
            {
               dataField: 'valAg69',
               caption: 'PPH Terhutang'
            },
            // {
            //    dataField: 'mpDetilRekeningBankId',
            //    caption: 'Bank',
            //    lookup:{
            //         dataSource: banks(this.props.store),
            //         valueExpr: 'id', // contains the same values as the "statusId" field provides
            //         displayExpr: 'bankName' 
            //     }
            // },
        ]

    }
    
    get Popup(){
        return this.PopupRef.current.instance
    }

    show() {
        this.setState({
            popupVisible: true,
            selectedTabIndex: 0            
        });
    }

    hide = async() => {
        this.setState({
            popupVisible: false,
            dataMaster: {},
            getUrl: 'pph-21-details/getAllByPph21Id/0'
        });
    }

    onValueChanged = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        var fieldValue = e.value

        this.setState({
            [fieldName]: fieldValue
        })        
    }

    retrieveData = async(data) => {
        this.id = data.id
        this.setState({
            // getUrl: `pph-21-details/getAllByPph21Id/${data.id}`,
            dataMaster : data
        })
    }

    cetak = async(data) => {
        for(let i = 49; i <= 70 ;i++){
            data[`valAg${i}`] = data[`valAg${i}`] || 0
        }

        let karyawanAsing = data.karyawanAsing === true ? 'checked' : 'unChecked'
        let female = data.sex === 'Perempuan' ? 'checked' : 'unChecked'
        let male = data.sex === 'Laki - Laki' ? 'checked' : 'unChecked'
        let tanggal = data.tanggalPotong
        tanggal = tanggal ? formatDate(tanggal).split('-') : tanggal

        data.karyawanAsing = karyawanAsing
        data.female = female
        data.male = male
        data.tanggal = `${tanggal[2]}-${tanggal[1]}-${tanggal[0]}`
        data.tanggalPotong = `${tanggal[2]}-${tanggal[1]}`
        try {
            var printData = {
                template:{
                    name: '/dapen/kepesertaan/pajak-pph/pajak-pph',
                    recipe: 'html'
                },
                data: data
            }
            var response = await reportRequest(this.props.store,printData)
            PrintElem(response.data)
        } catch (e) {
            console.log(e)
        }
    }
    // onToolbarPreparing = (e) => {
    //     e.toolbarOptions.items.unshift(
    //         {
    //             location: 'after',
    //             widget: 'dxButton',
    //             options: {
    //                 text: "Proses",
    //                 onClick: (e) => {
    //                     this.generate()
    //                 },
    //             }
    //         },
    //     );
    // }

    forceRefresh = () => {
        this.dataGridRef.current.forceRefresh(true)
    }
    render() {
        return (
            <Popup
                className={'popup'}
                visible={this.state.popupVisible}
                onHiding={this.hide}
                dragEnabled={true}
                resizeEnabled={true}
                showTitle={true}
                title={'Form Detail Generate SPT'} 
                minWidth={500}
                minHeight={500}

                ref={this.PopupRef}

                toolbarItems={this.popupToolbarItem}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Form
                                colCount={2}
                                id={'formData'}
                                formData={this.state.dataMaster}
                                items={this.formItem}
                                labelLocation = "left"
                                readOnly = {true}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <DevExpressDataGrid
                                ref = {this.dataGridRef}
                                // loadAPI={this.state.getUrl}
                                loadAPI={`pph-21-details/getAllByPph21Id/${this.id}`}
                                insertAPI='pph-21-details'
                                updateAPI='pph-21-details'
                                deleteAPI='pph-21-details' 
                                
                                backendserver={process.env.REACT_APP_BACKEND_CORE}

                                // remoteOperations = {true}
                                // useArraySource = {true}
                                // ArraySourceData = {this.loadDataGrid}

                                allowAdding={false}
                                allowDeleting={false}
                                allowUpdating={false}

                                exportExcel={false}
                                exportFileName={"Detail SPT"}
                                allowExportSelectedData={true}
                                selection={"multiple"}
                                
                                showBorders={true}

                                paging={true}
                                defaultPageSize={10}

                                //bagian konfigurasi popup saat insert dan edit record
                                popupTitle={'Detail SPT'}
                                popupWidth={700} //masukan dalam ukuran pixel
                                popupHeight={500} //masukkan dalam ukuran pixel

                                popupFormLabelLocation='left' //accepted value = top, left, right
                                popupFormMinColWidth={300} // minimum lebar kolom
                                popupFormColCount={3} //jumlah kolom pada form

                                //akhir bagian konfigurasi popup

                                ColumnChooser={false} // set false agar kolom tidak dapat di pindah pindah
                                ColumnFixing={false} // set false agar kolom tidak dapat di freeze

                                FilterRow={true} // set false untuk mematikan fitur filter

                                ColumnConfiguration={this.dataGridColumn} // taruh konfigurasi kolom disini
                                SummaryConfiguration={this.summary}

                                height={'calc(70vh - 200px)'}
                                onToolbarPreparing = {this.onToolbarPreparing}

                                //contoh konfigurasi kolom
                                //this.columns = [{
                                //    dataField: 'kolom1',
                                //    caption: 'Ini Kolom 1'
                                //}, {
                                //    dataField: 'kolom2',
                                //    caption: 'Ini Kolom 2'
                                //}]
                                // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                store={this.props.store} // jangan di edit edit
                            />
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }
}

export default TransaksiMpGenerateSPTDetail