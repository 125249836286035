import React, { Component } from 'react'
import {Popup} from 'devextreme-react/popup'
import Form from 'devextreme-react/form'
import { FileUploader } from 'devextreme-react';
import notify from "devextreme/ui/notify";
import httpRequest from 'plugin/httprequest';
import { imageSource, formatUploadFileData } from 'plugin/helper';
import DevExpressDataGrid from 'components/inheritComponent/devexpressdatagrid'
import DevextremeTab from 'components/inheritComponent/devextremeTab';


class FormEditorEmiten extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupVisible: false,
            dataMaster: {},
            uploadedData: []
        }

        this.prevTabIndex = 0
        this.show = this.show.bind(this)
        this.hide = this.hide.bind(this)

        this.PopupRef = React.createRef()

        // this.popupToolbarItemEdit = [
        //     {
        //         widget: 'dxButton',
        //         location: 'after',
        //         options: {
        //             'text': 'Download Laporan Keuangan',
        //             onClick: this.downloadLK.bind(this)
        //         },
        //         toolbar: 'bottom'
        //     },
        //     {
        //         widget: 'dxButton',
        //         location: 'after',
        //         options: {
        //             'text': 'Simpan',
        //             onClick: this.submitData.bind(this)
        //         },
        //         toolbar: 'bottom'
        //     },
        //     {
        //         widget: 'dxButton',
        //         location: 'after',
        //         options: {
        //             'text': 'Batal',
        //             onClick: this.hide.bind(this)
        //         },
        //         toolbar: 'bottom'
        //     }
        // ]

        this.popupToolbarItemAdd = [
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Simpan',
                    onClick: this.submitData.bind(this)
                },
                toolbar: 'bottom'
            },
            {
                widget: 'dxButton',
                location: 'after',
                options: {
                    'text': 'Batal',
                    onClick: this.hide.bind(this)
                },
                toolbar: 'bottom'
            }
        ]

        this.dataMaster = [
            {
                itemType: 'group',
                name: 'master',
                colCount: 4,
                items:[
                    {
                        dataField: 'emitenCode',
                        label: {
                            text: 'Kode Emiten'
                        },
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'emitenName',
                        label: {
                            text: 'Nama Emiten'
                        },
                        colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        itemType: 'empty',
                    },
                    {
                        dataField: 'industriUtama',
                        label: {
                            text: 'Industri Utama'
                        },
                        // colSpan: 2,
                        editorType: 'dxTextBox',
                        editorOptions: {
                        }
                    },
                    {
                        dataField: 'sektor',
                        label: {
                            text: 'Sektor'
                        },
                        editorType: "dxSelectBox",
                        editorOptions:{
                            dataSource: [
                            ],
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: 'value',
                            searchEnabled: true,
                        },
                    },
                    {
                        dataField: 'subSektor',
                        label: {
                            text: 'Sub Sektor'
                        },
                        editorType: "dxSelectBox",
                        editorOptions:{
                            dataSource: [
                            ],
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: 'value',
                            searchEnabled: true,
                        },
                    },
                    {
                        dataField: 'papanPencatatan',
                        label: {
                            text: 'Papan Pencatatan'
                        },
                        editorType: "dxSelectBox",
                        editorOptions:{
                            dataSource: [
                            ],
                            valueExpr: "id", // contains the same values as the "statusId" field provides
                            displayExpr: 'value',
                            searchEnabled: true,
                        },
                    },
                ]
            },
        ]

        this.informasiKeuangan = [
            {
                itemType: 'group',
                name: 'keuangan',
                // caption: 'Informasi Keuangan',
                colCount: 3,
                items:[
                    {
                        dataField: 'aset',
                        label: {
                            text: 'Nama'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'hutang',
                        label: {
                            text: 'Hutang'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'modal',
                        label: {
                            text: 'Modal'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'pendapatan',
                        label: {
                            text: 'Pendapatan'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        dataField: 'laba',
                        label: {
                            text: 'Laba Bersih'
                        },
                        editorType : 'dxNumberBox',
                        editorOptions: {
                            format: "#,##0.00",
                        },
                    },
                    {
                        itemType: 'button',
                        horizontalAlignment: "left",
                        buttonOptions: {
                            text: "Download Laporan Keuangan",
                            type: "success",
                            width: '100%',
                            onClick: () => {
                                this.downloadLK()
                            }
                        }
                    },
                ]
            }
        ]

        this.columnPemegangSaham = [
            {
                dataField:'rowNumber',
                caption: "No.",
                cellRender:(data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'namaPemegangSaham',
                caption: 'Nama'
            },
            {
                dataField: 'persentasePemegangSaham',
                caption: 'Persentase Saham'
            },
        ]

        this.columnDewanDireksi = [
            {
                dataField:'rowNumber',
                caption: "No.",
                cellRender:(data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'namaDireksi',
                caption: 'Nama'
            },
            {
                dataField: 'jabatanDireksi',
                caption: 'Jabatan'
            },
        ]

        this.columnDewanKomisaris = [
            {
                dataField:'rowNumber',
                caption: "No.",
                cellRender:(data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'namaKomisaris',
                caption: 'Nama'
            },
            {
                dataField: 'jabatanKomisaris',
                caption: 'Jabatan'
            },
        ]

        this.columnEfek = [
            {
                dataField:'rowNumber',
                caption: "No.",
                cellRender:(data) => {
                    return data.rowIndex + 1
                }
            },
            {
                dataField: 'jenisEfek',
                caption: 'Jenis Efek'
            },
            {
                dataField: 'kode',
                caption: 'Kode / Seri'
            },
            {
                dataField: 'harga',
                caption: 'Harga Terakhir',
                dataType: 'number',
                format: '#,##0.00'
            },
        ]

        this.TabDataSource = [
            {
                'id': 0,
                text: 'Informasi Perusahaan'
            },
            {
                'id': 1,
                text: 'Informasi Keuangan'
            },
        ]
    }

    get Popup(){
        return this.PopupRef.current.instance
    }

    show(dataMaster) {
        if(dataMaster){
            this.Popup.option('toolbarItems',this.popupToolbarItemEdit)
        }else{
            this.Popup.option('toolbarItems',this.popupToolbarItemAdd)
        }
        var dataMaster = dataMaster || {
        }
        
        this.setState({
            popupVisible: true,
            dataMaster: dataMaster
        });

    }

    hide(){
        this.setState({
            popupVisible: false,
        })
    }

    downloadLK = () => {
        let link = document.createElement("a");
        link.download = `FinancialStatement-2020-I-${this.state.dataMaster.emitenCode}.xlsx`;
        link.target = "_blank"
        link.href = `https://www.idx.co.id/Portals/0/StaticData/ListedCompanies/Corporate_Actions/New_Info_JSX/Jenis_Informasi/01_Laporan_Keuangan/02_Soft_Copy_Laporan_Keuangan/Laporan%20Keuangan%20Tahun%202020/TW1/${this.state.dataMaster.emitenCode}/FinancialStatement-2020-I-${this.state.dataMaster.emitenCode}.xlsx`;
        link.click();
    }

    submitData = async () => {
        if(this.state.dataMaster.id){
            httpRequest(process.env.REACT_APP_BACKEND,this.props.store,'emitens','PUT',{values: this.state.dataMaster},this.state.dataMaster)
        }else{
            httpRequest(process.env.REACT_APP_BACKEND,this.props.store,'emitens','POST',{values: this.state.dataMaster})
        }

        let type = 'success'
        let text = 'Data emiten berhasil di simpan'
        notify({ message: text, width: 'AUTO', shading: true, position:{at: 'center', my: 'center', of: window} }, type, 600);

        this.props.forceRefresh()
        this.hide()
    }

    onUploadStarted = (e) => {
        var fieldName = e.component._options._optionManager._options.name
        for (var i = 0; i < e.value.length; i++) {
            var files = e.value[i];
            
            var fr = new FileReader();

            var formattedFileData;

            var dataMaster = { ...this.state.dataMaster };

            fr.onload = efr => {
                formattedFileData = formatUploadFileData(efr.target.result);

                dataMaster[`${fieldName}Name`] = files.name;
                dataMaster[fieldName] = formattedFileData.base64data;
                dataMaster[`${fieldName}ContentType`] = formattedFileData.mime;

                console.log(dataMaster)

                this.setState({
                    dataMaster: dataMaster
                });
            };

            fr.readAsDataURL(files);
        }
    }

    loadDataPemegangSaham = () => {
        return []
    }

    loadDataDireksi = () => {
        return []
    }

    loadDataKomisaris = () => {
        return []
    }

    loadDataEfek = () => {
        return []
    }

    onTabSelectionChanged = (args) =>{
        document.getElementById(`tabindex-${args.value}`).classList.remove('d-none');

        document.getElementById(`tabindex-${this.prevTabIndex}`).classList.add('d-none');

        this.prevTabIndex = args.value
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    className={'popup'}
                    visible={this.state.popupVisible}
                    onHiding={this.hide}
                    dragEnabled={true}
                    resizeEnabled={true}
                    showTitle={true}
                    title={`Emiten Data`}
                    width={'90vw'}
                    height="90vh"

                    ref={this.PopupRef}

                    toolbarItems = {this.popupToolbarItemAdd}
                >
                    <div className="container-fluid py-3">
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <Form
                                    colCount={1}
                                    id={'formDataMaster'}
                                    formData={this.state.dataMaster}
                                    items={this.dataMaster}
                                    labelLocation={"left"}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-md-12">
                                <DevextremeTab
                                    dataSource={this.TabDataSource}
                                    onOptionChanged={this.onTabSelectionChanged}
                                />
                            </div>
                        </div>
                        <div id="tabindex-0" className={`col-md-12` } style={{height:"calc(100vh - 320px)", overflowY:"auto"}}>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Pemegang Saham</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='emitens'
                                        insertAPI='emitens'
                                        updateAPI='emitens'
                                        deleteAPI='emitens' 

                                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadDataPemegangSaham}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={true}

                                        exportExcel={true}
                                        exportFileName={"Emiten"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Emiten Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnPemegangSaham} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Informasi Efek</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='emitens'
                                        insertAPI='emitens'
                                        updateAPI='emitens'
                                        deleteAPI='emitens' 

                                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadDataEfek}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={true}

                                        exportExcel={true}
                                        exportFileName={"Emiten"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Emiten Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnEfek} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Dewan Direksi</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='emitens'
                                        insertAPI='emitens'
                                        updateAPI='emitens'
                                        deleteAPI='emitens' 

                                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadDataDireksi}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={true}

                                        exportExcel={true}
                                        exportFileName={"Emiten"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}

                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Emiten Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnDewanDireksi} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                                <div className="col-md-6">
                                    <span className="font-weight-bold">Dewan Komisaris</span>
                                    <DevExpressDataGrid
                                        ref={this.datagridRef}

                                        loadAPI='emitens'
                                        insertAPI='emitens'
                                        updateAPI='emitens'
                                        deleteAPI='emitens' 

                                        backendserver={process.env.REACT_APP_BACKEND_INVESTASI}

                                        useArraySource = {true}
                                        ArraySourceData = {this.loadDataKomisaris}

                                        allowAdding={true}
                                        allowDeleting={true}
                                        allowUpdating={true}

                                        exportExcel={true}
                                        exportFileName={"Emiten"}
                                        allowExportSelectedData={true}
                                        selection={"multiple"}

                                        showBorders={true}
                                        height = {200}

                                        paging={true}
                                        defaultPageSize={10}

                                        //bagian konfigurasi popup saat insert dan edit record
                                        popupTitle={'Emiten Data'}
                                        popupWidth={500} //masukan dalam ukuran pixel
                                        popupHeight={300} //masukkan dalam ukuran pixel

                                        popupFormLabelLocation='left' //accepted value = top, left, right
                                        popupFormMinColWidth={300} // minimum lebar kolom
                                        popupFormColCount={1} //jumlah kolom pada form

                                        //akhir bagian konfigurasi popup

                                        ColumnChooser={true} // set false agar kolom tidak dapat di pindah pindah
                                        ColumnFixing={true} // set false agar kolom tidak dapat di freeze

                                        FilterRow={false} // set false untuk mematikan fitur filter

                                        ColumnConfiguration={this.columnDewanKomisaris} // taruh konfigurasi kolom disini
                                        SummaryConfiguration={this.summary}

                                        //contoh konfigurasi kolom
                                        //this.columns = [{
                                        //    dataField: 'kolom1',
                                        //    caption: 'Ini Kolom 1'
                                        //}, {
                                        //    dataField: 'kolom2',
                                        //    caption: 'Ini Kolom 2'
                                        //}]
                                        // detail konfigurasi dapat dilihat di https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Configuration/columns/

                                        store={this.props.store} // jangan di edit edit
                                    />
                                </div>
                            </div>
                        </div>  
                        <div id="tabindex-1" className={`col-md-12 d-none` } style={{height:"calc(100vh - 320px)", overflowY:"auto"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Form
                                        colCount={1}
                                        id={'formDataMaster'}
                                        formData={this.state.dataMaster}
                                        items={this.informasiKeuangan}
                                        labelLocation={"left"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        )
    }
}

export default FormEditorEmiten
